import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { takeWhile, concatMap } from 'rxjs';
import { PlanType, StatusType } from 'src/app/enums/oem-management-enum';
import { MasterDataService } from 'src/app/services/master-data.service';
import { GeographicalChip } from 'src/app/models/product-model';
import { Continent, Country, State, City } from 'src/app/models/master-data.model';
import { forkJoin } from 'rxjs';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
@Component({
  selector: 'app-leads-filter-popup-component',
  templateUrl: './leads-filter-popup-component.component.html',
  styleUrls: ['./leads-filter-popup-component.component.scss']
})
export class LeadsFilterPopupComponentComponent implements OnInit {
  filterForm!: FormGroup;
  countries!: Country[];
  states!: State[];
  cities!: City[];
  searchText: string = '';
  selectedContinents: Continent[] = [];
  selectedCountries: Country[] = [];
  selectedStates: State[] = [];
  selectedCities: City[] = [];
  roleMappingId: any;
  showNoRecordsFounds: boolean = true;
  role: string = '';



  constructor(private dialogRef: MatDialogRef<LeadsFilterPopupComponentComponent>,private fb: FormBuilder, private masterDataService: MasterDataService,private localStorageService: LocalStorageService, private endCustomerService: EndCustomerService,private spinnerService: SpinnerService
    
  ) {}

  async ngOnInit() {
    await this.loadMasterData();
    this.filterForm = this.fb.group({
      country: [],
      state: [],
      city: [],
      accountType: [''],
      companyName: [''],
      leadState: [''],
      addedBy: ['']
    });
    this.getAllStaff();
  }

  submitFilter() {
  
    const payload = {
      continents: this.selectedContinents?.map(c => c.continentName),
      countries: this.selectedCountries?.map(c => c.countryName),
      states: this.selectedStates?.map(c => c.stateName),
      cities: this.selectedCities?.map(c => c.cityName),
      accountType: this.filterForm.value.accountType,
      companyName: this.filterForm.value.companyName,
      leadState: this.filterForm.value.leadState,
      addedBy: this.filterForm.value.addedBy,
      
    };
    this.dialogRef.close(payload);
  }

  clearFilter() {
    this.filterForm.reset();
    this.loadMasterData();
  }
  cancelFilter(){
    const payload = {
      continents: this.selectedContinents?.map(c => c.continentName),
      countries: this.selectedCountries?.map(c => c.countryName),
      states: this.selectedStates?.map(c => c.stateName),
      cities: this.selectedCities?.map(c => c.cityName),
      accountType: this.filterForm.value.accountType,
      companyName: this.filterForm.value.companyName,
      leadState: this.filterForm.value.leadState,
      addedBy: this.filterForm.value.addedBy,
      
    };
    this.dialogRef.close(payload);
  }

  private loadMasterData() {
    this.spinnerService.showSpinner();
    forkJoin({
      countries: this.masterDataService.getCountries(0, 250, '', []),
    }).subscribe(
      (results) => {
        this.countries = results.countries.data;
        this.setFormValues();
        this.spinnerService.hideSpinner();
      },
      (error) => {
        console.error('Error fetching master data:', error);
        this.spinnerService.hideSpinner();
      }
    );
  }

  staffData: any[] = [];

  getAllStaff(){
    this.role=this.localStorageService.getItem('roleName');
    if(this.role=='OEM'){
      this.roleMappingId=this.localStorageService.getItem('oemId');
    } else if(this.role=='DISTRIBUTOR'){
      this.roleMappingId=this.localStorageService.getItem('distributorId');
      console.log(this.roleMappingId, this.role);
      
    }
    this.showNoRecordsFounds=false;
    this.endCustomerService.executeGetRequestURL(MweConstants.getAllUserUrl, {role:this.role,roleMappingId:this.roleMappingId}).subscribe((data: any)=>{
      if(data && data?.data && data.data.length>0){
        this.staffData=data.data;
        console.log('dataaa', this.staffData);
        
      }
    }) 
  }
  private setFormValues() {
    const lead = this.filterForm.value;
    if (lead) {
      this.loadStates([lead.country]).then(() => {
        this.loadCities([lead.state]);
      });
    }
  }

 


  loadStates(countryNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.masterDataService.getStates(0, 500, '', countryNames).subscribe({
        next: (data) => {
          this.states = data.data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching states:', error);
          reject(error);
        }
      });
    });
  }

  loadCities(stateNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.masterDataService.getCities(0, 500, '', stateNames).subscribe({
        next: (data) => {
          this.cities = data.data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching cities:', error);
          reject(error);
        }
      });
    });
  }

  onCountryChange(country: Country) {
   
    if (!this.selectedCountries?.some(c => c.id === country.id)) {
      this.selectedCountries?.push(country);
    }
  
    const countryNames = this.selectedCountries?.map(c => c.countryName);
  
   
    this.loadStates(countryNames);
  
    this.removeContinentForCountry(country);
  
   ;
  }

  onStateChange(state: State) {
    if (!this.selectedStates?.some(c => c.id === state.id)) {
      this.selectedStates?.push(state);
    }
    this.removeCountryForState(state)
    const stateNames = this.selectedStates?.map(c => c.stateName);
    this.loadCities(stateNames);
   
  }

  onCityChange(city: City) {
    this.removeStateForCity(city);
    if (!this.selectedCities?.some(c => c.id === city.id)) {
      this.selectedCities?.push(city);
    }
   
  }
  receiveSearchText(searchText: string): void {
    this.searchText = searchText;
   
  }

  removeContinentForCountry(country: Country) {
    const continentCode = country.continentCode;
   
    this.selectedContinents = this.selectedContinents?.filter(continent => continent.continentCode !== continentCode);
  }

  removeCountryForState(state: State) {
    const countryName = state.countryName;
    
    const country = this.selectedCountries?.find(c => c.countryName === countryName);
    if (country) {
      this.removeContinentForCountry(country);
      
      this.selectedCountries = this.selectedCountries?.filter(country => country.countryName !== countryName);
    }
  }

  removeStateForCity(city: City) {
    const stateName = city.stateName;
    
    const state = this.selectedStates?.find(c => c.stateName === stateName);
    if (state) {
      this.removeCountryForState(state);
     
      this.selectedStates = this.selectedStates?.filter(state => state.stateName !== stateName);
    }
  }

}

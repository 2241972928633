import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthenticationComponent } from '../authentication/authentication.component';
import { MasterDataComponent } from '../shared/master-data/master-data.component';
import { authGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'super-admin',
        loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule),
       canActivate: [authGuard], data: { expectedRoles: ['SUPER_ADMIN'] }
      },
      {
        path: 'distributor',
        loadChildren: () => import('./distributor/distributor.module').then(m => m.DistributorModule),
        canActivate: [authGuard], data: { expectedRoles: ['DISTRIBUTOR'] }
      },
      {
        path: 'oem',
        loadChildren: () => import('./oem/oem.module').then(m => m.OemModule),
        canActivate: [authGuard], data: { expectedRoles: ['OEM'] }
      },
      {
        path: 'user',
        loadChildren: () => import('../end-customer/end-customer.module').then(m => m.EndCustomerModule)
      },
      {
        path: 'staff-management',
        loadChildren: () => import('../staff-management/staff-management.module').then(m => m.StaffManagementModule),
        canActivate: [authGuard], data: { expectedRoles: ['SUPER_ADMIN','OEM','DISTRIBUTOR'] }
      }
    ]
  }
  
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }


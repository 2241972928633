<div class="header d-flex align-items-center">
    <button class="header-btn m-2 add-btn"  mat-raised-button (click)="back()">
        <mat-icon>arrow_back</mat-icon> Back
    </button>
    <h2 class="mx-2 page-heading ">View Product</h2>
</div>
  <div class="product-view-container">
    <mat-card class="product-card">
      <mat-card-content>
        <div class="product-content">
          <div class="image-gallery">
            <!-- <div class="main-image" (click)="setMainImage(selectedViewData.images[0])" (mouseenter)="zoomIn()" (mouseleave)="zoomOut()">
              <img [src]="selectedMainImage" alt="Main Product" [ngStyle]="zoomStyle" />
            </div> -->
            <div class="main-image" (click)="setMainImage(selectedViewData.images[0])">
              <img [src]="selectedMainImage" alt="Main Product" [ngStyle]="zoomStyle" />
            </div>
            <div class="thumbnail-row">
              <img *ngFor="let image of selectedViewData.images" 
                   [src]="image" 
                   alt="Thumbnail" 
                   class="thumbnail" 
                   (mouseenter)="setMainImage(image)"  />
            </div>
          </div>

          <div class="product-details">
            <div class="product-oem">
              <h1>{{ selectedViewData.name | uppercase}}</h1>
            </div>
            <div >
              <h2>{{ selectedViewData.oemName| camelcase}}</h2>
            </div>
            <div class="rating-container">
              <div class="stars">
                <span *ngFor="let star of stars; let i = index">
                  <img src="assets/images/full-star-rating.svg" *ngIf="i < rating" class="star" />
                  <img src="assets/images/empty-star-rating.svg" *ngIf="i >= rating" class="star" />
                </span>
              </div>
              <div class="review-info">
                <img src="assets/images/message-productpage.svg" alt="Reviews" />
                <span>{{ rating }} out of  <strong>({{ totalReviews }})</strong> Reviews</span>
              </div>
            </div>
            
            <div class="category">
              <h3><span>Category</span> {{ selectedViewData.categoryName | camelcase}}</h3>
            </div>
            <div class="product-sku">
              <h3><span>Product SKU</span> {{ selectedViewData.productSku | uppercase}}</h3>
            </div>
            <div class="tags">
              <mat-chip-list>
                <mat-chip class="tag" *ngFor="let tag of selectedViewData.tags">{{ tag | camelcase }}</mat-chip>
              </mat-chip-list>
            </div>
            <!-- <button mat-raised-button class="chat"><img src="assets/images/chat.svg" alt="Chat" />Chat with OEM</button>
            <button mat-raised-button class="share"> <img src="assets/images/share.svg" alt="Share" />Share</button> -->
          </div>
        </div>

        <div class="tab-container">
          <div class="tab-header">
            <div class="tab" [class.active]="activeTab === 0" (click)="selectTab(0)">Product Description</div>
            <div class="tab" [class.active]="activeTab === 1" (click)="selectTab(1)">Specifications</div>
            <div class="tab" [class.active]="activeTab === 2" (click)="selectTab(2)">Videos</div>
            <div class="tab" [class.active]="activeTab === 3" (click)="selectTab(3)">Catalogs</div>
            <div class="tab" [class.active]="activeTab === 4" (click)="selectTab(4)">Reviews ({{ reviews.length }})</div>
            <div class="tab-slider" [style.width.px]="tabSliderWidth" [style.transform]="'translateX(' + tabSliderPosition + 'px)'"></div>
          </div>
          <div *ngIf="activeTab === 0" class="tab-content" [@slideInOut]><div [innerHtml]="selectedViewData.description"></div></div>
          <div *ngIf="activeTab === 1" class="tab-content" [@slideInOut]><div *ngFor="let spec of selectedViewData.specifications">
            <label>{{ spec.name }}:</label>
            <span *ngIf="spec.isRange == 'false'"> {{ spec.fromValue }} {{ spec.fromUnit }}</span>
            <span *ngIf="spec.isRange == 'true'"> {{ spec.fromValue }} {{ spec.fromUnit }} - {{ spec.toValue }} {{ spec.toUnit }}</span>
          </div></div>
          <div *ngIf="activeTab === 2" class="tab-content" [@slideInOut]>
            <div class="video-container">
              <iframe width="560" height="315" [src]="selectedViewData.video | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
          <div *ngIf="activeTab === 4" class="tab-content" [@slideInOut]>

              <!-- Reviews Start -->
          <span style="color: rgba(13, 135, 200, 1);
          font-size: 35px;
          font-weight: 600;
          line-height: 28px;
          "> {{ averageRating || 0 }} out of 5 </span> <br>
          <div class="ratings-star">
            <div  *ngFor="let j of [1, 2, 3, 4, 5]">
              <img  
                    src="../../assets/images/full-star-rating.svg" 
                    *ngIf="j <= averageRating" /> 
              <img  
                    src="../../assets/images/empty-star-rating.svg" 
                    *ngIf="j > averageRating" />
            </div>
            <div class="d-flex align-items-center justify-content-center">
              5.0 out of <strong class="mx-1"> ({{reviews.length}}) </strong> Reviews
            </div><br><br><hr>
          </div>

          <div class="reviews-container d-flex flex-row gap-5">
            <div>


              <div *ngFor="let i of ratingCount; let k = index">
                <div class="d-flex flex-row gap-2 align-items-center">
                  <!-- <progress [value]="calculatePercentage(i)" max="100"></progress> -->
                  <div class="rating-bar d-flex align-items-center">
                    <div class="bar">
                      
                      <div *ngIf="calculatePercentage(i) !== 0 && averageRating; else emptyState" class="fill" [style.width.%]="calculatePercentage(i)"></div>
                      <ng-template #emptyState>
                        <div style="padding: 3px;"></div>
                      </ng-template>

                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center" *ngFor="let j of [1, 2, 3, 4, 5]">
                    <img  
                         src="../../assets/images/full-star-rating.svg" 
                         width="10px" 
                         *ngIf="(k+j)<=5" /> 
                    <img  
                         src="../../assets/images/empty-star-rating.svg" 
                         width="10px" 
                         *ngIf="(k+j)>5" />
                  </div>
                  {{ calculatePercentage(i) || 0 }}%
                </div>
              </div>
              <br><br>
              <button class="button-primary btn btn-primary d-flex align-items-center" (click)="reviewInput()" *ngIf="!toggleWriteReview"> <img src="../../assets/images/review.svg" /> Write a Review</button>
              <div class="d-flex flex-column " *ngIf="toggleWriteReview" >
                <div class="ratings-star">
                  <div *ngFor="let i of [1,2,3,4,5]">
                    <img src="../../assets/images/full-star-rating.svg" (click)="starClicked(i)" *ngIf="starIndex>=i" />
                    <img src="../../assets/images/empty-star-rating.svg" (click)="starClicked(i)" *ngIf="starIndex<i" />
                  </div>
                </div>
                  <angular-editor [(ngModel)] = "review" class="editor" [config]="config" ></angular-editor>
                <div class="buttons mt-3 gap-3 d-flex flex-row w-25">
                  <button class="button-primary btn btn-primary d-flex align-items-center" style="border: none; padding: 0px 50px;" type="submit" (click)="addReview('add')" >Add</button>
                  <button class="button-inverse btn btn-primary d-flex align-items-center" style="border: none; padding: 0px 50px;" type="button" (click)="addReview('cancel')" >cancel</button>
                </div>
              </div><br><br>
            </div>

            <div>

              <div *ngFor="let review of reviews | slice : 0: displayReviews; let i = index" >
                <img src="../../assets/images/account-profile.svg" width="24px" />  <span style="font-weight: 600;"> {{review.userName}} </span> <span style="color: #888; margin-bottom: 5px;">  {{ calculateDaysAgo(review.date) }} <span *ngIf="review.userId===userId" style="cursor: pointer;"> <img src="../../assets/images/edit.svg" (click)="editReview(review.reviewId)" />   <img src="../../assets/images/icons/delete-icon.svg" (click)="deleteReview(review.reviewId)" />  </span>  </span> 
                  <br><span *ngFor="let i of [1,2,3,4,5]" style="margin-top: 10px;">
                    <img src="../../assets/images/full-star-rating.svg" *ngIf="review.rating>=i" />
                    <img src="../../assets/images/empty-star-rating.svg" *ngIf="review.rating<i" />
                  </span>
                <br> {{review.description}}<br>
                <hr>
              </div>
              <b class="view-more-reviews" *ngIf="reviews.length > displayReviews" (click)="viewMoreReviews()">View More</b>
              <br>
            </div>
          </div>
          
          <!-- Reviews End -->
            
          </div>
          <div *ngIf="activeTab === 3" class="tab-content" [@slideInOut]>
            <div class="catalog-container">
              <div class="card" *ngFor="let catalog of catalogFiles">
                <div *ngIf="catalog.type === 'image'">
                  <img [src]="catalog.url" alt="catalog"/>
                </div>
                <div *ngIf="catalog.type === 'pdf'">
                  <div class="iframe-container">
                    <iframe [src]="catalog.url | safe" class="main-iframe"></iframe>
                  </div>
                </div>
                
                <div class="card-actions">
                  <h3>{{ catalog.fileViewName.length > 20 ? catalog.fileViewName.slice(0, 20) + '...' : catalog.fileViewName | camelcase}} </h3>
                  <!-- <p>3 pages</p> -->
                </div>
                <div class="button-container">
                  <div class="buttons">
                    <button class="view-button" (click)="viewCatalog(catalog)"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
                    <button class="download-button" (click)="viewCatalog(catalog)"><img src="assets/images/download.svg" alt="Download"/>Download</button>
                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  

  <app-mwe-table [columns]="columns" [data]="data" [showPagination]="false" [showHeader]="false" (selectedAction)="selectedAction($event)"
></app-mwe-table>
<form [formGroup]="branchForm">


    <div class="card p-4 mt-2 mb-2" *ngIf="isProceed">
<div class="row mt-2">
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Branch Name*</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput   formControlName="brandName">
            </mat-form-field>
        </div>
    </div>
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Contact Person*</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput   formControlName="contactPerson" (keypress)="onlyText($event)">
          
            </mat-form-field>
        </div>
    </div>
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Contact Phone Number*</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput  formControlName="contactPhone" (keypress)="numberOnly($event)">
               
                    
            </mat-form-field>
        </div>
    </div>
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Contact Email*</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput  formControlName="contactEmail" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                    
            </mat-form-field>
        </div>
    </div>
</div>
<div class="row mt-2">
    
    <div class="col-lg-6">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Address*</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput   formControlName="address">
            </mat-form-field>
        </div>
    </div>
</div>
        

<div class="mt-3">

    <button class="mwe-button mwe-button--primary ps-3 pe-3" (click)=" addBranchInformation()"
       >
        <span class="deltext">Save</span>
    </button>&nbsp;&nbsp;
  
    <button class="mwe-button mwe-button--secondary ps-3 pe-3" (click)=" toggleCancel()"
       >
        <span class="deltext">Cancel</span>
    </button>
</div>
    </div>

    <div *ngIf="!isProceed&&!isMore" class="mt-2 mb-2" style="margin-left: 10px;">
        <button class="d-flex more" (click)=" toggleIsProceed()"
        ><mat-icon>add</mat-icon>
         <span class="deltext">Add</span>
     </button>
    </div>
    <div *ngIf="!isProceed&&isMore" class="mt-2 mb-2" style="margin-left: 10px;" >
     <button class="d-flex more" (click)=" toggleIsProceed()"
     ><mat-icon>add</mat-icon>
      <span class="deltext">Add More</span>
  </button>
</div>
    


</form>
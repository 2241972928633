import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { ChecklistDatabase } from 'src/app/services/shared/product-settings/check-list-database';
import { TodoItemFlatNode, TodoItemNode } from 'src/app/models/product-settings-model';
import { AddEditCategoryComponent } from './add-edit-category/add-edit-category.component';
import { ViewCategoryComponent } from './view-category/view-category.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
  providers: [ChecklistDatabase]
}) 

export class CategoriesComponent implements OnInit{
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  showNoRecordsFounds: boolean = false;
  isviewCategories : boolean = false;
  categoryData : any;

  constructor(
    private _database: ChecklistDatabase,
    private dialog: MatDialog,
    private categoryService : CategoryService,
    private sweetAlertService : SweetAlertService

  ) {
    
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
   this.getAllCategories();
  }

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.id = node.id;  // Ensure id is set here
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    const data =  {
      parentNode : parentNode,
      isAddItem : true
    }
    this.openAddEditCategory(data);
  }

  openParentCategory () {
    const data =  {
      isAddItem : true,
      isParentcategory : true
    }
    const dialogRef = this.dialog.open(AddEditCategoryComponent, { 
      width: 'auto',
      height:'auto',
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
     this.getAllCategories();
    });
  }

  openAddEditCategory (data: any) {
    const dialogRef = this.dialog.open(AddEditCategoryComponent, { 
      width: 'auto',
      height:'auto',
      data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
     this.getAllCategories();
    });
  }

  
  getAllCategories() {
    this.showNoRecordsFounds = false;
    this.categoryService.getAllParentCategories().subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          console.log('response.data-1', response.data);
          this.categoryData = response.data;
          this._database.initialize(this.categoryData);
        } else {
          this.showNoRecordsFounds = true;
        }
      },
      error: (error) => {
        console.error('Error fetching category data:', error);
      }
    });
  }

  editItem(node: TodoItemFlatNode): void {
    console.log('Edit item:', node);
    const nestedNode = this.flatNodeMap.get(node);
    if (nestedNode) {
      const data =  {
        parentNode : nestedNode,
        isAddItem : false
      }
      this.openAddEditCategory(data);
    }
  }
  
  deleteItem(node: TodoItemFlatNode): void {
    console.log('Delete item:', node);
    const nestedNode = this.flatNodeMap.get(node);
    
    if (nestedNode && nestedNode.children) {
      // Call service to delete category
      this.sweetAlertService.showConfirmation('Are you sure?', 'You will not be able to recover the deleted record!', 'Yes, Delete It', 'Cancel')
      .then((result) => {
        if (result.value) {
          this.categoryService.deleteCategory(nestedNode.id).subscribe(
            () => {
              const parentNode = this.getParentNode(node);
              if (parentNode) {
                const parentNestedNode = this.flatNodeMap.get(parentNode);
                if (parentNestedNode) {
                  parentNestedNode.children = parentNestedNode.children.filter(child => child.id !== nestedNode.id);
                  this._database.dataChange.next(this._database.data);
                }
              } else {
                // Root node
                this._database.dataChange.next(this._database.data.filter(child => child.id !== nestedNode.id));
              }
              this.sweetAlertService.showSuccess('Removed', 'Category deleted successfully !!');
            },
            (error) => {
              console.error('Failed to delete category:', error);
              this.sweetAlertService.showError('Failed', 'Failed to delete category !!');
            }
          );
        } else if(result.dismiss === this.sweetAlertService.CANCEL){
          this.sweetAlertService.showError('Cancelled', 'Item is safe.');
        }
      });
    }
  }

viewAllCategories() {
   this.isviewCategories = true;
}

onViewCategoriesChanged(isViewCategories: boolean) {
  this.isviewCategories = isViewCategories;
  this.treeControl.collapseAll();
} 

}

import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PlanType } from 'src/app/enums/oem-management-enum';
import { OemData } from 'src/app/models/oem-models';
import { SubScriptionDetails } from 'src/app/models/subscription-model';
import { SubscriptionsService } from 'src/app/services/shared/subscriptions/subscriptions.service';
import { OemManagementService } from 'src/app/services/super-admin/oem-management.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oem-management-table',
  templateUrl: './oem-management-table.component.html',
  styleUrls: ['./oem-management-table.component.css']
})

export class OemManagementTableComponent implements OnChanges {
  @Input() oemData!: OemData;
  @Input() searchValue!: string | null;
  dataSource = new MatTableDataSource(this.oemData?.data);
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = ['srNo', 'uniqueId', 'name', 'companyLogo', 'companyName', 'emailAddress', 'country','phoneNo', 'planTaken', 'status', 'date', 'actions'];
  subscriptionPlans: SubScriptionDetails[] = [];
  @Output() onDelete = new EventEmitter<any>();
  constructor (private router: Router, private oemManagementService: OemManagementService, private subscriptionsService: SubscriptionsService, private sweetAlertService: SweetAlertService) {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSubsctiptionStatus()
    this.dataSource = new MatTableDataSource(this.oemData?.data);
    this.dataSource.filter = this.searchValue?.trim().toLowerCase() || '';

  }

  getPlanImage(planTaken: string): string {
    switch (planTaken) {
      case PlanType.FREE_PLAN:
        return 'assets/images/icons/freePlan.svg';
      case PlanType.SILVER_PLAN:
        return 'assets/images/icons/silverPlan.svg';
      case PlanType.GOLD_PLAN:
        return 'assets/images/icons/goldPlan.svg';
      case PlanType.PLATINUM_PLAN:
        return 'assets/images/icons/platinumPlan.svg';
      // Add more cases for other plans if needed
      default:
        return 'assets/images/icons/freePlan.svg';
    }
  }

  edit(id: string) {
    this.router.navigate(['super-admin/oem-management/update-oem',id]);
  }

  delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover the deleted record!',
      iconHtml: '<img src="./assets/images/group-delete.svg">',
      iconColor: 'white',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      confirmButtonColor: '#0f74a9',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#6c757d'

    }).then((result) => {
      if (result.value) {
        this.oemManagementService.deleteOem(id).subscribe((res) => {
          if (res) {
            this.onDelete.emit();
            this.sweetAlertService.showSuccess('Removed!', 'Item Removed Successfully.')
          }
        })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: 'Item is safe.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#0f74a9',
        })
      }
    })


  }

  view(id: string) {
    this.router.navigate(['super-admin/oem-management/view-oem',id]);
  }

  getSubsctiptionStatus(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subscriptionsService.getAllSubscriptions().subscribe(data => {
        this.subscriptionPlans = data.data
        resolve();
      })
    })
  }

  getSubsctiptionName(subscriptionId: string): string {
    const subscription = this.subscriptionPlans.find(sub => sub.name === subscriptionId);
    return subscription?.name || '';
  }

  getSubscritonImage(subscriptionId: string): string {
    const subscription = this.subscriptionPlans.find(sub => sub.name === subscriptionId);
    return subscription?.image || '';
  }
}

<div class="container">
    <div class="row d-flex justify-content-between">
        <div class="id col-md-6">
            <p class="blue-text">#{{Data?.appointmentsequenceid}}</p>
            <!-- <p>{{Data?.id}}</p>  -->
        </div>
        <div class="calender d-flex  col-md-6 justify-content-end" *ngIf="Data?.status != 'PENDING'">
            <div class="blue-text">{{Data.status}}ED</div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-4">
            <p>Name</p>
        </div>
        <div class="col-md-6">
            <p>{{Data?.name}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <p>Email Address</p>
        </div>
        <div class="col-md-6">
            <p>{{Data?.email}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <p>Phone Number</p>
        </div>
        <div class="col-md-6">
            <p>{{fullPhoneNumber}}</p>
        </div>
    </div>
    <div class="row staff" *ngIf="isEdit">
        <div class="col-md-4 ">
            <p>Assigned Staff</p>
        </div>
        <div class="col-md-8">
            <mat-form-field appearance="outline" class="fieldss">
                <ng-container>
                  <mat-select placeholder="Select"   [formControl]="selectedStaff" multiple>
                    <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">{{ accountType }}</mat-option>
                  </mat-select>
                </ng-container>
              </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <p>Selected Date & Slot</p>
        </div>
        <div class="col-md-8">
            <p>{{Data?.date}} - {{Data?.slot}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p>Message</p>
        </div>
        <div class="col-md-12">
            <p>{{Data?.message}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 " style="margin-bottom: -10px;">
            <p>Remarks</p>
        </div>
        <div class="col-md-12 sty" style="margin-bottom: 10px;">
            <input type="text" class="form-control" [(ngModel)]="Data.remark"/>
        </div>
    </div>
    <div class="d-flex gap-1 mt-3 mb-2">
        <div *ngIf="isEdit && Data.status == 'PENDING'"><button class="button-primary" (click)="update('accept')">Accept</button></div>&nbsp;&nbsp;
        <div *ngIf="isEdit && Data.status == 'PENDING'"><button class="button-primary" (click)="update('reject')">Reject</button></div>&nbsp;&nbsp;
        <div *ngIf="isEdit && Data.status != 'PENDING'"><button class="button-primary" (click)="update(Data.status)">Save</button></div>&nbsp;&nbsp;
        <button class="button-inverse" (click)="cancel()">Cancel</button>
    </div>
</div>
import { environment } from "src/environments/environment";

const serveURL = `${environment.serverScheme}${environment.serverDns}`;

export const serverRoutes = {
  authBaseRoute: '/auth/api/user',
  masterDataBaseRoute: '/mdm/api/masterdata',
  onboardingBaseRoute: '/onboarding', 
  searchBaseRoute: '/search',
};

const baseOnboardingApiURL = `${serveURL}${serverRoutes.onboardingBaseRoute}`;
//const baseOnboardingApiURL = `http://localhost:8089/onboarding`;

export const apiUrls = {
  login: `${serveURL}${serverRoutes.authBaseRoute}/login`,
  register: `${serveURL}${serverRoutes.authBaseRoute}/signup`,
  forgotPassword: `${serveURL}${serverRoutes.authBaseRoute}/forgot-password`,
  resetPassword: `${serveURL}${serverRoutes.authBaseRoute}/reset-password`,
  refreshToken: `${serveURL}${serverRoutes.authBaseRoute}/refresh-token`,
  updatePassword: `${serveURL}${serverRoutes.authBaseRoute}/update-password`,
  resendVerification: `${serveURL}${serverRoutes.authBaseRoute}/resend-verification`,
  countryCodes: (pageNumber: number, pageSize: number) => `${serveURL}${serverRoutes.masterDataBaseRoute}/countries?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  accounttypes: `${serveURL}${serverRoutes.onboardingBaseRoute}/account`,
  signup: `${serveURL}${serverRoutes.authBaseRoute}/signup`,
  prospect: `${serveURL}${serverRoutes.authBaseRoute}/prospect`,
  salesLeads: `${serveURL}${serverRoutes.onboardingBaseRoute}/leads`,
  salesLeadss: `${serveURL}${serverRoutes.onboardingBaseRoute}/leads/`,
  leadSummary: `${serveURL}${serverRoutes.onboardingBaseRoute}/leads/summary`,
  salesTableLeads: `${serveURL}${serverRoutes.onboardingBaseRoute}/leads/filters`,
  createAccount: `${serveURL}${serverRoutes.onboardingBaseRoute}/leads/create-account/`,
  primaryCategory: `${serveURL}${serverRoutes.onboardingBaseRoute}/productinfo/primary`,
  secondaryCategory: `${serveURL}${serverRoutes.onboardingBaseRoute}/productinfo/secondary`,
  tertiaryCategory: `${serveURL}${serverRoutes.onboardingBaseRoute}/productinfo/tertiary`,
  productRange: `${serveURL}${serverRoutes.onboardingBaseRoute}/productinfo/productrange`,
  enquiryUrl : `${serveURL}${serverRoutes.onboardingBaseRoute}/enquiry`,
  notifications : `${serveURL}${serverRoutes.onboardingBaseRoute}/notification`,
  masterdata : `${serveURL}${serverRoutes.masterDataBaseRoute}`,
  emailCheck : `${serveURL}${serverRoutes.authBaseRoute}/check-email`,
};

export const OEMUrls = {
  staff: `${serveURL}${serverRoutes.onboardingBaseRoute}/staff/all?role=`,
  oem: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM`,
  catalog: `${serveURL}${serverRoutes.onboardingBaseRoute}/catalog`,
  distributor : `${serveURL}${serverRoutes.onboardingBaseRoute}/distributor`,
  oemDetails: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/details?userId=`,
  oemStatistics: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/stastics?id=`,
  exhibition: `${serveURL}${serverRoutes.onboardingBaseRoute}/exhibition`,
  categories: `${serveURL}${serverRoutes.onboardingBaseRoute}/api/categories`,
  products : `${serveURL}${serverRoutes.onboardingBaseRoute}/productinfo`,
  subscriptions : `${serveURL}${serverRoutes.onboardingBaseRoute}/subscriptions`,
  webinar: `${serveURL}${serverRoutes.onboardingBaseRoute}/webinar`,
  enquiry: `${serveURL}${serverRoutes.onboardingBaseRoute}/enquiry`,
  message: `${serveURL}${serverRoutes.onboardingBaseRoute}/message`,
  webinarSpeciality: (pageNumber: number, pageSize: number) => `${serveURL}${serverRoutes.masterDataBaseRoute}/specialities?pageNumber=${pageNumber}&pageSize=${pageSize}`,
  brandList: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/brands-list`,
  oemById: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM?id=`,
  oemWithManagementId: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/get-oem-with-management?id=`,
  oemWithManagementUpdate: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/update-with-management`,
  oemDistributorById: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/oem-distributor?id=`,
  oemByDistributorId: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/distributor-oem?id=`,
  sendRequest : `${serveURL}${serverRoutes.onboardingBaseRoute}/request/send`,
  sentPendingRequest : `${serveURL}${serverRoutes.onboardingBaseRoute}/request/sent-pending?id=`,
  receivedPendingRequest : `${serveURL}${serverRoutes.onboardingBaseRoute}/request/received-pending?id=`,
  respondRequest : `${serveURL}${serverRoutes.onboardingBaseRoute}/request`,
  myDistributors : `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/oem-distributor?id=`,
  namebyUserId : `${serveURL}${serverRoutes.onboardingBaseRoute}/enquiry/get-details-by-id/`,
  createMeeting: `${serveURL}${serverRoutes.onboardingBaseRoute}/meeting/create`,
  poc: `${serveURL}${serverRoutes.onboardingBaseRoute}/OEM/save-oem-poc`,
  updateStatus: `${serveURL}${serverRoutes.onboardingBaseRoute}/shared/update-status`,
};

export const SearchUrls = {
  increamentViewCount: `${serveURL}${serverRoutes.searchBaseRoute}/increment-product-view`,
  topProducts: `${serveURL}${serverRoutes.searchBaseRoute}/top-products`,
  searchSuggestions: `${serveURL}${serverRoutes.searchBaseRoute}/suggestions`
};

export const ProductInfoUrls = {
  baseURL: `${baseOnboardingApiURL}/productinfo`,
  getAllProducts: `${baseOnboardingApiURL}/productinfo`,
  getAllDistributor: `${baseOnboardingApiURL}/productinfo/distributor`,
  getOEMs: `${baseOnboardingApiURL}/OEM/list`,
  saveProduct: `${baseOnboardingApiURL}/productinfo/save`,
  filterProduct: `${baseOnboardingApiURL}/productinfo/filter`,
  updateProduct: `${baseOnboardingApiURL}/productinfo/update`,
  deleteProduct: `${baseOnboardingApiURL}/productinfo/delete`,
  importFile: `${baseOnboardingApiURL}/productinfo/import`,
  allProductsByOEMId: `${baseOnboardingApiURL}/productinfo/oem`,
  allProductsByDistributorId: `${baseOnboardingApiURL}/productinfo/distributor`,
  searchProduct: `${baseOnboardingApiURL}/productinfo/search`,
  getTextSpecificationsByNames: `${baseOnboardingApiURL}/productinfo/text-specifications-by-names`,
  modifiedOnByOemId : `${baseOnboardingApiURL}/productinfo/oem/modified-on` 
};





<div class="background">
    <app-website-header></app-website-header>
    <div class="row">
      <img src="../../../assets/images/landerpage.png" alt="landingpage" class="landingpageimage" />
    </div>
  
    <div class="mx-3 mt-2">
      <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="">
      <a class="breadcrummb ps-2" style="color:#858585 ;">Home</a>
      <a class="breadcrummb" style="color:#858585 ;">/</a>
      <a class="breadcrummb" style="color:#000000 ;">Distributors List</a>
    </div>
  
    <div class="row mt-3 p-4" style="background-color: #F5F5F5;">

      <div class="col-lg-3">
        <div class="row">           
            <div class="col-md-3  mb-md-0 mt-2" style="display: flex; align-items: center; gap: 1em;">
              <h2 class=" mr-2">
                Filters
              </h2>           
                <button (click)="reset()" class="reset"> <img src="/assets/images/reset.svg"  (click)="reset()">Reset</button>
                   
            </div>
            <div class="items-list mt-3 mb-2" >  
              <mat-chip *ngFor="let item of countryselected" >{{item}}</mat-chip>
            </div> 
        </div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title><h2>Region</h2></mat-panel-title>
          </mat-expansion-panel-header>

          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>         
              <mat-checkbox [(ngModel)]="node.selected" (change)="selectedcheckbox($event, node)" [value]="node.name" >
                {{node.name}}
              </mat-checkbox>
            </mat-tree-node>

            <mat-form-field>
              <input matInput placeholder="Search Country" >
            </mat-form-field>

            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding  >
              <button mat-icon-button matTreeNodeToggle
                      [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>   
              {{node.name}}
              
            </mat-tree-node>
          </mat-tree>        
        </mat-expansion-panel>
        
      </div>
      <div class="col-lg-9">  
        <div class="d-flex flex-row justify-content-between">
          <h1 style="border-bottom: 2px solid #0D87C8;  margin: 0; margin-left: .5rem; display: inline-block; color: #0D87C8; font-size: medium; font-weight: 00; font-family: 'work sans';">Distributors</h1>
          <h1 style="margin: 0; margin-left: .5rem; display: inline-block; color: #1F1F1F; font-size: medium; font-weight: 00; font-family: 'work sans';">Available Distributors: {{ distributorsCount }} </h1>
        </div>     
          <h1 style="border: none; border-top: 1px solid #0D87C8;  width: 100%; margin: 0; margin-left: .5rem;"></h1>
          <div *ngIf="distributorsCount===0 && roleName === 'USER'" class="d-flex align-items-center w-100 justify-content-center flex-column flex-wrap" style="margin-top: 25px;">
              <p style="color: rgba(5, 112, 159, 1); font-weight: bold;"> There are no Distributors for this product. If you want to enquire then.. </p>
              <button class="m-lg-2 button-primary btn btn-primary d-flex align-items-center justify-content-center" (click)="noDistributors()" >Continue</button>
          </div>
          <div *ngIf="distributorsCount===0 && roleName !== 'USER'" class="d-flex align-items-center w-100 justify-content-center flex-wrap" style="margin-top: 30px;">
            <p style="color: red; font-weight: bold; font-size: 20px;"> No Records Found</p>
          </div>
        <div class="row">
            <div class="col-md-4" *ngFor="let distributor of distributors" style="max-height: 400px;">
                <div class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D; width: 360px; height: 374px;">
                    <div class="card-img">
                        <img [src]="distributor.logo" width="319px" height="130px">
                    </div>
                    <div class="card-info p-2">
                        
                        <mat-label class="name d-flex flex-row w-100"> {{ distributor.name }} </mat-label>
                        <div class="info d-flex flex-column gap-1">
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/distributor-location-icon.svg" width="13px" height="13px">
                                </div>
                                <div *ngIf="!signin" class="signin"><p>Sign in for more Information</p></div>
                                <div *ngIf="!signin" class="inner-info-data w-100" style="filter: blur(4px);" > {{ distributor.address }} </div>
                                <div *ngIf="signin" class="inner-info-data w-100"> {{ distributor.address }} </div>

                            </div>
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/distributor-phone-icon.svg" width="13px" height="13px">
                                </div>
                                <div class="inner-info-data" *ngIf="!signin" style="filter: blur(4px);"> {{ distributor.phoneNumber }} </div>
                                <div class="inner-info-data" *ngIf="signin"> {{ distributor.phoneNumber }} </div>
                            </div>
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/distributor-email-icon.svg" width="13px" height="13px">
                                </div>
                                <div class="inner-info-data" *ngIf="!signin" style="filter: blur(4px);">{{ distributor.email }}</div>
                                <div class="inner-info-data" *ngIf="signin">{{ distributor.email }}</div>
                            </div>
                        </div>
                        <br>
                        <div class="d-flex flex-row justify-content-between">
                            <button [routerLink]="['/brands/brand-profile', distributor.oemId]" [queryParams]="{ type: 'brand' }" class="btn btn-primary" style="background-color: #0D87C8; border: none;" [disabled]="!signin">View Profile</button>
                            <button [routerLink]="['/user/products/enquiry', prodID]" class="btn" style="color: #0D87C8; border: 1px solid #0D87C8;" [disabled]="!signin">Send an Enquiry</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="pagination-container">
        <app-pagination [itemsPerPage]="itemsPerPage" [data]="brands" (pageChanged)="onPageChanged($event)"></app-pagination>
      </div> -->
    </div>
    <app-website-footer></app-website-footer>
  
    
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupVisibityService {


  private popupVisibilitySubject = new BehaviorSubject<boolean>(false);
   popupVisibility$ = this.popupVisibilitySubject.asObservable();
  

   private isProcessing: boolean = false;

   setPopupVisibility$(popupVisibility: boolean): void {
     // Avoid triggering the update if already processing
     if (!this.isProcessing) {
       this.isProcessing = true;
       console.log(popupVisibility, "popupVisibility");
       this.popupVisibilitySubject.next(popupVisibility);
       this.isProcessing = false;
     }
   }
    
  getPopupVisibility$() {
    return this.popupVisibilitySubject.asObservable();
  }
}


import { Component } from '@angular/core';


@Component({
  selector: 'app-how-does-it-works',
  templateUrl: './how-does-it-works.component.html',
  styleUrls: ['./how-does-it-works.component.scss']
})
export class HowDoesItWorksComponent {
  
}

<div class="filter-container">
  <div class="row-container">
    <div style="display: flex; align-items: center;width: 9%;">
      <img src="assets/images/filter.svg" alt="">
      <h3 class="filter-text">Filter by</h3>
  </div>
  
    <form [formGroup]="filterForm" class="filter-form">
      <mat-form-field class="input-dropdown" appearance="outline" >
        <mat-select placeholder="By Continent" formControlName="continent" (selectionChange)="onContinentChange($event.value)">
          <mat-option *ngFor="let continent of continents" [value]="continent">{{ continent?.continentName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-dropdown" appearance="outline">
        <mat-select placeholder="By Country" formControlName="country" (selectionChange)="onCountryChange($event.value)">
          <mat-option *ngFor="let country of countries" [value]="country">{{ country.countryName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-dropdown" appearance="outline">
        <mat-select placeholder="By State" formControlName="state" (selectionChange)="onStateChange($event.value)">
          <mat-option *ngFor="let state of states" [value]="state">{{ state.stateName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-dropdown" appearance="outline">
        <mat-select placeholder="By City" formControlName="city" (selectionChange)="onCityChange($event.value)">
          <mat-option *ngFor="let city of cities" [value]="city">{{ city.cityName }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="input-dropdown" appearance="outline">
        <mat-label class="medworld-label">Registered Period</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="start" formControlName="startDate">
          <input matEndDate placeholder="end" formControlName="endDate">
        </mat-date-range-input>
        <mat-icon matSuffix *ngIf="hasDateRange()" (click)="resetDateRange()">cancel</mat-icon>
        <mat-datepicker-toggle  *ngIf="!hasDateRange()" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker  #picker></mat-date-range-picker>
      </mat-form-field>
    </form>
    
  </div>
  
  <!-- Chips -->
  <div *ngIf="chips?.length" class="row-container-2" style="margin-top: 21px;">
    <span class="paragraph-medium">Filters applied: </span>
      <mat-chip class="chip-item" *ngFor="let chip of chips" (removed)="remove(chip)">
        {{ chip?.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    <button class="btn clear-filter-btn" mat-raised-button (click)="clearAllFilters()">Clear Filters</button>
  </div>
  
  <!-- Horizontal line -->
  
</div>
<hr class="line">
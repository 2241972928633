<div class="form-row">
    <div [formGroup]="distributorForm" class="w-100">
        <h2>Add Distributor</h2>
        <div class="row">
        <div class="form-row " style="padding: 10px; padding-bottom: 0px;">
            <div class="col-md-5">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >First Name</p>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="firstName" placeholder="First Name" class="inputs">
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >Last Name</p>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="lastName" placeholder="Last Name" class="inputs">
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >Company Name</p>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="companyName" placeholder="Company Name" class="inputs">
                </mat-form-field>
            </div>

            <div class="col-md-5 ">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >Company Email</p>
                <mat-form-field appearance="outline" class=" w-100">
                    <input matInput formControlName="email" placeholder="Company Email" class="inputs">
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >Phone Number</p>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="phoneNumber" placeholder="Phone Number" class="inputs" >
                </mat-form-field>
            </div>
            <div class="col-md-5">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" >Address</p>
                <mat-form-field appearance="outline" class="w-100">
                    <input matInput formControlName="address" placeholder="Address" class="inputs">
                </mat-form-field>
            </div>
        </div>
        </div>
        <div class="form-rows" style="gap: 0.8rem; margin-left: 1.35rem; margin-top: -5px;" >

            <button  class="mwe-button mwe-button--primary m-3"  (click)="createDistributor()">Save Distributor</button>
            <button class="mwe-button mwe-button--primary m-3"   (click)="cancelAddDistributor()">Cancel</button>
        </div>

    </div>
</div>
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
  appointmentForm!: FormGroup;
  submitted = false;
  isEmailError: boolean = false;
  availableTimes : string [] = [];
  minDate!: string;
  maxDate!: string;
  countryCodes: any[] = [];
  filteredCountryCodes: any[] = [];
  searchControl = new FormControl('');
  showDropdown: boolean = false;
  
  constructor(
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<AppointmentComponent>,
    private oemService : OemService,
    private sweetAlertService : SweetAlertService,
    private authService : AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
      this.appointmentForm = this.fb.group({
        name: ['', [Validators.required, Validators.pattern("^[a-zA-Z ]*$"), noWhitespaceValidator()]],
        email: ['', [Validators.required, Validators.email, noWhitespaceValidator()]],
        countryCode: ['', [Validators.required]],
        phoneCode : [''],
        phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
        date: ['', Validators.required],
        time: ['', Validators.required],
        message: ['']
      });
    }

  ngOnInit(): void {
    console.log(this.data);
    const appointment = this.data.appointments[0];
    console.log(appointment);
    
    if(appointment && appointment.startTime && appointment.endTime) {
      this.availableTimes = this.calculateAvailableSlots(appointment.startTime, appointment.endTime, '0:00', 60);
    }
    this.appointmentForm.get('date')?.setValue(this.data.exhibitionStartDate); 
    

    const exhibitionStartDate = new Date(this.data.exhibitionStartDate);
    const exhibitionEndDate = new Date(this.data.exhibitionEndDate);

    this.minDate = exhibitionStartDate.toISOString().split('T')[0];
    this.maxDate = exhibitionEndDate.toISOString().split('T')[0];

    this.getCountryCodes();
    this.searchControl.valueChanges.subscribe((term) => {
      this.filterCountryCodes(term ?? '');
    });
  }

  filterCountryCodes(term: string): void {
    this.filteredCountryCodes = term ? 
      this.countryCodes.filter(code =>
        code.countryCode.toLowerCase().includes(term.toLowerCase())
      ) 
      : this.countryCodes; 
  }
  
  selectCountryCode(code: { countryCode: string; phoneCode: string }): void {
    this.appointmentForm.patchValue({
      countryCode: code.countryCode,
      phoneCode: code.phoneCode
    });
    this.searchControl.setValue(code.countryCode);
    this.showDropdown = false; 
  }
  
  showDropdownList(): void {
    // Reset the filtered list to show all country codes
    this.filteredCountryCodes = this.countryCodes;
    this.showDropdown = true;
  }
  
  // Hide dropdown list with a delay
  hideDropdown(): void {
    setTimeout(() => this.showDropdown = false, 200);
  }
  
  
    getCountryCodes(): void { 
      this.authService.getCountrycodes(0, 300).subscribe(
        (response) => {
          this.countryCodes = response.data;
          this.filteredCountryCodes = this.countryCodes;
          console.log('countryCodes', this.countryCodes);
          
        },
        (error) => {
          console.error('Error fetching country codes:', error);
        }
      );
    }
  
    updatePhoneCode(event: Event): void {
      const selectElement = event.target as HTMLSelectElement;
      const selectedCountryCode = selectElement.value;
      console.log('selectedCountryCode', selectedCountryCode);
      
      const selectedCode = this.countryCodes.find(code => code.countryCode === selectedCountryCode);
      if (selectedCode) {
        this.appointmentForm.patchValue({
          phoneCode: selectedCode.phoneCode
        });
      }
    }

  getTodayDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  get isSelectedDateInvalid() {
    const selectedDate = this.appointmentForm.get('date')?.value;
    const exhibitionStartDate = new Date(this.data.exhibitionStartDate);
    const exhibitionEndDate = new Date(this.data.exhibitionEndDate);
    if (selectedDate) {
      const selectedDateObj = new Date(selectedDate);
      if (selectedDateObj < exhibitionStartDate || selectedDateObj > exhibitionEndDate) {
        return true;
      }
    }
    return false;
  }

  calculateAvailableSlots(startTime: string, endTime: string, breakTime: string, perSlotTime: number): string[] {
    // Parse the time strings
    const start = this.parseTime(startTime);
    const end = this.parseTime(endTime);
    const breakDuration = this.parseTime(breakTime);

    // Calculate the effective start time after the break
    let currentTime = start;
    let slots = [];

    // Generate the slots
    while (currentTime + perSlotTime <= end) {
      const slotStart = this.formatTime(currentTime);
      currentTime += perSlotTime;
      const slotEnd = this.formatTime(currentTime);
      slots.push(`${slotStart} - ${slotEnd}`);

      // Apply break time after the first slot
      if (currentTime + perSlotTime <= end) {
        currentTime += breakDuration;
      }
    }

    console.log(slots);
    return slots;
  }

  private parseTime(time: string): number {
    console.log(time);
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + (minutes || 0); // Handle cases where minutes might be omitted
  }

  private formatTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = mins.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }


  onSubmit(): void {
    this.submitted = true;
    if (this.appointmentForm.invalid) {
      return;
    }

    if (this.appointmentForm.valid) {
      // Handle form submission
      const formData = this.appointmentForm.value;
      const payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        date: formData.date,
        slot: formData.time,
        phoneCode: formData.phoneCode,
        exhibitionId: this.data.id
      };
      console.log('appointment payload', payload);
      this.oemService.saveAppointment(payload).subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.sweetAlertService.showSuccess('', 'Appointment booked successfully !!');
        },
        (error) => {
          console.log("error", error);
          this.dialogRef.close(true);
          this.sweetAlertService.showError('', 'Failed to booked appointment !!');
        }
      );
      }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  get phone() {
    return this.appointmentForm.get('phone');
  }

  get isPhoneInvalid() {
    if (this.submitted) {
      return this.appointmentForm.get('phone')?.invalid;
    }
    return this.appointmentForm.get('phone')?.invalid && (this.appointmentForm.get('phone')?.dirty || this.appointmentForm.get('phone')?.touched);
  }

  get isNameInvalid() {
    if (this.submitted) {
      return this.appointmentForm.get('name')?.invalid;
    }
    return this.appointmentForm.get('name')?.invalid && (this.appointmentForm.get('name')?.dirty || this.appointmentForm.get('name')?.touched);
  }

  get isDateInvalid() {
    if (this.submitted) {
      return this.appointmentForm.get('date')?.invalid;
    }
    return this.appointmentForm.get('date')?.invalid && (this.appointmentForm.get('date')?.dirty || this.appointmentForm.get('date')?.touched);
  }

  get isEmailInvalid() {
    if (this.submitted) {
        if(!this.appointmentForm.get('email')?.value){ 
          this.isEmailError = true;
        } else {
          this.isEmailError = false;
        }
      return this.appointmentForm.get('email')?.invalid;
    }
    return (this.appointmentForm.get('email')?.dirty || this.appointmentForm.get('email')?.touched);
  }

  get isTimeInvalid() {
    if (this.submitted) {
      return this.appointmentForm.get('time')?.invalid;
    }
    return this.appointmentForm.get('time')?.invalid && (this.appointmentForm.get('time')?.dirty || this.appointmentForm.get('time')?.touched);
  }


}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}

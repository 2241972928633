<!-- <mat-card   [routerLink]="isCategoryCard ? ['/category', id] : ['/product-details', id]"
    class="product-info-card" [ngClass]="{'default-card': !isCategoryCard, 'category-card': isCategoryCard}">
  <div class="card-info">
    <img *ngIf="!isCategoryCard && newBadge" src="assets/images/new.svg" alt="New Badge" class="new-badge">
    <img mat-card-image [src]="productImage"  style="height: 180px; width: 180px;" alt="Product Image" class="logo">
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="!isCategoryCard">
      <img [src]="subtitleImage"  alt="company logo">
    </mat-card-subtitle>
    <mat-card-content *ngIf="!isCategoryCard" class="card-content1">
      <ul>
        <li *ngFor="let item of contentItems">{{ item }}</li>
      </ul>
    </mat-card-content>
    <mat-card-content *ngIf="isCategoryCard" class="card-content2">
      <ul>
        <li *ngFor="let item of contentItems">{{ item }}</li>
      </ul>
    </mat-card-content>
  </div>
  <div *ngIf="!isCategoryCard" class="hover-overlay">
    <div class="hover-text">
      <p [innerHTML]="hoverText"></p>
    </div>
    <div class="hover-buttons">
      <button class="view-more">
        <img src="assets/images/angle arrow small right.svg" alt=""> View more
      </button>
      <button class="request-quote">
        <img src="assets/images/angle arrow small right.svg" alt="">Product enquiry
      </button>
    </div>
  </div>
</mat-card> -->


<mat-card  
    class="product-info-card" [ngClass]="{'default-card': !isCategoryCard, 'category-card': isCategoryCard}" [routerLink]="isCategoryCard ? ['/category', id] : ['/product-details', id]">
  <div class="card-info">
    <img *ngIf="!isCategoryCard && newBadge" src="assets/images/new.svg" alt="New Badge" class="new-badge">
    <img mat-card-image [src]="productImage"  style="height: 180px; width: 180px;" alt="Product Image" class="logo">
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle *ngIf="!isCategoryCard">
      <img [src]="subtitleImage"  alt="company logo">
    </mat-card-subtitle>
    <mat-card-content *ngIf="!isCategoryCard" class="card-content1">
      <ul>
        <li *ngFor="let item of contentItems.slice(0, 3)">
           {{ item.name }} :
           <span *ngIf="item.fromValue"> {{ item.fromValue }} {{ item.fromUnit }}</span>
        </li>
      </ul>
    </mat-card-content>
    <mat-card-content *ngIf="isCategoryCard" class="card-content2">
      <ul>
        <li *ngFor="let item of contentItems.slice(0, 3)">
          {{ item.name }} :{{ item.fromValue }}
        </li>
      </ul>
    </mat-card-content>
  </div>
  <div *ngIf="!isCategoryCard" class="hover-overlay">
    <div class="hover-text">
      <p [innerHTML]="hoverText"></p>
    </div>
    <div class="hover-buttons">
      <button class="view-more">
        <img src="assets/images/angle arrow small right.svg" alt=""> View more
      </button>
      <button class="request-quote" 
      [routerLink]="['/user/products/productEnquiryDistributors', id]">
        <img src="assets/images/angle arrow small right.svg" alt=""> Product enquiry
      </button>
    </div>
  </div>
</mat-card>


import { Component, EventEmitter, Input, Output, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LeadsService } from 'src/app/services/super-admin/leads.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.scss']
})
export class EditLeadComponent implements OnInit {
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabEvent = new EventEmitter<number>();
  isLaptopView: boolean = false;
  leadsForm!: FormGroup;
  isOEMSelected: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private leadsService: LeadsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.initializeLeadsFormWithData(this.data);
    this.leadsForm.get('lead.accountType')?.valueChanges.subscribe(value => {
      this.isOEMSelected = value === 'OEM';
    });
    if (this.data?.lead?.accountType === "OEM") {
      this.isOEMSelected = true;
    }
  }

  initializeLeadsFormWithData(filledData: any) {
    this.leadsForm = this.fb.group({
      lead: this.fb.group({
        id: filledData?.lead?.id,
        active: filledData?.lead?.active,
        createdBy: filledData?.lead?.createdBy,
        createdOn: filledData?.lead?.createdOn,
        modifiedBy: filledData?.lead?.modifiedBy,
        modifiedOn: filledData?.lead?.modifiedOn,
        leadSource: filledData?.lead?.leadSource,
        leadState: filledData?.lead?.leadState,
        addedBy: filledData?.lead?.addedBy,
        accountType: filledData?.lead?.accountType,
        name: filledData?.lead?.name,
        companyName: filledData?.lead?.companyName,
        brandName: filledData?.lead?.brandName,
        establishedIn: filledData?.lead?.establishedIn,
        numberOfEmployees: filledData?.lead?.numberOfEmployees,
        websiteUrl: filledData?.lead?.websiteUrl,
        city: filledData?.lead?.city,
        state: filledData?.lead?.state,
        continent: filledData?.lead?.continent,
        country: filledData?.lead?.country,
        description: filledData?.lead?.description,
        address: filledData?.lead?.address,
        zipCode: filledData?.lead?.zipCode,
        crCopy: filledData?.lead?.crCopy,
        businessLicense: filledData?.lead?.businessLicense,
        europeanCertificate: filledData?.lead?.europeanCertificate,
        otherCertificates: filledData?.lead?.otherCertificates,
      }),

      leadBranchInfo: this.fb.array(filledData?.leadBranchInfo),
      leadContact: this.fb.array(filledData?.leadContact),
      leadFinancialInfo: this.fb.array(filledData?.leadFinancialInfo),
      leadProductInfo: this.fb.array(filledData?.leadProductInfo),
      leadRemarks: this.fb.array(filledData?.leadRemarks),
      leadDistributorInfo: this.fb.group({
        exstingDistributors: [
          '3fa85f64-5717-4562-b3fc-2c963f66afa6'
        ],
        newDistributors: this.fb.array(filledData?.leadDistributorInfo?.newDistributors),
      }),

    });
  }

  get leadDistributorInfo() {
    return this.leadsForm.get('leadDistributorInfo') as FormGroup;
  }

  onTabChange(tab: number) {
    this.selectedTabIndex = tab;
    this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    this.selectedTabEvent.emit(this.selectedTabIndex);
  }

  navigateNext() {
    const maxTabIndex = this.isOEMSelected ? 7 : 6;
    if (this.selectedTabIndex < maxTabIndex) {
      this.selectedTabIndex++;
      this.selectedTabEvent.emit(this.selectedTabIndex);
      this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    }
  }

  save() {
    const payload = this.leadsForm.value;
    if (this.leadsForm.get('lead.accountType')?.value === "Distributor") {
      delete payload.leadDistributorInfo;
    }
    this.leadsService.updateLead(payload).subscribe(
      response => {
        window.location.reload()
      },
      error => {
        console.error('Error:', error);
        if (error.status === 404) {
          console.error('Not Found:', error.error); // Log the detailed error response
        } else {
          console.error('An error occurred:', error); // Log other errors
        }
      }
    );
  }

  back() {
    if (this.selectedTabIndex > 0) {
      this.selectedTabIndex--;
      this.selectedTabEvent.emit(this.selectedTabIndex);
      this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    }
  }
}

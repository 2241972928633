import { OemData } from './../../../models/oem-models';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MasterDataService } from 'src/app/services/master-data.service';
import { HttpClient } from '@angular/common/http';
import { FlatTreeControl } from '@angular/cdk/tree';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Distributor } from 'src/app/models/distributor-model';
 
interface FoodNode {
  name: string;
  children?: FoodNode[];
  parent?: FoodNode | null; 
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-product-enquiry-distributors',
  templateUrl: './product-enquiry-distributors.component.html',
  styleUrls: ['./product-enquiry-distributors.component.scss']
})
export class ProductEnquiryDistributorsComponent implements OnInit{

  distributors: any[] = [];

  hovered: boolean = false;
  hoveredIndex: any;
  itemsPerPage = 15;
  prodID: string = '';
  signin: boolean = false;

  countriesViewMore: boolean = false
  distributorsCount: number = 0;

  allcountries :any;
  allstates :any;
  allcities : any;

  roleName: any;
  ngOnInit(): void {
    this.roleName = this.localStorageService.getItem('roleName');
    this.route.params.subscribe((p: any)=>{
      this.prodID = p.id;
      this.getdistributorDetailsList(p.id)
    })
    this.allCountries()
    this.allStates()
    this.allCities()    
  }
  
  TREE_DATA: FoodNode[] = [
    {
      name: 'By Country',
      children: [],
      parent: null,
    },
    {
      name: 'By State',
      children: [],
      parent: null,
    },
    {
      name: 'By City',
      children: [],
      parent: null,
    },
  ];

  reset(){
    this.countryselected = [];
    this.iscountrychecked = false;
    this.stateselected = [];
    this.isstatechecked = false;
    this.cityselected = [];
    this.iscitychecked = false;
    this.resetTreeNodes(this.treeControl.dataNodes);
  }
  resetTreeNodes(nodes: any[]): void {
    nodes.forEach(node => {
      node.selected = false;
      if (node.children) {
        this.resetTreeNodes(node.children);
      }
    });
  }
  private _transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  form: FormGroup = new FormGroup({});
  constructor(
    private fb: FormBuilder,
    private masterDataService: MasterDataService,
    private http: HttpClient,
    private oemService: OemService,
    private router: Router, 
    private readonly route: ActivatedRoute,
    private distributorService: DistributorService,
    private localStorageService: LocalStorageService,
  ) {
    localStorageService.getUserDetails().subscribe((res: any)=>{
      this.signin = res      
    })
    this.dataSource.data = this.TREE_DATA;
    this.form = this.fb.group({});
    this.initializeFormControls(this.TREE_DATA);
    
  }
  initializeFormControls(nodes: FoodNode[], parentPath: string = '') {
    nodes.forEach(node => {
      const path = parentPath ? `${parentPath}.${node.name}` : node.name;
      this.form.addControl(path, new FormControl(false));
      if (node.children) {
        this.initializeFormControls(node.children, path);
      }
    });
  }

  countries: any[]=[]

  countryselected: any[] = [];
  stateselected: any[] = [];
  cityselected: any[] = [];
  iscountrychecked :boolean = false;
  isstatechecked :boolean = false;
  iscitychecked :boolean = false;

  selectedcheckbox(event: any, node : any ): void {
    const selectedValue = event.source.value;
    const isChecked = event.checked;
    if (isChecked) {
      this.iscountrychecked = true;
      if (!this.countryselected.includes(selectedValue)) {
        this.countryselected.push(selectedValue);
      }
    } else {
      const index = this.countryselected.indexOf(selectedValue);
      if (index > -1) {
        this.countryselected.splice(index, 1);
      }
      if (this.countryselected.length === 0) {
        this.iscountrychecked = false;
      }
    }
  }
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  hasNoContent = (_: number, _nodeData: FoodNode) => _nodeData.children === null;

  getdistributorDetailsList(id: string){
    this.distributorService.getdistributorDetailsList(id).subscribe((res: any)=>{
      this.distributors = res.data;
      this.distributorsCount = this.distributors?.length || 0;
  })};

  viewMore(node: any){
    if(node.name==='By Country'){
      this.countriesViewMore = true;
    }
  }

  countiresList: string[] = [];

  allCountries() {
    this.distributorService.getCountries(0, 10).subscribe(
      (data: any) => {
        if (data.status === 'OK' && data.data && data.data.length > 0) {
          this.countries.push(...data.data);
          const countryNode = this.TREE_DATA.find(node => node.name === 'By Country')
          if (countryNode) {
            this.allcountries = this.countries.map(country => country.countryName);
            countryNode.children = this.allcountries.map((countryName: any) => ({ name: countryName }));
            this.dataSource.data = this.TREE_DATA;
          } else {
            console.error('Country node not found in TREE_DATA.');
          }
        } else {
          console.error('Failed to fetch countries:', data.message);
        }
      },
      error => {
        console.error('Error fetching countries:', error);
      }
    );
  }

  allStates() {
    this.masterDataService.getStatesList(0, 20).subscribe(
      (data: any) => {
        if (data.status === 'OK' && data.data && data.data.length > 0) {
          this.allstates = data.data;

          const stateNode = this.TREE_DATA.find(node => node.name === 'By State')//?.children?.find(child => child.name === 'By State');
          if (stateNode) {
            this.allstates = this.allstates.map((state: any) => state.stateName);
            stateNode.children = this.allstates.map((stateName: any) => ({ name: stateName }));
            this.dataSource.data = this.TREE_DATA;
          } else {
            console.error('State node not found in TREE_DATA.');
          }
        } else {
          console.error('Failed to fetch states:', data.message);
        }
      },
      error => {
        console.error('Error fetching states:', error);
      }
    );
  }

  allCities() {
    this.masterDataService.getCitiesList(0, 20).subscribe(
      (data: any) => {
        if (data.status === 'OK' && data.data && data.data.length > 0) {
          this.allcities = data.data;
          const cityNode = this.TREE_DATA.find(node => node.name === 'By City')//?.children?.find(child => child.name === 'By City');
          if (cityNode) {
            this.allcities = this.allcities.map((city: any) => city.cityName);
            cityNode.children = this.allcities.map((cityName: any) => ({ name: cityName }));
            this.dataSource.data = this.TREE_DATA;
          } else {
            console.error('City node not found in TREE_DATA.');
          }
        } else {
          console.error('Failed to fetch Cities:', data.message);
        }
      },
      error => {
        console.error('Error fetching cities:', error);
      }
    );
  }

  paginatedItems: { name: string, logo: string }[] = [];
    onPageChanged(paginatedData: any[]): void {
      this.paginatedItems = paginatedData;
    }

    noDistributors(){
      this.router.navigate(['/user/home'],{
        state: { isProductEnquiry: true}
      }); 
    }
  }

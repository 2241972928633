export const environment = {
  production: false,
  serverScheme: 'https://',
  serverDns: 'dev.medworldexpo.com',
  hostname: 'api-dev.medworldexpo.com',
  MASTERDATA_BASE_URL: '',
  ONBOARDING_BASE_URL: '',
  AUTH_BASE_URL: '',
  WEBSOCKET_URL: 'https://dev.medworldexpo.com/onboarding/ws',
};

// Now define URLs
environment.MASTERDATA_BASE_URL = `${environment.serverScheme}${environment.serverDns}/mdm/api/masterdata/`;
environment.ONBOARDING_BASE_URL = `${environment.serverScheme}${environment.serverDns}/onboarding`;
environment.AUTH_BASE_URL = `${environment.serverScheme}${environment.serverDns}/auth/api/`;

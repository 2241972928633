import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { noWhitespaceValidator } from 'src/app/models/product-settings-model';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { SubscriptionsService } from 'src/app/services/super-admin/subscriptions.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-add-edit-subscrptions',
  templateUrl: './add-edit-subscrptions.component.html',
  styleUrls: ['./add-edit-subscrptions.component.scss']
})
export class AddEditSubscrptionsComponent implements OnInit{
  addEditSubscriptionForm: FormGroup;
  selectedFile: string | undefined;
  subscriptionId?: any;
  imageURL: string | undefined;
  isViewmode: boolean = false;
  showImage:string | undefined;

  constructor(
    private dialogRef: MatDialogRef<AddEditSubscrptionsComponent>,
    private formBuilder: FormBuilder,
    private sweetAlertService: SweetAlertService,
    private fileUploadService: FileUploadService,
    private subscriptionsService: SubscriptionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.subscriptionId = data?.subscriptionId;
    this.isViewmode = data?.isViewMode || false;
    this.addEditSubscriptionForm = this.formBuilder.group({
      name: ['', [Validators.required, noWhitespaceValidator()]],
      monthlyPrice: ['', [Validators.required, noWhitespaceValidator()]],
      annualPrice: ['', [Validators.required, noWhitespaceValidator()]],
      trialDays: ['', [Validators.required, noWhitespaceValidator()]],
      description: ['', [Validators.required, noWhitespaceValidator()]],
      active: ['true', Validators.required],
      image: ['']
    });

    if (this.subscriptionId) {
      this.loadSubscriptionData();
    }
  }

  ngOnInit(): void {
    if(this.isViewmode){
      console.log();
      
    this.addEditSubscriptionForm.disable();
    }
  }

  loadSubscriptionData(): void {
    this.subscriptionsService.getSubscriptionById(this.subscriptionId).subscribe({
      next: (subscription) => {
        const subscriptionData = subscription.data;
        this.addEditSubscriptionForm.patchValue(subscriptionData);
        this.imageURL = this.addEditSubscriptionForm.get('image')?.value;
        const imageUrl = this.imageURL || subscriptionData.image;
        this.selectedFile = this.getFileNameFromUrl(imageUrl);
        this.showImage=this.addEditSubscriptionForm.get('image')?.value
        console.log("Selected file is", this.selectedFile);
        this.addEditSubscriptionForm.get('active')?.patchValue(subscriptionData.active ? 'true' : 'false');
      },
      error: (error) => {
        this.sweetAlertService.showError('Error', error.error.message);
      }
    });
  }

  onFormSubmit(): void {
    if (this.addEditSubscriptionForm.valid) {
      if (this.subscriptionId) {
        this.updateSubscription();
      } else {
        this.addSubscription();
      }
    }
  }

  addSubscription(): void {
    this.addEditSubscriptionForm.patchValue({ image: this.imageURL });
    this.subscriptionsService.addSubscription(this.addEditSubscriptionForm.value).subscribe({
      next: (response) => {
        this.sweetAlertService.showSuccess('Success', 'Subscription added successfully');
        this.dialogRef.close(this.addEditSubscriptionForm.value);
      },
      error: (error) => {
        this.sweetAlertService.showError('Error', error.error.message);
      }
    });
  }

  updateSubscription(): void {
    this.addEditSubscriptionForm.patchValue({ image: this.imageURL });
    this.subscriptionsService.updateSubscription(this.subscriptionId, this.addEditSubscriptionForm.value).subscribe({
      next: (response) => {
        this.sweetAlertService.showSuccess('Success', 'Subscription updated successfully');
        this.dialogRef.close(this.addEditSubscriptionForm.value);
      },
      error: (error) => {
        this.sweetAlertService.showError('Error', error.error.message);
      }
    });
  }

  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || ''; // Extracting file name from URL
  }

  uploadLogo(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.selectedFile = file.name;
      this.uploadFile(file).subscribe(
        (url: string) => {
          this.imageURL = url;
          this.addEditSubscriptionForm.patchValue({ image: url });
        },
        (error: any) => {
          console.error('Error uploading file', error);
        }
      );
    }
  }

  removeFile(event: Event): void {
    event.preventDefault();
    this.selectedFile = '';
    this.addEditSubscriptionForm.patchValue({ image: '' });
  }

  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/subscriptions/');
    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; // Assuming response.data contains the URL of the uploaded file
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

export const ACCESS_TOKEN = 'accessToken';
export const ID_TOKEN = 'idToken';
export const REFRESH_TOKEN = 'refreshToken';
export const ROLE_NAME = 'roleName';
export const USER_VERIFIED = 'userVerified';
export const ACCOUNT_NAME = 'accountName';
export const EXPIRES_IN = 'expiresIn';
export const ROLE_OEM ="OEM"   ;
export const OEM_DETAILS = 'oemDetails';
export const USER_ID="userId";
export const OEM_ID="oemId";
export const IS_PROSPECT="isProspectMailsent";
export const DISTRIBUTOR_ID="distributorId";
export const PREFERENCES='preferences';
export const GUEST_TOKEN = 'x-guest-token';
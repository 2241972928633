import { Component, OnInit } from '@angular/core';
import { DasboardService } from 'src/app/services/super-admin/dasboard.service';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.css']
})
export class TermsandconditionsComponent implements OnInit {

  content: string = '';

  constructor(private dashboardService: DasboardService) { }

  ngOnInit(): void {
    this.fetchTermsAndConditions();
  }

  fetchTermsAndConditions(): void {
    this.dashboardService.getContent('terms').subscribe( (response) => {
        this.content = response.data.content;
      },
    );
  }
}

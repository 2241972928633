<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Certifications & Licenses</h2>
        <div class="d-flex">
        <img class="edit-profile mx-1" (click)="addCertificationsAndLicense()"
            *ngIf="(endCustomerData?.certifications && this.endCustomerData.certifications.length>0) || showEditForm" alt=""
            src="../../assets/images/add.svg">
        <img class="edit-profile " (click)="editCertificationsAndLicense()"
            *ngIf="(endCustomerData?.certifications && this.endCustomerData.certifications.length>0) &&!showEditForm" alt=""
            src="../../assets/images/editprofile.svg">
            </div>

    </div>
    <div class="mt-2" *ngIf="!(endCustomerData?.certifications && this.endCustomerData.certifications.length>0) && !showEditForm ">
        <button (click)="addCertificationsAndLicense()" class="profile-btn">
            <mat-icon >add</mat-icon> <p class="ps-2">Add Certifications & Licenses</p></button>
    </div>
    <div class="mt-2"
        *ngIf="(endCustomerData?.certifications && this.endCustomerData.certifications.length>0) && !showEditForm">
    <div  class="mt-2 pt-1" *ngIf="showPersonalInfo">
        <div *ngFor="let item of data">
            <div>
                <div class="d-flex align-items-center mt-2">
                    <img class="card-icon me-3" src="{{ certificateIcon }}" alt="Card Profile" />
                    <div>
                        <div style="font-weight: 600;">{{ item.name }}</div>
                        <a style="text-decoration: none;" href="{{item.certificate}}" target="_blank" *ngIf="item.fileName">
                            <div style="color: #0d87c8;text-decoration: underline">View Certificate</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
<div *ngIf="!showPersonalInfo">
    <app-mwe-table [showPagination]="false" [columns]="certificateColumns" [showHeader]="false"
    [data]="data" (selectedAction)="selectedAction($event)"></app-mwe-table>
</div>
        
    </div>

    <form [formGroup]="certificationsForm" class="mt-2" *ngIf="showEditForm">
        <div formArrayName="certifications">
            <div *ngFor="let certificationForm of certifications.controls;let i=index;">
                <div [formGroupName]="i">
                    <div class="row mb-4">
                        <div class="col-lg-12">
                            <div class="card p-4 ">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Name</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput  formControlName="name">
                                                <mat-error class="error-message error-msg"
                            *ngIf="certificationForm.get('name')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is
                            Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Certificate</p>
                                        </div>
                                        <div class="d-flex">
                                            <span class="file-link m-2">{{certificationForm.get('fileName')?.value}}</span>
                                            <input class="d-none" type="file" id="{{'uploadCertificate'+i}}" accept=".png,.jpg,.jpeg,.pdf" (change)="uploadCertificate($event,i)" />
                                            <label class="mwe-button mwe-button--primary upload med-world-basic-font med-world-basic-font__medium ps-3 pe-3" for="{{'uploadCertificate'+i}}"
                                                >Upload
                                               
                                            </label>

                                            <button  class="mx-4 mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="deleteCertificationsAndLicense(i)">Delete</button>
                                        </div>
                                        <div>
                                            <mat-error class="error-message error" style="margin-top: -1px;"
                                    *ngIf="certificationForm.get('certificate')?.invalid &&
                                     certificationForm.get('certificate')?.touched&&
                                     certificationForm.get('certificate')?.hasError('required')">
                                     <img class="error-icon"
                                     loading="lazy" alt="" src="./../../assets/images/alert.svg" />Certificate
                                                                       is Required</mat-error>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="profile-action " *ngIf="showEditForm">
            
    
        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
    
    
        
    
    
        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
    
    
       
      <!--   <span *ngIf="showErrorMsg">
            <mat-error class="p-2 error-message error-msg"
            ><img class="error-icon"
                loading="eager" alt="" src="./../../assets/images/alert.svg" /> please enter all required fields</mat-error>
        </span> -->
    </div>
</div>
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';
import { BreadcrumbService } from './shared/breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class CareerService {

  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  userId: any;
  private jobId: any;
  private applicantId: any;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private breadcrumbService: BreadcrumbService) {
    this.localStorageService.getUserDetails().subscribe(data => {
      this.userId = data?.userId;
    })
  }

  executeGetRequestURL(passedurl: string, queryparams: any): Observable<any> {
    let queryParameters = new HttpParams();
    if (queryparams) {

      Object.keys(queryparams).forEach(key => {
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/json")

    return this.http.get(this.ONBOARDING_BASE_URL + passedurl, { headers: headers, params: queryParameters });
  }

  public executePostRequestForAuth(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
    return this.http.post(this.AUTH_BASE_URL + url, body, { headers: headers });
  }

  public executePostRequest(url: string, body: any, userRoleById?: any): Observable<any> {
    let headers = new HttpHeaders().set("Accept", "application/json");
    const userId = this.getUserRoleById(userRoleById);
    const userRole = this.getUserRole();
      if (userId !== null && userId !== undefined && userId !== '0') {
      headers = headers.set('userId', userId);
    }
    if (userRole !== null && userRole !== undefined && userRole !== '0') {
      headers = headers.set('userRole', userRole);
    }
    return this.http.post(this.ONBOARDING_BASE_URL + url, body, { headers: headers });
  }

  public executePutRequest(url: string, body: any, userRoleById?: any): Observable<any> {
    let headers = new HttpHeaders().set("Accept", "application/json");
  
    const userId = this.getUserRoleById(userRoleById);
    const userRole = this.getUserRole();
     if (userId) {
      headers = headers.set('userId', userId);
    }
    if (userRole) {
      headers = headers.set('userRole', userRole);
    }
  
    return this.http.put(this.ONBOARDING_BASE_URL + url, body, { headers: headers });
  }
  

  private getUserRoleById(userRoleById: any) {
    return (userRoleById !== undefined && userRoleById !== null) ? userRoleById : this.userId;
  }

  private getUserRole() {
    return this.checkForNullValueInLocalStorage('roleName');
  }

  getUserIdBasedOnRole() {
    const roleName = this.getUserRole();
    let userIdByRole = null;
    switch (roleName) {
      case 'DISTRIBUTOR':
        userIdByRole = this.checkForNullValueInLocalStorage('distributorId');
        break;
      case 'OEM':
        userIdByRole = this.checkForNullValueInLocalStorage('oemId');
        break;
      case 'USER':
        userIdByRole = this.checkForNullValueInLocalStorage('userId');
        break;
    }
    return userIdByRole;
  }

  private checkForNullValueInLocalStorage(value: any) {
    const valueString = localStorage.getItem(value);
    return valueString ? JSON.parse(JSON.stringify(valueString)) : null;
  }

  public executePatchRequest(url: string, body: any, userRoleById?: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId', this.getUserRoleById(userRoleById))
      .set('userRole', this.getUserRole());
    return this.http.patch(this.ONBOARDING_BASE_URL + url, body, { headers: headers });
  }

  executeDeleteRequestURL(passedurl: string, queryparams: any, userRoleById?: any): Observable<any> {
    let queryParameters = new HttpParams();
    if (queryparams) {

      Object.keys(queryparams).forEach(key => {
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId', this.getUserRoleById(userRoleById))
      .set('userRole', this.getUserRole());;
    return this.http.delete(this.ONBOARDING_BASE_URL + passedurl, { headers: headers, params: queryParameters });
  }

  setJobId(jobId: any) {
    this.jobId = jobId;
  }

  getJobId() {
    return this.jobId;
  }

  setApplicantId(applicantId: any) {
    this.applicantId = applicantId;
  }

  getApplicantId() {
    return this.applicantId;
  }

  setCareersBreadCrumbs(breadcrumbs: string[]) {
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordChangeRequest, PasswordResetRequest, User } from 'src/app/models/authData';
import { AuthService } from 'src/app/services/auth.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  emailSent: boolean = false;
  emailVerifcationCode: string = "";
  showError = false;
  errorMessage = '';
  email = '';
  expiryTime : Date =  new Date();
  isLinkExpired = false;
  linkexpired = false;
  passwordResetRequest: PasswordResetRequest = {
    email: '',
    verificationCode: '',
    newpassword: '',
  };  
  shownewPasswordError: boolean = false;
  showConfirmNewPasswordError: boolean = false;
  
  newPassword: string = "";
  confirmNewPassword: string = "";

  constructor(private authService: AuthService, public router: Router, private route: ActivatedRoute, private sweetAlertService: SweetAlertService) { }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        this.passwordResetRequest.verificationCode = params['confirmation_code'];
        this.passwordResetRequest.email = params['user_name'];
        const expiryTimeInMillis = Number(params['expiry_time']);
        const expiryTime = new Date(expiryTimeInMillis);
        const timeDifference = expiryTime.getTime() - Date.now();
        this.isLinkExpired = timeDifference < 0;

        // Redirect after 10 minutes regardless of link expiry
        setTimeout(() => {
            this.isLinkExpired = true; // Change '/redirect-route' to the desired route
        },  10*60*1000); // 10 minutes in milliseconds
    });
}

  onSubmit() {
    console.log("error", this.shownewPasswordError, this.showConfirmNewPasswordError);
    this.shownewPasswordError = !this.newPassword;
    this.showConfirmNewPasswordError = !this.confirmNewPassword;
    if (this.shownewPasswordError || this.showConfirmNewPasswordError) {      
      this.showError = true;
      return;
    }
    if (this.newPassword != this.confirmNewPassword) {
      this.showError = true;
      this.errorMessage = 'Password and confirm password does not match'
      return;
    }
    this.passwordResetRequest.newpassword = this.newPassword;

    this.authService.resetPassword(this.passwordResetRequest).subscribe(response => {
      if (response.status === 'OK') {
        this.emailSent = true; 
      }
    },
      (error: any) => {
        this.showError = true;
        this.errorMessage = error.error.message;
        this.sweetAlertService.showError('Error', 'Failed to reset password');
      });
  }
  userName: any;
  password: any;
  
  rememberMeFlag: boolean = true;

  user: User = { email: '', password: '' } as User;
  ngOnChanges() {
    console.log("onChanges");
    this.showError = false;
    this.errorMessage = '';
  }
  onUserNameChange(): void {
    this.showError = false;
    this.errorMessage = '';
  }

  onPasswordChange(): void {
    this.showError = false;
    this.errorMessage = '';
  }

  clearError() {
    this.showError = false;
    this.errorMessage = '';
  }
  passwordValidation(password: string): string[] {
    const errors = [];
    const capitalRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /^(?!\s+)(?!.*\s+$)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

    if (typeof password !== "undefined" && password != null && password.length > 0) {
        if (password.length < 8 ) {
            errors.push('Password must be at least 8 characters');
        }
        else if (!capitalRegex.test(password)) {
            errors.push('Password must contain at least one capital letter');
        }
       else  if (!numberRegex.test(password)) {
            errors.push('Password must contain at least one number');
        }
        else if (!specialCharRegex.test(password)) {
            errors.push('Password must contain at least one special character');
        }
    }
    return errors;
}

}

<div class="bg-container">
  <div class="add-catalog-header">{{data?.actionType === 'view' ? 'View Catalog' : (data?.actionType === 'edit' ? 'Edit Catalog' : 'Add Catalog') }} </div>
  <div class="catalog-form">
    <form [formGroup]="addCatalogForm">
      
      <!-- <div class="form-field">
        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
          Catalog Name
        </p>
        <mat-form-field appearance="outline" class="fields-input" >
          <input matInput [readonly]="isViewmode" formControlName="title" required />
        </mat-form-field>
      </div> -->

      <div class="form-field">
        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color">
          Upload Catalog
        </p> 

        <div *ngIf="fileName===''" class="file-container">
          <label class="d-flex justify-content-center  med-world-basic-font med-world-basic-font__medium secondary_black_color text" for="upload" mat-raised-button>
            <img alt="" src="./../../assets/images/photo-add.svg" > Drag files here to upload (or) Select Files
            <input type="file" accept=".pdf,.jpg,.jpeg,.png,.img" id="upload" style="display: none;" [readonly]="isViewmode"  (change)="selectFile($event)" />  
          </label>   
        </div>
        <div class="d-flex align-items-center" style="gap: 8px; margin-top: 10px;">
          <div *ngIf="fileName !== ''" class="d-flex align-items-center">
            <img *ngIf="isImage" [src]="imageUrl" style="width:4rem; height: 4rem; margin-right: 1rem;" alt="Selected Image">
            <img *ngIf="!isImage" src="../../assets/images/pdf-icon.svg" style="width:4rem; height: 4rem; margin-right: 1rem;" alt="PDF Icon">

            <ng-container>
              <button class="btn border-0" mat-icon-button *ngIf="!isViewmode" (click)="clearSelectedFile()">
              
                <mat-icon>close</mat-icon>
              </button>
          </ng-container>
          </div>


        <!-- <span *ngIf="isViewmode">
 <ng-container *ngIf="!isImage">
    <img style="width:4rem; height: 4rem; margin-right: 1rem;" [src]="catalogImage" alt="Catalog Image">
  </ng-container>
  <ng-container>
    <img style="width:4rem; height: 4rem; margin-right: 1rem;" src="../../assets/images/pdf-icon.svg" alt="pdf Image">
  </ng-container>
</span> -->


        </div>
      </div>

      <label class="d-flex align-items-center" style="gap: 8px; margin-top: 10px;">
        <input
          type="checkbox"
          style="margin: 0; height: 20px; width: 20px; position: relative; min-height: 24px"
          formControlName="isAssociated"
          [disabled]="true"
        />
        <span class="medworld-label-inputs">Attach to Product</span>
      </label>

      <div *ngIf="addCatalogForm.get('isAssociated')?.value" class="form-field" style="margin-top: 10px;">
        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
          Select Product to attach
        </p>
        <mat-form-field appearance="outline" class="fields-input" [readonly]="isViewmode">

            <mat-select formControlName="productInfoId" [readonly]="isViewmode"  required >
              <mat-option *ngFor="let node of allProductList$ | async" [value]="node?.id">
                {{ node?.name }}
              </mat-option>


            </mat-select>
            <!-- <mat-autocomplete #auto="matAutocomplete"  formControlName="productInfoId" >
              <mat-option *ngFor="let node of allProductList$ | async" [value]="node?.id">
                {{ node?.name }}</mat-option>
          </mat-autocomplete> -->

          </mat-form-field>
        
      </div>

      <div class="d-flex justify-content-left mt-3">
        <ng-container *ngIf="data?.actionType !== 'view'">
        <button 
          class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3 mr-3"
          (click)="onSubmit()"
          type="submit" style="margin-right: 10px;" *ngIf="!isViewmode">
          Save
        </button>
        </ng-container>
        <button class="mwe-button mwe-button--secondary ps-3 pe-3 "  [mat-dialog-close]="false" type="button">Cancel</button>
      </div>
      
    </form>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-product-file-viewer',
  templateUrl: './product-file-viewer.component.html',
  styleUrls: ['./product-file-viewer.component.scss']
})
export class ProductFileViewerComponent implements OnInit{

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<ProductFileViewerComponent>,
  
  )  {
   
  }
  ngOnInit(): void {
    console.log(this.data);
    
  }

  onClose(): void {
    this.dialogRef.close();
  }

}

import { Component, Input, OnChanges, OnInit,  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditAllProductsComponent } from './add-edit-all-products/add-edit-all-products.component';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { ExportComponent } from './export/export.component';
import { ImportComponent } from './import/import.component';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit{ 
  exportData : any;
  searchValue: string | null = null;
  allProductData: any[] = [];
  showNoRecordsFounds=false
  isRemovedFilter = false;
  isFilter = false;
  isSearch = false;
  searchKeyword = ''
  filterObject:any;
  filterPayload:any;
  selectedFilters: any[] = [];
  filteredData: any[] = [];
  showViewProduct:boolean = false;
  selectedViewData:any;
  searchControl: FormControl = new FormControl('');
  dataSource = new MatTableDataSource(this.allProductData);

  pageIndex: number = 0;
  pageSize: number = 10;


  constructor(
    private dialog: MatDialog,
    private sweetAlertService : SweetAlertService,
    private allProductService : AllProductService
  ) {
  }

  ngOnInit(): void {
    this.getAllProductsInfo(); // Fetch initial products
    this.searchControl.valueChanges
    .pipe(
      debounceTime(300) // Adjust the time as needed (300ms in this example)
    )
    .subscribe(value => {
      if (value) {       
        this.searchKeyword = value.toLowerCase(); 
        this.applySearch(value.toLowerCase());
      } else {
        this.searchKeyword = value;
        this.loadDefault();
      }
    });
  }
  

  onPageChanged(event: { pageIndex: number, pageSize: number }): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize; 
    if(this.isSearch){
      console.log('search');
      this.getAllProductsBySearchKeyword(this.searchKeyword);
    } else if (this.isFilter) {
      console.log('filter');
      this.getAllProductInfoByFilter(); // Apply filters and pagination
    } else {
      console.log('default');
      this.getAllProductsInfo(); // Fetch paginated results without filters
    }
  }

  loadDefault(): void {
    this.pageIndex = 0; // Reset to the first page
    this.isFilter = false;
    this.isSearch = false;

    this.getAllProductsInfo();
  }

  // Apply search and reset pagination
  applySearch(keyword: string): void {
    this.pageIndex = 0; // Reset to the first page
    this.isSearch = true;
    this.isFilter = false;
    this.searchKeyword = keyword;
    this.getAllProductsBySearchKeyword(this.searchKeyword );
  }

  getAllProductsBySearchKeyword(filterValue: string): void {
    this.filteredData = [];
    this.showNoRecordsFounds = false;
    this.allProductService.searchProducts(filterValue, this.pageIndex, this.pageSize).subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          console.log(response.data);
          this.allProductData = response.data;
          this.allProductData = response.data.map((item: any, index: number) => ({ ...item, srNo: index + 1 }));
          this.filteredData = this.allProductData;
          this.dataSource = new MatTableDataSource(this.filteredData);
        } else {
          this.showNoRecordsFounds = true;
        }
      },
      error: (error) => {
        console.error('Error searching product info data:', error);
      }
    });
  }
  

  openFilter() {
    const dialogRef = this.dialog.open(ProductFilterComponent, {
      height:'auto',
      width : 'auto',
      data:this.filterObject,
      disableClose:true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.filterObject=result;
      if(this.filterObject){
        this.isSearch = false;
        this.pageIndex = 0;
        this.getAllProductInfoByFilter();
      }else{
        this.selectedFilters = [];
        this.getAllProductsInfo();
      }
    });
  }
  
  openImport() {
    const data = this.prepareExportData();
    const dialogRef = this.dialog.open(ImportComponent, {
      height:'auto',
      width : 'auto',
      data: {
        csvData: this.convertToCSV(data)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.getAllProductsInfo();
    });
  }

  openExportData() {
    const data = this.prepareExportData();
    console.log(this.exportData)
    const dialogRef = this.dialog.open(ExportComponent, {
      height:'auto',
      width : 'auto',
      data: {
        csvData: this.convertToCSV(data)
      }
    });

    dialogRef.afterClosed().subscribe({});
  }

  prepareExportData() {
    return this.filteredData.map(item => ({
      productName: item.name,
      oemName: item.oemName,
      categoryName: item.categoryName,
      createdBy: item.createdByName,
      status: item.active ? 'Active' : 'Inactive',
    }));
  }

  convertToCSV(data: any[]): string {
    if (!data.length) {
      return '';
    }

    const camelCaseToTitleCase = (camelCase: string): string => {
      const result = camelCase.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    const header = Object.keys(data[0])
      .map(key => camelCaseToTitleCase(key))
      .join(',');
    const rows = data.map(item => Object.values(item).join(','));
    return `${header}\n${rows.join('\n')}`;
  }

  getAllProductsInfo() { 
    this.isFilter = false;
    this.isSearch = false;
    this.filteredData=[];
    this.showNoRecordsFounds=false;
    this.allProductService.getAllProducts(this.pageIndex, this.pageSize).subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          console.log(response.data)
          this.allProductData = response.data;
          this.allProductData = response.data.map((item, index) => ({ ...item, srNo: index + 1 }));
          this.filteredData = this.allProductData;
          this.dataSource = new MatTableDataSource(this.filteredData);
        } else {
          this.showNoRecordsFounds=true;
        }
      },
      error: (error) => {
        console.error('Error fetching product info data:', error);
      }
    });
  }

  getAllProductInfoByFilter(){
    this.filteredData=[];
    this.showNoRecordsFounds=false;
    const filterObj = this.filterObject;
    const payload = {
      oemId:  filterObj.oemId ? filterObj.oemId.id : '',
      categoryId:  filterObj.categoryId.id,
      status : filterObj.status,
      createdBy:filterObj.createdBy.id
   };

    const toDisplay = {
      oemName:  filterObj.oemId ? filterObj.oemId.name : '',
      categoryName:  filterObj.categoryId.name,
      status : filterObj.status,
      createdByName:filterObj.createdBy.name
   };

   this.filterPayload = payload;
   this.applyFilters(toDisplay)
   this.filterProduct(payload);
  }

  filterProduct(payload:any) {
    this.isFilter = true;
    this.allProductService.filterProduct(payload, this.pageIndex, this.pageSize).subscribe(response=>{
      if(response && response?.data && response.data.length>0){
        this.allProductData = response.data;
        this.allProductData = response.data.map((item: any, index: number) => ({ ...item, srNo: index + 1 }));
        this.filteredData = this.allProductData;
        this.dataSource = new MatTableDataSource(this.filteredData);
        this.showNoRecordsFounds=false;
      }else{
        this.showNoRecordsFounds=true;
      }
    },error=>{
     console.log(error);
    })
  }

  applyFilters(filterObj:any): void {
    if(this.isRemovedFilter) {
      this.selectedFilters = this.selectedFilters.filter(f => f.field !== filterObj.field);
      this.isRemovedFilter = false;
      const payloadAfterRemoved = this.filterPayload;

      switch (filterObj.field) {
        case 'oemName':
          payloadAfterRemoved.oemId = undefined; 
           this.filterObject['oemId'] = '';
          break;
        case 'categoryName':
          payloadAfterRemoved.categoryId = undefined;
          this.filterObject['categoryId'] = '';
          break;
        case 'status':
          payloadAfterRemoved.status = undefined;
          this.filterObject['status'] = '';
          break;
        case 'createdByName':
          payloadAfterRemoved.createdBy = undefined;
          this.filterObject['createdBy'] = '';
        break;
      }
      console.log('Payload After Removed', payloadAfterRemoved);
      this.filterProduct(payloadAfterRemoved);

      if(this.selectedFilters.length === 0) {
          this.getAllProductsInfo();
      } else {
        this.filterProduct(payloadAfterRemoved);
      }
    } else {
      this.selectedFilters = [];
      for (const key in filterObj) {
        if (filterObj[key]) {
          this.selectedFilters.push({ field: key, value: filterObj[key] });
        }
      }
    }
    console.log('selectedFilters' , this.selectedFilters);
  }

  clearFilters(): void {
    this.pageIndex = 0;
    this.selectedFilters = [];
    this.filterObject = {};
    console.log('this.pageIndex', this.pageIndex);
    
    this.getAllProductsInfo()
  }

  removeFilter(filter:any): void {
    this.isRemovedFilter = true;
    this.applyFilters(filter);
  }

  openAddEditForm() {
    const dialogRef = this.dialog.open(AddEditAllProductsComponent, {
      height:'auto',
      width : 'auto'
      
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getAllProductsInfo();
        }
      },
    });
  }

  edit(data: any) {
    console.log(data)
    const dialogRef = this.dialog.open(AddEditAllProductsComponent, {
      width: 'auto',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getAllProductsInfo();
        }
      },
    });
  }
  
  delete(id: number) {
    this.sweetAlertService.showConfirmation('Are you sure?', 'You will not be able to recover the deleted record!', 'Yes, Delete It', 'Cancel')
    .then((result) => {
      if (result.value) {
        this.allProductService.deleteProduct(id).subscribe(
          (response) => {
            console.log(response)
           if(response.data){
            this.getAllProductsInfo();
            this.sweetAlertService.showSuccess('Removed', 'Product Info Deleted Successfully !!');
           } else {
            this.sweetAlertService.showError('Failed', 'Failed to delete product info !!');
           }
          },
          (error) => {
            console.log("error", error);
            this.sweetAlertService.showError('Failed', 'Failed to delete product info !!');
          }
        );
      } else if(result.dismiss === this.sweetAlertService.CANCEL){
        this.sweetAlertService.showError('Cancelled', 'Item is safe.');
      }
    });
  }

  columns: ListColumn[] = [
    { name: 'Sr No', property: 'srNo',visible:true,isModelProperty:false},
    {name:'Name of Product',property:'name',visible:true,isModelProperty:true,isText: true},
    { name: 'OEM Company', property: 'oemName', visible: true, isModelProperty: true ,isText: true},
    { name: 'Category Name', property: 'categoryName', visible: true, isModelProperty: true ,isText: true},
    { name: 'Created By', property: 'createdByName', visible: true, isModelProperty: true ,isText: true},
    {name:'Status', property:'active', visible:true, isModelProperty:true, isStatus:true},
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true  ,hasAction: true,
    actions:[
            {imageUrl:'assets/images/eye-visble.svg', isImage:true, actionType:MweConstants.viewAction},
            {imageUrl:'assets/images/edit.svg', isImage:true, actionType:MweConstants.editAction,hasCondition:true,conditionProperty:'active',conditionValue:true},
            {imageUrl:'assets/images/bin.svg', isImage:true, actionType:MweConstants.deleteAction,hasCondition:true,conditionProperty:'active',conditionValue:true}]
          },
  ] as ListColumn[];
  data:any[]=[];

  selectedAction(event:any){
    switch (event.action) {
      case MweConstants.addAction:
        this.openAddEditForm()
        break;
      case MweConstants.editAction:
        this.edit(event.row);
        break;
      case MweConstants.deleteAction:
        this.delete(event.row.id);
        break;
      case MweConstants.filterAction:
        this.openFilter();
        break;
      case MweConstants.exportAction:
        this.openExportData();
        break;
      case MweConstants.importAction:
        this.openImport();
        break;
      case MweConstants.viewAction:
        this.selectedViewData=event.row;
        this.showViewProduct = true;
        break;
      default:
        break;
    }
  }

  close(event:any){
    this.showViewProduct=false;
    this.selectedViewData=null;
    this.getAllProductsInfo();
  }

  closeToBack(event:any){
    this.showViewProduct=false;
    this.selectedViewData=null;
    this.getAllProductsInfo();
   }

}

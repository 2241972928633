import { Pipe, PipeTransform } from '@angular/core';
import { Signup } from 'src/app/models/SignupModels';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      const phoneCode = (item.phoneCode as Signup)?.phoneCode;
      if (phoneCode) {
        return phoneCode.toLowerCase().includes(searchText);
      }
      return false;
    });
  }
}

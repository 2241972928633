import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PublicationsProfileComponent } from './publications-profile/publications-profile.component';
import { AwardsProfileComponent } from './awards-profile/awards-profile.component';
import { CoursesProfileComponent } from './courses-profile/courses-profile.component';
import { AssociationsProfileComponent } from './associations-profile/associations-profile.component';
import { VolunteeringProfileComponent } from './volunteering-profile/volunteering-profile.component';

@Component({
  selector: 'app-more-info-profile',
  templateUrl: './more-info-profile.component.html',
  styleUrls: ['./more-info-profile.component.scss']
})
export class MoreInfoProfileComponent {

  @Input() moreInfos!:FormGroup;

  @Input() endCustomerData:any;

  @Input() isIndividualProfile:boolean=false;
  @Output() onUpdateMoreInfo:EventEmitter<any>=new EventEmitter();

  @ViewChild(PublicationsProfileComponent) publicationsProfileComponent!:PublicationsProfileComponent;
  @ViewChild(AwardsProfileComponent) awardsProfileComponent!:AwardsProfileComponent;
  @ViewChild(CoursesProfileComponent) coursesProfileComponent!:CoursesProfileComponent;
  @ViewChild(AssociationsProfileComponent) associationsProfileComponent!:AssociationsProfileComponent;
  @ViewChild(VolunteeringProfileComponent) volunteeringProfileComponent!:VolunteeringProfileComponent;


  updatePublications(){
    this.publicationsProfileComponent?.refresh();
  }

  updateAwards(){
    this.awardsProfileComponent?.refresh();
  }

  updateCourses(){
    this.coursesProfileComponent?.refresh();
  }

  updateAssociations(){
    this.associationsProfileComponent?.refresh();
  }
  updateVolunteering(){
    this.volunteeringProfileComponent?.refresh();
  }

  
  onUpdate(event:any){
    this.onUpdateMoreInfo.emit(event);
  }
}

import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { Observable } from 'rxjs';
import { map, takeWhile, concatMap } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Distributor } from 'src/app/models/distributor-model';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { forkJoin } from 'rxjs';
import {SubscriptionsService} from 'src/app/services/shared/subscriptions/subscriptions.service';


@Component({
  selector: 'app-oem-company-information',
  templateUrl: './oem-company-information.component.html',
  styleUrls: ['./oem-company-information.component.css']
})
export class OemCompanyInformationComponent implements OnInit {
  @Input() companyInfo!: FormGroup;
  @Input() isPopUpVisible: boolean = false;
  @Input() isCertificatePopUpVisible: boolean= false;
  informationFields: { name: string, label: string }[] = [];
  certificateFields: { name: string, label: string }[] = [];
  continents!: any[];
  countries!: any[];
  subscriptionPlan: string = '';
  status: string = '';
  subscriptionName: string = '';
  subscriptionImage: string = '';
  states!: any[];
  tooltipVisible: string | null = null;
  cities!: any[];
  @ViewChild('logoInput') logoInput!: ElementRef;
  @ViewChild('otherFilesInput') otherFilesInput!: ElementRef;
  @ViewChild('scrollContainer') scrollContainer: ElementRef = new ElementRef(null);

  triggerLogoInputClick() {
    this.logoInput.nativeElement.click();
  }
  // ngAfterViewInit() {
  //   this.scrollContainer.nativeElement.scrollTop = 0;
  // }

  get showInformationFields(): boolean {
    return this.isPopUpVisible && !this.isCertificatePopUpVisible;
  }
  
  get showCertificateFields(): boolean {
    return this.isPopUpVisible && this.isCertificatePopUpVisible;
  }
  
  get showBothFields(): boolean {
    return !this.isPopUpVisible;
  }

 
  triggerOtherFilesInputClick() {
    this.otherFilesInput.nativeElement.click();
  }

  constructor(private fileUploadService: FileUploadService, private masterDataService: MasterDataService, private fb: FormBuilder, private spinnerService: SpinnerService, private sweetAlertService: SweetAlertService
    ,private subscriptionService: SubscriptionsService
   ) {}
  
  ngOnInit(): void {
    
    const companyInformationGroup = this.companyInfo.get('companyInformation') as FormGroup;

    if (companyInformationGroup) {
      const fields = Object.keys(companyInformationGroup.controls).map(key => ({
        name: key,
        label: this.getLabel(key),
      }));
      if(!this.isPopUpVisible){
        this.informationFields = fields.filter(field => !this.isCertificateField(field.name) && !this.ignoreFields(field.name)).sort((a, b) => {
          const order = [
            'userName',
            'email',
            'phoneNumber',
            'uniqueId',
            'logo',
            'name',
            'brandName',
            'year',
            'url',
            'employeeCount',
            'continent',
            'country',
            'state',
            'city',
            'address',
            'zipCode',  
          ];
          return order.indexOf(a.name) - order.indexOf(b.name);
        }); 
      }
      else{
        this.informationFields = fields.filter(field => !this.isCertificateField(field.name) && !this.ignoreFields(field.name)).sort((a, b) => {
          const order = [
            'userName',
            'employeeCount',
            'email',
            'continent',
            'phoneNumber',
            'country',
            'uniqueId',
            'state',
            'logo',
            'city',
            'name',
            'address',
            'brandName',
            'zipCode',
            'year',
            'url',     
          ];
          return order.indexOf(a.name) - order.indexOf(b.name);
        });
      }
     
      this.certificateFields = fields
  .filter(field => this.isCertificateField(field.name))
  .sort((a, b) => {
    const order = [
      'crcopy',
      'cecopy',
      'businessLicense',
      'europeanCertificate',
      'otherFiles',
    ];
    return order.indexOf(a.name) - order.indexOf(b.name);
  });
      
  companyInformationGroup.get('subscriptionInfoId')?.valueChanges.subscribe(value => {
    if (value) {
      this.subscriptionService.getAllSubscriptions().subscribe(
        (response: any) => {
          if (response && response.data) {
            const subscription = response.data.find((sub: any) => sub.id === value);
            if (subscription) {
              this.subscriptionName = subscription.name;
              this.subscriptionImage = subscription.image;
            } else {
             
              this.subscriptionName = 'Free Plan';
            }
          }
        },
        (error) => {
          console.error('Error fetching subscription info:', error);
        }
      );
    } else {
      this.subscriptionService.getAllSubscriptions().subscribe(
        (response: any) => {
          if (response && response.data && response.data.length > 0) {
            const firstSubscription = response.data[0];
            companyInformationGroup.get('subscriptionInfoId')?.setValue(firstSubscription.id);
            this.subscriptionName = firstSubscription.name;
            this.subscriptionImage = firstSubscription.image;
          } else {
            this.subscriptionName = 'Free Plan';
          }
        },
        (error) => {
          console.error('Error fetching subscription info:', error);
        }
      );
    }
  });
  
      

      
  const statusControl = companyInformationGroup.get('status');
  if (!statusControl?.value) {
    statusControl?.setValue('Requested');
  }
  console.log( "statusControl", statusControl?.value , companyInformationGroup);
  
  this.status = statusControl?.value;
    }
    companyInformationGroup.get('uniqueId')?.disable();
    companyInformationGroup.get('email')?.disable();
    if(!this.showCertificateFields){
      this.loadMasterData().then(() => {
      
        this.setFormValues();
        // Ensure this is called after data is loaded
      });
    
    }
  
    // this.ngAfterViewInit();
  }

  

  getLabel(key: string): string {
    const labels: { [key: string]: string } = {
      email: 'Email',
      userName: 'User Name',
      uniqueId: 'Unique ID',
      year: 'Established Year',
      name: 'Company Name',
      phoneNumber: 'Phone Number',
      employeeCount: 'Employee Count',
      continent: 'Continent',
      country: 'Country',
      state: 'State',
      city: 'City',
      address: 'Address',
      zipCode: 'Zip Code',
      logo: 'Logo',
      url: 'Website URL',
      europeanCertificate: 'European Certificate',
      brandName: 'Brand Name',
      crcopy: 'CR Copy*',
      businessLicense: 'Business License*',
      cecopy: 'CE Copy*',
      otherFiles: 'Other Files',
      subscriptionInfoId: 'Subscription',
      branches: 'Branches'
    };
    return labels[key] || key;
  }

  private async loadMasterData() {
    try {
      this.spinnerService.showSpinner();
      const results = await forkJoin({
        continents: this.masterDataService.getContinents(),
      }).toPromise();

      this.continents = results?.continents.data;
      
    } catch (error) {
      console.error('Error fetching master data:', error);
    } 
    await this.loadContinents();
    await this.loadCountries(['']);
    await this.loadStates(['']);
    await this.loadCities(['']);
    this.spinnerService.hideSpinner();
  }

  private async setFormValues() {
    const lead = this.companyInfo.get('companyInformation')?.value;
    if (lead) {
      await this.loadCountries([lead.continent]);
      await this.loadStates([lead.country]).then(() => {
        this.loadCities([lead.state]);        
      })
    } 
  }

  isFieldInvalid(fieldName: string): boolean {
    // Only apply invalid-field class for crcopy, cecopy, and businessLicense
    if (['crcopy', 'cecopy', 'businessLicense'].includes(fieldName)) {
      const field = this.companyInfo.get('companyInformation.' + fieldName);
      return !!field?.touched && !field?.value;
    }
    return false;
  }

 

  isCertificateField(key: string): boolean {
    const certificateFields = ['otherFiles','crcopy', 'businessLicense', 'cecopy',  'europeanCertificate'];
    return certificateFields.includes(key);
  }

  ignoreFields(key: string): boolean {
    const ignoreFields = ['status','description', 'branches','branchesPresent', 'subscriptionInfoId', 'branches', 'distributors','oemId','phoneCode'];
    return ignoreFields.includes(key);
  }


  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }


  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    
    formData.append('destinationKey', randomNumber + '');

    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; // Return the full URL from the response
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }

  selectFile(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0];
      this.uploadFile(file).subscribe(
        (url: string) => {
          const companyInformation = this.companyInfo.get('companyInformation') as FormGroup;
          companyInformation.patchValue({
            [controlName]: url
          });
          this.resetFileInput(controlName);
        },
        (error) => {
          this.spinnerService.hideSpinner()
          this.sweetAlertService.showError('Failed to add file', '');
          console.error('Error uploading file:', error);
        }
      );
    }
  }

  private resetFileInput(controlName: string): void {
    const inputElement = document.getElementById(controlName) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  }

  selectFiles(event: Event, fieldName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.uploadFiles(input.files, fieldName);
    }
  }
  
  uploadFiles(files: FileList, fieldName: string): void {
    this.spinnerService.showSpinner();
    const fileArray: File[] = Array.from(files);
  
    const existingFiles = this.companyInfo.get('companyInformation.' + fieldName)?.value || [];
    
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    this.fileUploadService.uploadFilesAndGetUrls(fileArray, randomNumber + '').subscribe(
      (urls: string[]) => {
     
        this.companyInfo.get('companyInformation.' + fieldName)?.setValue([...existingFiles, ...urls]);
        this.spinnerService.hideSpinner();
      },
      (error) => {
        console.error('Error uploading files:', error);
        this.sweetAlertService.showError('Failed to add file', ''),
        
        this.spinnerService.hideSpinner();
      }
    );
  }

  
  
  clearFile(fieldName: string, index?: number): void {
    if (fieldName === 'otherFiles' && typeof index === 'number') {
      const files = this.companyInfo.get('companyInformation.otherFiles')?.value as string[];
      files.splice(index, 1);
      this.companyInfo.get('companyInformation.otherFiles')?.setValue(files);
    } else {
      this.companyInfo.get('companyInformation.' + fieldName)?.reset();
    }
  }
  
  getFileName(url: string): string {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];
    return fileName;
  }

 

showTooltip(fieldName: string) {
  this.tooltipVisible = fieldName;
}

hideTooltip(fieldName: string) {
  this.tooltipVisible = null;
}
  
  getFileWidth(url: string): number {
    const fileName = this.getFileName(url);
    const charWidth = 10; // Average character width in pixels.
    const width = fileName.length * charWidth;
    return width;
  }

  onContinentChange(event: any) {
    const continentName = event?.value;
    this.loadCountries([continentName]);
  }

  onCountryChange(event: any) {
    const countryName = event?.value;
    this.loadStates([countryName]);
  }

  onStateChange(event: any) {
    const stateName = event?.value;
    this.loadCities([stateName]);
  }

  onCityChange(event: any) {}

  loadContinents(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.masterDataService.getContinents().subscribe(data => {
        this.continents = data.data;
        resolve();
      }, error => {
        reject(error);
      });
    });
  }

  loadCountries(continentNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      let pageNumber = 0;
      const pageSize = 100;
      let allCountries: any[] = [];
      let morePages = true;

      this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames)
        .pipe(
          takeWhile(() => morePages),
          concatMap(response => {
            if (response.data.length === 0) {
              morePages = false; // No more items to fetch
              return [];
            }
            allCountries = allCountries.concat(response.data);
            pageNumber++;
            return this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames);
          })
        )
        .subscribe(
          data => {
            if (data.data.length === 0) {
              morePages = false;
            } else {
              allCountries = allCountries.concat(data.data);
            }
            this.countries = allCountries;
            resolve();
          },
          error => {
            reject(error);
          },
          () => {
            resolve();
          }
        );
    });
  }

  loadStates(countryNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      let pageNumber = 0;
      const pageSize = 500;
      let allStates: any[] = [];
      let morePages = true;

      this.masterDataService.getStates(pageNumber, pageSize, '', countryNames)
        .pipe(
          takeWhile(() => morePages),
          concatMap(response => {
            if (response.data.length === 0) {
              morePages = false; // No more items to fetch
              return [];
            }
            allStates = allStates.concat(response.data);
            pageNumber++;
            return this.masterDataService.getStates(pageNumber, pageSize, '', countryNames);
          })
        )
        .subscribe(
          data => {
            if (data.data.length === 0) {
              morePages = false;
            } else {
              allStates = allStates.concat(data.data);
            }
            this.states = allStates;
            resolve();
          },
          error => {
            reject(error);
          },
          () => {
            resolve();
          }
        );
    });
  }

  loadCities(stateNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      let pageNumber = 0;
      const pageSize = 500;
      let allCities: any[] = [];
      let morePages = true;

      this.masterDataService.getCities(pageNumber, pageSize, '', stateNames)
        .pipe(
          takeWhile(() => morePages),
          concatMap(response => {
            if (response.data.length === 0) {
              morePages = false; // No more items to fetch
              return [];
            }
            allCities = allCities.concat(response.data);
            pageNumber++;
            return this.masterDataService.getCities(pageNumber, pageSize, '', stateNames);
          })
        )
        .subscribe(
          data => {
            if (data.data.length === 0) {
              morePages = false;
            } else {
              allCities = allCities.concat(data.data);
            }
            this.cities = allCities;
            resolve();
          },
          error => {
            reject(error);
          },
          () => {
            resolve();
          }
        );
    });
  }

  hasUploadedFiles(fieldName: string): boolean {
    const files = this.companyInfo.get(`companyInformation.${fieldName}`)?.value;
    return files && files.length > 0;
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddCatalogComponent } from 'src/app/components/shared/add-catalog/add-catalog.component';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { CatalogService } from 'src/app/services/shared/catalog.service';
import { DISTRIBUTOR_ID, OEM_DETAILS, OEM_ID } from 'src/app/utils/constants';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  @Input() isDistributor: boolean = false;
  data!: any[];
  userId!: string;
  oemId!: string;
isViewmode: boolean = false;

startsWith: string = '';
pageNumber: number = 0;
size: number = 10;
  columns: ListColumn[] = [
    { name: 'Sr.No', property: 'sNo', visible: true, isModelProperty: false },
    // { name: 'Catalog Name', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'Uploaded File', property: 'catalog', visible: true, isModelProperty: true, isFile: true },
    {name : 'Product', property: 'productName', visible: true, isModelProperty: true, isText: true},
    { name: 'Added by', property: 'createdBy', visible: true, isModelProperty: true, isText: true },
    { name: 'Date', property: 'createdOn', visible: true, isModelProperty: true, isText: true },
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true, actions: [
        {
          imageUrl: 'assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction, hasCondition: true, conditionProperty: 'active', conditionValue: true,
          iconName: undefined,
          actionName: undefined
        },
        {
          imageUrl: 'assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction, hasCondition: true, conditionProperty: 'active', conditionValue: true,
          iconName: undefined,
          actionName: undefined
        },
        {
          imageUrl: 'assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction, hasCondition: true, conditionProperty: 'active', conditionValue: true,
          iconName: undefined,
          actionName: undefined
        }
      ]
    }
  ];

  constructor(private localStorageService: LocalStorageService, private router: Router, private dialog: MatDialog, private catalogService: CatalogService, private oemService: OemService) {
    this.localStorageService.getUserDetails().subscribe(userDetails => {
      this.userId = userDetails.userId;
    });
  }
  ngOnInit(): void {
    this.oemId = this.localStorageService.getItem(OEM_ID);
  
    if (this.isDistributor) {
      
      this.columns = this.columns.map(column => {
        if (column.property === 'action') {
          column.actions = column.actions?.filter(action => action.actionType === MweConstants.viewAction);
        }
        return column;
      });
      this.getAllDistributorCatalogs();
    } else {
      this.getAllCatalogs();
    }

    this.startsWith = '';
  }

  onPageChanged(event: any) {
    this.pageNumber= event.pageIndex;
    this.size = event.pageSize;
    if(this.isDistributor){
      this.getAllDistributorCatalogs();
    }
    else{
      this.getAllCatalogs();
    }
    

  }

  applyFilter(event: any) {
    this.startsWith = event;
    this.pageNumber = 0;
    if(this.isDistributor){
      this.getAllDistributorCatalogs();
    }
    else{
      this.getAllCatalogs();
    }
    
  }
  
  
  
  getAllDistributorCatalogs() {
    const distributorId = this.localStorageService.getItem(DISTRIBUTOR_ID);
   
    this.catalogService.getDistributorCatalogs(distributorId,  this.pageNumber, this.size, this.startsWith).subscribe(data => {
      this.data = data.data;
    });
  }
  

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.addAction:
        this.openAddCatalog();
        break;
      case MweConstants.editAction:
        this.isViewmode = false;
        this.openEditCatalog(event.row,this.isViewmode);
        break;
      case MweConstants.deleteAction:
        this.deleteAction(event.row);
        break;
      case MweConstants.viewAction:
        this.isViewmode = true;
        this.openViewCatalog(event.row,this.isViewmode);
      
        break;
      case MweConstants.filterAction:
        
        break;
      default:
        break;
    }
  }
  openAddCatalog() {
    const dialogRef = this.dialog.open(AddCatalogComponent, {
      width: '560px',
      position: { left: '480px', top: '150px' },
      data: { oemId: this.oemId , isViewmode: this.isViewmode}
    });
    dialogRef.afterClosed().subscribe(result => {

      this.getAllCatalogs();
    });
  }

  openEditCatalog(catalog: any, isViewmode: boolean ) {
    
    const dialogRef = this.dialog.open(AddCatalogComponent, {
      width: '560px',
      position: { left: '480px', top: '150px' },
      data: {
        oemId: this.oemId,
        catalog: catalog,
        isViewmode: isViewmode,
        fileName: catalog.catalog.split('/').pop(),
        isAssociated: catalog.isAssociated, 
        productId: catalog.productId,
        actionType : MweConstants.editAction
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllCatalogs();
      }
    });
  }

  deleteAction(row: any) {
    Swal.fire({
      position: 'center',
      title: 'Are you sure?',
      text: 'You will not be able to recover the deleted record!', 
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {
        this.catalogService.deleteCatalog(row.id).subscribe(
          () => {
            Swal.fire(
              'Removed!',
              'Item Removed Successfully.',
              'success'
            );
            this.getAllCatalogs();
          },
          (error) => {
            Swal.fire(
              'Error',
              'There was an error deleting the item.',
              'error'
            );
            console.error('Error occurred:', error);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Item is safe.',
          'error'
        );
      }
    });
  }

  getAllCatalogs() {
    this.catalogService.getCatalogs(this.oemId, this.pageNumber, this.size,this.startsWith).subscribe(data => {
      this.data = data.data 
      }); 
  }

  getOemDetails() {
    this.oemService.getOEMdetailswithUserId(this.userId).subscribe(data => {
      this.data = data.data;
    });
  }

  
  openViewCatalog(catalog: any, isViewmode: boolean) {
    const dialogRef = this.dialog.open(AddCatalogComponent, {
      width: '560px',
      position: { left: '480px', top: '150px' },
      data: {
        oemId: this.oemId,
        catalog: catalog,
        isViewmode: isViewmode,
        fileName: catalog.catalog.split('/').pop(),
        isAssociated: catalog.isAssociated, 
        productRangeId: catalog.productRangeId, 
        actionType : MweConstants.viewAction
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(this.isDistributor){
          this.getAllDistributorCatalogs();
        }
        else{
          this.getAllCatalogs();
        }
      }
    });
  }

}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { WelcomePopupComponent } from '../welcome-popup/welcome-popup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  constructor(private sweetAlertService: SweetAlertService, private dialog: MatDialog) {

    
   }

  ngOnInit(): void {
    // this.sweetAlertService.showWelcomeMessage();
    // this.showWelcomePopup();
  }
showWelcomePopup(){
  const dialogRef = this.dialog.open(WelcomePopupComponent, {
    disableClose: true // Prevent closing the dialog by clicking outside
  });
  dialogRef.afterClosed().subscribe(() => {
    console.log("Dialog closed");
  });
}

showPopup() {
  this.sweetAlertService.showWelcomeMessage();
}

}


import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-signout-popup',
  templateUrl: './signout-popup.component.html',
  styleUrls: ['./signout-popup.component.scss']
})
export class SignoutPopupComponent {

  constructor(private router: Router, private localStorageService: LocalStorageService,private dialogRef: MatDialogRef<SignoutPopupComponent>) { }

  cancel() {
    // Close the popup here
    this.dialogRef.close();
  } 
  logout() {
    this.dialogRef.close();
    this.localStorageService.clear();
    this.router.navigate(['login']);
  }
}

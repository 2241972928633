import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-hospital-portfolio',
  templateUrl: './hospital-portfolio.component.html',
  styleUrls: ['./hospital-portfolio.component.scss']
})
export class HospitalPortfolioComponent implements OnInit{

  id: string | null = null;
  hospitalPortfolio!: any;
  averageRating: any;

  activeTab: number = 0;
  previousTab: number = 0;
  activeInfoTab: number = 0;
  previousInfoTab: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private oemService: OemService
  ){ }

  ngOnInit(): void{
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    if (this.id !== null) {
      this.fetchProfileData(this.id);
    }
  }

  fetchProfileData(id: string): void {
    this.getOEMsById(id);
  }

  getOEMsById(id: string ){
    // Temporary data
    this.oemService.getOEMById(id).subscribe({
      next: (response) =>{
       console.log("hospital details: ", response.data)
       this.hospitalPortfolio = response.data
       this.averageRating = response.data.averageRating

      },
      error: (error) => {
        console.error('Error fetching OEM by id:', error);
      }
    }
    )
  }
  selectTab(tab: number) {
    if(tab !== this.activeTab){
      this.previousTab = this.activeTab;
      this.activeTab = tab;
    }
  }

  currentInfoTab(infoTab: number){
    if(infoTab !== this.activeInfoTab){
      this.previousInfoTab = this.activeInfoTab
      this.activeInfoTab = infoTab
    }
  }

  navigateTo():void{
    this.router.navigate(['/hospitals'])
  }

  downloadHospitalPortfolio() {
    // this.downloadPage();
    console.log("Hospital Profile is downloaded")
  }

  // downloadPage(): void{
    
  // }
}

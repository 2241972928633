<div class="modal-popup" mat-dialog-content>
    <h2 style="color: #0D87C8">Import</h2>
    <form>
        <div class="my-3">
            <mat-card class="upload-card">
                <div *ngIf="!fileName" class="upload-area" (click)="fileInput.click()">
                  <label class="d-flex justify-content-center text" for="upload" mat-raised-button>
                    <img alt="" src="./../../assets/images/photo-add.svg">
                    <span >Select File</span>
                  </label>
                </div>
                <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none;" accept=".csv">
                <div *ngIf="fileName" class="file-info">
                    <span>{{ fileName }}</span>
                    <button mat-icon-button color="warn" (click)="removeFile()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </mat-card>
            <mat-error class="error-message image-error" *ngIf="invalidFileType">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Invalid file type {{fileType}}, Only csv file is allowed.
            </mat-error>
        </div>
            <a class="download-csv-btn" (click)="downloadCSVFile()">
                <img src="assets/images/download.svg" alt=""> Sample CSV for Download
            </a>
        <div class="popup-buttons">
            <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">Close</button>
        </div> 
    </form>
</div>




import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage.service';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { UnitOfMeasureData } from 'src/app/models/product-settings-model';

@Injectable({
  providedIn: 'root'
})
export class UnitMeasureService {

  MASTERDATA_BASE_URL = environment.MASTERDATA_BASE_URL;
  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  baseURL = this.ONBOARDING_BASE_URL + `/unitmeasure`;
  //baseURL = 'http://localhost:8089/onboarding/unitmeasure/'

  userId:any;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,) {
    this.localStorageService.getUserDetails().subscribe(data=>{
      this.userId=data.userId;
    })
   }

 
  public getAllUnitMeasure(): Observable<ApiResponseModel<UnitOfMeasureData[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<UnitOfMeasureData[]>>(this.baseURL, {headers});
  }

  public getAllMeasureCategories(): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<any[]>>(this.baseURL + '/measure', {headers});
  }

  public saveUnitMeasure(body: any): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('userId',this.userId);
    return this.http.post(this.baseURL + '/save', body, { headers: headers });
  }

  public updateUnitMeasure(body: any, id : number): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('userId',this.userId);
    return this.http.put(this.baseURL + '/update/' + id, body, { headers: headers });
    
  }

  public deleteUnitMeasure(id : number): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.delete(this.baseURL + '/delete/' + id, { headers: headers });
  }
}

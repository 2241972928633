<div class="page-heading" style="cursor: pointer;" (click)="onBack()">Back to all jobs</div>
<div class="view-job-container mt-2 p-4">

    <div class="row">
        <div class="col-lg-9">


            <div style="height: 400px; overflow: hidden;">
                <img [src]="jobPost?.image" alt="featured-image" style="width: 100%;max-height: 100%;object-fit: cover">
            </div>

            <div class="title">{{jobPost?.title}}</div>
            <div class="description mt-1" [innerHTML]="jobPost?.content"></div>


            <div class="row job-fields">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between">
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-job-category.svg" alt="">
                            <p class="overview-text">Job Category</p>
                            <p class="overview-label-job">{{jobPost?.category}}</p>
                        </div>
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-job-speciality.svg" alt="">
                            <p class="overview-text">Speciality</p>
                            <p class="overview-label-job">{{jobPost?.speciality}}</p>
                        </div>
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-job-type.svg" alt="">
                            <p class="overview-text">Job Type</p>
                            <p class="overview-label-job">{{jobPost?.jobType}}</p>
                        </div>
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-career-level.svg" alt="">
                            <p class="overview-text">Career Level</p>
                            <p class="overview-label-job">{{jobPost?.careerLevel}}</p>
                        </div>
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-career-experience.svg" alt="">
                            <p class="overview-text">Experience</p>
                            <p class="overview-label-job">{{jobPost?.experience}}</p>
                        </div>
                        <div class="overview-border">
                            <img src="/assets/images/careers/frame-degree-level.svg" alt="">
                            <p class="overview-text">Degree Level</p>
                            <p class="overview-label-job">{{jobPost?.degreeLevel}}</p>
                        </div>
                    </div>
                </div>
            </div>


            <p class="job-label">Skills required</p>
            <mat-chip *ngFor="let skill of jobPost.skills; let i = index" class="me-2 mb-2">{{skill}}</mat-chip>

            <p class="job-label">Tags</p>
            <mat-chip *ngFor="let tag of jobPost.tags; let i = index" class="me-2 mb-2">{{tag}}</mat-chip>

            <p class="job-label">Description</p>
            <div [innerHtml]="jobPost?.description"></div>
            <!-- <p class="description">{{jobPost?.description}}</p> -->

            <p class="company-details-header">Company Details</p>
            <p class="company-name">{{jobPost?.company}}</p>
            <p class="company-address">{{jobPost?.address}}</p>

            <p class="company-details-header">Job Overview</p>

            <table class="table">
                <tbody>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-total-positions.svg" alt="" class="me-1">
                            Total Positions
                        </td>
                        <td class="overview-text">{{jobPost?.totalPositions}}</td>
                    </tr>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-posted-by.svg" alt="" class="me-1">
                            Posted by
                        </td>
                        <td class="overview-text">{{jobPost?.postedBy}}</td>
                    </tr>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-salary-range.svg" alt="" class="me-1">
                            Salary Range
                        </td>
                        <td class="overview-text">{{jobPost?.salaryRange}}</td>
                    </tr>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-salary.svg" alt="" class="me-1">
                            Salary
                        </td>
                        <td class="overview-text">{{getCurrencySymbol(jobPost?.salaryCurrency)}}{{jobPost?.salaryFrom}}
                            - {{getCurrencySymbol(jobPost?.salaryCurrency)}}{{jobPost?.salaryTo}}</td>
                    </tr>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-application-start-date.svg" alt="" class="me-1">
                            Applications Start Date
                        </td>
                        <td class="overview-text">{{jobPost?.applicationStartDate | date:'dd/MM/YYYY'}}</td>
                    </tr>
                    <tr>
                        <td class="overview-label">
                            <img src="/assets/images/careers/frame-application-end-date.svg" alt="" class="me-1">
                            Applications Close Date
                        </td>
                        <td class="overview-text">{{jobPost?.applicationCloseDate | date:'dd/MM/YYYY'}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noWhitespaceValidator } from 'src/app/models/product-settings-model';
import { SpecificationService } from 'src/app/services/shared/product-settings/specification.service';
import { UnitMeasureService } from 'src/app/services/shared/product-settings/unit-measure.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-edit-specifications',
  templateUrl: './add-edit-specifications.component.html',
  styleUrls: ['./add-edit-specifications.component.css']
})
export class AddEditSpecificationsComponent {
  specifications: FormGroup;
  types = ['Number', 'Text']; 
  alternateMeasureCode : string[] = []; 
  alternateMeasureSub = [];
  selectedValue: string = 'Number';
  selectedPrimaryUnit: string = '';
  selectedToUnit: string = '';
  unitOfMeasureValue : string = '';
  unitMeasureData : any [] = [];
  measureCodeOne = '';
  measureCodeTwo = '';
  id : any;
  units:any[] = [];
  actionType! : string;

  constructor(
    private dialogRef: MatDialogRef<AddEditSpecificationsComponent>, 
    private formBuilder: FormBuilder,
    private specificationservice : SpecificationService,
    private unitOfMeasure : UnitMeasureService,
    private sweetAlertService : SweetAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.specifications = this.formBuilder.group({
      type : ['',[Validators.required, noWhitespaceValidator()]],
      name: ['',[Validators.required, noWhitespaceValidator()]],
      unitOfMeasure: ['', Validators.required],
      active: ['true',[Validators.required]],
      alternativeMeasures: this.formBuilder.array([])
    });

  }

  ngOnInit() {
    this.specifications.patchValue(this.data);
    if(this.data) {
      this.id = this.data.id;
      this.actionType = this.data.actionType;
      this.selectedValue = this.data.type;
      if(this.data.active) {
      this.specifications.get('active')?.setValue('true');
      } else{
      this.specifications.get('active')?.setValue('false');
      }

      this.populateForm(this.data);

      if (this.data.actionType==='edit') {
        this.specifications.get('type')?.enable();
        this.specifications.get('name')?.enable();
        this.specifications.get('unitOfMeasure')?.enable();
        this.specifications.get('active')?.enable();
        this.specifications.get('alternativeMeasures')?.enable();
      } else {
        this.specifications.get('type')?.disable();
        this.specifications.get('name')?.disable();
        this.specifications.get('code')?.disable();
        this.specifications.get('active')?.disable();
        this.specifications.get('alternativeMeasures')?.disable();
        this.specifications.get('unitOfMeasure')?.disable();
      }
    }
    this.getAllUnitmeasure();
  }

  populateForm(data: any): void {
    data.measureRelation.forEach((measure: {primaryUnit : any; primaryValue : any; secondaryMeasure: { unit: any; value: any; }[]; }) => { 
      this.specifications.get('unitOfMeasure')?.patchValue(measure.primaryUnit);
      this.selectedPrimaryUnit = measure.primaryUnit;
      measure.secondaryMeasure.forEach((spec: { unit: any; value: any;}) => {
        const specGroup = this.formBuilder.group({
         fromValue: [measure.primaryValue, Validators.required],
         toUnit: [spec.unit, Validators.required],
         toValue:[spec.value, Validators.required],
        });
        this.alternativeMeasures.push(specGroup);
    });
    });
  }

  get alternativeMeasures(): FormArray {
    return this.specifications.get('alternativeMeasures') as FormArray;
  }

  addAlternativeMeasure(): void {
    const alternativeMeasure = this.formBuilder.group({
      fromUnit: [ this.selectedPrimaryUnit, Validators.required],
      toUnit: ['', Validators.required],
      fromValue: ['', Validators.required],
      toValue: ['', Validators.required]
    });

    this.alternativeMeasures.push(alternativeMeasure);
  }

  removeAlternativeMeasure(index: number): void {
    this.alternativeMeasures.removeAt(index);
  }

  hasAlternativeMeasures(): boolean {
    return this.alternativeMeasures.length > 0;
  }

  filterUnits(selectedUnit: string) {
    const selectedMeasureCategory = this.units.find(unit => unit.code === this.selectedPrimaryUnit)?.measureCategory;
    const selectedToUnit = this.units.find(unit => unit.code === this.selectedToUnit)?.measureCategory;
    // if(this.selectedToUnit) {
    //   return this.units.filter(unit => unit.measureCategory === selectedToUnit && unit.code !== this.selectedToUnit);
    // }
    return this.units.filter(unit => unit.measureCategory === selectedMeasureCategory && unit.code !== this.selectedPrimaryUnit);
  }

  onSelectionChange(event: any): void {
    this.selectedValue = event.value; 
  }

  onPrimaryUnitSelect(event: any): void {
    this.selectedPrimaryUnit = event.value;
    this.alternativeMeasures.controls.forEach(control => {
      control.get('fromUnit')?.patchValue( this.selectedPrimaryUnit);
    });
  }

  onToUnitSelect(event: any): void {
    this.selectedToUnit = event.value;
  }
 

  groupDataByCategory(data: any[]) {
    return data.reduce((acc, item) => {
      const { measureCategory, ...measure } = item;
      if (!acc[measureCategory]) {
        acc[measureCategory] = [];
      }
      acc[measureCategory].push(measure);
      return acc;
    }, {});
  }


  getAllUnitmeasure() {
    this.specificationservice.getAllUnitMeasure().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.unitMeasureData = response.data;
          const groupedData = this.unitMeasureData.reduce((acc, item) => {
          const category = item.measureCategory;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(item);
            return acc;
          }, {});

          const result = Object.keys(groupedData).map(category => ({
            measureCategory: category,
            measures: groupedData[category]
          }));

          this.units = result.flatMap(category => category.measures);
        } 
      },
      error: (error) => {
        console.error('Error fetching oem data:', error);
      }
    });
  }

  processMeasureRelation(formData: any): any[] {
    const result : any = [];
    if (formData.alternativeMeasures.length === 0) {
      return [{
          primaryUnit: this.selectedPrimaryUnit,
          primaryValue: null,
          secondaryMeasure: []
      }];
  }

    formData.alternativeMeasures.forEach((entry: { fromUnit: any; fromValue: any; toUnit: any; toValue: any; }) => {
      let existingEntry = result.find((item: { primaryUnit: any; primaryValue: any; }) => item.primaryUnit === entry.fromUnit && item.primaryValue === entry.fromValue);
      if (!existingEntry) {
          existingEntry = {
              primaryUnit: entry.fromUnit,
              primaryValue: entry.fromValue,
              secondaryMeasure: []
          };
          result.push(existingEntry);
      }
      existingEntry.secondaryMeasure.push({
          unit: entry.toUnit,
          value: entry.toValue
      });
  });
  return result;
  }

  get primaryUnit() {
    return this.specifications.get('unitOfMeasure') as FormArray;
  }

  get name() { return this.specifications.get('name'); }
  get type() { return this.specifications.get('type'); }

  get alternateMeasure() {
    return this.specifications.get('alternativeMeasures') as FormArray;
  }


  updateValidators() {
    if (this.hasAlternativeMeasures()) {
      console.log('secondary')
      this.specifications.get('unitOfMeasure')?.clearValidators();
      this.alternativeMeasures.controls.forEach(control => {
        control.get('toUnit')?.setValidators(Validators.required);
        control.get('toValue')?.setValidators(Validators.required);
        control.get('fromUnit')?.setValidators(Validators.required);
        control.get('fromValue')?.setValidators(Validators.required);
      });
    } else {
      console.log('primary')
      this.specifications.get('unitOfMeasure')?.setValidators(Validators.required);
      this.alternativeMeasures.controls.forEach(control => {
        control.get('toUnit')?.clearValidators();
        control.get('toValue')?.clearValidators();
        control.get('fromUnit')?.clearValidators();
        control.get('fromValue')?.clearValidators();
      });
    }

    this.specifications.get('unitOfMeasure')?.setValidators(Validators.required);
    this.alternativeMeasures.controls.forEach(control => {
      control.get('toUnit')?.clearValidators();
      control.get('toValue')?.clearValidators();
      control.get('fromUnit')?.clearValidators();
      control.get('fromValue')?.clearValidators();
    });
    this.specifications.get('unitOfMeasure')?.updateValueAndValidity();
    this.alternativeMeasures.controls.forEach(control => control.updateValueAndValidity());
  }

  onFormSubmit() {
    this.updateValidators();

    console.log(this.specifications.value)
      const formData = this.specifications.value; 
      const payload = {
        type : formData.type,
        name: formData.name,
        unitMeasureId: formData.unitOfMeasure.id,
        active: formData.active,
        measureRelation:this.processMeasureRelation(formData),
     }
     console.log('payload', payload)

    if(this.selectedValue === 'Number') {
      if (this.specifications.valid) {
          this.makeAPICall(payload);
        } else {
          this.specifications.markAllAsTouched();
      }
    } else if(this.selectedValue === 'Text'){
      if (!this.specifications.get('name')?.value) {
        this.specifications.get('name')?.setValidators([Validators.required]);
        return
      } else {
        this.makeAPICall(payload);
      }
    }
  }

  makeAPICall(payload : any) {
    if (this.data) {
      this.specificationservice.updateSpecification(payload, this.id).subscribe(
        (response) => {
          this.dialogRef.close(true);
          if(response.statusCode === '400 BAD_REQUEST') {
            this.sweetAlertService.showError('', response.message);
          } else {
            this.sweetAlertService.showSuccess('', 'Specification updated successfully !!');
          }
        },
        (error) => {
          console.log("error", error);
          this.dialogRef.close(true);
          this.sweetAlertService.showError('', 'Failed to update specification !!');
        }
      );
    } else {
      //Add
      this.specificationservice.saveSpecification(payload).subscribe(
        (response) => {
          this.dialogRef.close(true);
          if(response.statusCode === '400 BAD_REQUEST') {
            this.sweetAlertService.showError('', response.message);
          } else {
            this.sweetAlertService.showSuccess('', 'Specification added successfully !!');
          }
        },
        (error) => {
          console.log("error", error);
          this.dialogRef.close(true);
          this.sweetAlertService.showError('', 'Failed to add specification !!');
        }
      );
    }
  }
}

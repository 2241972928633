import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { CombinedData } from 'src/app/models/oem-models';


@Component({
  selector: 'app-add-oem',
  templateUrl: './add-oem.component.html',
  styleUrls: ['./add-oem.component.css']
})
export class AddOemComponent implements OnInit {
  labels: string[] = ['Company Information','Certifications','Branch Information','Point Of Contact','Product Categories','Distribution Network','Financial Information'];
  numberOfCircles = 10;
  circles: any[] = Array(this.numberOfCircles).fill(0);
  previewImage: any = null;
  step = 0;
  showDistributorDetails: boolean = false;
  isFileInputClickedCompanyLogo: boolean = false;
  isFileInputClickedCrCopy: boolean = false;
  isFileInputClickedBusinessLicense: boolean = false;
  isFileInputClickedEuropeanCertificates: boolean = false;
  isFileInputClickedOtherCertificates: boolean = false;
  continents: any[] = [];

  data: CombinedData = {
    company: {
      name: '',
      email: '',
      companyName: '',
      brandName: '',
      establishedIn: new Date(),
      numberOfEmployees: 0,
      websiteUrl: '',
      companyLogo: new File([''], ''),
      continent: '',
      country: '',
      state: '',
      city: '',
      address: '',
      zipCode: 0,
      crCopy: new File([''], ''),
      businessLicense: new File([''], ''),
      europeanCertificates: new File([''], ''),
      otherCertificates: [],
      subscriptionPlan: '',
      hasBranch: false
    },
    distributors: {
      continent: '',
      country: '',
      state: '',
      city: '',
      distributorCompany: ''
    },
    contacts: [],
    newDistributors: [],
    productCategories: [],
    financials: [],
    branches: []
  };

  constructor(private distributorService: DistributorService, private http: HttpClient) {}

  ngOnInit() {
    this.getContinents();
  }

  toggleDistributorDetails(event: any) {
    this.showDistributorDetails = event.target.value === 'yes';
  }

  onFileSelected(event: any, fileType: string) {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.uploadFiles(files, fileType);
  
      // Read and display the preview image
      const reader = new FileReader();
      reader.readAsDataURL(files[0]); // Read only the first file
      reader.onload = () => {
        // Set the previewImage variable to the data URL
        this.previewImage = reader.result as string;
      };
    }
    
  }

  removeLogo() {
    this.previewImage = null;
    this.data.company.companyLogo = new File([''], '');
  }

  
  uploadFiles(files: FileList, fileType: string) {
    switch (fileType) {
      case 'companyLogo':
        this.data.company.companyLogo = files[0];
        this.isFileInputClickedCompanyLogo = true;
        break;
      case 'crCopy':
        this.data.company.crCopy = files[0];
        this.isFileInputClickedCrCopy = true;
        break;
      case 'businessLicense':
        this.data.company.businessLicense = files[0];
        this.isFileInputClickedBusinessLicense = true;
        break;
      case 'europeanCertificates':
        this.data.company.europeanCertificates = files[0];
        this.isFileInputClickedEuropeanCertificates = true;
        break;
        case 'otherCertificates':
          if (!this.data.company.otherCertificates) {
              this.data.company.otherCertificates = [];
          }
          for (let i = 0; i < files.length; i++) {
              this.data.company.otherCertificates.push(files[i]);
          }
          this.isFileInputClickedOtherCertificates = true;
          break;
      default:
        console.log('Invalid fileType');
    }
  }
  removeFile(fileType: string) {
    
    switch (fileType) {
      case 'companyLogo':
        this.data.company.companyLogo = new File([''], '');
        this.isFileInputClickedCompanyLogo = false;
        break;
      case 'crCopy':
        this.data.company.crCopy = new File([''], '');
        this.isFileInputClickedCrCopy = false;
        break;
      case 'businessLicense':
        this.data.company.businessLicense = new File([''], '');
        this.isFileInputClickedBusinessLicense = false;
        break;
      case 'europeanCertificates':``
        this.data.company.europeanCertificates = new File([''], '');
        this.isFileInputClickedEuropeanCertificates = false;
        break;
      default:
        console.log('Invalid fileType');   
    }
  }
  removeFiles(fileType: string, file: File) {
    switch (fileType) {
        case 'otherCertificates':
            this.data.company.otherCertificates = this.data.company.otherCertificates.filter(f => f !== file);
            if (this.data.company.otherCertificates.length === 0) {
                this.isFileInputClickedOtherCertificates = false;
            }
            break;
        default:
            console.log('Invalid fileType');
    }
}
  getContinents() {
    this.distributorService.getContinents().subscribe(
      (data: any) => {
        this.continents = data.data;
      },
      (error) => {
        console.log('Error fetching continents:', error);
      }
    );
  }
  deleteBranch(index: number) {
    this.data.branches.splice(index, 1);
  }
  addBranch() {
    this.data.branches.push({});
  }

  addContact() {
    this.data.contacts.push({
      country: '',
      state: '',
      city: '',
      name: '',
      position: '',
      email: '',
      phone: ''
    });
  }
  deleteContact(index: number) {
    this.data.contacts.splice(index, 1);
  }

  addCategory() {
    this.data.productCategories.push({
      primaryCategory: '',
      secondaryCategory: '',
      tertiaryCategory: '',
      productRange: 0
    });
  }
  addDistributor() {
    this.data.newDistributors.push({
      companyName: '',
      emailAddress: '',
      phoneNumber: '',
      address: ''
    });
  }
  deleteDistributor(index: number) {
    this.data.newDistributors.splice(index, 1);
  }
  addFinancial() {
    this.data.financials.push({
      year: '',
      currency: '',
      networth: '',
      turnover: 0,
      profit: 0,
      growth: ''
    });
  }
  deleteFinancial(index: number) {
    this.data.financials.splice(index, 1);
  }
  next() {
    this.step++; 
  }
  goBack() {
    this.step--;
  }
  onSubmit() {
    this.http.post<CombinedData>('http://localhost:8089/onboarding/OEM/', this.data.company).subscribe(
      (data) => console.log('Data:', data.company),
      (error) => console.log('Error:', error)
    );
  }


  
}

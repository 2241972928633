import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TodoItemNode } from "src/app/models/product-settings-model";

@Injectable({
  providedIn: 'root'
})
export class ChecklistDatabase {

  dataChange = new BehaviorSubject<TodoItemNode[]>([]);
  get data(): TodoItemNode[] { return this.dataChange.value; }

  initialize(TREE_DATA: any[]) {
    const data = this.buildFileTree(TREE_DATA, 0, null);
    console.log("data", data);
    this.dataChange.next(data);
  }

  buildFileTree(obj: any[], level: number, parentId: string | null): TodoItemNode[] {
    return obj.map((item, index) => {
      const node = new TodoItemNode();
      node.item = item.name;
      node.id = item.id;
      node.image = item.image;
      node.children = item.subcategories ? this.buildFileTree(item.subcategories, level + 1, item.id) : [];
      return node;
    });
  }

  insertItem(parent: TodoItemNode, name: string, image : string, disabled : boolean) {

    if (parent.children) {
      const newItem = { item: name, id: this.generateUUID(), image:image, disabled: disabled, children: [] } as TodoItemNode;
      parent.children.push(newItem);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string,  image : string) {
    node.item = name;
    this.dataChange.next(this.data);
  }

  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
 
}


import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { ChangePasswordRequest, UserDetails } from 'src/app/models/authData';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { DasboardService } from 'src/app/services/super-admin/dasboard.service';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-super-admin-profile',
  templateUrl: './super-admin-profile.component.html',
  styleUrls: ['./super-admin-profile.component.scss']
})
export class SuperAdminProfileComponent implements OnInit {
  userName!: string;
  roleName!: string;
  profilePhoto!: SafeUrl;
  profilePhotoString!: string;
  passwordForm!: FormGroup;
  userForm!: FormGroup;
  isDelete: boolean = false;
  userData!: UserDetails;
  selectedFile: File | null = null;
  isSaveButtonEnabled = false;

  private readonly imageUrl: string = 'assets/images/icons/user-circle.svg';

  constructor(private localStorageService: LocalStorageService, private formBuilder: FormBuilder, private authService: AuthService, private snackBar: MatSnackBar, private renderer: Renderer2, private fileUploadService: FileUploadService, private dashboardService: DasboardService, private sanitizer: DomSanitizer,
    private http: HttpClient) { }
  /**
   * Lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
   */

  ngOnInit(): void {
    this.renderer.setStyle(document.querySelector('.mat-mdc-text-field-wrapper'), 'height', 'initial');
    this.roleName = this.localStorageService.getItem('roleName');
    
    this.initForm();
  }

  previewImage(input: HTMLInputElement): void {
    const file: File | null = input.files ? input.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.profilePhoto = event.target.result;
        this.isSaveButtonEnabled = true; // Enable save button when file is selected
      };
      reader.readAsDataURL(file);
      this.selectedFile = file;
    }
  }


  initForm(): void {
    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.checkPasswords });

    
    this.localStorageService.getUserDetails().subscribe(userDetails => { 
      this.userData = userDetails;
      this.userName = userDetails.userName;
      this.profilePhotoString = userDetails.profilePic;
      this.profilePhoto = this.profilePhotoString
        ? this.sanitizer.bypassSecurityTrustUrl(this.profilePhotoString)
        : this.sanitizer.bypassSecurityTrustUrl(this.imageUrl);

      this.userForm = this.formBuilder.group({
        username: [this.userData.userName],
        email: [{ value: this.userData.email, disabled: true }],
        profilePhoto: [this.userData.profilePic]
      });

      this.trackFormChanges(); // Track form changes after initialization
    });
  }

  checkPasswords(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
  

    // Check if passwords match
    const passwordsMatch = newPassword === confirmPassword;
  
    // Check for password complexity
    const passwordRegex = /^(?!\s)(?!.*\s$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-])[\w$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]{8,256}$/;
    const isPasswordComplex = passwordRegex.test(newPassword);
    // Set passwordRequirements error if password doesn't meet complexity requirements
    // if (!isPasswordComplex) {
    //   group.get('newPassword')?.setErrors({ passwordRequirements: true });
    // }
    
    // Return appropriate validation error
    return passwordsMatch ? null : { notSame: true };
  }

  trackFormChanges(): void {
    this.userForm.valueChanges.subscribe(() => {
      const userNameChanged = this.userForm.get('username')?.value !== this.userData.userName;
      const profilePicChanged = this.userForm.get('profilePhoto')?.value !== this.userData.profilePic;
      this.isSaveButtonEnabled = userNameChanged || profilePicChanged || !!this.selectedFile;
    });
  }

  onSubmit(): void {
    
    if (this.passwordForm.valid) {
      const formData = this.passwordForm.value as ChangePasswordRequest;
      formData.email = this.userData.email;
      formData.token = localStorage.getItem('accessToken') ?? '';
      delete formData['confirmPassword'];
      this.authService.changePassword(formData).subscribe({
        next: (response) => {
          this.snackBar.open('Password changed successfully', 'Close', {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: 'end', // Positioning from left/right
            verticalPosition: 'top', // Positioning from top/bottom
            panelClass: ['custom-snackbar'] // Custom CSS class
          });
        },
        error: (error) => {
          this.snackBar.open('Failed to change password. Please try again.', 'Close', {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: 'end', // Positioning from left/right
            verticalPosition: 'top' // Positioning from top/bottom
          });
        }
      });

    } else {
      this.passwordForm.markAllAsTouched();
    }
  }


  updateUserInfo(): void {
    if (this.profilePhoto && this.selectedFile) {
      this.uploadFile(this.selectedFile).subscribe({
        next: (uploadedUrl) => {
          // Update profile picture URL
          this.userData.profilePic = uploadedUrl;
          this.profilePhoto = uploadedUrl; 
          // Save profile with updated profile picture URL
          this.saveProfile();
        },
        error: (error) => {
          this.saveProfile();
        }
      });
    } else {
      // No profile picture change or no selected file, proceed to save the profile without modifying the picture URL
      this.saveProfile();
    }
  }

  private saveProfile(): void {
    const updateProfile = {
      userId: this.userData.userId,
      email: this.userData.email,
      userName: this.userForm.get('username')?.value,
      profilePic: this.userData.profilePic // Use the updated profile picture URL
    };
    this.dashboardService.saveSuperAdminProfile(updateProfile).subscribe({
      next: (response) => {
        this.userData.userName = updateProfile.userName;
        this.localStorageService.updateUserDetails(this.userData);
        this.snackBar.open('Profile updated successfully', 'Close', {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: 'end', // Positioning from left/right
          verticalPosition: 'top', // Positioning from top/bottom
          panelClass: ['custom-snackbar'] // Custom CSS class
        });
        this.isSaveButtonEnabled = false; // Disable the button after saving
      },
      error: (error) => {
      }
    });
  }

  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', this.userData.userName + '/profile_picture/');
    
    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          // Update user details with the new profile photo URL
          // this.userData.profilePic = response.data;
          // this.localStorageService.updateUserDetails(this.userData);
          return response.data; // Assuming response.data contains the URL
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }
  removeFile(filePath: string): Observable<void> {
    return this.http.delete<void>(`/api/files/remove?path=${encodeURIComponent(filePath)}`);
  }

  // Add this method to your component
deleteProfilePhoto(): void {
  this.profilePhoto = this.sanitizer.bypassSecurityTrustUrl(this.imageUrl); // Default image or empty
  this.selectedFile = null;

  // You may want to remove the photo from the server here as well
  this.removeFile(this.userData.userName + '/profile_picture/').subscribe({
    next: () => {
      // Optionally update user details or handle UI updates
      this.userData.profilePic = '';
      this.saveProfile();
      this.snackBar.open('Profile photo removed successfully', 'Close', {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar']
      });
    },
    error: (error: any) => {
      this.snackBar.open('Failed to remove profile photo. Please try again.', 'Close', {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'top'
      });
    }
  });
  
}

handleFileInputClick(): void {
  const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
  if (fileInput) {
    fileInput.click();
    
  }
}

handleFileInputChange(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    this.isDelete=true;
    this.previewImage(input);
  }
}

handleDeleteButtonClick(): void {
  // this.isSaveButtonEnabled = true;
  this.userData.profilePic = this.imageUrl;
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {
      this.isDelete=false;
      this.saveProfile();
    }
  });
  // this.deleteProfilePhoto();
}



  
}

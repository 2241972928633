<div *ngIf="!showAddExhibition && !updateExhibition && !showExhibition ">
  <app-mwe-table  [showAddButton]=true [showSearch]="true" [showFilter]="true"  [columns]="columns" [title]="'Exhibitions'" [showHeader]="true" [data]="Data" (selectedAction)="selectedAction($event)"></app-mwe-table>
</div>
<h2 *ngIf="showNoRecordsFounds " class="no-records">No records found</h2>

  <div *ngIf="showAddExhibition"> 
    <app-addexhibition  [oemId]="oemId" (backEvent)="close($event)"  (selectedAction)="selectedAction($event)" [UpdateExhibibitionid]="UpdateExhibibitionid" [updateExhibition]="updateExhibition"></app-addexhibition>
  </div>
  <div *ngIf="updateExhibition">
    <app-addexhibition  (backEvent)="close($event)"  (selectedAction)="selectedAction($event)" [UpdateExhibibitionid]="UpdateExhibibitionid" [updateExhibition]="updateExhibition" ></app-addexhibition>
  </div>

  <div *ngIf="showExhibition">
    <app-viewexhibition (backEvent)="close($event)"  (selectedAction)="selectedAction($event)"  [viewExhibitionId]="viewExhibitionId" ></app-viewexhibition>
  </div>
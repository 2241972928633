<div class="page-heading" style="cursor: pointer;" (click)="onCancel()">Back to all jobs</div>
<div class="post-job-container">
    <h2 class="page-heading"> Post a Job</h2>

    <div class="row">
        <div class="col-lg-9">

            <form [formGroup]="postJobForm" class="row" style="padding: 20px;" (ngSubmit)='onSubmit()'>
                <!-- Lead Source -->



                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Job Title
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput formControlName="title" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('title')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Job Title is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12 mb-2 mt-1">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Add
                            Featured Image
                        </p>
                    </div>

                    <div class="upload-asset" (click)="fileInput.click()" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event , fileInput)">
                        <img *ngIf="previewImage; else addFilesIcon" [src]="previewImage" alt="logo"
                            class="preview-image">
                        <ng-template #addFilesIcon>
                            <img src="assets/images/add-files.svg" alt="logo" height="20px" width="20px">
                        </ng-template>
                        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileInput
                            style="display: none;" />
                        <span *ngIf="!isFileInputClickedCompanyLogo" class="logo-text">
                            Select File
                        </span>
                        <mat-icon *ngIf="isFileInputClickedCompanyLogo" aria-hidden="false" class="ms-2"
                            aria-label="Example home icon" fontIcon="cancel" (click)="removeLogo($event)"></mat-icon>
                    </div>

                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Job
                            Category
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="category" placeholder="Select" required>
                                <mat-option *ngFor="let jobCategory of jobCategories"
                                    [value]="jobCategory.categoryName">{{
                                    jobCategory?.categoryName }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('category')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Job Category is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Specialities
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="speciality" placeholder="Select" required>
                                <mat-option *ngFor="let speciality of specialities" [value]="speciality.specialty">{{
                                    speciality?.specialty }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('speciality')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Specialities is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Skills
                            Required
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="skills" multiple required>
                                <mat-option *ngFor="let skill of jobSkills" [value]="skill.skillName">{{
                                    skill?.skillName }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('skills')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Skills is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Job
                            Type
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="jobType" placeholder="Select" required>
                                <mat-option *ngFor="let jobTypeOption of jobTypes" [value]="jobTypeOption.jobType">{{
                                    jobTypeOption?.jobType }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('jobType')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Job
                                Type is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Career Level
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="careerLevel" placeholder="Select" required>
                                <mat-option *ngFor="let careerLevel of careerLevels"
                                    [value]="careerLevel.experienceLevel">{{
                                    careerLevel?.experienceLevel }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('careerLevel')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Career Level is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Years of Experience
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <!-- <input matInput type="number" formControlName="experience" placeholder="Select" required> -->
                            <input matInput type="text" formControlName="experience" required onlyNumbers >
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('experience')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Experience is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Degree Level
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="degreeLevel" placeholder="Select" required>
                                <mat-option *ngFor="let degreeLevelOption of degreeLevels"
                                    [value]="degreeLevelOption.degreeLevel">{{
                                    degreeLevelOption?.degreeLevel }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('degreeLevel')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Degree Level is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Tags
                        </p>
                    </div>
                    <div>
                
                        <mat-form-field appearance="outline" class="w-100 input-font equipment" [class.equipment_increase]="jobTagOptionsLength > 5">
                            <mat-select placeholder="Select" formControlName="tags" multiple>
                              <mat-select-trigger>
                                <mat-chip-listbox>
                                  <mat-chip *ngFor="let jobTag of postJobForm.get('tags')?.value; let i = index" [removable]="true" (removed)="removeTag(i)">
                                    {{ jobTag }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                  </mat-chip>
                                </mat-chip-listbox>
                              </mat-select-trigger>
                              <mat-option *ngFor="let jobTagOption of jobTagOptions" [value]="jobTagOption.jobTags">
                                {{ jobTagOption?.jobTags }}
                              </mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg" *ngIf="postJobForm.get('tags')?.hasError('required')">
                              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                              Tags are Required
                            </mat-error>
                          </mat-form-field>
                          
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium input-font m-0">
                            Description
                        </p>
                    </div>
                    <div >
                        <!-- <mat-form-field appearance="outline" class="input-font w-100">
                            <textarea matInput formControlName="description" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="10"
                                required></textarea>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('description')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Description is Required
                            </mat-error>
                        </mat-form-field> -->
                        <angular-editor formControlName="description" [config]="config"></angular-editor>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Content
                        </p>
                    </div>
                    <div>
                        <angular-editor formControlName="content" [config]="config"></angular-editor>
                    </div>
                </div>

                <div class="col-lg-12 mt-4">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Company
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput type="text" formControlName="company" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('company')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Company is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Posted by
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput type="text" formControlName="postedBy" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('postedBy')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Posted by is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Posted
                            Email Address
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput formControlName="postedEmail" type="email" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('postedEmail')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Posted Email Address is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Total Positions
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput type="number" formControlName="totalPositions" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('totalPositions')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Total Positions is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Address
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput formControlName="address" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('address')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Address is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Country
                        </p>

                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input type="text" placeholder="Select" aria-label="Number" matInput
                                formControlName="country" #autoCompleteCountryInput="matAutocompleteTrigger"
                                [matAutocomplete]="countryAuto">
                            <mat-autocomplete #countryAuto="matAutocomplete"
                                (optionSelected)="onCountrySelected($event)">
                                <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName">
                                    {{ option?.countryName }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('country')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Country is Required
                            </mat-error>
                            <span class="custom-dropdown-arrow">&#9662;</span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            State
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="state"
                                [matAutocomplete]="stateAuto">
                            <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelected($event)">
                                <mat-option *ngFor="let option of stateDropdown" [value]="option?.stateName">
                                    {{ option?.stateName }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('state')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                State is Required
                            </mat-error>
                            <span class="custom-dropdown-arrow">&#9662;</span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            City
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="fields input-font w-100">
                            <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="city"
                                [matAutocomplete]="cityAuto">
                            <mat-autocomplete #cityAuto="matAutocomplete">
                                <mat-option *ngFor="let option of cityDropdown" [value]="option?.cityName">
                                    {{ option?.cityName }}

                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('city')?.hasError('required')"><img class="error-icon"
                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> City is
                                Required</mat-error>
                                <span class="custom-dropdown-arrow">&#9662;</span>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12 mb-3">
                    <div class="form-check form-switch d-flex">
                      <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="hideSalary" 
                        formControlName="hideSalary" 
                        >
                      <label class="form-check-label" for="hideSalary">Hide Salary</label>
                    </div>
                  </div>
                  
                <div class="col-lg-3">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Salary Currency
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="salaryCurrency" placeholder="Select" required>
                                <mat-option *ngFor="let country of countries" [value]="country.currency">{{
                                    country?.currency }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('salaryCurrency')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Salary Currency is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Salary Range
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select formControlName="salaryRange" placeholder="Select" required>
                                <mat-option *ngFor="let salaryRange of salaryRanges" [value]="salaryRange">{{
                                    salaryRange }}</mat-option>
                            </mat-select>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('salaryRange')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Salary Range is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Salary From
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput type="number" formControlName="salaryFrom"
                                [max]="getSalaryValue('salaryTo')" required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('salaryFrom')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Salary From is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Salary To
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <input matInput type="number" formControlName="salaryTo" [min]="getSalaryValue('salaryTo')"
                                required>
                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('salaryFrom')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Salary To is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Application Start Date
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                            <input matInput [matDatepicker]="applicationStartDate"
                                formControlName="applicationStartDate" placeholder="dd/mm/yyyy" required>
                            <mat-datepicker-toggle matIconSuffix [for]="applicationStartDate"></mat-datepicker-toggle>
                            <mat-datepicker #applicationStartDate color="primary"></mat-datepicker>

                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('applicationStartDate')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Application Start Date is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div>
                        <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
                            Application Close Date
                        </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                            <input matInput [matDatepicker]="applicationCloseDate"
                                formControlName="applicationCloseDate" placeholder="dd/mm/yyyy" required>
                            <mat-datepicker-toggle matIconSuffix [for]="applicationCloseDate"></mat-datepicker-toggle>
                            <mat-datepicker #applicationCloseDate color="primary"></mat-datepicker>

                            <mat-error class="error-message error-msg"
                                *ngIf="postJobForm.get('applicationCloseDate')?.hasError('required')">
                                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                                Application Close Date is Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-12 mb-3">
                    <div class="form-check form-switch d-flex">
                      <input class="form-check-input" type="checkbox" id="postAnonymously" 
                             formControlName="postAnonymously" >
                      <div class="form-check-label" for="postAnonymously">
                        Hide my company details (Post anonymously)
                      </div>
                    </div>
                  </div>

                <div class="button-container">
                    <button type="submit" class="button button-primary"  *ngIf="!isUpdateJob">Publish Job</button>
                    <button type="submit" class="button button-primary"  *ngIf="isUpdateJob">Update Job</button>
                    <button type="button" class="button button-primary" (click)="onPreview()"
                        *ngIf="!isUpdateJob">Preview</button>
                    <button style="height: 40px;" type="button" class="button button-inverse"
                        (click)="onCancel()">Cancel</button>
                </div>

            </form>

        </div>
    </div>

</div>
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PlanType } from 'src/app/enums/oem-management-enum';
import { OemDetails } from 'src/app/models/oem-models';
import { SubScriptionDetails } from 'src/app/models/subscription-model';
import { SubscriptionsService } from 'src/app/services/super-admin/subscriptions.service';
import { FileUtility } from 'src/app/utils/FileUtility';

@Component({
  selector: 'app-view-company-info',
  templateUrl: './view-company-info.component.html',
  styleUrls: ['./view-company-info.component.scss']
})
export class ViewCompanyInfoComponent implements OnChanges{

  @Input() oemData!:OemDetails;
  subscriptionName: string = '';
  subscriptionPlans: SubScriptionDetails[] = [];


  constructor(private subscriptionService: SubscriptionsService){}
  async ngOnChanges(changes: SimpleChanges) {
    await this.getSubsctiptionStatus();
    this.getSubscriptionDetails();
  }


  getPlanImage(subscriptionId: string): string {
    const subscription = this.subscriptionPlans.find(sub => sub.id === subscriptionId);
    return subscription?.image || '';
  }

  public getFileNameFromUrl(url: string | undefined): string | null {
    return url? FileUtility.getFileNameFromUrl(url) : '';
  }

  private getSubscriptionDetails() {
    if(!this.oemData?.companyInformation?.subscriptionInfoId) return
    this.subscriptionService.getSubscriptionById(this.oemData?.companyInformation?.subscriptionInfoId).subscribe(res => {
      this.subscriptionName = res.data?.name;
    })
  }

  getSubsctiptionStatus(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subscriptionService.getAllSubscriptions().subscribe(data => {
        this.subscriptionPlans = data.data;
        resolve();
      })
    })
  }

}

<div>
  <app-mwe-table
    [showHeader]="false"
    [columns]="columns"
    [data]="OEMsNetwork"
    [showPagination]="false"
    (selectedAction)="selectedAction($event)"
    [isDynemicPagination]="true"
  ></app-mwe-table>
</div>
 
<div>
  <button 
    class="mwe-button mwe-button--primary m-3" 
    (click)="assignMoreOEMs()">
    {{ isText ? 'Assign more OEMs' : 'Assign OEMs' }}
  </button>

  <div class="mt-1 mb-1">
    <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
      <span> 
        Didn't find your OEMs? 
        <button 
          class="more" 
          (click)="addNewOEM()" 
          style="text-decoration: underline; border: none;">
          Add new OEM
        </button>
      </span>
    </p>
  </div>
</div>

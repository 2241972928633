import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { CareerService } from 'src/app/services/career.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-job-applicant-details',
  templateUrl: './view-job-applicant-details.component.html',
  styleUrls: ['./view-job-applicant-details.component.scss']
})
export class ViewJobApplicantDetailsComponent implements OnInit {

  jobApplicant: any = {};

  downloadProfile = false;

  specialisations: any = [];

  skillSets: any = [];

  jobPostId: any;

  workExpColumns: ListColumn[] = [
    { name: 'Designation', property: 'designation', visible: true, isModelProperty: true, isText: true },
    { name: 'From Year', property: 'yearFrom', visible: true, isModelProperty: true, isText: true },
    { name: 'To Year', property: 'yearTo', visible: true, isModelProperty: true, isText: true },
    { name: 'Organization', property: 'organization', visible: true, isModelProperty: true, isText: true },
    { name: 'Specialization', property: 'specialization', visible: true, isModelProperty: true, isText: true },
  ] as ListColumn[];

  publicationColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'URL', property: 'url', visible: true, isModelProperty: true, isLink: true },
    { name: 'Published By', property: 'publishedby', visible: true, isModelProperty: true, isText: true, },
    { name: 'Author(s)', property: 'authors', visible: true, isModelProperty: true, isText: true },
    { name: 'Publication Date', property: 'publicationDate', visible: true, isModelProperty: true, isDatePipe: true },
  ] as ListColumn[];

  awardsColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'Association', property: 'association', visible: true, isModelProperty: true, isText: true },
    { name: 'Award Date', property: 'awardDate', visible: true, isModelProperty: true, isDatePipe: true },
  ] as ListColumn[];

  coursesColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'Institution', property: 'institution', visible: true, isModelProperty: true, isText: true },
    { name: 'Course Date', property: 'courseDate', visible: true, isModelProperty: true, isDatePipe: true },
  ] as ListColumn[];

  associationColumns: ListColumn[] = [
    { name: 'Association Name', property: 'associationName', visible: true, isModelProperty: true, isText: true },
    { name: 'Position Held', property: 'positionHeld', visible: true, isModelProperty: true, isText: true },
    { name: 'From', property: 'fromDate', visible: true, isModelProperty: true, isDatePipe: true },
    { name: 'To', property: 'toDate', visible: true, isModelProperty: true, isDatePipe: true },
  ] as ListColumn[];

  volunteeringColumns: ListColumn[] = [
    { name: 'Organization Name', property: 'organizationName', visible: true, isModelProperty: true, isText: true },
    { name: 'Position Held', property: 'positionHeld', visible: true, isModelProperty: true, isText: true },
    { name: 'From', property: 'fromDate', visible: true, isModelProperty: true, isDatePipe: true },
    { name: 'To', property: 'toDate', visible: true, isModelProperty: true, isDatePipe: true },
  ] as ListColumn[];

  constructor(private readonly careerService: CareerService,
    private snackbarService: SnackbarService, private endCustomerService: EndCustomerService,
    private readonly router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['download'] === 'true') {
        this.downloadProfile = true;
      }
    });
    this.getSpecialities();
    this.getSkillSets();
    this.getJobApplicantDetails();
  }

  private getJobApplicantDetails() {
    const routerSegments = this.router.url.split('?')[0].split('/');
    this.jobPostId = routerSegments[routerSegments.length - 2];
    const applicantId: any = routerSegments[routerSegments.length - 1];
    this.jobApplicant = {};
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat('/', this.jobPostId, "/applicant/", applicantId), null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          this.jobApplicant = response?.data;
          const lastUrlSegment = this.router.url.split('?')[0].split('/').pop();
          if (lastUrlSegment?.includes('download')) {
            this.downloadProfile = true;
          }
        }
      }, error: (error: any) => {
      }
    });
  }

  public async downloadPdf(): Promise<void> {
    const element = document.getElementById("careers");
    const jobApplicant = this.jobApplicant.name;

    if (!element) {
        console.error("Element not found");
        return;
    }

    const pdf = new jsPDF('p', 'mm', 'a4');
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const usableWidth = pageWidth - 2 * margin;
    const usableHeight = pageHeight - 2 * margin;

    try {
        const canvas = await html2canvas(element, { scale: 2 });
        const imgData = canvas.toDataURL('image/jpeg', 0.8);
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = usableWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        let currentYOffset = 0;

        while (currentYOffset < canvas.height) {
            const pageCanvas = document.createElement('canvas');
            pageCanvas.width = canvas.width;
            pageCanvas.height = canvas.height * (usableHeight / imgHeight);

            const ctx = pageCanvas.getContext('2d');
            if (ctx) {
                // Ensure the background is white
                ctx.fillStyle = "#FFFFFF";
                ctx.fillRect(0, 0, pageCanvas.width, pageCanvas.height);

                ctx.drawImage(
                    canvas,
                    0, currentYOffset,
                    canvas.width, canvas.height * (usableHeight / imgHeight),
                    0, 0,
                    pageCanvas.width, pageCanvas.height
                );

                const pageImgData = pageCanvas.toDataURL('image/jpeg');
                pdf.addImage(pageImgData, 'JPEG', margin, margin, imgWidth, usableHeight);

                currentYOffset += canvas.height * (usableHeight / imgHeight);

                if (currentYOffset < canvas.height) {
                    pdf.addPage();
                    pdf.setFontSize(14);
                }
            }
        }

        pdf.save('job-applicant.pdf'); 
    } catch (error) {
        console.error("Error generating PDF", error);
    }
}


  private getSpecialities() {
    this.endCustomerService.executeGetRequestURL(MweConstants.specialtyUrl, null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          this.specialisations = response?.data;
        }
      }, error: (error: any) => {
      }
    });
  }

  private getSkillSets() {
    this.endCustomerService.executeGetRequestURL(MweConstants.skillSetUrl, null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          this.skillSets = response?.data;
        }
      }, error: (error: any) => {
      }
    });
  }

  getSpecialityName(id: any) {
    return this.specialisations.find((obj: any) => obj?.id === id)?.name;
  }

  getSkillSetName(id: any) {
    return this.skillSets.find((obj: any) => obj?.id === id)?.name;
  }

  getSpecifiedData(dataType: string) {
    return this.jobApplicant.moreInfos.flatMap((moreInfo: any) => moreInfo[dataType]);
  }

  updateStatus(applicationStatus: string) {
    const jobApplicationRequest = { jobPostid: this.jobPostId, applicantId: this.jobApplicant.id, applicationStatus };
    this.careerService.executePatchRequest(MweConstants.getJobPostUrl.concat("/application"), jobApplicationRequest).subscribe({
      next: () => {
        Swal.fire('', 'Application status updated successfully !!', 'success');
      }, error: (error: any) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  onBack() {
    this.router.navigate(['../../..'], { relativeTo: this.route });
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-resume-profile',
  templateUrl: './resume-profile.component.html',
  styleUrls: ['./resume-profile.component.scss']
})
export class ResumeProfileComponent {

  @Input() resumesForm!:FormGroup;
  @Input() endCustomerData:any;
  @Input() accountType:any;
  @Output() onUpdateResume:EventEmitter<any>=new EventEmitter();

  showEditForm:boolean=false;
  copyResumeForm:any;
  data:any=[];

  resumeIcon= "assets/images/pdf-icon.svg";

  ngOnInit(): void {

    this.data=JSON.parse(JSON.stringify( this.resumesForm.get('resumes')?.value));
   
  }

  constructor(private fb:FormBuilder,private endCustomerService:EndCustomerService, private sweetalertService:SweetAlertService){}
  addResume(){
    this.copyResumeForm=JSON.parse(JSON.stringify(this.resumesForm.value));
    this.resumes.push( this.fb.group({
      "name"  :new FormControl(null),
         "resume":new FormControl(null),
         fileName:new FormControl(null)
     }),)
     this.showEditForm=true;
  }

  editResume(){
    this.copyResumeForm=JSON.parse(JSON.stringify(this.resumesForm.value));
    this.showEditForm=true;
  }

  update(){
   this.updateResumes();
   this.resumes.markAllAsTouched();
   if(this.resumes.invalid){
     return;
   }
   this.onUpdateResume.emit(150);
   this.refresh();
  }
  

  refresh(){
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.resumesForm.get('resumes')?.value));
  } 




  deleteResume(i:number){
    this.resumes.removeAt(i);
  }
  get resumes() {
    return this.resumesForm.controls["resumes"] as FormArray;
  }
  
  uploadResume(event:any,i:number){
    let uploadData = new FormData();
    uploadData.append('file', event.target.files[0]);
    let randomNumber = Math.floor(Math.random() * 10) + 1
    uploadData.append('destinationKey', randomNumber+'');

    const fileExtension = event.target.files[0].name.split('.').pop()?.toLowerCase();
    console.log(fileExtension);
    
    if (fileExtension !== 'pdf' && fileExtension !== 'docx') {
      this.sweetalertService.showError('Error', 'Only PDF and DOCX files are allowed');
      return;
    }

    this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
      if(data && data?.data){
       this.resumes.at(i).get('resume')?.patchValue(data.data);
       this.resumes.at(i).get('fileName')?.patchValue(event.target.files[0].name);
      }
      
    },error=>{

    })
  }
 
    
  cancel(){
    (this.resumesForm.get('resumes')as FormArray).clear();
     if(this.copyResumeForm?.resumes && this.copyResumeForm.resumes.length>0){
          for(let i=0;i<this.copyResumeForm.resumes.length;i++){
            (this.resumesForm.get('resumes')as FormArray).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "name"  :new FormControl(null),
              "resume":new FormControl(null),
              fileName:new FormControl(null)
            }));
          }
          (this.resumesForm.get('resumes')as FormArray).patchValue(this.copyResumeForm.resumes);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.resumesForm.get('resumes')?.value));
  }
  
updateResumes(){
  for(let i=0;i<this.resumes.controls.length;i++){
    if(!(this.resumes.at(i).get('name')?.value==null || this.resumes.at(i).get('name')?.value=='')
      ||
      this.resumes.at(i).get('resume')?.value!=null  ){
        
      this.resumes.at(i).get('name')?.setValidators([Validators.required]);
      this.resumes.at(i).get('resume')?.setValidators([Validators.required]);

    }else{
      this.resumes.at(i).get('name')?.clearValidators();
      this.resumes.at(i).get('resume')?.clearValidators();
    
    }
    this.resumes.at(i).get('name')?.updateValueAndValidity();
    this.resumes.at(i).get('resume')?.updateValueAndValidity();
  }
}

viewResume(link:string){
  window.open(link,'_blank');
}

}

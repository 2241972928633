<button class="header-btn m-2 add-btn" mat-raised-button (click)="back()">
    <mat-icon>arrow_back</mat-icon> Back
</button>

<div class="row mt-4 p-2">
  <div class="col-lg-6">
    <div class="card p-4">
      <h4 class="texthead col-lg-3 ps-3">Information</h4>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Event Title</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.title }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Start Date</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.exhibitionStartDate }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>End Date</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.exhibitionEndDate }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Description/Label</p>
        </div>
        <div class="col-lg-8">
          <div class="col-lg-8 typores">
            {{ data.description }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Images</p>
        </div>
        <div class="col-lg-8">
            <img [src]='data.files[0]' alt="Event Image" style="max-width: 100%; height: auto;">
          </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card p-4">
      <h4 class="texthead col-lg-3 ps-3">Address Info</h4>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Book Tickets URL</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.bookTicketsURL }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Facebook URL</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.fbURL }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>LinkedIn URL</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.linkedInURL }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Twitter URL</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.twitterURL }}
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 typo">
          <p>Other URL</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.otherURL }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Assign Staff</p>
        </div>
        <!-- <div class=" "> -->
          <div class="col" style="margin-top: 0px; display: flex; flex-wrap: wrap;" >  <span class="staff mx-1" *ngFor="let staff of data.staffNames" style="padding: 10px; margin: 1px; font-size: 1em;">  {{staff}} </span> </div>

        <!-- </div> -->
      </div>
    </div>
  </div>
</div>

<div class="row mt-4 p-2">
  <div class="col-lg-6">
    <div class="card p-4">
      <h4 class="texthead col-lg-3 ps-3">Address</h4>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Country</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.country }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>State</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.state }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Address</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.address }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 typo">
          <p>Zip Code</p>
        </div>
        <div class="col-lg-8 typores">
          {{ data.postalCode }}
        </div> 
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card p-4">
      <h4 class="texthead col-lg-3 ps-3">Appointment</h4>
      <div *ngFor="let appointment of data.appointments; let i = index" class="d-flex flex-column mb-2" style="border: 2px solid #0d87c8; padding: 10px; border-radius: 10px;">
        <div class="typo">
          <p (click)="toggleAppointmentDetails(i)" style="cursor: pointer; color: #0d87c8; text-align: center; padding-top: 10px;">
            Day {{ i + 1 }}: {{ appointment.appointmentDate }}
          </p>
        </div>
        <div *ngIf="selectedAppointmentIndex === i" class="typores d-flex flex-column">
          <p>Start Time: {{ appointment.startTime || '---' }}</p>
          <p>End Time: {{ appointment.endTime || '---' }}</p>
          <p>Break Time: {{ appointment.breakTime || '---' }}</p>
          <p>Per Slot Time: {{ appointment.perSlotTime || '---' }}</p>
        </div>
        
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { EndCustomerService } from 'src/app/services/end-customer.service';

import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
@Component({
  selector: 'app-company-product-categories',
  templateUrl: './company-product-categories.component.html',
  styleUrls: ['./company-product-categories.component.scss']
})
export class CompanyProductCategoriesComponent implements OnInit {
  @Input() productCategoriesForm!: FormArray;
  @Output() categoriesChange = new EventEmitter<any[]>();
  categories: any[] = [];
  selectControl = new FormControl();

  constructor(private endCustomerService: EndCustomerService,private categoryService: CategoryService,) {}

  ngOnInit(): void {
    this.getCategories();
    this.selectControl.valueChanges.subscribe(value => {
      this.onSelectionChange(value);
    });
  }

  getCategories() {
    
    this.categoryService.getLastNodeCategory().subscribe(data => {
      if (data && data.data && data.data.length > 0) {
        this.categories = data.data;
        // After fetching categories, map the existing IDs to their names
        this.mapSelectedCategories();
      }
    }, error => {
      console.error("Error fetching categories", error);
    });
  }

  getCategory(id: any) {
    return this.categories.find((obj: any) => obj?.id === id)?.name;
  }

  remove(index: number): void {
    const removedValue = this.productCategoriesForm.at(index).value;
    this.productCategoriesForm.removeAt(index);
    const currentSelectedValues = this.selectControl.value.filter((id: any) => id !== removedValue);
    this.selectControl.setValue(currentSelectedValues);
    this.emitChanges();
  }
  
  onSelectionChange(selectedIds: any[]) {
    this.productCategoriesForm.clear();
    selectedIds.forEach((id: any) => this.productCategoriesForm.push(new FormControl(id)));
    this.emitChanges();
  }

  emitChanges() {
    this.categoriesChange.emit(this.productCategoriesForm.value);
  }

  mapSelectedCategories() {
    const selectedIds = this.productCategoriesForm.value;
    this.selectControl.setValue(selectedIds);
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { concatMap, takeWhile } from 'rxjs';
import { PlanType, StatusType } from 'src/app/enums/oem-management-enum';
import { MasterDataService } from 'src/app/services/master-data.service';
import { GeographicalType } from 'src/app/enums/product-enum';
import { City, Continent, Country, State } from 'src/app/models/master-data.model';
import { CustomChip, GeographicalChip } from 'src/app/models/product-model';
import { SubScriptionDetails } from 'src/app/models/subscription-model';
import { SubscriptionsService } from 'src/app/services/shared/subscriptions/subscriptions.service';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent implements OnInit {

  filterForm!: FormGroup;
  planTypes = Object.values(PlanType);
  statusTypes = Object.values(StatusType);
  countries!: Country[];
  states!: State[];
  cities!: City[];
  searchText: string = '';
  chips: GeographicalChip[] = [];
  oldChips: GeographicalChip[] = [];

  selectedContinents: Continent[] = [];
  selectedCountries: Country[] = [];
  selectedStates: State[] = [];
  selectedCities: City[] = [];
  subscriptionPlans: SubScriptionDetails[] = [];
  countryControl = new FormControl();
  stateControl = new FormControl();
  cityControl = new FormControl();
  filteredCountries: Country[] | undefined;
  filteredStates: State[] | undefined;
  filteredCities: City[] | undefined;
  


  constructor(private dialogRef: MatDialogRef<FilterPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: { filterData: any, chips: any, type: any }, private fb: FormBuilder, private masterDataService: MasterDataService,
    private subscriptionsService: SubscriptionsService
  ) { }

  async ngOnInit() {
    this.filterForm = this.fb.group({
      startDate: [],
      endDate: [],
      country: [],
      state: [],
      city: [],
      planType: [''],
      status: ['']
    });

    this.chips = this.data.chips.filter((chip: CustomChip) => chip.isGeographical === true);
    this.oldChips = this.data.chips.filter((chip: CustomChip) => chip.isGeographical === true);

    if (this.data.filterData) {
      this.filterForm.patchValue(this.data.filterData);
    }
    
    await this.loadMasterData();

    this.countryControl.valueChanges.subscribe(value => {
      if (!value?.countryName) {
        this.filteredCountries = this.countries.filter(country =>
          country.countryName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      }
    });
    this.stateControl.valueChanges.subscribe(value => {
      if (!value?.stateName) {
        this.filteredStates = this.states.filter(state =>
          state.stateName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      } 
    });
    this.cityControl.valueChanges.subscribe(value => {
      if (!value?.cityName) {
        

        this.filteredCities = this.cities.filter(city =>
          city.cityName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      } 
    });



  }


  submitFilter() {
    const filterData = {
      continents: this.selectedContinents?.map(c => c.continentName),
      countries: this.selectedCountries?.map(c => c.countryName),
      states: this.selectedStates?.map(c => c.stateName),
      cities: this.selectedCities?.map(c => c.cityName),
      startDate: this.filterForm.value.startDate,
      endDate: this.filterForm.value.endDate,
      planType: this.filterForm.value.planType,
      status: this.filterForm.value.status
    };
    const payload = {
      filterData : filterData,
      chips : this.chips
    }
    this.dialogRef.close(payload);
  }

  clearFilter() {
    this.chips = [];
    this.filterForm.reset();
    const filterData = {
      continents: this.selectedContinents?.map(c => c.continentName),
      countries: this.selectedCountries?.map(c => c.countryName),
      states: this.selectedStates?.map(c => c.stateName),
      cities: this.selectedCities?.map(c => c.cityName),
      startDate: this.filterForm.value.startDate,
      endDate: this.filterForm.value.endDate,
      planType: this.filterForm.value.planType,
      status: this.filterForm.value.status
    };
    const payload = {
      filterData : filterData,
      chips : this.chips
    }
    this.dialogRef.close(payload);
  }

  private async loadMasterData() {
    this.loadCountries(['']);
    this.loadStates(['']);
    this.loadCities(['']);
    await this.getSubsctiptionStatus();
  }

  loadCountries(continentNames: string[]) {
    let pageNumber = 0;
    let pageSize = 200;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
        this.filteredCountries = allCountries;
      });
  }

  loadStates(countryNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', countryNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', countryNames);
        })
      )
      .subscribe(data => {
        allStates = allStates.concat(data.data);
        this.states = allStates;
      });
  }

  loadCities(stateNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCities: any[] = [];
    let morePages = true;

    this.masterDataService.getCities(pageNumber, pageSize, '', stateNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', stateNames);
        })
      )
      .subscribe(data => {
        allCities = allCities.concat(data.data);
        this.cities = allCities;
      });
     
  }

  onCountryChange(event: any) {



    const country = event?.option?.value;
    // Add the selected country if it's not already in the list
    if (!this.selectedCountries?.some(c => c.id === country.id)) {
      this.selectedCountries?.push(country);
    }

    // Get the list of selected country names
    const countryNames = this.selectedCountries?.map(c => c.countryName);

    // Load states for the selected countries
    this.loadStates(countryNames);

    this.removeContinentForCountry(country);

    // Add a new chip for the selected country
    this.addNewChip(this.createChipStructure(country.countryName, GeographicalType.COUNTRY, 2));
    this.countryControl.reset();
  }

  onStateChange(state: State) {
    if (!this.selectedStates?.some(c => c.id === state.id)) {
      this.selectedStates?.push(state);
    }
    this.removeCountryForState(state)
    const stateNames = this.selectedStates?.map(c => c.stateName);
    this.loadCities(stateNames);
    // this.removeOldChips(GeographicalType.STATE); // Remove old chip
    this.addNewChip(this.createChipStructure(state.stateName, GeographicalType.STATE, 3));
    this.stateControl.reset();
  }

  onCityChange(city: City) {
    this.removeStateForCity(city);
    if (!this.selectedCities?.some(c => c.id === city.id)) {
      this.selectedCities?.push(city);
    }
    // this.removeOldChips(GeographicalType.CITY); // Remove old chip
    this.addNewChip(this.createChipStructure(city.cityName, GeographicalType.CITY, 4));
    this.cityControl.reset();
  }
  receiveSearchText(searchText: string): void {
    this.searchText = searchText;
    // Now you can use searchText in the child component
  }

  remove(chip: GeographicalChip) {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      switch (chip.type) {
        case GeographicalType.CONTINENT:
          this.filterForm.patchValue({ continent: '' });
          this.selectedContinents = this.selectedContinents.filter(c => c.continentName !== chip.name);
          break;
        case GeographicalType.COUNTRY:

          this.selectedCountries = this.selectedCountries.filter(c => c.countryName !== chip.name);
          //set last value of selected countrries to filter form
          this.filterForm.patchValue({ country: this.selectedCountries[this.selectedCountries.length - 1] || '' });
          this.loadStates(this.selectedCountries.map(c => c.countryName));
          this.loadCities(this.selectedStates.map(c => c.stateName));
          break;
        case GeographicalType.STATE:
          const state = this.states.find(c => c.id === chip.id);
          if (state) {
            const countryForSelectedState = this.countries.find(c => c.countryName === state.countryName);
            const countryAvailableInFilter = this.chips.find(c => c.type === GeographicalType.COUNTRY && c.name === countryForSelectedState?.countryName);
            if (countryForSelectedState && countryAvailableInFilter) {
              this.selectedCountries.push(countryForSelectedState);
            }
          }
          this.selectedStates = this.selectedStates.filter(c => c.stateName !== chip.name);
          this.filterForm.patchValue({ state: this.selectedStates[this.selectedStates.length - 1] || '' });
          this.loadCities(this.selectedStates.map(c => c.stateName));
          break;
        case GeographicalType.CITY:
          const city = this.cities.find(c => c.id === chip.id);
          if (city) {
            const stateForSelectedCity = this.states.find(c => c.stateName === city.stateName);
            const stateAvailableInFilter = this.chips.find(c => c.type === GeographicalType.STATE && c.name === stateForSelectedCity?.stateName);
            if (stateForSelectedCity && stateAvailableInFilter) {
              this.selectedStates.push(stateForSelectedCity);
            }
          }
          this.selectedCities = this.selectedCities.filter(c => c.cityName !== chip.name);
          this.filterForm.patchValue({ city: this.selectedCities[this.selectedCities.length - 1] || '' });
          break;
        default:
          break;
      }
    }
  }

  addNewChip(newChip: GeographicalChip) {
    // Check if the chip with the same name already exists in the array
    const chipExists = this.chips.some(chip => chip.name === newChip.name);

    // If the chip does not exist, push it to the array
    if (!chipExists) {
      this.chips.push(newChip);
    }
  }

  createChipStructure(name: string, type: GeographicalType, priority: number, id: number = 0): GeographicalChip {
    return {
      id: id,
      name: name,
      type: type,
      priority: priority
    };
  }

  removeContinentForCountry(country: Country) {
    const continentCode = country.continentCode;
    // Find and remove the continent corresponding to the country
    this.selectedContinents = this.selectedContinents?.filter(continent => continent.continentCode !== continentCode);
  }

  removeCountryForState(state: State) {
    const countryName = state.countryName;

    const country = this.selectedCountries?.find(c => c.countryName === countryName);
    if (country) {
      this.removeContinentForCountry(country);
      this.selectedCountries = this.selectedCountries?.filter(country => country.countryName !== countryName);
    }
  }

  removeStateForCity(city: City) {
    const stateName = city.stateName;

    const state = this.selectedStates?.find(c => c.stateName === stateName);
    if (state) {
      this.removeCountryForState(state);
      // Find and remove the state corresponding to the city
      this.selectedStates = this.selectedStates?.filter(state => state.stateName !== stateName);
    }
  }

  getSubsctiptionStatus(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subscriptionsService.getAllSubscriptions().subscribe(data => {
        this.subscriptionPlans = data.data
        resolve();
      })
    })
  }
  cancel() {
    this.filterForm.patchValue(this.data.filterData);
    const payload = {
      filterData: this.filterForm.value,
      chips: this.oldChips
    };
    this.dialogRef.close(payload);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEMCompany, OEMFilterData, OEMTableData, OemDetails } from 'src/app/models/oem-models';
import { FilterPayload } from 'src/app/models/product-model';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OemManagementService {

  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  constructor(private http: HttpClient) { }

  oemSummery(data: any){
    return this.http.post<ApiResponseModel<OEMFilterData>>(`${this.ONBOARDING_BASE_URL}/OEM/summary`, data)
  }

  getAllOEMsData(page: number, size: number, body: any): Observable<ApiResponseModel<OEMTableData[]>> {
    return this.http.post<ApiResponseModel<OEMTableData[]>>(`${this.ONBOARDING_BASE_URL}/OEM/all?page=${page}&size=${size}`, body);
  }

  getOemDataById(id: string): Observable<ApiResponseModel<OemDetails>> {
    return this.http.get<ApiResponseModel<OemDetails>>(`${this.ONBOARDING_BASE_URL}/OEM/get-oem-with-management?id=${id}`);
  }

  deleteOem(id: string): Observable<ApiResponseModel<string>> {
    return this.http.delete<ApiResponseModel<string>>(`${this.ONBOARDING_BASE_URL}/OEM/${id}`);
  }

  addOEM(data: OemDetails): Observable<ApiResponseModel<string>> {
    return this.http.post<ApiResponseModel<string>>(`${this.ONBOARDING_BASE_URL}/OEM/add-with-management`, data);
  }

  updateOEM(data: OemDetails): Observable<ApiResponseModel<string>> {
    return this.http.put<ApiResponseModel<string>>(`${this.ONBOARDING_BASE_URL}/OEM/update-with-management`, data);
  }

  deleteFinancialInfoById(id: string): Observable<ApiResponseModel<string>> {
    return this.http.delete<ApiResponseModel<string>>(`${this.ONBOARDING_BASE_URL}/OEM/financial-information/delete/{id}?id=${id}`);
  }
  searchOEM (keyword: string): Observable<ApiResponseModel<OEMTableData[]>> {
    return this.http.get<ApiResponseModel<OEMTableData[]>>(`${this.ONBOARDING_BASE_URL}/OEM/search?keyword=${keyword}`);
  }
}

<div class="sidenav">
  <button *ngFor="let button of buttons" (click)="toggleContent(button.id)"
    [class.active]="activeContent === button.id">
    {{ button.label }}
  </button>
</div>
<div class="main">
  <ng-container *ngFor="let button of buttons">
    <div [id]="button.id" *ngIf="activeContent === button.id">
      <app-mwe-table [columns]="columns" [data]="Data" [title]="button.label"  [showAddButton]="true"
        [showFilter]="false" [showMasterDataSearch]="true" [showPagination]="true" (selectedAction)="selectedAction($event)" (pageChanged)="onPageChanged($event)" (applyyFilter)="applyFilter($event)"   [currentPageChanged]="pageIndex" [masterDataSearch]="startsWith" [isDynemicPagination]="true" ></app-mwe-table>
    </div>
    
  </ng-container>
  <h2 *ngIf="showNoRecordsFounds" class="no-records">No records found</h2>
</div>
 
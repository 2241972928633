<div class="medworld-background-container">
  <div class="form-conatiner">
    <div class="medworld-form-container">
      <div class="logo-container" routerLink="/">
        <img
          loading="lazy"
          alt=""
          src="./../../assets/images/logomedworld32full.svg"
        />
      </div>

<div>
      <div class="medworld-label mx-auto">Login</div>
      <form class="form-container" (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <div class="login-container2">
          <div class="input-element-container">
            <label class="medworld-label-inputs" for="email"
              >{{ 'EMAIL' | translate }}</label
            >
            <div class="input-element">
              <img
                class="mail-icon"
                loading="lazy"
                alt=""
                src="./../../assets/images/mail.svg"
              />
              <input
              (input)="onUserNameChange()"
                [(ngModel)]="user.email"
                type="email"
                class="form-control-input"
                id="email"
                name="userName"
                style="margin-top: 4px"
                placeholder="{{ 'Enter_Email' | translate }}"
                required
                #userNameField="ngModel"
              />
            </div>
            <!-- Display error message if the field is touched and has errors -->
           
          </div>
         
          <div
            *ngIf="showUserNameError || showError"
            class="error-message"
            style="display: flex; align-items: center"
          >
            <img
              class="error-icon"
              loading="lazy"
              alt=""
              src="./../../assets/images/alert.svg"
            />
            <span style="margin-left: 10px">{{ errorMessage || 'EMAIL_REQUIRED' | translate }}</span>
          </div>


          <div class="input-element-container">
            <label class="medworld-label-inputs" for="password">{{ 'PASSWORD' | translate }}</label>
            <div class="input-element">
              <img
                class="password-icon"
                loading="lazy"
                alt=""
                src="./../../assets/images/lock-closed.svg"
              />
              <input
              (input)="onPasswordChange()"
              [type]="showPassword ? 'text' : 'password'"
                style="margin-top: 4px"
                class="form-control-input"
                id="password"
                name="password"
                [(ngModel)]="user.password"
                placeholder="{{ 'Enter_Password' | translate }}"
                required
                #passwordField="ngModel"
              />
              <button
                type="button"
                class="eye-button border-0 bg-transparent"
                (click)="showPassword = !showPassword"
              >
              <img
              class="eye-icon"
              loading="lazy"
              alt=""
              [src]="showPassword ?  './../../../../assets/images/Blind.svg':'./../../../../assets/images/Eye.svg' "
            /></button>
              
            </div>
          </div>
          <div
            *ngIf="showPasswordError"
            class="error-message"
            style="display: flex; align-items: center"
          >
            <img
              class="error-icon"
              loading="lazy"
              alt=""
              src="./../../assets/images/alert.svg"
            />
            <span style="margin-left: 10px">{{ 'PASSWORD_REQUIRED' | translate }}</span>
          </div>
        </div>
        <div class="forgot-password-container">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
            "
          >
            <div class="sign-in-remember">
              <label class="checkbox-container">
                <input
                type="checkbox"
                  style="
                    margin: 0;
                    height: 20px;
                    width: 20px;
                    position: relative;
                    min-height: 24px;
                  "
                  value="true"
                  name="rememberMe"
                  [(ngModel)]="rememberMeFlag"
                />
                <span class="medworld-label-inputs" style="margin-top: 4px"
                  >{{ 'REMEMBER_ME' | translate }}</span
                >
              </label>
            </div>
            <div>
              <a class="forgot-password" [routerLink]="['/user/forgot-password']"
                >{{ 'FORGOT_PASSWORD' | translate }}</a
              >
            </div>
          </div>
        </div>
  
        <button
          type="submit"
          style="width: 85%"
          class="button button-primary mt-3"
         
        >
     {{ 'LOGIN' | translate }}
        </button>
      </form>
  
    </div>


    </div>
  </div>
      <div class="copyright">{{ "COPYRIGHT" | translate }}

 </div>
</div>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<string[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  setBreadcrumbs(breadcrumbs: string[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  getBreadcrumbs(): string[] {
    return this.breadcrumbsSubject.getValue();
  }
}

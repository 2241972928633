<div class="personal-infomation p-4 mt-4">
  <div class="d-flex justify-content-between">
    <h2 class="profile-heading">Resume  <span style="color: #1F1F1F;
      font-weight: 100;
      font-size: 14px; "> (Be sure to include an updated resume.)</span> </h2>
    <div class="d-flex">
      <img
        class="edit-profile mx-1"
        accept=".pdf"
        (click)="addResume()"
        *ngIf="
          (endCustomerData?.resumes &&
            this.endCustomerData.resumes.length > 0) ||
          showEditForm
        "
        alt=""
        src="../../assets/images/add.svg"
      />
      <img
        class="edit-profile"
        accept=".pdf"
        (click)="editResume()"
        *ngIf="
          endCustomerData?.resumes &&
          this.endCustomerData.resumes.length > 0 &&
          !showEditForm
        "
        alt=""
        src="../../assets/images/editprofile.svg"
      />
    </div>
  </div>
  <div
    class="mt-2"
    *ngIf="
      !(endCustomerData?.resumes && this.endCustomerData.resumes.length > 0) &&
      !showEditForm
    "
  >
    <button (click)="addResume()" class="profile-btn">
      <mat-icon>add</mat-icon>
      <p class="ps-2">Add Resume</p>
    </button>
  </div>

  <div
    class="mt-2"
    *ngIf="
      endCustomerData?.resumes &&
      this.endCustomerData.resumes.length > 0 &&
      !showEditForm
    "
  >
  <div class="mt-2 pt-1 d-flex flex-wrap">
    <div *ngFor="let item of data" class="me-3">
      <div (click)="viewResume(item.resume)" class="card-resume d-flex align-items-center">
        <img
          class="card-icon me-3"
          [src]="resumeIcon"
          alt="Card Profile"
        />
        <div>
          <div style="text-decoration: none" *ngIf="item.fileName">
            <div style="font-weight: 600;"
              [style.text-overflow]="item.fileName.length > 20 ? 'ellipsis' : ''"
              [style.overflow]="item.fileName.length > 20 ? 'hidden' : ''"
              [style.white-space]="item.fileName.length > 20 ? 'nowrap' : ''"
              [style.max-width]="item.fileName.length > 20 ? '20ch' : ''"
            >
              {{ item.fileName }}
            </div>
            <div>
              Last Updated on {{ (item?.modifiedOn ? item?.modifiedOn : item?.createdOn) | date: 'dd/MM/yyyy' }}
            </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  

    <!-- <app-mwe-table [showPagination]="false" [columns]="certificateColumns" [showHeader]="false"
        [data]="data" (selectedAction)="selectedAction($event)"></app-mwe-table> -->
  </div>

  <form [formGroup]="resumesForm" class="mt-2" *ngIf="showEditForm">
    <div formArrayName="resumes">
      <div *ngFor="let resumeForm of resumes.controls; let i = index">
        <div [formGroupName]="i">
          <div class="row mb-4">
            <div class="col-lg-12">
              <div class="card p-4">
                <div class="row">
                  <div class="col-lg-5">
                    <div>
                      <p
                        class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"
                      >
                        Name
                      </p>
                    </div>
                    <div>
                      <mat-form-field
                        appearance="outline"
                        class="fields input-font b-r w-100"
                      >
                        <input matInput formControlName="name" />
                        <mat-error
                          class="error-message error-msg"
                          *ngIf="resumeForm.get('name')?.hasError('required')"
                          ><img
                            class="error-icon"
                            loading="lazy"
                            alt=""
                            src="./../../assets/images/alert.svg"
                          />
                          Name is Required</mat-error
                        >
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div>
                      <p
                        class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"
                      >
                        Resume
                      </p>
                    </div>
                    <div class="d-flex">
                      <span class="file-link m-2">{{
                        resumeForm.get("fileName")?.value
                      }}</span>
                      <input
                        class="d-none"
                        type="file"
                        id="{{ 'uploadResume' + i }}"
                        accept=".png,.jpg,.jpeg,.pdf"
                        (change)="uploadResume($event, i)"
                      />
                      <label
                        class="mwe-button mwe-button--primary upload med-world-basic-font med-world-basic-font__medium ps-3 pe-3"
                        for="{{ 'uploadResume' + i }}"
                        >Upload
                      </label>

                      <button
                        class="mx-4 mwe-button mwe-button--secondary ps-3 pe-3"
                        (click)="deleteResume(i)"
                      >
                        Delete
                      </button>
                    </div>
                    <div>
                      <mat-error
                        class="error-message error"
                        style="margin-top: -1px"
                        *ngIf="
                          resumeForm.get('certificate')?.invalid &&
                          resumeForm.get('certificate')?.touched &&
                          resumeForm.get('certificate')?.hasError('required')
                        "
                      >
                        <img
                          class="error-icon"
                          loading="lazy"
                          alt=""
                          src="./../../assets/images/alert.svg"
                        />Certificate is Required</mat-error
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="profile-action" *ngIf="showEditForm">
    <button
      class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3"
      (click)="update()"
    >
      Update
    </button>

    <button
      class="mwe-button mwe-button--secondary ps-3 pe-3"
      (click)="cancel()"
    >
      Cancel
    </button>
  </div>
</div>

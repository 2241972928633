import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data);
  }

  downloadCSVFile () {
    const blob = new Blob([this.data.csvData], { type: 'text/csv' });
    saveAs(blob, 'products.csv');
  }
}

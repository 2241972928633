import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage.service';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { Distributers } from 'src/app/models/product-settings-model';
import { OEMTableData, OemData } from 'src/app/models/oem-models';
import { ProductInfoUrls, serverRoutes } from 'src/app/utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class AllProductService {
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  userId:any;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,) {
    this.localStorageService.getUserDetails().subscribe(data=>{
      this.userId=data.userId;
    })
   }

    getAllUsers(): Observable<ApiResponseModel<any[]>> {
      const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      return this.http.get<ApiResponseModel<any[]>>(serverRoutes.authBaseRoute, {headers});
    }

    getAllDistributors(): Observable<ApiResponseModel<Distributers[]>> {
      const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      return this.http.get<ApiResponseModel<Distributers[]>>(ProductInfoUrls.getAllDistributor, {headers});
    }

   getAllProducts(page: number, size: number): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<any[]>>(`${ProductInfoUrls.getAllProducts}?page=${page}&size=${size}`, {headers});
  }

  getOEMs(page: number, size: number, startWith?: string): Observable<ApiResponseModel<OEMTableData[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = new HttpParams();
    if (startWith) {
      params = params.set('startWith', startWith);
    }
    return this.http.get<ApiResponseModel<OEMTableData[]>>(`${ProductInfoUrls.getOEMs}?page=${page}&size=${size}`, {headers, params});
  }

  public saveProduct(body: any): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('userId',this.userId);
    return this.http.post(ProductInfoUrls.saveProduct, body, { headers: headers });
  }

  public filterProduct(body: any, page: number, size: number): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
    return this.http.post(`${ProductInfoUrls.filterProduct}?page=${page}&size=${size}`, body, { headers: headers });
  }

  public updateProduct(body: any, id : number): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set('userId',this.userId);
    return this.http.put(`${ProductInfoUrls.updateProduct}/${id}`, body, { headers: headers });
  }

  public deleteProduct(id : number): Observable<any>{
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.delete(`${ProductInfoUrls.deleteProduct}/${id}`, { headers: headers });
  }

  public uploadFile(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
    return this.http.post(this.AUTH_BASE_URL + url, body, { headers: headers });
  }

  public importFile(file: File, oemId: string | null): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    let headers = new HttpHeaders().set('userId', this.userId);

    // Conditionally add the 'oemId' header if it's provided
    if (oemId) {
      headers = headers.set('oemId', oemId);
    }
      
    return this.http.post(ProductInfoUrls.importFile, formData, { headers: headers });
  }

  public getAllProductByOemId(page: number, size: number, id : string): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<any[]>>(`${ProductInfoUrls.allProductsByOEMId}/${id}?page=${page}&size=${size}`, {headers});
  }

  public getAllProductsByOemId(id : string): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<any[]>>(`${ProductInfoUrls.allProductsByOEMId}/all/${id}`, {headers});
  }

  public getAllProductByDistributorId(page: number, size: number, id : string): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders()
    .set("Content-Type", "application/json");
    return this.http.get<ApiResponseModel<any[]>>(`${ProductInfoUrls.allProductsByDistributorId}/${id}?page=${page}&size=${size}`, {headers});
  }

  searchProducts(query: string, page: number = 0, size: number = 10): Observable<any> {
    let params = new HttpParams()
      .set('query', query);
    return this.http.get<any>(`${ProductInfoUrls.searchProduct}?page=${page}&size=${size}`, { params: params });
  }

  getTextSpecificationsByNames(): Observable<any> {
    return this.http.get(ProductInfoUrls.getTextSpecificationsByNames);
  }

  downloadFile(url: string, fileName: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  saveFile(blob: Blob, fileName: string): void {
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(downloadURL);
  }

  getModifiedOnByOemId(id: string): Observable<any> {
    const url = `${ProductInfoUrls.modifiedOnByOemId}/${id}`;
    return this.http.get<any>(url);
  }
}

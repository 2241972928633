import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { AddEditSpecificationsComponent } from './add-edit-specifications/add-edit-specifications.component';
import { ListColumn } from 'src/app/models/list-column.model';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { SpecificationService } from 'src/app/services/shared/product-settings/specification.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-specifications',
  templateUrl: './specifications.component.html',
  styleUrls: ['./specifications.component.css']
})
export class SpecificationsComponent implements OnInit{
  specifications: any[] = [];
  showNoRecordsFounds:boolean=false;
  actionType! : string;

  constructor(
    private dialog: MatDialog,
    private sweetAlertService : SweetAlertService,
    private specificationservice : SpecificationService

  ) {
  }

  ngOnInit(): void {
   this.getAllSpecifications();
  }

  getAllSpecifications() {
    this.showNoRecordsFounds=false;
    this.specificationservice.getAllSpecifications().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.specifications = response.data;
          this.specifications = response.data.map((item, index) => ({ ...item, srNo: index + 1 }));
          console.log('this.specifications', this.specifications)
        } else {
          this.showNoRecordsFounds=true;
        }
      },
      error: (error) => {
        console.error('Error fetching oem data:', error);
      }
    });
  }
  
  openAddEditForm() {
    const dialogRef = this.dialog.open(AddEditSpecificationsComponent, {
      width: 'auto',
      height : 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.getAllSpecifications();
    });
  }

  openEditForm(data: any) {
    console.log(data)
    data = {
      ...data,
      actionType : this.actionType
    };
    const dialogRef = this.dialog.open(AddEditSpecificationsComponent, {
      width: 'auto',
      height : 'auto',
      data,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.getAllSpecifications();
    });
  }
  

  delete(id: number) {
    this.sweetAlertService.showConfirmation('Are you sure?', 'You will not be able to recover the deleted record!', 'Yes, Delete It', 'Cancel')
    .then((result) => {
      if (result.value) {
        this.specificationservice.deleteSpecification(id).subscribe(
          (response) => {
            this.getAllSpecifications();
            this.sweetAlertService.showSuccess('Removed', 'Specification deleted successfully !!');
          },
          (error) => {
            this.sweetAlertService.showError('Failed', error?.error?.message);
          }
        );
      } else if(result.dismiss === this.sweetAlertService.CANCEL){
        this.sweetAlertService.showError('Cancelled', 'Item is safe.');
      }
    });

  }


  columns: ListColumn[] = [
    { name: 'Sr No', property: 'srNo',visible:true,isModelProperty:false},
    {name:'Type',property:'type',visible:true,isModelProperty:true,isText:true},
    {name:'Name',property:'name',visible:true,isModelProperty:true,isText:true},
    { name: 'Unit of Measure', property: 'measureRelation',visible:true,isModelProperty:true, isArray: true},
    { name: 'Status', property: 'active',visible:true,isModelProperty:true, isStatus:true},
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true  ,hasAction: true,
    actions:[
            {imageUrl:'assets/images/eye-visble.svg', isImage:true, actionType:MweConstants.viewAction},
            {imageUrl:'assets/images/edit.svg', isImage:true, actionType:MweConstants.editAction,hasCondition:true,conditionProperty:'active',conditionValue:true},
            {imageUrl:'assets/images/bin.svg', isImage:true, actionType:MweConstants.deleteAction,hasCondition:true,conditionProperty:'active',conditionValue:true}]
          },
  ] as ListColumn[];

selectedAction(event:any){
  switch (event.action) {
    case MweConstants.addAction:
      this.actionType=event.action;
     this.openAddEditForm()
      break;
    case MweConstants.editAction:
      this.actionType=event.action;
      this.openEditForm(event.row);
      break;
    case MweConstants.viewAction:
    this.actionType=event.action;
    this.openEditForm(event.row);
    break;
    case MweConstants.deleteAction:
      this.actionType=event.action;
      this.delete(event.row.id);
      break;
    default:
      break;
  }
}

}

<app-website-header></app-website-header>
<div class="job-apply-container">

    <div class="d-flex">
        <img src="/assets/images/home.svg" alt="home">
        <div class="more-info-title ms-1" style="font-size: 14px; color: rgba(133, 133, 133, 1);">Home/Careers/</div>
        <div style="font-weight: 500;">{{jobPost?.title}}</div>
    </div>

    <div class="card mt-3">
        <div class="card-body" style="height: 400px; overflow: hidden;">
            <img [src]="jobPost?.image" alt="featured-image" style="width: 100%;max-height: 100%;object-fit: cover">
        </div>
    </div>

    <div class="row">
        <!-- Job Description and Details -->

        <div class="col-md-9">
            <app-profile [jobPost]="jobPost" (endCustomerId)="setApplicantId($event)"></app-profile>
            <div class="button-container mt-2">
                <button type="button" class="button button-primary secondary me-3" (click)="onCancel()">Cancel</button>
                <button type="submit" class="button button-primary" (click)="onSubmitApplication()">Submit
                    Application</button>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card mt-4">
                <div class="card-body">
                    <div class="body-title" style="color: rgba(0, 0, 0, 1);">Career Portfolio</div>
                    <div class="d-flex mt-3">
                        <img src="/assets/images/carrers-apply-tip-icon.svg" alt="carrers-apply-tip" class="me-2">
                        <div class="career-tips">Tips to complete your profile</div>
                    </div>
                    <ul class="tips">
                        <li>Add Work Experience</li>
                        <li>Add Education</li>
                        <li>Add References</li>
                    </ul>
                    <hr>
                    <div class="d-flex mt-3">
                        <img src="/assets/images/careers-saved-jobs.svg" alt="carrers-apply-tip" class="me-2">
                        <div class="career-tips">Saved Jobs</div>
                    </div>
                    <hr>
                    <div class="d-flex mt-3">
                        <img src="/assets/images/careers-applied-jobs.svg" alt="carrers-apply-tip" class="me-2">
                        <div class="career-tips">Applied Jobs</div>
                    </div>
                    <hr>
                    <div class="d-flex mt-3">
                        <img src="/assets/images/careers-manage-password.svg" alt="carrers-apply-tip" class="me-2">
                        <div class="career-tips">Manage Password</div>
                    </div>
                    <hr>
                    <div class="d-flex mt-3">
                        <img src="/assets/images/careers-logout.svg" alt="carrers-apply-tip" class="me-2">
                        <div class="career-tips">Logout</div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>
<app-website-footer></app-website-footer>
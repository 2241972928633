import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup,FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { UserDetails } from 'src/app/models/authData';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-personal-infomation',
  templateUrl: './personal-infomation.component.html',
  styleUrls: ['./personal-infomation.component.scss']
})
export class PersonalInfomationComponent implements OnInit, OnChanges{
  @Input() personalForm!:FormGroup;
  @Input() showPersonalInfo:boolean=false;
  @Input() jobPost: any
  @Output() onUpdate:EventEmitter<any>=new EventEmitter();
  
  copyPersonalForm:any;
  showEditForm:boolean=false;
  maxDate:Date=new Date();
  positionDropdown:any=[];

  userData!: UserDetails;

  [x: string]: any;
  countryDropdown:any=[];
  stateDropdown:any=[];
  cityDropdown:any=[];

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;

  constructor(private endCustomerService:EndCustomerService,private fb: FormBuilder,private masterDataService:MasterDataService, private localStorageService:LocalStorageService){}

  ngOnInit(): void {
    if(this.showPersonalInfo){
      this.getPositionsDropdown();
    }
   
    const httpsUrlPattern = /^(https:\/\/|www\.).*/;
    this.personalForm.get('url')?.setValidators([Validators.required, Validators.pattern(httpsUrlPattern)]);


   
    this.maxDate.setDate(new Date().getDate()-1);
    this.getCountries(null);
    if(this.showPersonalInfo){
      this.getPositionsDropdown();
    }

    this.country.valueChanges.subscribe(data=>{
      if(data){
        this.getCountries({startsWith:data});
      }else{
        this.getCountries(null);
      }
    });

    this.state.valueChanges.subscribe(data=>{
      if(this.personalForm.get('country')?.value){
        if(data){
          this.getStatesByCountryName({countryName:this.personalForm.get('country')?.value,startsWith:data});
        }else{
          this.getStatesByCountryName({countryName:this.personalForm.get('country')?.value});
        }
      }
    
    });
    this.city.valueChanges.subscribe(data=>{
      if(this.personalForm.get('state')?.value){
        if(data){
          this.getCityiesByStateName({stateName:this.personalForm.get('state')?.value,startsWith:data});
        }else{
          this.getCityiesByStateName({stateName:this.personalForm.get('state')?.value});
        }
      }
    
    });
      
    this.localStorageService.getUserDetails().subscribe(userDetails => { 
      this.userData = userDetails;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
console.log(changes)
  }

  editPersonalInfo(){
    console.log(this.personalForm.value)
    this.copyPersonalForm=JSON.parse(JSON.stringify(this.personalForm.value));
    this.showEditForm=true;
  }

  getPositionsDropdown(){
    this.endCustomerService.executeGetRequestURL(MweConstants.positionUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.positionDropdown=data.data;
       /*  if(this.actionType===MweConstants.editAction){ */
          const positionObj=this.positionDropdown.find((obj:any)=>obj.id===this.personalForm.get('position')?.value?.id);
      
          if(positionObj){
            this.personalForm.get('position')?.patchValue(positionObj);
          }
        //}
      }
    },error=>{

    })
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  getCountries(params:any){
    this.executeGetRequestURL(MweConstants.countryURL,params,'countryDropdown');
  }
  getStatesByCountryName(params:any){
    this.executeGetRequestURL(MweConstants.stateUrl,params,'stateDropdown');
  }
  getCityiesByStateName(params:any){
    this.executeGetRequestURL(MweConstants.cityUrl,params,'cityDropdown');
  }

  executeGetRequestURL(url:string,params:any,type:string){
    this[type]=[];
    this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this[type]=data.data;
      }
    },error=>{

    })
  }

  getStatesDropdown(){
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.setContinentAndPhoneCode()
    this.personalForm.get('state')?.reset();
    this.personalForm.get('city')?.reset();
    if(this.personalForm.get('country')?.value){
      this.getStatesByCountryName({countryName:this.personalForm.get('country')?.value})
    }
  }

  getCityDropdown(){
    this.cityDropdown=[];
    this.personalForm.get('city')?.reset();
    if(this.personalForm.get('state')?.value){
      this.getCityiesByStateName({stateName:this.personalForm.get('state')?.value})
    }
  }

  get country(){
    return this.personalForm.get('country') as FormControl;
  }
  get state(){
    return this.personalForm.get('state') as FormControl;
  }
  get city(){
    return this.personalForm.get('city') as FormControl;
  }

  get gender(){
    return this.personalForm.get('gender') as FormControl;
  }

  ngAfterViewInit() {
    this.autoCompleteCountryInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.country.setValue(null);
        }
     
      });
      this.autoCompleteStateInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.state.setValue(null);
        }
      
      });
      this.autoCompleteCityInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.city.setValue(null);
        }
        
      });
  }

  cancel(){
    this.showEditForm=false;
    this.personalForm.get('name')?.patchValue(this.copyPersonalForm?.name);  
    if(this.showPersonalInfo){
      this.personalForm.get('dob')?.patchValue(this.copyPersonalForm?.dob);  
      this.personalForm.get('gender')?.patchValue(this.copyPersonalForm?.gender);  
      this.personalForm.get('workingIn')?.patchValue(this.copyPersonalForm?.workingIn);  
      const positionObj=this.positionDropdown.find((obj:any)=>obj.id===this.copyPersonalForm?.position?.id);
      
      if(positionObj){
        this.personalForm.get('position')?.patchValue(positionObj);
      }
     // this.personalForm.get('position')?.patchValue(this.copyPersonalForm?.position);  
    }else{
      this.personalForm.get('establishedIn')?.patchValue(this.copyPersonalForm?.establishedIn);  
      this.personalForm.get('noEmployees')?.patchValue(this.copyPersonalForm?.noEmployees);  
      this.personalForm.get('url')?.patchValue(this.copyPersonalForm?.url);  
    }

    this.personalForm.get('address')?.patchValue(this.copyPersonalForm?.address);  
    this.personalForm.get('phone')?.patchValue(this.copyPersonalForm?.phone);  
    this.personalForm.get('country')?.patchValue(this.copyPersonalForm?.country); 
    this.personalForm.get('state')?.patchValue(this.copyPersonalForm?.state);  
    this.personalForm.get('city')?.patchValue(this.copyPersonalForm?.city);  
    this.personalForm.get('zipCode')?.patchValue(this.copyPersonalForm?.zipCode);  
    this.personalForm.get('logo')?.patchValue(this.copyPersonalForm?.logo);
    

    

  }

  update(){

    // if(this.personalForm.get('url')?.invalid){      
    //   Swal.fire(
    //     'Please fill all fields',
    //     '',
    //     'warning'
    //   );
    //   return;
    // }

    this.personalForm.get('name')?.markAsTouched();
    if(this.showPersonalInfo){
      this.personalForm.get('dob')?.markAsTouched();
      this.personalForm.get('gender')?.markAsTouched();
      this.personalForm.get('workingIn')?.markAsTouched();
      this.personalForm.get('position')?.markAsTouched();
    }else{
      this.personalForm.get('establishedIn')?.markAsTouched();
      this.personalForm.get('noEmployees')?.markAsTouched();
      this.personalForm.get('url')?.markAsTouched();
    }
    

    this.personalForm.get('email')?.markAsTouched();
    this.personalForm.get('phone')?.markAsTouched();
    this.personalForm.get('country')?.markAsTouched();
    this.personalForm.get('state')?.markAsTouched();
    this.personalForm.get('city')?.markAsTouched();
    this.personalForm.get('zipCode')?.markAsTouched();
    this.personalForm.get('address')?.markAsTouched();

    if(this.personalForm.get('name')?.invalid ||
    this.personalForm.get('email')?.invalid ||
    this.personalForm.get('phone')?.invalid ||
    this.personalForm.get('country')?.invalid ||
    this.personalForm.get('state')?.invalid ||
    this.personalForm.get('city')?.invalid ||
    this.personalForm.get('zipCode')?.invalid ||
    this.personalForm.get('address')?.invalid ||


  (!this.showPersonalInfo && (this.personalForm.get('establishedIn')?.invalid ||
    this.personalForm.get('noEmployees')?.invalid ||
    this.personalForm.get('url')?.invalid)) ||
     (this.showPersonalInfo && (this.personalForm.get('dob')?.invalid ||
      this.personalForm.get('gender')?.invalid ||
      this.personalForm.get('workingIn')?.invalid ||
      this.personalForm.get('position')?.invalid))){
        console.log(this.personalForm.value)
      return;
    }
    this.userData.userName = this.personalForm.get('name')?.value;
    this.localStorageService.updateUserDetails(this.userData);
    this.onUpdate.emit(0);


  }

  setContinentAndPhoneCode(){
    if(this.personalForm.get('country')?.value){
        
      const countryObj=this.countryDropdown.find((obj:any)=>obj.countryName===this.personalForm.get('country')?.value);
      if(countryObj){
          
        this.personalForm.get('continent')?.patchValue(countryObj.continentName);
        this.personalForm.get('phoneCode')?.patchValue(countryObj.phoneCode);
      
      }
  
    }

  }
  refresh(){
    this.showEditForm=false;

    if(this.showPersonalInfo){
      const positionObj=this.positionDropdown.find((obj:any)=>obj.id===this.personalForm.get('position')?.value?.id);
      
      if(positionObj){
        this.personalForm.get('position')?.patchValue(positionObj);
      }
    }
  }

  uploadLogo(event:any){
    let uploadData = new FormData();
   //this.fileName=event.target.files[0].name;
    uploadData.append('file', event.target.files[0]);
    let randomNumber = Math.floor(Math.random() * 10) + 1
    uploadData.append('destinationKey', randomNumber+'');

    this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
      if(data && data?.data){
        this.personalForm.get('logo')?.patchValue(data.data);
       // this.organisationInfoForm.get('logoFileName')?.patchValue(event.target.files[0].name);
      }
    },error=>{

    })
  }

  removeLogo(){
    this.personalForm.get('logo')?.patchValue(null);
  }
  urlValidator(control: AbstractControl) {
    const url = control.value;
    if (!url.startsWith('https://') || !url.startsWith('www.')) {
      return { invalidUrl: true };
    }
    return null;
  }

  openLink(url: string) {
    if (this.personalForm.get('url')?.valid) {
      window.open(url, '_blank');
    } else {
      console.error('Invalid URL');
      this.markFormGroupTouched(this.personalForm);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}

import { ViewJobComponent } from './../dashboard/super-admin/super-admin-careers/view-job/view-job.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EndCustomerComponent } from './end-customer.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { ChatComponent } from '../shared/chat/chat.component';
import { Path } from 'three';
import { WebinarUserComponent } from './profile/webinar-user/webinar-user.component';
import { ProductEnquiryDistributorsComponent } from './product-enquiry-distributors/product-enquiry-distributors.component';
import { ManagePasswordComponent } from '../shared/manage-password/manage-password.component';
import { SuperAdminCareersComponent } from '../dashboard/super-admin/super-admin-careers/super-admin-careers.component';
import { SuperAdminCareersModule } from '../dashboard/super-admin/super-admin-careers/super-admin-careers.module';
import { PostJobComponent } from '../dashboard/super-admin/super-admin-careers/post-job/post-job.component';

const routes: Routes = [


  {
    path: 'home',
    component : EndCustomerComponent
  },
  {
    path: 'productEnquiry',
    component : ChatComponent
  },{
    path: 'products/enquiry/:id',
    component : EnquiryComponent
  },
  {
    path: 'products/enquiry/chat/:id',
    component : ChatComponent
  },
  {
    path: 'manage-password',
    component : ManagePasswordComponent
  },

  {
    path: 'user/home/view-job/:id',
    // loadChildren: () => import('../dashboard/super-admin/super-admin-careers/super-admin-careers.module').then(m => m.SuperAdminCareersModule)
    component : ViewJobComponent
  },
  {
    path: 'user/home/',
    component : PostJobComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EndCustomerRoutingModule { }

import { Component, Input } from '@angular/core';
import {CdkTreeNode, FlatTreeControl, TreeControl} from '@angular/cdk/tree';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map, switchMap, filter, forkJoin } from 'rxjs';
import { ProductsService } from 'src/app/services/products.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MasterDataService } from 'src/app/services/master-data.service';
import { HttpClient } from '@angular/common/http';
import { OemService } from 'src/app/services/oem/oem.service';
@Component({
  selector: 'app-category-deatils',
  templateUrl: './category-deatils.component.html',
  styleUrls: ['./category-deatils.component.scss']
})
export class CategoryDeatilsComponent  {
 
  filterColumns: string[] = ['Products', 'Region', 'Application', 'Shape', 'Size', 'Model', 'SampleType'];
  category: any; 
  products: any[] = [];
  breadcrumb: any;
  originalData: any[] = [];
  @Input() data : any;
  itemsPerPage = 12;
  showNoRecordsFounds: boolean = false;
  newData : any[] = [];
  contentItems = [
    { name: 'Product Count', value: '1000 products' },
    { name: 'Price Range', value: '100 - 500', fromValue: '100', fromUnit: 'USD' }
  ];

  form: FormGroup = new FormGroup({});

  // @Input fi

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private categoryService: CategoryService,
    private productsService: ProductsService,
    private masterDataService:MasterDataService,
    private http: HttpClient,
    private oemService: OemService
  ) {
    
  }
 
  executeGetRequestURL(url:string,params:any,type:string){
    (this as any)[type]=[];
    this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        (this as any)[type]=data.data;
      }
     
    },_error=>{
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
    forkJoin([
      this.loadCategoryDetails(routeParams['id']),
      this.getProductsByCategory(routeParams['id']),
      this.getBreadcrumb(routeParams['id']),
      this.getProductDetails(routeParams['id']),
    ]).subscribe();
    });
  }

  loadCategoryDetails(id: String) {
    return this.categoryService.getCategoriesbyId(id).pipe(
      map((res) => {
        this.category = res?.data;
     
        return res;
      })
    );
  }

  getProductsByCategory(categoryId: string) {
   
    return this.productsService.getAllProductsByCategory(categoryId).pipe(
      map((res: any) => {
       
        this.products = res?.data?.map((product: any) => ({
          id: product?.id,
          newBadge: true,
          productImage: product?.images[0],
          title: product?.name,
          subtitleImage: 'assets/images/gima-logo.svg',
          contentItems: product?.specifications?.map((spec: any) => spec?.name),
          hoverText: product?.description
        }));
        this.originalData = [...this.products];
        return res;
      })
    );
  }

  getBreadcrumb(categoryId: string) {
    return this.categoryService.getBreadcrumb(categoryId).pipe(
      map((res) => {
        this.breadcrumb = res;
        return res;
      })
    );
  }
    
  getProductDetails(id: string) {
    return this.productsService.getProductDetails(id);
  }

  filteredData = this.products;

  paginatedItems: { catalog: string, title: string, type: string }[] = [];

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.products = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
   
  }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-distributors-network',
  templateUrl: './distributors-network.component.html',
  styleUrls: ['./distributors-network.component.scss']
})
export class DistributorsNetworkComponent implements OnInit{ 
  @Output() viewProfileEvent = new EventEmitter<any>();
  @Input() data : any;
  itemsPerPage = 9;
  products: any[] = [];
  originalData: any[] = [];
  showNoRecordsFounds: boolean = false;
 
  constructor(
    private route: ActivatedRoute,
    private oemService: OemService,
    private router: Router,
  ) { }
  
  ngOnInit(): void {
    this.getAllDistributorById(this.data.id);
  }

  paginatedItems: { logo: string, distributorName: string, address: string, phoneNumber: string, email: string, city: string, State: string, country:string }[] = [];
  getAllDistributorById(brandId: string) {
    this.oemService.getOemDistributorById(brandId).subscribe({
      next: (response) => {
        this.products = response.data; 
        this.originalData  =response.data;
        this.paginatedItems = this.products.slice(0, this.itemsPerPage);
      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
    });
  }

  onPageChanged(paginatedData: any[]): void {
    this.paginatedItems = paginatedData;
  }

  filteredData = this.products;

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    console.log('Received filtereddasdasdas')
    console.log('Received filtered data:', filtered);
    this.products = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
    console.log('Received filtered dataaaaaaaaaaaaaa:', filtered);
  }

  onViewProfileClick(product : any): void {
    console.log('product', product);
    
    const dataToSend = {
      id: product.distributorId,
      name: product.distributorName,
      logo : product.logo,
      url : product.url,
    };
    this.viewProfileEvent.emit(dataToSend);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { ChecklistDatabase } from 'src/app/services/shared/product-settings/check-list-database';
import { TodoItemFlatNode, TodoItemNode } from 'src/app/models/product-settings-model';

@Component({
  selector: 'app-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit {
  @Input() productInformationForm!: FormArray;
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl!: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener!: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  isAnyNodeSelected = false;

  constructor(
    private categoryService: CategoryService,
    private _database: ChecklistDatabase,
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.getAllCategories();
    this.restoreSelectionState()
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children && node.children.length > 0;
    flatNode.id = node.id;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    node.selected = !node.selected;
    this.updateSelectionState();
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
    this.updateFormArray(node);
  }

  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    node.selected = !node.selected;
    this.updateSelectionState();
    this.updateFormArray(node);
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getAllCategories() {
    this.categoryService.getAllParentCategories().subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          this._database.initialize(response.data);
          this.restoreSelectionState();
        }
      },
      error: (error) => {
        console.error('Error fetching category data:', error);
      }
    });
  }
  restoreSelectionState(): void {
    const selectedIds = this.productInformationForm.value;
    this.treeControl.dataNodes.forEach(node => {
      node.selected = selectedIds.includes(node.id);
      if (node.selected) {
        this.checklistSelection.select(node);
        this.expandParentNodes(node);
      }
    });
    this.updateSelectionState();
  }
  markSelectedNodes() {
    this.treeControl.dataNodes.forEach(node => {
      if (node.selected) {
        this.expandParentNodes(node);
      }
    });
    this.treeControl.expandAll(); // Add this line to expand all nodes
  }

  updateSelectionState(): void {
    this.isAnyNodeSelected = this.treeControl.dataNodes.some(node => node.selected);
    this.dataSource.data = [...this.dataSource.data];
  }

  updateFormArray(node: TodoItemFlatNode): void {
    const selectedIds = this.productInformationForm.value;
    if (node.selected) {
      if (!selectedIds.includes(node.id)) {
        this.productInformationForm.push(new FormControl(node.id));
      }
    } else {
      const index = selectedIds.indexOf(node.id);
      if (index !== -1) {
        this.productInformationForm.removeAt(index);
      }
    }
  }

 

  expandParentNodes(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.treeControl.expand(parent);
      parent = this.getParentNode(parent);
    }
  }
}

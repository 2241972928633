import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalInfomationComponent } from '../personal-infomation/personal-infomation.component';
import { ProfileAboutComponent } from '../profile-about/profile-about.component';
import { SkillSetProfileComponent } from '../skill-set-profile/skill-set-profile.component';
import { SpecialitiesComponent } from '../specialities/specialities.component';
import { CertificationProfileComponent } from '../certification-profile/certification-profile.component';
import { EducationProfileComponent } from '../education-profile/education-profile.component';
import { WorkExperienceProfileComponent } from '../work-experience-profile/work-experience-profile.component';
import { MoreInfoProfileComponent } from '../more-info-profile/more-info-profile.component';
import { ResumeProfileComponent } from '../resume-profile/resume-profile.component';

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.scss']
})
export class DoctorProfileComponent {

  @Input() doctorForm!:FormGroup;

  @Input() jobPost: any
  @Input() endCustomerData:any

  @Output() onUpdateEvent:EventEmitter<any>=new EventEmitter(); 

  @ViewChild(PersonalInfomationComponent) personalInfomationComponent!:PersonalInfomationComponent;
  @ViewChild(ProfileAboutComponent) profileAboutComponent!:ProfileAboutComponent;
  @ViewChild(SpecialitiesComponent) specialitiesComponent!:SpecialitiesComponent;
  @ViewChild(EducationProfileComponent) educationProfileComponent!:EducationProfileComponent;
  @ViewChild(WorkExperienceProfileComponent) workExperienceProfileComponent!:WorkExperienceProfileComponent;
  @ViewChild(CertificationProfileComponent) certificationProfileComponent!:CertificationProfileComponent;
  @ViewChild(ResumeProfileComponent) resumeProfileComponent!:ResumeProfileComponent;
  @ViewChild(SkillSetProfileComponent) skillSetProfileComponent!:SkillSetProfileComponent;
  @ViewChild(MoreInfoProfileComponent) moreInfoProfileComponent!:MoreInfoProfileComponent;

  onUpdate(event:any){

    console.log(event);
    
    this.onUpdateEvent.emit(event);
  }

}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { Account } from 'src/app/models/account';
import { Distributor, DistributorOEMManagement, DistributorsSummaryData, GeographicalOptions } from 'src/app/models/distributor-model';
import { Continent, Currency } from 'src/app/models/master-data.model';
import { OEMTableData } from 'src/app/models/oem-models';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { AnyMapping } from 'three';
import { apiUrls, OEMUrls, serverRoutes } from 'src/app/utils/apiUrls';
import { DISTRIBUTOR_ID, OEM_ID } from 'src/app/utils/constants';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {

  MASTERDATA_BASE_URL = apiUrls.masterdata;
  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  userId!: string;

  serveURL = `${environment.serverScheme}${environment.serverDns}`;
  oemId: any;
  distributorId: any;
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.localStorageService.getUserDetails().subscribe(data => {
      this.userId = data.userId;
      this.oemId = this.localStorageService.getItem(OEM_ID);
      this.distributorId = this.localStorageService.getItem(DISTRIBUTOR_ID);
    })
  }

  getDistributorsSummaryData(id: string): Observable<any> {
    return this.http.get<any>(`${this.ONBOARDING_BASE_URL}/distributor/statistics/${id}`).pipe(
    );
  }

  /* public getCountries(): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + '/countries';
    return this.http.get<any>(url);
  } */

  public getCountriesByContinent(continentName: string): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + `countries?pageNumber=0&continetName=${continentName}&pageSize=1000`;
    return this.http.get<any>(url);
  }

  public getStatesByCountry(countryName: string): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + `state/states?pageNumber=0&countryName=${countryName}&pageSize=1000`;
    return this.http.get<any>(url);
  }

  public getStates(): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + '/states';
    return this.http.get<any>(url);
  }

  public getCities(): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + '/cities';
    return this.http.get<any>(url);
  }

  public getCitiesByState(stateName: string): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + `cities?stateName=${stateName}&pageNumber=0&pageSize=1000`;
    return this.http.get<any>(url);
  }


  public addDistributor(distributorForm: Distributor): Observable<ApiResponseModel<Distributor>> {
    const url = this.ONBOARDING_BASE_URL + `/distributor`;
    return this.http.post<any>(url, distributorForm);
  }

  public addDistributorFromOEM(distributorForm: any): Observable<any> {

    const url = this.ONBOARDING_BASE_URL + `/distributor/`;
    return this.http.post<any>(url, distributorForm);
  }

  public addPointOfContact(branchId: string, contacts: any): Observable<any> {
    const url = this.ONBOARDING_BASE_URL + `/pointOfContact/many/${branchId}`;
    return this.http.post<any>(url, contacts);
  }

  public addRevenues(branchId: string, revenues: any) {
    const url = this.ONBOARDING_BASE_URL + `/revenue/many/${branchId}`;
    return this.http.post<any>(url, revenues);
  }

  getCurrency(): Observable<any> {
    const url = this.MASTERDATA_BASE_URL + `/currency`
    return this.http.get<any>(url);
  }

  public getAllDistributors(): Observable<ApiResponseModel<Distributor[]>> {
    const url = this.ONBOARDING_BASE_URL + `/distributor`;
    return this.http.get<ApiResponseModel<Distributor[]>>(url);
  }

  public getDistributorById(id: any): Observable<ApiResponseModel<Distributor>> {
    const url = this.ONBOARDING_BASE_URL + `/distributor/${id}`;
    return this.http.get<ApiResponseModel<Distributor>>(url);
  }

  deleteDistributorById(distributorId: AnyMapping): Observable<ApiResponseModel<any>> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId', this.userId);
    const url = this.ONBOARDING_BASE_URL + `/distributor/${distributorId}`;
    return this.http.delete<ApiResponseModel<Distributor>>(url, { headers });
  }

  getCurrencies(pageNumber: number, pageSize: number): Observable<ApiResponseModel<Currency[]>> {
    const url = `${this.MASTERDATA_BASE_URL}/Currencies?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.http.get<ApiResponseModel<Currency[]>>(url);
  }
  getContinents(): Observable<Continent[]> {
    return this.http.get<Continent[]>(`${this.MASTERDATA_BASE_URL}/continents?pageNumber=0&pageSize=20`);
  }

  getCountries(pageNumber: number, pageSize: number): Observable<ApiResponseModel<any[]>> {
    const url = `${this.MASTERDATA_BASE_URL}/countries?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.http.get<ApiResponseModel<any[]>>(url);
  }

  getMasterData(): Observable<any> {
    return forkJoin({
      continents: this.getContinents(),
      currencies: this.getCurrencies(0, 100),
      countries: this.getCountries(1, 100),
    });
  }

  public executePostRequestForAuth(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
    return this.http.post(this.AUTH_BASE_URL + url, body, { headers: headers });
  }

  public getDistributorSummery(geographicalOptions: GeographicalOptions): Observable<ApiResponseModel<DistributorsSummaryData>> {
    const url = this.ONBOARDING_BASE_URL + `/distributor/summary`;
    return this.http.post<any>(url, geographicalOptions);
  }

  getAccountType(accountType: any): Observable<ApiResponseModel<Account[]>> {
    const url = this.ONBOARDING_BASE_URL + `/account?accountTypes=${accountType}`;
    return this.http.get<ApiResponseModel<Account[]>>(url);
  }

  searchDistributors(keyword: string): Observable<ApiResponseModel<Distributor[]>> {
    return this.http.get<ApiResponseModel<Distributor[]>>(this.ONBOARDING_BASE_URL + `/distributor/search?keyword=${keyword}`);
  }

  filterDistributors(filter: any): Observable<ApiResponseModel<Distributor[]>> {
    return this.http.post<ApiResponseModel<Distributor[]>>(this.ONBOARDING_BASE_URL + `/distributor/filter`, filter);
  }

  public updateDistributor(distributorForm: Distributor, distributorId: any): Observable<ApiResponseModel<Distributor>> {
    const url = this.ONBOARDING_BASE_URL + `/distributor/${distributorId}`;
    return this.http.put<any>(url, distributorForm);
  }

  public addOEMSByDistributor(data: any, distributorId: any){
    const url = this.ONBOARDING_BASE_URL + `/distributor/${distributorId}`;
    return this.http.put<any>(url, data);
  }

  public addDistributorOemManagement(distributor: DistributorOEMManagement): Observable<ApiResponseModel<string[]>> {
    return this.http.post<ApiResponseModel<string[]>>(this.AUTH_BASE_URL + `user/signup`, distributor);
  }

  getOEMs(page: number, size: number, startWith?: string): Observable<ApiResponseModel<OEMTableData[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = new HttpParams();
    if (startWith) {
      params = params.set('startWith', startWith);
    }
    return this.http.get<ApiResponseModel<OEMTableData[]>>(`${this.ONBOARDING_BASE_URL}/OEM/list?page=${page}&size=${size}`, { headers, params });
  }

  getdistributorDetailsList(id: string) {
    return this.http.get<ApiResponseModel<Distributor>>(`${this.ONBOARDING_BASE_URL}/productinfo/distributor-detailsby/${id}`);
  }

  formatDate(establishedIn: Date) {
    // Convert timestamp to Date
    const date = new Date(establishedIn);

    // Format date to DD-MM-YYYY
    return this.convertDateToYYYYMMDD(date);
  }

    // Method to convert date to YYYY-MM-DD
    convertDateToYYYYMMDD(date: Date): string {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    }

  getAllDistributorsWithOutFilter(data: any, pageNumber?: number, pageSize?: number): Observable<ApiResponseModel<Distributor[]>> {
    const params = new HttpParams()
    .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
    .set('size', pageSize ? pageSize.toString() : '10');
    return this.http.post<ApiResponseModel<Distributor[]>>(this.ONBOARDING_BASE_URL+`/distributor/details-list`, data, { params }).pipe();
  }
  getAllDistributorss(data: any,pageNumber?: number, pageSize?: number): Observable<ApiResponseModel<Distributor[]>> {
    const params = new HttpParams()
    .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
    .set('size', pageSize ? pageSize.toString() : '1000');
    return this.http.post<ApiResponseModel<Distributor[]>>(this.ONBOARDING_BASE_URL+`/distributor/details-list`,data, { params }).pipe();
  }

  oemByDistributors(id: string){
    return this.http.get<any>(this.ONBOARDING_BASE_URL+`/distributor/oems-by-distributorid/${id}`)
  }

  sendEnquiry(data: any){
    return this.http.post<any>(this.ONBOARDING_BASE_URL+`/enquiry`, data);
  }
  getEnquiriesById(): Observable<any> {
    return this.http.get<any>(this.ONBOARDING_BASE_URL+`/enquiry/get-enquiries-by-id/${this.oemId}`).pipe(
      map(response => {
        const productEnquiries = response.data.enquiryDetails.productEnquiries;
        const distributorEnquiries = response.data.enquiryDetails.distributorEnquiries;
        
        return {
          productEnquiries,
          distributorEnquiries
        };
      })
    );
  }
  getEnquiriesByIdForDistributors(): Observable<any> {
    return this.http.get<any>(this.ONBOARDING_BASE_URL+`/enquiry/get-enquiries-by-id/${this.distributorId}`).pipe(
      map(response => {
        const productEnquiries = response.data.enquiryDetails.productEnquiries;
        
        
        return {
          productEnquiries,
         
        };
      })
    );
  }
  public updateStatus(status: string , role : string , id : string): Observable<any> {
    const params = new HttpParams()
      .set('status', status)
      .set('role', role)
      .set('id', id);
    return this.http.post<any>(`${OEMUrls.updateStatus}`, null, { params });
  }
}




import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeWhile, concatMap } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-career-filter',
  templateUrl: './career-filter.component.html',
  styleUrls: ['./career-filter.component.scss']
})
export class CareerFilterComponent implements OnInit {
  [x: string]: any;
  countryDropdown!: any[];
  states!: any[];
  cities!: any[];
  jobCategories!: any[];
  jobSkills!: any[];

  careerFilterForm: FormGroup = new FormGroup({
    postedDate: new FormControl(null),
    jobCategories: new FormControl([]),
    skills: new FormControl([]),
    experience: new FormControl(null),
    company: new FormControl(null),
    countries: new FormControl([]),
    city: new FormControl(null),
    state: new FormControl(null),
    showOnlyActiveJobPosts: new FormControl(false)
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private masterDataService: MasterDataService) { }

  ngOnInit(): void {
    this.getMasterData();
  }

  private getMasterData() {
    this.loadJobCategories();
    this.loadJobSkills();
    this.getCountries(null);
    this.changeLocations();
  }

  private changeLocations() {
    this.careerFilterForm.get('countries')?.valueChanges.subscribe((data: any) => {
      this.getCountries(data ? { startsWith: data } : null);
    });

    this.careerFilterForm.get('state')?.valueChanges.subscribe((data: any) => {
      const country = this.careerFilterForm.get('countries')?.value;
      if (country) {
        this.getStatesByCountryName(data ? { countryName: country, startsWith: data } : { countryName: country });
      }
    });

    this.careerFilterForm.get('city')?.valueChanges.subscribe((data: any) => {
      const state = this.careerFilterForm.get('state')?.value;
      if (state) {
        this.getCityiesByStateName(data ? { stateName: state, startsWith: data } : { stateName: state });
      } else {
        this.getCityiesByStateName(data ? { startsWith: data } : null);
      }
    });

  }

  private loadJobCategories() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobCategories: any[] = [];
    let morePages = true;

    this.masterDataService.getJobCategories(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobCategories = allJobCategories.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobCategories(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobCategories = allJobCategories.concat(data.data);
        this.jobCategories = allJobCategories;
      });
  }

  private loadJobSkills() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobSkills: any[] = [];
    let morePages = true;

    this.masterDataService.getJobSkills(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobSkills = allJobSkills.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobSkills(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobSkills = allJobSkills.concat(data.data);
        this.jobSkills = allJobSkills;
      });
  }

  filterCareer() {
    const jobPostFilter = this.careerFilterForm.value;
    console.log('jobposrtfilert', jobPostFilter, this.careerFilterForm);
    
    jobPostFilter.jobCategories = this.checkAndConvertArray(jobPostFilter.jobCategories);
    jobPostFilter.skills = this.checkAndConvertArray(jobPostFilter.skills);
    jobPostFilter.countries = this.checkAndConvertArray(jobPostFilter.countries);
    this.dialogRef.close(JSON.parse(JSON.stringify(jobPostFilter)));
    this.careerFilterForm.reset();
  }

  cancelCareerFilter() {
    // this.careerFilterForm.reset();
    this.dialogRef.close(null);
  }
  clearCareerFilter() {
    this.careerFilterForm.reset();
  }

  private checkAndConvertArray(value: any) {
    return (value !== undefined && value !== null && value.length > 0) ? [value] : null;
  }

  getCountries(params: any) {
    this.executeGetRequestURL(MweConstants.countryURL, params, 'countryDropdown');
  }

  getStatesByCountryName(params: any) {
    this.executeGetRequestURL(MweConstants.stateUrl, params, 'states');
  }

  getCityiesByStateName(params: any) {
    this.executeGetRequestURL(MweConstants.cityUrl, params, 'cities');
  }

  executeGetRequestURL(url: string, params: any, type: string) {
    (this as any)[type] = [];
    this.masterDataService.executeGetRequestURL(url, params).subscribe({
      next: (data: any) => {
        if (data && data?.data && data.data.length > 0) {
          (this as any)[type] = data.data;
        }
      }, error: (error: any) => {
      }
    })
  }

  onCountrySelected(event: any) {
    this.getStatesByCountryName({ countryName: this.careerFilterForm.get('countries')?.value });
  }

  onStateSelected(event: any) {
    this.getCityiesByStateName({ stateName: this.careerFilterForm.get('state')?.value });
  }
}

<div class="tbs" style="background-color: #fff;">
    <div class="d-flex justify-content-around mt-5 " style="background-color: #fff;">
        <div class="tabs-container" style="background-color: #fff; width: 100%;">
            <button class="tabs first-tab" 
                [ngStyle]="{'color': tabName === 'upcomingwebinar' ? '#0D87C8' : '#D7D7D7', 'border': tabName === 'upcomingwebinar' ? '1px solid #D6D6D6' : 'none', 'border-bottom': tabName === 'upcomingwebinar' ? 'none' : '1px solid #D6D6D6', 'border-top-left-radius': tabName === 'upcomingwebinar' ? '10px' : '', 'border-top-right-radius': tabName === 'upcomingwebinar' ? '10px' : '' }" 
                (click)="activateCurrentab('upcomingwebinar')">
                <img src="../../../assets/images/upcoming webinars.svg"> Upcoming Webinars
            </button>
            <button class="tabs" 
                [ngStyle]="{'color': (tabName === 'archievedwebinar') ? '#0D87C8' : '#D7D7D7', 'border': (tabName === 'archievedwebinar') ? '1px solid #D6D6D6' : 'none', 'border-bottom': (tabName === 'archievedwebinar') ? 'none' : '1px solid #D6D6D6',  'border-top-left-radius': tabName === 'archievedwebinar' ? '10px' : '', 'border-top-right-radius': tabName === 'archievedwebinar' ? '10px' : '' }" 
                (click)="activateCurrentab('archievedwebinar')">
                <img src="../../../assets/images/archieved webinar.svg"> Archived Webinars
            </button>
        </div>
        <div class="remainingtabs" style="    left: 50%;
        transform: translateX(-17%);
        width: 100%;
        border-bottom: 1px solid #d7d7d7;"></div>
    </div>

    <div class="row" style="flex: 3; row-gap: 1rem;margin-top: 1rem;">
        <div class="col-md-4" *ngFor="let webinar of displayWebinars">
            <div class="d-flex card" (click)="viewWebinar(webinar)">
                <div class="card-img">
                    <img src="../../../assets/images/website-webinar-card.svg" width="100%" height="auto">
                </div>
                <div class="card-heading">
                    <p> {{ webinar.webinarTitle }} </p>
                </div>
                <div class="card-info">
                    <div class="card-info-calender d-flex">
                        <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                        <div class="d-flex flex-column justify-content-around">
                            <p class="date-time"> {{ webinar.webinarDate }}  {{ webinar.webinarTime }} </p>
                            <p class="specialization m-0"> {{ webinar.speciality }} </p>
                        </div>
                    </div>
                    <div class="card-info-person d-flex ">
                        <img src="../../../assets/images/webiste-webinar-card-person.svg">
                        <div class="d-flex flex-column justify-content-around">
                            <p class="person-name" *ngFor="let speaker of webinar.speakersName"> {{ speaker }} </p>
                            <p class="specialization m-0"> {{ webinar.speciality }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { map } from 'rxjs';
import { Component, inject, Inject, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { Distributor } from 'src/app/models/distributor-model';
import { ListColumn } from 'src/app/models/list-column.model';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AddDistributorComponent } from '../add-distributor/add-distributor.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Renderer2 } from '@angular/core';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-assign-more-oems',
  templateUrl: './assign-more-oems.component.html',
  styleUrls: ['./assign-more-oems.component.scss']
})

export class AssignMoreOemsComponent {
  addOEM(event: MatChipInputEvent) {
    event.chipInput!.clear();
  }

  loadOEM() {
    this.getCountries('');
  }

  countryDropdown:any=[]; 
  stateDropdown:any=[];
  cityDropdown:any=[];
  assignMoreOEMsForm!:FormGroup;
  OEMsNetwork!: Distributor[];
  allOEMs!: Distributor[];
  selectedIds: string[] = [];
  filteredOEMs: Distributor[] = [];
  index: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  addOnBlur = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectedCountry: any = '';
  isEditMode: any;
  announcer = inject(LiveAnnouncer);
  status: string = 'Active'

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private distributorService: DistributorService, public dialogRef: MatDialogRef<any>,private masterDataService:MasterDataService,public dialog: MatDialog,private fb:FormBuilder,private endCustomerService:EndCustomerService,private renderer: Renderer2, private oemService: OemService )  {
    this.selectedIds =data.selectedIds;
    console.log("this.selectedIds",this.selectedIds);
  }
  
  columns =  [
    { name: 'Company Name', property: 'companyName', visible: true, isModelProperty: true, isText: true },
    { name: 'name', property: 'name', visible: true, isModelProperty: true, isText: true },
    { name: 'continent', property: 'continent', visible: true, isModelProperty: true, isText: true },
    { name: 'country', property: 'country', visible: true, isModelProperty: true, isText: true },
    { name: 'state', property: 'state', visible: true, isModelProperty: true, isText: true },
    { name: 'city', property: 'city', visible: true, isModelProperty: true, isText: true },
    {name:'Status',property:'active',visible:true,isModelProperty:true,isStatus:true},
    { name: 'Add', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        {isCheckBox: true, visible: false,  actionType: MweConstants.checkBox ,hasAction: true,checkBox: true, conditionValue: true},  
      ]
    }
  ] as ListColumn[];

  selectedIdsData: any[] = [];
    
  selectedAction(event: any): void {
    switch (event.action) {
      case MweConstants.checkBox:        
        this.index = this.selectedIds.indexOf(event.row.id);
        if (this.index > -1) {
          this.selectedIds.splice(this.index, 1);
          this.selectedIdsData.splice(event.row, 1);
        } else {
          this.selectedIds.push(event.row.id);
          this.selectedIdsData.push(event.row);
        }
        console.log(this.selectedIdsData);
        
        break;
      default:
        break;
    }

  }

  addOEMs() {
    console.log('this.selectedIds', this.selectedIds, this.selectedIdsData);
    
    if(this.dialogRef){
      this.dialogRef.close({selectedIds: this.selectedIds, selectedIdsData: this.selectedIdsData});
    }
  }

  onPageChanged(event: { pageIndex: number, pageSize: number }) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize; 
    this.getAllOEMs(this.pageIndex, this.pageSize);  
  }

  ngOnInit(): void { 
    this.getAllOEMs();
    this.initializeexhibitionFilterForm();
    this.getCountries(null);
    this.getStatesByCountryName(null);
    this.getCityiesByStateName(null);
    if(this.data){
      this.assignMoreOEMsForm.patchValue(this.data);
    }
    this.country.valueChanges.subscribe((data: any)=>{
      if(data){
        this.getCountries({startsWith:data});
      }      
      else{
        this.getCountries(null);
      }
    });
    this.state.valueChanges.subscribe((data: any)=>{
      if(this.assignMoreOEMsForm.get('countries')?.value){
        console.log('working');
        
        if(data){
          this.getStatesByCountryName({countryName:this.assignMoreOEMsForm.get('countries')?.value,startsWith:data});
        }else{
          this.getStatesByCountryName({countryName:this.assignMoreOEMsForm.get('countries')?.value});
        }
      }
    else{
      if(data){
        this.getStatesByCountryName({startsWith:data});
      }
      else{
        this.getStatesByCountryName(null);
      }
    }
    });
    this.city.valueChanges.subscribe((data: any)=>{
      if(this.assignMoreOEMsForm.get('states')?.value){
        if(data){
          this.getCityiesByStateName({stateName:this.assignMoreOEMsForm.get('states')?.value,startsWith:data});
        }else{
          this.getCityiesByStateName({stateName:this.assignMoreOEMsForm.get('states')?.value});
        }
      }
    else{
      if(data){
        this.getCityiesByStateName({startsWith:data});
      }
      else{
        this.getCityiesByStateName(null);
      }
    }    
    });
  }

  getAllOEMs(pageIndex?: number, pageSize?: number) {
    this.oemService.getoems(100).subscribe(res => {
     this.data = res.data;     
     this.OEMsNetwork = this.data
     this.allOEMs=this.data;
    })    
  }

  filterDistributors(filters: any): void {
    this.OEMsNetwork = this.allOEMs.filter(distributor => {
      return (!filters.continent || distributor.continent === filters.continent) &&
        (!filters.countries || distributor.country === filters.countries) &&
        (!filters.states || distributor.state === filters.states) &&
        (!filters.cities || distributor.city === filters.cities);
    });
  }

  onCountrySelected(event: any) {
    this.selectedCountry=event.option.value
    this.getStatesByCountryName({countryName:event.option.value});
    this.filterDistributors(this.assignMoreOEMsForm.value);
  }
    onStateSelected(event: any) {
    this.getCityiesByStateName({stateName:event.option.value});
    this.filterDistributors(this.assignMoreOEMsForm.value);
  }
  onCitySelected(event: any) {
    this.filterDistributors(this.assignMoreOEMsForm.value);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
  }

  initializeexhibitionFilterForm(){
    this.assignMoreOEMsForm=this.fb.group({
      continents:new FormControl(''),
      countries:new FormControl(''),
      states:new FormControl(''),
      cities:new FormControl(''),
    })
  }

  getCountries(params:any){
    this.executeGetRequestURL(MweConstants.countryURL,params,'countryDropdown');
  }

  getStatesByCountryName(params:any){
    this.executeGetRequestURL(MweConstants.stateUrl,params,'stateDropdown');
  }
  getCityiesByStateName(params:any){
    this.executeGetRequestURL(MweConstants.cityUrl,params,'cityDropdown');
  }
  executeGetRequestURL(url:string,params:any,type:string){
    (this as any)[type]=[];
    this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
      if(data && data?.data && Array.isArray(data.data)){
        (this as any)[type]=data.data;
       }
    },error=>{
    })
  }
  executeGetRequestURLForEndCustomer(url:string,params:any,type:string){
    (this as any)[type]=[];
    this.endCustomerService.executeGetRequestURL(url,params).subscribe((data: { data: string | any[]; })=>{
      if(data && data?.data && data.data.length>0){
        (this as any)[type]=data.data;
      }
    },(error: any)=>{
    })
  }

  getCountryDropdown(){
    this.countryDropdown=[];
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.assignMoreOEMsForm.get('states')?.reset();
    this.assignMoreOEMsForm.get('cities')?.reset();
  }

  getStatesDropdown(){
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.assignMoreOEMsForm.get('states')?.reset();
    this.assignMoreOEMsForm.get('cities')?.reset();
    if(this.assignMoreOEMsForm.get('countries')?.value){
      this.getStatesByCountryName({countryName:this.assignMoreOEMsForm.get('countries')?.value})
    }
  }

  getCityDropdown(){
    this.cityDropdown=[];
    this.assignMoreOEMsForm.get('cities')?.reset();
    if(this.assignMoreOEMsForm.get('states')?.value){
      this.getCityiesByStateName({stateName:this.assignMoreOEMsForm.get('states')?.value})
    }
  }

  get country(){
    return this.assignMoreOEMsForm.get('countries') as FormControl;
  }
  get state(){
    return this.assignMoreOEMsForm.get('states') as FormControl;
  }
  get city(){
    return this.assignMoreOEMsForm.get('cities') as FormControl;
  }

  shouldAutofocus(): boolean {
    return false; // Change this based on your condition
  }

  ngAfterViewInit() {
    this.autoCompleteCountryInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.country.setValue(null);
        }
      });
      this.autoCompleteStateInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.state.setValue(null);
        }      
      });
      this.autoCompleteCityInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.city.setValue(null);
        }
    });
  }
 
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.country.setValue('');
    }
    this.getAllOEMs();
  }

  removeOEM() {
    this.selectedCountry=''
    this.getAllOEMs();
  }
}
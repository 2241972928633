<div>
  <div style="display: flex; flex-wrap: wrap; gap: 20px;" *ngIf="isEducationCard">
    <div class="card"  *ngFor="let item of data">
      <div class="card-content">
        <img class="card-icon" src="{{ image }}" alt="Card Profile" />
        <div class="card-details" *ngIf="isEducationCard" >
          <div style="font-weight: 600;">{{ item.degree }}</div>
          <div >{{ item.fieldOfStudy }}</div>
          <div>{{ item.location }}</div>
          <div style="font-weight: 600;"> {{ item.yearFrom }} To {{ item.yearTo }} </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; flex-wrap: wrap; gap: 20px;" *ngIf="isWorkCard">
    <div class="card"  *ngFor="let item of data">
      <div class="card-content">
        <img class="card-icon" src="{{ image }}" alt="Card Profile" />
        <div class="card-details" >
          <div style="font-weight: 600;">{{ item.designation }}</div>
          <div>{{ item.specialization}}</div>
          <div>{{ item.organization }}</div>
          <div style="font-weight: 600;">{{ item.yearFrom }} To {{ item.yearTo }}</div>
        </div>
      </div>
    </div>
  </div>

</div>

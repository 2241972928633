import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MweConstants {

  constructor() { }
  static readonly accountTypeURL = '/account';
  static readonly continentURL = '/continents';
  static readonly countryURL = '/countries';
  static readonly countryURLv1 = '/v1?countries';
  static readonly stateUrl = '/states';
  static readonly cityUrl = '/cities';
  static readonly specialtyUrl = '/specialityInfo/speciality';
  static readonly positionUrl = '/position';
  static readonly equipmentUrl = '/equipment';
  static readonly serviceUrl = '/serviceInfo/service';
  static readonly skillSetUrl = '/skillsetInfo/skillset';
  static readonly uploadFile = 's3/upload';
  static readonly saveEndCustomerUrl = '/end-customer/create';
  static readonly getEndCustomerUrl = '/end-customer';
  static readonly getEndCustomerUrlById = '/end-customer/';
  static readonly getEndCustomerFilterUrl = '/end-customer/filter';
  static readonly updateEndCustomerUrl = '/end-customer/update/';
  static readonly productSetting = 'productSetting/';
  static readonly productSettingAllProduct = 'productSetting/allProduct';
  static readonly deleteEndCustomerUrl = '/end-customer/delete/';
  static readonly getCustomerByUserId = '/end-customer/customerByUserId/';
  static readonly checkEmailUrl = 'user/check-email';
  static readonly getAllRoles = '/role-type/all/roleTypes';
  static readonly createRole = '/role-type/create';
  static readonly deleteRole = '/role-type/delete/';
  static readonly updateRole = '/role-type/update/';
  static readonly getAllDepartments = '/department';
  static readonly createStaff = '/staff/create';
  static readonly getAllUserUrl = '/staff/all';
  static readonly getUserByIdUrl = '/staff/';
  static readonly updateStaff = '/staff/update/';
  static readonly deleteStaff = '/staff/delete/';

  static readonly getJobPostUrl = '/careers';
  static readonly saveJobPostUrl = MweConstants.getJobPostUrl.concat("/create");
  static readonly getJobPostFilterUrl = MweConstants.getJobPostUrl.concat("/filter");
  static readonly updateJobPostUrl = MweConstants.getJobPostUrl.concat("/update/");
  static readonly deleteJobPostUrl = MweConstants.getJobPostUrl.concat("/delete/");
  static readonly applyJobPostUrl = MweConstants.getJobPostUrl.concat("/apply");

  static readonly jobSkillsUrl = '/jobskills';
  static readonly jobCategoriesUrl = '/jobcategories';

  static readonly addAction = 'add';
  static readonly viewAction = 'view';
  static readonly editAction = 'edit';
  static readonly deleteAction = 'delete';
  static readonly filterAction = 'filter';
  static readonly searchAction = 'search';
  static readonly exportAction = 'export';
  static readonly importAction = 'import';
  static readonly linkAction = 'link';
  static readonly fileDownloadAction = 'fileDownload';
  static readonly fillterDropdownAction = 'fillterDropdown';
  static readonly filterByDateAction = 'filterByDate';
  static readonly ACCOUNT_TYPE = 'admin';
  static readonly sendRequest: 'sendRequest';
  static readonly sendMail: 'sendMail';
  static readonly scheduleCall: 'scheduleCall';
  static readonly checkBox: 'checkBox';

  static readonly viewJobApplicationsAction = 'viewJobApplications';
  static readonly viewExhibitionsAction = 'viewExhibitions';


}

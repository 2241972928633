import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from "src/app/components/shared/shared.module";
import { CustomMaterialModule } from "src/app/material.module";
import { PostJobComponent } from './post-job/post-job.component';
import { CareerFilterComponent } from './career-filter/career-filter.component';
import { ViewJobComponent } from './view-job/view-job.component';
import { SuperAdminCareersComponent } from "./super-admin-careers.component";
import { ViewAllJobApplicationsComponent } from './view-all-job-applications/view-all-job-applications.component';
import { ViewJobApplicantDetailsComponent } from './view-job-applicant-details/view-job-applicant-details.component';
import { JobPreviewComponent } from './job-preview/job-preview.component';


const routes: Routes = [
    {
        path: '',
        component: SuperAdminCareersComponent,
    },
    {
        path: 'post-job',
        component: PostJobComponent,
    },
    {
        path: 'update-job/:id',
        component: PostJobComponent,
    },
    {
        path: 'view-job/:id',
        component: ViewJobComponent,
    },
    {
        path: 'view-job-applications/:id',
        component: ViewAllJobApplicationsComponent
    },
    {
        path: 'view-job-applicant-details/:id/:applicantId',
        component: ViewJobApplicantDetailsComponent,
    },
    {
        path: 'download-job-applicant-details/:id/:applicantId',
        component: ViewJobApplicantDetailsComponent,
    }
];

@NgModule({
    declarations: [
        SuperAdminCareersComponent,
        PostJobComponent,
        CareerFilterComponent,
        ViewJobComponent,
        ViewAllJobApplicationsComponent,
        ViewJobApplicantDetailsComponent,
        JobPreviewComponent,
    ],
    imports: [
        CommonModule, RouterModule.forChild(routes),
        TranslateModule,
        CustomMaterialModule,
        SharedModule,
        AngularEditorModule,
    ],
    exports: [RouterModule, SuperAdminCareersComponent, PostJobComponent, ViewJobComponent]
})
export class SuperAdminCareersModule { }
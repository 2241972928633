import { Component, Inject, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-exhibitionfilter',
  templateUrl: './exhibitionfilter.component.html',
  styleUrls: ['./exhibitionfilter.component.scss']
})
export class ExhibitionfilterComponent {

  [x: string]: any;
  countryDropdown:any=[]; 
  stateDropdown:any=[];
  cityDropdown:any=[];
  exhibitionFilterForm!:FormGroup;

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<any>,private masterDataService:MasterDataService,private fb:FormBuilder,private endCustomerService:EndCustomerService,)  {}
  ngOnInit(): void { 
    this.initializeexhibitionFilterForm();
    this.getCountries(null);
    this.getStatesByCountryName(null);
    this.getCityiesByStateName(null);
    if(this.data){
      this.exhibitionFilterForm.patchValue(this.data);
    }

    this.country.valueChanges.subscribe((data: any)=>{
      if(data){
        this.getCountries({startsWith:data});
        
      }      
      else{
        this.getCountries(null);
      }
    });

    this.state.valueChanges.subscribe((data: any)=>{
      if(this.exhibitionFilterForm.get('countries')?.value){
        if(data){
          this.getStatesByCountryName({countryName:this.exhibitionFilterForm.get('countries')?.value,startsWith:data});
        }else{
          this.getStatesByCountryName({countryName:this.exhibitionFilterForm.get('countries')?.value});
        
        }
      }
    else{
      if(data){
        this.getStatesByCountryName({startsWith:data});
      }
      else{
        this.getStatesByCountryName(null);
      }
    }
    });
    this.city.valueChanges.subscribe((data: any)=>{
      if(this.exhibitionFilterForm.get('states')?.value){
        if(data){
          this.getCityiesByStateName({stateName:this.exhibitionFilterForm.get('states')?.value,startsWith:data});
        }else{
          this.getCityiesByStateName({stateName:this.exhibitionFilterForm.get('states')?.value});
        }
      }
    else{
      if(data){
        this.getCityiesByStateName({startsWith:data});
      }
      else{
        this.getCityiesByStateName(null);
      }
    }    
    });
  }

  onCountrySelected(event: any) {
    this.getStatesByCountryName({countryName:event.option.value});
  }
    onStateSelected(event: any) {
    this.getCityiesByStateName({stateName:event.option.value});
  }
  
  ngOnChanges(changes: SimpleChanges): void {
      }

  initializeexhibitionFilterForm(){
    this.exhibitionFilterForm=this.fb.group({
      countries:new FormControl(''),
      states:new FormControl(''),
      cities:new FormControl(''),
      date :new FormControl(''),
         })
  }
  getCountries(params:any){
    this.executeGetRequestURL(MweConstants.countryURL,params,'countryDropdown');
  }
  getStatesByCountryName(params:any){
    this.executeGetRequestURL(MweConstants.stateUrl,params,'stateDropdown');
  }
  getCityiesByStateName(params:any){
    this.executeGetRequestURL(MweConstants.cityUrl,params,'cityDropdown');
  }
  executeGetRequestURL(url:string,params:any,type:string){
    this[type]=[];
    this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this[type]=data.data;
      }
    },error=>{
    })
  }
  executeGetRequestURLForEndCustomer(url:string,params:any,type:string){
    this[type]=[];
    this.endCustomerService.executeGetRequestURL(url,params).subscribe((data: { data: string | any[]; })=>{
      if(data && data?.data && data.data.length>0){
        this[type]=data.data;
      }
    },(error: any)=>{

    })
  }
  getStatesDropdown(){
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.exhibitionFilterForm.get('states')?.reset();
    this.exhibitionFilterForm.get('cities')?.reset();
    if(this.exhibitionFilterForm.get('countries')?.value){
      this.getStatesByCountryName({countryName:this.exhibitionFilterForm.get('countries')?.value})
    }
  }
  getCityDropdown(){
    this.cityDropdown=[];
    this.exhibitionFilterForm.get('cities')?.reset();
    if(this.exhibitionFilterForm.get('states')?.value){
      this.getCityiesByStateName({stateName:this.exhibitionFilterForm.get('states')?.value})
    }
  }
  get country(){
    return this.exhibitionFilterForm.get('countries') as FormControl;
  }
  get state(){
    return this.exhibitionFilterForm.get('states') as FormControl;
  }
  get city(){
    return this.exhibitionFilterForm.get('cities') as FormControl;
  }
get date(){
  return this.exhibitionFilterForm.get('date') as FormControl;
}
  ngAfterViewInit() {
    this.autoCompleteCountryInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.country.setValue(null);
        }     
      });
      this.autoCompleteStateInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.state.setValue(null);
        }      
      });
      this.autoCompleteCityInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.city.setValue(null);
        }
              });
  }
  filterExhibitionCustomer(){
    this.dialogRef?.close(this.exhibitionFilterForm.value);
      }
  clearFilter(){
    this.exhibitionFilterForm.reset();
  }
  closeDialog(){
    this.exhibitionFilterForm.reset();
    this.filterExhibitionCustomer();   
    this.dialogRef.close(null);
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as saveAs from 'file-saver';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {
    fileName: string = '';
    invalidFileType = false;
    fileType = '';
    oemId : string = ''; 
  
    constructor(
      private dialogRef: MatDialogRef<ImportComponent>, 
      private sweetAlertService: SweetAlertService,
      private allProductService: AllProductService,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
      const id = localStorage.getItem('oemId');
      if(id !== null && id !== undefined && id !== '') {
        this.oemId = id;
        console.log("this is oem id",this.oemId);
        
      }
    }
  
    acceptedFileFormats = ['text/csv']

    onFileSelected(event: any): void {
      const file: File = event.target.files[0];
      if (file) {
        this.invalidFileType = false
        if (this.acceptedFileFormats.indexOf(file.type) !== -1) {
          this.fileName = file.name;
          this.allProductService.importFile(file, this.oemId).subscribe(
            (response) => {
              this.dialogRef.close(true);
              this.sweetAlertService.showSuccess('', 'Product imported successfully !!');
            },
            (error) => {
              console.log("error", error);
              this.dialogRef.close(true);
              this.sweetAlertService.showError('', 'Failed to import product !!');
            }
          );

        } else {
          this.fileType = file.type;
          this.invalidFileType = true;
          return;
        }
      }
    }

  
    removeFile(): void {
      this.fileName = '';
      const fileInput = document.querySelector('#fileInput') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
      console.log('File removed');
    }

    downloadCSVFile () {
      const blob = new Blob([this.convertToCSV(this.sampleData)], { type: 'text/csv' });
      saveAs(blob, 'products.csv');
    }

    convertToCSV(data: any[]): string {
      if (!data.length) {
        return '';
      }
  
      const header = Object.keys(data[0]).join(',');
      const rows = data.map(item => Object.values(item).join(','));
      return `${header}\n${rows.join('\n')}`;
    }

    sampleData : any [] = [ 
      {
        "category": "524368c7-e03f-4f5f-9806-909d57b15f1b",
        "name": "Test-1",
        "productSku": "Test SKU CSV1",
        "releaseDate": "30-08-2024",
        "tags": "Tag1",
        "certificates": "Certificate1",
        "soldTillNow": "100",
      },
      {
        "category": "524368c7-e03f-4f5f-9806-909d57b15f1b",
        "name": "Test-2",
        "productSku": "Test SKU CSV2",
        "releaseDate": "30-08-2024",
        "tags": "Tag1",
        "certificates": "Certificate1",
        "soldTillNow": "100",
      },
      {
        "category": "524368c7-e03f-4f5f-9806-909d57b15f1b",
        "name": "Test-3",
        "productSku": "Test CSV1",
        "releaseDate": "30-08-2024",
        "tags": "Tag1",
        "certificates": "Certificate1",
        "soldTillNow": "100",
      },
      {
        "category": "524368c7-e03f-4f5f-9806-909d57b15f1b",
        "name": "Test-4",
        "productSku": "Test CSV2",
        "releaseDate": "30-08-2024",
        "tags": "Tag1",
        "certificates": "Certificate1",
        "soldTillNow": "100",
      }
    ]
}

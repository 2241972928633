import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-education-profile',
  templateUrl: './education-profile.component.html',
  styleUrls: ['./education-profile.component.scss']
})
export class EducationProfileComponent implements OnInit{

  educationDetailsColumns: ListColumn[] = [
    { name: 'Degree', property: 'degree', visible: true, isModelProperty: true ,isText: true},
    { name: 'From Year', property: 'yearFrom', visible: true, isModelProperty: true ,isText: true},
    { name: 'To Year', property: 'yearTo', visible: true, isModelProperty: true ,isText: true},
    { name: 'Institute Location', property: 'location', visible: true, isModelProperty: true ,isText: true},
    { name: 'Field of Study', property: 'fieldOfStudy', visible: true, isModelProperty: true ,isText: true},
  ] as ListColumn[];

  @Input() educationDetailsForm!:FormGroup;
  @Input() endCustomerData:any;
  showEditForm:boolean=false;
     copyEducationForm:any;
  data:any=[];
  degreeIcon:string="assets/images/degree-icon.svg";
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  yearDropdown:any=[];
  futureYearDropdown:any=[];

  constructor(private fb :FormBuilder ){}
  ngOnInit(): void {
    console.log(this.educationDetailsForm.value);
    
    this.data=JSON.parse(JSON.stringify( this.educationDetailsForm.get('educationInfos')?.value));
   this.getYearDropdown();
   this.getFutureYearDropdown();
  }

  getYearDropdown(){
    for(let year=new Date().getFullYear();year>=1947;year--){
      this.yearDropdown.push(year);
    }
  }

  getFutureYearDropdown(){
    for(let year=new Date().getFullYear()+5;year>=1947;year--){
      this.futureYearDropdown.push(year);
    }
  }

  get educationInfos() {
    return this.educationDetailsForm?.controls["educationInfos"] as FormArray;
  }

addEducation(){
  this.copyEducationForm=JSON.parse(JSON.stringify(this.educationDetailsForm.value));
  this.educationInfos.push(  this.fb.group({
    id:new FormControl(null),
    active:new FormControl(null),
    createdBy:new FormControl(null),
    createdOn:new FormControl(null),
    modifiedBy:new FormControl(null),
    modifiedOn:new FormControl(null),
     "degree"  :new FormControl(null),
      "yearFrom": new FormControl(null),
      "yearTo": new FormControl(null),
      "location": new FormControl(null),
      "fieldOfStudy": new FormControl(null),
    }),)
    this.showEditForm=true;
}

editEducation(){
  this.copyEducationForm=JSON.parse(JSON.stringify(this.educationDetailsForm.value));
  this.showEditForm=true;
}

update(){
  this.updateEducationInfosValidations();
  this.educationInfos.markAllAsTouched();
  if(this.educationInfos.invalid){
    return;
  }
  this.onUpdate.emit(30);
}

refresh(){
  this.showEditForm=false;
  this.data=JSON.parse(JSON.stringify( this.educationDetailsForm.get('educationInfos')?.value));
  
}

cancel(){
    
  (this.educationDetailsForm.get('educationInfos')as FormArray).clear();
   if(this.copyEducationForm?.educationInfos && this.copyEducationForm.educationInfos.length>0){
        for(let i=0;i<this.copyEducationForm.educationInfos.length;i++){
          (this.educationDetailsForm.get('educationInfos')as FormArray).push( this.fb.group({
            id:new FormControl(null),
            active:new FormControl(null),
            createdBy:new FormControl(null),
            createdOn:new FormControl(null),
            modifiedBy:new FormControl(null),
            modifiedOn:new FormControl(null),
            "degree"  :new FormControl(null),
            "yearFrom": new FormControl(null),
            "yearTo": new FormControl(null),
            "location": new FormControl(null),
            "fieldOfStudy": new FormControl(null),
          }));
        }
        (this.educationDetailsForm.get('educationInfos')as FormArray).patchValue(this.copyEducationForm.educationInfos);
      }
     
  this.showEditForm=false;
  this.data=JSON.parse(JSON.stringify( this.educationDetailsForm.get('educationInfos')?.value));
 


}

updateEducationInfosValidations(){
  for(let i=0;i<this.educationInfos.controls.length;i++){
    if(!(this.educationInfos.at(i).get('degree')?.value==null || this.educationInfos.at(i).get('degree')?.value=='')
      || this.educationInfos.at(i).get('yearFrom')?.value!=null || !(this.educationInfos.at(i).get('location')?.value==null || this.educationInfos.at(i).get('location')?.value=='') ||
      this.educationInfos.at(i).get('yearTo')?.value!=null || !(this.educationInfos.at(i).get('fieldOfStudy')?.value==null || this.educationInfos.at(i).get('fieldOfStudy')?.value=='')  ){
        
      this.educationInfos.at(i).get('degree')?.setValidators([Validators.required]);
      this.educationInfos.at(i).get('yearFrom')?.setValidators([Validators.required]);
      this.educationInfos.at(i).get('yearTo')?.setValidators([Validators.required]);
      this.educationInfos.at(i).get('location')?.setValidators([Validators.required]);
      this.educationInfos.at(i).get('fieldOfStudy')?.setValidators([Validators.required]);
    }else{
      this.educationInfos.at(i).get('degree')?.clearValidators();
      this.educationInfos.at(i).get('yearFrom')?.clearValidators();
      this.educationInfos.at(i).get('yearTo')?.clearValidators();
      this.educationInfos.at(i).get('location')?.clearValidators();
      this.educationInfos.at(i).get('fieldOfStudy')?.clearValidators();
    }
    this.educationInfos.at(i).get('degree')?.updateValueAndValidity();
    this.educationInfos.at(i).get('yearFrom')?.updateValueAndValidity();
    this.educationInfos.at(i).get('yearTo')?.updateValueAndValidity();
    this.educationInfos.at(i).get('location')?.updateValueAndValidity();
    this.educationInfos.at(i).get('fieldOfStudy')?.updateValueAndValidity();
  }
}

deleteEducation(i:number){
this.educationInfos.removeAt(i);
}
}

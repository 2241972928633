<app-website-header></app-website-header>
<div class="header">
    <img src="../../../../../assets/images/webinars-website.svg" width="100%" height="auto" />
    <div class="header-name">
        <p>Webinars</p>
    </div>
</div>
<div style="background-color: #F5F5F5;" >

    <div class="upcoming p-5"> 
        <div class="data d-flex gap-2">
            <img src="./../../../assets/images/icons/calender-icon.svg">
            <h2 class="-webinars">Upcoming Webinars</h2>
        </div>
        <div class="webinar-block"> 
            <div class="row m-2" style="flex: 3"> 
                <div class="col-md-4" *ngFor="let webinar of upcomingWebinars.slice(0, 5)" style="max-height: 400px;">
                    <div class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D;" (click)="view(webinar.id)" >
                        <div class="card-img">
                            <img [src]="webinar.image" width="100%" height="130px">
                        </div>
                        <div class="card-heading elipses">
                            <p> {{ webinar.webinarTitle }} </p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="date-time"> {{ webinar.webinarDate }} {{ webinar.webinarTime }} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex ">
                                <img [src]="webinar.hostImage" width="100%" height="auto" style="max-width: 32px;" /> 
                                <div class="elipses">
                                    <p class="person-name elipses"> {{webinar.speakersName[0]}} <span *ngif="webinar.speakersName.length > 1" >...</span> </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="upcomingWebinars.length>=5 && this.viewMoreUpcomingToggle" (click)="viewMore('upcoming')"  >
                    <div class="d-flex card my-2 justify-content-center align-items-center view-more-box">
                        <img src="../../../../../assets/images/icons/viewMore-icon.svg" width="32px" height="32px" />
                        <p class="view-more"  >View More</p>
                    </div>
                </div>
                <div class="col-md-4" *ngFor="let webinar of upcomingWebinars.slice(6)" style="max-height: 400px;">
                    <div *ngIf="!viewMoreUpcomingToggle" class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D;" (click)="view(webinar.id)" >
                        <div class="card-img">
                            <img [src]="webinar.image" width="100%" height="130px">
                        </div>
                        <div class="card-heading elipses">
                            <p> {{ webinar.webinarTitle }} </p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="date-time"> {{ webinar.webinarDate }} {{ webinar.webinarTime }} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex ">
                                <img [src]="webinar.hostImage" width="100%" height="auto" style="max-width: 32px;" /> 
                                <div class="elipses">
                                    <p class="person-name elipses"> {{webinar.speakersName[0]}} <span *ngif="webinar.speakersName.length > 1" >...</span> </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters" style="flex: 1;">
                <div class="f-specialties">
                    <p class="card-heading " >Filter by Specialties</p>
                    <ul *ngFor="let specialty of specialties.slice(0, 3)" class="m-0 p-0"  >
                        <li>
                            <mat-checkbox (change)="specialtySelected($event, specialty, 'specialty')"> {{specialty}} </mat-checkbox>
                        </li> 
                    </ul>
                    <div *ngIf="specialityViewAll===1">
                        <ul *ngFor="let specialty of specialties.slice(3, specialties.length)" class="m-0 p-0"  >
                            <li>
                                <mat-checkbox (change)="specialtySelected($event, specialty, 'specialty')"> {{specialty}} </mat-checkbox>
                            </li> 
                        </ul>
                    </div><br>  
                    <div class="view-all-div d-flex flex-row gap-1 " *ngIf="specialityViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="specialtiesViewAll()" >view all ...</span>
                    </div>                
                </div><br>
                <div class="f-sponsors">
                    <p class="card-heading">Filter by Sponsors</p>
                    <ul *ngFor="let sponsor of sponsors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="sponsorSelected($event, sponsor, 'specialty')"> {{sponsor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="sponsorViewAll===1">
                        <ul *ngFor="let sponsor of sponsors.slice(0, sponsors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="sponsorSelected($event, sponsor, 'specialty')"> {{sponsor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="sponsorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="sponsorsViewAll()" >view all ...</span>
                    </div>
                </div><br>
                <div class="f-doctors">
                    <p class="card-heading">Filter by Doctors</p>
                    <ul *ngFor="let doctor of doctors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="doctorSelected($event, doctor, 'upcoming')"> {{doctor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="doctorViewAll===1">
                        <ul *ngFor="let doctor of doctors.slice(0, doctors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="doctorSelected($event, doctor, 'upcoming')"> {{doctor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="doctorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="doctorsViewAll()" >view all ...</span>
                    </div>
                </div><br>

            </div>
        </div>
    </div>

    <!-- <div class="Sponsored p-5"> 
        <div class="data d-flex gap-2">
            <img src="./../../../assets/images/icons/sponsored-icon.svg">
            <h2 class="-webinars">Sponsored Webinars</h2>
        </div>
        <div class="webinar-block">
            <div class="row m-2" style="flex: 3"> 
                <div class="col-md-4" *ngFor="let webinar of sponsoredWebinars" style="max-height: 400px;">
                    <div class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D;" (click)="view(webinar.id)" >
                        <div class="card-img">
                            <img src="../../../assets/images/website-webinar-card.svg" width="100%" height="130px">
                        </div>
                        <div class="card-heading">
                            <p> {{ webinar.webinarTitle }} </p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="date-time"> {{ webinar.webinarDate }} {{ webinar.webinarTime }} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex ">
                                <img src="../../../assets/images/webiste-webinar-card-person.svg">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="person-name" *ngFor="let doc of webinar.speakersName" > {{doc}} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex card my-2 justify-content-center align-items-center view-more-box">
                        <img src="../../../../../assets/images/icons/viewMore-icon.svg" width="32px" height="32px" />
                        <p class="view-more">View More</p>
                    </div>
                </div>
            </div>
            <div class="filters" style="flex: 1;">
                <div class="f-specialties">
                    <p class="card-heading " >Filter by Specialties</p>
                    <ul *ngFor="let specialty of specialties.slice(0, 3)" class="m-0 p-0"  >
                        <li>
                            <mat-checkbox (change)="specialtySelected($event, specialty, 'specialty')"> {{specialty}} </mat-checkbox>
                        </li> 
                    </ul>
                    <div *ngIf="specialityViewAll===1">
                        <ul *ngFor="let specialty of specialties.slice(3, specialties.length)" class="m-0 p-0"  >
                            <li>
                                <mat-checkbox (change)="specialtySelected($event, specialty, 'specialty')"> {{specialty}} </mat-checkbox>
                            </li> 
                        </ul>
                    </div><br>  
                    <div class="view-all-div d-flex flex-row gap-1 " *ngIf="specialityViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="specialtiesViewAll()" >view all ...</span>
                    </div>                
                </div><br>
                <div class="f-sponsors">
                    <p class="card-heading">Filter by Sponsors</p>
                    <ul *ngFor="let sponsor of sponsors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="sponsorSelected($event, sponsor, 'specialty')"> {{sponsor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="sponsorViewAll===1">
                        <ul *ngFor="let sponsor of sponsors.slice(0, sponsors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="sponsorSelected($event, sponsor, 'specialty')"> {{sponsor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="sponsorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="sponsorsViewAll()" >view all ...</span>
                    </div>
                </div><br>
                <div class="f-doctors">
                    <p class="card-heading">Filter by Doctors</p>
                    <ul *ngFor="let doctor of doctors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="doctorSelected($event, doctor)"> {{doctor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="doctorViewAll===1">
                        <ul *ngFor="let doctor of doctors.slice(0, doctors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="doctorSelected($event, doctor)"> {{doctor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="doctorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="doctorsViewAll()" >view all ...</span>
                    </div>
                </div><br>
            </div>
        </div>
    </div> -->

    <div class="Archived p-5"> 
        <div class="data d-flex gap-2">
            <img src="./../../../assets/images/icons/archived-icon.svg">
            <h2 class="-webinars">Archived Webinars</h2>
        </div>
        <div class="webinar-block">
            <div class="row m-2" style="flex: 3"> 
                <div class="col-md-4" *ngFor="let webinar of archivedWebinars.slice(0, 5)" style="max-height: 400px;">
                    <div class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D;" (click)="view(webinar.id)" >
                        <div class="card-img">
                            <img [src]="webinar.image" width="100%" height="130px">
                        </div>
                        <div class="card-heading elipses">
                            <p> {{ webinar.webinarTitle }} </p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="date-time"> {{ webinar.webinarDate }} {{ webinar.webinarTime }} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex ">
                                <img [src]="webinar.hostImage" width="100%" height="auto" style="max-width: 32px;" /> 
                                <div class="d-flex flex-column justify-content-around elipses">
                                    <p class="person-name"> {{webinar.speakersName}} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="archivedWebinars.length>=5 && this.viewMoreArchivedToggle" (click)="viewMore('archived')"  >
                    <div class="d-flex card my-2 justify-content-center align-items-center view-more-box">
                        <img src="../../../../../assets/images/icons/viewMore-icon.svg" width="32px" height="32px" />
                        <p class="view-more"  >View More</p>
                    </div>
                </div>
                <div class="col-md-4" *ngFor="let webinar of archivedWebinars.slice(6)" style="max-height: 400px;">
                    <div *ngIf="!viewMoreArchivedToggle" class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D;" (click)="view(webinar.id)" >
                        <div class="card-img">
                            <img [src]="webinar.image" width="100%" height="130px">
                        </div>
                        <div class="card-heading elipses">
                            <p> {{ webinar.webinarTitle }} </p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;">
                                <div class="d-flex flex-column justify-content-around">
                                    <p class="date-time"> {{ webinar.webinarDate }} {{ webinar.webinarTime }} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }} </p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex ">
                                <img [src]="webinar.hostImage" width="100%" height="auto" style="max-width: 32px;" /> 
                                <div class="d-flex flex-column justify-content-around elipses">
                                    <p class="person-name"> {{webinar.speakersName}} </p>
                                    <p class="specialization m-0"> {{ webinar.speciality }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filters" style="flex: 1;">
                <div class="f-specialties">
                    <p class="card-heading " >Filter by Specialties</p> 
                    <ul *ngFor="let specialty of specialties.slice(0, 3)" class="m-0 p-0"  >
                        <li>
                            <mat-checkbox (change)="specialtySelected($event, specialty, 'archived')"> {{specialty}} </mat-checkbox>
                        </li> 
                    </ul>
                    <div *ngIf="specialityViewAll===1">
                        <ul *ngFor="let specialty of specialties.slice(3, specialties.length)" class="m-0 p-0"  >
                            <li>
                                <mat-checkbox (change)="specialtySelected($event, specialty, 'archived')"> {{specialty}} </mat-checkbox>
                            </li> 
                        </ul>
                    </div><br>  
                    <div class="view-all-div d-flex flex-row gap-1 " *ngIf="specialityViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="specialtiesViewAll()" >view all ...</span>
                    </div>                
                </div><br>
                <div class="f-sponsors">
                    <p class="card-heading">Filter by Sponsors</p>
                    <ul *ngFor="let sponsor of sponsors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="sponsorSelected($event, sponsor, 'archived')"> {{sponsor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="sponsorViewAll===1">
                        <ul *ngFor="let sponsor of sponsors.slice(0, sponsors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="sponsorSelected($event, sponsor, 'archived')"> {{sponsor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="sponsorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="sponsorsViewAll()" >view all ...</span>
                    </div>
                </div><br>
                <div class="f-doctors">
                    <p class="card-heading">Filter by Doctors</p>
                    <ul *ngFor="let doctor of doctors.slice(0, 3)" class="m-0 p-0">
                       <li>
                            <mat-checkbox (change)="doctorSelected($event, doctor, 'archived')"> {{doctor}} </mat-checkbox>
                       </li> 
                    </ul>
                    <div *ngIf="doctorViewAll===1">
                        <ul *ngFor="let doctor of doctors.slice(0, doctors.length)" class="m-0 p-0">
                            <li>
                                 <mat-checkbox (change)="doctorSelected($event, doctor, 'archived')"> {{doctor}} </mat-checkbox>
                            </li> 
                         </ul>
                    </div>
                    <div class="view-all-div d-flex flex-row gap-1  " *ngIf="doctorViewAll===0">
                        <img src="../../../assets/images/icons/view-all-arrow-icon.svg" class="d-flex align-items-center justify-content-center" />
                        <span class="view-all d-flex align-items-center justify-content-center " (click)="doctorsViewAll()" >view all ...</span>
                    </div>
                </div><br>

            </div>
        </div>
    </div>
</div>
<app-website-footer></app-website-footer>
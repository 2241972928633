// camelcase.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase'
})
export class CamelcasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    const words = value.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    } else {
      return words.map((word, index) => 
        index === 0 
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }
  }
}

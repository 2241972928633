import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup,FormBuilder, Validators, FormArray, } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Enquiry } from 'src/app/models/messaging-models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MessageService } from 'src/app/services/shared/message.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ProductsService } from 'src/app/services/products.service';
import { I } from '@angular/cdk/keycodes';
import { ChangeDetectorRef } from '@angular/core';
import { SidebarServiceService } from 'src/app/services/sidebar-service.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {
  @Input() messages: any[] = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() enquiryTabVisible: boolean = true

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '3rem',
    minHeight: '0rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    showToolbar: false,
  };

  messageObj: any;
  AllChats: { [key: string]: any } = {};
  chats: { [key: string]: any } = {};
  chatsMessage!: { [key: string]: any[] };
  chatMessages: any[] = [];
  allMessages: any[] = [];
  chatForm!: FormGroup;
  senderCheck: any;
  userName: any;
  isOem: boolean = false;
  userId: any;
  receiver1Id: any;
  receiver2Id: any;
  private messageSubscription!: Subscription;
  subscription: any;
  oemPersona: boolean = true;
  href: string = '';
  selectedChatItem!: string;
  uploadedFileUrl: string | null = null;
  uploadedFileName: string | null = null;
  companyDetailsMap: { [key: string]: any } = {};
  chatWith: string = '';
  prodId: string = '';
  productDetails: any;
  productImage: any;
  sendButton: boolean = false;
  activeTab: number = 0;
  previousTab: number = 0;
  showAdd: boolean = false;
  selectedProfile: any = null;
  sidebarVisible = false;
  filteredChats: any[] = [];
  groups: any[] = [];
  selecteChat: any;
  tab:number=0;
  scheduleVisible: boolean = false;
  scheduleForm!: FormGroup;
  attendeeAdded: boolean = true;
  attendeeGroup: any;
  newEmail: FormControl = new FormControl();
  newName: FormControl = new FormControl();
  scheduleAttendees: any[] = [];
  attendeeGroups: string[] = [];
  attachedFiles: string[] = [];
  attachedFileUrls: string[] = [];

  constructor(private localStorageService: LocalStorageService,
    private websocketService: WebsocketService,
    private messageService: MessageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private endCustomerService: EndCustomerService,
    private sidebarService: SidebarServiceService,
    private sweetAlertService: SweetAlertService,
    private spinnerService: SpinnerService,
    private oemService: OemService) {
    this.chatForm = new FormGroup({
      replymessage: new FormControl()
    });

    const state = window.history.state
    if(state?.enquiryTerm === 'Product Enquiry'){
      this.selectEnquiryType({value: 'PRODUCT'});
    }else if (state?.enquiryTerm === 'Distributor Enquiry'){
      this.selectEnquiryType({value: 'DISTRIBUTOR'});
    }
    
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    this.receiver1Id = this.route.snapshot.params['id'];
    this.href = this.router.url;
    if (this.href?.split('/')?.indexOf('user') === 1) {
      this.oemPersona = false;
    }
    const roleName = localStorage.getItem('roleName') ?? '';
    this.userId = (roleName === 'USER' ? localStorage.getItem('userId') :
      roleName === 'OEM' ? localStorage.getItem('oemId') :
        roleName === 'DISTRIBUTOR' ? localStorage.getItem('distributorId') : '') ?? '';
    
    this.isOem = roleName === 'OEM' ? true : false;

    this.websocketService.joinChat(this.userId);
    this.websocketService.newMessageReceived.subscribe((msg: any) => {

      if (this.selecteChat.id === msg.senderId || this.selecteChat.groupName === msg.groupName) {
        this.chatMessages.push(msg);
      }

    });
    this.sidebarService.sidebarVisibility$.subscribe(
      (isVisible: boolean) => {
        this.sidebarVisible = isVisible;
      }
    );

    const navigation = this.router.getCurrentNavigation();
    
    if (navigation?.extras?.state) {
      this.chatWith = navigation.extras.state['fromId'];
      this.prodId = navigation.extras.state['prodId'];
    } else {
      const state = window.history.state;
      this.chatWith = state['fromId'];
      this.prodId = state['prodId'];
      
      if(this.prodId){
        this.getProductdetails(this.prodId)
      }
    }

    this.getChatsByUser(this.userId);
    if (this.chatWith) {
      
      this.goToChatById(this.chatWith)
    //   this.selectedChatItem = this.chatWith
    //   this.getMessagesByGroup(this.chatWith)
    //   this.fetchCompanyDetails(this.chatWith)
    }
    this.selectTab(0)
  }

  selectEnquiryType(e: any){
    
    this.enquiryType = e.value;
    // this.getChatIds(this.enquiryType);
    
  }

  initializeForm() {
    this.scheduleForm = this.fb.group({
      subject: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      attendees: this.fb.array([])
    });
  }
  
  addAttendee() {
    if (this.newEmail.valid && this.newName.valid) {
      const attendeeGroup = this.createAttendee();
      attendeeGroup.patchValue({ email: this.newEmail.value, name: this.newName.value });
      this.attendees.push(attendeeGroup);
      this.clearInputFields();
    } else {
      this.sweetAlertService.showError('Error', 'Please enter valid email and name');
    }
  }

  add() {
    this.attendeeAdded = false;
    this.showAdd=false;    
  }

  clear() {
    this.clearInputFields();
    this.attendeeAdded = true; 
  }
  
  clearInputFields() {
    this.newEmail.reset();
    this.newName.reset();
  }
  
  toggleSidebar() {
    this.scheduleVisible = !this.scheduleVisible;
    this.newEmail = new FormControl('',Validators.required);
    this.newName = new FormControl('', Validators.required);
  }
  
  removeAttendee(index: number) {
    const attendeesArray = this.attendees;
    if (index > -1 && index < attendeesArray.length) {
        attendeesArray.removeAt(index);
    }
  }
  
  get attendees(): FormArray {
    return this.scheduleForm.get('attendees') as FormArray;
  }
  
  createAttendee(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required]
    });
  }

  openProfile(senderId: string) {
    // Perform any additional logic if needed
    this.selectedProfile = this.companyDetailsMap[senderId];
    if(this.isOem){
      this.sidebarService.toggleSidebar(true);
    this.scheduleVisible = false;
    this.attendeeAdded = true;
      
    }
    
  }

  closeSidebar() {
    this.sidebarService.toggleSidebar(false);
  }

  closescheduleSidebar() {
    this.scheduleVisible = false;
    this.attendeeAdded = true;
  }

  fetchCompanyDetails(senderId: string): Promise<void> {
    return this.messageService.getUserNameByUserId(senderId)
      .pipe(
        catchError(err => {
          
          return of({ statusCode: '500', message: 'Error retrieving data', data: null });
        })
      )
      .toPromise()
      .then(response => {
        if (response.statusCode === '200 OK' && response.data) {
          this.companyDetailsMap[senderId] = response.data;

        } else {
          
        }
      });
  }

  sendMessage(): void {

    if(this.attachedFileUrls.length>0){
      this.attachedFileUrls.forEach((url: string,i: number)=>{
        const reqObj: any = {
          groupName: this.selecteChat.groupName,
          senderId: this.userId,
          receiverId: this.selecteChat.group ? this.selecteChat.id : this.selecteChat.receiverId,
          message: this.chatForm.value.replymessage,
          createdOn: new Date(),
          isGroup: this.selecteChat.group
        };
        
        const fileExtension = url?.split('.').pop()?.toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'jfif'];
        if (imageExtensions.includes(fileExtension || '')) {
          reqObj.message = `<img src="${url}" alt="${this.attachedFiles[i]}"  />`;
        } else {
          reqObj.message = `<a href="${url}" target="_blank">${this.attachedFiles[i]}</a>`;
        }
        this.chatForm.patchValue({ 'replymessage': null });
        // this.allMessages.push(reqObj);
        this.chatMessages.push(reqObj);
        this.websocketService.sendPrivateMessage(reqObj);
    })
    }else{
      const reqObj: any = {
        groupName: this.selecteChat.groupName,
        senderId: this.userId,
        receiverId: this.selecteChat.group ? this.selecteChat.id : this.selecteChat.receiverId,
        message: this.chatForm.value.replymessage,
        createdOn: new Date(),
        isGroup: this.selecteChat.group
      };
      
      this.chatForm.patchValue({ 'replymessage': null });
      this.allMessages.push(reqObj);
      this.chatMessages.push(reqObj);
      this.websocketService.sendPrivateMessage(reqObj);
    }
    this.attachedFileUrls = []
    this.attachedFiles = []
  }


  goToChatById(id: string): void {
    this.initializeForm();
    
    const chat = this.chats[id];
   

    this.selectedChatItem = id;

    this.selecteChat = this.chats[id];
    if (chat.group && chat.groupMembers) {
      // Clear existing attendees first
      this.clearAttendees();
  
      // Fetch details of each group member and add them to attendees
      chat.groupMembers.forEach((memberId: string) => {
        this.fetchCompanyDetails(memberId).then(() => {
          const attendeeData = this.companyDetailsMap[memberId];
          if (attendeeData) {
            this.addGroupAttendee(attendeeData.email, attendeeData.name);
          }
        });
      });
    }
    if (chat.group) {
      this.getMessagesByGroup(id);
    } else {

      this.getMessagesByNonGroup(this.userId, id);  
    }
    this.sidebarVisible = false;
    this.scheduleVisible = false;
  }

  addGroupAttendee(email: string, name: string) {
    const attendeeGroup = this.createAttendee();
    attendeeGroup.patchValue({ email, name });
    this.attendeeGroups.push(attendeeGroup.value.email);
  
    // Disable the attendee form group and hide the remove button for group members
    
  
    this.attendees.push(attendeeGroup);
    
  }
  
  clearAttendees() {
    while (this.attendees.length !== 0) {
      this.attendees.removeAt(0);
    }
  }

  getMessagesByNonGroup(userId1: string, userId2: string): void {
    this.messageService.getMessagesByNonGroup(userId1, userId2).subscribe(data => {

      this.chatMessages = data.data;
      const uniqueSenderIds = new Set<string>();
      this.chatMessages.forEach(message => {
        uniqueSenderIds.add(message.senderId);
      });

      const fetchDetailsPromises = Array.from(uniqueSenderIds).map(senderId => this.fetchCompanyDetails(senderId));
      Promise.all(fetchDetailsPromises).then(() => {

      });
    });
  }

  enquiryType: string = 'All';
  getChatIds(type: string): string[] {
    

    if (type === 'All' || this.enquiryType === 'All') {
      return Object.keys(this.chats);
    }

    return Object.keys(this.chats).filter(id => this.chats[id]?.enquiryType === type);
  }

  getRandomColor(seed: string): string {
    let hash = 0;
    for (let i = 0; i < seed.length; i++) {
      hash = seed.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.floor((Math.abs(Math.sin(hash) * 16777115)) % 16777115).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  removeUploadedFile(i: number): void {
    this.attachedFiles = this.attachedFiles.filter((data: any, id:number)=>i!==id)
    this.attachedFileUrls = this.attachedFileUrls.filter((data: any, id:number)=>i!==id);
    this.uploadedFileUrl = null;
    this.uploadedFileName = null;
    this.chatForm.patchValue({ 'replymessage': null });
  }

  uploadPhoto(event: any): void {
    const input = event.target as HTMLInputElement;
    const file: File = event.target.files[0];
    if (file) {
      this.uploadedFileName = file.name;
      this.attachedFiles.push(file.name) ; 
      const uploadData = new FormData();
      uploadData.append('file', file);
      const randomNumber = Math.floor(Math.random() * 10) + 1;
      uploadData.append('destinationKey', randomNumber.toString());

      this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile, uploadData)
        .subscribe(data => {
          if (data && data?.data) {
            this.uploadedFileUrl = data.data;
            this.attachedFileUrls.push(data.data)
            input.value = '';
          }
        }, error => {
          
        });
    }
  }


  filterByGroupName(event: any): void {
    const searchTerm = event.target.value.toLowerCase();
    
    this.chats = Object.values(this.AllChats).filter(chat => chat.groupName.toLowerCase().includes(searchTerm));
  }

  getGroupsByUser(userId: string): void {
    this.messageService.getGroupsByUser(userId).subscribe(data => {
      data.data.forEach((chat: any) => {
        this.chats[chat.id] = { ...chat, isGroup: true };
        
        
      });
      const lastGroupId = Object.keys(this.chats).filter(id => this.chats[id].isGroup).pop();
      if (lastGroupId) {
        this.getMessagesByGroup(lastGroupId);
      }
    });

  }

  getNotGroupsByUser(userId: string): void {
    this.messageService.getConversationWithUser(userId)
      .subscribe(data => {
        data.data.forEach((id: string) => {
          this.chats[id] = { id, isGroup: false };

        });
      }, error => {
        
      });
  }

  getProductdetails(id: string | null) {
    if (id === null) {
      return;
    }
    this.productService.getProductById(id).subscribe((res: any) => {
      this.productDetails = res.data;
      
      const images = res.data.productInfoDTO?.images;
      if (images && images.length) {
        this.productImage = images[0];
      }
      

    });
  }

  getMessagesByGroup(groupId: string): void {
    this.selecteChat = this.chats[groupId];
    this.selectedChatItem = groupId;
    this.messageService.getMessagesByGroup(groupId).subscribe(data => {

      this.chatMessages = data.data;
      const uniqueSenderIds = new Set<string>();
      this.chatMessages.forEach(message => {
        uniqueSenderIds.add(message.senderId);
      });

      const fetchDetailsPromises = Array.from(uniqueSenderIds).map(senderId => this.fetchCompanyDetails(senderId));
      Promise.all(fetchDetailsPromises).then(() => {

      });
    });
  }

  viewProduct() {
    

  }
  getChatsByUser(userId: string): void {
   
    
    this.messageService.getConversationsById(userId).subscribe((data: any) => {
        this.chats = {};
        data.data.forEach((chat: any) => {
            this.AllChats[chat.id] = chat;
             this.chats[chat.id] = chat;
            if (!chat.group) this.fetchCompanyDetails(chat.id);
        });
        if (this.chatWith) {
         
          if(this.prodId){
            this.chatForm.patchValue({
              'replymessage': `<img src="${this.productImage}" alt="${this.productDetails.productInfoDTO.name}" /> <br /> ${this.productDetails.productInfoDTO.name}`
            });
          }
            this.getMessagesByGroup(this.chatWith);
        }
    });
}

selectTab(index: number): void {
  if (index !== this.activeTab) {
    this.previousTab = this.activeTab;
    this.activeTab = index;

    
    
    
    this.chats = Object.values(this.AllChats).reduce((acc: any, chat: any) => {
        acc[chat.id] = chat;
      return acc;
    }, {});
   
    
  }
}


 
  mediaTab(){
    this.tab = 0;
  }
  documentsTab(){
    this.tab = 1;
  }

  scheduleMeeting(){
    this.scheduleVisible = true;
  }

  save() {
    
    this.spinnerService.showSpinner();

    if (this.scheduleForm.invalid) {
      const invalidFields = Object.keys(this.scheduleForm.controls)
        .filter(control => this.scheduleForm.get(control)?.invalid)
        .map(control => control.charAt(0).toUpperCase() + control.slice(1));

      this.spinnerService.hideSpinner();
      if (invalidFields.length > 1) {
        this.sweetAlertService.showError('Error', 'Please fill all the fields');
      } else if (invalidFields.length === 1) {
        this.sweetAlertService.showError('Error', `Please fill the field: ${invalidFields[0]}`);
      }
      return;
    }
    const formValue = this.scheduleForm.value;
    const startTime = new Date(formValue.startTime).toISOString();
    const endTime = new Date(formValue.endTime).toISOString();
  
    const formattedFormValue = {
      ...formValue,
      startTime: startTime,
      endTime: endTime
    };
  
    
    this.oemService.createMeeting(formattedFormValue).subscribe((res: any) => {
      
      this.spinnerService.hideSpinner();
      this.sweetAlertService.showSuccess('Success', 'Meeting scheduled successfully');
    }, error => {
      
      this.spinnerService.hideSpinner();
      this.sweetAlertService.showError('Error', 'Failed to schedule meeting');
    });
  }
  
  
}
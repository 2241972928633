import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.scss']
})
export class CardProfileComponent {
  @Input () data: any;
  @Input () image: any;
  @Input ( ) isEducationCard: boolean = false;
  @Input ( ) isWorkCard: boolean = false;
  @Input ( ) keys: any;
  constructor() {
    
    
   }

   ngOnInit(): void {
  
   }

}

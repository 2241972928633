
<div class="brand-header">
  <ng-container *ngIf="data.logo">
    <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
  </ng-container>
  <div class="brand-details">
    <h1>{{ data.companyName | camelcase}}</h1>
    <ng-container *ngIf="data.url">
      <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
    </ng-container>
  </div>
</div>
<div class="total-results">
  <strong>Total Results {{ products.length }} </strong>
</div>
  <div class="parent-container">
    <div class="filter">
      <app-brand-product-filter [filterColumns]="['Products', 'Region', 'Application', 'Size', 'Shape', 'Model', 'SampleType']" [data]="originalData" (filteredData)="onFilteredData($event)"></app-brand-product-filter>
    </div>
    <div class="product-list">
      <ng-container *ngIf="products.length===0">
        <div class="no-records-found"> No records Found </div>
      </ng-container>
      <div class="product-card" *ngFor="let product of paginatedItems">
        <img [src]="product.images[0]" alt="{{ product.name }}">
        <h4>{{ (product.name.length > 18 ? product.name.slice(0,18) + '...' : product.name) | titlecase}}</h4>
        <h1>{{ (product.oemName.length > 18 ? product.oemName.slice(0,18) + '...' : product.oemName) | uppercase}}</h1>
        <p [innerHtml]="product.description && product.description.slice(0,130) + (product.description.length > 130 ? '...' : '')"></p>
        <ul *ngFor="let feature of product.tags; let i = index">
          <li  *ngIf="i < 2"><img src="../../../assets/images/fi_711239.svg" alt="" >{{ feature | titlecase}}</li>
        </ul>
        <button (click)="onViewDetails(product)">View Details</button>
      </div>
    </div>
  </div>
  <div class="pagination-container">
    <app-pagination [itemsPerPage]="itemsPerPage" [data]="products" (pageChanged)="onPageChanged($event)"></app-pagination>
  </div>


  
      
      
      
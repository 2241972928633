import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalToReadable'
})
export class TitlecasePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    // Insert space before each uppercase letter except the first one
    const result = value.replace(/([A-Z])/g, ' $1').trim();

    // Capitalize the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

}

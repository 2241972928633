import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BranchService {
    ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
    constructor(private http: HttpClient) { }
    deleteBranchById(id: string): Observable<any> {
        return this.http.delete<any>(`${this.ONBOARDING_BASE_URL}/branch/${id}`);
    }
}
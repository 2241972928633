import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'salary'
})
export class SalaryPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    let transformedValue = value;
    if (value >= 1000 && value < 100000) {
      transformedValue = (value / 1000) + 'K';
    } else if (value >= 100000 && value < 10000000) {
      transformedValue = (value / 100000) + 'L';
    } else if (value >= 10000000) {
      transformedValue = (value / 10000000) + 'C';
    }
    return transformedValue !== undefined && transformedValue !== null ? transformedValue.toString() : '';
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-specialitiess',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.scss']
})
export class SpecialitiesComponent implements OnInit{

  @Input() specialityForm!:FormGroup;
  specialisations:any=[];
 

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();
 

  showEditForm:boolean=false

  copySpecilitySetForm:any;

  specialityData:any=[];

  constructor(private endCustomerService:EndCustomerService) {}

  ngOnInit(): void {
    this.getSpecialities();
    
  }

  getSpecialities(){
    this.endCustomerService.executeGetRequestURL(MweConstants.specialtyUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.specialisations=data.data;
        if( this.specialityForm.get('specialityInfos')?.value &&this.specialityForm.get('specialityInfos')?.value.length>0){
          this.getSpecialityData();
        }
       
      }
    },error=>{

    })
  }

  update(){
    

    this.onUpdate.emit(10);
    this.refresh();

  }

  cancel(){
    this.showEditForm=false;
  
       this.specialityForm.get('specialityInfos')?.patchValue([]); 
       
  if(this.copySpecilitySetForm?.specialityInfos && this.copySpecilitySetForm?.specialityInfos.length>0){
    this.specialityForm.get('specialityInfos')?.patchValue(this.copySpecilitySetForm?.specialityInfos); 
  
   
  }
  this.getSpecialityData(); 
   
  
  }

  editSpeciality(){
    this.copySpecilitySetForm=JSON.parse(JSON.stringify(this.specialityForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    this.getSpecialityData();
  }

  getSpecialityData(){
    
    const speciality=this.specialisations;
    
    let specialityInfos:any=[];
    
    if(this.endCustomerData?.specialityInfos && this.endCustomerData.specialityInfos.length>0){
      specialityInfos=speciality.filter((obj1:any)=>{
        return this.endCustomerData.specialityInfos.some((obj2:any)=>{
          return obj1.id===obj2.specialityInfoId;
        })
      })
    }
   
  
    this.specialityData=specialityInfos;
  }
 
  remove(i:number): void {
    this.specialityForm.get('specialityInfos')?.value.splice(i,1);
    this.specialityForm.get('specialityInfos')?.patchValue(this.specialityForm.get('specialityInfos')?.value);
}

getSpecility(id:any){
  return this.specialisations.find((obj:any)=>obj?.id===id)?.name;
 }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { concatMap, takeWhile } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { JobPreviewComponent } from '../job-preview/job-preview.component';

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.scss']
})
export class PostJobComponent implements OnInit {

  @Output() done = new EventEmitter<string>();
  @Input() from: string = '';

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

  @ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;

  hideCompanyDetails = false;

  isFileInputClickedCompanyLogo: boolean = false;
  previewImage: any = null;
  countryDropdown: any[] = [];
  stateDropdown: any[] = [];
  cityDropdown: any[] = [];
  continents!: any[];
  countries!: any[];
  states!: any[];
  cities!: any[];
  jobCategories!: any[];
  specialities!: any[];
  jobSkills!: any[];
  jobTypes!: any[];
  careerLevels!: any[];
  degreeLevels!: any[];
  jobTagOptions!: any[];

  jobTagOptionsLength: number = 0;
  
  salaryRanges = ["Monthly", "Annually", "Bi Weekly", "Weekly", "Hourly"];

  isUpdateJob = false;
  currentJobPost: any;

  postJobForm = new FormGroup({
    title: new FormControl('', Validators.required),
    image: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    speciality: new FormControl('', Validators.required),
    skills: new FormControl([], Validators.required),
    jobType: new FormControl('', Validators.required),
    careerLevel: new FormControl('', Validators.required),
    experience: new FormControl('', Validators.required),
    degreeLevel: new FormControl('', Validators.required),
    tags: new FormControl([], Validators.required),
    description: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    postedBy: new FormControl('', Validators.required),
    postedEmail: new FormControl('', Validators.required),
    totalPositions: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    salaryCurrency: new FormControl('', Validators.required),
    salaryRange: new FormControl('', Validators.required),
    salaryFrom: new FormControl('', Validators.required),
    salaryTo: new FormControl('', Validators.required),
    applicationStartDate: new FormControl('', Validators.required),
    applicationCloseDate: new FormControl('', Validators.required),
    hideSalary: new FormControl(false, Validators.required),
    postAnonymously: new FormControl(false, Validators.required),
  });


  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '0rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
onCitySelected: any;

  constructor(private readonly masterDataService: MasterDataService, private fileUploadService: FileUploadService,
    private careerService: CareerService, private readonly snackbarService: SnackbarService,
    private readonly router: Router, private route: ActivatedRoute,private dialog: MatDialog) {

  }



  ngOnInit(): void {
    this.loadMasterData();
    this.onUpdateJobPost();
    this.getCountries(null);
    this.postJobForm.get('country')?.valueChanges.subscribe((data: any) => {
      if (data) {
        this.getCountries({ startsWith: data });
      }
      else {
        this.getCountries(null);
      }
    });

    console.log('from id id ', this.from);
    if(this.from){
      this.isUpdateJob = true;
    }
    

    

    this.postJobForm.get('state')?.valueChanges.subscribe((data: any) => {
      if (this.postJobForm.get('country')) {
        if (data) {
          this.getStatesByCountryName({ countryName: this.postJobForm.get('country')?.value, startsWith: data });
        } else {
          this.getStatesByCountryName({ countryName: this.postJobForm.get('country')?.value });
        }
      }
    });
    this.postJobForm.get('city')?.valueChanges.subscribe((data: any) => {
      if (this.postJobForm.get('state')?.value) {
        if (data) {
          this.getCityiesByStateName({ stateName: this.postJobForm.get('state')?.value, startsWith: data });
        } else {
          this.getCityiesByStateName({ stateName: this.postJobForm.get('state')?.value });
        }
      }
      else {
        if (data) {
          this.getCityiesByStateName({ startsWith: data });
        }
        else {
          this.getCityiesByStateName(null);
        }
      }
    });
    
  }
 
  onUpdateJobPost() {
    if(this.from !== '') {
      console.log('updating .......');
      
      this.getJobPostById(this.from);
      return
      
    }
    const jobPostId = this.router.url.split('?')[0].split('/').pop();
    if (this.router.url.includes('update-job') && jobPostId !== undefined && jobPostId !== null) {
      this.isUpdateJob = true;
      this.getJobPostById(jobPostId);
    }
  }
  removeTag(index: number): void {
    const tags = this.postJobForm.get('tags') as FormControl;
    const currentTags = [...tags.value];
    currentTags.splice(index, 1);
    tags.setValue(currentTags);
  }
  

  private getJobPostById(jobPostId: any) {
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat('/', jobPostId), null).subscribe({
      next: (response) => {
        if (response && response?.data) {
          const data = response?.data;
          this.currentJobPost = data;
          const formData: any = this.mapJobFormFromRequest(data);
          this.postJobForm.setValue(formData);
          this.isFileInputClickedCompanyLogo = true;
          this.previewImage = data.image;
        }
      }, error: (error) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files = inputElement.files;
    if (files) {
      this.isFileInputClickedCompanyLogo = true;
      this.handleFiles(files, 'image', inputElement);
    }
  }

  onDragOver($event: DragEvent){
    $event.preventDefault();
  }

  onDragLeave($event: DragEvent){
    $event.preventDefault();
  }

  onDrop($event: DragEvent, inputElement: HTMLInputElement){
    $event.preventDefault();
    const files = $event.dataTransfer?.files;
    if(files){
      this.handleFiles(files, 'image', inputElement)
    }
  }

  handleFiles(files: FileList, formControlName: string, inputElement: HTMLInputElement) {
    this.fileUploadService.uploadFilesAndGetUrls(Array.from(files), 'destinationKey').subscribe({
      next: (urls: any) => {
        this.showFile(files[0]);
        this.postJobForm.get(formControlName)?.setValue(urls[0]);
        // Clear the input element value to allow the same file to be selected again
        inputElement.value = '';
      }, error: (error: any) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  showFile(file: any) {
    this.isFileInputClickedCompanyLogo = true;
    // Read and display the preview image
    const reader = new FileReader();
    reader.readAsDataURL(file); // Read only the first file
    reader.onload = () => {
      // Set the previewImage variable to the data URL
      this.previewImage = reader.result as string;
    };
  }

  removeLogo(event: any) {
    if (event !== undefined && event !== null) {
      event.stopPropagation();
    }
    this.previewImage = null;
    this.postJobForm.get('image')?.setValue(null);
    this.isFileInputClickedCompanyLogo = false;
  }
  

  private loadMasterData() {
    this.careerService.setCareersBreadCrumbs(['Dashboard', 'Post a Job']);
    this.loadCountries('');
    this.loadJobCategories();
    this.loadSpecialities();
    this.loadJobSkills();
    this.loadJobTypes();
    this.loadCareerLevels();
    this.loadDegreeLevels();
    this.loadJobTags();
  }

  getSalaryValue(formControlName: string) {
    const salary = this.postJobForm.get(formControlName)?.value;

    if (salary !== undefined && salary !== null) {
      return salary;
    } else {
      return null;
    }

  }

  private loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  loadJobCategories() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobCategories: any[] = [];
    let morePages = true;

    this.masterDataService.getJobCategories(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobCategories = allJobCategories.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobCategories(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobCategories = allJobCategories.concat(data.data);
        this.jobCategories = allJobCategories;
      });
  }

  loadSpecialities() {
    let pageNumber = 0;
    let pageSize = 100;
    let allSpecialities: any[] = [];
    let morePages = true;

    this.masterDataService.getSpecialities(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allSpecialities = allSpecialities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getSpecialities(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allSpecialities = allSpecialities.concat(data.data);
        this.specialities = allSpecialities;
      });
  }

  loadJobSkills() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobSkills: any[] = [];
    let morePages = true;

    this.masterDataService.getJobSkills(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobSkills = allJobSkills.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobSkills(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobSkills = allJobSkills.concat(data.data);
        this.jobSkills = allJobSkills;
      });
  }

  loadCareerLevels() {
    let pageNumber = 0;
    let pageSize = 100;
    let allCarrerLevels: any[] = [];
    let morePages = true;

    this.masterDataService.getCareerLevels(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCarrerLevels = allCarrerLevels.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCareerLevels(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allCarrerLevels = allCarrerLevels.concat(data.data);
        this.careerLevels = allCarrerLevels;
      });
  }

  loadDegreeLevels() {
    let pageNumber = 0;
    let pageSize = 100;
    let allDegreeLevels: any[] = [];
    let morePages = true;

    this.masterDataService.getDegreeLevels(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allDegreeLevels = allDegreeLevels.concat(response.data);
          pageNumber++;
          return this.masterDataService.getDegreeLevels(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allDegreeLevels = allDegreeLevels.concat(data.data);
        this.degreeLevels = allDegreeLevels;
      });
  }

  loadJobTypes() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobTypes: any[] = [];
    let morePages = true;

    this.masterDataService.getJobTypes(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobTypes = allJobTypes.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobTypes(pageNumber, pageSize, '');
        })
      )
      .subscribe((data: any) => {
        allJobTypes = allJobTypes.concat(data.data);
        this.jobTypes = allJobTypes;
      });
  }

  loadJobTags() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobTags: any[] = [];
    let morePages = true;

    this.masterDataService.getJobTags(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobTags = allJobTags.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobTags(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobTags = allJobTags.concat(data.data);
        this.jobTagOptions = allJobTags;
        this.jobTagOptionsLength = this.jobTagOptions?.length;
      });
  }


  onSubmit() {

    if (this.postJobForm.valid) {

      const body = this.postJobForm.value;

      if (body.skills != undefined && body.skills.length > 0 && Array.isArray(body.skills)) {
        const skills: any = body.skills?.join(',');
        body.skills = skills;
      }

      if (body.tags != undefined && body.tags.length > 0 && Array.isArray(body.tags)) {
        const tags: any = body.tags?.join(',');
        body.tags = tags;
      }

      const userIdByRole = this.careerService.getUserIdBasedOnRole();

      if (this.isUpdateJob) {
        const jobPost: any = body;
        jobPost.id = this.currentJobPost.id;
        jobPost.jobPostId = this.currentJobPost.jobPostId;
        jobPost.active = this.currentJobPost.active;
        jobPost.createdOn = new Date(this.currentJobPost.createdOn);
        jobPost.createdBy = this.currentJobPost.createdBy;
        this.careerService.executePutRequest(MweConstants.updateJobPostUrl + this.currentJobPost.id, body, userIdByRole).subscribe({
          next: (data: any) => {
            Swal.fire('', 'Job Post updated successfully !!', 'success');
            this.onCancel();
          }, error: (error: any) => {
            this.snackbarService.openSnackBar("An issue occured while updating Job post. Please try again later.", '');
          }
        });
      } else {
        this.careerService.executePostRequest(MweConstants.saveJobPostUrl, body, userIdByRole).subscribe({
          next: (data: any) => {
            Swal.fire('', 'Job Post added successfully !!', 'success');
            this.onCancel();
          }, error: (error: any) => {
            this.snackbarService.openSnackBar("An issue occured while creating Job post. Please try again later", '');
          }
        });
      }
    }

  }

  onCancel() {
    this.removeLogo(null);
    this.postJobForm.reset();
    if(this.from !== ''){
      this.done.emit('cancel');
      return;
    }
    this.router.navigate([this.isUpdateJob ? '../..' : '../'], { relativeTo: this.route });
  }

  mapJobFormFromRequest(jobPost: any) {
    return {
      title: jobPost.title,
      image: jobPost.image,
      category: jobPost.category,
      speciality: jobPost.speciality,
      skills: this.convertStringToArray(jobPost.skills),
      jobType: jobPost.jobType,
      careerLevel: jobPost.careerLevel,
      experience: jobPost.experience,
      degreeLevel: jobPost.degreeLevel,
      tags: this.convertStringToArray(jobPost.tags),
      description: jobPost.description,
      content: jobPost.content,
      company: jobPost.company,
      postedBy: jobPost.postedBy,
      postedEmail: jobPost.postedEmail,
      totalPositions: jobPost.totalPositions,
      address: jobPost.address,
      city: jobPost.city,
      state: jobPost.state,
      country: jobPost.country,
      salaryCurrency: jobPost.salaryCurrency,
      salaryRange: jobPost.salaryRange,
      salaryFrom: jobPost.salaryFrom,
      salaryTo: jobPost.salaryTo,
      applicationStartDate: this.convertToDate(jobPost.applicationStartDate),
      applicationCloseDate: this.convertToDate(jobPost.applicationCloseDate),
      hideSalary: jobPost.hideSalary,
      postAnonymously: jobPost.postAnonymously
    }
  }

  private convertStringToArray(field: any) {
    return (field !== undefined && field !== null && field.length > 0) ? field.split(",") : null;
  }

  private convertToDate(field: any) {
    return (field !== undefined && field !== null) ? new Date(field) : null;
  }

  onCountrySelected(event: any) {
    this.getStatesByCountryName({ countryName: this.postJobForm.get('country')?.value });
  }

  onStateSelected(event: any) {
    this.getCityiesByStateName({ stateName: this.postJobForm.get('state')?.value });
  }

  getCountries(params: any) {
    this.executeGetRequestURL(MweConstants.countryURL, params, 'countryDropdown');
  }
  getStatesByCountryName(params: any) {
    this.executeGetRequestURL(MweConstants.stateUrl, params, 'stateDropdown');
  }
  getCityiesByStateName(params: any) {
    this.executeGetRequestURL(MweConstants.cityUrl, params, 'cityDropdown');
  }
  executeGetRequestURL(url: string, params: any, type: string) {
    (this as any)[type] = [];
    this.masterDataService.executeGetRequestURL(url, params).subscribe(data => {
      if (data && data?.data && data.data.length > 0) {
        (this as any)[type] = data.data;
      }
    }, error => {
    })
  }

  onPreview() {
    if (this.postJobForm.valid) {
      const dialogRef = this.dialog.open(JobPreviewComponent, {
        width: '60%',
        data: this.postJobForm.value
      });
    } else {
      this.snackbarService.openSnackBar('Please fill all required fields before previewing.', '');
    }
  }
}
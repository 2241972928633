import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss']
})
export class JobApplyComponent implements OnInit, OnDestroy {

  specialities!: any[];
  jobSkills!: any[];

  isFileInputClickedCompanyLogo: boolean = false;
  previewImage: any = null;

  jobPost: any;

  applicantId: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '0rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  constructor(private careerService: CareerService, private readonly snackbarService: SnackbarService,
    private route: ActivatedRoute, private readonly router: Router) { }

  ngOnInit(): void {
    this.getAllJobPostByFilter();
  }


  getAllJobPostByFilter() {
    this.jobPost = {};
    this.careerService.executePostRequest(MweConstants.getJobPostFilterUrl, { jobPostIds: [this.careerService.getJobId()] }).subscribe({
      next: (data) => {
        if (data && data?.data && data.data.length > 0) {
          this.jobPost = data.data[0];
        }
      }, error: (error) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  onSubmitApplication() {
    const body = { jobApplicantKey: { jobPostId: this.jobPost.id, applicantId: this.applicantId } };
    this.careerService.executePostRequest(MweConstants.applyJobPostUrl, body).subscribe({
      next: () => {
        Swal.fire('', 'Job Application submitted successfully !!', 'success');
        this.onCancel();
      }, error: (error: any) => {
        this.snackbarService.openSnackBar(error.error.message, '');
      }
    });
  }

  onCancel() {
    this.router.navigate(['../view-all-jobs'], { relativeTo: this.route });
  }

  setApplicantId(applicantId: any) {
    this.applicantId = applicantId;
  }

  ngOnDestroy(): void {
    this.careerService.setJobId(null);
  }
}

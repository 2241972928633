<div class="notification-popup-container">
    <h2>All Notifications</h2>
  
    
      <div *ngFor="let notification of notifications" class="notification-item">
        <strong>{{ notification.status }}</strong>
        <p>{{ notification.content }}</p>
      </div>
    
  
    <!-- <div class="close-button-container">
      <button mat-button (click)="closeDialog()">Close</button>
    </div> -->
  </div>
  
<app-website-header></app-website-header>

<div class="view-all-jobs-container">


    <!-- Job Details Section -->
    <section class="job-filters-section p-4">
        <div class="row">
            <!-- Job Description and Details -->

            <div class="col-md-3">
                <div class="d-flex">
                    <div class="filters-heading me-2">Filters</div>
                    <mat-chip class="reset-chip" (click)="resetFilters()"><img
                            src="/assets/images/job-filters-reset.svg" alt="job-filters-reset"
                            class="me-1">Reset</mat-chip>
                </div>

                <div class="card mt-3" style="height: 95%;">
                    <div class="card-body">

                        <form [formGroup]="jobFilterForm">

                            <div class="filter-field-container">
                                <div class="filter-label mb-3">Your Search Keyword</div>

                                <mat-chip-grid #chipList>
                                    <mat-chip-row *ngFor="let keyword of jobFilterForm.get('keywords')?.value"
                                        class="reset-chip" [selectable]="selectable" [removable]="removable"
                                        (removed)="remove(keyword)">
                                        {{keyword}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip-row>

                                    <label class="input-container w-100">
                                        <mat-icon matPrefix class="inp">search</mat-icon>
                                        <input matInput [matChipInputFor]="chipList" placeholder="Search KeyWord"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                                    </label>

                                </mat-chip-grid>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Region </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="input-font w-100">
                                        <mat-select multiple placeholder="Search Region" formControlName="countries">
                                            <mat-option *ngFor="let country of countries"
                                                [value]="country.countryName">{{
                                                country?.countryName }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Specialist </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="input-font w-100">
                                        <mat-select multiple placeholder="Select Speciality"
                                            formControlName="specialities">
                                            <mat-option *ngFor="let speciality of specialities"
                                                [value]="speciality.specialty">{{
                                                speciality?.specialty }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Salary Range </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <!-- <mat-form-field appearance="outline" class="input-font w-100"> -->
                                    <mat-slider min="0" max="100000" class="w-100">
                                        <input formControlName="salaryFrom" matSliderStartThumb>
                                        <input formControlName="salaryTo" matSliderEndThumb>
                                    </mat-slider>
                                    <!-- </mat-form-field> -->
                                    <p>${{jobFilterForm.get('salaryFrom')?.value}} -
                                        ${{jobFilterForm.get('salaryTo')?.value}}</p>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Experience </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="input-font w-100">
                                        <input matInput type="number" placeholder="Experience"
                                            formControlName="experience">
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Job Type </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="input-font w-100">
                                        <mat-select multiple placeholder="Search Job Type" formControlName="jobTypes">
                                            <mat-option *ngFor="let jobTypeOption of jobTypes"
                                                [value]="jobTypeOption.jobType">{{
                                                jobTypeOption?.jobType }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Job Posted </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                        <input matInput [matDatepicker]="postedDate" placeholder="dd/mm/yyyy"
                                            formControlName="postedDate">
                                        <mat-datepicker-toggle matIconSuffix [for]="postedDate"></mat-datepicker-toggle>
                                        <mat-datepicker #postedDate color="primary"></mat-datepicker>
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                            <div class="filter-field-container">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="filter-label"> Skills </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-form-field appearance="outline" class="input-font w-100">
                                        <mat-select multiple placeholder="Select Skills" formControlName="skills">
                                            <mat-option *ngFor="let skill of jobSkills" [value]="skill.skillName">{{
                                                skill?.skillName }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-expansion-panel>
                            </div>

                        </form>
                    </div>
                </div>
            </div>



            <div class="col-md-9">

                <ng-container *ngIf="showNoRecordsFounds; else displayRecords">
                    <div class="no-records-found"> No records Found </div>
                </ng-container>

                <ng-template #displayRecords>

                    <div class="d-flex justify-content-between">

                        <div>Displaying <span style="color: #0d87c8;font-weight: bold;">{{getRangeLabel(currentPage,
                                pageSize,
                                totalData)}}
                            </span> jobs out of
                            <span style="color: #0d87c8;font-weight: bold;">{{totalData}}</span>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <mat-form-field appearance="outline" class="custom-form-field">
                              <mat-select placeholder="Select Sort Option" [(value)]="selectedSortOption" placeholder="Sort by" (selectionChange)="onSortOptionSelected($event.value)">
                                <mat-option value="alphabetical">Sort by Name</mat-option>
                                <mat-option value="date">Sort Application Start</mat-option>
                                <mat-option value="createdOn">Sort By Created On</mat-option>
                              </mat-select>
                            </mat-form-field>
                            <button *ngIf="selectedSortOption" mat-icon-button (click)="toggleSortDirection()" [attr.aria-label]="currentSortDirection === 'asc' ? 'Sort Descending' : 'Sort Ascending'" >
                              <mat-icon>
                                {{ currentSortDirection === 'asc' ? 'arrow_downward' : 'arrow_upward' }}
                              </mat-icon>
                            </button>
                          </div>                          
                    </div>

                    <div class="row g-3">
                        <ng-container *ngFor="let jobPost of obs | async;">
                            <div class="col-md-4">

                                <div class="card mt-3">
                                    <div class="card-header">

                                        <div class="d-flex justify-content-between">
                                            <h2>{{jobPost?.title}}</h2>
                                            <img src="{{ !isJobSaved(jobPost) ?'/assets/images/careers-apply-icon.svg':'/assets/images/saved-jobs.svg'}}" style="cursor: pointer;"
                                                (click)="onSaveJob(jobPost)" alt="apply-icon">
                                        </div>

                                        <div class="d-flex mt-2" style="gap: 10px;">
                                            <div class="job-details-header"><img
                                                    src="/assets/images/careers-map-pin.svg" alt="">
                                                {{jobPost?.country}}</div>
                                            
                                            <div class="job-details-header"><img src="/assets/images/careers-clock.svg"
                                                    alt="">
                                                {{jobPost?.jobType}}</div>
                                        </div>

                                        <div class="d-flex mt-2 mb-2" style="gap: 10px;" *ngIf="salaryHide">
                                            <div class="job-details-header"><img src="/assets/images/careers-dollar.svg"
                                                    alt="">
                                                {{jobPost?.salaryFrom | salary}}-{{jobPost?.salaryTo | salary}}
                                                {{jobPost?.salaryRange}}
                                            </div>
                                            .
                                            <div class="job-details-header"><img
                                                    src="/assets/images/careers-calendar.svg" alt="">
                                                {{getPostedDate(jobPost?.applicationStartDate)}} day(s) ago</div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="description" [innerHTML]="jobPost?.description"></div>
                                        <button type="button" class="button button-primary read w-auto"
                                            (click)="onReadMore(jobPost?.id)">
                                            Read More ></button><br>

                                        <button type="button" class="button button-primary w-auto"
                                            (click)="onApplyJob(jobPost?.id)">
                                            <img src="/assets/images/angle-arrow-small-right.svg"
                                                alt="angle-arrow-small-right">
                                            Apply Now</button>
                                    </div>
                                </div>

                            </div>
                        </ng-container>
                    </div>

                </ng-template>

            </div>
        </div>
    </section>

    <mat-paginator class="mat-pagination d-none" [length]="dataSource.data?.length" [pageSize]="pageSize"
        *ngIf="!showNoRecordsFounds">
    </mat-paginator>

    <div class="paginator-container" *ngIf="!showNoRecordsFounds">
        <div class="page-numbers">
            <button mat-icon-button class="arrow" (click)="changePage(0)" [disabled]="currentPage === 0">
                <img src="assets/images/pagination_first.svg" alt="pagination_first" srcset="">
            </button>
            <button mat-icon-button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
                <img src="assets/images/pagination_prev.svg" alt="pagination_prev" srcset="">
            </button>

            <ng-container *ngFor="let page of getPageNumbers()">
                <button mat-button *ngIf="page !== '...'" [class.page-active]="currentPage === page"
                    (click)="changePage(+page)">
                    {{ +page + 1 }}
                </button>
                <span *ngIf="page === '...'">...</span>
            </ng-container>
            <button mat-icon-button (click)="changePage(currentPage + 1)"
                [disabled]="currentPage >= getTotalPages() - 1">
                <img src="assets/images/pagination_next.svg" alt="pagination_next" srcset="">
            </button>
            <button mat-icon-button (click)="changePage(getTotalPages() - 1)"
                [disabled]="currentPage >= getTotalPages() - 1">
                <img src="assets/images/pagination_last.svg" alt="pagination_last" srcset="">
            </button>
        </div>
    </div>

</div>

<app-website-footer></app-website-footer>
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[allowPlusAndNumbers]'
})
export class AllowPlusAndNumbersDirective {

  constructor() { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', '+'];
    if (allowedKeys.indexOf(event.key) !== -1 || 
        // Allow Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
        (event.ctrlKey && ['a', 'c', 'v', 'x'].indexOf(event.key.toLowerCase()) !== -1) ||
        // Allow numbers and numpad numbers
        (event.key >= '0' && event.key <= '9') || (event.key >= 'Numpad0' && event.key <= 'Numpad9')) {
      return;  // Allow the event
    } else {
      event.preventDefault();  // Block the event
    }
  }

  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.value = inputElement.value.replace(/[^0-9+]/g, '');
  }

  @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedInput = clipboardData?.getData('text');
    if (pastedInput?.match(/[^0-9+]/)) {
      event.preventDefault();
    }
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { MasterDataService } from 'src/app/services/master-data.service';

@Component({
  selector: 'app-profile-jobs',
  templateUrl: './profile-jobs.component.html',
  styleUrls: ['./profile-jobs.component.scss']
})
export class ProfileJobsComponent implements OnChanges {
  data: any[] = [];
  @Input() isSavedJobs!: boolean;
  @Input() applicantId!: any;

  jobs!: Observable<any>;

  showNoRecordsFound: boolean = true;
  pageSize = 6;
  currentPage = 0;
  totalData = this.data.length;
  dataSource = new MatTableDataSource<any[]>;
  paginator!: MatPaginator;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  constructor(private readonly masterDataService: MasterDataService,
    private readonly careerService: CareerService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.findApplicantJobsByFilter();
  }

  findApplicantJobsByFilter() {
    const jobApplicationFilter = { applicantId: this.applicantId, isSaved: this.isSavedJobs };
    this.careerService.executePostRequest(MweConstants.getJobPostUrl.concat("/applicant/jobs/filter"), JSON.parse(JSON.stringify(jobApplicationFilter))).subscribe({
      next: (data) => {
        if (data && data?.data && data.data.length > 0) {
          this.data = data.data;
          this.dataSource = new MatTableDataSource<any[]>(this.data);
          this.dataSource.paginator = this.paginator;
          this.jobs = this.dataSource.connect();
          this.updateCardData();
          this.showNoRecordsFound = false;
        } else {
          this.showNoRecordsFound = true;
        }
      }, error: (error) => {
        this.showNoRecordsFound = true;
      }
    });
  }

  getPostedDate(jobPostedDate: any) {
    const startDate: any = new Date(jobPostedDate);
    const todayDate: any = new Date();
    const diffTime = Math.abs(todayDate - startDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  changePage(pageIndex: number) {
    this.currentPage = pageIndex;
    this.updateCardData();
  }

  updateCardData() {
    this.totalData = this.data?.length;
    if (this.paginator) {
      this.paginator.pageIndex = this.currentPage;
      this.dataSource.paginator = this.paginator;
    }
  }

  getTotalPages(): number {
    return Math.ceil(this.totalData / this.pageSize);
  }

  getPageNumbers(): (number | string)[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPage;
    const pageNumbers: (number | string)[] = [];
    // Always show the first page
    pageNumbers.push(0);

    // Show ellipsis if there are more than 3 pages before the current page
    if (currentPage > 2) {
      pageNumbers.push('...');
    }

    // Show current page and its neighbors
    for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    // Show ellipsis if there are more than 3 pages after the current page
    if (currentPage < totalPages - 3) {
      pageNumbers.push('...');
    }

    // Always show the last page if total pages is greater than 1
    if (totalPages > 1) {
      pageNumbers.push(totalPages - 1);
    }

    return pageNumbers;
  }

  unSaveJob( jobData: any): void{
    this.careerService.executeDeleteRequestURL(MweConstants.deleteJobPostUrl.concat(`applicant/${jobData.applicantId}/jobs/${jobData.jobPostId}`), null, null).subscribe({
      next: () =>{
        console.log("Job is unsaved");
        this.data = this.data.filter(job => job.jobPostId !== jobData.jobPostId);

        if(this.data.length === 0){  // Checking whether length is zero or not
          this.dataSource.data = [];
          this.jobs = this.dataSource.connect();    
          this.showNoRecordsFound = true
        }else{
          this.dataSource = new MatTableDataSource<any[]>(this.data);
          this.dataSource.paginator = this.paginator;
          this.jobs = this.dataSource.connect();

          this.totalData = this.data?.length
          const pageIndex = this.getTotalPages();

          this.changePage(pageIndex-1)
        }
      },
      error: (error) =>{
        console.log(error)
      }
    })
  }
}


<div class="brand-header">
  <ng-container *ngIf="data.logo">
    <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
  </ng-container>
  <div class="brand-details">
    <h1>{{ data.companyName | camelcase}}</h1>
    <ng-container *ngIf="data.url">
      <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
    </ng-container>
  </div>
</div>
<div class="total-results">
  <strong>Total Results {{ products.length }} </strong>
</div>
<div class="parent-container">
  <div class="filter">
    <app-brand-product-filter [filterColumns]="['Products']" [data]="originalData" (filteredData)="onFilteredData($event)"></app-brand-product-filter>
  </div>
  
    <div class="product-list">
      <ng-container *ngIf="products.length===0">
        <div class="no-records-found"> No records Found </div>
      </ng-container>
      <div class="card" *ngFor="let catalog of paginatedItems">
        <!-- <img [src]="catalog.catalog" alt="catalog"/> -->
        <div *ngIf="catalog.type === 'image'">
          <img [src]="catalog.catalog" alt="catalog"/>
        </div>
        <div *ngIf="catalog.type === 'pdf'">
          <div class="iframe-container">
            <iframe [src]="catalog.catalog | safe" class="main-iframe"></iframe>
          </div>
        </div>
        
        <div class="card-actions">
          <h3 *ngIf="catalog.title">{{ catalog.title.length > 20 ? catalog.title.slice(0, 20) + '...' : catalog.title | camelcase}} </h3>
          <!-- <p>3 pages</p> -->
           <p></p>
        </div>
        <div class="buttons">
          <div class="buttons">
            <button class="view-button" (click)="viewCatalog(catalog)"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
            <button class="download-button" (click)="downloadCatalog(catalog)"><img src="assets/images/download.svg" alt="Download"/>Download</button>
          </div>
        </div>
      </div>
    </div>
</div>
  <div class="pagination-container">
    <app-pagination [itemsPerPage]="itemsPerPage" [data]="products" (pageChanged)="onPageChanged($event)"></app-pagination>
  </div>


  
      
      
      
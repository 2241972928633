<table mat-table [dataSource]="dataSource" class="full-width-table" matSort aria-label="Elements">
    <!-- Sr No Column -->
    <ng-container matColumnDef="srNo">
      <th mat-header-cell *matHeaderCellDef style="padding-right:0px; width: 3.2%;">Sr No</th>
      <td mat-cell *matCellDef="let element; let i = index" class="sr-no"> {{element?.srNo}} </td>
    </ng-container>
  
    <!-- Unique ID Column -->
    <ng-container matColumnDef="uniqueId">
      <th mat-header-cell *matHeaderCellDef style="padding-right:0px">Unique ID</th>
      <td mat-cell *matCellDef="let element" style="padding-right:0px">{{element?.uniqueId}}</td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef >Name</th>
      <td mat-cell *matCellDef="let element">{{element?.userName}}</td>
    </ng-container>
  
    <!-- Company Logo Column -->
    <ng-container matColumnDef="companyLogo">
      <th mat-header-cell *matHeaderCellDef>Company Logo</th>
      <td mat-cell *matCellDef="let element">
        <img [src]="element.logo" alt="Plan" class="company-logo">
      </td>
    </ng-container>
  
    <!-- Company Name Column -->
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef>Company Name</th>
      <td mat-cell *matCellDef="let element">{{element?.companyName}}</td>
    </ng-container>
  
    <!-- Email Address Column -->
    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef>Email Address</th>
      <td mat-cell *matCellDef="let element">{{element?.email}}</td>
    </ng-container>
    
    <!-- Country Column -->
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>Country</th>
      <td mat-cell *matCellDef="let element">{{element?.country}}</td>
    </ng-container>
  
    <!-- Phone No Column -->
    <ng-container matColumnDef="phoneNo">
      <th mat-header-cell *matHeaderCellDef>Phone No</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element?.phoneCode && element?.phoneCode !== ''">{{element.phoneCode}}</span>
        <span *ngIf="element?.phoneNumber && element?.phoneNumber !== ''">
          {{element.phoneCode ? ' ' : ''}}{{element.phoneNumber}}
        </span>
      </td>
    </ng-container>
    
  
    <!-- Plan Taken Column -->
    <ng-container matColumnDef="planTaken">
      <th mat-header-cell *matHeaderCellDef style="width: 10px;">Plan Taken</th>
      <td mat-cell *matCellDef="let element">
        <div class="action-icons-container">
          <img *ngIf="element?.subscriptionInfo" [src]="getSubscritonImage(element?.subscriptionInfo)" alt="Plan" class="plan-image"> {{getSubsctiptionName(element?.subscriptionInfo)}}
        </div>
      </td>
    </ng-container>
  
    <!-- Req Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Req. Status</th>
      <td mat-cell *matCellDef="let element" ><span [ngClass]="element?.status"> {{element?.status}}</span></td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" ><span [ngClass]="element?.active"> {{element?.active}}</span></td>
    </ng-container>
  
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let element">{{ element?.createdOn | date }}</td>
    </ng-container>
  
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 10px;">Actions</th>
      <td mat-cell *matCellDef="let element">
      <!-- Add your action icons here -->
        <div class="action-icons-container">
          <img src="assets/images/icons/view-icon.svg" alt="" class="action-icon" (click)="view(element.id)" >
        <img src="assets/images/icons/edit.svg" alt="" class="action-icon" (click)="edit(element.id)" >
        <img src="assets/images/icons/delete-icon.svg" alt="" class="action-icon" (click)="delete(element.id)">
        </div>
      </td>
    </ng-container>
  
    <!-- Define columns -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
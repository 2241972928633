import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-services-profile',
  templateUrl: './services-profile.component.html',
  styleUrls: ['./services-profile.component.scss']
})
export class ServicesProfileComponent implements OnInit{

  @Input() servicesForm!:FormGroup;

  services:any=[];


  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  showEditForm:boolean=false

  copyServicesForm:any;

  servicesData:any=[];
  constructor(private endCustomerService:EndCustomerService){}

  ngOnInit(): void {
    this.getServices();
  }

  getServices(){
    this.endCustomerService.executeGetRequestURL(MweConstants.serviceUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.services=data.data;
        if(this.servicesForm.get('serviceInfos')?.value &&this.servicesForm.get('serviceInfos')?.value.length>0){
          this.getServicesData();
        }
      }
    },error=>{

    })
  }

   update(){
    this.onUpdate.emit(40);
  }

  cancel(){
    this.showEditForm=false;
  
       this.servicesForm.get('serviceInfos')?.patchValue([]); 
       
  if(this.copyServicesForm?.serviceInfos && this.copyServicesForm?.serviceInfos.length>0){
    this.servicesForm.get('serviceInfos')?.patchValue(this.copyServicesForm?.serviceInfos); 
  
   
  }
  this.getServicesData(); 
   
  
  }

  editService(){
    this.copyServicesForm=JSON.parse(JSON.stringify(this.servicesForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    this.getServicesData();
  }

  getServicesData(){

     const equipment=this.services;
     
     let serviceInfos:any=[];
     
     if(this.endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length>0){
      serviceInfos= equipment.filter((obj1:any)=>{
         
         return this.endCustomerData.serviceInfos.some((obj2:any)=>{
          
           return obj1.id===obj2.serviceInfoId;
         })
       })
     }
     
 
     this.servicesData=serviceInfos;
   }
  remove(i:number): void {
    this.servicesForm.get('serviceInfos')?.value.splice(i,1);
    this.servicesForm.get('serviceInfos')?.patchValue(this.servicesForm.get('serviceInfos')?.value);
}
 
  
getService(id:any){
  return this.services.find((obj:any)=>obj?.id===id)?.name;
 }
  
}

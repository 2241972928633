import { DistributorNetworkManagementService } from './../../../../services/oem/distributor-network-management.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RejectDistributorRemarksPopupComponent } from 'src/app/components/shared/reject-distributor-remarks-popup/reject-distributor-remarks-popup.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DISTRIBUTOR_ID, OEM_ID, USER_ID } from 'src/app/utils/constants';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-distributor-requests',
  templateUrl: './distributor-requests.component.html',
  styleUrls: ['./distributor-requests.component.scss']
})
export class DistributorRequestsComponent implements OnInit {

  formColumns: string[] = ['country', 'state', 'city', 'verification', 'brands', 'ratings']

  ids: { id: string, senderId: string }[] = [];
  cardIds: any;
  distributorIds: any = {};
  
  body: any= 
  {
      senderId: this.localStorageService.getItem('DISTRIBUTOR'),
      receiverId: null,
      senderRole: 'DISTRIBUTOR',
      receiverRole: 'OEM',
      status: 'PENDING'
    }

    rejectData: string = '';

rejectRequest(id: string) {
  this.remarksPopup(id)
  this.getRequests();


}

remarksPopup(id: string): void{
  const dialogRef = this.dialog.open(RejectDistributorRemarksPopupComponent,{
    height: "198px%",
    width: "50%",
    data: { }
  })

  dialogRef.afterClosed().subscribe( result =>{
    this.rejectData = result;
    this.distributorNetworkService.rejectRequest(id, this.rejectData).subscribe((res: any)=>{
    })
  }, error =>{
  })
}
acceptRequest(id: any) {
  this.distributorNetworkService.acceptRequest(id).subscribe((response) => {
    this.getRequests();
  })
}
  userId: any;
  requests: any;
  oemId: any;
  oemData: any[]=[];

  constructor(
    private oemService: OemService,
    private http : HttpClient, 
    private localStorageService: LocalStorageService, private distributorNetworkService: DistributorNetworkManagementService, private dialog: MatDialog) {
    
    this.localStorageService.getUserDetails().subscribe(userDetails => {
      this.oemId = this.localStorageService.getItem(OEM_ID);
    });
  }

  ngOnInit(): void {
    this.getRequests();
  }

  reqDistributors: { role: string; ids: string[] } = {
    role: '',
    ids: []
  }
  

  getRequests() {
    this.distributorNetworkService.getReceivedPendingRequests(this.oemId).subscribe((data: any) => {
      (data.data).forEach((d: any) => {
        this.reqDistributors.role = d.senderRole;
        this.reqDistributors.ids.push(d.senderId)
        if (!this.cardIds) this.cardIds = {};
        this.cardIds[d.id] = d.senderId
        this.oemData = data.data;
      });
      this.oemService.requestedDetails(this.reqDistributors).subscribe((res: any)=>{
        if (res?.data) {
          (res.data).forEach((d: any) => {
          if (!this.distributorIds) this.distributorIds = {};
          this.distributorIds[d.distributorId] = d;
        })
        for (let i = 0; i < this.oemData.length; i++) {
          const data = this.oemData[i]
        }
      }
      })
    
    })
  }

  getDistributorById(id:any){
    this.distributorNetworkService.getDistributorById(id).subscribe(data => {
      this.oemData.push(data.data);
    })
  }

productImage: any="/assets/images/lab equipment.svg";
title: any;
isCategoryCard: any;
subtitleImage: any;
contentItems: any;
hoverText: any;
@Input() isAll: boolean = true;
@Input() isRequested: boolean = false;
@Input() isRequestedBy: boolean = false;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-work-experience-profile',
  templateUrl: './work-experience-profile.component.html',
  styleUrls: ['./work-experience-profile.component.scss']
})
export class WorkExperienceProfileComponent implements OnInit{
  @Input() value: boolean | undefined;

  handleWorkExperience(clickedWorkExperience: boolean) {
    console.log('Received Work Experience:', clickedWorkExperience);
  }

  workExpColumns: ListColumn[] = [
    { name: 'Designation', property: 'designation', visible: true, isModelProperty: true ,isText: true},
    { name: 'From Year', property: 'yearFrom', visible: true, isModelProperty: true ,isText: true},
    { name: 'To Year', property: 'yearTo', visible: true, isModelProperty: true ,isText: true},
    { name: 'Organization', property: 'organization', visible: true, isModelProperty: true ,isText: true},
    { name: 'Specialization', property: 'specialization', visible: true, isModelProperty: true ,isText: true},
  ] as ListColumn[];

  @Input() workExperienceForm!:FormGroup;
  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyWorkExperienceForm:any;
  data:any=[];

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  yearDropdown:any=[];
  futureYearDropdown:any=[];
  hospitalIcon:string="assets/images/hospital.svg";

  constructor(private fb :FormBuilder ){}
  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify( this.workExperienceForm.get('workInfos')?.value));
   this.getYearDropdown();
   this.getFutureYearDropdown();
  }

  getYearDropdown(){
    for(let year=new Date().getFullYear();year>=1947;year--){
      this.yearDropdown.push(year);
    }
  }

  getFutureYearDropdown(){
    for(let year=new Date().getFullYear()+5;year>=1947;year--){
      this.futureYearDropdown.push(year);
    }
  }

  get workInfos() {
    return this.workExperienceForm?.controls["workInfos"] as FormArray;
  }

  addWork(){
    this.copyWorkExperienceForm=JSON.parse(JSON.stringify(this.workExperienceForm.value));
    this.workInfos.push(
      this.fb.group({
        id:new FormControl(null),
        active:new FormControl(null),
        createdBy:new FormControl(null),
        createdOn:new FormControl(null),
        modifiedBy:new FormControl(null),
        modifiedOn:new FormControl(null),
        "designation"  :new FormControl(null),
         "yearFrom": new FormControl(null),
         "yearTo": new FormControl(null),
         "organization": new FormControl(null),
         "specialization": new FormControl(null),
       }),
    )
    this.showEditForm=true;
  }

  editWork(){
    this.copyWorkExperienceForm=JSON.parse(JSON.stringify(this.workExperienceForm.value));
    this.showEditForm=true;
  }
  
  update(){
    this.updateWorkInfosValidations();
    this.workInfos.markAllAsTouched();
    if(this.workInfos.invalid){
      return;
    }
    this.onUpdate.emit(40);
  }
  
  refresh(){
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.workExperienceForm.get('workInfos')?.value));
  }
  
  cancel(){
      
    (this.workExperienceForm.get('workInfos')as FormArray).clear();
     if(this.copyWorkExperienceForm?.workInfos && this.copyWorkExperienceForm.workInfos.length>0){
          for(let i=0;i<this.copyWorkExperienceForm.workInfos.length;i++){
            (this.workExperienceForm.get('workInfos')as FormArray).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "designation"  :new FormControl(null),
              "yearFrom": new FormControl(null),
              "yearTo": new FormControl(null),
              "organization": new FormControl(null),
              "specialization": new FormControl(null),
            }));
          }
          (this.workExperienceForm.get('workInfos')as FormArray).patchValue(this.copyWorkExperienceForm.workInfos);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.workExperienceForm.get('workInfos')?.value));
   
  
  
  }

  updateWorkInfosValidations(){
    for(let i=0;i<this.workInfos.controls.length;i++){
      if(!(this.workInfos.at(i).get('designation')?.value==null || this.workInfos.at(i).get('designation')?.value=='')
        || this.workInfos.at(i).get('yearFrom')?.value!=null || !(this.workInfos.at(i).get('organization')?.value==null || this.workInfos.at(i).get('organization')?.value=='') ||
        this.workInfos.at(i).get('yearTo')?.value!=null || !(this.workInfos.at(i).get('specialization')?.value==null || this.workInfos.at(i).get('specialization')?.value=='')  ){
          
        this.workInfos.at(i).get('designation')?.setValidators([Validators.required]);
        this.workInfos.at(i).get('yearFrom')?.setValidators([Validators.required]);
        this.workInfos.at(i).get('yearTo')?.setValidators([Validators.required]);
        this.workInfos.at(i).get('organization')?.setValidators([Validators.required]);
        this.workInfos.at(i).get('specialization')?.setValidators([Validators.required]);
      }else{
        this.workInfos.at(i).get('designation')?.clearValidators();
        this.workInfos.at(i).get('yearFrom')?.clearValidators();
        this.workInfos.at(i).get('yearTo')?.clearValidators();
        this.workInfos.at(i).get('organization')?.clearValidators();
        this.workInfos.at(i).get('specialization')?.clearValidators();
      }
      this.workInfos.at(i).get('designation')?.updateValueAndValidity();
      this.workInfos.at(i).get('yearFrom')?.updateValueAndValidity();
      this.workInfos.at(i).get('yearTo')?.updateValueAndValidity();
      this.workInfos.at(i).get('organization')?.updateValueAndValidity();
      this.workInfos.at(i).get('specialization')?.updateValueAndValidity();
    }
  }
  
  deleteWork(i:number){
  this.workInfos.removeAt(i);
  }
}

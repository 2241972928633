import { Specifications } from 'src/app/models/product-settings-model';
import { LocalStorageService } from './../../../services/local-storage.service';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { catchError, forkJoin, Observable, tap, throwError, Subscription, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { HttpClient } from '@angular/common/http';
import { ProductFileViewerComponent } from '../product-settings/product-file-viewer/product-file-viewer.component';
import { MatDialog } from '@angular/material/dialog';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import Swal from 'sweetalert2';
import { ProductDistributionRequestComponent } from '../product-distribution-request/product-distribution-request.component';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})

export class ProductPageComponent implements OnInit {
  productDetails$!: Observable<ApiResponseModel<any> | null>;
  data: any;
  oemData: any;
  breadcrumb: any;
  currImg: number = 0;
  autoSlideInterval: any;
  moreImages: any = false;
  userDetails: any;
  currDate: string = '';
  currTime: string = '';
  signin: any;
  id: any;
  products: any[] = [];
  specifications: any[] = [];
  starIndex = -1;
  toggleWriteReview: boolean = false;
  ratingCount: any[] = [0, 0, 0, 0, 0]
  isEditReview: boolean = false;
  editReviewData: any;
  reviews: any[] = [];
  review: string = '';
  myReviews: any[] = [];
  totalReviews: number = 0;
  roleName: string = '';
  isLoggedIn:boolean = false;
  displayReviews: number = 5;
  reviewsBatchSize: number = 5;

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      translate: 'yes',
      enableToolbar: true,
      showToolbar: false,
      placeholder: 'Write your review here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  userId!: string;
  averageRating: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductsService,
    private http: HttpClient,
    private dialog: MatDialog,
    private allProductService : AllProductService,
    private categoryService: CategoryService,
    private localStorageService: LocalStorageService,
  ) {
    this.localStorageService.getUserDetails().subscribe((userDetails) => {
      this.userId = userDetails.userId;
      this.isLoggedIn = !!this.userId;  // Set isLoggedIn based on userId
      this.signin = userDetails;
      this.userDetails = userDetails?.userName || '';      
    });
    this.roleName = this.localStorageService.getItem('roleName');
    
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id) {
        this.getProductDetails(this.id)
       
      } 
    });
    this.route.params.subscribe(routeParams => {
    forkJoin([
      this.getBreadcrumb(routeParams['id']),
    ]).subscribe((res: any) => {
      if(res) {
        
      }
    });
    });
    this.selectedMainImage = this.selectedViewData?.images[0]
    // this.processCatalogs(this.selectedViewData?.catalogs);    
    this.incrementViewCount(this.id);
    this.getAllProducts();
    this.getReviews(this.id)
  }

  incrementViewCount(id: any) {
   this.productService.increamentProductCount(id).subscribe((res: any) => {
   })
  }

  writeReview(reviewData: any){
    this.productService.postReview(reviewData).subscribe((res: any)=>{
      this.getReviews(this.id);
    })
  }

  calculatePercentage(rating: number): number {
    return Math.round((rating * 100) / this.totalReviews);
  }
  
  calculateDaysAgo(reviewDate: string): string {
    const reviewDateObj = new Date(reviewDate);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - reviewDateObj.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    if (dayDifference <= 0) {
        return 'Today';
    } else if (dayDifference === 1) {
        return 'Yesterday';
    } else if (dayDifference < 30) {
        return `${dayDifference} days ago`;
    } else if (dayDifference < 60) {
        return 'a month ago';
    } else if (dayDifference < 365) {
        const months = Math.floor(dayDifference / 30);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (dayDifference < 730) {
        return 'a year ago';
    } else {
        const years = Math.floor(dayDifference / 365);
        return `${years} year${years > 1 ? 's' : ''} ago`;
    }
}  

getReviews(id: any) {
  this.totalReviews = 0;
  this.ratingCount = [0, 0, 0, 0, 0];
  this.productService.getProductReviews(id, 0, 100).subscribe((res: any) => {
    if (res && res.data && res.data.reviews) {
      this.reviews = res.data.reviews.map((t: any) => {
        t.date = String(new Date(t.date));
        this.ratingCount[5 - t.rating]++;
        return t;
      });
      this.totalReviews = this.reviews.length;
      this.averageRating = res.data.averageRating;
      this.reviews = this.reviews.reverse();
    }
  });
}

  editReview(reviewId: string){
    this.toggleWriteReview = true;
    this.isEditReview = true;
    this.editReviewData = this.reviews.find((t: any)=>t.reviewId===reviewId);
    this.starIndex = this.editReviewData.rating;
    this.review = this.editReviewData.description;
  }

  putReview(data: any, id: string){
    this.productService.editProductReview(data, id).subscribe((res: any)=>{
      this.getReviews(this.id);
    })
  }
  
  addReview(type: string) {
    if (type === 'add') {
      const reviewData = {
        'productId': this.data.id,
        'description': this.review,  
        'rating': this.starIndex,
        'userId': this.userId
      };

      if (this.isEditReview) {
        this.putReview(reviewData, this.editReviewData.reviewId);
        this.isEditReview = false;
      } else {
        this.writeReview(reviewData);
      }

      this.starIndex = -1;
      this.review = '';
      this.toggleWriteReview = false;
    } else {
      this.starIndex = -1;
      this.review = '';
      this.toggleWriteReview = false;
    }
  }

  reviewInput(){
    this.toggleWriteReview = true;
  }

  deleteReview(reviewId: string){
    this.productService.deleteProductReview(reviewId).subscribe((res: any)=>{
      this.getReviews(this.id);
    })
  }

  getBreadcrumb(categoryId: string) {
    this.categoryService.getBreadcrumb(categoryId).subscribe((res: any ) => {
      this.breadcrumb = res;      
    })
  }

  applyForDistributor(){    
    if(this.roleName === 'DISTRIBUTOR'){
      this.router.navigate(['productDistributionRequest', this.id])
    }
    else{
      Swal.fire({
        title: "We're sorry, but it seems like you are currently unable to send a Partner or Distributor request to the OEM.",
        text: 'However, you can still apply for distribution rights by creating a Distributor account. Click the button below to sign up for a new account and start the process today',
        iconHtml: '<img src="./assets/images/group-delete.svg">',
        iconColor : 'white',
        showCancelButton: true,
        confirmButtonText: 'Sign up for Distributor',
        confirmButtonColor : 'rgba(13, 135, 200, 1)',
        cancelButtonText: 'Close',
        cancelButtonColor : 'rgba(173, 173, 173, 1)'  
      }).then((t: any)=>{
    
        if(t.isConfirmed) {
       
          this.router.navigate(['/distributor/signup']);
        }
      })
    }
  }

  getProductDetails(id: string){
    this.productDetails$ = this.productService.getProductDetails(id);
    this.productDetails$.subscribe((res: any) => {
      this.data = res.data.productInfoDTO;
      this.oemData = res.data;
      (this.data.images.length>4)?(this.moreImages = true): (this.moreImages = false);
      this.processCatalogs(this.data.catalogs)      
    });
  }

  @Output() closeToBack = new EventEmitter<any>();
  @Input() selectedViewData:any;
  catalogFiles: any[] = [];
  descriptionContent : string = '';
  selectedMainImage : any;
  zoomStyle = {};
  rating: number = 4;
  stars: number[] = [1, 2, 3, 4, 5];
  tabSliderPosition = 0; 
  tabSliderWidth = 0;
  selectedFile: string = '';
  selectedFileType: string = '';
  activeTab = 0;
  previousTab: number = 0;
  animateContent: boolean = false;

  processCatalogs(urls: string[]): void {
    if (!urls || urls.length === 0) {
   
      return;
    }
  
    urls.forEach(url => {
      if (url) {
     
        this.fetchCatalogsDetails(url);
      } else {
        
      }
    });
  }

  fetchCatalogsDetails(url: string): void {
    
    const fileName = this.extractFileName(url);
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    const fileExtension = url.split('.').pop(); 
    const definedExtensions = ['jpeg', 'png', 'jpg']; 
    const fileType = definedExtensions.includes(fileExtension as string) ? 'image' : 'pdf'; 
    const file = {
      name: fileName,
      size: Math.floor(Math.random() * 1024 * 1024), 
      url: url,
      progress: Math.floor(Math.random() * 100),
      type: fileType
    };
    this.catalogFiles.push({
      file: file,
      name: file.name,
      size: (file.size / 1024).toFixed(2), 
      url: file.url,
      type: fileType,
      fileViewName : fileNameWithoutExtension,
      progress: file.progress
    });
   
  }

  extractFileName(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  back(){
    this.closeToBack.emit(true);
  }

  setMainImage(image: any) {
    this.selectedMainImage = image;
  }

  zoomIn() {
    this.zoomStyle = {
      transform: 'scale(1.2)',
      transition: 'transform 0.2s ease-in-out'
    };
  }

  zoomOut() {
    this.zoomStyle = {
      transform: 'scale(1)',
      transition: 'transform 0.2s ease-in-out'
    };
  }

  setTabSliderWidth() {
    const tabs = document.querySelectorAll('.tab');
    if (tabs.length > 0) {
      this.tabSliderWidth = (tabs[0] as HTMLElement).offsetWidth;
    }
  }

  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }

  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();
    }
  }

  getAllProducts() {
    this.productService.getTopProducts().subscribe((res: any) => {
      this.products = res.data;
     
  
      this.products.forEach((product: any) => {
       
  
        product.specifications.forEach((spec: any) => {
          if (spec.isRange === 'false') {
            this.specifications.push(`${spec.fromValue} ${spec.fromUnit}`);
           
          } else {
            this.specifications.push(`${spec.fromValue} ${spec.fromUnit} - ${spec.toValue} ${spec.fromUnit}`);
          
          }
        });
      });
  
      // Limit the specifications array to the first 3 items after processing all products
      this.specifications = this.specifications.slice(0, 3);
    });
  }
  
  totalCards: number = 10;
  currentPage: number = 1;
  pagePosition: string = "0%";
  cardsPerPage: any;
  totalPages: any;
  overflowWidth: string | undefined;
  cardWidth: string | undefined;
  containerWidth?: number;
  @ViewChild("container", { static: true, read: ElementRef })
  container: any;  

  getEmbedUrl(videoUrl: string): string {
    const videoId = this.extractVideoId(videoUrl);
    return `https://www.youtube.com/embed/${videoId}`;
  }

  private extractVideoId(url: string): string {
    const videoIdMatch = url.match(/[?&]v=([^&]+)/);
    return videoIdMatch ? videoIdMatch[1] : '';
  }

  initializeSlider() {
    this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
    this.overflowWidth = `calc(${this.totalPages * 100}% + ${this.totalPages *
      10}px)`;
    this.cardWidth = `calc((${100 / this.totalPages}% - ${this.cardsPerPage *
      10}px) / ${this.cardsPerPage})`;
  }

  changePage(incrementor: any) {
    this.currentPage += incrementor;
    this.populatePagePosition();
  }

  populatePagePosition() {
    this.pagePosition = `calc(${-100 * (this.currentPage - 1)}% - ${10 *
      (this.currentPage - 1)}px)`;
  }
 
  startAutoSlide(): void {
    this.autoSlideInterval = setInterval(() => {
      this.btnNxt();
    }, 3000); // Change slide every 3 seconds
  }

  stopAutoSlide(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  btnPrev(){
    this.currImg-=1;
    this.stopAutoSlide();
    if(this.currImg < 0){
      this.currImg = this.data.images.length - 1;
    }
    this.startAutoSlide();
  }

  btnNxt(){
    this.currImg+=1;
    this.stopAutoSlide();
    if(this.currImg >= this.data.images.length){
      this.currImg = 0;
    }
    this.startAutoSlide();
  }

  smallImgs(idx: any){
    this.currImg = idx;
  }

  getInitials(name: string): string {
    return name.split(' ').map(n => n[0]).join('');
  }

  starClicked(i: number){
    if(this.starIndex===i){
      this.starIndex = -1
    }
    else{
      this.starIndex = i;
    }
  }

  getFileType(url: string): string {
    const extension = url.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image';
      default:
        return 'unknown';
    }
  }

  viewCatalog(catalog: any) {
    window.open(catalog.catalog, '_blank');
  }

  downloadCatalog(catalog: any) {
    const link = document.createElement('a');
    link.href = catalog.catalog;
    link.download = '';
    link.target = '_blank';
    link.click();
  }

  viewMoreReviews(){
    this.displayReviews += this.reviewsBatchSize;
  }
}


import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {Component, EventEmitter, Inject, Input, OnInit, inject} from '@angular/core';
import { MatChipInputEvent} from '@angular/material/chips';
import { ProductsService } from 'src/app/services/products.service';

import {LiveAnnouncer} from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SpecificationService } from 'src/app/services/shared/product-settings/specification.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit{
  
  @Input() productDetails!: FormGroup;
  selectedUnits : string = 'fixed';
  fixed : string = 'fixed';
  range : string = 'range';
  selectedTab : number = 0;
  showAdditionalInput = 'fixed';
  specificationsData: any[] = [];
  specificationNames: any[] = [];
  selectedSpecification: any;
  unitsMap: { [key: number]: string[] } = {};
  fromUnitsMap: { [key: number]: string[] } = {};
  toUnitsMap: { [key: number]: string[] } = {};
  tags: any[] = [];
  certificates: any[] = [];
  unsavedEntries: any[] = [];
  productInfo: any;
  showAddCertificate: boolean = false;
  certificateForm!: FormGroup;

  constructor(
    private productsService: ProductsService,
    private formBuilder: FormBuilder,
    private specificationservice : SpecificationService,
    private endCustomerService: EndCustomerService, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.productDetails = this.formBuilder.group({
      specifications: this.formBuilder.array([]),
      tags : [''],
      certificates : [''],
    });
  }

  getProductById(id: string) {
    this.productsService.getProductDetails(id).subscribe((res: any) => {
      // Assign the fetched data to this.data
      this.data = res.data.productInfoDTO;
      console.log('Fetched Product Data:', this.data);

      // Populate the form controls with the fetched data
      this.productDetails.patchValue({
        // Assuming your form has these controls
        productName: this.data.productName,
        productSku: this.data.productSku,
        soldTillNoe: this.data.soldTillNow,
        productDescription: this.data.productDescription,
        price: this.data.price,
        specifications: this.data.specifications,
        tags: this.data.tags,
        // certificates: this.data.certificates,
        
        // Add any other controls you have in your form
      });
  
      // Handle specificationsData based on form or fetched data
      if (this.productDetails.get('specifications')?.value.length > 0) {
        this.specificationsData = this.productDetails.get('specifications')?.value;
      } else {
        this.specificationsData = this.data.specifications;
      }
      console.log('Specifications Data:', this.specificationsData);
  
      // Process the specifications data
      this.specificationNames = this.transformData(this.specificationsData);
      
      // Assign the tags and certificates
      this.tags = this.data.tags;
      this.certificates = this.data.certificates;
    });
  }
  
  populateCertificate(data: any) {
    const certificationsArray = this.certificateForm.get('certifications') as FormArray;
    
    // Clear any existing controls in the FormArray
    certificationsArray.clear();
    
    // Check if data.certificate is an array and populate FormArray
    if (Array.isArray(data.certificates)) {
      data.certificates.forEach((cert: any) => {
        certificationsArray.push(this.formBuilder.group({
          name: [cert.name],
          certificate: [cert.certificate],
          fileName: [cert.fileName]
        }));
      });
    }
    this.showAddCertificate = true;
    console.log(this.certificateForm.get('certifications')?.value, "---------------------------------------->");
  }  

  ngOnInit(): void {
    this.getAllSpecifications();
    if (this.data) {
      this.getProductById(this.data.id)
    }
    this.clearFormArray();
    this.populateFormArray();
    this.certificateForm = this.formBuilder.group({
      certifications: new FormArray([]),
    })
    if(this.data?.certificates.length > 0){
      if(this.productDetails?.get('certificates')?.value.length > this.data?.certificates.length){
        this.populateCertificate({certificates: this.productDetails?.get('certificates')?.value});
      } else {
        this.populateCertificate(this.data);
      }
    }else{
      if(this.productDetails?.get('certificates')?.value){
        this.populateCertificate({certificates: this.productDetails?.get('certificates')?.value});
      }
    }
  }

  clearFormArray(): void {
    this.unsavedEntries = this.specifications.controls
    .filter(control => !this.specificationsData.some(spec => spec.name === control.get('name')?.value));

    while (this.specifications.length !== 0) {
      this.specifications.removeAt(0);
    }
  }

  createRow(): FormGroup {
    return this.formBuilder.group({
      type : [''],
      name: [''],
      fromValue: [''],
      toValue: [''],
      fromUnit: [{ value: '', disabled: true }, Validators.required],
      toUnit: [{ value: '', disabled: true }, Validators.required],
      isRange: ['false'],
    });
  }

  transformData(data: any[]): any[] {
    const groupedData = data.reduce((acc, item) => {
      const { name, toUnit, toValue, fromUnit, fromValue } = item;
      if (!acc[name]) {
        acc[name] = [];
      }
      acc[name].push({
        toUnit,
        toValue,
        fromUnit,
        fromValue
      });
      return acc;
    }, {});

    return Object.keys(groupedData).map(key => ({
      name: key,
      measureRelation: groupedData[key]
    }));
  }

  dateFilter = (date: Date | null): boolean => {
    const today = new Date();
    return (date || today) <= today;
  }

  onSpecificationChange(index: number) {
    const selectedName = this.specifications.at(index).get('name')?.value;
    const selectedSpecification = this.specificationNames.find(spec => spec.name === selectedName);
    console.log('selectedSpecification',selectedSpecification)
    if (selectedSpecification) {
      if(selectedSpecification.type === 'Number') {
        this.specifications.at(index).get('type')?.setValue('Number'); 
      } else {
        this.specifications.at(index).get('type')?.setValue('Text');
      }

      const fromUnits = selectedSpecification.measureRelation.map((relation: { primaryUnit: any; }) => relation.primaryUnit);
      const toUnits = selectedSpecification.measureRelation[0].secondaryMeasure.map((measure: { unit: any; }) => measure.unit);

      this.unitsMap[index] = selectedSpecification.measureRelation.map((relation: { primaryUnit: any; }) => relation.primaryUnit).concat(
        selectedSpecification.measureRelation[0].secondaryMeasure.map((measure: { unit: any; }) => measure.unit)
      );

      this.specifications.at(index).get('fromUnit')?.setValue('');
      this.specifications.at(index).get('toUnit')?.setValue('');

      this.specifications.at(index).get('fromUnit')?.enable();
      this.specifications.at(index).get('toUnit')?.enable();

      this.specifications.at(index).get('fromUnit')?.setValidators([Validators.required]);
      this.specifications.at(index).get('toUnit')?.setValidators([Validators.required]);

      this.fromUnitsMap[index] = fromUnits;
      this.toUnitsMap[index] = toUnits;

      // Restore previously set values for fromUnit and toUnit
      const spec = this.specifications.at(index).value;
      this.specifications.at(index).get('fromUnit')?.setValue(spec.fromUnit);
      this.specifications.at(index).get('toUnit')?.setValue(spec.toUnit);
    } else {
      this.specifications.at(index).get('fromUnit')?.disable();
      this.specifications.at(index).get('toUnit')?.disable();
    }
  }

  onFromUnitChange(index: number) {
    // const fromUnit = this.specifications.at(index).get('fromUnit')?.value;
    // const selectedName = this.specifications.at(index).get('name')?.value;
    // const selectedSpecification = this.specificationNames.find(spec => spec.name === selectedName);
    // if (selectedSpecification) {
    //   const measureRelation = selectedSpecification.measureRelation.find((relation: { primaryUnit: any; }) => relation.primaryUnit === fromUnit);
    //   const toUnits = measureRelation ? measureRelation.secondaryMeasure.map((measure: { unit: any; }) => measure.unit) : [];

    //   this.specifications.at(index).get('toUnit')?.setValue('');
    //   this.toUnitsMap[index] = toUnits;
    // }
  }

  getAllSpecifications() {
    this.specificationservice.getAllSpecifications().subscribe({
      next: (response) => {
        console.log('get all specifications', response);
        
        if(response.data.length > 0) {
          this.specificationsData = response.data;
          const existingData = this.specificationsData;
          this.specificationNames = existingData.map(data => ({
            id: data.id,
            name: data.name,
            type: data.type,
            measureRelation: data.measureRelation
          }));
        } 
      },
      error: (error) => {
        console.error('Error fetching oem data:', error);
      }
    });
  }

  addSpecification(specification:any = null, isUserAdded: boolean = false) {
    const newSpecGroup = this.formBuilder.group({
      type: [specification ? specification.type : 'Number', Validators.required],
      name: [specification ? specification.name : null, Validators.required],
      fromUnit: [{ value: specification ? specification.fromUnit : '', disabled: !specification }, Validators.required],
      toUnit: [{ value: specification ? specification.toUnit : '', disabled: !specification }, Validators.required],
      fromValue: [specification ? specification.fromValue : '', Validators.required], 
      toValue: [specification ? specification.toValue : '', Validators.required], 
      isRange:  [specification ? specification.isRange : 'false'], 
    });
    this.specifications.push(newSpecGroup);

    // if (specification && !isUserAdded) {
    //   this.fromUnitsMap[this.specifications.length - 1] = this.getUnitsForSpecification(specification.name, 'fromUnit');
    //   this.toUnitsMap[this.specifications.length - 1] = this.getUnitsForSpecification(specification.name, 'toUnit', specification.fromUnit);
    // }
  }

  getUnitsForSpecification(specificationName: string, unitType: 'fromUnit' | 'toUnit', fromUnit: string = ''): string[] {
    const selectedSpecification = this.specificationNames.find(spec => spec.name === specificationName);
    console.log('specificationNames', this.specificationNames)
    console.log('selectedSpecification', selectedSpecification)
    if (unitType === 'fromUnit') {
      return selectedSpecification ? selectedSpecification.measureRelation.map((relation: { fromUnit: any; }) => relation.fromUnit) : [];
    } else {
      return selectedSpecification ? selectedSpecification.measureRelation.filter((relation: { fromUnit: string; }) => relation.fromUnit === fromUnit).map((relation: { toUnit: any; }) => relation.toUnit) : [];
    }
  }

  populateFormArray() {
    this.specificationsData.forEach(spec => {
      this.addSpecification({
        type: spec.type,
        name: spec.name,
        fromUnit: spec.fromUnit,
        toUnit: spec.toUnit,
        fromValue: spec.fromValue, 
        toValue : spec.toValue,
        isRange: spec.isRange 
      });
    });

    this.unsavedEntries.forEach((entry: any) => this.specifications.push(entry));
    this.specifications.controls.forEach((_, index) => this.initializeSpecification(index));
    this.getAllSpecifications();
  }

  initializeSpecification(index: number) {
    const selectedName = this.specifications.at(index).get('name')?.value;
    const currentFromUnit = this.specifications.at(index).get('fromUnit')?.value;
    const currentToUnit = this.specifications.at(index).get('toUnit')?.value;

    this.specifications.at(index).get('fromUnit')?.enable();
    this.specifications.at(index).get('toUnit')?.enable();
  
    this.specifications.at(index).get('fromUnit')?.setValidators([Validators.required]);
    this.specifications.at(index).get('toUnit')?.setValidators([Validators.required]);
  
    this.unitsMap[index] = currentFromUnit ? [currentFromUnit] : [];
    this.unitsMap[index] = currentToUnit ? [...this.unitsMap[index], currentToUnit] : this.unitsMap[index];
    // this.fromUnitsMap[index] = currentFromUnit ? [currentFromUnit] : [];
    // this.toUnitsMap[index] = currentToUnit ? [currentToUnit] : [];
  
    if (currentFromUnit) {
      this.specifications.at(index).get('fromUnit')?.setValue(currentFromUnit);
    }
  
    if (currentToUnit) {
      this.specifications.at(index).get('toUnit')?.setValue(currentToUnit);
    }
  }

  get specifications(): FormArray {
    return this.productDetails.get('specifications') as FormArray;
  }

  getSpecification()  {
    return this.productDetails.get('specifications') as FormArray<FormGroup>;
  }
 
  removeSpecification(i:number){
    this.getSpecification().removeAt(i);
  }

  onUnitsSelect(str :string, i:number) {
    if(str === 'range') {
      this.selectedUnits = this.range;
    } else {
      this.selectedUnits = this.fixed; 
    } 
  }
  
  onRadioSelectionChange(event: any): void {
    if (event.value === 'fixed') {
      this.showAdditionalInput = 'fixed';
    } else if(event.value === 'range'){
      this.showAdditionalInput = 'range';
    }
  }

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
    }
    console.log('add tags', this.tags)
    this.productDetails.get('tags')?.setValue(this.tags);
    event.chipInput!.clear();
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
    }
  }

  removeCertificate(certificate : any) {
    const index = this.certificates.indexOf(certificate);
    if (index >= 0) {
      this.certificates.splice(index, 1);
      this.announcer.announce(`Removed ${certificate}`);
    }
  }

  addCertificate(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.certificates.push(value);
    }
    this.productDetails.get('certificates')?.setValue(this.certificates);
    event.chipInput!.clear();
  }

  next(tab : number) {
    this.selectedTab = tab + 1;
  }

  back(tab : number) {
    this.selectedTab = tab - 1;
  }


  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '120px',
      minHeight: '0',
      maxHeight: 'auto',
      width: '1000px',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };


  get certifications(){
    return this.certificateForm.get('certifications') as FormArray
  }
  openCertificateForm() {
    this.showAddCertificate = true;
    this.certifications.push(this.formBuilder.group({
      name : [''],
      certificate : [''],
      fileName : ['']
    }))
  }

  uploadCertificate(event:any,i:number){
    let uploadData = new FormData();
     uploadData.append('file', event.target.files[0]);
     let randomNumber = Math.floor(Math.random() * 10) + 1
     uploadData.append('destinationKey', randomNumber+'');
 
     this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
       if(data && data?.data){
        // this.certificates.at(i).name = this.certificateForm.get('name')?.value;
        this.certifications.at(i).get('certificate')?.setValue(data?.data);
        this.certifications.at(i).get('fileName')?.setValue(event.target.files[0].name);

        // this.certificatesData.emit(this.certifications);
        this.productDetails.get('certificates')?.setValue(this.certificateForm.get('certifications')?.value)
       }    
     },error=>{
 
     })
  }

  deleteCertificate(i : number){
    this.certifications.removeAt(i);
    this.productDetails.get('certificates')?.setValue(this.certificateForm.get('certifications')?.value);
    if(this.certifications.length === 0){
      this.showAddCertificate = false
    }
  }

  changeCertificateName(i : number){
    if(this.data && this.data.certificates){
      // this.certificatesData.emit(this.certifications);
      this.productDetails.get('certificates')?.setValue(this.certificateForm.get('certifications')?.value)
    }
  }
}
 
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OemComponent } from './oem.component';
import { AddOemComponent } from './add-oem/add-oem.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { CatalogComponent } from './catalog/catalog.component';
import { AddCatalogComponent } from '../../shared/add-catalog/add-catalog.component';
import { ProductsManagementComponent } from './products-management/products-management.component';
import { ProductEnquiryComponent } from '../../shared/product-enquiry/product-enquiry.component';
import { Path } from 'three';
import { WebinarsComponent } from '../../shared/webinars/webinars.component';
import { MweTableComponent } from '../../shared/mwe-table/mwe-table.component';
import { ChatComponent } from '../../shared/chat/chat.component';
import { MydistributorsComponent } from './mydistributors/mydistributors.component';
import { SuperAdminProfileComponent } from '../super-admin/super-admin-profile/super-admin-profile.component';
import { OemDistributorNetworkComponent } from '../../shared/oem-distributor-network/oem-distributor-network.component';
import { AllMyDistributorsComponent } from './all-my-distributors/all-my-distributors.component';
import { ExhibitionAppointmentsComponent } from './exhibition-appointments/exhibition-appointments.component';
import { NotificationsComponent } from '../../shared/notifications/notifications.component';
import { authGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'profile',
    component: SuperAdminProfileComponent
  },
  {
    path: 'company-details',
    component: CompanyProfileComponent
  },
  {
    path: 'exhibitions',
    component: ExhibitionsComponent
  },
  {
    path: 'exhibitions/appointments',
    component: ExhibitionAppointmentsComponent
  },
  {
    path: 'add-oem',
    component: AddOemComponent
  },
  {
    path:'notifications',
    component:NotificationsComponent
  },
  {
    path: 'catalogs',
    component: CatalogComponent,
    
  },
  {
    path: 'products-management',
    component: ProductsManagementComponent
  },
  {
    path: 'enquiry',
    component: ProductEnquiryComponent
  },
  {
    path: 'webinars',
    component: WebinarsComponent
  },
  {
    path: 'mwe-table',
    component: MweTableComponent
  }, {
    path: 'products/enquiry/chat/:id',
    component: ChatComponent
  },
  {
    path: 'distributor-network',
    component: AllMyDistributorsComponent
  },
  {
    path: 'careers',
    loadChildren: () => import('../super-admin/super-admin-careers/super-admin-careers.module').then(m => m.SuperAdminCareersModule),

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OemRoutingModule { }

<div  class="product-details-container" mat-dialog-content>
    <form [formGroup]="productDetails">
          <div class="">
            <div class="row mt-2">
              <div class="col-lg-3 ">
                <div >
                  <mat-label class="label">Product Name*</mat-label>
                </div>
                <mat-form-field appearance="outline" class="fields w-100">
                  <input matInput formControlName="name">
                  <mat-error class="error-message error-msg" *ngIf="productDetails.get('name')?.hasError('required')">
                    <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
                  </mat-error>
                </mat-form-field>
              </div>
        
              <div class="col-lg-3">
                <div >
                  <mat-label class="label">Product SKU*</mat-label>
                </div>
                <mat-form-field appearance="outline" class="fields w-100">
                  <input matInput formControlName="productSku"> 
                  <mat-error class="error-message error-msg" *ngIf="productDetails.get('productSku')?.hasError('required')">
                    <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Product SKU is Required
                  </mat-error>
                </mat-form-field>
              </div>
        
              <div class="col-lg-3">
                <div >
                  <mat-label class="label">Product Release Date*</mat-label> 
                </div>

                <div>
                  <mat-form-field appearance="outline" class="input-font w-100">
                      <input matInput  [matDatepickerFilter]="dateFilter" [matDatepicker]="datepicker" formControlName="dateManufacture" placeholder="mm/dd/yyyy">
                      <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                      <mat-datepicker #datepicker color="primary"></mat-datepicker>
                      <mat-error class="error-message error-msg" *ngIf="productDetails.get('dateManufacture')?.hasError('required')">
                        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Release Date is Required
                      </mat-error>
                  </mat-form-field>
                </div>
              </div>
        
              <div class="col-lg-3">
                <div >
                  <mat-label class="label">Sold Till Now*</mat-label>
                </div>
                <mat-form-field appearance="outline" class="fields w-100">
                  <input matInput type="number" formControlName="soldTillNoe">
                  <mat-error class="error-message error-msg" *ngIf="productDetails.get('soldTillNoe')?.hasError('required')">
                    <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Sold Till Now is Required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
        </div>

        <div class="row mt-2">
          <div class="col-lg-12">
            <div formArrayName="certificates">
              <mat-label class="label">Add Certificates</mat-label>
              <div class="my-2" *ngIf="!showAddCertificate" (click)="openCertificateForm()">
                <button class="profile-btn">
                    <mat-icon >add</mat-icon> <p class="ps-2">Add Certifications</p></button>
              </div>
              <div [formGroup]="certificateForm">
                <div formArrayName="certifications">
                  <div class="add-certificates" *ngFor="let certificate of certifications.controls; let i = index" [formGroupName]="i">
                    <div class="add-fields">
                      <div>
                        <mat-label class="label certificate-label">Certificate Name</mat-label>
                      </div>
                      <div>
                        <mat-form-field appearance="outline" class="ms-2">
                          <input matInput type="text" formControlName="name" (change)="changeCertificateName(i)">
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="add-fields">
                      <span class="file-link m-2">{{certificate.get('fileName')?.value}}</span>
                      <input class="d-none" type="file" id="{{'uploadCertificate'+i}}"  accept=".png,.jpg,.jpeg,.pdf" (change)="uploadCertificate($event, i)" />
                      <label class="mwe-button mwe-button--primary upload med-world-basic-font med-world-basic-font__medium ps-3 pe-3 pt-2" style="margin-top: -2px;" for="{{'uploadCertificate'+i}}">
                        Upload</label>
  
                        <button  class="mx-4 mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="deleteCertificate(i)">Delete</button>
                    </div>
                </div>
                </div>
              </div>
              <button *ngIf="showAddCertificate"  class=" mwe-button mwe-button--primary ps-3 pe-3 "  (click)="openCertificateForm()">Add More</button>
            </div>
          </div>
        </div>
        
        <div class="row mt-2">
          <div class="col-lg-12">
            <div>
              <mat-label class="label">Description*</mat-label>
            </div>
            <div>
                <angular-editor formControlName="description" [config]="config"></angular-editor>
                <mat-error class="error-message error-msg" *ngIf="productDetails.get('description')?.hasError('required')">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Description is Required
                </mat-error>
            </div>
          </div>
        </div> 

            <div class="mt-2 card-specification">
              <div class="card-specification-body">
                <div>
                  <mat-label class="label">Specification</mat-label> 
                </div>
                <div class="mt-2">
                  <div class="" formArrayName="specifications" >
                    <div class="" *ngFor="let spec of getSpecification().controls; let i = index" [formGroupName]="i"> 
                        <div  class="add-specifications-column" *ngIf="spec.get('isRange')?.value === 'false'">
                          <button class="d-flex delete" (click)="removeSpecification(i)"><img alt="" src="./assets/images/delete-small.svg"></button>
      
                          <div class="field-specification-values" >
                            <mat-form-field appearance="outline" class="fields-input">
                              <mat-select formControlName="name"  placeholder="Select" (selectionChange)="onSpecificationChange(i)">
                                <mat-option *ngFor="let name of specificationNames" [value]="name.name">{{ name.name }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="field-specification-input-values" *ngIf="spec.get('type')?.value === 'Number'">
                            <mat-form-field appearance="outline" class="fields-input">
                              <input matInput formControlName="fromValue" type="number"> 
                            </mat-form-field>
                          </div>

                          <div class="">
                            <mat-form-field appearance="outline" class="fields-input-text" *ngIf="spec.get('type')?.value === 'Text'">
                              <input matInput formControlName="fromValue" type="text"> 
                            </mat-form-field>
                          </div>

                          <div class="field-specification-unit" *ngIf="spec.get('isRange')?.value === 'false' && spec.get('type')?.value === 'Number'" >
                            <mat-form-field appearance="outline" class="fields-input"> 
                              <mat-select formControlName="fromUnit"  placeholder="Select"  (selectionChange)="onFromUnitChange(i)">
                                <mat-option *ngFor="let unit of unitsMap[i]" [value]="unit">{{ unit }}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>

                          <div class="spec-fixed-radio" *ngIf="spec.get('type')?.value === 'Number'">
                            <mat-radio-group formControlName="isRange">
                              <mat-radio-button value="false">Fixed</mat-radio-button>
                              <mat-radio-button value="true">Range</mat-radio-button>
                            </mat-radio-group>
                          </div>
                        </div>

                          <div *ngIf="spec.get('isRange')?.value === 'true'">
                            <div class="range-container">
                              <button class="d-flex delete" (click)="removeSpecification(i)"><img alt="" src="./assets/images/delete-small.svg"></button>
                              <div class="field-specification-values" >
                                <mat-form-field appearance="outline" class="fields-input">
                                  <mat-select formControlName="name"  placeholder="Select" (selectionChange)="onSpecificationChange(i)">
                                    <mat-option *ngFor="let name of specificationNames" [value]="name.name">{{ name.name }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
  
                              <div class="field-specification-input-values" *ngIf="spec.get('type')?.value === 'Number'">
                                <mat-form-field appearance="outline" class="fields-input">
                                  <input matInput formControlName="fromValue" type="number">
                                </mat-form-field>
                              </div>

                              <div class="field-specification-unit" *ngIf="spec.get('type')?.value === 'Number'">
                                <mat-form-field appearance="outline" class="fields-input"> 
                                  <mat-select formControlName="fromUnit"  placeholder="Select" (selectionChange)="onFromUnitChange(i)">
                                    <mat-option *ngFor="let unit of unitsMap[i]" [value]="unit">{{ unit }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>

                              <div class="span-unit" *ngIf="spec.get('type')?.value === 'Number'">
                                <span>to</span>
                              </div>

                              <div class="field-specification-input-values" *ngIf="spec.get('type')?.value === 'Number'">
                                <mat-form-field appearance="outline" class="fields-input">
                                  <input matInput formControlName="toValue" type="number">
                                </mat-form-field>
                              </div>

                              <div class="field-specification-unit" *ngIf="spec.get('type')?.value === 'Number'">
                                <mat-form-field appearance="outline" class="fields-input"> 
                                  <mat-select formControlName="toUnit"  placeholder="Select">
                                    <mat-option *ngFor="let unit of unitsMap[i]" [value]="unit">{{ unit }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>

                              <div class="field-specification-status" *ngIf="spec.get('type')?.value === 'Number'">
                                <mat-radio-group formControlName="isRange">
                                  <mat-radio-button value="false">Fixed</mat-radio-button>
                                  <mat-radio-button value="true">Range</mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>
                          </div>
                        
                        <mat-divider class="divider-line" *ngIf="i < getSpecification().controls.length - 1"></mat-divider>
                    </div>
                  </div>
                </div>
                  
                <div class="">
                  <button class="specification-btn" (click)="addSpecification()" type="button"> Add Specification</button>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-lg-12">
                <div formArrayName="tags">
                  <mat-label class="label">Add Tags</mat-label>
                  <div class="chip-container">
                    <mat-chip-grid #chipGrid aria-label="Enter tag">
                      <mat-chip-row class="chip-display"  *ngFor="let tag of tags" (removed)="removeTag(tag)">
                        {{tag}}
                        <button matChipRemove [attr.aria-label]="'remove ' + tag">
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input
                        matInput
                        class="chip-input"
                        [matChipInputFor]="chipGrid"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur"
                        (matChipInputTokenEnd)="addTag($event)"
                      />
                    </mat-chip-grid>
                  </div>
                </div>
              </div>
            </div>
    </form>
</div>















 


<mat-card>
    <div class="tab-container">
      <div class="tab-header">
        <div class="tab" [class.active]="activeTab === 0" (click)="selectTab(0)">All Distributors</div>
        <div class="tab" [class.active]="activeTab === 1" (click)="selectTab(1)">My Distributors</div>
        <div class="tab" [class.active]="activeTab === 2" (click)="selectTab(2)">Requests</div>
        <div class="tab-slider" [style.width.px]="tabSliderWidth" [style.transform]="'translateX(' + tabSliderPosition + 'px)'"></div>
      </div>
  
      <div *ngIf="activeTab === 0" class="tab-content" [@slideInOut]>
        <app-all-distributors ></app-all-distributors>
      </div>
      <div *ngIf="activeTab === 1" class="tab-content" [@slideInOut]>
        <app-mydistributors [tab]=1 [isAll]="true" [isMyDistributors]="true" ></app-mydistributors>
      </div>
      <div *ngIf="activeTab === 2" class="tab-content" [@slideInOut]>
        <div class="tab-header">
          <div class="tab" [class.active]="subactiveTab === 3" (click)="selectSubTab(3)">Requested Distributors</div>
          <div class="tab" [class.active]="subactiveTab === 4" (click)="selectSubTab(4)">Requested By</div>
          <div class="tab-slider" [style.width.px]="tabSliderWidth" [style.transform]="'translateX(' + tabSliderPosition + 'px)'"></div>
        </div>
        <div *ngIf="subactiveTab === 3" class="tab-content" [@slideInOut]>
          <app-distributor-requests [isRequested]="true" [isAll]="false"></app-distributor-requests>
        </div>
        <div *ngIf="subactiveTab === 4" class="tab-content" [@slideInOut]>
          <app-distributor-requested-by [tab]=4 [isRequestedBy]="true" [isAll]="false"></app-distributor-requested-by>
        </div>
      </div>
      
    </div>
  </mat-card>
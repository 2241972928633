import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeWhile, concatMap } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  specialities!: any[];
  isNew: boolean = false;
  selectTab: number = 0;

  searchJobForm = new FormGroup({
    speciality: new FormControl(''),
    keywords: new FormControl(''),
  });

  constructor(private readonly masterDataService: MasterDataService, private readonly router: Router, private readonly route: ActivatedRoute,private readonly localStorageService: LocalStorageService) {

  }

  ngOnInit(): void {

    this.loadSpecialities();
    this.localStorageService.getUserDetails().subscribe(data => {
      this.isNew = !data || !data.userId;
    });
      
  }

  private loadSpecialities() {
    let pageNumber = 0;
    let pageSize = 100;
    let allSpecialities: any[] = [];
    let morePages = true;

    this.masterDataService.getSpecialities(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allSpecialities = allSpecialities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getSpecialities(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allSpecialities = allSpecialities.concat(data.data);
        this.specialities = allSpecialities;
      });
  }

  onSubmit() {
    const searchJobForm = this.searchJobForm.value;
    if (this.validateFields(searchJobForm.speciality) !== null || this.validateFields(searchJobForm.keywords) !== null) {
      this.router.navigate(['./view-all-jobs'], {
        relativeTo: this.route, queryParams: {
          speciality: this.validateFields(searchJobForm.speciality),
          keywords: this.validateFields(searchJobForm.keywords),
        },
      });
    }
  }

  onViewAllJobs() {
    this.router.navigate(['./view-all-jobs'], { relativeTo: this.route });
  }

  private validateFields(value: any) {
    return (value != undefined && value != null && value.length > 0) ? value : null;
  }

  activeTab(tabNumber: number){
    this.selectTab = tabNumber;
  }
}

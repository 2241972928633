import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit{
  videoUrl!: SafeResourceUrl;
  @Input() data : any;

  constructor(
    private sanitizer: DomSanitizer, 
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
   console.log('profile data', this.data);
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/Rd4wMe9ucvk?si=BRVloh61xU8_er5W');
  }


  images: string[] = [
    "../../../assets/images/icons/image 63.svg", 
    "../../../assets/images/icons/image 57.svg",
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg" ,
    "../../../assets/images/icons/image 60.svg",
  "../../../assets/images/icons/image 57.svg",
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg" ,
    "../../../assets/images/icons/image 60.svg",
    "../../../assets/images/icons/image 63.svg", 
    "../../../assets/images/icons/image 57.svg",
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg" ,
    "../../../assets/images/icons/image 60.svg",
    "../../../assets/images/icons/image 57.svg",
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg", 
    "../../../assets/images/icons/image 60.svg" ,
    "../../../assets/images/icons/image 60.svg",
    "../../../assets/images/icons/image 60.svg",
  ];
 
  showMore = false;

  get visibleImages() {
    return this.showMore ? this.images.slice(0, 6) : this.images.slice(0, 5);
  }

  get hiddenImagesCount() {
    return this.images.length - 6;
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }
}

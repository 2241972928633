 import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import { Observable, map, forkJoin, tap } from 'rxjs';
import * as SockJS from 'sockjs-client';
import { UserDetails } from 'src/app/models/authData';
import { OemStatics } from 'src/app/models/oem-models';
import { FilterPayload, ProductCotnent, ProductResponse } from 'src/app/models/product-model';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { CardLayout, DistributorsSummaryData, EndCustomerSummaryData, OEMSSummaryData } from 'src/app/models/super-admin-model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { ProductsService } from 'src/app/services/products.service';
import { DasboardService } from 'src/app/services/super-admin/dasboard.service';
import { OEM_DETAILS, OEM_ID } from 'src/app/utils/constants';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { PopupVisibityService } from 'src/app/services/shared/popup-visibity.service';
import { Router } from '@angular/router';
import { DistributorService } from 'src/app/services/distributor/distributor.service';




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  distributorsSummaryData!: DistributorsSummaryData;
  metrics: any[] = [];
  distributorsMetrics: any[] = [];
  endCustomerMetrics: any[] = [];
  cardLayout!: Observable<CardLayout>;
  userName!: string;
  userId!: string;
  roleName!: string;
  profilePhoto!: string;
  oemsSummaryData!: OemStatics;
  productEnquiries: any[] = [];
  distributorEnquiries: any[] = [];
  jsonData: any[] = [];
  columns : any[]=[];

  defaultMetrics: any[] = [];
  filter = {
    "continent": "string",
    "country": "string",
    "state": "string",
    "city": "string",
    "startDate": "2024-04-23",
    "endDate": "2024-04-23",
    "role": "string"
  };

  userData!: UserDetails;
  isPopupVisible = false;

  productsData!: ProductCotnent[];
  oemId: any;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private superAdminDashboardService: DasboardService,
    private productService: ProductsService,
    private localStorageService: LocalStorageService,
    private distributorService: DistributorService,
    private oemService: OemService,
    private spinnerService: SpinnerService,
    private sweetAlertService : SweetAlertService,
    private popUpVisibilityService: PopupVisibityService,
    private router: Router
  ) 
  {
    
   }
  
   ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.localStorageService.getUserDetails().subscribe(userDetails => {
      this.userName = userDetails.userName;
      this.userId = userDetails.userId;
      this.oemId = this.localStorageService.getItem(OEM_ID);
      this.isPopupVisible = userDetails.status === 'InComplete' ? true : false;
  
      this.oemService.getStaticData(this.oemId).subscribe({
        next: data => {
          this.oemsSummaryData = data.data;
          this.defaultMetrics = [
            { title: 'Total Distributors', label: 'Total Distributors', property: 'totalDistributors', icon: 'assets/images/total_dstributor.svg', value: this.oemsSummaryData.totalDistributorCount, routeLink: 'dashboard/oem/distributor-network' },
            { title: 'Requested Distributors', label: 'Requested Distributors', property: 'requestedDistributors', icon: 'assets/images/requested_distributors.svg', value: this.oemsSummaryData.totalRequestedDistributorsCount, routeLink: '/dashboard/oem/distributor-network' },
            { title: 'Total Product Enquiries', label: 'Total Customer Request', property: 'publishedDistributors', icon: 'assets/images/published_distributors.svg', value: this.oemsSummaryData.totalCustomerEnquiriesCount, routeLink: '/dashboard/oem/enquiry' },
            { title: 'Total Staff', label: 'Total Staff', property: 'hideDistributors', icon: 'assets/images/hide_distributors.svg', value: this.oemsSummaryData.totalUsersCount, routeLink: '/dashboard/staff-management/staff' },
            { title: 'Total Products', label: 'Total Products', property: 'rejectedDistributors', icon: 'assets/images/total_dstributor.svg', value: this.oemsSummaryData.totalProductsCount, routeLink: '/dashboard/oem/products-management' },
            { title: 'Total Catalogs', label: 'Total Catalogs', property: 'deletedDistributors', icon: 'assets/images/total_dstributor.svg', value: this.oemsSummaryData.totalCatalogsCount, routeLink: '/dashboard/oem/catalogs' },
          ];
          this.spinnerService.hideSpinner();
        },
        error: () => {
          this.spinnerService.hideSpinner(); 
        }
      });
    });
    this.distributorService.getEnquiriesById().subscribe(data => {
      
      this.productEnquiries = data.productEnquiries;
      this.distributorEnquiries = data.distributorEnquiries;
      
      // Combine the data or show separately as per your requirement
      this.jsonData = [...this.productEnquiries, ...this.distributorEnquiries]; // Combining both data
    });
  
    this.cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => matches ? { columns: 1, miniCard: { cols: 1, rows: 1 }, chart: { cols: 1, rows: 1 } } :
        { columns: 3, miniCard: { cols: 1, rows: 1 }, chart: { cols: 1, rows: 1 } })
    );
    this.popUpVisibilityService.setPopupVisibility$(this.isPopupVisible);
    if (localStorage.getItem('isPopupVisible')) {
      this.isPopupVisible = localStorage.getItem('isPopupVisible') === 'true' ? true : false;
    } 
  }
  
  onClosePopUp(): void {
    this.oemService.updateStatus('Requested', 'OEM', this.oemId).subscribe({
      next: data => {
        this.isPopupVisible = false;
        localStorage.setItem('isPopupVisible', 'false');
        this.popUpVisibilityService.setPopupVisibility$(this.isPopupVisible);
        this.sweetAlertService.showSuccess('Thank you', 'Your profile has been successfully submitted for review. We will review your details and get back to you soon.');
      },
      error: () => {
        // this.isPopupVisible = true;
        // localStorage.setItem('isPopupVisible', 'true');
        this.sweetAlertService.showWarning('Oops', 'Something went wrong. Please try again later.');
      }
    })
  
  }

  navigate(value: string) {
    if(value === 'Product Enquiry'){
      this.router.navigate(['/dashboard/oem/enquiry'],{
        state: { enquiryTerm: 'Product Enquiry'}
      });
    }else if(value === 'Distributor Enquiry'){
      this.router.navigate(['/dashboard/oem/enquiry'],{
        state: { enquiryTerm: 'Distributor Enquiry' }
      })
    }
  }
}

import { SendMessagePopupComponent } from 'src/app/components/shared/send-message-popup/send-message-popup.component';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DistributorNetworkManagementService } from 'src/app/services/oem/distributor-network-management.service';
import { DISTRIBUTOR_ID, OEM_ID, USER_ID } from 'src/app/utils/constants';

@Component({
  selector: 'app-distributor-requested-by',
  templateUrl: './distributor-requested-by.component.html',
  styleUrls: ['./distributor-requested-by.component.scss']
})
export class DistributorRequestedByComponent {
  formColumns: string[] = ['country', 'state', 'city', 'verification', 'brands', 'ratings']
  
  @Input() isMyDistributors = false;
  @Input() tab = 1;
  userId: any;
  requests: any;
  oemData: any;
  ids: { id: string, senderId: string }[] = [];
  distributorId: string='';
  body: any= 
  {
      senderId: this.localStorageService.getItem(OEM_ID),
      receiverId: null,
      senderRole: 'OEM',
      receiverRole: 'DISTRIBUTOR',
      status: 'PENDING'
    }
  distributorIds: any[]=[];
  
  constructor(
    private dialog: MatDialog,
    private http : HttpClient, private localStorageService: LocalStorageService,private distributorNetworkService: DistributorNetworkManagementService) {
        this.localStorageService.getUserDetails().subscribe(userDetails => {
          this.userId = userDetails.userId;this.userId = this.localStorageService.getItem('roleName') === 'DISTRIBUTOR' ? this.localStorageService.getItem('distributorId') : 
          (this.localStorageService.getItem('roleName') === 'OEM' ? this.localStorageService.getItem('oemId') : this.localStorageService.getItem(USER_ID));
    });
  }
  
  sendMessage(data : any) {
    const message: any = {
      message: '',
      senderId: data.senderId,
      isGroup: true,
      receiverId: [data.receiverId, data.senderId],
      enqCustomerName: this.oemIds[this.cardIds[data.id]].distributorName,
      enquiryType: 'DISTRIBUTOR'
    };
    const dialog = this.dialog.open(SendMessagePopupComponent, {
      width: '500px',
      data: message
    })
  }

  cancelRequest(id: any) {
    this.distributorNetworkService.cancelRequest(id).subscribe((response) => {
      if(this.oemData.length === 1){
        this.oemData = [];
        return
      }
      this.getRequests();
    });
  }

  ngOnInit(): void {
    if(this.tab === 4){
      this.getRequests();
    }
    if(this.isMyDistributors){
      this.getMyDistributors(this.userId);
    }
  }

  getMyDistributors(id : String) {
    this.distributorNetworkService.getMyDistributors(id).subscribe(data => {
      this.oemData = data.data
    })
  }

  reqOems: { role: string; ids: string[] } = {
    role: '',
    ids: []
  }
  cardIds: any;
  oemsData: any[]=[];
  oemIds: any = {};

  getRequests() {
    this.distributorNetworkService.getSentPendingRequests(this.userId).subscribe((data: any) => {
      (data.data).forEach((d: any) => {
        this.reqOems.role = d.receiverRole;
        this.reqOems.ids.push(d.receiverId)
        if (!this.cardIds) this.cardIds = {};
        this.cardIds[d.id] = d.receiverId
        this.oemsData = data.data;        
      });
      this.distributorNetworkService.requestedDetails(this.reqOems).subscribe((res: any)=>{
        if (res?.data) {
          (res.data).forEach((d: any) => {
          if (!this.oemIds) this.oemIds = {};
          this.oemIds[d.distributorId] = d;
        })
        for (let i = 0; i < this.oemsData.length; i++) {
          const data = this.oemsData[i]
        }
        this.oemData = this.oemsData;
      }
      })
    })
  }

  getDistributorById(id:any){
    this.distributorNetworkService.getDistributorById(id).subscribe(data => {
    });
    
  }

productImage: any="/assets/images/lab equipment.svg";
title: any;
isCategoryCard: any;
subtitleImage: any;
contentItems: any;
hoverText: any;
@Input() isAll: boolean = true;
@Input() isRequested: boolean = false;
@Input() isRequestedBy: boolean = false;

tabSliderPosition = 0; // Initial position of the tab slider
  tabSliderWidth = 0; // Initial width of the tab slider
  activeTab = 0;
  previousTab: number = 0;
  animateContent: boolean = false;

  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();   
    }
  }

  
  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }


}


import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-send-message-popup',
  templateUrl: './send-message-popup.component.html',
  styleUrls: ['./send-message-popup.component.scss']
})

export class SendMessagePopupComponent {

  roleName!: string;

  constructor(
    private localStorageService: LocalStorageService,
    private distributorService: DistributorService,
    public dialogRef: MatDialogRef<SendMessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.roleName = localStorageService.getItem('roleName');
  }

  onSendClick(): void {
    this.distributorService.sendEnquiry(this.data).subscribe((res: any)=>{
      this.dialogRef.close(this.data);
      if(res.statusCode === 'success'){
        Swal.fire({
          icon: 'success',
          title: 'Message sent Successfull.',
          text: `You can see your message in Distributor Enquiry at ${res.data.groupName}`,
        });
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
<div class="leads-container" style="padding: 15px;">
  <div class="d-flex justify-content-between align-items-center">
    <div style="display:flex;">
      <button class="header-btn m-2 add-btn"  mat-raised-button (click)="cancel()">
        <mat-icon>arrow_back</mat-icon> Back
      </button>
      <h2 class="page-heading">Lead Details</h2>
    </div>
  </div>
  <div style="top: 20px; left: 30px">
    <div class="tab-group">
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 0}" (click)="onTabChange(0)">
        <p>Deal Details</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 1}" (click)="onTabChange(1)">
        <p>Point of Contacts</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 2}" (click)="onTabChange(2)">
        <p>Branch Information</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 3}" (click)="onTabChange(3)">
        <p>Documents</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 4}" (click)="onTabChange(4)">
        <p>Product Information</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" *ngIf="isOEMSelected" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 5}" (click)="onTabChange(5)">
        <p>Network</p>
      </div>
      <div class="tab" *ngIf="!isOEMSelected" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 5}" (click)="onTabChange(5)">
        <p>Network</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex ===  6}" (click)="onTabChange( 6 )">
        <p>Financial Information</p>
      </div>
      <div class="mx-2"></div>
      <div class="tab" [class.customer_tab]="isLaptopView" [ngClass]="{'tab-active': selectedTabIndex === 7 }" (click)="onTabChange(7)">
        <p>Remarks</p>
      </div>
    </div>
  </div>

  <app-deal-details *ngIf="selectedTabIndex === 0" [dealDetailsForm]="leadsForm"></app-deal-details>
  <app-points-of-contacts *ngIf="selectedTabIndex === 1" [pointsOfContactsForm]="leadsForm" [isEdit]="isEdit" (branchAdded)="handleBranchAdded($event)"></app-points-of-contacts>
  <app-branch-information *ngIf="selectedTabIndex === 2" [branchInformationForm]="leadsForm" [isEdit]="isEdit" (branchAdded)="handleBranchAdded($event)"></app-branch-information>
  <app-documents *ngIf="selectedTabIndex === 3" [dealDetailsForm]="leadsForm"></app-documents>
  <app-product-information *ngIf="selectedTabIndex === 4" [productInformationForm]="productInfo"></app-product-information>
  <div *ngIf="isOEMSelected">
  <app-network *ngIf="selectedTabIndex === 5 && isOEMSelected" [network]="leadDistributorInfo"></app-network>
</div>
  <div *ngIf="!isOEMSelected">
    <app-lead-oem-network *ngIf="selectedTabIndex === 5" [network]="leadOEMInfo"></app-lead-oem-network>
  </div>
  <app-financial-information *ngIf="selectedTabIndex ===  6 " [financialInformationForm]="leadsForm" [isEdit]="isEdit" (branchAdded)="handleBranchAdded($event)"></app-financial-information>
  <app-remarks *ngIf="selectedTabIndex === 7" [remarksForm]="leadsForm"></app-remarks>

  <div class="popup-action" style="margin-top: 5px;">
    <button *ngIf="selectedTabIndex ===  7 " class="mwe-button mwe-button--primary ps-3 pe-3" (click)="save()">Save</button>
    <button *ngIf="selectedTabIndex < 7 " class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3 " (click)="navigateNext()">Continue</button>&nbsp;&nbsp;
    <button *ngIf="selectedTabIndex > 0" class="mwe-button mwe-button--secondary ps-3 pe-3" (click)="back()">Back</button>
  </div>
</div>

<app-website-header></app-website-header>


<!-- Page Header Section -->
<section class="page-header-section text-center ">

    <div  class="carousel slide">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
            <!-- <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                aria-label="Slide 3"></button> -->
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="slide-1">
                    <div class="overlay"></div>
                </div>
                <img src="/assets/images/careers-page-header.png" class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h1>The Official Medical Jobs</h1>
                    <p>For healthcare professionals seeking reliable employment opportunities, The Official Medical Jobs platform serves as a vital resource. It offers a wide range of job listings across various medical disciplines, ensuring that doctors, nurses, technicians, and administrative staff can find positions that align with their skills and aspirations. By providing verified and current job openings, this platform supports the medical community in maintaining a well-staffed and effective healthcare environment.</p>

                    <div class="search-bar justify-content-center my-4">
                        <form [formGroup]="searchJobForm" class="row" (ngSubmit)='onSubmit()'>
                            <div class="col-5">
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select placeholder="speciality" formControlName="speciality">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let speciality of specialities"
                                            [value]="speciality.specialty">{{
                                            speciality?.specialty }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-5">
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput type="text" formControlName="keywords" placeholder="Enter Keywords">
                                </mat-form-field>
                            </div>
                            <div class="col-2">
                                <button type="submit" class="button button-primary success">
                                    <img src="/assets/images/search-white.svg" alt="angle-arrow-small-right">
                                    Search</button>
                            </div>
                        </form>
                    </div>

                    <div class="job-categories d-flex justify-content-center">
                        <div class="category mx-2">
                            <img src="/assets/images/careers-nurse.svg" alt="nurse_image">
                            <p class="name">Nurses</p>
                            <p class="description">75 Jobs</p>
                        </div>
                        <div class="category mx-2">
                            <img src="/assets/images/careers-doctor.svg" alt="doctor_image">
                            <p class="name">Doctors</p>
                            <p class="description">32 Jobs</p>
                        </div>
                        <div class="category mx-2">
                            <img src="/assets/images/careers-sales.svg" alt="sales_image">
                            <p class="name">Marketing/Sales</p>
                            <p class="description">12 Jobs</p>
                        </div>
                        <div class="category mx-2">
                            <img src="/assets/images/careers-customer-care.svg" alt="customer_care_image">
                            <p class="name">Customer Care</p>
                            <p class="description">10 Jobs</p>
                        </div>
                        <div class="category mx-2">
                            <img src="/assets/images/careers-finance.svg" alt="finance_image">
                            <p class="name">Finance</p>
                            <p class="description">5 Jobs</p>
                        </div>
                        <div class="category mx-2">
                            <img src="/assets/images/careers-admin.svg" alt="admin_image">
                            <p class="name">Admin</p>
                            <p class="description">8 Jobs</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="carousel-item">
                <img src="..." class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Second slide label</h5>
                    <p>Some representative placeholder content for the second slide.</p>
                </div>
            </div>
            <div class="carousel-item">
                <img src="..." class="d-block w-100" alt="...">
                <div class="carousel-caption d-none d-md-block">
                    <h5>Third slide label</h5>
                    <p>Some representative placeholder content for the third slide.</p>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>


</section>


<!-- Page Header Section -->
<section class="welcome-section">
    <h2>Welcome to MedWorldExpo,<br> the Premier Platform for Medical Job Careers!</h2>
    <div class="row">
        <div class="col-7">
            <img src="/assets/images/careers-welcome-container-image.svg" />
        </div>
        <div class="col-5">
            <div class="welcome-info-container">
                <p>At Medworldexpo, we connect top global clients such as big OEMs,
                    distributors, and hospitals with talented professionals in the medical field.
                </p>
                <p>
                    Whether you're a
                    healthcare provider looking to fill a position or a job seeker searching for your next opportunity,
                    Medworldexpo has you covered.
                </p>
                <button type="button" class="button button-primary w-auto" (click)="onViewAllJobs()">
                    <img src="/assets/images/angle-arrow-small-right.svg" alt="angle-arrow-small-right">
                    View All Jobs</button>
            </div>
        </div>
    </div>
</section>

<!-- Why Choose Section -->
<section class="why-choose-section">
    <div class="container">
        <h2>Why Choose MedWorldExpo</h2>
        <div class="row">
            <div class="col-md-3">
                <img src="/assets/images/careers-jobs.svg" alt="careers-jobs">
                <p>Jobs Come to You</p>
            </div>
            <div class="col-md-3">
                <img src="/assets/images/careers-access.svg" alt="careers-access">
                <p>Access to Worldwide Jobs</p>
            </div>
            <div class="col-md-3">
                <img src="/assets/images/careers-hospitals.svg" alt="careers-hospitals">
                <p>From Top Hospitals/Organizations</p>
            </div>
            <div class="col-md-3">
                <img src="/assets/images/careers-quickly-paid.svg" alt="careers-quickly-paid">
                <p>Apply & Get Paid Quickly</p>
            </div>
        </div>
    </div>
</section>

<!-- How It Works Section -->
<section class="how-it-works-section">
    <div class="container">
        <h2>How It Works?</h2>
        <div class="header-container">
            <div>
                <h3 [ngClass]=" selectTab === 0? 'active': ''" (click)="activeTab(0)"><span class="numberCircle"><span>1</span></span> Create Your Profile</h3>
            </div>
            <div>
                <h3 [ngClass]=" selectTab === 1? 'active': ''" (click)="activeTab(1)"><span class="numberCircle"><span>2</span></span> Explore Jobs</h3>
            </div>
            <div>
                <h3 [ngClass]=" selectTab === 2? 'active': ''" (click)="activeTab(2)"><span class="numberCircle"><span>3</span></span> Get A Chance to Work</h3>
            </div>
        </div>


        <div class="row info-conatiner">
            <div class="col-md-8">
                <p>As a new member of the Medworldexpo community, you have the opportunity to showcase your expertise,
                    experience, and availability to top global clients in the medical industry.</p>
                <p>By setting up your talent profile on Medworldexpo, you can position yourself for exciting career
                    opportunities and connections with industry leaders.</p>

                    <a *ngIf="isNew" [routerLink]="['/user/signup']">
                <button type="button" class="button button-primary w-auto">
                    <img src="/assets/images/angle-arrow-small-right.svg" alt="angle-arrow-small-right">
                    Sign up</button></a>
            </div>
            <div class="col-md-4">
                <img src="/assets/images/careers-works-doctor.svg" alt="careers-hospitals">
            </div>
        </div>

    </div>
</section>

<app-website-footer></app-website-footer>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { LandingpageComponent } from './components/shared/landingpage/landingpage.component';
import { CategoryDeatilsComponent } from './components/shared/category-deatils/category-deatils.component';
import { ProductPageComponent } from './components/shared/product-page/product-page.component';
import { WebinarComponent } from './components/shared/website/webinar/webinar.component';
import { AboutUsComponent } from './components/shared/about-us/about-us.component';
import { ContactUsComponent } from './components/shared/contact-us/contact-us.component';
import { WebinarViewComponent } from './components/shared/website/webinar-view/webinar-view.component';
import { RegisterWebinarComponent } from './components/shared/website/register-webinar/register-webinar.component';
import { SubscriptionDetailsComponent } from './components/shared/subscription-details/subscription-details.component';

const routes: Routes = [

  {
    path: 'dashboard',
    canActivate : [],
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'distributor',
    canActivate : [],
    loadChildren: () => import('./components/dashboard/distributor/distributor.module').then(m => m.DistributorModule)
  },
  
  {
    path: '',
    loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./components/end-customer/end-customer.module').then(m => m.EndCustomerModule)
  },
    {
    path:'category',
    component: CategoryDeatilsComponent
  },
  {
    path:'aboutus',
    component: AboutUsComponent
  },
  {
    path:'contactus',
    component: ContactUsComponent
  },
  {
    path:'subscriptions',
    component: SubscriptionDetailsComponent
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }



import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEMUrls } from 'src/app/utils/apiUrls';

@Injectable({
  providedIn: 'root'
})

export class DistributorNetworkManagementService {
  private sendRequestUrl = OEMUrls.sendRequest;
  private sentPendingRequestUrl = OEMUrls.sentPendingRequest;
  private receivedPendingRequestUrl = OEMUrls.receivedPendingRequest;
  private getDistributorByDistributorIdUrl = OEMUrls.oemDistributorById;
  private respondRequestUrl = OEMUrls.respondRequest;
  private allDistributorsUrl = OEMUrls.distributor; 
  private myDistributorsUrl = OEMUrls.myDistributors;

  constructor(private http: HttpClient) { }

  sendRequestToDistributor(body: any): Observable<any> {
   return this.http.post<any>(`${this.sendRequestUrl}`, body).pipe();
  }

  getDistributorById(id: string): Observable<any> {
    return this.http.get<any>(`${this.getDistributorByDistributorIdUrl}${id}`).pipe();
  }

  getAllDistributors(): Observable<any> {
    return this.http.get<any>(`${this.allDistributorsUrl}`).pipe();
  }

  getMyDistributors(id : String): Observable<any> {
    return this.http.get<any>(`${this.myDistributorsUrl}${id}`).pipe();
  }

  getSentPendingRequests(id ?: string):Observable<any>{
    return this.http.get<any>(`${this.sentPendingRequestUrl}${id}`).pipe();
  }

  getReceivedPendingRequests(id ?: string):Observable<any>{
    return this.http.get<any>(`${this.receivedPendingRequestUrl}${id}`).pipe();
  }

  acceptRequest(id: string): Observable<any> {
    return this.http.post<any>(`${this.respondRequestUrl}/respond/${id}?status=ACCEPTED&remarks=`, null).pipe();
  }

  rejectRequest(id: string, remark: any): Observable<any> {
    console.log('body is', remark);
    
    return this.http.post<any>(`${this.respondRequestUrl}/respond/${id}?status=REJECTED&remarks=${remark}`, null).pipe();
  }

  cancelRequest(id: string): Observable<any> {
    return this.http.post<any>(`${this.respondRequestUrl}/respond/${id}?status=CANCELLED&remarks=`, null).pipe();
  }

  requestedDetails(data: any){
    return this.http.post<any>(`${OEMUrls.respondRequest}/requested-details`, data);
  }
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, concatMap, Observable } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { USER_ID } from 'src/app/utils/constants';
import Swal from 'sweetalert2';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-view-all-jobs',
  templateUrl: './view-all-jobs.component.html',
  styleUrls: ['./view-all-jobs.component.scss']
})
export class ViewAllJobsComponent implements OnInit {

  applicantId: any;
  salaryHide: boolean = false;
  companyDetailsHide: boolean = false;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  data: any[] = [];
  showNoRecordsFounds: boolean = true;
  filterObject: any;
  countries!: any[];
  jobTypes!: any[];
  jobSkills!: any[];
  specialities!: any[];
  savedJobs = new Set<number>();
  isJobSave = true;

  obs!: Observable<any>;

  pageSize = 6;
  currentPage = 0;
  totalData = this.data.length;
  dataSource = new MatTableDataSource<any[]>;
  paginator!: MatPaginator;

  selectedSortOption: string = '';
  currentSortDirection: string = 'asc';

  jobFilterForm: FormGroup = new FormGroup({
    specialities: new FormControl(null),
    keywords: new FormControl(null),
    countries: new FormControl(null),
    salaryFrom: new FormControl(null),
    salaryTo: new FormControl(null),
    experience: new FormControl(null),
    jobTypes: new FormControl(null),
    postedDate: new FormControl(null),
    skills: new FormControl(null),
    showOnlyActiveJobPosts: new FormControl(true)
  });
  userId!: string;
  roleName:boolean = false;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  constructor(private readonly masterDataService: MasterDataService,
    private readonly careerService: CareerService, private readonly endCustomerService: EndCustomerService,
    private readonly localStorageService: LocalStorageService,
    private snackbarService: SnackbarService, private route: ActivatedRoute, private readonly router: Router, private swalService: SweetAlertService) {
    this.route.queryParams.subscribe(params => {
      if (params !== undefined && params !== null && Object.entries(params).length > 0) {
        if (this.checkAndConvertArray(params['speciality']) !== null) {
          this.jobFilterForm.get('specialities')?.setValue([params['speciality']]);
        }
        if (this.checkAndConvertArray(params['keywords']) !== null) {
          let keywordsCurrentValue = this.jobFilterForm.get('keywords')?.value;
          const keywordsNewValue = (keywordsCurrentValue !== undefined && keywordsCurrentValue !== null) ? [...keywordsCurrentValue, params['keywords']] : [params['keywords']];
          this.jobFilterForm.get('keywords')?.setValue(keywordsNewValue);
        }
      }
      this.getAllJobPostByFilter();
    });
  }

  ngOnInit(): void {
    this.loadCountries('');
    this.loadJobTypes();
    this.loadJobSkills();
    this.loadSpecialities();
    this.checkForFormValueChange();
    this.localStorageService.getUserDetails().subscribe(data => {
      this.userId = data?.userId;
      this.getEndCustomerByUserId(data?.userId);
    });
    const roleName = localStorage.getItem('roleName') ?? '';
    this.roleName = roleName === 'USER';
  }

  checkForFormValueChange() {
    this.jobFilterForm.valueChanges.subscribe(() => this.getAllJobPostByFilter());
  }

  private loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  private loadJobTypes() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobTypes: any[] = [];
    let morePages = true;

    this.masterDataService.getJobTypes(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobTypes = allJobTypes.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobTypes(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobTypes = allJobTypes.concat(data.data);
        this.jobTypes = allJobTypes;
      });
  }

  private loadJobSkills() {
    let pageNumber = 0;
    let pageSize = 100;
    let allJobSkills: any[] = [];
    let morePages = true;

    this.masterDataService.getJobSkills(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allJobSkills = allJobSkills.concat(response.data);
          pageNumber++;
          return this.masterDataService.getJobSkills(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allJobSkills = allJobSkills.concat(data.data);
        this.jobSkills = allJobSkills;
      });
  }

  private loadSpecialities() {
    let pageNumber = 0;
    let pageSize = 100;
    let allSpecialities: any[] = [];
    let morePages = true;

    this.masterDataService.getSpecialities(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allSpecialities = allSpecialities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getSpecialities(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allSpecialities = allSpecialities.concat(data.data);
        this.specialities = allSpecialities;
      });
  }

  private getAllJobPostByFilter() {
    this.data = [];
    const jobPostFilter = this.jobFilterForm.value;
    jobPostFilter.countries = this.checkAndConvertArray(jobPostFilter.countries);
    jobPostFilter.skills = this.checkAndConvertArray(jobPostFilter.skills);
    jobPostFilter.specialities = this.checkAndConvertArray(jobPostFilter.specialities);
    jobPostFilter.jobTypes = this.checkAndConvertArray(jobPostFilter.jobTypes);
    jobPostFilter.keywords = this.checkAndConvertArray(jobPostFilter.keywords);
    console.log(jobPostFilter , 'jobPostFilter');
    

    this.careerService.executePostRequest(MweConstants.getJobPostFilterUrl, JSON.parse(JSON.stringify(jobPostFilter))).subscribe({
      next: (data) => {
          
        if (data && data?.data && data.data.length > 0) {
          this.data = data.data;
          this.dataSource = new MatTableDataSource<any[]>(this.data);
          this.dataSource.paginator = this.paginator;
          this.obs = this.dataSource.connect();
          this.updateCardData();
          this.showNoRecordsFounds = false;
        } else {
          this.showNoRecordsFounds = true;
        }
      },error: (error) => {
        console.log(error); // Add this line
        this.snackbarService.openSnackBar(error.error?.error || 'An unexpected error occurred', '');
        this.showNoRecordsFounds = true;
      }
    });
  }

  getEndCustomerByUserId(userId: any) {
    if (userId) {
    this.endCustomerService.executeGetRequestURL(MweConstants.getCustomerByUserId + userId, null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          this.applicantId = response?.data.id;
          this.findSavedJobFilter();
        }
      }, error: (error: any) => {
      }
    });
  }
  }

  findSavedJobFilter(): void{
    const jobApplicationFilter = { applicantId: this.applicantId, isSaved: this.isJobSave };
    this.careerService.executePostRequest(MweConstants.getJobPostUrl.concat("/applicant/jobs/filter"), JSON.parse(JSON.stringify(jobApplicationFilter))).subscribe({
      next: (response) => {
        // Create a Set of jobPostIds from response.data for quick lookups
        const savedJobIds = new Set(response.data.map((savedData: any) => savedData.jobPostId));

        // Iterate through this.data and add matching job ids to savedJobs
        this.data.forEach((data) => {
          if (savedJobIds.has(data.id)) {
            this.savedJobs.add(data.id);
          }
        });
      }, error: (error) => {
        console.log("error")
      }
    });
  }

  changePage(pageIndex: number) {
    this.currentPage = pageIndex;
    this.updateCardData();
  }

  updateCardData() {
    this.totalData = this.data?.length;
    if (this.paginator) {
      this.paginator.pageIndex = this.currentPage;
      this.dataSource.paginator = this.paginator;
    }
  }

  getTotalPages(): number {
    return Math.ceil(this.totalData / this.pageSize);
  }

  getPageNumbers(): (number | string)[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPage;
    const pageNumbers: (number | string)[] = [];
    // Always show the first page
    pageNumbers.push(0);

    // Show ellipsis if there are more than 3 pages before the current page
    if (currentPage > 2) {
      pageNumbers.push('...');
    }

    // Show current page and its neighbors
    for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    // Show ellipsis if there are more than 3 pages after the current page
    if (currentPage < totalPages - 3) {
      pageNumbers.push('...');
    }

    // Always show the last page if total pages is greater than 1
    if (totalPages > 1) {
      pageNumbers.push(totalPages - 1);
    }

    return pageNumbers;
  }

  private checkAndConvertArray(value: any) {
    return (value !== undefined && value !== null && value.length > 0) ? value : null;
  }

  resetFilters() {
    this.jobFilterForm.reset();
    this.getAllJobPostByFilter();
  }

  getRangeLabel(page: number, pageSize: number, length: number) {
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex}`;
  }

  onReadMore(jobPostId: any) {
    this.navigateToOtherRoute(jobPostId);
  }

  onApplyJob(jobPostId: any) {
    if(this.roleName){
      if(!this.userId) {
        this.swalService.showError('','Please login to apply for job');
        return;
      }
      this.navigateToOtherRoute(jobPostId, false);

    }
    else{
      this.swalService.showError('','Access Denied');
    }
   
  }

  private navigateToOtherRoute(jobPostId: any, isReadMore = true) {
    this.careerService.setJobId(jobPostId);
    this.router.navigate([isReadMore ? '../job-details' : '../job-apply'], { relativeTo: this.route });
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      let keywordsCurrentValue = this.jobFilterForm.get('keywords')?.value;
      const keywordsNewValue = (keywordsCurrentValue !== undefined && keywordsCurrentValue !== null) ? [...keywordsCurrentValue, value] : [value];
      this.jobFilterForm.get('keywords')?.setValue(keywordsNewValue);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(keyword: string): void {
    const keywordsValue = this.jobFilterForm.get('keywords')?.value;
    const index = keywordsValue.indexOf(keyword);
    if (index >= 0) {
      keywordsValue.splice(index, 1);
      this.getAllJobPostByFilter();
    }
  }

  getPostedDate(applicationStartDate: any) {
    const startDate: any = new Date(applicationStartDate);
    const todayDate: any = new Date();
    const diffTime = Math.abs(todayDate - startDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  onSaveJob(jobPost: any) {
    const body = { jobApplicantKey: { jobPostId: jobPost.id, applicantId: this.applicantId } };
    if(!this.userId) {
      this.swalService.showError('','Please login to save job');
      return;
    }
    this.careerService.executePostRequest(MweConstants.getJobPostUrl.concat("/applicant/jobs"), body).subscribe({
      next: () => {
        if (!this.savedJobs.has(jobPost.id)) {
          this.savedJobs.add(jobPost.id);
        }
        Swal.fire('', 'Job has been saved successfully !!', 'success');
      }, error: (error: any) => {
        console.log(error);
        
        this.snackbarService.openSnackBar(error.error.message.includes("Duplicate Record") ? 'Job has already been saved' : 'There is some unknown issue. Please try again later', '');
      }
    });
  }
  isJobSaved(jobPost: any): boolean {
    return this.savedJobs.has(jobPost.id);
  }

  private getJobPostById() {
    //this.jobPost = {};
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat('/', this.careerService.getJobId()), null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          const jobPostResponse = response?.data;
          this.salaryHide = jobPostResponse.hideSalary;
   }
      }, error: (error: any) => {
      }
    });
  }
  onSortChange(sortOption: string, direction: string) {
    if (!sortOption) return;
    const normalizeString = (str: string) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
    switch (sortOption) {
      case 'alphabetical':
        this.data.sort((a, b) =>
          direction === 'asc'
            ? normalizeString(a.title).localeCompare(normalizeString(b.title))
            : normalizeString(b.title).localeCompare(normalizeString(a.title))
        );
        break;
      case 'date':
        this.data.sort((a, b) =>
          direction === 'asc'
            ? new Date(a.applicationStartDate).getTime() - new Date(b.applicationStartDate).getTime()
            : new Date(b.applicationStartDate).getTime() - new Date(a.applicationStartDate).getTime()
        );
        break;
      case 'createdOn':
        this.data.sort((a, b) =>
          direction === 'asc'
            ? new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
            : new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
        );
        break;
      default:
        break;
    }
    this.dataSource = new MatTableDataSource<any[]>(this.data);
    this.dataSource.paginator = this.paginator; 
    this.obs = this.dataSource.connect(); 
  }
  onSortOptionSelected(sortOption: string) {
    this.selectedSortOption = sortOption;
    this.currentSortDirection = 'asc';
    this.onSortChange(sortOption, 'asc'); 
  }

  toggleSortDirection() {
    this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
    if (this.selectedSortOption) {
      this.onSortChange(this.selectedSortOption, this.currentSortDirection);
    }

  }
  
}
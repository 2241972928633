import { Component } from '@angular/core';

@Component({
  selector: 'app-company-profile-popup',
  templateUrl: './company-profile-popup.component.html',
  styleUrls: ['./company-profile-popup.component.scss']
})
export class CompanyProfilePopupComponent {

  constructor() { }


  
}


    <div class="modal-popup" mat-dialog-content>
      <div *ngIf="data.type === 'image'">
        <img [src]="data.url" alt="file" class="file-image"/>
      </div>
      <div *ngIf="data.type === 'pdf'">
        <!-- <pdf-viewer [src]="data.url" [render-text]="true" style="display: block;"></pdf-viewer> -->
        <iframe [src]="data.url | safe"></iframe>
      </div>
    </div>

  




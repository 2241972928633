import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-company-attachments',
  templateUrl: './company-attachments.component.html',
  styleUrls: ['./company-attachments.component.scss']
})
export class CompanyAttachmentsComponent {

  @Input() attachmentsForm!: FormGroup;

  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  showEditForm: boolean = false;
  showVideoForm: boolean = false;
  data: any[] = [];
  
  videoUrls!: FormArray;  // Define videoUrls as FormArray
  newVideoUrl: string = '';

  constructor(private endCustomerService: EndCustomerService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.data = [...this.attachmentsForm.get('photos')?.value];
    this.videoUrls = this.attachmentsForm.get('videos') as FormArray;  // Cast to FormArray
  }

  get photos() {
    return this.attachmentsForm.get('photos') as FormArray;
  }

  uploadPhoto(event: any) {
    let uploadData = new FormData();
    uploadData.append('file', event.target.files[0]);
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    uploadData.append('destinationKey', randomNumber + '');

    this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile, uploadData).subscribe(response => {
      if (response ) {
        
        this.photos.push(new FormControl(response.data));  // Push the URL (string) into the FormArray
        this.data = [...this.photos.value];  // Update the local data array to reflect the change
        
      }
    }, error => {
      console.error('Error uploading file:', error);
    });
  }

  clearPhoto(i: number) {
    this.photos.removeAt(i);
    
    this.data = [...this.photos.value];  // Update the local data array after removal
  }

  addPhoto() {
    this.showEditForm = true;
  }
  cancel() {
    this.showEditForm = false;
  }
  cancel2(){
    this.showVideoForm = false;
  }
  addVideos() {
    this.showVideoForm = true;
  }
  getUrls(url: string):void{
    window.open(url,'_blank')
  }

  refresh() {
    this.showEditForm = false;
    this.data = [...this.photos.value];
  }

  addVideo() {
    if (this.newVideoUrl) {
      this.videoUrls.push(this.fb.control(this.newVideoUrl));  // Add new FormControl to FormArray
      this.newVideoUrl = ''; // Reset input field
    }
  }

  removeVideo(index: number) {
    this.videoUrls.removeAt(index);

  }
  getVideoFormControl(index: number): FormControl {
    return this.videoUrls.at(index) as FormControl;
  }

 
}

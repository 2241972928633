import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})
export class HospitalsComponent implements OnInit {

  showNoRecordsFounds: boolean = false;
  itemsPerPage = 15;
  hoveredIndex: any;
  hospitals: any[] = [];
  originalHospitalsData: any[] = []
  paginatedItems: { name: string, logo: string }[] = [];

  constructor(private oemService: OemService, private router: Router) { }


  ngOnInit(): void {
    this.getAllHospitalsList()
  }

  viewHospitalsProfile(hospital:any):void{
    this.router.navigate([`hospitals/hospital-portfolio/${hospital.id}`])
  }

  getAllHospitalsList() :void{
    //Temporary Data
    this.oemService.getBrandsList().subscribe({
      next: (response)=>{
        this.hospitals = response.data.filter((data:any, index: number)=> index == 2)
        console.log(this.hospitals)

        this.originalHospitalsData = this.hospitals
        this.paginatedItems = this.hospitals.slice(0, this.itemsPerPage)
      }
    })
  }


  onPageChanged(paginatedData: any[]) {
    this.paginatedItems = paginatedData
  }

  filteredData = this.hospitals

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.hospitals = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
    console.log('Received filtered data:', filtered);
  }

}

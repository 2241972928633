import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Webinar } from 'src/app/models/oem-models';
import { OEMUrls } from 'src/app/utils/apiUrls';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable({
  providedIn: 'root'
})
export class WebinarService {

  constructor(private http: HttpClient) { }

  public createWebinar(data: any): Observable<any> {         
    return this.http.post<any>(`${OEMUrls.webinar}/save`, data);
  }

  public updateWebinar(webinarId: string, data: any): Observable<any> {
    return this.http.put<any>(`${OEMUrls.webinar}/edit/${webinarId}`, data);
  }

  public getAllWebinars(): Observable<any>{
    return this.http.get<any>(`${OEMUrls.webinar}/all`);
  }

  public getWebinar(oemId: string): Observable<any> {
    return this.http.get<any>(OEMUrls.webinar + `/all/${oemId}`);
  }

  public deleteWebinar(webinarId: string): Observable<any> {
    return this.http.delete<any>(`${OEMUrls.webinar}/${webinarId}`);
  }

  public speciality(): Observable<any> {
    return this.http.get<any>(`${OEMUrls.webinar}/specialities`);
  }

  public getSpeciality(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${OEMUrls.webinarSpeciality(pageNumber, pageSize)}`);
  }

  public getWebinarById(id: any): Observable<any>{
    return this.http.get<any>(`${OEMUrls.webinar}/${id}`);
  }

  public getSpeakers(): Observable<any>{
    return this.http.get<any>(`${OEMUrls.webinar}/speaker-names`);
  }

  public getSponsors(): Observable<any>{
    return this.http.get<any>(`${OEMUrls.webinar}/organizer-names`);
  }

  public filters(filteredData: any): Observable<any>{
    return this.http.post<any>(`${OEMUrls.webinar}/filters`, filteredData);
  }

  public register(regData: any): Observable<any>{
    return this.http.post<any>(`${OEMUrls.webinar}/customers/save`, regData);
  }

  public getRegWebinars(id: any){
    return this.http.get<any>(`${OEMUrls.webinar}/customer/${id}`);
  }

 
  
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-certification-profile',
  templateUrl: './certification-profile.component.html',
  styleUrls: ['./certification-profile.component.scss']
})
export class CertificationProfileComponent implements OnInit{

  certificateColumns: ListColumn[] = [
    { name: 'Name', property: 'name', visible: true, isModelProperty: true ,isText: true},
  
    { name: 'Certificate', property: 'fileName', visible: true, isModelProperty: true ,isFileDownload: true},
  ] as ListColumn[];
  @Input() showPersonalInfo:boolean=false;
  @Input() certificationsForm!:FormGroup;
  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyCertificationForm:any;
  data:any=[];
  certificateIcon:string="assets/images/Certifications.svg";
  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify( this.certificationsForm.get('certifications')?.value));
   }
  

  constructor(private fb:FormBuilder,private endCustomerService:EndCustomerService){}

  get certifications() {
    return this.certificationsForm.controls["certifications"] as FormArray;
  } 
  
  addCertificationsAndLicense(){
    this.copyCertificationForm=JSON.parse(JSON.stringify(this.certificationsForm.value));
    this.certifications.push( this.fb.group({
      "name"  :new FormControl(null),
     
         "certificate":new FormControl(null),
         fileName:new FormControl(null)
     }),)
     this.showEditForm=true;
  }

  editCertificationsAndLicense(){
    this.copyCertificationForm=JSON.parse(JSON.stringify(this.certificationsForm.value));
    this.showEditForm=true;
    
  }
  update(){
    this.updateCertifications();
    this.certifications.markAllAsTouched();
    if(this.certifications.invalid){
      return;
    }
    this.onUpdate.emit(50);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify( this.certificationsForm.get('certifications')?.value));
  }

  updateCertifications(){
    for(let i=0;i<this.certifications.controls.length;i++){
      if(!(this.certifications.at(i).get('name')?.value==null || this.certifications.at(i).get('name')?.value=='')
        ||
        this.certifications.at(i).get('certificate')?.value!=null  ){
          
        this.certifications.at(i).get('name')?.setValidators([Validators.required]);
        this.certifications.at(i).get('certificate')?.setValidators([Validators.required]);

      }else{
        this.certifications.at(i).get('name')?.clearValidators();
        this.certifications.at(i).get('certificate')?.clearValidators();
      
      }
      this.certifications.at(i).get('name')?.updateValueAndValidity();
      this.certifications.at(i).get('certificate')?.updateValueAndValidity();
    }
  }

  cancel(){
    
    (this.certificationsForm.get('certifications')as FormArray).clear();
     if(this.copyCertificationForm?.certifications && this.copyCertificationForm.certifications.length>0){
          for(let i=0;i<this.copyCertificationForm.certifications.length;i++){
            (this.certificationsForm.get('certifications')as FormArray).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "name"  :new FormControl(null),
              "certificate":new FormControl(null),
              fileName:new FormControl(null)
            }));
          }
          (this.certificationsForm.get('certifications')as FormArray).patchValue(this.copyCertificationForm.certifications);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.certificationsForm.get('certifications')?.value));
   
  
  
  }

  uploadCertificate(event:any,i:number){
    let uploadData = new FormData();
     uploadData.append('file', event.target.files[0]);
     let randomNumber = Math.floor(Math.random() * 10) + 1
     uploadData.append('destinationKey', randomNumber+'');
 
     this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
       if(data && data?.data){
        this.certifications.at(i).get('certificate')?.patchValue(data.data);
        this.certifications.at(i).get('fileName')?.patchValue(event.target.files[0].name);
       }
     },error=>{
 
     })
  }

  deleteCertificationsAndLicense(i:number){
    this.certifications.removeAt(i);
  }

  clearCertificate(i:number){
    this.certifications.at(i).get('certificate')?.reset();
    this.certifications.at(i).get('fileName')?.reset();
  }

  selectedAction(event:any){
    switch (event.action) {
      case MweConstants.fileDownloadAction:
        window.open(event.row?.certificate,'_blank');
        break;
    
      default:
        break;
    }
  }
}

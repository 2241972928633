import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ACCESS_TOKEN, ACCOUNT_NAME, PREFERENCES, ROLE_NAME } from '../utils/constants';
import { PermissionService } from '../services/permission.service';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class authGuard implements CanActivate {

  constructor(private router: Router,private permissionService:PermissionService,private authService:AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentRouteUrl = state.url;
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const expectedRoles = route.data['expectedRoles'] as Array<string>;
    const currentRole:any= localStorage.getItem(ROLE_NAME);
    if (!!accessToken &&expectedRoles.includes(currentRole) ) {
      if(localStorage.getItem(ACCOUNT_NAME)==='Staff'){
        if(this.permissionService.hasStaffAccesss(currentRouteUrl.substring(1))){
          return true;
        }else{
          // Redirect to login page and return false to prevent access to the requested route
        //  this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
           this.unAuthorized(currentRole);
          return false ;
        }
      
      }else{
        return true; // User is authenticated
      }
      
    } else {
      // Redirect to login page and return false to prevent access to the requested route
  //    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
         this.unAuthorized(currentRole);
      return false;
    }
  }

  unAuthorized(currentRole:any){
    Swal.fire({
      title: 'Unauthorized!',
      text: 'You do not have permission to access this page.',
      allowOutsideClick: false,
      confirmButtonText: 'Ok',
      confirmButtonColor : '#0f74a9',

    }).then(result=>{
      if (this.authService.isAuthenticated()) {
        console.log("already logged in");
        const accountName=localStorage.getItem(ACCOUNT_NAME);
        if(accountName=='Staff'){
          const preferences:any=localStorage.getItem(PREFERENCES)
          this.authService.navigateStaff(preferences ,currentRole)
        }else{
          this.authService.navigateByRole(this.authService.getRoleName());
        }
      }else{
        console.log("not logged in");
        this.router.navigate(['/login']/* , { queryParams: { returnUrl: state.url } } */);
      }
    })
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { StatusType } from 'src/app/enums/oem-management-enum';

@Component({
  selector: 'app-add-oem-remarks-popup',
  templateUrl: './add-oem-remarks-popup.component.html',
  styleUrls: ['./add-oem-remarks-popup.component.scss']
})
export class AddOemRemarksPopupComponent {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '214px',
    minHeight: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    toolbarHiddenButtons:[
      [
        'insertVideo',
        'insertImage',
        'toggleEditorMode'
      ]
    ],
  };
  
  remarkForm!:FormGroup;
  isAddMode!: boolean;
  statusTypes: StatusType[] = [StatusType.REQUESTED, StatusType.PUBLISH, StatusType.HIDE, StatusType.INCOMPLETE];
  constructor( private dialogRef: MatDialogRef<AddOemRemarksPopupComponent>, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.remarkForm = this.formBuilder.group({
      remarks: [''],
      status: ['']
    });
    this.isAddMode = this.data?.isAddMode
    this.remarkForm.get('remarks')?.setValue(this.data.remarks, { emitEvent: false });
    this.remarkForm.get('status')?.setValue(this.data.status, { emitEvent: false });
    console.log(data)
   }

   
  

   
  
  
  public addRemarksDescription():void{
   const remarks = this.remarkForm.get('remarks')?.value
   const status = this.remarkForm.get('status')?.value
   console.log(remarks, status)
    this.dialogRef.close({remarks,status});
  }
  

}

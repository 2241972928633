<div *ngFor="let fileField of fileFields">
  <label class="med-world-basic-font med-world-basic-font__medium secondary_black_color">
    {{ fileField.label }}
  </label>
  <div class="card p-3 mb-3">
    <input 
      class="d-none" 
      type="file" 
      id="{{ fileField.controlName }}" 
      accept=".png,.jpg,.jpeg,.svg" 
      (change)="selectFile($event, fileField.controlName)"
      [multiple]="fileField.controlName === 'otherCertificates'"
      #otherCertificatesInput
    />
    <label *ngIf="!dealDetailsForm.get('lead.' + fileField.controlName)?.value" 
           class="d-flex justify-content-center text" 
           for="{{ fileField.controlName }}" 
           mat-raised-button>
      <img alt="" src="./../../assets/images/photo-add.svg">
      Drag files here to upload (or) Select Files
    </label>
    <div *ngIf="fileField.controlName !== 'otherCertificates' && dealDetailsForm.get('lead.' + fileField.controlName)?.value" 
         class="d-flex align-items-center">
      <div class="input-group">
        <div class="d-flex align-items-center ">
          <input class="form-control border-0" type="text" 
                 [value]="getFileName(dealDetailsForm.get('lead.' + fileField.controlName)?.value)" 
                 readonly >
          <button class="btn border-0" mat-icon-button (click)="clearFile(fileField.controlName)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="fileField.controlName === 'otherCertificates'">
      <div
      class="file-list"
      style="max-height: 100px; overflow-y: auto;"
    >
      <!-- Show the drag-and-drop prompt only if there are no files -->
      <label *ngIf="!dealDetailsForm.get('lead.otherCertificates')?.value?.length" 
             class="d-flex justify-content-center text" 
             for="{{ fileField.controlName }}" 
             mat-raised-button>
        <img alt="" src="./../../assets/images/photo-add.svg">
        Drag files here to upload (or) Select Files
      </label>
    
      <!-- List the files if present -->
     
      <div *ngFor="let file of dealDetailsForm.get('lead.otherCertificates')?.value; let i = index" 
           class="d-flex align-items-center  mb-2">
        <input style="width: fit-content;" class="form-control border-0" type="text" [value]="getFileName(file)" readonly>
        <button class="btn border-0" (click)="clearFile('otherCertificates', i)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      </div>
     
    
      <!-- Show the add more files button if there are already files -->
      <button *ngIf="dealDetailsForm.get('lead.otherCertificates')?.value?.length" 
              style="border: none; background: none;" 
              (click)="triggerOtherCertificatesInput()">
        <img class="edit-profile mx-1" src="../../assets/images/add.svg" alt="Add more certificates">
      </button>
    </div>
    
    
    
  </div>
</div>
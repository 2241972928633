<app-website-header></app-website-header>

<div class="header">
    <img src="../../../../../assets/images/about-us-header.svg" alt="About Us Header" class="header-image" />
    <div class="header-name">
        <h1 class="header-text">About Us</h1>
    </div>
</div>

<div class="container">
    <!-- Intro Section -->
    <section class="intro">
        <div class="text-content">
            <h2>Step into the Future with Medworld Expo</h2>
            <p>
                Medworld Expo is redefining medical equipment expositions with an innovative digital platform. Our mission is to overcome the limitations of traditional expos, creating a comprehensive and accessible solution for the global medical community.
            </p>
        </div>
        <div class="image-content">
            <img src="assets/images/a1.jfif" alt="Future with Medworld Expo" />
        </div>
    </section>

    <!-- Revolutionizing Medical Expos Section -->
    <section class="section">
        <div class="image-content">
            <img src="assets/images/careers/a2.jfif" alt="Revolutionizing Medical Expos" />
        </div>

        <div class="text-content">
            <h2>Revolutionizing Medical Expos</h2>
            <p>
                We transcend traditional expos by addressing prohibitive costs, restricted geographical reach, and complex logistics. Our platform empowers manufacturers, distributors, and healthcare providers to fully leverage the benefits of a global digital expo.
            </p>
        </div>
       
    </section>

    <!-- Our Platform Section -->
    <section class="section">
        
        <div class="text-content">
            <h2>Our Platform</h2>
            <p>
                We offer tools that streamline product showcasing, enhance networking, and facilitate market access for manufacturers, distributors, and healthcare providers alike. Our platform is built to serve the diverse needs of the medical industry.
            </p>
        </div>
        <div class="image-content">
            <img src="assets/images/careers/a3.jfif" alt="Our Platform" />
        </div>
        
    </section>

    <!-- Empowering Innovation Section -->
    <section class="section">
        <div class="image-content">
            <img src="assets/images/careers/a4.jfif" alt="Empowering Innovation" class="full-width-image" />
        </div>
        <div class="text-content">
        <h2>Empowering Innovation</h2>
    
        <p>
            Medworld Expo ensures that cutting-edge medical equipment and services are accessible to a global audience by removing geographical and financial barriers.
        </p>
    </div>
       
    </section>

    <!-- Fostering Collaboration Section -->
    <section class="section">
        <div class="text-content">
            <h2>Fostering Collaboration</h2>
            <p>
                Networking and collaboration are at the core of Medworld Expo. Our platform fosters partnerships among manufacturers, distributors, and healthcare providers that drive industry growth and innovation.
            </p>
        </div>
        <div class="image-content">
            <img src="assets/images/careers/a5.jfif" alt="Fostering Collaboration" />
        </div>
    </section>

    <!-- Vision and Mission Section -->
    <section class="vision-mission">
        <div class="vision">
            <h2>Our Vision</h2>
            <p>
                We aspire to be the global beacon for medical innovation, setting the standard for excellence in medical trade by catalyzing progress through collaboration and knowledge.
            </p>
        </div>
        <div class="mission">
            <h2>Our Mission</h2>
            <p>
                To revolutionize the healthcare industry by providing a premier digital platform that empowers manufacturers, distributors, and healthcare providers with cutting-edge tools for growth and connectivity.
            </p>
        </div>
    </section>

    <!-- What We Offer Section -->
    <section class="sections offerings">
        <p class="section-title">What We Offer</p>
        <div class="offerings-grid">
            <div class="offering-item">
                <div class="number">1</div>
                <p class="offering">Democratized Access</p>
                <p>Access to medical equipment and services.</p>
            </div>
            <div class="offering-item">
                <div class="number">2</div>
                <p class="offering">Enhanced Networking</p>
                <p>Collaboration and partnerships for growth.</p>
            </div>
            <div class="offering-item">
                <div class="number">3</div>
                <p class="offering">Product Showcasing</p>
                <p>Streamlined showcasing for OEMs and distributors.</p>
            </div>
            <div class="offering-item">
                <div class="number">4</div>
                <p class="offering">Global Market Reach</p>
                <p>Expand your business to a global market.</p>
            </div>
            <div class="offering-item">
                <div class="number">5</div>
                <p class="offering">Improved User Experience</p>
                <p>Advanced search features for better navigation.</p>
            </div>
            <div class="offering-item">
                <div class="number">6</div>
                <p class="offering">Educational Programs</p>
                <p>Webinars and CME programs for professional development.</p>
            </div>
            <div class="offering-item">
                <div class="number">7</div>
                <p class="offering">Community Engagement</p>
                <p>Engage in forums and discussion boards.</p>
            </div>
            <div class="offering-item">
                <div class="number">8</div>
                <p class="offering">Career Opportunities</p>
                <p>Grow within the medical industry.</p>
            </div>
        </div>
    </section>
    
</div>

<app-website-footer></app-website-footer>

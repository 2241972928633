<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Infrastructure</h2>
        <img class="edit-profile " *ngIf="!showEditForm" alt="" src="../../assets/images/editprofile.svg" (click)="editInfra()">

    </div>
    <div class="row mt-3 mb-2" *ngIf="!showEditForm" >
        <div class="col-lg-5">
            <div class="card" >
                <div class="row "  style="padding: 2px 10px;">
                    <div  class="d-flex" style="border-bottom: 2px solid #F5F5F5;" >
                        <span class="col-lg-8 table-text text-center">Total No. of Rooms</span>
                        <span class="col-lg-4 table-data">{{infras.at(0).get('totalRooms')?.value}}</span>
                    </div>
                </div>
                <div class="row "  style="padding: 2px 10px;">
                    <div  class="d-flex" style="border-bottom: 2px solid #F5F5F5;" >
                        <span class="col-lg-8 table-text text-center">Total ICU Rooms</span>
                        <span class="col-lg-4 table-data">{{infras.at(0).get('totalIcuRooms')?.value}}</span>
                    </div>
                   
                </div>
                <div class="row "  style="padding: 2px 10px;">
                    <div  class="d-flex"  >
                        <span class="col-lg-8 table-text text-center">Laboratory Available</span>
                        <span class="col-lg-4 table-data">{{infraStructureForm.get('equipments')?.value && infraStructureForm.get('equipments')?.value.length>0?'Yes':'No'}}</span>
                    </div>
                   
                </div>
                <!-- <div class="row d-flex" >
                    <span class="col-lg-8 table-text text-center">Laboratory Available</span>
                    <span class="col-lg-4 table-data">{{infraStructureForm.get('equipments')?.value && infraStructureForm.get('equipments')?.value.length>0?'Yes':'No'}}</span>
                </div> -->
            </div>
            
        </div>
        <div class="col-lg-5">
            <div class="card" >
                <div class="row "  style="padding: 2px 10px;">
                    <div class="d-flex" style="border-bottom: 2px solid #F5F5F5;">
                        <span class="col-lg-8 table-text text-center">Total No. of Beds</span>
                        <span class="col-lg-4 table-data">{{infras.at(0).get('totalBeds')?.value}}</span>
                    </div>

                </div>
                <div class="row "  style="padding: 2px 10px;">
                    <div class="d-flex" >
                        <span class="col-lg-8 table-text text-center">Patient Capacity</span>
                        <span class="col-lg-4 table-data">{{infras.at(0).get('patientCapacity')?.value}}</span>
                    </div>
                    
                </div>
               
               
            </div>
            
        </div>
        
        </div>
     

    
    <div *ngIf="showEditForm" class="mt-2">
        <form [formGroup]="infraStructureForm">
            <div formArrayName="infras">
                <div *ngFor="let infrasForm of infras.controls;let i=index;">
                    <div [formGroupName]="i">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-5 label">Total No. of Rooms</div>
                                    <div class="col-lg-7 ">
                                        <mat-form-field appearance="outline" class=" w-100  input-font">
                                            <input matInput required formControlName="totalRooms">

                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-5 label">Total No. of Beds</div>
                                    <div class="col-lg-7 ">
                                        <mat-form-field appearance="outline" class=" w-100  input-font">
                                            <input matInput  formControlName="totalBeds">

                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-5 label">Total ICU Rooms</div>
                                    <div class="col-lg-7 ">
                                        <mat-form-field appearance="outline" class=" w-100  input-font">
                                            <input matInput  formControlName="totalIcuRooms">

                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-5 label">Patient Capacity</div>
                                    <div class="col-lg-7 ">
                                        <mat-form-field appearance="outline" class=" w-100  input-font">
                                            <input matInput  formControlName="patientCapacity">

                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            
            <!-- <div class="row" >
              
                <div
                    class="col-lg-12">
                    <div >
                        <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color ">Types of Equipment Available</p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font w-100 equipment"
                            [class.equipment_increase]="infraStructureForm.get('equipments')?.value.length>4">
                            <mat-select placeholder="Select" formControlName="equipments" multiple
                                >
                                <mat-select-trigger>
    
                                    <mat-chip-listbox>
                                        <mat-chip class="chip" *ngFor="let topping of infraStructureForm.get('equipments')?.value ; let i=index"
                                            [removable]="true" (removed)="remove(i)">
                                            {{ getEquipment(topping)}}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-listbox>
                                </mat-select-trigger>
                                <mat-option *ngFor="let equipment of equipments"
                                    [value]="equipment.id">{{equipment?.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div> -->
        </form>
        <div class="profile-action " >
            
    
            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
        
        
            
        
        
            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
        
        
        
        </div>
    </div>
</div>
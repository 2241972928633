import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DasboardService } from 'src/app/services/super-admin/dasboard.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  content: any;

  constructor(private dashboardService: DasboardService, private sanitizer: DomSanitizer) { 
  }

  ngOnInit(): void {
    this.fetchHowItWorks();
  }

  fetchHowItWorks(): void {
    this.dashboardService.getContent('howitworks').subscribe( (response) => {
        const rawHtml = this.sanitizer.bypassSecurityTrustHtml(response.data.content);
        this.content = rawHtml ;
      },
    );
  }

}

<app-website-header></app-website-header>
<div class="container py-5">

  <div class="t">
    <div class="t-img"></div>
    <div class="d-flex flex-column lm align-items-center p-3">
      <p class="d-flex justify-content-center heading-faq">Our story begins</p>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo, tempore non iusto nam animi quasi tempora dolorum delectus modi vero fugiat aperiam maxime, eius odit ducimus quia molestias dolorem ratione ipsam. Quo, fugit assumenda! Sunt?</p>
      <button class="button button-primary px-2">Learn More</button>
    </div>
  </div>

  <div class="m d-flex justify-content-around flex-wrap ">
    <div class="ml">
      <form [formGroup]="contactUs" class="d-flex flex-column">
        <h2>Get in touch today</h2>
        <mat-form-field appearance="outline" class="input-font">
          <input matInput formControlName="name" placeholder="Your Name">
          <mat-error class="error-message error-msg" *ngIf="!contactUs.get('name')?.valid">
            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-font">
          <input matInput formControlName="email" placeholder="Email Address" type="email">
          <mat-error class="error-message error-msg" *ngIf="!contactUs.get('email')?.valid">
            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Email Address is Required
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="input-font">
          <input matInput formControlName="phone" placeholder="Phone Number" type="tel" onlyNumbers>
          <mat-error class="error-message error-msg" *ngIf="!contactUs.get('phone')?.valid">
            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number is Required
          </mat-error>
        </mat-form-field>
          <angular-editor formControlName="message" placeholder="Message"    ></angular-editor>
        <button class="button button-primary" (click)="sendMessage()" >Send message</button>
      </form>
    </div>
    <div class="mr">
      <div class="card d-flex flex-column justify-content-center">
        <div class="card-img  d-flex justify-content-center">
          <img src="assets/images/Frame.svg" alt="company logo">
        </div>
        <div class="card-info d-flex flex-column justify-content-center">
          <div class="row">
           
            <div class="col-1 my-2"><img src="../../assets/images/icons/location-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><strong>Office</strong></div>
            <div class="col-9 my-2"><strong>address</strong></div>
            <div class="w-100"></div>
            <div class="col-1 my-2"><img  src="../../assets/images/icons/phone-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><strong>Phone</strong></div>
            <div class="col-9 my-2"><strong>phone</strong></div>
            <div class="w-100"></div>
            <div class="col-1 my-2"><img src="../../assets/images/icons/email-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><p><strong>Email</strong></p></div>
            <div class="col-9 my-2"><strong>email</strong></div>
        </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Endeavour%20Technologies,%20Kondapur,%20Hyderabad,%20Telangana%2C%20India&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>
  </div>

  <div class="FAQ d-flex flex-column align-items-center">
    <p class="heading-faq">Frequently Asked Questions</p>
    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, vitae?</p>
    <div class="questions">
      <ul *ngFor="let question of faq; let i = index">
        <li class="d-flex justify-content-between align-items-center " >
          <div class="q">
            {{ question.question  }}
          </div>
          <div>
            <h1 (click)="showAnswer(i)"> + </h1>
          </div>
        </li>
        <p *ngIf="question.display">{{question.answer}} </p>
      </ul>
    </div>
  </div>

</div>
<app-website-footer></app-website-footer>

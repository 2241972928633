
<div class="container p-4">
    <div class="row">
        <h4 class="head">Filter Products</h4>
    </div>
    <form [formGroup]="productFilterForm">
        <div class="row mt-4">
            <div class="col-lg-4" >
                <div >
                    <mat-label class="label">All OEM</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-chip-grid #chipGridOEM>
                            <mat-chip class="oem-chip" *ngIf="selectedOEM !==''"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeOEM()">
                                {{selectedOEM}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input color="primary" 
                            [class.hidden]="isEditMode || selectedOEM !== ''"
                                placeholder="Select"
                                class="custom-select-input"
                                formControlName="startWith"
                                [matChipInputFor]="chipGridOEM"
                                [matAutocomplete]="auto"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" 
                                [matChipInputAddOnBlur]="addOnBlur"
                                (keydown.enter)="loadOEM()"
                                (click)="loadOEM()"
                                (matChipInputTokenEnd)="addOEM($event)"/>
                        </mat-chip-grid>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOEMChange($event)">
                            <mat-option *ngFor="let om of oems" [value]="om">{{ om.name }}</mat-option> 
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4" >
                <div >
                    <mat-label class="label">All Category</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="category">
                            <mat-option *ngFor="let category of categories" [value]="category.id">
                                {{category.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4" >
                <div >
                    <mat-label class="label">Status</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="status">
                            <mat-option *ngFor="let status of status" [value]="status.value">
                                {{status.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class="col-lg-4" >
                <div >
                    <mat-label class="label">Created By</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="createdBy">
                            <mat-option *ngFor="let createdBy of users" [value]="createdBy.id">
                                {{ createdBy?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div> -->
        </div>
        <div class="row mt-4 d-flex">
            <div class="col-lg-1">
                <button class="buttons p-2" (click)="filterProduct()">Filter</button>
            </div>
            <div  class="col-lg-1 " >
                <button class="cbuttons p-2" (click)="clearFilter()">Clear</button>
            </div>
            <div  class="col-lg-1 " >
                <button class="cbuttons p-2" (click)="closeDialog()">Cancel</button>
            </div>
        </div>
</form>
</div>

<div class="container p-4">
    <div class="row">
<h4 class="head">Filter Exhibitions</h4>
    </div>
    <form [formGroup]="exhibitionFilterForm">
    <div class="row">
        <div class="col-lg-4" >
            <div >
                <mat-label class="label">Country</mat-label>
            </div>           
                <div>
                    <mat-form-field appearance="outline" class="fields input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="country" [matAutocomplete]="auto" >
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCountrySelected($event)">
                          <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName" >
                            {{ option?.countryName }}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
        </div>
        <div class="col-lg-4" >
            <div >
                <mat-label class="label">State</mat-label>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100">
                    <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="state" [matAutocomplete]="stateAuto">
                    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelected($event)" >
                      <mat-option *ngFor="let option of stateDropdown" [value]="option?.stateName">
                        {{ option?.stateName }}
                      </mat-option>
                    </mat-autocomplete>
                    
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-4" >
            <div >
                <mat-label class="label">City</mat-label>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100">
                    <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="city" [matAutocomplete]="cityAuto">
                    <mat-autocomplete #cityAuto="matAutocomplete"  (optionSelected)="onStateSelected($event.option.value)">
                      <mat-option *ngFor="let option of cityDropdown" [value]="option?.cityName">
                        {{ option?.cityName }}
                      </mat-option>
                    </mat-autocomplete>
                    
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-4 mt-4" >
            <div >
                <mat-label class="label">Filter By Date</mat-label>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 mt-1" >
                    <input matInput [matDatepicker]="datePicker"  [formControl]="date" placeholder="Choose a date" >
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
</div>
<div class="popup-action mt-4">
    <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="filterExhibitionCustomer()">Filter</button>
    <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="clearFilter()">Clear</button>
    <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="closeDialog()">Cancel</button>
</div>
</form>
</div>
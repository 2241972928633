import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EndCustomerRoutingModule } from './end-customer-routing.module';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomMaterialModule } from 'src/app/material.module';
import { EndCustomerComponent } from './end-customer.component';
import { MemberProfileComponent } from './profile/member-profile/member-profile.component';
import { InfraProfileComponent } from './profile/infra-profile/infra-profile.component';
import { ServicesProfileComponent } from './profile/services-profile/services-profile.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PhotosProfileComponent } from './profile/photos-profile/photos-profile.component';
import { EnquiryComponent } from './enquiry/enquiry.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { EquipmentsComponent } from './profile/equipments/equipments.component';
import { WebinarUserComponent } from './profile/webinar-user/webinar-user.component';
import { ProductEnquiryDistributorsComponent } from './product-enquiry-distributors/product-enquiry-distributors.component';;
import { OemModule } from '../dashboard/oem/oem.module';
import { CustomerPreviewDialogComponent } from './customer-preview-dialog/customer-preview-dialog.component';
import { SuperAdminCareersModule } from '../dashboard/super-admin/super-admin-careers/super-admin-careers.module';
import { SuperAdminCareersComponent } from '../dashboard/super-admin/super-admin-careers/super-admin-careers.component';
import { PostJobComponent } from '../dashboard/super-admin/super-admin-careers/post-job/post-job.component';

@NgModule({
  declarations: [
    EndCustomerComponent,
 
    
    EnquiryComponent,
    PortfolioComponent,
    
    WebinarUserComponent,
    ProductEnquiryDistributorsComponent,
    CustomerPreviewDialogComponent,
  
  ],
  exports: [
    MemberProfileComponent,
    InfraProfileComponent,
    ServicesProfileComponent,
    EquipmentsComponent,
    
  ],
  imports: [
    CommonModule,
    EndCustomerRoutingModule,
    TranslateModule,
    CustomMaterialModule,
    SharedModule,
    AngularEditorModule,
    OemModule,
    SuperAdminCareersModule,

  ]
})
export class EndCustomerModule { }

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-brand-contact-us',
  templateUrl: './brand-contact-us.component.html',
  styleUrls: ['./brand-contact-us.component.scss']
})

export class BrandContactUsComponent implements OnInit {
  @Input() data : any;
  enquiryForm!: FormGroup;
  submitted = false;
  isEmailError: boolean = false;
  brandId : string = '';
  brandName : string = '';
  logo : string = '';
  url : string = '';
  userId : string = '';
  countryCodes: any[] = [];
  filteredCountryCodes: any[] = [];
  searchControl = new FormControl('');
  showDropdown: boolean = false;
  senderId: string = '';

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute, 
    private oemService : OemService,
    private sweetAlertService : SweetAlertService,
    private localStorageService: LocalStorageService,
    private authService : AuthService
  ) {
    this.enquiryForm = this.fb.group({
      name: ['', [Validators.required, noWhitespaceValidator(), Validators.pattern('^[a-zA-Z ]*$')]],  
      email: ['', [Validators.required, Validators.email,]],
      countryCode: ['', [Validators.required]],
      phoneCode : [''],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(15)]],
      message: ['']
    }); 
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params !== undefined && params !== null && Object.entries(params).length > 0) {
        console.log(' params brandId', params['brandId']);
        this.brandId = params['brandId'];
        this.brandName = params['brandName'];
        this.logo = params['logo'];
        this.url = params['url'];
      }
    });

    this.localStorageService.getUserDetails().subscribe(data=>{
      this.userId=data.userId;
    })
    this.senderId = this.localStorageService.getItem('distributorId');
    if(!this.senderId){
      this.senderId = this.localStorageService.getItem('oemId');
    }

    this.getCountryCodes();

    this.searchControl.valueChanges.subscribe((term) => {
      this.filterCountryCodes(term ?? '');
    });
  }
filterCountryCodes(term: string): void {
  this.filteredCountryCodes = term ? 
    this.countryCodes.filter(code =>
      code.countryCode.toLowerCase().includes(term.toLowerCase())
    ) 
    : this.countryCodes; 
}

selectCountryCode(code: { countryCode: string; phoneCode: string }): void {
  this.enquiryForm.patchValue({
    countryCode: code.countryCode,
    phoneCode: code.phoneCode
  });
  this.searchControl.setValue(code.countryCode);
  this.showDropdown = false;
}

showDropdownList(): void {
  // Reset the filtered list to show all country codes
  this.filteredCountryCodes = this.countryCodes;
  this.showDropdown = true;
}

// Hide dropdown list with a delay
hideDropdown(): void {
  setTimeout(() => this.showDropdown = false, 200);
}


  getCountryCodes(): void { 
    this.authService.getCountrycodes(0, 300).subscribe(
      (response) => {
        this.countryCodes = response.data;
        this.filteredCountryCodes = this.countryCodes;
        console.log('countryCodes', this.countryCodes);
        
      },
      (error) => {
        console.error('Error fetching country codes:', error);
      }
    );
  }

  updatePhoneCode(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const selectedCountryCode = selectElement.value;
    console.log('selectedCountryCode', selectedCountryCode);
    
    const selectedCode = this.countryCodes.find(code => code.countryCode === selectedCountryCode);
    if (selectedCode) {
      this.enquiryForm.patchValue({
        phoneCode: selectedCode.phoneCode
      });
    }
  }

  onSubmit(): void {
    this.submitted = true;    
    if (!this.userId) {
      this.sweetAlertService.showWarning('', 'Login required before sending enquiry!');
      return;
    }

    if (this.enquiryForm.invalid) {
      this.sweetAlertService.showWarning('', 'Please fill all the required fields');    
      return;
    }

    if (this.enquiryForm.valid) {
      const formData = this.enquiryForm.value;
      const payload = {
        senderId: this.senderId,
        enqCustomerName: formData.name,
        email: formData.email,
        phone: this.concatCountryCodeAndPhone(),
        message: formData.message,
        receiverId: [this.senderId, this.data.id],
        enquiryType: 'GENERAL',
        isGroup : true
      };
      console.log(payload);

      this.oemService.saveEnquiry(payload).subscribe(
        (response) => {
          this.sweetAlertService.showSuccess('', 'Enquiry submitted successfully !!');
          this.enquiryForm.reset();
          this.searchControl.setValue(''); 
          this.showDropdown = false; 
        },
        (error) => {
          console.log("error", error);
          this.sweetAlertService.showError('', 'Failed to submit enquiry !!');
        }
      );
    } else {
      this.enquiryForm.markAllAsTouched();
      console.log('err')
    }
  }

  concatCountryCodeAndPhone(): string {
    const phoneCode = this.enquiryForm.get('phoneCode')?.value || '';
    console.log('phoneCode', phoneCode);
    
    const phone = this.enquiryForm.get('phone')?.value || '';
    return `${phoneCode}${phone}`;
  }

  get f() { return this.enquiryForm.controls; }
  get phone() {
    return this.enquiryForm.get('phone');
  }

  get isPhoneInvalid() {
    return this.enquiryForm.get('phone')?.invalid && (this.enquiryForm.get('phone')?.dirty || this.enquiryForm.get('phone')?.touched);
  }

  get isNameInvalid() {
    return this.enquiryForm.get('name')?.invalid && (this.enquiryForm.get('name')?.dirty || this.enquiryForm.get('name')?.touched);
  }

  get isEmailInvalid() {
    if (this.submitted) {
        if(!this.enquiryForm.get('email')?.value){ 
          this.isEmailError = true;
        } else {
          this.isEmailError = false;
        }
      return this.enquiryForm.get('email')?.invalid;
    }
    return (this.enquiryForm.get('email')?.dirty || this.enquiryForm.get('email')?.touched);
  }

  onCancel(): void {
    this.enquiryForm.reset();
  }
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  };
}

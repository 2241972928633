
<div class="container p-4">
    <div class="row">
        <h4 class="head">Product Filter</h4>
    </div>
    <form [formGroup]="productFilterForm">
        <div class="row mt-4">
            <div class="col-lg-3" >
                <div >
                    <mat-label class="label">All Category</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="category">
                            <mat-option *ngFor="let category of categories" [value]="category.id">
                                {{category.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-3" >
                <div >
                    <mat-label class="label">Status</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="status">
                            <mat-option *ngFor="let status of status" [value]="status.value">
                                {{status.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-3" >
                <div >
                    <mat-label class="label">Created By</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="createdBy">
                            <mat-option *ngFor="let createdBy of users" [value]="createdBy.id">
                                {{ createdBy?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-3" >
                <div >
                    <mat-label class="label">Created On</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields w-100">
                        <mat-select placeholder="Select" formControlName="createdOn">
                            <mat-option *ngFor="let modifiedOn of modifiedOnData" [value]="modifiedOn">
                                {{modifiedOn}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row mt-4 d-flex">
            <div class="col-lg-1">
                <button class="buttons p-2" (click)="filterProduct()">Filter</button>
            </div>
            <div  class="col-lg-1 " >
                <button class="cbuttons p-2" (click)="clearFilter()">Clear</button>
            </div>
            <div  class="col-lg-1 " >
                <button class="cbuttons p-2" (click)="closeDialog()">Cancel</button>
            </div>
        </div>
</form>
</div>
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AttributesComponent } from './attributes/attributes.component';
import { CareersComponent } from './careers/careers.component';
import { CmspagesComponent } from './cmspages/cmspages.component';
import { EndCustomersComponent } from './end-customers/end-customers.component';
import { HomeComponent } from './home/home.component';
import { KnowledgeBaseComponent } from './knowledge-base/knowledge-base.component';
import { LoginComponent } from './login/login.component';
import { MasterDataComponent } from './master-data/master-data.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { OemSignupComponent } from './oem-signup/oem-signup.component';
import { OemsComponent } from './oems/oems.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProductConfigurationComponent } from './product-configuration/product-configuration.component';
import { ProductSettingsComponent } from './product-settings/product-settings.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { SignupComponent } from './signup/signup.component';
import { StaffManagementComponent } from './staff-management/staff-management.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SupportTicketsComponent } from './support-tickets/support-tickets.component';
import { WebLoginComponent } from './web-login/web-login.component';
import { WebinarsComponent } from './webinars/webinars.component';
import { WebsiteFooterComponent } from './website-footer/website-footer.component';
import { WebsiteHeaderComponent } from './website-header/website-header.component';
import { FormsModule } from '@angular/forms';
import { CardComponent } from './card/card.component';
import { CustomMaterialModule } from 'src/app/material.module';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { TableComponent } from './table/table.component';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FooterComponent } from './footer/footer.component';
import { DistributorSignupComponent } from './distributor-signup/distributor-signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FilterComponent } from './filter/filter.component';
import { LogoutComponent } from './logout/logout.component';
import { EndCustomerSignupComponent } from './end-customer-signup/end-customer-signup.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FilterPipe } from './filter/filter.pipe';
import { OemManagementTableComponent } from './tables/oem-management-table/oem-management-table.component';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';
import { ViewCompanyInfoComponent } from './view-company-info/view-company-info.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { OemProfileSummaryComponent } from '../dashboard/oem/oem-profile-summary/oem-profile-summary.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { OemCompanyInformationComponent } from '../dashboard/oem/oem-company-information/oem-company-information.component';
import { MweTableComponent } from './mwe-table/mwe-table.component';
import { SpecificationsComponent } from './product-settings/specifications/specifications.component';
import { CategoriesComponent } from './product-settings/categories/categories.component';
import { AllProductsComponent } from './product-settings/all-products/all-products.component';
import { AddEditSpecificationsComponent } from './product-settings/specifications/add-edit-specifications/add-edit-specifications.component';
import { ActivePipe } from 'src/app/pipes/active.pipe';
import { LocationManagementComponent } from './location-management/location-management.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { ProductDetailsComponent } from './product-settings/all-products/product-details/product-details.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { ProductFilterComponent } from './product-settings/all-products/product-filter/product-filter.component';
import { SignoutPopupComponent } from './signout-popup/signout-popup.component';
import { UnitOfMeasureComponent } from './product-settings/unit-of-measure/unit-of-measure.component';
import { AddEditUnitOfMeasureComponent } from './product-settings/unit-of-measure/add-edit-unit-of-measure/add-edit-unit-of-measure.component';
import { AddEditAllProductsComponent } from './product-settings/all-products/add-edit-all-products/add-edit-all-products.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddCatalogComponent } from './add-catalog/add-catalog.component';
import { AddProductCategoriesComponent } from './add-product-categories/add-product-categories.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { DealDetailsComponent } from './deal-details/deal-details.component';
import { PointsOfContactsComponent } from './points-of-contacts/points-of-contacts.component';
import { BranchInformationComponent } from './branch-information/branch-information.component';
import { DocumentsComponent } from './documents/documents.component';
import { ProductInformationComponent } from './product-information/product-information.component';
import { FinancialInformationComponent } from './financial-information/financial-information.component';
import { RemarksComponent } from './remarks/remarks.component';
import { NetworkComponent } from './network/network.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ImportComponent } from './product-settings/all-products/import/import.component';
import { ExportComponent } from './product-settings/all-products/export/export.component';
import { WelcomePopupComponent } from './welcome-popup/welcome-popup.component';
import { AddWebinarComponent } from './add-webinar/add-webinar.component';
import { ViewWebinarComponent } from './view-webinar/view-webinar.component';

import { ProductEnquiryComponent } from './product-enquiry/product-enquiry.component';
import { ChatComponent } from './chat/chat.component';
import { AddLeadsComponent } from './add-leads/add-leads.component';
import { TitlecasePipe } from 'src/app/pipes/titlecase.pipe';
import { OemBranchInfoComponent } from './oem-branch-info/oem-branch-info.component';
import { MatTreeModule } from '@angular/material/tree';
import { AddEditCategoryComponent } from './product-settings/categories/add-edit-category/add-edit-category.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { ProductInfoCardComponent } from './product-info-card/product-info-card.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { EditLeadComponent } from './edit-lead/edit-lead.component';
import { CategoryDeatilsComponent } from './category-deatils/category-deatils.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { BrandComponent } from './brand/brand.component';
import { AddOemSuccessPopupComponent } from './add-oem-success-popup/add-oem-success-popup.component';
import { ViewProductsComponent } from './product-settings/all-products/view-products/view-products.component';
import { OemPointOfContactsComponent } from './oem-point-of-contacts/oem-point-of-contacts.component';
import { CompanyProductCategoriesComponent } from './company-product-categories/company-product-categories.component';
import { OemDistributorNetworkComponent } from './oem-distributor-network/oem-distributor-network.component';
import { CompanyFinacialInfoComponent } from './company-finacial-info/company-finacial-info.component';
import { JobDetailsComponent } from './careers/job-details/job-details.component';
import { ViewAllJobsComponent } from './careers/view-all-jobs/view-all-jobs.component';
import { JobApplyComponent } from './careers/job-apply/job-apply.component';
import { ProductsFilterComponent } from './products-filter/products-filter.component';
import { AddEditSubscrptionsComponent } from './add-edit-subscrptions/add-edit-subscrptions.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { CamelcasePipe } from 'src/app/pipes/camelcase.pipe';
import { ProductFileViewerComponent } from './product-settings/product-file-viewer/product-file-viewer.component';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { BrandProfileComponent } from './brand-profile/brand-profile.component';
import { CompanyProfileComponent } from './brand-profile/company-profile/company-profile.component';
import { ProductsComponent } from './brand-profile/products/products.component';
import { DistributorsNetworkComponent } from './brand-profile/distributors-network/distributors-network.component';
import { BrandFiltersComponent } from './brand/brand-filters/brand-filters.component';
import { WebinarComponent } from './website/webinar/webinar.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ViewCategoryComponent } from './product-settings/categories/view-category/view-category.component';
import { CategoryCardComponent } from './product-settings/categories/category-card/category-card.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BrandProductFilterComponent } from './brand-profile/brand-product-filter/brand-product-filter.component';
import { WebinarViewComponent } from './website/webinar-view/webinar-view.component';
import { RegisterWebinarComponent } from './website/register-webinar/register-webinar.component';
import { MydistributorsComponent } from '../dashboard/oem/mydistributors/mydistributors.component';
import { CatalogsComponent } from './brand-profile/catalogs/catalogs.component';
import { ExhibitionsComponent } from './brand-profile/exhibitions/exhibitions.component';
import { BrandContactUsComponent } from './brand-profile/brand-contact-us/brand-contact-us.component';
import { AppointmentComponent } from './brand-profile/appointment/appointment.component';
import { OnlyNumbersDirective } from 'src/app/directives/only-numbers.directive';
import { AllowPlusAndNumbersDirective } from 'src/app/directives/allow-plus-and-numbers.directive';
import { TruncateTextPipe } from 'src/app/pipes/truncate-text.pipe';
import { CertificationProfileComponent } from '../end-customer/profile/certification-profile/certification-profile.component';
import { DoctorProfileComponent } from '../end-customer/profile/doctor-profile/doctor-profile.component';
import { EducationProfileComponent } from '../end-customer/profile/education-profile/education-profile.component';
import { HospitalProfileComponent } from '../end-customer/profile/hospital-profile/hospital-profile.component';
import { MoreInfoProfileComponent } from '../end-customer/profile/more-info-profile/more-info-profile.component';
import { PersonalInfomationComponent } from '../end-customer/profile/personal-infomation/personal-infomation.component';
import { ProfileAboutComponent } from '../end-customer/profile/profile-about/profile-about.component';
import { ProfileComponent } from '../end-customer/profile/profile.component';
import { ResumeProfileComponent } from '../end-customer/profile/resume-profile/resume-profile.component';
import { SkillSetProfileComponent } from '../end-customer/profile/skill-set-profile/skill-set-profile.component';
import { SpecialitiesComponent } from '../end-customer/profile/specialities/specialities.component';
import { WorkExperienceProfileComponent } from '../end-customer/profile/work-experience-profile/work-experience-profile.component';
import { CardProfileComponent } from '../end-customer/profile/card-profile/card-profile.component';
import { PublicationsProfileComponent } from '../end-customer/profile/more-info-profile/publications-profile/publications-profile.component';
import { AssociationsProfileComponent } from '../end-customer/profile/more-info-profile/associations-profile/associations-profile.component';
import { AwardsProfileComponent } from '../end-customer/profile/more-info-profile/awards-profile/awards-profile.component';
import { CoursesProfileComponent } from '../end-customer/profile/more-info-profile/courses-profile/courses-profile.component';
import { VolunteeringProfileComponent } from '../end-customer/profile/more-info-profile/volunteering-profile/volunteering-profile.component';
import { SalaryPipe } from 'src/app/pipes/salary.pipe';
import { PhotosProfileComponent } from '../end-customer/profile/photos-profile/photos-profile.component';
import { SubscriptionDetailsComponent } from './subscription-details/subscription-details.component';
import { ProfileJobsComponent } from '../end-customer/profile/profile-jobs/profile-jobs.component';
import { ConnectedBrandsComponent } from './brand-profile/connected-brands/connected-brands.component';
import { LeadsFilterPopupComponentComponent } from './leads-filter-popup-component/leads-filter-popup-component.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ManagePasswordComponent } from './manage-password/manage-password.component';
import { AssignMoreDistributorsComponent } from './assign-more-distributors/assign-more-distributors.component';
import { AddDistributorComponent } from './add-distributor/add-distributor.component';
import { AddOemRemarksPopupComponent } from './add-oem-remarks-popup/add-oem-remarks-popup.component';
import { ProductDistributionRequestComponent } from './product-distribution-request/product-distribution-request.component';
import { LeadOemNetworkComponent } from './lead-oem-network/lead-oem-network.component';
import { CompanyAttachmentsComponent } from './company-attachments/company-attachments.component';
import { HospitalsComponent } from './hospitals/hospitals.component';
import { HospitalFiltersComponent } from './hospitals/hospital-filters/hospital-filters.component';
import { HospitalPortfolioComponent } from './hospitals/hospital-portfolio/hospital-portfolio.component';
import { HospitalProfileInfoComponent } from './hospitals/hospital-portfolio/hospital-profile-info/hospital-profile-info.component';
import { AlphabetOnlyDirective } from 'src/app/directives/alphabet-only.directive';
import { DoctorsComponent } from './hospitals/hospital-portfolio/doctors/doctors.component';
import { HospitalReviewsComponent } from './hospitals/hospital-portfolio/hospital-reviews/hospital-reviews.component';
import { HospitalServicesComponent } from './hospitals/hospital-portfolio/hospital-services/hospital-services.component';
import { RejectDistributorRemarksPopupComponent } from './reject-distributor-remarks-popup/reject-distributor-remarks-popup.component';
import { SendMessagePopupComponent } from './send-message-popup/send-message-popup.component';
import { ShareResumeComponent } from './share-resume/share-resume.component';
import { NotificationsPopUpComponent } from './notifications-pop-up/notifications-pop-up.component';
import { InfraProfileComponent } from '../end-customer/profile/infra-profile/infra-profile.component';
import { MemberProfileComponent } from '../end-customer/profile/member-profile/member-profile.component';
import { ServicesProfileComponent } from '../end-customer/profile/services-profile/services-profile.component';
import { EquipmentsComponent } from '../end-customer/profile/equipments/equipments.component';
import { HowDoesItWorksComponent } from './how-does-it-works/how-does-it-works.component';

@NgModule({
  declarations: [
    AnalyticsComponent,
    MemberProfileComponent,
    InfraProfileComponent,
    AttributesComponent,
    CareersComponent,
    CmspagesComponent,
    EndCustomersComponent,
    EndCustomerSignupComponent,
    HomeComponent,
    KnowledgeBaseComponent,
    LoginComponent,
    MasterDataComponent,
    NotificationsComponent,
    OemSignupComponent,
    OemsComponent,
    PaymentsComponent,
    ProductConfigurationComponent,
    ProductSettingsComponent,
    ResetPasswordComponent,
    SettingsComponent,
    SignupComponent,
    StaffManagementComponent,
    SubscriptionPlansComponent,
    SupportTicketsComponent,
    WebLoginComponent,
    WebinarsComponent,
    OemCompanyInformationComponent,
    WebsiteFooterComponent,
    WebsiteHeaderComponent,
    CardComponent,
    NavbarComponent,
    FooterComponent,
    DistributorSignupComponent,
    ForgotPasswordComponent,
    TableComponent,
    OemProfileSummaryComponent,
    FilterComponent,
    TermsandconditionsComponent,
    PrivacypolicyComponent,
    LogoutComponent,
    EndCustomerSignupComponent,
    FilterPipe,
    OemManagementTableComponent,
    FilterPopupComponent,
    ViewCompanyInfoComponent,
    DynamicTableComponent,
    MweTableComponent,
    ActivePipe,
    LocationManagementComponent,
    ProductDetailsComponent,
    ProductFilterComponent,
    SignoutPopupComponent,
    UnitOfMeasureComponent,
    AddEditUnitOfMeasureComponent,
    SpecificationsComponent,
    AddEditSpecificationsComponent,
    AllProductsComponent,
    CategoriesComponent,
    AddEditAllProductsComponent,
    AddCatalogComponent,
    AddProductCategoriesComponent,
    ImportComponent,
    ExportComponent,
    LeadDetailsComponent,
    AddProductCategoriesComponent,
    SidebarComponent,
    WelcomePopupComponent,
    AddWebinarComponent,
    ViewWebinarComponent,
    ProductEnquiryComponent,
    ChatComponent,
    AddLeadsComponent,
    DealDetailsComponent,
    PointsOfContactsComponent,
    BranchInformationComponent,
    DocumentsComponent,
    ProductInformationComponent,
    FinancialInformationComponent,
    RemarksComponent,
    NetworkComponent,
    TitlecasePipe,
    OemBranchInfoComponent,
    AddEditCategoryComponent,
    LandingpageComponent,
    ProductInfoCardComponent,
    ProductPageComponent,
    EditLeadComponent,
    CategoryDeatilsComponent,
    BreadCrumbComponent,
    AddOemSuccessPopupComponent,
    OemPointOfContactsComponent,
    CompanyProductCategoriesComponent,
    OemDistributorNetworkComponent,
    CompanyFinacialInfoComponent,
    BrandComponent,
    AddOemSuccessPopupComponent,
    ViewProductsComponent,
    JobDetailsComponent,
    ViewAllJobsComponent,
    JobApplyComponent,
    ProductsFilterComponent,
    AddEditSubscrptionsComponent,
    SpinnerComponent,
    CamelcasePipe,
    ProductFileViewerComponent,
    SafePipe,
    BrandProfileComponent,
    CompanyProfileComponent,
    ProductsComponent,
    DistributorsNetworkComponent,
    BrandFiltersComponent,
    WebinarComponent,
    AboutUsComponent,
    ContactUsComponent,
    ViewCategoryComponent,
    CategoryCardComponent,
    PaginationComponent,
    BrandProductFilterComponent,
    WebinarViewComponent,
    RegisterWebinarComponent,

    ProfileComponent,
    HospitalProfileComponent,
    DoctorProfileComponent,
    PersonalInfomationComponent,
    ProfileAboutComponent,
    SpecialitiesComponent,
    EducationProfileComponent,
    WorkExperienceProfileComponent,
    SkillSetProfileComponent,
    CertificationProfileComponent,
    MoreInfoProfileComponent,
    ResumeProfileComponent,
    CardProfileComponent,
    PublicationsProfileComponent,
    AwardsProfileComponent,
    CoursesProfileComponent,
    AssociationsProfileComponent,
    VolunteeringProfileComponent,
    PhotosProfileComponent,
    CatalogsComponent,
    ExhibitionsComponent,
    BrandContactUsComponent,
    AppointmentComponent,
    OnlyNumbersDirective,
    AllowPlusAndNumbersDirective,
    TruncateTextPipe,
    SalaryPipe,
    SubscriptionDetailsComponent,
    ProfileJobsComponent,
    ConnectedBrandsComponent,
    LeadsFilterPopupComponentComponent,
    ManagePasswordComponent,
    AssignMoreDistributorsComponent,
    AddDistributorComponent,
    AddOemRemarksPopupComponent,
    ProductDistributionRequestComponent,
    LeadOemNetworkComponent,
    CompanyAttachmentsComponent,
    HospitalsComponent,
    ServicesProfileComponent,
    EquipmentsComponent,
    
    HospitalFiltersComponent,
    HospitalPortfolioComponent,
    HospitalProfileInfoComponent,
    AlphabetOnlyDirective,
    DoctorsComponent,
    HospitalReviewsComponent,
    HospitalServicesComponent,
    RejectDistributorRemarksPopupComponent,
    SendMessagePopupComponent,
    ShareResumeComponent,
    NotificationsPopUpComponent,
    HowDoesItWorksComponent,

  ],
  exports: [
    AnalyticsComponent,
    AttributesComponent,
    AddCatalogComponent,
    CareersComponent,
    CmspagesComponent,
    EndCustomersComponent,
    ForgotPasswordComponent,
    HomeComponent,
    KnowledgeBaseComponent,
    MasterDataComponent,
    NotificationsComponent,
    OemSignupComponent,
    OemsComponent,
    OemCompanyInformationComponent,
    OemProfileSummaryComponent,
    EndCustomerSignupComponent,
    PaymentsComponent,
    ProductConfigurationComponent,
    ProductSettingsComponent,
    TermsandconditionsComponent,
    PrivacypolicyComponent,
    ResetPasswordComponent,
    SettingsComponent,
    SignupComponent,
    StaffManagementComponent,
    SubscriptionPlansComponent,
    SupportTicketsComponent,
    WebLoginComponent,
    WebinarsComponent,
    WebsiteFooterComponent,
    WebsiteHeaderComponent,
    CardComponent,
    TableComponent,
    FilterComponent,
    LogoutComponent,
    OemManagementTableComponent,
    ViewCompanyInfoComponent,
    DynamicTableComponent,
    MweTableComponent,
    LocationManagementComponent,
    ActivePipe,
    WelcomePopupComponent,
    AddLeadsComponent,
    DealDetailsComponent,
    PointsOfContactsComponent,
    BranchInformationComponent,
    DocumentsComponent,
    ProductInformationComponent,
    FinancialInformationComponent,
    RemarksComponent,
    OemBranchInfoComponent,
    OemPointOfContactsComponent,
    CompanyProductCategoriesComponent,
    OemDistributorNetworkComponent,
    CompanyFinacialInfoComponent,
    ChatComponent,
    SpinnerComponent,
    EditLeadComponent,
    CamelcasePipe,
    SafePipe,
    WebinarComponent,
    OnlyNumbersDirective,
    AllowPlusAndNumbersDirective,
    BrandFiltersComponent,
    PhotosProfileComponent,
    ProfileComponent,
    HospitalProfileComponent,
    DoctorProfileComponent,
    PersonalInfomationComponent,
    ProfileAboutComponent,
    SpecialitiesComponent,
    EducationProfileComponent,
    WorkExperienceProfileComponent,
    SkillSetProfileComponent,
    CertificationProfileComponent,
    MoreInfoProfileComponent,
    ResumeProfileComponent,
    CardProfileComponent,
    PublicationsProfileComponent,
    AwardsProfileComponent,
    CoursesProfileComponent,
    AssociationsProfileComponent,
    VolunteeringProfileComponent,
    BrandProductFilterComponent,
    ProfileJobsComponent,
    CompanyAttachmentsComponent,
    AlphabetOnlyDirective,
    MemberProfileComponent,
    InfraProfileComponent,
    ServicesProfileComponent,
    EquipmentsComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CustomMaterialModule,
    TranslateModule,
    RouterModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    AngularEditorModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTreeModule,
    ClipboardModule,
    MatSnackBarModule,

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }



<div #scrollContaine  class="infoForPopUp">
  <div *ngIf="showInformationFields">
  
    <div class="left" >
      <div class="information2">
        <span class="div-header" style="font-size: 16px;">Information</span>
        <form [formGroup]="companyInfo">
          <div formGroupName="companyInformation">
            
            <div class="row" style="padding-top: 10px;">
              <div class="col-md-6" *ngFor="let field of informationFields; let i = index">
                <div class="row align-items-center mb-0 ">
                  <div class="col-md-4">
                    <div class="headings">
                      <p class="text12">{{ field.label }}*</p>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div *ngIf="field.name === 'logo'">
                      <div *ngIf="companyInfo.get('companyInformation.logo')?.value" class="d-flex align-items-center" >
                        <img [src]="companyInfo.get('companyInformation.logo')?.value" alt="Logo" width="100" class="me-2">
                        <button type="button" class="mwe-button mwe-button--primary" (click)="triggerLogoInputClick()">Update Logo</button>
                        <input type="file" #logoInput (change)="selectFile($event, 'logo')" style="display: none;">
                      </div>
                      <div *ngIf="!companyInfo.get('companyInformation.logo')?.value" class="d-flex align-items-center">
                        <button type="button" class="mwe-button mwe-button--primary" (click)="triggerLogoInputClick()">Add Logo</button>
                        <input type="file" #logoInput (change)="selectFile($event, 'logo')" style="display: none;">
                      </div>
                    </div>
                    
                    <mat-form-field appearance="outline" class="fields w-100" *ngIf="field.name !== 'continent' && field.name !== 'country' && field.name !== 'state' && field.name !== 'city' && field.name !== 'logo'">
                      <input matInput [formControlName]="field.name" (keypress)="field.name === 'year' || field.name === 'employeeCount' ? numberOnly($event) : null" [placeholder]="field.name === 'year' ? 'yyyy' : ''">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fields w-100" *ngIf="field.name === 'continent'">
                      <mat-select formControlName="continent" (selectionChange)="onContinentChange($event)">
                        <mat-option *ngFor="let continent of continents" [value]="continent.continentName">
                          {{ continent?.continentName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fields w-100" *ngIf="field.name === 'country'">
                      <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                        <mat-option *ngFor="let country of countries" [value]="country.countryName">
                          {{ country?.countryName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="fields w-100" *ngIf="field.name === 'state'">
                      <mat-select formControlName="state" (selectionChange)="onStateChange($event)">
                        <mat-option *ngFor="let state of states" [value]="state.stateName">
                          {{ state?.stateName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-100" *ngIf="field.name === 'city'">
                      <mat-select formControlName="city">
                        <mat-option *ngFor="let city of cities" [value]="city.cityName">
                          {{ city?.cityName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </form>
      </div>
    </div>
    
    
</div>

<div #scrollContaine *ngIf="showCertificateFields">
  <div class="right">
    <div class="information2">
      <span class="div-header">Certificates</span>
      <form [formGroup]="companyInfo">
        <div formGroupName="companyInformation">
          <div class="row" *ngFor="let field of certificateFields" style="margin-top: 5px;">
            <div class="headings col-md-3">
              <div class="label">
                <p class="text">{{ field.label }}</p>
              </div>
            </div>
            <div class="col-md-9">
              <div class="card" [ngClass]="{'invalid-field': isFieldInvalid(field.name)}">
               
                <input *ngIf="field.name !== 'otherFiles'" class="d-none" type="file" id="{{ field.name }}" accept="*" (change)="selectFile($event, field.name)"/>
                <input *ngIf="field.name === 'otherFiles'" #otherFilesInput class="d-none" type="file" id="{{ field.name }}" accept=".png,.jpg,.jpeg,.svg,.pdf" (change)="selectFiles($event, field.name)" multiple />
    
                <!-- Label for file selection -->
                <label
                  *ngIf="!companyInfo.get('companyInformation.' + field.name)?.value || (field.name === 'otherFiles' && !companyInfo.get('companyInformation.' + field.name)?.value.length)"
                  class="d-flex justify-content-center text"
                  for="{{ field.name }}"
                  mat-raised-button
                >
                  <img
                    alt=""
                    src="./../../assets/images/photo-add.svg"
                    height="20px"
                    width="20px"
                  />
                  Drag files here to upload (or) Select Files
                </label>
    
                <!-- Display selected file name for non-otherFiles fields -->
                <div
                  *ngIf="
                    field.name !== 'otherFiles' &&
                    companyInfo.get('companyInformation.' + field.name)?.value
                  "
                  class="d-flex align-items-center"
                >
                  <div class="input-group">
                    <div class="d-flex align-items-center">
                      <input
                        class="file_name form-control border-0"
                        type="text"
                        [value]="getFileName(
                          companyInfo.get('companyInformation.' + field.name)
                            ?.value
                        )"
                        readonly
                        style="color: #0D87C8;"
                      />
                      <button
                        class="btn border-0"
                        mat-icon-button
                        (click)="clearFile(field.name)"
                        style="margin-left: -10px;"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
    
                <!-- Display selected files for otherFiles field -->
                <ng-container *ngIf="field.name === 'otherFiles'">
                  <div class="d-flex flex-column">
                    <div
                      class="file-list"
                      style="max-height: 100px; overflow-y: auto;"
                    >
                      <div
                        *ngFor="
                          let file of
                            companyInfo.get('companyInformation.' + field.name)
                              ?.value;
                          let i = index
                        "
                        class="d-flex align-items-center"
                      >
                        <div class="input-group">
                          <div class="d-flex align-items-center">
                            <input
                              class="file_name form-control border-0"
                              type="text"
                              [value]="getFileName(file)"
                              readonly
                            />
                            <button
                              class="btn border-0"
                              mat-icon-button
                              (click)="clearFile(field.name, i)"
                              style="margin-left: -10px;"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="add-img"
                      *ngIf="
                        companyInfo.get('companyInformation.' + field.name)
                          ?.value?.length
                      "
                    >
                      <img
                        class="edit-profile mx-1"
                        (click)="triggerOtherFilesInputClick()"
                        src="../../assets/images/add.svg"
                        alt="Edit Profile"
                      />
                      <span class="text1">Add</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
<!-- Subscription Plan -->
<div class="informations">
  <span class="div-header2">Subscription Plan</span>
  <div class="subscription-info">
    <img *ngIf="subscriptionImage" [src]="subscriptionImage" alt="{{ subscriptionName }}" class="subscription-image">
    <span class="subscription-name"
      [ngStyle]="{'margin-top.px': (subscriptionName === 'Free Plan') ? '10' : null, 'margin-left.px': (subscriptionName === 'Free Plan') ? '18' : null}">{{ subscriptionName }}</span>
  </div>
</div>

<!-- Status -->
<div class="informations">
  <span class="div-header2">Status</span>
  <ul class="key-value-list">
    <li>
      <span class="key">{{ status }}</span>
    </li>
  </ul>
 
</div>

  </div>

</div>
</div>

<div *ngIf="showBothFields" class="info">

  <div class="left">
    <div class="information">
      <span class="div-header">Information</span>
      <form [formGroup]="companyInfo">
        <div formGroupName="companyInformation">
          <div class="row" *ngFor="let field of informationFields" style="margin-bottom: -15px;">
            <div class="headings col-md-3">
              <div class="label">
                <p class="text">{{ field.label }}*</p>
              </div>
            </div>
            <div class="col-md-9">
              <div *ngIf="field.name === 'logo'">
                <div *ngIf="companyInfo.get('companyInformation.logo')?.value" class="row" style="margin-top: 3px; margin-bottom: 23px;">
                  <div class="col-md-12">
                    <img [src]="companyInfo.get('companyInformation.logo')?.value" alt="Logo" width="100">
                    &nbsp;&nbsp;
                    <button type="button" class="mwe-button mwe-button--primary ps-3 pe-3" (click)="triggerLogoInputClick()">Update Logo</button>
                    <input type="file" #logoInput (change)="selectFile($event, 'logo')" style="display: none;">
                  </div>
                </div>
                <div *ngIf="!companyInfo.get('companyInformation.logo')?.value" class="row" style="margin-top: 3px; margin-bottom: 23px;">
                  <div class="col-md-12">
                    <button type="button" class="mwe-button mwe-button--primary ps-3 pe-3" (click)="triggerLogoInputClick()">Add Logo</button>
                    <input type="file" #logoInput (change)="selectFile($event, 'logo')" style="display: none;">
                  </div>
                </div>
              </div>
                        
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.name !== 'continent' && field.name !== 'country' && field.name !== 'state' && field.name !== 'city' && field.name !== 'logo'">
                <input matInput [formControlName]="field.name" (keypress)="field.name === 'year'  || field.name === 'employeeCount' ? numberOnly($event) : null"  [placeholder]="field.name === 'year' ? 'yyyy' : ''">
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.name === 'continent'">
                <mat-select formControlName="continent" (selectionChange)="onContinentChange($event)">
                  <mat-option *ngFor="let continent of continents" [value]="continent.continentName">
                    {{ continent?.continentName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.name === 'country'">
                <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                  <mat-option *ngFor="let country of countries" [value]="country.countryName">
                    {{ country?.countryName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.name === 'state'">
                <mat-select formControlName="state" (selectionChange)="onStateChange($event)">
                  <mat-option *ngFor="let state of states" [value]="state.stateName">
                    {{ state?.stateName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100" *ngIf="field.name === 'city'">
                <mat-select formControlName="city">
                  <mat-option *ngFor="let city of cities" [value]="city.cityName">
                    {{ city?.cityName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="right">
    <div class="information">
      <span class="div-header">Certificates</span>
      <form [formGroup]="companyInfo">
        <div formGroupName="companyInformation">
          <div
            class="row"
            *ngFor="let field of certificateFields"
            style="margin-bottom: -5px;"
          >
            <div class="headings col-md-3">
              <div class="label">
                <p class="text">{{ field.label }}</p>
              </div>
            </div>
            <div class="col-md-9">
              <div class="card" [ngClass]="{'invalid-field': isFieldInvalid(field.name)}">
               
                <input *ngIf="field.name !== 'otherFiles'" class="d-none" type="file" id="{{ field.name }}" accept="*" (change)="selectFile($event, field.name)"/>
                <input *ngIf="field.name === 'otherFiles'" #otherFilesInput class="d-none" type="file" id="{{ field.name }}" accept=".png,.jpg,.jpeg,.svg,.pdf" (change)="selectFiles($event, field.name)" multiple />
    
                <!-- Label for file selection -->
                <label
                  *ngIf="!companyInfo.get('companyInformation.' + field.name)?.value || (field.name === 'otherFiles' && !companyInfo.get('companyInformation.' + field.name)?.value.length)"
                  class="d-flex justify-content-center text"
                  for="{{ field.name }}"
                  mat-raised-button
                >
                  <img
                    alt=""
                    src="./../../assets/images/photo-add.svg"
                    height="20px"
                    width="20px"
                  />
                  Drag files here to upload (or) Select Files
                </label>
    
                <!-- Display selected file name for non-otherFiles fields -->
                <div
                  *ngIf="
                    field.name !== 'otherFiles' &&
                    companyInfo.get('companyInformation.' + field.name)?.value
                  "
                  class="d-flex align-items-center"
                >
                  <div class="input-group">
                    <div class="d-flex align-items-center">
                      <input
                        class="file_name form-control border-0"
                        type="text"
                        [value]="getFileName(
                          companyInfo.get('companyInformation.' + field.name)
                            ?.value
                        )"
                        readonly
                        style="color: #0D87C8;"
                      />
                      <button
                        class="btn border-0"
                        mat-icon-button
                        (click)="clearFile(field.name)"
                        style="margin-left: -10px;"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
    
                <!-- Display selected files for otherFiles field -->
                <ng-container *ngIf="field.name === 'otherFiles'">
                  <div class="d-flex flex-column">
                    <div
                      class="file-list"
                      style="max-height: 100px; overflow-y: auto;"
                    >
                      <div
                        *ngFor="
                          let file of
                            companyInfo.get('companyInformation.' + field.name)
                              ?.value;
                          let i = index
                        "
                        class="d-flex align-items-center"
                      >
                        <div class="input-group">
                          <div class="d-flex align-items-center">
                            <input
                              class="file_name form-control border-0"
                              type="text"
                              [value]="getFileName(file)"
                              readonly
                            />
                            <button
                              class="btn border-0"
                              mat-icon-button
                              (click)="clearFile(field.name, i)"
                              style="margin-left: -10px;"
                            >
                              <mat-icon>close</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="add-img"
                      *ngIf="
                        companyInfo.get('companyInformation.' + field.name)
                          ?.value?.length
                      "
                    >
                      <img
                        class="edit-profile mx-1"
                        (click)="triggerOtherFilesInputClick()"
                        src="../../assets/images/add.svg"
                        alt="Edit Profile"
                      />
                      <span class="text1">Add</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    
<!-- Subscription Plan -->
<div class="informations">
  <span class="div-header2">Subscription Plan</span>
  <div class="subscription-info">
    <img *ngIf="subscriptionImage" [src]="subscriptionImage" alt="{{ subscriptionName }}" class="subscription-image">
    <span class="subscription-name"
      [ngStyle]="{'margin-top.px': (subscriptionName === 'Free Plan') ? '10' : null, 'margin-left.px': (subscriptionName === 'Free Plan') ? '18' : null}">{{ subscriptionName }}</span>
  </div>
</div>

<!-- Status -->
<div class="informations">
  <span class="div-header2">Status</span>
  <ul class="key-value-list">
    <li>
      <span class="key">{{ status }}</span>
    </li>
  </ul>
 
</div>

  </div>


</div>







<app-website-header></app-website-header>
<div class="header">
    <img src="../../../../../assets/images/about-us-header.svg" width="100%" height="auto" />
    <div class="header-name">
        <p>How does it Work?</p>
    </div>
</div>
<div>
    <div class="mission d-flex"  style="padding: 1rem;">
        <div class="mission-txt d-flex flex-column justify-content-center" style="flex: 1;">
            <div class="heading">
                Our Mission
            </div>
            <div>
                <p class="content">To revolutionize the health-care industry by providing a premier digital platform for seamless interaction. </p>
                <p class="content">Commit to empowering Manufacturers, Distributors, and Health Care Providers with cutting-edge tools for growth and connectivity. </p>
                <p class="content">To democratize access to medical solutions, driving forward the industry with integrity, innovation, and insight. </p>
                <p class="content">Dedicated to nurturing a vibrant ecosystem where the medical community thrives together. </p>
                <p class="content">Foster continuous learning and professional growth through our comprehensive Knowledge Centre. </p>
                <p class="content">Facilitate career advancement by offering a platform for job opportunities and professional networking within the medical industry. </p>
            </div>
        </div>
        <div class="mission-img d-flex justify-content-center align-items-center" style="flex: 1">
            <img src="../../assets/images/about-us-mission.svg" width="100%" height="auto">
        </div>
    </div>
    <div class="work d-flex flex-column">
        <div class="heading">How Does it Work?</div>
        <!-- <div class="d-flex flex-row">
            <div>
                <img src="../../assets/images/icons/about-us-search-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-search-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-select-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-connect-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-buy-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Buy</p>
                    <p style="color: #05709F;">Look for the nearest distributor or request a custom quotation.</p>
                </div>
            </div>
            <div class="down-arrow" style="padding-bottom: 0;">
                <img src="../../assets/images/about-us-down-arrow.svg">
            </div>
        </div> -->

    

        <div class="image-container d-flex flex-row">
            <div>
                <img src="../../assets/images/icons/about-us-search-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-search-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-select-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-connect-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Search</p>
                    <p style="color: #05709F;">Use specific keywords to search for the product that you need.</p>
                </div>
            </div>
            <div class="arrow d-flex justify-content-center align-items-center">
                <img src="../../assets/images/icons/about-us-right-icon.svg" style="padding-right: 10px;">
            </div>
            <div>
                <img src="../../assets/images/icons/about-us-buy-icon.svg">
                <div class="content">
                    <p class="m-0 sub-heading">Buy</p>
                    <p style="color: #05709F;">Look for the nearest distributor or request a custom quotation.</p>
                </div>
            </div>
            <div class="down-arrow" style="padding-bottom: 0;">
                <img src="../../assets/images/about-us-down-arrow.svg">
            </div>
        </div>
      

    </div>
    <!-- <div class="d-flex align-items-center justify-content-center p-2">
        <div class="ready d-flex gap-2">
            <div>
                <img src="../../assets/images/about-us-ready.svg">
            </div>
            <div>
                <div class="heading">
                    Ready to talk? Get in touch with us
                </div>
                <div>
                    <p style="font-weight: 600;">Medworld Expo is dedicated to transforming how the medical industry connects, collaborates, and grows. Our digital platform offers unparalleled opportunities to reach a global audience, foster meaningful connections, and stay at the forefront of medical innovation. By joining Medworld Expo, you'll gain access to a vast network of professionals, cutting-edge tools, and a seamless, efficient way to showcase your products and services. </p>
                    <p style="font-weight: 600;">Take the first step towards revolutionizing your presence and become an integral part of the future of medical expos. For more information or assistance, please contact our support team. We are here to help you make the most of your Medworld Expo experience. </p>
                </div>
                <button class="btn d-flex align-items-center justify-content-center gap-2 py-2" style="background-color: #0D87C8; color: white; margin-top: 1em; ">
                    <img src="../../assets/images/icons/mail-icon.svg">
                    <span>Contact Us</span>
                </button>
            </div>
        </div>
    </div> -->


    <div class="d-flex justify-content-center align-items-center p-2">
        <div class="ready d-flex p-2 ">
            <div>
                <img src="../../assets/images/about-us-ready.svg" class="p-3">
            </div>
            <div class="heading-container d-flex flex-column justify-content-start" style="gap:3px;">
                <div class="heading" style="margin-left: 0px;">
                    Ready to talk? Get in touch with us
                </div>
                <div>
                    <p class="paragraphs">
                        Medworld Expo is dedicated to transforming how the medical industry connects, collaborates, and grows. Our digital platform offers unparalleled opportunities to reach a global audience, foster meaningful connections, and stay at the forefront of medical innovation. By joining Medworld Expo, you'll gain access to a vast network of professionals, cutting-edge tools, and a seamless, efficient way to showcase your products and services.
                    </p>
                    <p class="paragraphs">
                        Take the first step towards revolutionizing your presence and become an integral part of the future of medical expos. For more information or assistance, please contact our support team. We are here to help you make the most of your Medworld Expo experience.
                    </p>
                </div>
                
                <button class="btn d-flex align-items-center justify-content-center gap-2" style="background-color: #0D87C8; color: white; margin-top: 1em; width:160px; ">
                    <img src="../../assets/images/icons/mail-icon.svg" height="24px" width="24px">
                    <span>Contact Us</span>
                </button>
            </div>
        </div>
    </div>
    
</div>
<app-website-footer></app-website-footer>
<app-website-header></app-website-header>
<div class="p-4 bg">

    <div class="d-flex justify-content-around">
        <button class="tabs first-tab " [ngClass]="{ 'active-tab': (tabName === 'myProfile')}"
            (click)="activateCurrentab('myProfile')">My Profile</button>
        <button class="tabs" [ngClass]="{'active-tab': (tabName === 'productEnquiry')}"
            (click)="activateCurrentab('productEnquiry')">Product Enquiry</button>
        <button class="tabs" [ngClass]="{'active-tab': (tabName === 'webinar')}"
            (click)="activateCurrentab('webinar')">Webinars</button>
        <button class="tabs" [ngClass]="{'active-tab': (tabName === 'carrers' || tabName === 'profileJobs')}"
            (click)="activateCurrentab('carrers')">Careers</button>
        <button class="tabs">Community</button>
        <button class="tabs last-tab">Notifications</button>


    </div>

    <div class="row">
        <div class="col-lg-9">
            <app-profile *ngIf="tabName === 'myProfile'" (workExperienceChanged)="handleWorkExperience($event)"></app-profile>
            <app-chat *ngIf="tabName === 'productEnquiry'" [enquiryTabVisible]="isEnquiry"></app-chat>
            <app-webinar-user *ngIf="tabName === 'webinar'"></app-webinar-user>
            <div class="buttontype">

                <!-- <button style="border: none;" *ngIf="tabName === 'carrers'" ><img src="/assets/images/resume-icon.svg" alt="Resume"> Create Resume</button> -->
                <button style="border: none;" *ngIf="tabName === 'carrers' && endCustomerType === 'Individual'" (click)="downloadResumefromHtml()"><img src="/assets/images/download-icon.svg" alt="Download as PDF"> Download Resume as PDF</button>

                <button style="border: none;" *ngIf="tabName === 'carrers' && endCustomerType === 'Individual'" (click)="openPreview('carrers')"> <img src="/assets/images/preview-icon.svg" alt="Preview"> Preview </button>                  
                <button style="border: none;" *ngIf="tabName === 'carrers' && endCustomerType === 'Individual'" (click)="shareResume()" ><img src="/assets/images/share-icon.svg" alt="Share"> Share</button>
            </div>
            
            <app-profile id="carrers" *ngIf="tabName === 'carrers'"></app-profile>

            <app-profile-jobs *ngIf="tabName === 'profileJobs'" [isSavedJobs]="showSavedJobs"
                [applicantId]="applicantId"></app-profile-jobs>

            <app-super-admin-careers *ngIf="tabName === 'ViewAllJobs'" [from]="'endCustomer'" (viewActive)="actionView($event)" (updateActive)="actionUpdate($event)" (addActive)="postAJob($event)" (endCustomerSelctedAction)="selectedAction($event)" ></app-super-admin-careers>
            <app-view-job *ngIf="tabName === 'viewJob'" [from]="JobId" (backToAllJobs)="backToAllJobs($event)"></app-view-job>         
            <app-post-job *ngIf="tabName === 'updateJob'" (done)="messageFromPostJob($event)" [from]="JobId"></app-post-job>            
            <app-post-job *ngIf="tabName === 'postJob'" (done)="messageFromPostJob($event)"></app-post-job>            



        </div>
        <div class="col-lg-3">
            <app-portfolio [isCareersTabActive]="tabName === 'carrers' || tabName === 'profileJobs'" (viewAllJobs)="showAllJobs($event)" (postJobs)="postAJob($event)" (viewProfileJobs)="showMyProfileJobs($event)" (workExperience)="onWorkExperience({type: $event.type, value: true})"></app-portfolio>
            <!-- <app-portfolio (viewProfileJobs)="showMyProfileJobs($event)" (WorkExperience)="handleWorkExperience({type: event, value: true})"></app-portfolio> -->
            <!-- <app-portfolio (viewProfileJobs)="showMyProfileJobs($event)" (WorkExperience)="onWorkExperience($event)" ></app-portfolio> -->
        </div>
    </div>
</div>

<app-website-footer></app-website-footer>
<div class="background">
    <app-website-header></app-website-header>
    
    <div class="row">
        <div class="image-container">
            <img src="../../../assets/images/landerpage.png" alt="landingpage" class="landingpageimage" />
            <div class="header-name">
                <p>Health Centers</p>
            </div>
        </div>
    </div>

    <div class="mx-3 mt-2">
        <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="">
        <a class="breadcrummb ps-2" style="color:#858585 ;">Home</a>
        <a class="breadcrummb" style="color:#858585 ;">/</a>
        <a class="breadcrummb" style="color:#858585 ;">Health Centers</a>
        <a class="breadcrummb" style="color:#858585 ;">/</a>
        <a class="breadcrummb" style="color:#000000 ;">Hospitals</a>
      </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3">
                <app-hospital-filters [data]="originalHospitalsData" (filteredData)="onFilteredData($event)"></app-hospital-filters>
            </div>
            <div class="col-lg-9">
                <div class="row">
                  <div class="d-flex justify-content-between ">
                    <h2 class="hospitals">Hospitals</h2>
                    <p class="end-text">Total Hospitals: {{originalHospitalsData.length}}</p>
                  </div>
                  <div class="mt-2">
                    <hr style="border: none; height: 2px; background-color: #0d87c8; margin-top: -1rem;" />
                  </div>
                </div>
                <ng-container *ngIf="originalHospitalsData.length">
                  <div class="no-records-found"> No records Found </div>
                </ng-container>
                <div class="row">
                    <div class="col-lg-4" *ngFor="let hospital of paginatedItems; let i = index">

                        <div class="card" (click)="viewHospitalsProfile(hospital)" style="position: relative;" (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = -1"
                        [ngStyle]="{'background-color': hoveredIndex === i ? 'rgba(0, 0, 0, 0.6)' : 'white', 'color': hoveredIndex === i ? '#fff' : 'inherit',}">
                        <img [src]="hospital.logo" alt="{{hospital.name}}" height="180px"
                        [style.opacity]="hoveredIndex === i ? 0.1 : 1" />
                        <p class="hospital-name" [style.opacity]="hoveredIndex==i ? 0.1 : 1">{{hospital.name}}</p>
                        <p class="text-center" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" [style.display]="hoveredIndex === i ? 'block' : 'none'"
                        [style.background-color]="hoveredIndex === i ? '' : 'rgba(0, 0, 0, 0.6)'"
                        [style.color]="hoveredIndex === i ? '#fff' : 'rgba(0, 0, 0, 0.6)'" [style.font-weight]="'600'">
                        <!-- View {{hospital.name}} Portfolio -->
                        View {{hospital.name}} Profile
                        </p>
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="pagination-container">
                <app-pagination [itemsPerPage]="itemsPerPage" [data]="hospitals" (pageChanged)="onPageChanged($event)"></app-pagination>
              <!-- <app-pagination [itemsPerPage]="itemsPerPage" [data]="brands" (pageChanged)="onPageChanged($event)"></app-pagination> -->
            </div>
          </div>
          <app-website-footer></app-website-footer>
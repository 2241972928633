
  <h2 class="modal-header">Book an Appointment</h2>
  <div mat-dialog-content>
  <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()">
    <div class="mb-3">
      <label for="name" class="form-label">Name</label>
      <input type="text" id="name" class="form-control" formControlName="name" [ngClass]="{'is-invalid': isNameInvalid}" alphabetOnly>
      <mat-error class="error-message error-msg" *ngIf="isNameInvalid">
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
      </mat-error>
    </div>

    <div class="mb-3">
      <label for="email" class="form-label">Email Address</label>
      <input type="email" id="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid': isEmailInvalid}">
      <mat-error class="error-message error-msg" *ngIf="(submitted && isEmailError) || ((appointmentForm.get('email')?.dirty || appointmentForm.get('email')?.touched) && !appointmentForm.get('email')?.value)">
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Email is Required
      </mat-error>
      <mat-error class="error-message error-msg" *ngIf="appointmentForm.get('email')?.invalid && appointmentForm.get('email')?.value">
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Enter valid email
      </mat-error>
    </div>

    <div class="mb-3">
      <!-- <label for="phone" class="form-label">Phone Number</label>
      <div class="row">
        <div class="col-3">
          <input type="text" id="countryCode" class="form-control" formControlName="phoneCode" placeholder="+91" allowPlusAndNumbers [ngClass]="{'is-invalid': isPhoneInvalid}" maxlength="4">
        </div>
        <div class="col-9">
          <input type="text" id="phone" class="form-control" formControlName="phone" placeholder="Phone Number" onlyNumbers [ngClass]="{'is-invalid': isPhoneInvalid}" maxlength="15">
        </div>
      </div>
      <mat-error class="error-message error-msg"  *ngIf="isPhoneInvalid" >
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone is Required
      </mat-error>
      <mat-error class="error-message error-msg"  *ngIf="(appointmentForm.get('phone')?.value.length < 10 && appointmentForm.get('phone')?.value.length > 1) || appointmentForm.get('phone')?.value.length > 10" >
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Invalid Phone Number
      </mat-error> -->

      <label for="phone" class="form-label">Phone Number</label>
              <div class="input-group" style="display: flex; align-items: center; position: relative;">
            
                <!-- Search and Select Input Field (Country Code) -->
                <input 
                  type="text" 
                  placeholder="IN" 
                  [formControl]="searchControl"
                  (focus)="showDropdownList()"
                  (input)="filterCountryCodes(searchControl.value || '')"
                  (blur)="hideDropdown()"
                  class="form-control country-input"
                />
            
                <!-- Custom Dropdown -->
                <div class="dropdown-menu" [ngClass]="{'show': showDropdown}" style="position: absolute; top: 100%; left: 0; width: 100%; z-index: 1000;">
                  <button 
                    class="dropdown-item" 
                    *ngFor="let code of filteredCountryCodes" 
                    (mousedown)="selectCountryCode(code)"
                  >
                    {{ code.countryCode | uppercase }}
                  </button>
                </div>
            
                <!-- Phone Code Input (Automatically updated) -->
                <input type="text" class="form-control phone-code-input" placeholder="+91" formControlName="phoneCode" readonly required>
            
                <!-- Phone Number Input -->
                <input type="text" class="form-control phone-number-input" formControlName="phone" title="Enter a valid mobile number" minlength="10" maxlength="10" required>
              </div>
            
              <!-- Validation Error Messages -->
              <mat-error class="error-message" *ngIf="appointmentForm.get('phoneCode')?.invalid && appointmentForm.get('phoneCode')?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Country Code is required.
              </mat-error>
            
              <!-- Validation Error Messages for Phone Number -->
              <mat-error class="error-message" *ngIf="phone?.hasError('required') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number is required.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('minlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should be at least 10 digits long.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('maxlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should not exceed 10 digits.
              </mat-error>

    </div>

    <div class="mb-3">
      <label for="date" class="form-label">Select Date</label>
      <input type="date" id="date" class="form-control" formControlName="date" [ngClass]="{'is-invalid': isDateInvalid}"  [min]="minDate" [max]="maxDate">
      <mat-error class="error-message error-msg"  *ngIf="isDateInvalid">
        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Date is Required
      </mat-error> 
    </div>

    <div class="mb-3" *ngIf="!isSelectedDateInvalid">
      <label for="time" class="form-label">Available Slots</label>
      <div id="time" class="available-time" >
        <div *ngFor="let time of availableTimes" class="form-check-inline">
          <input class="form-check-input" type="radio" [id]="time" formControlName="time" [value]="time" [ngClass]="{'is-invalid': isTimeInvalid}">
          <label class="form-check-label" [for]="time">{{ time }}</label>
        </div>
      </div>
    </div>

    <div *ngIf="isSelectedDateInvalid" class="mb-3" >
      <strong class="text-danger text-center">No slots available on selected date {{ appointmentForm.get('date')?.value }}. Slots available on between date {{ data.exhibitionStartDate }} and {{ data.exhibitionEndDate }} only.</strong>
    </div>

    <div class="mb-3">
      <label for="message" class="form-label">Add Message (If any)</label>
      <textarea id="message" rows="3" class="form-control" formControlName="message" rows="3"></textarea>
    </div>
  </form>
  </div>

  <div class="popup-buttons">
      <button mat-raised-button class="save-btn" type="button" (click)="onSubmit()">
          Next
      </button>
      <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="onCancel()">
          Cancel
      </button>
  </div>

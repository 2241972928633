<div>
  <app-mwe-table
    [showHeader]="false"
    [columns]="columns"
    [data]="distributorsNetwork"
    [showPagination]="false"
    (selectedAction)="selectedAction($event)"
    [isDynemicPagination]="true"
  ></app-mwe-table>
</div>
<div>
  <button *ngIf="isText" class="mwe-button mwe-button--primary m-3" (click)="assignMoreDistributors()" >
    Assign more Distributors
  </button>
  <button *ngIf="!isText" class="mwe-button mwe-button--primary m-3" (click)="assignMoreDistributors()" >
    Assign Distributors
  </button>
  <div class="mt-1 mb-1">
    <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">
      <span> Didn't find your Distributors?<button class="more" (click)="addNewDistributor()" style="text-decoration:underline; border: none;">Add new Distributor</button></span>
    </p>
  </div>
  
</div>
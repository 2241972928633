import { EndCustomerService } from './../../../../services/end-customer.service';
import { EndCustomerComponent } from './../../../end-customer/end-customer.component';
import { LocalStorageService } from './../../../../services/local-storage.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { addexhibition, appointment } from 'src/app/models/oem-models';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OemService } from 'src/app/services/oem/oem.service';
import Swal from 'sweetalert2';
import { LoginComponent } from 'src/app/components/shared/login/login.component';
import { PhotosComponent } from '../../super-admin/super-admin-end-customer/photos/photos.component';
import { Observable, map, startWith } from 'rxjs';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { LatheGeometry } from 'three';
@Component({
  selector: 'app-addexhibition',
  templateUrl: './addexhibition.component.html',
  styleUrls: ['./addexhibition.component.scss']
})
export class AddexhibitionComponent implements OnInit, OnChanges{
  // @Input() exhibitionData: any;
  @Input() exhibitionid: any;
  @Input() oemId!: string;
  @Input() UpdateExhibibitionid?: any;
  @Input() updateExhibition: boolean=false;
  @Output() onUpdate:EventEmitter<any>=new EventEmitter();


  exhibitiondata: any;

  isAvailable: boolean = false;
  @Output() backEvent = new EventEmitter<any>();
  @Output()closeToBack = new EventEmitter<any>();

  exhibitionForm: FormGroup;
  currentAppointmentIndex: number | null = null;
   currentDate: string = new Date().toISOString().split('T')[0];
  day: number=0;
  selectedDate: Date = new Date();
  defaultDate = new Date(this.currentDate); 
  isEditMode: any = false;
  staffdata: any;
  fileName = '';
  currentFile?: File;
  selectedFileName: string | null = null;
  imageURL: any;
  countryDropdown: any[] = [];
  stateDropdown: any[] = [];
  cityDropdown: any[] = [];
  filteredCountryOptions!: Observable<any[]>;
  filteredStateOptions!: Observable<any[]>;
  filteredCityOptions!: Observable<any[]>;
  role: string = '';

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;
  roleMappingId: any;
  showNoRecordsFounds: boolean = true;
  data: any[] = [];
  snackbarService: any;

constructor(private fb: FormBuilder, private exhibitionService: OemService, private http: HttpClient, private fileUploadService: FileUploadService,private masterDataService:MasterDataService, private cdr: ChangeDetectorRef, private localStorageService: LocalStorageService, private endCustomerService: EndCustomerService) {
  
  this.isEditMode=true
  this.currentDate = this.getCurrentDate();
    this.exhibitionForm = this.fb.group({
      title:new FormControl('', Validators.required),
      exhibitionStartDate: ['', Validators.required],
      exhibitionEndDate: ['', Validators.required],
      description: [''],
      image: ['', Validators.required],
      files: [[]],
      country: new FormControl('', Validators.required),
      state:new FormControl('', Validators.required),
      city:new FormControl('', Validators.required),
      address: ['', Validators.required],
      zipCode: ['', Validators.required],
      bookTicketsUrl: ['',  Validators.pattern(/^(https:\/\/|www\.).*/)],
      facebookUrl: ['',  Validators.pattern(/^(https:\/\/|www\.).*/)],
      twitterUrl: ['',  Validators.pattern(/^(https:\/\/|www\.).*/)],
      instagramUrl: ['',  Validators.pattern(/^(https:\/\/|www\.).*/)],
      linkedinUrl: ['', Validators.pattern(/^(https:\/\/|www\.).*/)],
      otherUrl: ['',  Validators.pattern(/^(https:\/\/|www\.).*/)],
      assignStaff: [[], Validators.required], 
      appointments: this.fb.array([])
    });
  }

  ngOnInit() {

  if (this.updateExhibition===true ) {
    this.getExhibitionByIdUpdate(this.UpdateExhibibitionid);
  }

  this.getAllStaff();
  this.getCountries(null);
 
  this.exhibitionForm.get('country')?.valueChanges.subscribe((data: any)=>{
    if(data){
      this.getCountries({startsWith:data});    
    }      
    else{
      this.getCountries(null);
    }
  });

  this.exhibitionForm.get('state')?.valueChanges.subscribe((data: any)=>{
    if(this.exhibitionForm.get('country')){
      if(data){
        this.getStatesByCountryName({countryName:this.exhibitionForm.get('country')?.value,startsWith:data});
      }else{
        this.getStatesByCountryName({countryName:this.exhibitionForm.get('country')?.value});
      }
  }
  });
  this.exhibitionForm.get('city')?.valueChanges.subscribe((data: any)=>{    
    if(this.exhibitionForm.get('state')?.value){    
      if(data){    
        this.getCityiesByStateName({stateName:this.exhibitionForm.get('state')?.value,startsWith:data});
      }else{
        this.getCityiesByStateName({stateName:this.exhibitionForm.get('state')?.value});
      }
    }
  else{
    if(data){
      this.getCityiesByStateName({startsWith:data});
    }
    else{
      this.getCityiesByStateName(null);
    }
  }    
  });
}

        onCountrySelected(event: any) {
          this.getStatesByCountryName({countryName:this.exhibitionForm.get('country')?.value});
        }
        onStateSelected(event: any) {
          this.getCityiesByStateName({stateName:this.exhibitionForm.get('state')?.value});
        }


  ngOnChanges(changes: SimpleChanges): void {
      }
      getCountries(params:any){
        this.executeGetRequestURL(MweConstants.countryURL,params,'countryDropdown');
      }
      getStatesByCountryName(params:any){
        this.executeGetRequestURL(MweConstants.stateUrl,params,'stateDropdown');
      }
      getCityiesByStateName(params:any){
        this.executeGetRequestURL(MweConstants.cityUrl,params,'cityDropdown');
      }

      executeGetRequestURL(url:string,params:any,type:string){
        (this as any)[type]=[];
        this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
          if(data && data?.data && data.data.length>0){
            (this as any)[type]=data.data;
          }
        },error=>{
            })
      }
    
      getStatesDropdown(){
        this.stateDropdown=[];
        this.cityDropdown=[];
        this.exhibitionForm.get('state')?.reset();
        this.exhibitionForm.get('city')?.reset();
        if(this.exhibitionForm.get('country')?.value){
          this.getStatesByCountryName({countryName:this.exhibitionForm.get('country')?.value})
        }
      }
      getCityDropdown(){
        this.cityDropdown=[];
        this.exhibitionForm.get('city')?.reset();
        if(this.exhibitionForm.get('state')?.value){
          this.getCityiesByStateName({stateName:this.exhibitionForm.get('state')?.value})
        }
      }
      getcountry(){
        return this.exhibitionForm.get('country') as FormControl;
      }
      getstate(){
        return this.exhibitionForm.get('state') as FormControl;
      }
      getcity(){
        return this.exhibitionForm.get('city') as FormControl;
      }
  reformatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  staffData: any[] = [];

  getAllStaff(){
    this.role=this.localStorageService.getItem('roleName');
    if(this.role=='OEM'){
      this.roleMappingId=this.localStorageService.getItem('oemId');
    } else if(this.role=='DISTRIBUTOR'){
      this.roleMappingId=this.localStorageService.getItem('distributorId');      
    }
    this.showNoRecordsFounds=false;
    this.endCustomerService.executeGetRequestURL(MweConstants.getAllUserUrl, {role:this.role,roleMappingId:this.roleMappingId}).subscribe((data: any)=>{
      if(data && data?.data && data.data.length>0){
        this.staffData=data.data;
      }
    }) 
  }

  getExhibitionByIdUpdate(id: string) {
    this.exhibitionService.getExhibitionById(id).subscribe(
      (data) => {
        this.exhibitiondata = data;
        this.oemId = this.exhibitiondata.data?.oemId;
        const startdate = this.getstandardfromepoch(this.exhibitiondata?.data?.exhibitionStartDate);
        const enddate = this.getstandardfromepoch(this.exhibitiondata?.data?.exhibitionEndDate);       
        const appointmentsArray = this.exhibitiondata.data.appointments?.map((appointment: any) => 
          this.fb.group({
            appointmentDate: this.getstandardfromepoch(appointment.appointmentDate) || '',
            startTime: this.getTimeFromEpoch(appointment.startTime) || '',
            endTime: this.getTimeFromEpoch(appointment.endTime) || '',
            breakTime: this.getTimeFromEpoch(appointment.breakTime) || '',
            perSlotTime: this.getTimeFromEpoch(appointment.perSlotTime) || '',
            availableSlots: appointment.availableSlots || '' 
          })
        );    
        this.exhibitionForm.patchValue({
          title: this.exhibitiondata.data.title,
          exhibitionStartDate: startdate,
          exhibitionEndDate: enddate,
          description: this.exhibitiondata.data.description || '',
          country: this.exhibitiondata.data.country || '',
          state: this.exhibitiondata.data.state || '',
          city: this.exhibitiondata.data.city || '',
          address: this.exhibitiondata.data.address || '',
          zipCode: this.exhibitiondata.data.postalCode || '',
          bookTicketsUrl: this.exhibitiondata.data.bookTicketsURL || '',
          facebookUrl: this.exhibitiondata.data.fbURL || '',
          twitterUrl: this.exhibitiondata.data.twitterURL || '',
          instagramUrl: this.exhibitiondata.data.instagramURL || '',
          linkedinUrl: this.exhibitiondata.data.linkedInURL || '',
          otherUrl: this.exhibitiondata.data.otherURL || '',
          assignStaff: this.exhibitiondata.data.staffId || []
        });
        this.imageURL=this.exhibitiondata.data?.files[0] || '';
        this.selectedFileName = this.exhibitiondata.data?.files[0] || '';
        const appointmentsControl = this.exhibitionForm.get('appointments') as FormArray;
        appointmentsControl.clear();
        appointmentsArray?.forEach((appointmentGroup: any) => appointmentsControl.push(appointmentGroup));
      },
      (error) => {
      }
    );    
  }
  
  back() {
    this.closeToBack.emit(true);
    this.backEvent.emit({ actionType: '' });
  }
  appointmentArray: appointment[] = [];
  firstAppointmentControls = this.appointmentArray[0]?.controls;
  isFileInputClickedCompanyLogo: boolean = false;
  previewImage: any = null;
 
    getstandardfromepoch(epoch: number): string {
      const date = new Date(epoch);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    }
    getTimeFromEpoch(epoch: number): string {
      const date = new Date(epoch);
      date.setHours(date.getHours() - 5);
  date.setMinutes(date.getMinutes() - 30);

  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);

  return `${hours}:${minutes}`;
    }
    
  getCurrentDate(): string {
    const today = new Date();
    const year = today.getFullYear();
    let month = '' + (today.getMonth() + 1);
    let day = '' + today.getDate();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-'); // Format: YYYY-MM-DD
  } get appointments(): FormArray {
    return this.exhibitionForm.get('appointments') as FormArray;
  }
  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || ''; // Extracting file name from URL
  }
  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.getFileNameFromUrl(file.name); 
      this.selectedFileName = this.fileName;
      this.imageURL= this.uploadFile(this.currentFile).subscribe(
      )
      
    }
  }

  clearSelectedFile(): void {
    this.exhibitionForm.value.image=null;
    this.exhibitionForm.value.files=null;
  }

  daydifference(date1: any, date2: any): any {
    date1=new Date(date1);
    date2=new Date(date2);
    const diffTime = Math.abs(date2-date1);    
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/exhibitions/');
    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          this.imageURL = response.data;
          return response.data; // Extracting file name from URL
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }
  addNewAppointment() {
    this.appointments.push(this.fb.group({
      appointmentDate: ['', Validators.required],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      breakTime: ['', Validators.required],
      perSlotTime: ['', Validators.required],
      availableSlots: ['', Validators.required]
    }));
    this.currentAppointmentIndex = this.appointments.length - 1;
  }
  selectAppointment(index: number) {
    const appointment = this.appointments.at(index).value;
    this.selectedDate = appointment.appointmentDate;
    this.currentAppointmentIndex = index;
  }

  toggleAvailability() {
    this.isAvailable = !this.isAvailable;
    this.exhibitionForm.addControl('isAvailable', new FormControl(this.isAvailable));
    if (this.isAvailable) {
      this.addNewAppointment(); // Automatically add an appointment field when checkbox is checked
    } else {
      this.clearAppointments(); // Clear all appointments when checkbox is unchecked
    }
    this.cdr.detectChanges();
  }

  clearAppointments() {
    while (this.appointments.length) {
      this.appointments.removeAt(0);
    }
    this.currentAppointmentIndex = null;
  }

  selecteddate(index: number, date: Date) {
    this.selectedDate = date;
  }

  deleteAppointment(index: number) {
    this.appointments.removeAt(index);
    if (this.currentAppointmentIndex === index) {
      this.currentAppointmentIndex = null;
    } else if (this.currentAppointmentIndex !== null && this.currentAppointmentIndex > index) {
      this.currentAppointmentIndex--;
    }
  }

  removeFile(event: Event): void {
    event.preventDefault();
    this.exhibitionForm.get('image')?.reset();
    this.selectedFileName = '';
  }
  
 formatTime(time: string): string {
    return `2022-01-11T${time}:00.000Z`;
  }

  formatDate(date :string):string{
    return `${date}T00:00.000Z`
  }

  saveForm() {
    if(!this.exhibitionForm.valid){
      Swal.fire(
        'Please fill all fields',
        '',
        'warning'
      );
      return
     
    }
    const formData = this.exhibitionForm.value;      
       formData.image=this.imageURL;
    const transformedData = {
      title: formData.title,
      exhibitionStartDate: this.formatDate(formData.exhibitionStartDate),
      exhibitionEndDate: this.formatDate(formData.exhibitionEndDate),
      files: [this.imageURL],
      country: formData.country,
      state: formData.state,
      city: formData.city,
      postalCode: formData.zipCode, 
      bookTicketsURL: formData.bookTicketsUrl,
      fbURL: formData.facebookUrl,
      linkedInURL: formData.linkedinUrl,
      twitterURL: formData.twitterUrl, 
      otherURL: formData.otherUrl,
      staffId: formData.assignStaff,
      createdBy: this.oemId, 
      appointments: formData.appointments.map((appointment: any) => ({
        appointmentDate: appointment.appointmentDate,
        startTime: this.formatTime(appointment.startTime),
        endTime: this.formatTime(appointment.endTime),
        breakTime: this.formatTime(appointment.breakTime),
        perSlotTime: this.formatTime(appointment.perSlotTime),
        availableSlots: Number(appointment.availableSlots) || 0,
      })),
      address: formData.address,
      description: formData.description      
    };   
    
    this.exhibitionService.saveExhibition(transformedData).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: 'Exhibition Added successfully.',
        });
        this.back();
      },
      error => {
       
        // Handle error, e.g., show an error message
        if(error) {
          Swal.fire(
            'Please fill all fields',
            '',
            'warning'
          );
        }
      }
    );
  }

  updateForm() {    
    const populated_data: any = {
      title: this.exhibitionForm.value.title,
      exhibitionStartDate: this.formatDate(this.exhibitionForm.value.exhibitionStartDate),
      exhibitionEndDate: this.formatDate(this.exhibitionForm.value.exhibitionEndDate),
      description: this.exhibitionForm.value.description,
      files: [this.imageURL], // Assuming this is the base64 string
      country: this.exhibitionForm.value.country,
      state: this.exhibitionForm.value.state,
      city: this.exhibitionForm.value.city,
      address: this.exhibitionForm.value.address,
      postalCode: this.exhibitionForm.value.zipCode,
      bookTicketsURL: this.exhibitionForm.value.bookTicketsUrl,
      fbURL: this.exhibitionForm.value.facebookUrl,
      twitterURL: this.exhibitionForm.value.twitterUrl,
      linkedInURL: this.exhibitionForm.value.linkedinUrl,
      otherURL: this.exhibitionForm.value.otherUrl,
      createdBy:  this.oemId, 
      staffId: this.exhibitionForm.value.assignStaff,
      appointments: this.exhibitionForm.value.appointments.map((appointment: any) => ({
        appointmentDate: this.formatDate(appointment.appointmentDate),
        startTime: this.formatTime(appointment.startTime),
        endTime: this.formatTime(appointment.endTime),
        breakTime: this.formatTime(appointment.breakTime),
        perSlotTime: this.formatTime(appointment.perSlotTime),
        availableSlots: Number(appointment.availableSlots) || 0,
      }))
    };
    
     this.exhibitionService.updateExhibition(populated_data, this.UpdateExhibibitionid).subscribe(
      response => {
        Swal.fire({
          icon: 'success',
          title: 'Exhibition updated successfully.',
        });
        this.back();
      },
      error => {
        
        if(error) {
          Swal.fire(
            'Please fill all fields',
            '',
            'warning'
          );
        }
      }
    );
  }
}
<div class="brand-header">
  <ng-container *ngIf="data.logo">
    <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
  </ng-container>
  <div class="brand-details">
    <h1>{{ data.companyName | camelcase}}</h1>
    <ng-container *ngIf="data.url">
      <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
    </ng-container>
  </div>
</div>
<div class="total-results">
  <strong>Total Results {{ products.length }} </strong>
</div>
<div class="parent-container">
  <div class="filter">
    <app-brand-product-filter [data]="originalData" [filterColumns]="['Products', 'Region']" (filteredData)="onFilteredData($event)"></app-brand-product-filter>
  </div>
    <div class="product-list">
      <ng-container *ngIf="products.length===0">
        <div class="no-records-found"> No records Found </div>
      </ng-container>
      <div class="card" *ngFor="let product of paginatedItems">
        <img height="150" [src]="product.logo" alt="{{ product.distributorName }}">
        <div class="card-content">
          <h2>{{ product.distributorName }}</h2>
          <hr/>
          <ul class="card-info">
            <li>
              <span class="icon"><img src="../../../assets/images/Frame.svg" alt="address"/></span>
              {{ product.address }} {{ product.city }}, {{ product.country }} 
            </li>
            <li>
              <span class="icon"><img src="../../../assets/images/phone rings.svg" alt="phone"/></span>
              {{ product.phoneNumber }}
            </li>
            <li>
              <span class="icon"><img src="../../../assets/images/email.svg" alt="email"/></span>
              {{ product.email }}
            </li>
          </ul>
        </div>
        <button (click)="onViewProfileClick(product)">View Profile</button>
      </div>
    </div>
    </div>
    <div class="pagination-container">
      <app-pagination [itemsPerPage]="itemsPerPage" [data]="products" (pageChanged)="onPageChanged($event)"></app-pagination>
    </div>
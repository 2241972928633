import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.css']
})
export class DynamicTableComponent {
  @Input()
  columns: string[] = [];
  @Input()
  jsonData: any[] = [];
  defaultColumn: any;
}

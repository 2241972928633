<div class="brand-header">
  <ng-container *ngIf="data.logo">
    <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
  </ng-container>
  <div class="brand-details">
    <h1>{{ data.companyName | camelcase}}</h1>
    <ng-container *ngIf="data.url">
      <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
    </ng-container>
  </div>
</div>
<div class="total-results">
  <strong>Total Results: {{ data.length || 0 }} </strong>
</div>
  <div class="parent-container">
    <div class="filter">
      <app-brand-product-filter [filterColumns]="['Region']" [data]="originalData" (filteredData)="onFilteredData($event)"></app-brand-product-filter>
    </div>
  
    <div class="product-list">
      <ng-container *ngIf="paginatedItems?.length === 0">
        <div class="no-records-found"> No records Found </div>
      </ng-container>
      <div class="card" *ngFor="let product of paginatedItems">
        <img height="150" [src]="product.files[0]" alt="{{ product?.title }}">
        <div class="card-content">
          <h2>{{ product?.title }}</h2>
          <hr/>
          <ul class="card-info">
            <li>
              <span class="icon"><img src="../../../assets/images/Frame.svg" alt="address"/></span>
              {{ product?.address }} 
            </li>
          </ul>
          <div class="description-container">
            <p>{{product?.showMore ? product?.description : (product?.description | slice:0:50) + '...'}}</p>
            <a *ngIf="product?.description && product.description.length > 100" (click)="toggleShowMore(product)">
              {{product?.showMore ? 'Show less' : 'Read more'}}
            </a>
          </div>
        </div>
        <button type="button" (click)="openDialog(product)">Book An Appointment</button>
      </div>
    </div>
  </div>
  <div class="pagination-container">
    <app-pagination [itemsPerPage]="itemsPerPage" [data]="products" (pageChanged)="onPageChanged($event)"></app-pagination>
  </div>

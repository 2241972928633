import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { Category } from 'src/app/models/product-model';
import { TodoItemNode, TodoItemFlatNode } from 'src/app/models/product-settings-model';

import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-view-category',
  templateUrl: './view-category.component.html',
  styleUrls: ['./view-category.component.scss']
})
export class ViewCategoryComponent {

  @Input() dataSource!: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  @Input() treeControl!: FlatTreeControl<TodoItemFlatNode>;

  

  @Input() isViewCategories: boolean = false;
  @Input() categoryData: any;
  @Output() isViewCategoriesChange = new EventEmitter<boolean>();


  constructor(private spinnerService: SpinnerService) {
    this.spinnerService.showSpinner("Loading...");
    setTimeout(() => {
      this.treeControl.expandAll();
      this.spinnerService.hideSpinner();
    }, 100);

  }
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';
  hasChild = (_: number, node: TodoItemFlatNode) => node.expandable;
  onClose() {
    this.isViewCategoriesChange.emit(false);
  }

  Oninit() {
    
  }
  hasChildren(node: any): boolean {
    return node.children && node.children.length > 0;
  }
}


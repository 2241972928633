
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Signup } from 'src/app/models/SignupModels';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { catchError, forkJoin, of, tap } from 'rxjs';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-end-customer-signup',
  templateUrl: './end-customer-signup.component.html',
  styleUrls: ['./end-customer-signup.component.css']
})
export class EndCustomerSignupComponent implements OnInit {
  UserSignup: Signup = {} as Signup;
  emailSent: boolean = true;
  showError: boolean = false;
  errorMessage: string = '';
  isindividual: boolean = false;
  isProceed: boolean = false;
  selectedAccountType: any;
  accounttype: any[] = [];
  countrycodes: any[] = [];
  badRequest: boolean = false;
  selectedPhoneCode: string = '';
  isSignupButtonEnabled: boolean = false;
  countryCode: string | undefined;
  filter: any;
  isSelected: boolean = false;
  
  email: string = '';
  emailInvalid: boolean = false;
  validNumber: boolean = true;
  usertype='Organization,Individual';
showPassword: boolean = false;
showConfirmPassword: boolean = false;
@ViewChild('countryCodeInput') countryCodeInput!: ElementRef;


  constructor(private http: HttpClient, private authService: AuthService, private sweetAlertService: SweetAlertService) {
    this.UserSignup = { role: 'USER' } as Signup;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (this.countryCodeInput && !this.countryCodeInput.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }
  ngOnInit() {
    this.fetchDropdownData();
  }
  onusertypechange($event: any) {
    if (this.UserSignup.accountType === 'Individual') {
      this.isindividual = true;
    }
    else {
      this.isindividual = false;
    }
  }
  preventCopyPaste(event: Event): void {
    event.preventDefault();
  }
  
  preventContextMenu(event: MouseEvent): void {
    event.preventDefault();
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onlyText(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
 
    if ((charCode >= 48 && charCode <= 57) || 
        (charCode >= 65 && charCode <= 90) || 
        (charCode >= 97 && charCode <= 122) || 
        charCode === 32) { 
      return true;
    }
    return false;
  }
  onlyText2(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if ( 
        (charCode >= 65 && charCode <= 90) || 
        (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }
  
  updateSelectedCountry(event: any) {
    const selectedValue = event.target?.value;
    if (selectedValue) {
      this.selectedPhoneCode = selectedValue;
      this.UserSignup.phoneCode = selectedValue;
    }
  }
  selectedaccountcategory(accountType: string): void {
    this.isSelected = true;
    const selectedType = this.accounttype.find(type => type.id === accountType);
    console.log(selectedType?.accountType.name);
    if(selectedType?.accountType.name === "Organization"){
      this.isindividual = true;}
    else{
      this.isindividual = false;
    }
      }

  isExpanded: boolean = false;

  toggleDropdown() {
    this.isExpanded = !this.isExpanded;
  }
  submitForm(form: NgForm) {
    if (form.valid) {
      this.isProceed = false;
      this.authService.signup(this.UserSignup)
        .subscribe({
          next: (response) => {
            this.emailSent = false;
            this.badRequest = false;
            this.errorMessage = ''; // Clear any previous error messages
          },
          error: (error) => {
            console.error('Error submitting form:', error);
            if (error.status === 400) {
              this.badRequest = true;
              this.emailSent = true;
              this.errorMessage = error.error.message || 'Invalid data entered.';
            } else {
              this.errorMessage = error.message || 'An error occurred while submitting the form.';
            }
            Swal.fire('Error', this.errorMessage? this.errorMessage : 'An error occurred while submitting the form.', 'warning');
          }
        });
    } else {
      this.showError = true;
      this.errorMessage = 'Please fill out all required fields.';
      this.isProceed = true;
    }
  }
  
  fetchDropdownData() {
   
    forkJoin([
      this.authService.getAccounttypes(this.usertype),
      this.authService.getCountrycodes(0, 300)
    ]).subscribe(
      ([accounttypesResponse, countryCodesResponse]) => {
        this.accounttype = accounttypesResponse.data;
        this.countrycodes = countryCodesResponse.data;
      },
      (error) => {
        console.error('Error fetching account types or country codes:', error);
      }
    );
     }
  passwordValidation(password: string): string[] {
    const errors = [];
    const capitalRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /^(?!\s+)(?!.*\s+$)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

    if (typeof password !== "undefined" && password != null && password.length > 0) {
      if (password.length < 8) {
        errors.push('Password must be at least 8 characters');
      } else if (!capitalRegex.test(password)) {
        errors.push('Password must contain at least one capital letter');
      } else if (!numberRegex.test(password)) {
        errors.push('Password must contain at least one number');
      } else if (!specialCharRegex.test(password)) {
        errors.push('Password must contain at least one special character');
      }
    }
    return errors;
  }

  updateSignupButtonState(event: Event) {
    const target = event.target as HTMLInputElement;
    this.isSignupButtonEnabled = target.checked;
  }
  showOptions: boolean = true;
  filteredCountryCodes: {
    phoneCode: string | undefined; countryCode: string
  }[] = [];

  filterOptions(inputValue: string) {
    if (!inputValue) {
      this.filteredCountryCodes = this.countrycodes;
      return;
    }

    const lowerInput = inputValue.toLowerCase();
    this.filteredCountryCodes = this.countrycodes.filter(code =>
      code.countryCode && code.countryCode.toLowerCase().includes(lowerInput)
    );

    const exactMatch = this.countrycodes.find(code =>
      code.countryCode && code.countryCode.toLowerCase() === lowerInput
    );
  
    if (exactMatch) {
      this.selectOption(exactMatch);
    }
    else {
      this.showOptions = true; 
    }
  }

  selectOption(code: any) {
    this.countryCode = code.countryCode; 
    this.UserSignup.phoneCode = code.phoneCode; 
    this.UserSignup.countryCode = code.countryCode;
    this.showOptions = false; 
  }
  emailValidation(email: string) {
    this.emailInvalid = true;
    this.emailInvalid = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }
  phonenumbervalidation(phoneNumber: any) { // Accepting any type to avoid type errors
    this.validNumber = /^[0-9]+$/.test(phoneNumber) &&phoneNumber.length >= 5 && phoneNumber.length <= 15;

  }

  resendVerificationMail(): void {
    this.authService.resendVerificationEmail(this.UserSignup.email)
      .subscribe(
        (response) => {
          if (response && response.status === 'OK') {
            this.sweetAlertService.showSuccess('Email Sent', 'Verification Email Sent');
          } else {
        
            
            this.sweetAlertService.showError('Error', 'Error Sending Verification Email');
          }
        }
        
      );
  }
  
  
}


<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Members</h2>
        <div class="d-flex">
            <img class="edit-profile mx-1" alt="" src="../../assets/images/add.svg" (click)="addMember()">
            <img class="edit-profile " alt="" src="../../assets/images/editprofile.svg" (click)="editMember()">
        </div>
        

    </div>
    <div class="mt-2" *ngIf="!showEditForm">
        <app-mwe-table  [columns]="membersColumns" [showHeader]="false"  [showPagination]="false" [data]="data"></app-mwe-table>
    </div> 
    <!-- <div class="mt-2"  *ngIf="!(members && members.length>0) && !showEditForm">
        <button (click)="addMember()" class="profile-btn">
            <mat-icon>add</mat-icon>
            <p class="ps-2">Add Member</p>
        </button>
    </div> -->
    <form [formGroup]="membersForm" *ngIf="showEditForm">
        <div formArrayName="members">
            <div *ngFor="let memberForm of members.controls;let i=index;">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card p-2 mt-4">
                                <div class="row mt-3">
                                    <div style="margin-top: -20px;"*ngIf="memberForm.get('image')?.invalid &&
                                    memberForm.get('image')?.touched&&
                                    memberForm.get('image')?.hasError('required')">
                                        <mat-error class="error-message " 
                                       >
                                         <img class="error-icon"
                                         loading="lazy" alt="" src="./../../assets/images/alert.svg" />Image
                                                                           is Required</mat-error>
                                    </div>
                                    <div class="col-lg-12 d-flex">
                                      
                                    <div class="mx-1" >
                                        <img src="{{memberForm.get('image')?.value?memberForm.get('image')?.value:'../../../../../assets/images/avatar.svg'}}" class="image">
                                        
                                    </div>
                                    <div class="profile-action mx-1">
                                        <div class="row upload-row">
                                            <div class="col-lg-12 "> 
                                                <input class="d-none" type="file" id="{{'uploadImage'+i}}" accept=".png,.jpg,.jpeg" (change)="uploadImage($event,i)" />
                                        <label class=" upload " for="{{'uploadImage'+i}}"
                                            ><mat-icon>cloud_upload</mat-icon>
                                           
                                        </label>
                                               </div>
                                            <div class="col-lg-12 mt-1"><mat-icon class="delete-icon" (click)="clearImage(i)">delete</mat-icon>  <!--  <button  class="mwe-button mwe-button--secondary ps-3 pe-3 " (click)="clearImage(i)"  >Delete</button> --></div>
                                        </div>
        
                                           
                                    </div>
                                    <div class="mx-1">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Name</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput  formControlName="name">
                                                <!-- <mat-error class="error-message error-msg"
                            *ngIf="memberForm.get('name')?.hasError('required')"><img class="error-icon"
                                loading="eager" alt="" src="./../../assets/images/alert.svg" /> Name is
                            Required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="mx-1">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> Position</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <mat-select placeholder="Select" formControlName="position">
                                                    <mat-option *ngFor="let position of positionDropdown" [value]="position">
                                                        {{position?.name}}
                                                    </mat-option>
                                                </mat-select>
                                                <!-- <mat-error class="error-message error-msg"
                                                *ngIf="memberForm.get('position')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Position is
                                                Required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="mx-1">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Phone Number</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput  formControlName="phone">
                                               <!--  <mat-error class="error-message error-msg"
                                                *ngIf="memberForm.get('phone')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Phone Number is
                                                Required</mat-error>
                                                <mat-error class="error-message error-msg"
                                                *ngIf="memberForm.get('phone')?.hasError('pattern')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Enter valid phone number</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="mt-4 mx-1">
                                        <button class="mwe-button mwe-button--secondary delete-button ps-3 pe-3 " (click)="deleteMember(i)">Delete</button>
                                    </div>
                                </div>
                                </div>
                              

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </form>
    <div class="profile-action " *ngIf="showEditForm">
            
    
        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
    
    
        
    
    
        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
    
    
       
      <!--   <span *ngIf="showErrorMsg">
            <mat-error class="p-2 error-message error-msg"
            ><img class="error-icon"
                loading="eager" alt="" src="./../../assets/images/alert.svg" /> please enter all required fields</mat-error>
        </span> -->
    </div>
    </div>

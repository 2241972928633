<div class="profile-container ">
  <div class="row">
    <div class="col-6">
      <mat-card class="profile-settings">
        <mat-card-content [formGroup]="userForm" style="padding: 3% 8%;">
          <span class="profile-heading">Personal Info</span>
          <div class="form-text">Details about your personal information</div>
          <div class="element-container">
            <div class="profile-image-container">
              <img [src]="profilePhoto" class="profile-image" alt="">
              <div class="button-container">
                <!-- Edit Button -->
                <label class="file-upload-label">
                  <mat-icon>edit</mat-icon>
                  <input type="file" accept="image/*" style="display:none;" id="fileUpload" (change)="handleFileInputChange($event)">
                </label>
                <!-- Delete Button -->
                <button class="delete-photo-btn" *ngIf="isDelete" (click)="handleDeleteButtonClick()">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>            
                      
            <p class="form-heading" style="margin-bottom: 14px;margin-top: 27px;">User Name</p>
            <mat-form-field appearance="outline" class="personal-info">
              <input class="personal-info-input" formControlName="username" matInput type="text" placeholder="username">
            </mat-form-field>
            <p class="form-heading">Email</p>
            <mat-form-field appearance="outline" class="personal-info">
              <input class="personal-info-input" formControlName="email" matInput type="email" placeholder="example@gmail.com" readonly="true">
            </mat-form-field>
          </div>
        </mat-card-content>

      </mat-card>
      <button mat-button class="update-profile-btn" [ngClass]="{'save-button-active': isSaveButtonEnabled, 'save-button-inactive': !isSaveButtonEnabled}"
        (click)="updateUserInfo()"
        [disabled]="!isSaveButtonEnabled"><span
        class="btn-text">Save</span></button>
    </div>
    <div class="col-6">
      <mat-card class="profile-settings">
        <mat-card-content [formGroup]="passwordForm" style="padding: 3% 8%;">
          <div>
            <span class="profile-heading">Change password</span>
            <div class="form-text">Details about your account’s password</div>
          </div>
          <div class="element-container">
            <span class="form-heading">Old Password</span>
            <input class="password-input" matInput type="password" formControlName="password" placeholder="*********">
            <mat-error
              *ngIf="passwordForm.get('password')?.invalid && (passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched)">
              Old Password is required.
            </mat-error>
          </div>
          <div class="element-container">
            <span class="form-heading">New Password</span>
            <input class="password-input" matInput type="password" formControlName="newPassword"
              placeholder="*********">
            <mat-error
              *ngIf="passwordForm.get('newPassword')?.hasError('required') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
              New Password is required.
            </mat-error>
            <mat-error
              *ngIf="passwordForm.get('newPassword')?.hasError('minlength') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
              New Password must be at least 8 characters long.
            </mat-error>
            <mat-error
              *ngIf="passwordForm.get('newPassword')?.hasError('passwordRequirements') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
              New Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special
              character.
            </mat-error>
          </div>
          <div class="element-container">
            <span class="form-heading">Confirm Password</span>
            <input class="password-input" matInput type="password" formControlName="confirmPassword"
              placeholder="*********">
              <mat-error
              *ngIf="passwordForm.get('confirmPassword')?.touched && !passwordForm.get('confirmPassword')?.value">
              Confirm Password is required.
            </mat-error>
            <mat-error
              *ngIf=" passwordForm.get('confirmPassword')?.value && passwordForm.hasError('notSame') && (passwordForm.get('confirmPassword')?.dirty || passwordForm.get('confirmPassword')?.touched)">
              Passwords do not match.
            </mat-error>
          </div>
          
        </mat-card-content>
      </mat-card>
      <button mat-button class="update-password-btn" (click)="onSubmit()"><span
        class="btn-text">Update Password</span></button>
    </div>

  </div>
</div>
<app-website-header></app-website-header>
<div
  class="home-container"
  style="
    background-image: url('assets/images/mri-scanner.png');
    background-size: cover;
  "
>
  <div class="home-text">
    <p class="home-title">
      Welcome to the Ultimate <br /> Digital Medical Expo:
      <!-- <br/> -->
      <br/>
    </p>
    <span class="home-subtext">Embrace the Future with the World’s Premier </span> <br/>
    <span class="home-subtext">  Digital Medical Exhibition </span> 
    <!-- <a (click)="showPopup()" class="btn btn-primary register" >Exhibit with us</a> -->
  </div>
</div>

<div class="section-1">
  <div class="inner-section-1-l inner-section-1">
    <img
      src="assets/images/section-1.png"
      style="border-radius: 10px"
      width="90%"
      height=""
    />
  </div>

  <div class="inner-section-1 inner-section-1-r">
    <!-- <h3  class="sub-text" style="color: #0D87C8;">Welcome to Medworldexpo</h3><br> -->
    <h5 class="home-subtitle" style="color: black">
      The Premier Platform for Connecting<br />
      buyers and sellers across the world.
    </h5>
    <br />
    <p>
      Welcome to Medworld Expo, your premier digital platform for
      revolutionizing the way<br />
      medical equipment are showcased, discovered, and connected.<br />
      We understand the challenges faced by Original Equipment Manufacturers
      (OEMs), distributors, and end customers in the traditional expo
      setting—high costs, limited reach, and logistical hurdles.<br />
      Medworld Expo is designed to address these issues by bringing the expo<br />
      experience online, creating a seamless, cost-effective, and far-reaching
      solution for the global medical industry.
    </p>
  </div>
</div>

<div class="section-2">
  <div class="inner-section-2">
    <div class="vision">
      <div>
        <img src="assets/images/ourvision.png" />
      </div>
      <div>
        <h3 class="sub-text" style="color: black !important">Our Vision</h3>
        <p>
          To democratize access to medical expos, ensuring that innovative
          medical equipment and services are accessible to all, regardless of
          geographical or financial constraints.
        </p>
      </div>
    </div>
  </div>

  <div class="inner-section-2">
    <div class="vision">
      <div>
        <img src="assets/images/ourmission.png" />
      </div>
      <div>
        <h3 class="sub-text">Our Mission</h3>
        <p>
          To provide a comprehensive digital platform that allows OEMs to
          showcase their products, distributors to expand their network and sell
          effectively, and end customers to easily discover and acquire the
          latest medical innovations.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="section-1">
  <div class="inner-section-1 inner-section-1-r">
    <h3 style="color: #0d87c8">
      Transforming the <br />
      future of medical expos
    </h3>
    <br />
    <p>
      Medworld Expo brings together OEMs, distributors, and end customers in a
      virtual environment that fosters collaboration, networking, and growth.
      Our platform offers a suite of tools and features designed to streamline
      product showcasing, business networking, and market access.
    </p>
    <br />
    <p>
      Join us in transforming the future of medical expos. Whether you are an
      OEM looking to expand your reach, a distributor aiming to build your
      network, or an end customer searching for cutting-edge medical solutions,
      Medworld Expo is your go-to platform.
    </p>
  </div>

  <div class="inner-section-1-l inner-section-1">
    <img
      src="assets/images/section-3.png"
      width="90%"
      style="border-radius: 10px"
      height=""
    />
  </div>
</div>

<div
  class="medworld-mid"
  style="background-color: #fff; padding-top: 3rem; padding-bottom: 1rem"
>
  <!-- <h3 style="color:#0D87C8; padding-left: 5rem;">How it will be useful for the users</h3> -->
  <p style="padding-left: 15rem; font-size: large">
    Medworld Expo is designed to bridge the gap between innovation and market
    access, <br />
    making it easier for all stakeholders in the medical industry to connect,
    collaborate, and thrive.
  </p>
  <div class="section-4">
    <div class="inner-section-4">
      <h3 style="color: #0d87c8">Our OEMs</h3>
      <br />

      <h5>Cost-Effective Showcasing</h5>
      <p>
        Avoid the high costs associated with physical expos and reach a global
        audience online.
      </p>

      <h5>Increased Reach</h5>
      <p>
        Showcase products to a wider audience, including international markets.
      </p>

      <h5>Networking Opportunities</h5>
      <p>
        Connect with potential distributors and partners from around the world.
      </p>

      <h5>Efficient Management</h5>
      <p>
        Use the centralized dashboard to manage products, catalogs, inquiries,
        and staff efficiently.
      </p>
    </div>
    <div class="inner-section-4">
      <h3 style="color: #0d87c8">For Distributors</h3>
      <br />

      <h5>Enhanced Visibility</h5>
      <p>Gain visibility to OEMs looking for distribution partners.</p>

      <h5>Product Management</h5>
      <p>
        Easily manage the products you distribute and respond to inquiries
        quickly.
      </p>

      <h5>Networking and Partnerships</h5>
      <p>Build relationships with OEMs and expand your distribution network.</p>

      <h5>Efficient Management</h5>
      <p>
        Streamline operations with tools for staff management, catalog creation,
        and exhibition participation.
      </p>
    </div>
    <div class="inner-section-4">
      <h3 style="color: #0d87c8">For End Customers (Organizations)</h3>
      <br />

      <h5>Access to Latest Innovations</h5>
      <p>
        Discover and acquire the latest medical equipment and technologies from
        various OEMs and distributors.
      </p>

      <h5>Streamlined Search</h5>
      <p>
        Use advanced search features to find specific products and brands
        quickly.
      </p>

      <h5>Educational Opportunities</h5>
      <p>
        Participate in webinars and CME programs to stay updated with industry
        trends and advancements.
      </p>

      <h5>Community Engagement</h5>
      <p>
        Engage with a community of medical professionals and experts for
        insights and networking.
      </p>
    </div>
    <div class="inner-section-4">
      <h3 style="color: #0d87c8">For End Customers (Individuals)</h3>
      <br />

      <h5>Job Opportunities</h5>
      <p>Explore and apply for job opportunities in the medical industry.</p>

      <h5>Product Discovery</h5>
      <p>
        Easily search and learn about the latest medical products and
        technologies.
      </p>

      <h5>Continuing Education</h5>
      <p>Access CME programs to enhance professional knowledge and skills.</p>

      <h5>Community Involvement</h5>
      <p>
        Join webinars and community discussions to stay informed and connected
        with industry peers.
      </p>
    </div>
  </div>
</div>

<app-website-footer></app-website-footer>

<mat-card class="mt-4" >
    <mat-card-content>
        <div class="personal-infomation  mt-1">
            <div class=" d-flex justify-content-between " style="margin-left: 16px;">
                <h2 class="profile-heading">Services Available</h2>
            </div>
        
            
            <div class="mt-1 name-container"
                *ngIf="data?.specialityInfos && this.data.specialityInfos.length>0 || true ">
                <!-- <div *ngFor="let speciality of specialityData" class="my-3">
                    <span class=" speciality " >
                        <label class="med-world-basic-font med-world-basic-font__grey">{{speciality?.name}}</label>
                        
                    </span>
                    <span class="mx-1"></span>
                </div> -->
                <div class="my-3">
                    <span class="services">
                        <label class="med-world-basic-font med-world-basic-font__grey">Emergency Services</label>
                    </span>
                    <span class="services">
                        <label class="med-world-basic-font med-world-basic-font__grey">Impatient Care</label>
                    </span>
                    <span class="services">
                        <label class="med-world-basic-font med-world-basic-font__grey">Surgery</label>
                    </span>
                    <span class="services">
                        <label class="med-world-basic-font med-world-basic-font__grey">Intensive Care Unit (ICU)</label>
                    </span>
                    <span class="" style="margin-left: 25px;">
                        <label class="med-world-basic-font med-world-basic-font__grey" style="font-weight: bold;">+45 Services</label>
                    </span>
                </div>
            </div>
        
        </div>
    </mat-card-content>
    </mat-card>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { IS_PROSPECT } from 'src/app/utils/constants';
import { HomeComponent } from '../home/home.component';

@Component({
  selector: 'app-welcome-popup',
  templateUrl: './welcome-popup.component.html',
  styleUrls: ['./welcome-popup.component.scss']
})
export class WelcomePopupComponent {

   email = '';
   isProspect = false;
   emailInvalid = true;
  constructor(private authService: AuthService , private sweetAlertService:SweetAlertService,private localStoragService:LocalStorageService, @Inject(MAT_DIALOG_DATA) private dialogRef: MatDialogRef<HomeComponent>) {
  }

  onNotifyMeSubmit(){
   
    if(this.email && !this.emailInvalid ){
      this.authService.saveProspect(this.email).subscribe(res =>{
        this.localStoragService.setItem(IS_PROSPECT,true); //this.localStoragService.setItem('prospectMail',);
          this.sweetAlertService.showSuccess('Thank you',' we will contact you soon!');
         this.isProspect = true;
         this.email = '';
       
      })
    }
  }

  emailValidation(email: string) {
    this.emailInvalid = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  }

}

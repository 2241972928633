<div class="mt-4">
    <div class="d-flex">
        <p class="title mt-1 mx-1">Publications</p>
        <img class="edit-profile mx-1" (click)="addPublication()"
            *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
        this.endCustomerData.moreInfos[0]?.publications &&this.endCustomerData.moreInfos[0].publications.length>0) || showEditForm" alt="" src="../../assets/images/add.svg">
        <img class="edit-profile " (click)="editPublication()"
            *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
        this.endCustomerData.moreInfos[0]?.publications &&this.endCustomerData.moreInfos[0].publications.length>0)" alt=""
            src="../../assets/images/editprofile.svg">
    </div>

    <div class="mt-2"
        *ngIf="!(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.publications &&this.endCustomerData.moreInfos[0].publications.length>0) && !showEditForm">
        <button (click)="addPublication()" class="profile-btn">
            <mat-icon>add</mat-icon><p class="ps-2">Add Publications</p> </button>
    </div>
    <div class="mt-2"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
        this.endCustomerData.moreInfos[0]?.publications &&this.endCustomerData.moreInfos[0].publications.length>0)&& !showEditForm">
        <app-mwe-table [columns]="publicationColumns" [showPagination]="false" [showHeader]="false"
            [data]="data" (selectedAction)="selectedAction($event)"></app-mwe-table>
    </div>
   <div class="mt-2" *ngIf="showEditForm">
    <form [formGroup]="publicationsForm">
        <div formArrayName="moreInfos">
            <div *ngFor="let moreInfoForm of moreInfos.controls;let i=index;" [formGroupName]="i">
                <div formArrayName="publications">
                    <div *ngFor="let publicationForm of publications(i).controls;let j=index;" [formGroupName]="j">
                        <div class="row">
                            <div class="card p-4 mt-2 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Title</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="title">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Link</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="url">
                                            </mat-form-field>
                                            <mat-error *ngIf="publicationForm?.get('url')?.hasError('pattern')">
                                                <img class="error-icon"
                                    loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid URL starts with https:// or www.
                                              </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Published By</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="publishedby">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> Publication Date</p>
                                        </div>

                                        <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                            <input matInput [matDatepicker]="publicationDate"  formControlName="publicationDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="publicationDate"></mat-datepicker-toggle>
                                            <mat-datepicker #publicationDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Author(s)</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="authors">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div >
                                                <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Summary</p>
                                            </div>
                                            <div>
                                                <mat-form-field appearance="outline" class="input-font b-r w-100 description">
                                                    <textarea   matInput formControlName="summary"  rows="4" 
                                                  ></textarea>
                                                </mat-form-field>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="row mt-2" >
                                        <div class="col-lg-3">
                                            <button class=" delete" (click)="deletePublication(i,j)" ><img alt="" src="./../../assets/images/Trash bin.svg">
                                                <span class="deltext">Delete</span>
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="profile-action my-3" >
            
    
        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
    
    
        
    
    
        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
    
    
    
    </div>
   </div>

</div>
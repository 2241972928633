<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading" id="education">Education Details</h2>
        <div class="d-flex">
            <img class="edit-profile mx-1" (click)="addEducation()"
                *ngIf="(endCustomerData?.educationInfos && this.endCustomerData.educationInfos.length>0) || showEditForm" alt=""
                src="../../assets/images/add.svg">
            <img class="edit-profile " (click)="editEducation()"
                *ngIf="(endCustomerData?.educationInfos && this.endCustomerData.educationInfos.length>0)&&!showEditForm " alt=""
                src="../../assets/images/editprofile.svg">
        </div>

    </div>
    <div class="mt-2"
        *ngIf="!(endCustomerData?.educationInfos && this.endCustomerData.educationInfos.length>0) && !showEditForm">
        <button (click)="addEducation()" class="profile-btn">
            <mat-icon>add</mat-icon>
            <p class="ps-2">Add Education</p>
        </button>
    </div>

    <div class="mt-2"
        *ngIf="(endCustomerData?.educationInfos && this.endCustomerData.educationInfos.length>0) && !showEditForm">


<app-card-profile [image]="degreeIcon" [data]="data"   [isEducationCard]="true" >


</app-card-profile>


        <!-- <app-mwe-table [showPagination]="false" [columns]="educationDetailsColumns" [showHeader]="false"
            [data]="data">
        </app-mwe-table> -->
    </div>


    <form [formGroup]="educationDetailsForm" class="mt-2" *ngIf="showEditForm">
        <div formArrayName="educationInfos">
            <div *ngFor="let educationForm of educationInfos.controls;let i=index;">
                <div [formGroupName]="i">
                    <div class="row mb-4">
                        <div class="col-lg-12">
                            <div class="card p-4 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Degree</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="degree"	>
                                                <mat-error class="error-message error-msg"
                                                *ngIf="educationForm.get('degree')?.hasError('required')"><img class="error-icon"
                                                    loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Degree is
                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0 mx-2"> Year</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields w-45 mx-2 b-r">
                                                <mat-select placeholder="From" formControlName="yearFrom">
                                                    <mat-option *ngFor="let year of yearDropdown" [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-message error-msg" style="margin-top: -2px;"
                                                *ngIf="educationForm.get('yearFrom')?.hasError('required')"><img class="error-icon"
                                                    loading="lazy" alt="" src="./../../assets/images/alert.svg" /> From is
                                                Required</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="fields w-45 b-r">
                                                <mat-select placeholder="To" formControlName="yearTo"	>
                                                    <mat-option *ngFor="let year of futureYearDropdown" [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-message error-msg"
                                                *ngIf="educationForm.get('yearTo')?.hasError('required')"><img class="error-icon"
                                                    loading="lazy" alt="" src="./../../assets/images/alert.svg" /> To is
                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Institute Location</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="location">
                                                <mat-error class="error-message error-msg"
                                                                *ngIf="educationForm.get('location')?.hasError('required')"><img class="error-icon"
                                                                    loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Institute Location is
                                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Field of Study</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="fieldOfStudy"	>
                                                <mat-error class="error-message error-msg"
                                                                *ngIf="educationForm.get('fieldOfStudy')?.hasError('required')"><img class="error-icon"
                                                                    loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Field of Study is
                                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" >
                                    <div class="col-lg-3">
                                     
                                        <button class="d-flex delete"  (click)="deleteEducation(i)"><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                </div>
                                
                                </div>
                                </div>
                                </div>
                    </div>
                    </div>
                    </div>
                    </form>
                    <div class="profile-action " *ngIf="showEditForm">
            
    
                        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                    
                    
                        
                    
                    
                        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                    
                    
                       
                      <!--   <span *ngIf="showErrorMsg">
                            <mat-error class="p-2 error-message error-msg"
                            ><img class="error-icon"
                                loading="eager" alt="" src="./../../assets/images/alert.svg" /> please enter all required fields</mat-error>
                        </span> -->
                    </div>
</div>
<app-website-header></app-website-header>
<div class="mx-3 mt-2">
    <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="" (click)="navigateTo()">
    <a class="breadcrummb ps-2" style="color:#858585 ;" (click)="navigateTo()">Home</a>
    <a class="breadcrummb" style="color:#858585 ;">/</a>
    <a class="breadcrummb" style="color:#858585 ;">Health centers</a>
    <a class="breadcrummb" style="color:#858585 ;">/</a>
    <a class="breadcrummb" style="color:#858585 ;" (click)="navigateTo()">Hospitals</a>
    <a class="breadcrummb" style="color:#858585 ;" >/</a>
    <a class="breadcrummb" style="color:#000000 ;" >{{hospitalPortfolio?.companyName}} </a>
    <a class="breadcrummb" style="color:#000000 ;" > </a>
  </div>

    <div class="tab-container">
      <div class="tab-header">
        <div class="tab" [class.active]="activeTab === 0" (click)="selectTab(0)">Company Profile</div>
        </div>

        <!-- <div class="action-buttons">
            <button class="view-button" (click)="viewProfile()"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
            <button class="download-button" (click)="downloadProfile()"><img src="assets/images/download.svg" alt="Download"/>Download</button>
          </div> -->

          <div class="action-buttons">
            <button class="view-button"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
            <button class="download-button" (click)="downloadHospitalPortfolio()"><img src="assets/images/download.svg" alt="Download"/>Download</button>
          </div>
    </div>

    <div class="">
        <div class="hospital-contact-info">
            <div class="hospital-info">
                <div class="logo">
                    <img [src]="hospitalPortfolio?.logo" alt="">
                </div>
                <div class="hospital-details" style="line-height: 30px;">
                    <div class="title">
                        <h2>{{hospitalPortfolio?.companyName}}</h2>
                    </div>
                    <div class="tab-content">
                        <div class="ratings-star">
                          <div  *ngFor="let j of [1, 2, 3, 4, 5]">
                            <img  
                                  src="../../../assets/images/full-star-rating.svg" />
                                  <!-- *ngIf="j <= averageRating" />  -->
                            <img  
                                  src="../../../assets/images/empty-star-rating.svg" 
                                  *ngIf="j > averageRating" />
                          </div>
                          <!-- <div class="d-flex align-items-center justify-content-center">
                            5.0 out of <strong class="mx-1"> ({{reviews.length}}) </strong> Reviews
                          </div><br><br><hr> -->
                        </div>
                        <div class="rating-number">
                         <!-- Reviews Start -->
                         <span style="color: rgba(13, 135, 200, 1);
                         font-size: 12px;
                         font-weight: 600;
                         line-height: 28px;"> 
                         <!-- {{ averageRating || 0 }} out of 5 </span> <br> -->
                         (5.0) out of (368) Reviews </span> <br>
                        </div>
                </div>

                <div class="hospital-place" style="position: relative;">
                    <p><span style="font-weight: bold;">{{hospitalPortfolio?.state}}</span>, {{hospitalPortfolio?.country}}</p>

                    <!--Temporary data -->
                    <p>Super Speciality Hospital | 175+ Beds | 50+ Doctors</p> 

                </div>
                <div class="contact-button-container">
                    <button class="contact-now">Contact Now</button>
                  </div>
            </div>
        </div>
    </div>

    <div class="tab-container information-tab-container">
        <div class="tab-header">
          <div class="tab" [class.active]="activeInfoTab === 0" (click)="currentInfoTab(0)">More Info</div>
          <div class="tab" [class.active]="activeInfoTab === 1" (click)="currentInfoTab(1)">Doctors</div>
          <div class="tab" [class.active]="activeInfoTab === 2" (click)="currentInfoTab(2)">Reviews</div>
          <div class="tab" [class.active]="activeInfoTab === 3" (click)="currentInfoTab(3)">Services</div>
          <div class="tab" [class.active]="activeInfoTab === 4" (click)="currentInfoTab(4)">Gallery</div>
          </div>

          <div *ngIf="activeInfoTab === 0" class="tab-content">
            <app-hospital-profile-info [data]="hospitalPortfolio"></app-hospital-profile-info>
          </div>
          <div *ngIf="activeInfoTab === 1" class="tab-content">
            <app-doctors [data]="hospitalPortfolio"></app-doctors>
          </div>
          <div *ngIf="activeInfoTab === 2" class="tab-content">
            <app-hospital-reviews [data]=" hospitalPortfolio "></app-hospital-reviews>
          </div>
          <div *ngIf="activeInfoTab === 3" class="tab-content">
            <app-hospital-services [data]=" hospitalPortfolio "></app-hospital-services>
          </div>
      </div>
   </div>   

      
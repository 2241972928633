
<div class="brand-header">
    <ng-container *ngIf="data.logo">
      <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
    </ng-container>
    <div class="brand-details">
      <h1>{{ data.companyName | camelcase}}</h1>
      <ng-container *ngIf="data.url">
        <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
      </ng-container>
    </div>
  </div>
  <div class="total-results">
    <strong>Total Results {{ data.length }} </strong>
  </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
            <app-brand-product-filter [filterColumns]="['Products', 'Region']" [data]="originalData" (filteredData)="onFilteredData($event)"></app-brand-product-filter>
        </div>
        <div class="col-lg-9">
          <ng-container *ngIf="showNoRecordsFounds">
            <div class="no-records-found"> No records Found </div>
          </ng-container>
          <div class="row">
            <div class="col-lg-4" *ngFor="let brand of paginatedItems; let i = index">
              <div (click)="viewPortfolio(brand)"  class="card" (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = -1"
                [ngStyle]="{'background-color': hoveredIndex === i ? 'rgba(0, 0, 0, 0.6)' : 'white', 'color': hoveredIndex === i ? '#fff' : 'inherit',}">
                <img height="150" [src]="brand.logo" alt="{{brand.name}}" 
                  [style.opacity]="hoveredIndex === i ? 0.1 : 1"/>
                <p class="brand-name" [style.opacity]="hoveredIndex === i ? 0.1 : 1">{{brand.name}}</p>
                <p class="text-center" [style.display]="hoveredIndex === i ? 'block' : 'none'"
                  [style.background-color]="hoveredIndex === i ? '' : 'rgba(0, 0, 0, 0.6)'"
                  [style.color]="hoveredIndex === i ? '#fff' : 'rgba(0, 0, 0, 0.6)'" [style.font-weight]="'600'">
                  View {{brand.name}} Portfolio
  
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-container">
        <app-pagination [itemsPerPage]="itemsPerPage" [data]="brands" (pageChanged)="onPageChanged($event)"></app-pagination>
      </div>
    </div>
  
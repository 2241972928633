<div class="medworld-page-container">
    <div class="spacer"></div> 
    <div class="add-oem-text">Add OEM</div>
    <div class="medworld-titles-container" style="margin-top: 10px; margin-bottom: 10px;">
      <ng-container *ngFor="let label of labels; let i = index">
          <div class="frame-container" *ngIf="i !== labels.length - 1">
              <div class="step-symbol-wrapper">
                  <img class="step-symbol-icon" loading="lazy" [src]="step >= i ? './assets/images/step symbol after.svg' : './assets/images/Step Symbol.svg'"/>
              </div>
              <div class="step-information">
                  <p class="steps" [ngClass]="{'active-company': step >= i }">{{label}}</p> 
              </div>
              <div class="financial-info-symbol-track">
                  <div class="step-trail">
                      <ng-container *ngFor="let circle of circles">
                          <div class="circle" [ngClass]="{'active-circle': step >= i }"></div>
                      </ng-container>
                  </div>
              </div>
          </div>
      </ng-container>
      <div class="frame-container">
          <div class="step-symbol-wrapper">
              <img class="step-symbol-icon" loading="lazy" [src]="step === labels.length - 1 ? './assets/images/step symbol after.svg' : './assets/images/Step Symbol.svg'"/>
          </div>
          <div class="step-information">
              <p class="steps" [ngClass]="{'active-company': step === labels.length - 1 }">Financial Information</p>
          </div>
      </div>
  </div>
      <div [ngSwitch]="step">
        <form *ngSwitchCase="0"   >
            <div class="info-container">
                <div class="row">
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Name</label><br>
                      <input class="input1" [(ngModel)]="data.company.name" name="name" type="text" >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Email</label><br>
                      <input class="input1" [(ngModel)]="data.company.email" name="email" type="text">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Company Name</label><br>
                      <input class="input1" [(ngModel)]="data.company.companyName" name="companyName" type="text">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Brand Name</label><br>
                      <input class="input1" [(ngModel)]="data.company.brandName" name="brandName" type="text">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12 ">
                    <div class="inputs">
                      <label class="label-styles">Established In</label><br>
                      <input class="input1" [(ngModel)]="data.company.establishedIn" name="establishedIn" type="date">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">No.of Employees</label><br>
                      <input class="input1 number-input" [(ngModel)]="data.company.numberOfEmployees" name="numberOfEmployees" type="number">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Website Url</label><br>
                      <input class="input1" [(ngModel)]="data.company.websiteUrl" name="websiteUrl" type="number">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="label-styles">Company Logo &nbsp;</label><br>
                    <div class="inputs file-input-container">
                      <input type="file" (change)="onFileSelected($event, 'companyLogo')" name="companyLogo" id="companyLogoInput">
                      <span class="drag-drop-text" *ngIf="!isFileInputClickedCompanyLogo || !data.company.companyLogo.name">
                        <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;Upload Company Logo
                      </span>
                      <span class="drag-drop-text2" *ngIf="data.company.companyLogo && isFileInputClickedCompanyLogo">
                        <span>{{ data.company.companyLogo.name }}</span>
                        <ng-container *ngIf="data.company.companyLogo.name">
                          <button class="close-icon" (click)="removeFile('companyLogo')">&times;</button>
                        </ng-container>
                      </span>
                    </div>
                    <div class="preview-container" *ngIf="previewImage">
                      <img [src]="previewImage" class="preview-image" alt="Preview Image">
                      <img src="./assets/images/cancel.svg" alt="Remove Logo" (click)="removeLogo()" class="remove-logo-icon" height="12px" width="12px">
                    </div>
                  </div>
                  
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Select Continent &nbsp;</label><br>
                      <select class="form-control" [(ngModel)]="data.company.continent" name="continent">
                        <option *ngFor="let cont of continents" [value]="cont">{{cont.continentName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Select Country&nbsp;</label><br>
                      <input class="input1" [(ngModel)]="data.company.country" type="text" name="country">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Select State &nbsp;</label><br>
                      <input class="input1" [(ngModel)]="data.company.state" type="text" name="state">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Select City &nbsp;</label><br>
                      <input class="input1" [(ngModel)]="data.company.city" type="number" name="city">
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-8 col-sm-12">
                    <div class="inputs" >
                      <label class="label-styles" >Address &nbsp;</label><br>
                      <input class="input2" [(ngModel)]="data.company.address" type="text" name="address" >
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Zip Code&nbsp;</label><br>
                      <input class="input1" [(ngModel)]="data.company.zipCode" type="number" name="zipCode">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="Certificates-header">
                      Certificates
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">CR Copy&nbsp;</label><br>
                      <div class="inputs file-input-container" style="height: 40px;">
                        <input type="file" (change)="onFileSelected($event , 'crCopy')" name="crCopy" id="fileInput">
                        <span class="drag-drop-text" *ngIf="!isFileInputClickedCrCopy || !data.company.crCopy.name">
                          <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;Upload CR Copy Pdf 
                        </span>
                        <span class="drag-drop-text2" *ngIf="data.company.crCopy && isFileInputClickedCrCopy">
                          <span>{{ data.company.crCopy.name }}</span>
                        <ng-container *ngIf="data.company.crCopy.name">
                          <button class="close-icon" (click)="removeFile('crCopy')">&times;</button>
                        </ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Business License&nbsp;</label><br>
                      <div class="inputs file-input-container" style="height: 40px;">
                        <input type="file" (change)="onFileSelected($event , 'businessLicense')" name="businessLicense" id="fileInput">
                        <span class="drag-drop-text" *ngIf="!isFileInputClickedBusinessLicense || !data.company.businessLicense.name">
                          <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;Upload Business License Copy Pdf 
                        </span>
                        <span class="drag-drop-text2" *ngIf="data.company.businessLicense && isFileInputClickedBusinessLicense">
                          <span>{{ data.company.businessLicense.name }}</span>
                        <ng-container *ngIf="data.company.businessLicense.name">
                          <button class="close-icon" (click)="removeFile('businessLicense')">&times;</button>
                        </ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">European Certificates&nbsp;</label><br>
                      <div class="inputs file-input-container" style="height: 40px;">
                        <input type="file" (change)="onFileSelected($event , 'europeanCertificates')" name="europeanCertificates" id="fileInput">
                        <span class="drag-drop-text" *ngIf="!isFileInputClickedEuropeanCertificates || !data.company.europeanCertificates.name">
                          <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;Upload European Certificates Copy Pdf 
                        </span>
                        <span class="drag-drop-text2" *ngIf="data.company.europeanCertificates && isFileInputClickedEuropeanCertificates">
                          <span>{{ data.company.europeanCertificates.name }}</span>
                        <ng-container *ngIf="data.company.europeanCertificates.name">
                          <button class="close-icon" (click)="removeFile('europeanCertificates')">&times;</button>
                        </ng-container>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="inputs">
                      <label class="label-styles">Other Certificates (if any)&nbsp;</label><br>
                      <div class="inputs file-input-container">
                        <input type="file" (change)="onFileSelected($event , 'otherCertificates')" name="otherCertificates" id="fileInput">
                        <span class="drag-drop-text" *ngIf="!isFileInputClickedOtherCertificates || !data.company.otherCertificates.length">
                          <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;Drag files here to upload (or) <u>Select Files</u>
                        </span>
                        <span class="drag-drop-text2" *ngIf="data.company.otherCertificates && isFileInputClickedOtherCertificates">
                          <ng-container *ngFor="let file of data.company.otherCertificates">
                            <span>{{ file.name }}</span>
                            <button class="close-icon" (click)="removeFiles('otherCertificates', file)" style="z-index: 999;">&times;</button>&nbsp;
                        </ng-container>
                        <button class="add-files">Add More</button>
                        </span>
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="inputs">
                      <label class="label-styles">Subscription Plan&nbsp;</label><br>
                      <input class="input1" [(ngModel)]="data.company.subscriptionPlan" type="text"  name="subscriptionPlan">
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-4 col-sm-12">
                    <div class="salutation">
                        <p class="label-styles">Do you have any branchs?</p>
                        <input type="radio" id="mr" name="salutation" value="mr" /><label for="mr">No</label>&nbsp;
                        <input type="radio" id="ms" name="salutation" value="ms" /><label for="ms">Yes</label>
                      </div>
                  </div>
                </div>
              </div>
          <div class="button-container" >
            <button type="button" class="button button-primary" (click)="next()">Continue</button>
            <button  style="height: 40px;" class="button button-inverse">Back</button>
          </div>
        </form>

        <div *ngSwitchCase="1">
          <form *ngFor="let company of data.branches">
            <div class="info-container">
                <div class="row">
                  <div class="col-md-3 col-sm-6 col-12 inputs">
                    <label class="label-styles">Company Name:</label><br>
                    <input class="form-control input1" [(ngModel)]="company.name" name="name" placeholder="Enter Company Name">
                  </div>
                  <div class="col-md-3 col-sm-6 col-12 inputs">
                    <label class="label-styles">Brand Name</label><br>
                    <input class="form-control input1" [(ngModel)]="company.brandName" name="brandName" type="text">
                  </div>
                  <div class="col-md-6 col-sm-12 col-12 inputs">
                    <label class="label-styles">Address</label><br>
                    <input class="form-control input1" [(ngModel)]="company.address" name="address" type="text">
                  </div>
                  <div class="w-100"></div>
                  <div class="col-md-3 col-sm-6 col-12 inputs">
                    <label class="label-styles">Contact Person</label><br>
                    <input class="form-control input1" [(ngModel)]="company.contactPerson" name="contactPerson" type="text">
                  </div>
                  <div class="col-md-3 col-sm-6 col-12 inputs">
                    <label class="label-styles">Contact Phone Number</label><br>
                    <input class="form-control input1" [(ngModel)]="company.contactNumber" name="contactNumber" type="tel">
                  </div>
                  <div class="col-md-6 col-sm-12 col-12 inputs">
                    <label class="label-styles">Contact Email</label><br>
                    <input class="form-control input1" [(ngModel)]="company.contactEmail" name="contactEmail" type="email">
                  </div>
                    <button class="button button-delete" (click)="deleteBranch(company)">
                      <img src="./assets/images/Trash bin.svg" alt="Trash Bin" class="button-icon">Delete</button>
                </div>
              </div>
          </form>
        <button class="button button-light" (click)="addBranch()">
          <img src="./assets/images/plus.svg" alt="Plus Icon" class="button-icon">Add More</button>
        <div class="button-container"  >
          <button type="button" class="button button-primary" (click)="next()">Continue</button>
          <button type="submit" style="height: 40px;" class="button button-inverse" (click)="next()">Cancel</button>
          <button (click)="goBack()"  style="height: 40px;" class="button button-inverse">Back</button>
        </div>
      </div>  
      <form *ngSwitchCase="2">
        <div class="info-container" *ngFor="let employee of data.contacts; let i = index">
            <div class="row">
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Select Country</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].country" name="country{{i}}" type="text" placeholder="Select Country">
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Select State</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].state" name="state{{i}}" type="text" placeholder="Select State">
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 col-12">
                  <div class="inputs">
                    <label class="label-styles">Select City</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].city" name="city{{i}}" type="text" placeholder="Select City">
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Employee Name</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].name" name="name{{i}}" placeholder="Enter Employee Name">
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Employee Position</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].position" name="position{{i}}" type="text" placeholder="Enter Employee Position">
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Email Address</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].email" name="email{{i}}" type="email" placeholder="Enter Employee Email">
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                  <div class="inputs">
                    <label class="label-styles">Phone No</label><br>
                    <input class="form-control input1" [(ngModel)]="data.contacts[i].phone" name="phone{{i}}" type="number" placeholder="Enter Phone Number">
                  </div>
                </div>
                  <button class="button button-delete" (click)="deleteContact(i)">
                    <img src="./assets/images/Trash bin.svg" alt="Trash Bin" class="button-icon">Delete
                  </button>
            </div>
          </div>
        <button class="button button-light" (click)="addContact()">
          <img src="./assets/images/plus.svg" alt="Plus Icon" class="button-icon">Add More</button>
      
        <div class="button-container"  >
          <button type="button" class="button button-primary" (click)="next()">Continue</button>
          <button type="submit" style="height: 40px;" class="button button-inverse" (click)="next()">Skip</button>
          <button (click)="goBack()"  style="height: 40px;" class="button button-inverse">Back</button>
        </div>
      </form>
      <form *ngSwitchCase="3" >
        <div class="info-container" *ngFor="let product of data.productCategories; let i = index">
            <div class="row">
                <div class="inputs col-md-3 col-sm-6 col-12">
                  <label class="label-styles">Choose primary category:</label><br>
                  <input class="form-control input1" [(ngModel)]="data.productCategories[i].primaryCategory" name="primaryCategory{{i}}">
                </div>
                <div class="inputs col-md-3 col-sm-6 col-12">
                  <label class="label-styles">Choose secondary category:</label><br>
                  <input class="form-control input1" type="text" [(ngModel)]="data.productCategories[i].secondaryCategory" name="secondaryCategoryt{{i}}">
                </div>
                <div class="inputs col-md-3 col-sm-6 col-12">
                  <label class="label-styles">Choose tertiary category:</label><br>
                  <input class="form-control input1" type="text" [(ngModel)]="data.productCategories[i].tertiaryCategory" name="tertiaryCategory{{i}}">
                </div>
                <div class="inputs col-md-3 col-sm-6 col-12">
                  <label class="label-styles">Choose product range:</label><br>
                  <input class="form-control input1" type="number" [(ngModel)]="data.productCategories[i].productRange" name="productRange{{i}}">
                </div>
            </div>
          </div>
          <button class="button button-light" (click)="addCategory()">
            <img src="./assets/images/plus.svg" alt="Plus Icon" class="button-icon">Add More</button>
          <div class="button-container"  >
            <button type="button" class="button button-primary" (click)="next()">Continue</button>
            <button type="submit" style="height: 40px;" class="button button-inverse" (click)="next()">Cancel</button>
            <button (click)="goBack()"  style="height: 40px;" class="button button-inverse">Back</button>
          </div>
        </form>
        
         <form *ngSwitchCase="4">
            <div class=" inputs">
                <div class="salutation" style="margin-left: 10px;">
                    <p class="headings-styles">Distributors?</p>
                    <input type="radio" value="yes" (change)="toggleDistributorDetails($event)"/><label for="ms">Yes</label>&nbsp;
                    <input type="radio" value="no" (change)="toggleDistributorDetails($event)" /><label for="mr">No</label>
                  </div>
              </div>
          <div *ngIf="showDistributorDetails">
            <div class="info-container">
                <div class="row">
                    <div class="inputs col-md-3">
                      <label class="label-styles">Select Continent:</label><br>
                      <input class=" input1" [(ngModel)]="data.distributors.continent" placeholder="Select Continent">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Select Country:</label><br>
                      <input class=" input1" [(ngModel)]="data.distributors.country" type="text" placeholder="Select Country">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Select State:</label><br>
                      <input class=" input1" [(ngModel)]="data.distributors.state" type="text" placeholder="Select State">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Select City:</label><br>
                      <input class=" input1" [(ngModel)]="data.distributors.city" type="text" placeholder="Select City">
                    </div>
                    <div class="full-width col-md-12">
                      <label class="label-styles">Distributor Company:</label><br>
                      <input class=" input1" [(ngModel)]="data.distributors.distributorCompany" type="text">
                    </div>
                </div>
              </div>     
                <div><p class="headings-styles" style="margin-left: 10px;">Didn't find your distributor?</p></div>
                  <button class="button button-light" (click)="addDistributor()">
                    <img src="./assets/images/plus.svg" alt="Plus Icon" class="button-icon">Add Distributor</button>
          </div>
          <div *ngFor="let distributor of data.newDistributors; let i = index">
            <div class="info-container">
                <div class="row">
                    <div class="full col-md-12"><h4>New Distributor</h4></div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Company Name:</label><br>
                      <input class="form-control input1" [(ngModel)]="distributor.companyName" name="companyName{{i}}" type="text">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Email Address:</label><br>
                      <input class="form-control input1" type="email" [(ngModel)]="distributor.emailAddress" name="emailAddress{{i}}">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Phone Number:</label><br>
                      <input class="form-control input1" type="number" [(ngModel)]="distributor.phoneNumber" name="phoneNumber{{i}}">
                    </div>
                    <div class="inputs col-md-3 col-sm-6 col-12">
                      <label class="label-styles">Address:</label><br>
                      <input class="form-control input1" [(ngModel)]="distributor.address" name="address{{i}}" type="text">
                    </div>
                    <button class="button button-delete" style="margin-top: 27px;" (click)="deleteDistributor(i)">
                      <img src="./assets/images/Trash bin.svg" alt="Trash Bin" class="button-icon">Delete
                    </button>
                </div>
              </div> 
          </div>
          <div class="button-container"  >
            <button type="submit" style="height: 40px;" class="button button-primary" (click)="next()">Continue</button>
            <button type="submit" style="height: 40px;" class="button button-inverse" (click)="next()">Skip</button>
            <button (click)="goBack()" style="height: 40px;" class="button button-inverse">Back</button>
          </div>
        </form>
        
   <form *ngSwitchCase="5" >
    <div class="info-container" *ngFor="let financial of data.financials; let i = index">
        <div class="row">
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Financial Year:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].year" name="year{{i}}" >
            </div>
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Select Currency:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].currency" name="currency{{i}}" type="text" >
            </div>
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Networth:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].networth" name="networth{{i}}" type="text" >
            </div>
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Turnover:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].turnover" name="turnover{{i}}" type="number" >
            </div>
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Profit:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].profit" name="profit{{i}}" type="number" >
            </div>
            <div class="inputs col-lg-2 col-md-3 col-sm-6 col-12">
              <label class="label-styles">Year on Year Growth %:</label><br>
              <input class="form-control inp" [(ngModel)]="data.financials[i].growth" name="growth{{i}}" type="number" >
            </div>
            <div class="full col-md-12"></div>
            <button class="button button-delete" (click)="deleteFinancial(i)">
              <img src="./assets/images/Trash bin.svg" alt="Trash Bin" class="button-icon">Delete
            </button>
        </div>
      </div>
    <button class="button button-light" (click)="addFinancial()">
      <img src="./assets/images/plus.svg" alt="Plus Icon" class="button-icon">Add More</button>
    <div class="button-container"  >
      <button type="submit" style="height: 40px;" class="button button-primary" (click)="onSubmit()" >Create</button>
      <button (click)="goBack()" style="height: 40px;" class="button button-inverse">Back</button>
    </div>
  </form> 
  </div>
  </div>
     
  
  
  
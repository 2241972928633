<div *ngIf="!addWebinar && !updateWebinar && !viewWebinar">
    <app-mwe-table [title]="'Webinars'" [columns]="columns" showAddButtonName="Add Webinar" [showAddButton]="true" [showSearch]="true" [data]="data" [showHeader]="true" (selectedAction)="selectedAction($event)" ></app-mwe-table>
</div>

<div *ngIf="addWebinar">
    <app-add-webinar [addWebinar]="addWebinar" (backEvent)="close($event)" (selectedAction)="selectedAction($event)" ></app-add-webinar>
</div>

<div *ngIf="updateWebinar">
    <app-add-webinar [updateWebinar]="updateWebinar" (backEvent)="close($event)"  [updateWebinarID]="updateWebinarID"   (selectedAction)="selectedAction($event)" ></app-add-webinar>
</div>

<div *ngIf="viewWebinar">
    <app-view-webinar [viewWebinar] ='viewWebinar' [viewWebinarID]="viewWebinarID" (backEvent)="close($event)" (selectedAction)="selectedAction($event)" ></app-view-webinar>
</div> 
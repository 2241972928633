<div class="container-fluid">
  <div [formArrayName]="'productCategories'">
    <div class="row">
      <div class="col-lg-12" *ngIf="productCategoriesForm">
        <div class="my-2">
          <mat-label class="med-world-basic-font med-world-basic-font__medium secondary_black_color">Select Category</mat-label>
        </div>
        <div>
          <mat-form-field appearance="outline" class="w-100 input-font equipment" [class.equipment_increase]="productCategoriesForm.value.length > 5">
            <mat-select placeholder="Select" [formControl]="selectControl" multiple>
              <mat-select-trigger>
                <mat-chip-listbox>
                  <mat-chip class="chip" *ngFor="let category of productCategoriesForm.value; let i = index" [removable]="true" (removed)="remove(i)">
                    {{ getCategory(category) }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                </mat-chip-listbox>
              </mat-select-trigger>
              <mat-option *ngFor="let category of categories" [value]="category.id">{{ category?.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { CareerService } from 'src/app/services/career.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-view-all-job-applications',
  templateUrl: './view-all-job-applications.component.html',
  styleUrls: ['./view-all-job-applications.component.scss']
})
export class ViewAllJobApplicationsComponent implements OnInit {

  columns: ListColumn[] = [
    { name: 'Sr.No', property: 'sNo', visible: true, isModelProperty: false },
    { name: 'Applicant Name', property: 'applicantName', visible: true, isModelProperty: true, isText: true },
    { name: 'Email Address', property: 'applicantEmail', visible: true, isModelProperty: true, isText: true },
    { name: 'Phone', property: 'phoneNumber', visible: true, isModelProperty: true, isText: true },
    { name: 'Status', property: 'status', visible: true, isModelProperty: true, isRequestStatus: true },
    { name: 'Date', property: 'applicationDate', visible: true, isModelProperty: true, isDatePipe: true },
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: 'assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
        { imageUrl: 'assets/images/download.svg', isImage: true, actionType: MweConstants.fileDownloadAction },
      ]
    },
  ] as ListColumn[];
  data: any[] = [];
  showNoRecordsFounds: boolean = true;
  selectedData: any;
  isDesktopView: boolean = false;
  isXLLaptopView: boolean = false;
  isLargeLaptopView: boolean = false;
  constructor(public dialog: MatDialog, private careerService: CareerService, private breakpointObserver: BreakpointObserver,
    private snackbarService: SnackbarService, private readonly router: Router, private readonly route: ActivatedRoute,) {
    this.breakpointObserver.observe(
      ["(min-width: 1920px)", "(min-width: 1536px)"]
    ).subscribe((result: BreakpointState) => {
      if (result.breakpoints['(min-width: 1920px)']) {
        this.isDesktopView = true;
      } else if (result.breakpoints['(min-width: 1536px)']) {
        this.isXLLaptopView = true;
      } else if (result.breakpoints['(max-width: 1366px)']) {
        this.isLargeLaptopView = true;
      } else {

      }
    });
  }

  ngOnInit(): void {
    this.getAllJobApplications();
    this.careerService.setCareersBreadCrumbs(['Careers', 'Applications']);
  }

  private getAllJobApplications() {
    const jobPostId: any = this.router.url.split('?')[0].split('/').pop();
    this.showNoRecordsFounds = false;
    this.data = [];
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat("/", jobPostId, "/applications"), null).subscribe({
      next: (data) => {
        if (data && data?.data && data.data.length > 0) {
          this.data = data.data;
          this.showNoRecordsFounds = false;
        } else {
          this.showNoRecordsFounds = true;
        }
      }, error: (error) => {
        this.showNoRecordsFounds = true;
      }
    });
  }

  onBack() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  selectedAction(event: any) {
    if (event.action === MweConstants.viewAction) {
      this.router.navigate(['../../', 'view-job-applicant-details', event.row.jobPostid, event.row.applicantId], { relativeTo: this.route });
    } else if (event.action === MweConstants.fileDownloadAction) {
      this.downloadProfile(event.row.jobPostid, event.row.applicantId);
    }
  }
   
  downloadProfile(jobPostId: string, applicantId: string) {
    this.router.navigate(['../../', 'view-job-applicant-details', jobPostId, applicantId], 
      { 
        relativeTo: this.route,
        queryParams: { download: 'true' }
      }
    );
  }
  
}

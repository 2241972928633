import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { CareerService } from 'src/app/services/career.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CareerFilterComponent } from './career-filter/career-filter.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-super-admin-careers',
  templateUrl: './super-admin-careers.component.html',
  styleUrls: ['./super-admin-careers.component.scss']
})
export class SuperAdminCareersComponent implements OnInit {

  @Input() from: string = '';

  @Output() viewActive = new EventEmitter<any>();
  @Output() updateActive = new EventEmitter<any>();
  @Output() addActive = new EventEmitter<boolean>();
  @Output() endCustomerSelctedAction = new EventEmitter<any>();


  columns: ListColumn[] = [
    { name: 'Sr.No', property: 'sNo', visible: true, isModelProperty: false },
    { name: 'Job Title', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'Job ID', property: 'jobPostId', visible: true, isModelProperty: true, isSecondaryLink: true, secondaryAction: MweConstants.viewAction },
    { name: 'Category', property: 'category', visible: true, isModelProperty: true, isText: true },
    { name: 'Total Applicants', property: 'totalApplicants', visible: true, isModelProperty: true, isSecondaryLink: true, secondaryAction: MweConstants.viewJobApplicationsAction },
    { name: 'Posted Company', property: 'company', visible: true, isModelProperty: true, isText: true },
    { name: 'Country', property: 'country', visible: true, isModelProperty: true, isText: true },
    { name: 'Posted by', property: 'postedBy', visible: true, isModelProperty: true, isText: true },
    { name: 'Email Address', property: 'postedEmail', visible: true, isModelProperty: true, isText: true },
    { name: 'Date', property: 'applicationStartDate', visible: true, isModelProperty: true, isDatePipe: true },
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: 'assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
        { imageUrl: 'assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction, hasCondition: true, conditionProperty: 'active', conditionValue: true },
        { imageUrl: 'assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction, hasCondition: true, conditionProperty: 'active', conditionValue: true }]
    },
  ] as ListColumn[];
  data: any[] = [];
  filterObject: any;
  showNoRecordsFounds: boolean = true;
  selectedData: any;
  isDesktopView: boolean = false;
  isXLLaptopView: boolean = false;
  isLargeLaptopView: boolean = false;
  private userIdByRole: any;

  constructor(public dialog: MatDialog, private careerService: CareerService, private breakpointObserver: BreakpointObserver,
    private snackbarService: SnackbarService, private readonly router: Router, private readonly route: ActivatedRoute,) {
    this.breakpointObserver.observe(
      ["(min-width: 1920px)", "(min-width: 1536px)"]
    ).subscribe((result: BreakpointState) => {
      if (result.breakpoints['(min-width: 1920px)']) {
        this.isDesktopView = true;
      } else if (result.breakpoints['(min-width: 1536px)']) {
        this.isXLLaptopView = true;
      } else if (result.breakpoints['(max-width: 1366px)']) {
        this.isLargeLaptopView = true;
      } else {

      }
    });
  }

  ngOnInit(): void {
    this.careerService.setCareersBreadCrumbs(['Dashboard', 'Careers']);
    this.getAllJobPost();
  }

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.addAction:
        if(this.from === 'endCustomer'){
          this.endCustomerSelctedAction.emit({type: 'add'})
          return;
        }
        this.router.navigate(['./post-job'], { relativeTo: this.route });
        break;
      case MweConstants.editAction:
        if(this.from === 'endCustomer'){
          this.endCustomerSelctedAction.emit({type: 'update', id: event.row.id})
          return;
        }
        this.router.navigate(['./update-job/', event.row.id], { relativeTo: this.route });
        break;
      case MweConstants.deleteAction:
        this.deleteJobPost(event);
        break;
      case MweConstants.viewAction:
        if(this.from === 'endCustomer'){
          this.viewActive.emit(event.row.id);
          return;
        }
        this.router.navigate(['./view-job', event.row.id], { relativeTo: this.route });
        break;
      case MweConstants.filterAction:
        this.openFilter();
        break;
      case MweConstants.viewJobApplicationsAction:
        this.router.navigate(['./view-job-applications', event.row.id], { relativeTo: this.route });
        break;
      default:
        break;
    }
  }
  convertToLocalDate(dateString: string): string {
    const date = new Date(dateString);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 10); // If you want only the date (YYYY-MM-DD)
  }
  

  openFilter() {
    const dialogRef = this.dialog.open(CareerFilterComponent, {
      width: 'auto',
      data: this.filterObject,
      disableClose: true,
      autoFocus: false,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Check if postedDate is set and needs formatting
        if (result.postedDate) {
          result.postedDate = this.convertToLocalDate(result.postedDate);
        }
  
        this.filterObject = result;
        console.log('The dialog was closed', result);
  
        // Apply the appropriate filter function based on the result
        if (this.filterObject) {
          this.getAllJobPostByFilter();
        } else {
          this.getAllJobPost();
        }
      }
    });
  }
  

  getAllJobPost() {
    this.data = [];
    this.userIdByRole = this.careerService.getUserIdBasedOnRole();

    if (this.userIdByRole !== undefined && this.userIdByRole !== null) {
      this.getAllJobPostByFilter();
    } else {
      this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl, null).subscribe({
        next: (data) => {
          if (data && data?.data && data.data.length > 0) {
            this.data = data.data;
            this.showNoRecordsFounds = false;
          } else {
            this.showNoRecordsFounds = true;
          }
        }, error: (error) => {
          this.snackbarService.openSnackBar("No records found", '');
          this.showNoRecordsFounds = true;
        }
      });
    }
  }

  getAllJobPostByFilter() {
    this.data = [];

    const jobPostFilterRequest = { ...this.filterObject, 'userIds': (this.userIdByRole !== undefined && this.userIdByRole !== null) ? [this.userIdByRole] : null };

    this.careerService.executePostRequest(MweConstants.getJobPostFilterUrl, jobPostFilterRequest).subscribe({
      next: (data) => {
        if (data && data?.data && data.data.length > 0) {
          this.data = data.data;
          this.showNoRecordsFounds = false;
        } else {
          this.showNoRecordsFounds = true;
        }
      }, error: (error) => {
        this.snackbarService.openSnackBar("No records found", '');
        this.showNoRecordsFounds = true;
      }
    });
  }

  deleteJobPost(event: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover the deleted record!',
      iconHtml: '<img src="./assets/images/group-delete.svg">',
      iconColor: 'white',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      confirmButtonColor: '#0f74a9',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#6c757d'

    }).then((result) => {
      if (result.value) {
        this.deleteJobPostApi(event);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: 'Item is safe.',
          icon: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#0f74a9',
        });
      }
    });
  }

  deleteJobPostApi(event: any) {
    const userIdByRole = (this.userIdByRole !== undefined && this.userIdByRole !== null) ? this.userIdByRole : null;
    this.careerService.executeDeleteRequestURL(MweConstants.deleteJobPostUrl + event.row.id, null).subscribe({
      next: (data) => {
        if (data && data?.data) {
          Swal.fire({
            title: 'Removed!',
            text: 'Item Removed Successfully.',
            iconHtml: '<img src="./assets/images/confirm-btn.svg">',
            iconColor: 'white',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#0f74a9',
          }).then(result => {
            this.getAllJobPost();
          });
        }
      }, error: (error) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  close(event: any) {
    this.selectedData = null;
    this.getAllJobPost();
  }

}

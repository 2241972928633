<div class="background">
<mat-card>
    <mat-card-content>
        <div *ngIf="data">
            <!-- <div class="hospital-header" *ngIf="data.type === 'brand'"> -->
            <div class="hospital-details">
                <h1>About {{data?.companyName | camelcase }}</h1>
                <ng-container *ngIf="data">
                    <div [innerHTML]="data?.description"></div>
                
                    <div class="hospital-detailed-info">
                        <div class="hospital-address" *ngIf="data?.address">
                            Address
                            <br><br>
                            <p *ngIf="data?.plotNo||true">Plot No. {{8}}</p>
                            <p *ngIf="data?.address">{{data?.address}}</p>
                            <p><span *ngIf="data?.state">{{data?.state}}</span>, <span *ngIf="data?.country">{{data?.country}}</span></p>
                            <p *ngIf="data?.zipCode">{{data?.zipCode}}</p>
                        </div>
                        <div class="hospital-timings" *ngIf="data.timings || true" >
                            Timings
                            <br><br>
                            <p>24 * 7</p>
                        </div>
                        <div class="hospital-beds" *ngIf="data.timings || true" >
                            Number of Beds
                            <br><br>
                            <p>175+</p>
                        </div>
                        <div class="" *ngIf="data.timings || true" >
                            Modes of Payment
                            <br><br>
                            <p>Insurance | Cash | Cheque |</p>
                            <p>Online Payment | Debit Card |</p>
                            <p>Credit Card</p>
                        </div>
                        <div class="" *ngIf="data.timings || true" >
                            Photos
                            <br><br>
                            <img [src]="data?.logo" style="width: 50px; height: 50px;" alt="">
                        </div>
                    </div>
            </ng-container>
            </div>
        </div>


    </mat-card-content>
    
</mat-card>

<mat-card class="mt-4" >
<mat-card-content>
    <div class="personal-infomation mt-1">
        <div class=" d-flex justify-content-between " style="margin-left: 16px;">
            <h2 class="profile-heading">Specialites</h2>
        </div>
    
        
        <div class="mt-1 name-container"
            *ngIf="data?.specialityInfos && this.data.specialityInfos.length>0 || true ">
            <!-- <div *ngFor="let speciality of specialityData" class="my-3">
                <span class=" speciality " >
                    <label class="med-world-basic-font med-world-basic-font__grey">{{speciality?.name}}</label>
                    
                </span>
                <span class="mx-1"></span>
            </div> -->
            <div class="my-3">
                <span class="speciality">
                    <label class="med-world-basic-font med-world-basic-font__grey">Cardiology</label>
                </span>
                <span class="speciality">
                    <label class="med-world-basic-font med-world-basic-font__grey">Gastroentrology</label>
                </span>
                <span class="speciality">
                    <label class="med-world-basic-font med-world-basic-font__grey">Neurology</label>
                </span>
                <span class="speciality">
                    <label class="med-world-basic-font med-world-basic-font__grey">Oncology</label>
                </span>
                <span class="" style="margin-left: 25px;">
                    <label class="med-world-basic-font med-world-basic-font__grey" style="font-weight: bold;">+9 Specialites</label>
                </span>
            </div>
        </div>
    
    </div>
</mat-card-content>
</mat-card>

<app-hospital-services [data]=" data "></app-hospital-services>

<app-doctors [data]="data"></app-doctors>

<app-hospital-reviews [data]="data"></app-hospital-reviews>

<br>
<app-website-footer></app-website-footer>
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterDataService } from 'src/app/services/master-data.service';


@Component({
  selector: 'app-location-management',
  templateUrl: './location-management.component.html',
  styleUrls: ['./location-management.component.css']
})
export class LocationManagementComponent implements OnInit {
  @Input() headings: any[] = [];
  locationForm: FormGroup;
  locationType: string;
  isEdit: boolean = false;
  Edit: boolean = false;
  rowData: any;
  accountTypes: any[] = []; 
  isViewMode: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<LocationManagementComponent>,
    private formBuilder: FormBuilder,
    private masterDataService: MasterDataService,
    private http: HttpClient, 
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.locationType = this.data.type;
    this.headings = this.data.headings;
    this.rowData = this.data.rowData;
    this.isEdit = !!this.rowData;
    this.Edit = this.isEdit;
    this.isViewMode = this.data.viewMode || false;
    this.locationForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.initForm();
    if (this.isEdit) {
      this.populateForm();
    }
    if (this.locationType === 'Position' || this.locationType === 'Speciality') {
      this.getAccountTypes();  // Add this line
    }
    if(this.isViewMode) {
      this.locationForm.disable();
      this.Edit= false;
    }
   
  }

  getAccountTypes() {
    this.masterDataService.getAccountType().subscribe(
      (data) => {
        console.log('Fetched Account Types:', data);
        this.accountTypes = data;
        if (this.isEdit) {
          this.populateAccountTypes();
        }
      },
      (error) => {
        console.error('Failed to fetch account types', error);
      }
    );
  }

  populateAccountTypes() {
    if (this.rowData && this.rowData.accountType) {
      const selectedAccountTypes = Object.values(this.rowData.accountType);
      this.locationForm.patchValue({
        accountType: selectedAccountTypes
      });
    }
    if (this.rowData && this.rowData.accountsTypes) {
      const selectedAccountTypes = Object.values(this.rowData.accountsTypes);
      this.locationForm.patchValue({
        accountsTypes: selectedAccountTypes
      });
    }
  }


  initForm() {
    const formGroupControls: { [key: string]: any } = {}; 
    this.headings.forEach(heading => {
      formGroupControls[heading.property] = ['', [Validators.required]];
    });
    this.locationForm = this.formBuilder.group(formGroupControls);
  }

  populateForm() {
    this.locationForm.patchValue(this.rowData);
  }

  onFormSubmit() {
    let apiCall: Observable<any> | undefined = undefined; 
  
    if (this.locationForm.valid) {
      const formData = this.locationForm.value;

      if (this.locationType === 'Position') {
        if (formData['accountType'] && typeof formData['accountType'] === 'string') {
          formData['accountType'] = formData['accountType'].split(',').map((type: string) => type.trim());
        }
      }
      if (this.locationType === 'Speciality') {
        if (formData['accountsTypes'] && typeof formData['accountsTypes'] === 'string') {
          formData['accountsTypes'] = formData['accountsTypes'].split(',').map((type: string) => type.trim());
        }
      }

      if (this.isEdit ) {
        if (this.locationType === 'Position') {
          if (formData['accountType']) {
            const accountTypeObject: { [key: string]: string } = {};
            formData['accountType'].forEach((type: string, index: number) => {
              accountTypeObject[index + 1] = type;
            });
            formData['accountType'] = accountTypeObject;
          }
        }
        
        if (this.locationType === 'Speciality') {
          if (formData['accountsTypes']) {
            const accountTypeObject: { [key: string]: string } = {};
            formData['accountsTypes'].forEach((type: string, index: number) => {
              accountTypeObject[index + 1] = type;
            });
            formData['accountsTypes'] = accountTypeObject;
          }
        }

        if (this.locationType === 'Continent') {
          apiCall = this.masterDataService.updateContinent(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Country') {
          apiCall = this.masterDataService.updateCountry(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'State') {
          apiCall = this.masterDataService.updateState(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'City') {
          apiCall = this.masterDataService.updateCity(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Account Type') {
          apiCall = this.masterDataService.updateAccountType(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Speciality') {
          apiCall = this.masterDataService.updateSpeciality(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Position') {
          apiCall = this.masterDataService.updatePosition(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Lead Source') {
          apiCall = this.masterDataService.updateLeadSource(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Job Category') {
          apiCall = this.masterDataService.updateJobCategory(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Job Skill') {
          apiCall = this.masterDataService.updateJobSkill(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Job Type') {
          apiCall = this.masterDataService.updateJobType(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Experience Level') {
          apiCall = this.masterDataService.updateExperience(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Degree Level') {
          apiCall = this.masterDataService.updateDegree(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else if (this.locationType === 'Job Tag') {
          apiCall = this.masterDataService.updateJobTags(this.rowData.id, formData, { responseType: 'text' as 'json' });
        } else {
          console.error('Invalid location type:', this.locationType); 
        }
        
      } else {
        if (this.locationType === 'Continent') {
          apiCall = this.masterDataService.addContinent(formData, );
        } else if (this.locationType === 'Country') {
          apiCall = this.masterDataService.addCountry(formData, );
        } else if (this.locationType === 'State') {
          apiCall = this.masterDataService.addState(formData, );
        } else if (this.locationType === 'City') {
          apiCall = this.masterDataService.addCity(formData);
        } else if (this.locationType === 'Account Type') {
          apiCall = this.masterDataService.addAccountType(formData);
        } else if (this.locationType === 'Speciality') {
          apiCall = this.masterDataService.addSpeciality(formData);
        } else if (this.locationType === 'Position') {
          apiCall = this.masterDataService.addPosition(formData);
        } else if (this.locationType === 'Lead Source') {
          apiCall = this.masterDataService.addLeadSource(formData);
        } else if (this.locationType === 'Job Category') {
          apiCall = this.masterDataService.addJobCategory(formData);
        } else if (this.locationType === 'Job Skill') {
          apiCall = this.masterDataService.addJobSkill(formData);
        } else if (this.locationType === 'Job Type') {
          apiCall = this.masterDataService.addJobType(formData);
        } else if (this.locationType === 'Experience Level') {
          apiCall = this.masterDataService.addExperience(formData);
        } else if (this.locationType === 'Degree Level') {
          apiCall = this.masterDataService.addDegree(formData);
        } else if (this.locationType === 'Job Tag') {
          apiCall = this.masterDataService.addJobTags(formData);
        } else {
          console.error('Invalid location type:', this.locationType); 
        }
      }
  
      if (apiCall) {
        apiCall.subscribe(
          (response) => {
            console.log('API Response:', response);
            this.dialogRef.close(true);
            Swal.fire('', this.isEdit ? 'Updated successfully !!' : 'Added successfully !!', 'success');
          },
          (error) => {
            console.error('API Error:', error);
            Swal.fire('Error', this.isEdit ? 'Failed to update . Please try again later.' : 'Failed to add. Please try again later.', 'error');
          }
        );
      }
    } else {
      console.log('Form is invalid');
      Swal.fire('Error', 'Please fill in all required fields', 'error');
    }
  }
}

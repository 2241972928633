import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderbreadcrumbService {

  private headerBreadcrumbsSubject = new BehaviorSubject<string[]>([]);
  headerBreadcrumbs$ = this.headerBreadcrumbsSubject.asObservable();

  setHeaderBreadcrumbs(headerBreadcrumbs: string[]) {
    this.headerBreadcrumbsSubject.next(headerBreadcrumbs);
  }

  getHeaderBreadcrumbs(): string[] {
    return this.headerBreadcrumbsSubject.getValue();
  }
}

import { map } from 'rxjs';
import { Component, inject, Inject, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { Distributor } from 'src/app/models/distributor-model';
import { ListColumn } from 'src/app/models/list-column.model';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { AddDistributorComponent } from '../add-distributor/add-distributor.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-assign-more-distributors',
  templateUrl: './assign-more-distributors.component.html',
  styleUrls: ['./assign-more-distributors.component.scss']
})
export class AssignMoreDistributorsComponent {
  addOEM(event: MatChipInputEvent) {
    event.chipInput!.clear();
  }

  loadOEM() {
    this.getCountries('');
    console.log('loadOEM');
    
  }

  countryDropdown:any=[]; 
  distibutorForm!:FormGroup;
  stateDropdown:any=[];
  cityDropdown:any=[];
  assignMoreDistributorsForm!:FormGroup;
  distributorsNetwork!: Distributor[];
  allDistributors!: Distributor[];
  selectedIds: string[] = [];
  filteredDistributors: Distributor[] = [];
  index: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10;
  addOnBlur = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectedCountry: any = '';
  isEditMode: any;
  announcer = inject(LiveAnnouncer);
  status: string = 'Active'

  @ViewChild('autoCompleteCountryInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCountryInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteStateInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteStateInput!: MatAutocompleteTrigger;

@ViewChild('autoCompleteCityInput', { static: false, read: MatAutocompleteTrigger }) autoCompleteCityInput!: MatAutocompleteTrigger;

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private distributorService: DistributorService, public dialogRef: MatDialogRef<any>,private masterDataService:MasterDataService,public dialog: MatDialog,private fb:FormBuilder,private endCustomerService:EndCustomerService,private renderer: Renderer2,
  )  { this.selectedIds =data.selectedDistributors;
    console.log("this.selectedIds2",this.selectedIds);

  }
  
  
  columns =  [
    { name: 'Company Name', property: 'companyName', visible: true, isModelProperty: true, isText: true },
    { name: 'First Name', property: 'firstName', visible: true, isModelProperty: true, isText: true },
  { name: 'Last Name', property: 'lastName', visible: true, isModelProperty: true, isText: true },
  { name: 'Country', property: 'country', visible: true, isModelProperty: true, isText: true },
  { name: 'State', property: 'state', visible: true, isModelProperty: true, isText: true },
  { name: 'City', property: 'city', visible: true, isModelProperty: true, isText: true },
  {name:'Status',property:'active',visible:true,isModelProperty:true,isStatus:true},
  { name: 'Add', property: 'action', visible: true, isModelProperty: true, hasAction: true,
    actions: [
      {isCheckBox: true, visible: false,  actionType: MweConstants.checkBox ,hasAction: true,checkBox: true, conditionValue: true},  
    ]
  }
] as ListColumn[];
  
selectedAction(event: any): void {
  switch (event.action) {
    case MweConstants.checkBox:
      this.index = this.selectedIds.indexOf(event.row.id);
      if (this.index > -1) {
        this.selectedIds.splice(this.index, 1);
      } else {
        this.selectedIds.push(event.row.id);
      }
      break;
    default:
      break;
  }
}
addDistributors() {
  this.dialogRef.close(this.selectedIds);
}

onPageChanged(event: { pageIndex: number, pageSize: number }) {
  this.pageIndex = event.pageIndex;
  this.pageSize = event.pageSize; 
  this.getAllDistributors(this.pageIndex, this.pageSize);  
}

  ngOnInit(): void { 
    this.intialze();
    this.getAllDistributors();
    this.initializeexhibitionFilterForm();
    // this.getContinents();
    this.getCountries(null);
    this.getStatesByCountryName(null);
    this.getCityiesByStateName(null);
    if(this.data){
      this.assignMoreDistributorsForm.patchValue(this.data);
    }

    this.country.valueChanges.subscribe((data: any)=>{
      if(data){
        this.getCountries({startsWith:data});
        
      }      
      else{
        this.getCountries(null);
      }
    });

    this.state.valueChanges.subscribe((data: any)=>{
      if(this.assignMoreDistributorsForm.get('countries')?.value){
        if(data){
          this.getStatesByCountryName({countryName:this.assignMoreDistributorsForm.get('countries')?.value,startsWith:data});
        }else{
          this.getStatesByCountryName({countryName:this.assignMoreDistributorsForm.get('countries')?.value});
        
        }
      }
    else{
      if(data){
        this.getStatesByCountryName({startsWith:data});
      }
      else{
        this.getStatesByCountryName(null);
      }
    }
    });
    this.city.valueChanges.subscribe((data: any)=>{
      if(this.assignMoreDistributorsForm.get('states')?.value){
        if(data){
          this.getCityiesByStateName({stateName:this.assignMoreDistributorsForm.get('states')?.value,startsWith:data});
        }else{
          this.getCityiesByStateName({stateName:this.assignMoreDistributorsForm.get('states')?.value});
        }
      }
    else{
      if(data){
        this.getCityiesByStateName({startsWith:data});
      }
      else{
        this.getCityiesByStateName(null);
      }
    }    
    });
  }
  intialze(){
    this.distibutorForm = this.fb.group({
      continents: '',
      countries: '',
      states: '',
      cities: '',
      date: ''
    });
  }

  onSearchDistributor(event: any) {
    
  }
  getAllDistributors(pageIndex?: number, pageSize?: number) {
    this.distributorService.getAllDistributorsWithOutFilter(this.distibutorForm.value,pageIndex, pageSize).subscribe(res => {
     this.data = res.data;       
     this.distributorsNetwork = this.data
     this.allDistributors=this.data;
     this.data.forEach((d: any) => {
        console.log('foreach', d);
        
     });
    })    
  }


  filterDistributors(filters: any): void {
    this.distributorsNetwork = this.allDistributors.filter(distributor => {
      return (!filters.continent || distributor.continent === filters.continent) &&
        (!filters.countries || distributor.country === filters.countries) &&
        (!filters.states || distributor.state === filters.states) &&
        (!filters.cities || distributor.city === filters.cities);
    });
  }
  
 
  onCountrySelected(event: any) {
    this.selectedCountry=event.option.value
    this.getStatesByCountryName({countryName:event.option.value});
    this.filterDistributors(this.assignMoreDistributorsForm.value);
  }
    onStateSelected(event: any) {
    this.getCityiesByStateName({stateName:event.option.value});
    this.filterDistributors(this.assignMoreDistributorsForm.value);
  }
  onCitySelected(event: any) {
    this.filterDistributors(this.assignMoreDistributorsForm.value);
  }
  
  ngOnChanges(changes: SimpleChanges): void {
      }

  initializeexhibitionFilterForm(){
    this.assignMoreDistributorsForm=this.fb.group({
      continents:new FormControl(''),
      countries:new FormControl(''),
      states:new FormControl(''),
      cities:new FormControl(''),
         })
  }

  // getContinents(){
  //   this.masterDataService.executeGetRequestURL(MweConstants.continentURL,null).subscribe(data=>{
  //     if(data && data?.data && Array.isArray(data.data)){
  //       this.continentDropdown=data.data;
  //     }
  //   },error=>{
  //   })
  // }
  getCountries(params:any){
    this.executeGetRequestURL(MweConstants.countryURL,params,'countryDropdown');
   
  }
  // getCountriesByContinentName(params:any){
  //   this.executeGetRequestURL(MweConstants.continentURL,params,'countryDropdown');
        
  // }
  getStatesByCountryName(params:any){
    this.executeGetRequestURL(MweConstants.stateUrl,params,'stateDropdown');
  }
  getCityiesByStateName(params:any){
    this.executeGetRequestURL(MweConstants.cityUrl,params,'cityDropdown');
  }
  executeGetRequestURL(url:string,params:any,type:string){
    (this as any)[type]=[];
    this.masterDataService.executeGetRequestURL(url,params).subscribe(data=>{
      if(data && data?.data && Array.isArray(data.data)){
        (this as any)[type]=data.data;
       }
    },error=>{
    })
  }
  executeGetRequestURLForEndCustomer(url:string,params:any,type:string){
    (this as any)[type]=[];
    this.endCustomerService.executeGetRequestURL(url,params).subscribe((data: { data: string | any[]; })=>{
      if(data && data?.data && data.data.length>0){
        (this as any)[type]=data.data;
      }
    },(error: any)=>{

    })
    
    
  }

  getCountryDropdown(){
    this.countryDropdown=[];
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.assignMoreDistributorsForm.get('states')?.reset();
    this.assignMoreDistributorsForm.get('cities')?.reset();
    // if(this.assignMoreDistributorsForm.get('continents')?.value){
    //   this.getCountriesByContinentName({continentName:this.assignMoreDistributorsForm.get('continents')?.value})
    // }
  }
  getStatesDropdown(){
    this.stateDropdown=[];
    this.cityDropdown=[];
    this.assignMoreDistributorsForm.get('states')?.reset();
    this.assignMoreDistributorsForm.get('cities')?.reset();
    if(this.assignMoreDistributorsForm.get('countries')?.value){
      this.getStatesByCountryName({countryName:this.assignMoreDistributorsForm.get('countries')?.value})
    }
  }
  getCityDropdown(){
    this.cityDropdown=[];
    this.assignMoreDistributorsForm.get('cities')?.reset();
    if(this.assignMoreDistributorsForm.get('states')?.value){
      this.getCityiesByStateName({stateName:this.assignMoreDistributorsForm.get('states')?.value})
    }
  }


  // get continent(){
  //   return this.assignMoreDistributorsForm.get('countries') as FormControl;
  // }
  get country(){
    return this.assignMoreDistributorsForm.get('countries') as FormControl;
  }
  get state(){
    return this.assignMoreDistributorsForm.get('states') as FormControl;
  }
  get city(){
    return this.assignMoreDistributorsForm.get('cities') as FormControl;
  }
// get date(){
//   return this.assignMoreDistributorsForm.get('date') as FormControl;
// }

shouldAutofocus(): boolean {
  return false; // Change this based on your condition
}

  ngAfterViewInit() {
    this.autoCompleteCountryInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.country.setValue(null);
        }
      });
    // this.autoCompleteContinentInput?.panelClosingActions
    //   .subscribe(e => {
    //     if(e==null){
    //       this.continent.setValue(null);
    //     }
    //   });
      this.autoCompleteStateInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.state.setValue(null);
        }      
      });
      this.autoCompleteCityInput?.panelClosingActions
      .subscribe(e => {
        if(e==null){
          this.city.setValue(null);
        }
              });
  }
 
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.country.setValue('');
     
    }
    this.getAllDistributors();
  }

  removeOEM() {
    this.selectedCountry=''
    // this.selectedOEM = '';
    // this.allProducts.get('oemId')?.setValue(null);
    // this.allProducts.get('startWith')?.setValue('');
    // this.isEditMode = false;
    this.getAllDistributors();
  }

}

import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  @Input() dealDetailsForm!: FormGroup;
  @ViewChild('otherCertificatesInput') otherCertificatesInput!: ElementRef;

  fileFields = [
    { label: 'Cr Copy', controlName: 'crCopy' },
    { label: 'Business License', controlName: 'businessLicense' },
    { label: 'European Certificates', controlName: 'europeanCertificate' },
    { label: 'Other Certificates', controlName: 'otherCertificates' }
  ];

  constructor(private fileUploadService: FileUploadService) {}

  ngOnInit(): void {
    // Safely check if the 'otherCertificates' field exists and initialize if necessary
    const lead = this.dealDetailsForm.get('lead') as FormGroup;
    
    const otherCertificatesControl = lead.get('otherCertificates');
    
    // If the control exists and has no value, initialize it as an empty array
    if (otherCertificatesControl && !otherCertificatesControl.value) {
      lead.patchValue({
        otherCertificates: []
      });
    }
  }
  

  selectFile(event: Event, controlName: string): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      if (controlName === 'otherCertificates') {
        this.uploadMultipleFiles(input.files, controlName);
      } else {
        const file = input.files[0];
        this.uploadSingleFile(file, controlName);
      }
    }
  }

  uploadSingleFile(file: File, controlName: string): void {
    this.uploadFile(file).subscribe(
      (url: string) => {
        const lead = this.dealDetailsForm.get('lead') as FormGroup;
        lead.patchValue({
          [controlName]: url
        });
        this.resetFileInput(controlName);
      },
      (error) => {
        console.error('Error uploading file:', error);
      }
    );
  }

  uploadMultipleFiles(files: FileList, controlName: string): void {
    const fileArray = Array.from(files);
    const uploadObservables = fileArray.map(file => this.uploadFile(file));

    forkJoin(uploadObservables).subscribe(
      (urls: string[]) => {
        const lead = this.dealDetailsForm.get('lead') as FormGroup;
        const existingUrls = lead.get(controlName)?.value || [];
        lead.patchValue({
          [controlName]: [...existingUrls, ...urls]
        });
        this.resetFileInput(controlName);
      },
      (error) => {
        console.error('Error uploading files:', error);
      }
    );
  }

  clearFile(controlName: string, index?: number): void {
    const lead = this.dealDetailsForm.get('lead') as FormGroup;
    if (controlName === 'otherCertificates' && typeof index === 'number') {
      const files = lead.get(controlName)?.value as string[];
      files.splice(index, 1);
      lead.patchValue({
        [controlName]: files
      });
    } else {
      lead.patchValue({
        [controlName]: null
      });
    }
    this.resetFileInput(controlName);
  }

  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/certificates/');

    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; // Return the full URL from the response
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }

  private resetFileInput(controlName: string): void {
    const fileInput = document.getElementById(controlName) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }

  getFileName(url: string): string {
    if (!url) return '';
    return url.split('/').pop() || '';
  }

  triggerOtherCertificatesInput(): void {
    const input = document.getElementById('otherCertificates') as HTMLInputElement;
    if (input) {
      input.click();
    }
  }
  
}
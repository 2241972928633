import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit{

showNoRecordsFounds: boolean = false;
hovered: boolean = false;
hoveredIndex: any;
itemsPerPage = 9;
brands: any[] = [];
originalData: any[] = [];
paginatedItems: { name: string, logo: string }[] = [];

  ngOnInit(): void {
    this.getAllBrandsList();
  }

  constructor(
    private oemService :OemService,
    private router: Router, 
    private readonly route: ActivatedRoute,
  ) { }

  viewPortfolio(brand: any): void {
    this.router.navigate([`brands/brand-profile/${brand.id}`], {
      queryParams: { type: 'brand' }
    });
  }

  onPageChanged(paginatedData: any[]): void {
    this.paginatedItems = paginatedData;
  }

  getAllBrandsList() {
    this.oemService.getBrandsList().subscribe({
      next: (response) => {
        this.brands = response.data; 
        console.log(this.brands);
        
        this.originalData = this.brands;
        this.paginatedItems = this.brands.slice(0, this.itemsPerPage);
      },
      error: (error) => {
        console.error('Error fetching brand list data:', error);
      }
    });
  }

  filteredData = this.brands;

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.brands = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
    console.log('Received filtered data:', filtered);
  }
}
          
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterPayload, Product, ProductResponse } from '../models/product-model';
import { ApiResponseModel } from '../models/shared-model';
import { OEMUrls, SearchUrls } from '../utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  

  constructor(private http: HttpClient, private router: Router) { }

  getProducts(requestBody: FilterPayload): Observable<ApiResponseModel<ProductResponse>> {
    return this.http.post<ApiResponseModel<ProductResponse>>(this.ONBOARDING_BASE_URL + '/product/all-product-links', requestBody);
  }

  public getAllProductsByCategory(categoryId: string): Observable<ApiResponseModel<Product[]>> {
    return this.http.get<ApiResponseModel<Product[]>>(OEMUrls.products + '/by-category/' + categoryId);
  }

  public getProductDetails(productId: string): Observable<ApiResponseModel<Product>> {
    return this.http.get<ApiResponseModel<Product>>(OEMUrls.products+"/"+productId);
  }
  
  public searchitemsbykeyword(keyword: string): Observable<ApiResponseModel<Product[]>> {
    const params = new HttpParams().set('query', keyword);
    return this.http.get<any>(SearchUrls.searchSuggestions, {params});
  }

  public getCategory(id: string){
    return this.http.get<any>(`${this.ONBOARDING_BASE_URL}/productinfo/by-category/${id}`);
  }

  public getProductById(id: string){
    return this.http.get<any>(`${this.ONBOARDING_BASE_URL}/productinfo/${id}`);
  }
  
  public increamentProductCount(productId: string): Observable<ApiResponseModel<Product>> {
    return this.http.put<ApiResponseModel<Product>>(SearchUrls.increamentViewCount + '/' + productId, null);
  
  }

  public getTopProducts(): Observable<ApiResponseModel<Product[]>> {
    return this.http.get<ApiResponseModel<Product[]>>(SearchUrls.topProducts);
  }

  public postReview(data: any){
    return this.http.post<any>(`${this.ONBOARDING_BASE_URL}/review`, data)
  }

  public getProductReviews(id: string, pageNumber: number, pageSize: number ){
    return this.http.get<any>(`${this.ONBOARDING_BASE_URL}/review/product/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  }

  public deleteProductReview(id: string){
    return this.http.delete<any>(`${this.ONBOARDING_BASE_URL}/review/${id}`)
  }

  public editProductReview(data: any, id: string){
    return this.http.put<any>(`${this.ONBOARDING_BASE_URL}/review/${id}`, data)
  }

}

<div class="medworld-background-container">
  <div class="form-conatiner">
    <div class="medworld-form-container">
      <div class="logo-container">
        <img
          loading="lazy"
          alt=""
          src="./../../assets/images/logomedworld32full.svg"
        />
      </div>
      <div *ngIf="!emailSent && !isLinkExpired" class="forgot-password-container">
        <div class="medworld-label">{{ "RESET_PASSWORD" | translate }}</div>

        <form #resetForm="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
          <div class="medworld-descrption" style="margin: 10px 0 10px 0;">
            {{ "RESET_PASSWORD_DESC" | translate }}
          </div>
          <div class="medworld-label-inputs">{{ "NEW_PASSWORD" | translate }}</div>
          <div class="input-field-container">
            <input
              type="password"
              class=" form-control input-element"
              name="newPassword"
              required
              [(ngModel)]="newPassword"
              #newPasswordField="ngModel"
              (ngModelChange)="passwordValidation(newPassword)"
            />
          </div>
          <div *ngIf="newPasswordField?.touched && newPasswordField?.errors?.['required']" class="error-message"
          style="display: flex; align-items: center">
          <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
          <span style="margin-left: 10px" class="error-message">Password is required.</span>
        </div>
        <div *ngFor="let error of passwordValidation(newPassword)" class="error-message"
          style="display: flex; align-items: center">
          <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
          <span style="margin-left: 10px" class="error-message">
            {{error}}</span>
        </div>
          <div class="medworld-label-inputs mt-3">
            {{ "CONFIRM_NEW_PASSWORD" | translate }}
          </div>
          <div class="input-field-container">
            <input
              type="password"
              class="form-control input-element"
              name="confirmNewPassword"
              required
              [(ngModel)]="confirmNewPassword"
              #confirmNewPasswordField="ngModel"
            />
          </div>
          <div
          *ngIf="(confirmNewPasswordField.touched && confirmNewPasswordField.errors?.['required']) || showConfirmNewPasswordError" 
            class="error-message"
          >
            <img
              class="error-icon"
              loading="lazy"
              alt=""
              src="./../../assets/images/alert.svg"
            />
            <span style="margin-left: 10px">{{
              "CONFIRM_NEW_PASSWORD_REQUIRED" | translate
            }}</span>
          </div>
          <div
            *ngIf="
              newPassword !== confirmNewPassword &&
              confirmNewPasswordField.touched
            "
            class="error-message"
          >
            <img
              class="error-icon"
              loading="lazy"
              alt=""
              src="./../../assets/images/alert.svg"
            />
            <span style="margin-left: 10px">{{
              "PASSWORDS_NOT_MATCH" | translate
            }}</span>
          </div>
          <div class="medworld-button-container mt-3" >
            <button
              class="medworld-button"
              class="button button-primary"
              type="submit"
              [disabled]="
                resetForm.invalid || newPassword !== confirmNewPassword
              "
            >
              {{ "RESET_PASSWORD" | translate }}
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="emailSent && !isLinkExpired" class="reset-password-container">
        <div class="medworld-label">{{ "RESET_PASSWORD_SUCCESS" | translate }}</div>
        <div class="medworld-descrption">
          {{ "RESET_PASSWORD_UPDATE_SUCCESS" | translate }}
        </div>
        <div class="email-sent-icon">
          <img
          style="display: block; margin: auto;"
          loading="lazy"
          alt=""
          src="./../../../../assets/images/Reset password.svg"
        />

        </div>
       
          <button
            class="medworld-button"
            class="button button-primary"
            [routerLink]="['/login']"
            
            style="width: 85%;"
          >
            {{ "BACK_TO_LOGIN" | translate }}
          </button>
      

      </div>
      <div *ngIf="isLinkExpired" class="forgot-password-container">
     <div class="medworld-label" style="margin-top: 30px; margin-bottom: 30px;">
     Password Reset  Link Expired, Please generate New link
    </div>
    <div>
      <div class="d-flex align-items-center medworld-button-container  mx-auto"  >
        <button
          class="medworld-button"
          class="button button-primary"
         style="width: 85%;"
          [routerLink]="['/user/forgot-password']"
        >
          {{ "FORGOT_PASSWORD" | translate }}
        </button>
      </div>
    </div>
      </div>
    </div>
  </div>

  <div class="copyright">{{ "COPYRIGHT" | translate }}</div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { OemService } from 'src/app/services/oem/oem.service';
import { ViewappointmentComponent } from '../viewappointment/viewappointment.component';

@Component({
  selector: 'app-exhibition-appointments',
  templateUrl: './exhibition-appointments.component.html',
  styleUrls: ['./exhibition-appointments.component.scss']
})
export class ExhibitionAppointmentsComponent implements OnInit {
Data: any[]=[];
  ExhibitionId: string='';
  appointmentExhibitionId: any;
  appointmentId: any;
  assignedStaff: any[]=[];
  fullPhoneNumber: any;


constructor(private appointmentService: OemService,private readonly route: ActivatedRoute,private dialog: MatDialog) { }

ngOnInit(): void {
  this.ExhibitionId = this.route.snapshot.queryParams['exhibitionId'];
  console.log("appointmentExhibitionId", this.ExhibitionId);
  if (this.ExhibitionId) {
    this.getAppointmentsByExhibitionId(this.ExhibitionId);
  }
}

getAppointmentsByExhibitionId(id: string): void {
  this.appointmentService.getAppoinmentByExhibitionId(id).subscribe((data: any) => {
    console.log("appointments are ", data);
    this.Data = data.data.map((item: { phonecode: any; phone: any; assignedstaff: any; staffnames: any }) => {
      return { 
        ...item, 
        fullPhoneNumber: `${item.phonecode} ${item.phone}`,
        assignedStaff: item.assignedstaff && item.assignedstaff.length > 0 ? item.assignedstaff : item.staffnames 
      };
    });
    
    console.log("Data", this.Data);
    
  });
}




columns: ListColumn[] = [
  { name: 'ID', property: 'appointmentsequenceid', visible: true, isModelProperty: true, isText: true },
  // { name: 'Image', property: 'image', visible: true, isModelProperty: true, isText: true },
  { name: 'Date', property: 'date', visible: true, isModelProperty: true, isText: true, secondaryAction: MweConstants.viewExhibitionsAction},
  { name: 'Duration', property: 'slot', visible: true, isModelProperty: true, isText: true },
  { name: 'Name', property: 'name', visible: true, isModelProperty: true, isText: true },
  { name: 'Email', property: 'email', visible: true, isModelProperty: true, isText: true },
  { name: 'Phone', property:'fullPhoneNumber', visible: true, isModelProperty: true, isText: true },
  { name: 'Staff', property: 'assignedStaff', visible: true, isModelProperty: true, isText: true },
  {name:'id', property: 'id', visible: false, isModelProperty: true, isText: true},
  {
    name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
    actions: [
      { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
      { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
    ]
  },
] as ListColumn[];
selectedAction(event: any) {
  switch (event.action) {
    case MweConstants.viewAction:
      this.openViewAppointments(event.row.id);
      break;
    case MweConstants.editAction:
      this.openEditAppointments(event.row.id);
      break;
      
  }
}

openEditAppointments(appointmentId: string) {
  const dialogRef = this.dialog.open(ViewappointmentComponent, {
    width: '560px',
    position: { left: '480px', top: '150px' },
    data: {
      id: appointmentId,
      edit:true
    }
  });
  dialogRef.afterClosed().subscribe(result => {
    this.getAppointmentsByExhibitionId(this.ExhibitionId);
  });
}

openViewAppointments(appointmentId: string) {
  const dialogRef = this.dialog.open(ViewappointmentComponent, {
    width: '560px',
    position: { left: '480px', top: '150px' },
    data: {
      id: appointmentId

    }
  });
  dialogRef.afterClosed().subscribe(result => {

    this.getAppointmentsByExhibitionId(this.ExhibitionId);
  });
}
}



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { FormGroup } from '@angular/forms';
import { catchError, map, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OemService } from 'src/app/services/oem/oem.service';
import Swal from 'sweetalert2';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DISTRIBUTOR_ID, OEM_ID, ROLE_NAME, USER_ID } from 'src/app/utils/constants';
import { ExhibitionfilterComponent } from '../exhibitionfilter/exhibitionfilter.component';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { Distributor } from 'src/app/models/distributor-model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss']
})
export class ExhibitionsComponent implements OnInit {
  @Input() selectedData: any;
  @Output() exhibitiondata: any;
  @Output() UpdateExhibibitionid?: string;
  @Output() x: any;
  @Output() oemId: any;
  

  viewExhibitionId: string = '';
  @Output() backEvent = new EventEmitter<any>();
  @Output() exhibitionid = new EventEmitter<string>();
  @Input() public id!: string;
  Data: any[] = [];
  activeContent: string = 'Exhibitions';
  showAddExhibition: boolean = false;
  exhibitionRegisterForm!: FormGroup;
  exhibitionData: any;
  selectedAccountType: any;
  updateExhibition: boolean = false;
  showExhibition: boolean = false;
  OemService: any;
  userId: any;
  data: any;
  filterObject: any;
  showNoRecordsFounds: boolean=false;
  snackbarService: any;
  exhibitionfiltereddata: any;
  showfilterdata: boolean = false;
  distributorId: any;
  appointmentExhibitionId: string='';

  close(event:any){
    this.showAddExhibition=false;
    this.updateExhibition=false;
    this.showExhibition=false;
    this.selectedData=null;
    this.getAllExhibitions();   
    
  }
  
  closeToBack(event:any){
    this.showAddExhibition=false;
    this.selectedData=null;
   }
   getExhibitionById(id: string) {
    this.exhibitionService.getExhibitionById(id).subscribe(
      (data) => {
        this.exhibitiondata = data;
      },
      (error) => {
      }
    );
  }
 
  columns: ListColumn[] = [
    { name: 'Sr.no', property: 'sr_no', visible: true, isModelProperty: true, isText: true },
    // { name: 'Image', property: 'image', visible: true, isModelProperty: true, isText: true },
    { name: 'Appointments', property: 'appointment', visible: true, isModelProperty: true, isSecondaryLink: true, secondaryAction: MweConstants.viewExhibitionsAction},
    { name: 'Exhibition Title', property: 'title', visible: true, isModelProperty: true, isText: true },
    { name: 'Display Date', property: 'displayDate', visible: true, isModelProperty: true, isText: true },
    { name: 'Location', property: 'location', visible: true, isModelProperty: true, isText: true },
    { name: 'Staff Assignment', property: 'staffAssignment', visible: true, isModelProperty: true, isRole: true },
    {name:'id', property: 'id', visible: false, isModelProperty: true, isText: true},
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    },
  ] as ListColumn[];

  constructor(private dialog: MatDialog, private http: HttpClient, private endCustomerService:EndCustomerService, private exhibitionService: OemService, private localStorageService: LocalStorageService,private readonly router: Router, private readonly route: ActivatedRoute,) {
    this.oemId = this.localStorageService.getItem('roleName') === 'DISTRIBUTOR' ? this.localStorageService.getItem(DISTRIBUTOR_ID) : 
      (this.localStorageService.getItem('roleName') === 'OEM' ? this.localStorageService.getItem(OEM_ID) : this.localStorageService.getItem(USER_ID));
    
  }
  ngOnInit(): void {
    // this.oemId = this.localStorageService.getItem(OEM_ID);
    // this.distributorId = this.localStorageService.getItem(DISTRIBUTOR_ID);    
    this.getAllExhibitions();
      }
  getOemDetails() {
    this.exhibitionService.getOEMdetailswithUserId(this.userId).subscribe(data => {
      this.data = data.data;  
    });
  }
  getAllExhibitions() {
    this.exhibitionService.getExhibitions(this.oemId).pipe(
      catchError((error) => {
        return throwError(error);
      })
    ).subscribe((response: any) => {
      this.exhibitionData = response.data;
      this.transformData( this.exhibitionData);
    });
  }
  transformData(transfomedData?: any) {
    this.Data = transfomedData.map((item: { id: any; title: any; appointmentCount: any; exhibitionStartDate: any; city: any; staffNames: any; exhibitionEndDate: any }, index: number) => ({
      sr_no: (index + 1).toString(),
      appointment:  item.appointmentCount,
      title: item.title || 'N/A',
      displayDate: `${this.formatDate(new Date(item.exhibitionStartDate))} - ${this.formatDate(new Date(item.exhibitionEndDate))}`,
      exhibitionDate: item.exhibitionStartDate || 'N/A',
      location: item.city || 'N/A',
      staffAssignment: item.staffNames ? item.staffNames.map((name: string) => name.trim()) : ['N/A,'],
      action: true,
      id: item.id 
      }));
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.addAction:
        this.showAddExhibition = true;
        this.updateExhibition = false;
        this.showExhibition = false;
        break;
      case MweConstants.editAction:
        this.updateExhibition = true;
        this.showAddExhibition = false;
        this.showExhibition = false;
        this.UpdateExhibibitionid=event.row.id;
       

        break;
      case MweConstants.deleteAction:
        this.deleteExhibition(event);
        break;
      case MweConstants.viewAction:
        this.showExhibition = true;
        this.showAddExhibition = false;
        this.updateExhibition = false;
        this.getExhibitionById(event.row.id);
        this.viewExhibitionId = event.row.id;

        break;
        case MweConstants.filterAction:
      this.openFilter();
        break;
        case MweConstants.filterByDateAction:
          break;
          case MweConstants.viewExhibitionsAction:
            this.getAppointmentsByExhibitionId(event.row.id);
            this.appointmentExhibitionId=event.row.id;
            this.router.navigate(['./appointments'], { relativeTo: this.route, queryParams: { exhibitionId: this.appointmentExhibitionId } })
            break;
      default:
        break;
    }
  }
  getAppointmentsByExhibitionId(id: any) {
    this.router.navigate(['./appointments'], { relativeTo: this.route })
  }

  
 
 openFilter(){
    const dialogRef = this.dialog.open(ExhibitionfilterComponent, {
      width: 'auto',
      data:this.filterObject,
      disableClose:true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.filterObject=result;
      if(this.filterObject){
        this.getAllEndCustomerByFilter();
      }else{
      }
    });
  }

  getAllEndCustomerByFilter(){
    this.showNoRecordsFounds=true;
    const date=this.filterObject.date;
    this.filterObject.date=this.FilterformatDate(date);
    this.exhibitionService.filterExhibitions(this.oemId,this.filterObject)
      .pipe(
        map((response: { data: any; }) => response.data),
        catchError((error: HttpErrorResponse) => {
          this.snackbarService.openSnackBar(error.error.error,'');
          return of(null);
        })
      )
      .subscribe(data => {
        if(data && data.length > 0){
          this.showNoRecordsFounds=false;
          this.exhibitionfiltereddata=data;
          this.transformData(this.exhibitionfiltereddata);
        }else{
          this.exhibitionfiltereddata=[];
          this.showNoRecordsFounds=true;
          this.transformData(this.exhibitionfiltereddata);
        }
      });
  }

  FilterformatDate(date: any) {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } else {
      return date;
    }
  }


  deleteExhibition(event: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover the deleted record!',
        iconHtml: '<img src="./assets/images/group-delete.svg">',
        iconColor : 'white',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
        confirmButtonColor : '#0f74a9',
        cancelButtonText: 'Cancel',  
        cancelButtonColor : '#6c757d'
      }).then((result) => {
        if (result.value) {
        this.deleteExhibitionApi(event);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: 'Cancelled',
            text: 'Item is safe.',
            icon : 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor : '#0f74a9',
          })         
        }
      })    
    }  
    deleteExhibitionApi(event:any){
      this.exhibitionService.executeDeleteRequestURL(event.row.id).subscribe(data=>{
                if(data && data?.data ){
          Swal.fire({
            title: 'Removed!',
            text: 'Item Removed Successfully.',
            iconHtml: '<img src="./assets/images/confirm-btn.svg">',
            iconColor : 'white',
            confirmButtonText: 'Ok',
            confirmButtonColor : '#0f74a9',  
          }).then(result=>{
            this.getAllExhibitions();
          })
        }
      },error=>{    
      })
    }}
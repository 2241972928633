import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-share-resume',
  templateUrl: './share-resume.component.html',
  styleUrls: ['./share-resume.component.scss']
})
export class ShareResumeComponent {

  public linkCopied: boolean = false;
  constructor( public dialogRef: MatDialogRef<ShareResumeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }

  closeDialog(): void {
    this.dialogRef.close();
  }

  copyLink(): void {
    navigator.clipboard.writeText(this.data.url);
    this.linkCopied = true;
  }

}

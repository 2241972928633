<app-website-header></app-website-header>
<div class="header">
    <img src="../../../../../assets/images/webinars-website.svg" width="100%" height="auto" />
    <div class="header-name">
        <p>Webinars</p>
    </div>
</div>
<div>
    <div class="p-5" style="background-color: #F5F5F5; ">
        <div class="containerr p-5 mt-5" style="background-color: #ffffff; border-radius: 10px;">
            <div class="header-container d-flex flex-row  gap-3" >
                <div class="header d-flex" style="width: 80%;">
                    <img [src]="webinar?.image" alt="" style="max-height: 350px;">
                    <div class="time">
                        <div class="days">
                        <span class="number"> {{timeToWebinar.length>0?timeToWebinar[0]:'00'}} </span>
                        <hr>
                            <span class="string">DAYS</span>
                        </div>
                        <div class="hrs">
                            <span class="number"> {{timeToWebinar.length>0?timeToWebinar[1]:'00'}} </span> 
                            <hr>
                            <span>HRS</span>
                        </div>
            
                        <div class="mins">
                            <span class="number"> {{timeToWebinar.length>0?timeToWebinar[2]:'00'}} </span>
                            <hr>
                            <span>MINS</span>
                        </div>   
            
                        <div class="secs">
                            <span class="number"> {{timeToWebinar.length>0?timeToWebinar[3]:'00'}} </span>
                            <hr>
                            <span>SECS</span>
                        </div>
                    </div>
                </div>

                <div class="header-info d-flex flex-column gap-3 " style="width: 40%">
                <div class="sponsored d-flex flex-column">
                    <span class="sponsored-name"> Sponsored by </span>
                    <img [src]="webinar?.logo" class="sponsored-img"  >
                </div>
                <div class="sponsored d-flex flex-column">
                        <span class="sponsored-name">Hosted by</span>
                        <div class="card-info-person d-flex ">
                            <img [src]="webinar?.hostImage" width="52px" height="auto"  />
                            <div class="d-flex flex-column justify-content-around">
                                <p class="person-name" *ngFor="let doc of webinar?.speakersName" > {{doc}}</p>
                                <p class="specialization m-0"> {{ webinar?.speciality }} </p>
                            </div>
                            <p class="person-name m-0 align-content-end"> {{webinarDateTime}} </p> 
                        </div>
                    </div>
                    <button class="btn btn-primary d-flex flex-row align-items-center justify-content-center py-3 gap-3" (click)="registerToWebinar()" [disabled]="iswebinarActive"><img class="d-flex" src="../../../assets/images/icons/calender-icon.svg"> 
                        <span *ngIf="!iswebinarActive" class="button-txt d-flex flex-row">Register for Webinar</span> 
                        <span *ngIf="iswebinarActive" class="button-txt d-flex flex-row">Registration closed</span> 
                    </button>
                </div>
            </div>

            <div class="mt-5">
                <p class="txt-heading"> {{ webinar?.webinarTitle }} </p>
                <p class="infoo" [innerHTML]="webinar?.aboutWebinar" ></p>
            </div>
    </div>
    </div>
<app-website-footer></app-website-footer>

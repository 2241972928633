<nav class="navbar top-nav navbar-expand-lg navbar-dark p-2">
  <div class="container-fluid">
    <div class="contact-information d-flex align-items-center">
      <div class="contact-number me-4">
        <div class="call-icon d-flex align-items-center">
          <img src="./../../../assets/images/phone rings.svg" alt="call-icon" />
          <h5 class="mb-0 ms-2 forResposiveness">+91 9063685541</h5>
        </div>
      </div>
      <div class="contact-email">
        <div class="email-icon d-flex align-items-center">
          <img src="./../../../assets/images/email.svg" alt="email-icon" />
          <h5 class="mb-0 ms-2 forResposiveness">info&#64;digiworldexpo.com</h5>
        </div>
      </div>
    </div>

    <div class="top-menu-rightside ms-auto d-flex align-items-center">
      <!-- Account Dropdown -->
      <div
        class="dropdown account-icon me-4"
        #dropdownRef
        (mouseenter)="toggleDropdown(true)"
        (mouseleave)="toggleDropdown(false)"
      >
        <button
          href="#"
          class="d-flex align-items-center my-account"
          role="button"
          id="accountDropdownMenuLink"
          aria-expanded="false"
        >
          <img
            src="./../../../assets/images/user round.svg"
            alt="account-icon"
          />
          <h5 class="mb-0 ms-2 text-white forResposiveness">
            MY&nbsp;ACCOUNT {{  isLoggedin ? "| " + userName : "" }}
          </h5>
          <img
            class="dropdownarrow-black ms-2"
            src="./../../../assets/images/angle arrow small down.svg"
            alt="arrow-down-icon"
          />
        </button>
        <!-- Account Dropdown Content -->
        <div class="isloggedin" *ngIf="!isLoggedin">
          <ul
            class="dropdown-menu text-center"
            [ngClass]="{ show: isDropdownOpen }"
            aria-labelledby="accountDropdownMenuLink"
          >
            <li><a [routerLink]="['/login']">Sign in</a></li>
            <li>
              <div class="mt-3">
                <a [routerLink]="['/user/signup']">New user? Sign up</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="isloggedin" *ngIf="isLoggedin">
          <ul
            class="dropdown-menu text-center"
            [ngClass]="{ show: isDropdownOpen }"
            aria-labelledby="accountDropdownMenuLink"
          >
            <li>
              <div
                class="mt-3 d-flex gap-2 align-items-center"
                (click)="navigateByRole()"
              >
                <img
                  class="ms-3"
                  src="/assets/images/user.svg"
                  alt="My Dashboard"
                />
                My Dashboard
              </div>
            </li>
            <li>
              <div class="mt-3 d-flex gap-2 align-items-center">
                <img
                  class="ms-3"
                  src="/assets/images/setting.svg"
                  alt="Account settings"
                />
                Account settings
              </div>
            </li>
            <li>
              <div class="mt-3 d-flex gap-2 align-items-center">
                <img
                  class="ms-3"
                  src="/assets/images/Megaphone.svg"
                  alt="Notifications"
                />
                Notifications
              </div>
            </li>
            <li>
              <div
                class="mt-3 d-flex mb-2 gap-2 align-items-center"
                (click)="logout()"
              >
                <img
                  class="ms-3"
                  src="/assets/images/log out.svg"
                  alt="Logout"
                />
                Logout
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Language Selection Dropdown -->
      <div
        class="dropdown language-selection me-4"
        #languageDropdown
        (mouseenter)="toggleLanguageDropdown(true)"
        (mouseleave)="toggleLanguageDropdown(false)"
      >
        <button
          href="#"
          class="d-flex align-items-center my-account"
          role="button"
          id="languageDropdownMenuLink"
        >
          <img src="./../../../assets/images/earth.svg" alt="language-icon" />
          <h5 class="mb-0 ms-2 text-white">
            {{ selectedLanguage === "cn" ? "中文" : "ENGLISH" }}
          </h5>
          <img
            class="dropdownarrow-black ms-2"
            src="./../../../assets/images/angle arrow small down.svg"
            alt="arrow-down-icon"
          />
        </button>
        <!-- Language Selection Dropdown Content -->
        <ul
          class="dropdown-menu text-center"
          [ngClass]="{ show: isLanguageDropdownOpen }"
          aria-labelledby="languageDropdownMenuLink"
        >
          <li>
            <a
              class="dropdown-item"
              (click)="selectLanguage('en')"
              [class.active]="selectedLanguage === 'en'"
              >ENGLISH</a
            >
          </li>
          <li>
            <a
              class="dropdown-item"
              (click)="selectLanguage('cn')"
              [class.active]="selectedLanguage === 'cn'"
              >CHINESE</a
            >
          </li>
        </ul>
      </div>

      <!-- Exhibit Button -->
      <button
        class="btn btn-dark d-flex align-items-center responsive-btn"
        routerLink="/signup"
      >
        <!-- <img src="./../../../assets/images/Exhibition.svg" alt="Exhibition-icon"> -->
        <h5 class="ms-2 mb-0 forResposiveness" style="font-size: 14px">Exhibit With Us</h5>
      </button>
    </div>
  </div>
</nav>

<nav class="navbar-expand-lg main-nav">
  <div class="container-fluid">
    <div class="row align-items-center navheight">
      <!-- Logo -->
      <div
        class="col-2 top-menu-leftside"
        [routerLink]="['/']"
        style="cursor: pointer"
      >
        <img
          src="./../../../assets/images/logomedworld32full.svg"
          alt="medworldexpo"
          style="height: 50px"
        />
      </div>

      <!-- Search Input -->
      <!-- <div class="col-3 top-menu-rightside">
        <input
          class="form-control custom-input"
          type="search"
          placeholder="Search Products, Brands by Keyword"
          aria-label="Search"
          #searchInput
          (input)="search(searchInput.value)"
        /> -->
      <!-- Search Input -->
      <div class="col-3 top-menu-rightside">
        <div class="search-wrapper">
          <mat-icon
            _ngcontent-ng-c91545049=""
            id="searchicon"
            role="img"
            matprefix=""
            style="padding-right: 15px"
            class="mat-icon notranslate material-icons mat-ligature-font mat-icon-no-color ng-tns-c1607617776-78"
            aria-hidden="true"
            data-mat-icon-type="font"
            >search</mat-icon
          >
          <input
            id="search"
            class="custom-input"
            type="search"
            placeholder="Search Products, Brands by Keyword"
            aria-label="Search"
            #searchInput
            (input)="search(searchInput.value)"
          />
        </div>

        <div *ngIf="showDropdown" class="dropdown-menu search show">
          <div class="container">
            <div class="row d-flex">
              <div class="col-md-6"  *ngIf="products.length!==0">
                <div>
                  <h5 class="dropdown-header">Products</h5>
                  <a
                    class="dropdown-item"
                    (click)="navigateTo(['/product-details', product.id])"
                    *ngFor="let product of products"
                    >{{ product.name }}</a
                  >
                </div>
              </div>
              <div class="col-md-6" *ngIf="oems.length">
                <div *ngIf="filteredCategories.length">
                  <h5 class="dropdown-header">Categories</h5>
                  <a
                    class="dropdown-item"
                    (click)="navigateTo(['/category', category.id])"
                    *ngFor="let category of filteredCategories"
                    >{{ category.name }}</a
                  >
                </div>
                <div >
                  <h5 class="dropdown-header">OEMs</h5>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    (click)="navigateToBrandProfile(oem.id)"
                    *ngFor="let oem of oems"
                    >{{ oem.name }}</a
                  >
                </div>
                
              </div>
              <div class="col-md-6" *ngIf="webinars.length">
             
                  <h5 class="dropdown-header">Webinars</h5>
                  <a
                    class="dropdown-item"
                    style="cursor: pointer"
                    (click)="navigateToWebinar(webinar.id)"
                    *ngFor="let webinar of webinars"
                    >{{ webinar.name  | truncateText: 15}}</a>
                  
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Categories Dropdown -->
      <div class="col-7 navheight trapezoid top-menu-rightside">
        <div
          class="d-flex justify-content-around align-items-center h-100 ml-10ms-3"
          style="margin-left: 68px"
        >
          <div
            class="p-2 "
            #CategoriesDropdown
            (mouseenter)="toggleCategoryDropdown(true)"
            (mouseleave)="toggleCategoryDropdown(false)"
          >
            {{ "PRODUCTS" | translate }}
            <!-- Categories Dropdown Content -->
            <div>
              <ul
                class="dropdown-menu"
                [ngClass]="{ show: isCategoryDropdownOpen }"
                aria-labelledby="CategoriesDropdownMenuLink"
              >
                <li *ngFor="let category of categories">
                  <div
                    class="dropdown-item"
                    [routerLink]="['/category', category.id]"
                    [state]="{ category: category }"
                  >
                    {{ category.name }}
                  </div>
                </li>
              </ul>
            </div>
            <div></div>
          </div>

          <!-- Other Menu Items -->
          <div class="white-line"></div>
          <div class="p-2 header-option" [routerLink]="['/brands']">
            {{ "BRANDS" | translate }}
          </div>

          <div class="white-line"></div>
          <div
            class="p-2"
            #HealthCenterDropdown
            (mouseenter)="toggleHealthCenterDropdown(true)"
            (mouseleave)="toggleHealthCenterDropdown(false)"
          >
            {{ "HEALTH CENTERS" | translate }}
            <div>
              <ul
                class="dropdown-menu"
                [ngClass]="{ show: isHealthCenterDropdownOpen }"
                aria-labelledby="HealthCenterDropdownMenuLink"
              >
                <li *ngFor="let health of HealthCenterList">
                  <div
                    class="dropdown-item"
                    [routerLink]="['/hospitals']"
                    [state]="{ health: health }"
                  >
                    {{ health }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="white-line"></div>
          <div
            class="p-2"
            #newsAndInsightsDropdown
            (mouseenter)="toggleNewsAndInsightsDropdown(true)"
            (mouseleave)="toggleNewsAndInsightsDropdown(false)"
          >
            {{ "NEWS_TRENDS" | translate }}
            <div>
              <ul
                class="dropdown-menu"
                [ngClass]="{ show: isNewsAndInsightsDropdownOpen }"
                aria-labelledby="NewsAndInsightsMenuLink"
              >
                <li *ngFor="let news of NewsAndInsightsList">
                  <div
                    class="dropdown-item"
                    [routerLink]="['/']"
                    [state]="{ news: news }"
                  >
                    {{ news }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="white-line"></div>
          <div
            class="p-2"
            #KnowledgeCenterDropdown
            (mouseenter)="toggleKnowledgeCenterDropdown(true)"
            (mouseleave)="toggleKnowledgeCenterDropdown(false)"
          >
            {{ "KNOWLEDEGE_CENTER" | translate }}
            <div>
              <ul
                class="dropdown-menu"
                [ngClass]="{ show: isKnowledgeCenterDropdownOpen }"
                aria-labelledby="KnowledgeCenterDropdownMenuLink"
              >
                <li *ngFor="let knowledge of KnowledgeCenterList">
                  <div
                    class="dropdown-item"
                    [routerLink]="['/webinars']"
                    [state]="{ knowledge: knowledge }"
                  >
                    {{ knowledge }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="white-line"></div>
          <div class="p-2 header-option" [routerLink]="['/careers']">
            {{ "CAREERS" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading" id="workingExperiences">Work Experience</h2>
        <div class="d-flex">
            <img class="edit-profile mx-1" (click)="addWork()"
                *ngIf="(endCustomerData?.workInfos && this.endCustomerData.workInfos.length>0) || showEditForm" alt=""
                src="../../assets/images/add.svg">
            <img class="edit-profile " (click)="editWork()"
                *ngIf="(endCustomerData?.workInfos && this.endCustomerData.workInfos.length>0)&& !showEditForm " alt=""
                src="../../assets/images/editprofile.svg">
        </div>

    </div>
    <div class="mt-2"
        *ngIf="!(endCustomerData?.workInfos && this.endCustomerData.workInfos.length>0) && !showEditForm">
        <button (click)="addWork()" class="profile-btn">
            <mat-icon>add</mat-icon>
            <p class="ps-2">Add Work Experience</p>
        </button>
    </div>

    <div class="mt-2"
        *ngIf="(endCustomerData?.workInfos && this.endCustomerData.workInfos.length>0) && !showEditForm">


<app-card-profile [image]="hospitalIcon" [data]="data" [isWorkCard]="true" [keys]="workExpColumns" > </app-card-profile>
        <!-- <app-mwe-table [showPagination]="false" [columns]="workExpColumns" [showHeader]="false"
            [data]="data"></app-mwe-table> -->
    </div>
    <form [formGroup]="workExperienceForm"  class="mt-2" *ngIf="showEditForm">
        <div formArrayName="workInfos">
            <div *ngFor="let workExperienceForm of workInfos.controls;let i=index;">
                <div [formGroupName]="i">
                    <div class="row mb-4">
                        <div class="col-lg-12">
                            <div class="card p-4 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Designation</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput formControlName="designation">
                                                <mat-error class="error-message error-msg" style="margin-left: -24px"
                                *ngIf="workExperienceForm.get('designation')?.hasError('required')"><img class="error-icon"
                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Designation is
                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0 mx-2"> Year</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields w-45 mx-2 b-r">
                                                <mat-select placeholder="From" formControlName="yearFrom">
                                                    <mat-option *ngFor="let year of yearDropdown" [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-message error-msg" style="margin-left: -2px;"
                                                *ngIf="workExperienceForm.get('yearFrom')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> From is
                                                Required</mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="fields w-45 b-r">
                                                <mat-select placeholder="To" formControlName="yearTo">
                                                    <mat-option *ngFor="let year of futureYearDropdown" [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                                <mat-error class="error-message error-msg"
                                                *ngIf="workExperienceForm.get('yearTo')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> To is
                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Organization</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="organization">
                                                <mat-error class="error-message error-msg"
                                                *ngIf="workExperienceForm.get('organization')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Organization is
                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Specialization</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="specialization">
                                                <mat-error class="error-message error-msg"
                                                *ngIf="workExperienceForm.get('specialization')?.hasError('required')"><img class="error-icon"
                                                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Specialization is
                                                Required</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4" >
                                    <div class="col-lg-3">
                                        
                                        <button class=" delete"  (click)="deleteWork(i)"><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                </div>
                                
                                </div>
                                </div>
                                </div>
                    </div>
                    </div>
                    </div>
                    </form>
                    <div class="profile-action " *ngIf="showEditForm">
            
    
                        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                    
                    
                        
                    
                    
                        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                    
                    
                       
                      <!--   <span *ngIf="showErrorMsg">
                            <mat-error class="p-2 error-message error-msg"
                            ><img class="error-icon"
                                loading="eager" alt="" src="./../../assets/images/alert.svg" /> please enter all required fields</mat-error>
                        </span> -->
                    </div>
</div>
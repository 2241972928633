import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { SubScriptionDetails } from 'src/app/models/subscription-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor(private http: HttpClient) { }

  SUBSCRITION_URL = environment.ONBOARDING_BASE_URL + '/subscriptions/';

  getAllSubscriptions(): Observable<ApiResponseModel<SubScriptionDetails[]>> {
    return this.http.get<ApiResponseModel<SubScriptionDetails[]>>(this.SUBSCRITION_URL+'all/isActive/true');
  }
}

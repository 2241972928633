<div class="brand-header">
  <ng-container *ngIf="data.logo">
    <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
  </ng-container>
  <div class="brand-details">
    <h1>{{ data.companyName | camelcase}}</h1>
    <ng-container *ngIf="data.url">
      <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
    </ng-container>
  </div>

   <!-- Vertical Line -->
   <div class="vertical-line"></div>
 
   <!-- Address Details -->
    <div class="address-container">
      <p *ngIf="data.address">{{ data.address }}</p>
      <p *ngIf="data.country || data.state || data.city || data.zipCode">
          {{ data.country }}<span *ngIf="data.country && (data.state || data.city || data.zipCode)">, </span>
          {{ data.state }}<span *ngIf="data.state && (data.city || data.zipCode)">, </span>
          {{ data.city }}<span *ngIf="data.city && data.zipCode">, </span>
          {{ data.zipCode }}
      </p>
      <p *ngIf="data.email || data.phoneCode || data.phoneNumber">
          <span *ngIf="data.email">{{ data.email }}</span>
          <span *ngIf="data.email && (data.phoneCode || data.phoneNumber)">, </span>
          <span *ngIf="data.phoneCode">{{ data.phoneCode }}</span>
          <span *ngIf="data.phoneCode && data.phoneNumber">-</span>
          <span *ngIf="data.phoneNumber">{{ data.phoneNumber }}</span>
      </p>
    </div>
</div>

<h1 style="border-bottom: 2px solid #0D87C8;  margin: 0; margin-left: 20px; display: inline-block; color: #0D87C8; font-size: medium; font-weight: bold; font-family: 'work sans';">Send Enquiry</h1>
<h1 style="border: none; border-top: 1px solid #0D87C8;  width: 100%; margin: 0; margin-left: 20px;"></h1>
<div class="container mt-3">

    <div class="card mt-3">
      <div class="card-body">
        <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()">
          <div class="mb-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" id="name" class="form-control" formControlName="name" [ngClass]="{'is-invalid': isNameInvalid}" alphabetOnly>
            <mat-error class="error-message error-msg" *ngIf="isNameInvalid">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
            </mat-error>
          </div>
  
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="email" class="form-label">Email Address</label>
              <input type="email" id="email" class="form-control" formControlName="email" >               <!-- [ngClass]="{'is-invalid': isEmailInvalid}" -->
              <mat-error class="error-message error-msg" *ngIf="(submitted && isEmailError) || ((enquiryForm.get('email')?.dirty || enquiryForm.get('email')?.touched) && !enquiryForm.get('email')?.value)">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Email is Required
              </mat-error>
              <mat-error class="error-message error-msg" *ngIf="enquiryForm.get('email')?.invalid && enquiryForm.get('email')?.value">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Enter valid email
              </mat-error>
            </div>

            <div class="col-md-6">
              <label for="phone" class="form-label">Phone Number</label>
              <div class="input-group" style="display: flex; align-items: center; position: relative;">
            
                <!-- Search and Select Input Field (Country Code) -->
                <input 
                  type="text" 
                  placeholder="IN" 
                  [formControl]="searchControl"
                  (focus)="showDropdownList()"
                  (input)="filterCountryCodes(searchControl.value || '')"
                  (blur)="hideDropdown()"
                  class="form-control country-input"
                />
            
                <!-- Custom Dropdown -->
                <div class="dropdown-menu" [ngClass]="{'show': showDropdown}" style="position: absolute; top: 100%; left: 0; width: 100%; z-index: 1000;">
                  <button 
                    class="dropdown-item" 
                    *ngFor="let code of filteredCountryCodes" 
                    (mousedown)="selectCountryCode(code)"
                  >
                    {{ code.countryCode | uppercase }}
                  </button>
                </div>
            
                <!-- Phone Code Input (Automatically updated) -->
                <input type="text" class="form-control phone-code-input" placeholder="+91" formControlName="phoneCode" readonly required>
            
                <!-- Phone Number Input -->
                <input type="text" class="form-control phone-number-input" formControlName="phone" title="Enter a valid mobile number" minlength="10" maxlength="15" required>
              </div>
            
              <!-- Validation Error Messages -->
              <mat-error class="error-message" *ngIf="enquiryForm.get('phoneCode')?.invalid && enquiryForm.get('phoneCode')?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Country Code is required.
              </mat-error>
            
              <!-- Validation Error Messages for Phone Number -->
              <mat-error class="error-message" *ngIf="phone?.hasError('required') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number is required.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('minlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should be at least 10 digits long.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('maxlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should not exceed 15 digits.
              </mat-error>
            </div>
          </div>
  
          <div class="mb-3">
            <label for="message" class="form-label">Add Message</label>
            <textarea id="message" class="form-control" formControlName="message" rows="4"></textarea>
          </div>
  
          <div class="d-flex">
            <button type="submit" class="btn send-enquiry">Send Enquiry</button>
            <button type="button" class="btn cancel" (click)="onCancel()">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
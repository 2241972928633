<div [formGroup]="remarkForm" class="add-remarks-container">
    <h2 class="add-remarks-description fs-2 mb-4">Remarks</h2>
    <div class="add-remarks-editor-container">
        <h4 *ngIf="!isAddMode">Status: 
        <mat-form-field appearance="outline">
          <mat-select formControlName="status">
              <mat-option *ngFor="let status of statusTypes" [value]="status">{{ status }}</mat-option>
          </mat-select>
      </mat-form-field></h4>
        <angular-editor [config]="editorConfig" formControlName="remarks"></angular-editor>
      </div>
      
<button class="remarks-btn" (click)="addRemarksDescription()">Add</button>
</div>
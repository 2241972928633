import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-member-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileComponent implements OnInit {
  membersColumns: ListColumn[] = [
    { name: 'Photo/Name', property: 'name', visible: true, isModelProperty: true ,isUserImage: true,imageKey:'image'},
  
    { name: 'Position', property: 'position', visible: true, isModelProperty: true ,hasChildProperty: true,childPropertyKey:'name'},
    { name: 'Phone', property: 'phone', visible: true, isModelProperty: true ,isText: true},
  ] as ListColumn[];
  
  @Input() membersForm!:FormGroup;
  @Input() actionType:any;

  positionDropdown:any=[];
  showEditForm:boolean=false;
  copyMemberForm:any;
  data:any=[];

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();
  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify( this.membersForm.get('members')?.value));
    console.log(this.data,"$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$")
    this.getPositionsDropdown();
  }
  constructor(private fb:FormBuilder,private endCustomerService:EndCustomerService){}

  get members() {
    return this.membersForm.controls["members"] as FormArray;
  } 

  getPositionsDropdown(){
    this.endCustomerService.executeGetRequestURL(MweConstants.positionUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.positionDropdown=data.data;
       /*  if(this.members.controls && this.members.controls.length>0){
          for(let i=0;i<this.members.controls.length;i++){
            if(this.members.at(i).get('position')?.value){
              const position=this.positionDropdown.find((obj:any)=>obj.id===this.members.at(i).get('position')?.value?.id);
              if(position){
                this.members.at(i).get('position')?.patchValue(position);
              }
            }
           
          }
        } */
          this.updatePositiobValue();
      }
    },error=>{

    })
  }
  
  addMember(){
    this.copyMemberForm=JSON.parse(JSON.stringify(this.membersForm.value));

    this.members.push(this.fb.group({
      "name"  :new FormControl(null),
       "position": new FormControl(null),
       "phone": new FormControl('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{5,10}$")]),
         "image":new FormControl(null),
         fileName:new FormControl(null)
     }),);
     this.showEditForm=true;
     console.log(this.copyMemberForm?.members )
  }

  editMember(){
    this.copyMemberForm=JSON.parse(JSON.stringify(this.membersForm.value));
    this.showEditForm=true;
  }
  update(){
    this.updateMemberValidations();
    this.members.markAllAsTouched();
    if(this.members.invalid){
      return;
    }
    this.onUpdate.emit(20);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
this.updatePositiobValue();
   this.data=JSON.parse(JSON.stringify( this.membersForm.get('members')?.value));
  }

  updatePositiobValue(){
    if(this.members.controls && this.members.controls.length>0){
      for(let i=0;i<this.members.controls.length;i++){
        if(this.members.at(i).get('position')?.value){
          const position=this.positionDropdown.find((obj:any)=>obj.id===this.members.at(i).get('position')?.value?.id);
          if(position){
            this.members.at(i).get('position')?.patchValue(position);
          }
        }
       
      }
    }
  }
  cancel(){
    
    (this.membersForm.get('members')as FormArray).clear();
     if(this.copyMemberForm?.members && this.copyMemberForm.members.length>0){
          for(let i=0;i<this.copyMemberForm.members.length;i++){
            (this.membersForm.get('members')as FormArray).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "name"  :new FormControl(null),
              "position": new FormControl(null),
              "phone": new FormControl('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
              "image":new FormControl(null),
              fileName:new FormControl(null),
            }));
          }
          (this.membersForm.get('members')as FormArray).patchValue(this.copyMemberForm.members);
        }
        this.updatePositiobValue();
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify( this.membersForm.get('members')?.value));
  
  
  }

  deleteMember(i:number){
    this.members.removeAt(i);
  }

  uploadImage(event:any,i:number){
    let uploadData = new FormData();
     uploadData.append('file', event.target.files[0]);
     let randomNumber = Math.floor(Math.random() * 10) + 1
     uploadData.append('destinationKey', randomNumber+'');
 
     this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
       if(data && data?.data){
        this.members.at(i).get('image')?.patchValue(data.data);
        this.members.at(i).get('fileName')?.patchValue(event.target.files[0].name);
       }
     },error=>{
 
     })
  }

  clearImage(i:number){
    this.members.at(i).get('image')?.reset();
    this.members.at(i).get('fileName')?.reset();
  }

  updateMemberValidations(){
    for(let i=0;i<this.members.controls.length;i++){
      if(!(this.members.at(i).get('name')?.value==null || this.members.at(i).get('name')?.value=='')
        || this.members.at(i).get('position')?.value!=null || !(this.members.at(i).get('phone')?.value==null || this.members.at(i).get('phone')?.value=='') ||
        this.members.at(i).get('image')?.value!=null  ){
          
        this.members.at(i).get('name')?.setValidators([Validators.required]);
        this.members.at(i).get('position')?.setValidators([Validators.required]);
        this.members.at(i).get('phone')?.setValidators([Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{5,10}$")]);
        this.members.at(i).get('image')?.setValidators([Validators.required]);

      }else{
        this.members.at(i).get('name')?.clearValidators();
        this.members.at(i).get('position')?.clearValidators();
        this.members.at(i).get('phone')?.clearValidators();
        this.members.at(i).get('image')?.clearValidators();
      
      }
      this.members.at(i).get('name')?.updateValueAndValidity();
      this.members.at(i).get('position')?.updateValueAndValidity();
      this.members.at(i).get('phone')?.updateValueAndValidity();
      this.members.at(i).get('image')?.updateValueAndValidity();
    }
  }
}


import { AbstractControl, ValidatorFn } from "@angular/forms";

export class TodoItemNode {
  children!: TodoItemNode[];
  item!: string;
  id!: string;
  image! : string;
  disabled!: boolean;
}

export class TodoItemFlatNode {
  id !: string;
  item!: string;
  level!: number;
  expandable!: boolean;
  disabled!: boolean;
  selected?: boolean;
}

export interface CategoryLevelsData {
    searchValue: string | null;
    data: CategoryLevelsTableData[]
  }
  
  export interface CategoryLevelsTableData { 
    id?: number;
    categoryName: string; 
    levelName: string;
  }

  export interface UnitOfMeasureData {
    srNo: number;
    name: string;
    code: string;
    active: string;
  }

  export interface Specifications {
    srNo: number;
    type : string;
    name: string;
    unitOfMeasure: string;
    status: string;
  }

  export interface Distributers {
    distributorId : string;
    distributorName : string;
    distributorContinent : string;
    distributorCountry : string;
    distributorState : string;
    distributorCity : string;
    distributorNetworkId : string
    distributorNetworkName : string
  }

  export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }



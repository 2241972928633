<footer class="footer" >
    <section class="top" style="background-color: #0D87C8;">
    <div class="footer-info" style="display: flex; align-items: center; ">
        <div class="row">
            <div class="col-1 "></div>
 
            <div class="col-4 mt-4 ml-3">
                <img src="/assets/images/layer-1.svg" alt="med world logo">
                <p class='mt-2' style="color: #fff;">At Digiworld Expo, we help everyone with a B2B project make the right purchasing decision. We offer them carefully organized information by selecting quality products and suppliers from around the world. ay after day, our incentive is to help businesses carry out their projects. A lack of quality information will never again be an obstacle to their success.</p>
            </div>
 
 
 
<div class="col-4">
    <div class="row">
        <div class="col-6 mt-5 ml-5 mr-5" >
            <h2 style="color: #fff;">Know More</h2>
            <ul style="color: #fff">
                <li ><a href="" class='links'>How to Source Products</a></li>
                <li ><a href="" class='links'>Exhibit with us</a></li>
                <li ><a href="" class='links'>FAQ</a></li>
                <li ><a href="" class='links'>Blogs</a></li>
                <li ><a href="" class='links'>careers</a></li>
                <li ><a href="" class='links'>Webinars</a></li>
            </ul>
        </div>
        <div class="col-6 mt-5 ml-5 mb-3" >
            <h2 style="color: #fff;">Useful Links</h2>
            <ul style="color: #fff">
                <li ><a href="" class='links'>Brand List</a></li>
                <li ><a href="" class='links'>OEMs</a></li>
                <li ><a href="" class='links'>Distributors</a></li>
                <li ><a href="" class='links'>Our Services</a></li>
                <li ><a href="" class='links'>Newsletter Subscription</a></li>
                <li ><a href="" class='links'>About Digiworld Expo</a></li>
            </ul>
        </div>
        <div class="col-2"></div>
    </div>
</div> 
        </div>
    </div>
</section>
 
<div class="footer-down d-flex justify-content-around p-4" >
    <div class="footertext" style="color: #000000;">
&copy; 2024. All rights reserved. -Terms  -Privacy PolicyAA
</div>
<div class="socialicons d-flex " >
    <div class="imageicon">
      <img  src="/assets/images/Facebook App Symbol.svg" alt="Facebook">
    </div>
 
    <div class="imageicon">
      <img src="/assets/images/Twitter.svg" alt="Twitter">
</div>
    <div class="imageicon">
      <img src="/assets/images/Linkedin Big Logo.svg" alt="linked in">
</div>
      <div class="imageicon">
  <img src="/assets/images/Youtube.svg" alt="youtube">
</div>
  <div class="imageicon">
  <img src="/assets/images/Instagram.svg" alt="Instagram">
  </div>
</div>
</div>
</footer>

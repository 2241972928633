<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Photos</h2>
        <img class="edit-profile "*ngIf="data.length>0 && !showEditForm" (click)="addPhoto()" alt="" src="../../assets/images/editprofile.svg" >

    </div>
    <div class="mt-2" *ngIf="data.length==0 && !showEditForm">
        <button  class="profile-btn" (click)="addPhoto()">
            <mat-icon >add</mat-icon> <p class="ps-2">Add Photos</p></button>
    </div>
    <div class="row mt-3 mb-2" *ngIf="data.length>0 || showEditForm" >
        <div class="card p-4  ">
            <div class="name-container">
                <div *ngFor="let photo of data;let i=index;" class="mt-2">
                    <div class="img-container">
                        <img src="{{photo?.photoLink}}" alt="" class="photo">
                        <div class="dot-circle dots-icon" *ngIf="showEditForm" [matMenuTriggerFor]="menu">
                            <img src="./../../assets/images/vector_dots.svg" class="dots-icon" mat-icon-button   alt="">
                            <mat-menu #menu="matMenu">
                                <mat-icon class="delete-icon" (click)="clearPhoto(i)">delete</mat-icon>
                            </mat-menu>
                        </div>
                        
                    </div>
                    <span class="mx-2"></span>
                </div>
                <div class="mt-2" *ngIf="showEditForm">
                    <input class="d-none" type="file" id="upload" accept=".png,.jpg,.jpeg,.svg" (change)="uploadPhoto($event)" />
                <label class="add-button " for="upload" mat-raised-button>
                    <mat-icon>add</mat-icon>
                </label>
                </div>
                
            </div>
        </div>
    </div>
    <div class="profile-action mt-3" *ngIf="showEditForm">
            
    
        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
    
    
        
    
    
        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
    
    
    
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangePasswordRequest, UserDetails } from 'src/app/models/authData';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-manage-password',
  templateUrl: './manage-password.component.html',
  styleUrls: ['./manage-password.component.scss']
})
export class ManagePasswordComponent implements OnInit{
  passwordForm!: FormGroup;
  userData!: UserDetails;
  constructor(private localStorageService: LocalStorageService, private formBuilder: FormBuilder,private authService: AuthService, private snackBar: MatSnackBar) { }

ngOnInit(): void {
  this.initForm();
}

  onSubmit(): void {
    if (this.passwordForm.valid) {
      const formData = this.passwordForm.value as ChangePasswordRequest;
      formData.token = localStorage.getItem('accessToken') ?? '';
      delete formData['confirmPassword'];
      this.authService.changePassword(formData).subscribe({
        next: (response) => {
          this.snackBar.open('Password changed successfully', 'Close', {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: 'end', // Positioning from left/right
            verticalPosition: 'top', // Positioning from top/bottom
            panelClass: ['green-snackbar'] // Custom CSS class
          });
        },
        error: (error) => {
          console.error('Error changing password', error);
          this.snackBar.open('Failed to change password. Please try again.', 'Close', {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: 'end', // Positioning from left/right
            verticalPosition: 'top' , // Positioning from top/bottom
            panelClass: ['red-snackbar'] // Custom CSS class

          });
        }
      });

    } else {
      this.passwordForm.markAllAsTouched();
    }
  }
  checkPasswords(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
  

    // Check if passwords match
    const passwordsMatch = newPassword === confirmPassword;
  
    // Check for password complexity
    const passwordRegex = /^(?!\s)(?!.*\s$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-])[\w$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-]{8,256}$/;
    const isPasswordComplex = passwordRegex.test(newPassword);
    return passwordsMatch ? null : { notSame: true };
  }

  initForm(): void {
    this.passwordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.checkPasswords });
    this.localStorageService.getUserDetails().subscribe(userDetails => { 
      this.passwordForm.patchValue({email: userDetails.email});
    });
  }
 

  
}

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OemService } from 'src/app/services/oem/oem.service';
import Swal from 'sweetalert2';
import { AddOemNetworkComponent } from '../../dashboard/distributor/add-oem-network/add-oem-network.component';
import { AssignMoreOemsComponent } from '../../dashboard/distributor/assign-more-oems/assign-more-oems.component';

@Component({
  selector: 'app-lead-oem-network',
  templateUrl: './lead-oem-network.component.html',
  styleUrls: ['./lead-oem-network.component.scss']
})
export class LeadOemNetworkComponent {
  @Input() network!: FormGroup;
  data!: any[];
  OEMsNetwork!: any[];
  allOEMs!: any[];
  selectedIds: string[] = [];
  removedIds: string[] = [];
  filteredOEMs: any[] = [];
  isText: boolean = false;
  distributorId: string = '';
  isFilterShow: boolean = false;
  selectedIdsData: any[] = [];

  columns: ListColumn[] = [
    { name: 'Sr No', property: 'srNo',visible:true,isModelProperty:false},
    {name: 'Company Name',property:'companyName',visible:true,isModelProperty:true,isText: true},
    {name: 'Name',property:'name',visible:true,isModelProperty:true,isText: true},
    { name: 'Continent', property: 'continent', visible: true, isModelProperty: true ,isText: true},
    { name: 'Country', property: 'country', visible: true, isModelProperty: true ,isText: true},
    { name: 'State', property: 'state', visible: true, isModelProperty: true ,isText: true},
    { name: 'City', property: 'city', visible: true, isModelProperty: true ,isText: true},
    {name:'Status',property:'active',visible:true,isModelProperty:true,isStatus:true},
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];

  constructor(
    public dialog: MatDialog, 
    private fb: FormBuilder,
    private oemService: OemService,
    private distributorService: DistributorService,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef
  ) {
    this.distributorId = localStorageService.getItem('distributorId');
   }

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.deleteAction:
        this.removeOEM(event.row.id);
        break;
      default:
        break;
    }
  }

  removeOEM(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover the deleted record!',
      iconHtml: '<img src="./assets/images/group-delete.svg">',
      iconColor : 'white',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      confirmButtonColor : '#0f74a9',
      cancelButtonText: 'Cancel',  
      cancelButtonColor : '#6c757d'
    }).then((result) => {
      if (result.value) {
      this.deleteOEM(id);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: 'Item is safe.',
          icon : 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor : '#0f74a9',
        })         
      }
    })
  }

  deleteOEM(id: any) {
    this.OEMsNetwork = this.OEMsNetwork.filter(d => d.id !== id);
    this.selectedIds = this.selectedIds.filter(selectedId => selectedId !== id);
    this.removedIds.push(id);
    const selectedIdsFormArray = this.network.get('selectedIds') as FormArray;
    const removedIdsFormArray = this.network.get('removedIds') as FormArray;
    const index = selectedIdsFormArray.controls.findIndex(control => control.value === id);
    if (index !== -1) {
      selectedIdsFormArray.removeAt(index);
    }
    if (!this.removedIds.includes(id)) {
      removedIdsFormArray.push(new FormControl(id));
    }
    if(this.OEMsNetwork.length <= 0){
      this.isText=false
    }
    console.log('removedids ', this.removedIds, this.selectedIds);
    
  }

  ngOnInit() {
    console.log('networkkkkk', this.network);
    
    
    this.getAllOEMs();
  }

  getAllOEMs() {
    this.distributorService.oemByDistributors(this.distributorId).subscribe((res: any)=>{
      this.data = res.data;
      console.log('dataaaa', this.data);
      this.allOEMs = this.data;
      if (this.data) {
        this.selectedIds = [...this.selectedIds, ...this.data.map(data => data.id)];
      }
      if (this.data) {
        this.OEMsNetwork = this.data.filter(d => this.selectedIds.includes(d.id)).map(d => d);
      }
     if (this.selectedIds.length > 0) {
       this.isText = true;
     }
    })
  } 

  filterOEMs(filters: any): void {
    this.filteredOEMs = this.data.filter(oem => {
      return (!filters.continent || oem.continent === filters.continent) &&
        (!filters.country || oem.country === filters.country) &&
        (!filters.state || oem.state === filters.state) &&
        (!filters.city || oem.city === filters.city);
    });
    this.OEMsNetwork.forEach(selected => {
      if (!this.filteredOEMs.find(d => d.id === selected.id)) {
        this.filteredOEMs.push(selected);
      }
    });
  }
  newOEM: any;

  addNewOEM(){
    const dialogRef = this.dialog.open(AddOemNetworkComponent, {
      width: '40%',
      maxHeight: '80vh',
    });    
    dialogRef.afterClosed().subscribe((result: any)=>{
      console.log('ressss', result);
      const newOEMsFormArray = this.network.get('newOEMs') as FormArray;
      newOEMsFormArray.push(new FormControl(result));
      
    })


  }

  assignMoreOEMs() {
    this.getAllOEMs();
    this.cdr.detectChanges();
    const dialogRef = this.dialog.open(AssignMoreOemsComponent, {
      width: 'auto',
      data: { selectedIds: this.selectedIds, selectedIdsdata: this.selectedIdsData },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      const result = res?.selectedIds || [];
      
      // Optionally, update selectedIds and OEMsNetwork if needed
      if (result && Array.isArray(result)) {
        const selectedIdsFormArray = this.network.get('existingOEMs') as FormArray;
        console.log('result is ', selectedIdsFormArray);

        result.forEach((id: string) => {
          if (!this.selectedIds.includes(id)) {
            this.selectedIds.push(id);
            selectedIdsFormArray.push(new FormControl(id));
          }
        });
  
        this.isText = this.OEMsNetwork && this.OEMsNetwork.length > 0 && this.selectedIds && this.selectedIds.length > 0;
        this.OEMsNetwork = this.data.filter(d => this.selectedIds.includes(d.id)).map(d => d);
        console.log('selected ids data', this.selectedIdsData);
        
        this.OEMsNetwork = this.OEMsNetwork.concat(res.selectedIdsData);
      }
  
      this.cdr.detectChanges();
    });
  }
   
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACCESS_TOKEN, ACCOUNT_NAME, DISTRIBUTOR_ID, EXPIRES_IN, GUEST_TOKEN, ID_TOKEN, OEM_DETAILS, OEM_ID, PREFERENCES, REFRESH_TOKEN, ROLE_NAME, USER_ID, USER_VERIFIED } from '../utils/constants';
import { ChangePasswordRequest, PasswordChangeRequest, PasswordResetRequest, User } from '../models/authData';
import { Router } from '@angular/router';
import { EMPTY, Observable, catchError, of, switchMap, tap, throwError } from 'rxjs';
import { apiUrls } from '../utils/apiUrls';
import { Signup } from '../models/SignupModels';
import { OemService } from './oem/oem.service';
import { PermissionService } from './permission.service';
import { HeaderbreadcrumbService } from './shared/headerbreadcrumb.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userId!: string;
  private loginUrl = apiUrls.login;
  private AccountypesUrl = apiUrls.accounttypes;
  private updatePasswordUrl = apiUrls.updatePassword;


  constructor(private http: HttpClient, private router: Router, private oemService: OemService, private permissionService: PermissionService, private headerBreadCrumbService: HeaderbreadcrumbService) { }

  login(user: any): Observable<any> {
    return this.http.post<any>(this.loginUrl, { email: user.email, password: user.password });
  }
  getAccounttypes(accountType: string): Observable<any> {
    const url = `${this.AccountypesUrl}?accountTypes=${accountType}`;
    return this.http.get<any>(url);
  }
  getCountrycodes(pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get<any>(apiUrls.countryCodes(pageNumber, pageSize));
  }
  getfilteredphonecodes(startsWith: number, pageNumber: number): Observable<any> {
    return this.http.get<any>(apiUrls.countryCodes(startsWith, pageNumber));
  }



  saveTokens(data: any, rememberMeFlag: boolean): void {


    this.userId = data.userId;
    const roleName = data.roleName;
    // const storage = rememberMeFlag ? localStorage : sessionStorage;
    const storage =  localStorage ;
    storage.clear();
    storage.setItem(USER_ID, data.userId);
    storage.setItem(ACCESS_TOKEN, data.accessToken);
    storage.setItem(ID_TOKEN, data.idToken);
    storage.setItem(REFRESH_TOKEN, data.refreshToken);
    storage.setItem(ROLE_NAME, roleName);
    storage.setItem(USER_VERIFIED, String(data.userVerified));
    storage.setItem(ACCOUNT_NAME, data.accountType);
    storage.setItem(PREFERENCES, data.preferences);
   
    if (data.oemId !== null) storage.setItem(OEM_ID, String(data.oemId));
    if (data.distributorId !== null) storage.setItem(DISTRIBUTOR_ID, String(data.distributorId));
    if (data?.status === 'InComplete') {
      localStorage.setItem('isPopupVisible', 'true');
    } 

    const currentTime = Date.now();
    const expiryTime = currentTime + data.expiresIn * 1000;
    storage.setItem(EXPIRES_IN, String(expiryTime));
    if (data.accountType == 'Staff') {
      this.navigateStaff(data.preferences, roleName);
    } else {
      this.navigateByRole(roleName);
    }

  }


  onLogout() {
    localStorage.clear();
    sessionStorage.clear();

    this.router.navigate(['/login']);
  }
  public forgotPassword(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.post<any>(apiUrls.forgotPassword, {}, { params });
  }

  public resetPassword(passwordChangeRequest: PasswordResetRequest): Observable<any> {
    return this.http.post<any>(apiUrls.resetPassword, passwordChangeRequest);
  }

  getAccessTokenExpiry(): Observable<any> {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (refreshToken) {

      console.log("trying to refresh token");
      
      const headers = new HttpHeaders().set('refreshToken', `${refreshToken}`);
      return this.http.post<any>(apiUrls.refreshToken, {}, { headers, observe: 'response' });
    }
    return EMPTY;
  }


  isAuthenticated(): boolean {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const isGuest = localStorage.getItem(GUEST_TOKEN)
    if (token && !isGuest) {
      const expiryTime = Number(localStorage.getItem(EXPIRES_IN));
      const currentTime = Date.now();
      if (currentTime < expiryTime) {
        return true;
      } else {


        this.getAccessTokenExpiry().subscribe();
        return true;
      }
    }
    return false;
  }


  navigateByRole(roleName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.headerBreadCrumbService.setHeaderBreadcrumbs([])
      if (roleName === 'SUPER_ADMIN') {
        this.router.navigate(['dashboard/super-admin']).then(() => resolve(true));
      } else if (roleName === 'DISTRIBUTOR') {
        this.router.navigate(['/dashboard/distributor']).then(() => resolve(true));
      } else if (roleName === 'OEM') {
        this.router.navigate(['/dashboard/oem']).then(() => resolve(true));
      } else if (roleName === 'USER') {
        this.router.navigate(['/user/home']).then(() => resolve(true));
      } else {
        // Handle other roles or default navigation
        resolve(false);
      }
    });
  }

  navigateMyAccountOptionsByRole(roleName:string,routeName:string): Promise<boolean>{
    return new Promise((resolve,reject)=>{
      if(roleName === 'SUPER_ADMIN'){
        this.router.navigate([`dashboard/super-admin/${routeName}`]).then(()=> resolve(true));
      } else if (roleName === 'DISTRIBUTOR') {
        this.router.navigate([`/dashboard/distributor/${routeName}`]).then(() => resolve(true));
      } else if (roleName === 'OEM') {
        this.router.navigate([`/dashboard/oem/${routeName}`]).then(() => resolve(true));
      } else if (roleName === 'USER') {
        this.router.navigate([`/user/home/${routeName}`]).then(() => resolve(true));
      } else {
        resolve(false);
      }
    })
  }

  navigateStaff(permissions: string[], roleName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const menu = roleName == 'SUPER_ADMIN' ? this.permissionService.getSuperAdminMenu() : roleName === 'OEM' ? this.permissionService.getOemMenu() : this.permissionService.getDistributorMenu();
      const filteredMenu = menu.filter((item: any) => {
        if (item?.children && item.children.length > 0) {
          const children = item.children.filter((child: any) => {
            return this.permissionService.hasAnyPermission(child.permissions, permissions);
          })
          if (children && children.length > 0) {
            item.route = children[0].route;
            item.children = children;
            return true;
          }
          return false;
        } else {
          return this.permissionService.hasAnyPermission(item.permissions, permissions)
        }

      })
      if (filteredMenu && filteredMenu.length > 0) {
        this.router.navigate([filteredMenu[0].route]).then(() => resolve(true));
      } else {
        resolve(false);
      }
    });

  }

  getRoleName(): string {
    const roleName = localStorage.getItem(ROLE_NAME);
    if (roleName) {
      return roleName;
    } else {
      return sessionStorage.getItem(ROLE_NAME) || '';
    }
  }

  public updatePassword(passwordChangeRequest: PasswordChangeRequest): Observable<any> {
    return this.http.post<any>(this.updatePasswordUrl, passwordChangeRequest);
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): Observable<any> {
    return this.http.post<any>(this.updatePasswordUrl, changePasswordRequest);
  }


  public signup(signup: Signup): Observable<any> {
    return this.http.post<any>(apiUrls.signup, signup);
  }

  public saveProspect(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.http.post<any>(apiUrls.prospect, {}, { params });
  }


  public resendVerificationEmail(email: string): Observable<any> {
    const body = {};
    const params = new HttpParams().set('email', email);
    return this.http.post<any>(apiUrls.resendVerification, body, { params });
  }
}


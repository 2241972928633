import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OemService } from 'src/app/services/oem/oem.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-viewappointment',
  templateUrl: './viewappointment.component.html',
  styleUrls: ['./viewappointment.component.scss']
})
export class ViewappointmentComponent implements OnInit {
appoinmentId: any;
isEdit: boolean = false;
accountTypes: any[] = [];
selectedStaff = new FormControl([]);
Data: any = {remark:''};
  fullPhoneNumber: string | undefined;

  constructor(private appointmentService : OemService, private sweetAlertService: SweetAlertService,
    private dialogRef: MatDialogRef<ViewappointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.appoinmentId = data.id;
    this.isEdit = data.edit;
    
  }

  ngOnInit(): void {
    this.getAppoinmentById(this.appoinmentId);
  }
  getAppoinmentById(appoinmentId: any) {
    this.appointmentService.getAppoinmentById(appoinmentId).subscribe((data: any) => {
      this.Data=data.data;
      this.fullPhoneNumber = (this.Data.phonecode ? this.Data.phonecode + " " : "") + (this.Data.phone ? this.Data.phone : "");
      this.accountTypes=data.data.staffnames;
     
    if(this.isEdit && this.Data && this.Data.assignedstaff) {
      this.selectedStaff.setValue(this.Data.assignedstaff);
    }
    });
    
   
  }

  update(status :string) {
    if (!this.Data) {
      console.error("No appointment data found.");
      return;
    }
  
    const updatedData = {
      id: this.Data.id,
      active: true,
      createdBy: this.Data.createdBy || '',  
      createdOn: this.Data.createdOn || new Date().toISOString(),
      modifiedBy: this.Data.modifiedBy || '',  
      modifiedOn: new Date().toISOString(),
      name: this.Data.name || '',  
      email: this.Data.email || '',
      date: this.Data.date || '',
      slot: this.Data.slot || '',
      message: this.Data.message || '',
      remark: this.Data.remark || '',
      exhibitionId: this.Data.exhibitionid || '',
      appointmentSequenceId: this.Data.appointmentsequenceid || '',
      phone: this.Data.phone || '',
      phoneCode: this.Data.phonecode || '',
      assignedStaff: this.selectedStaff.value,
      status: status.toLocaleUpperCase()
    };
    this.appointmentService.updateAppointment(this.appoinmentId, updatedData).subscribe(response => {
      if(response){
        this.sweetAlertService.showSuccess('Success', 'Appointment updated successfully !!');
      }
      else{
        this.sweetAlertService.showError('Error', 'Failed to update appointment !!');
      }
      this.dialogRef.close(response);
      this.showAlert(status);
    });
  }

  showAlert(status: string) {
    switch (status.toLocaleLowerCase()) {
      case 'accept':
        Swal.fire({
          icon: 'success',
          title: 'Appointment Status Accepted',
          text: 'Appointment status accepted successfully!',
          confirmButtonText: 'OK'
        });
        break;
      case 'reject':
        Swal.fire({
          icon: 'warning',
          title: 'Appointment Status Rejected',
          text: 'Appointment status rejected!',
          confirmButtonText: 'OK'
        });
        break;
      default:
        Swal.fire({
          icon: 'info',
          title: 'Appointment Updated',
          text: 'Appointment updated successfully!',
          confirmButtonText: 'OK'
        });
        break;
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}

<section class="job-filters-section p-3">
    <div class="">
        <div class="">
            <div class="d-flex">
                <div class="filters-heading me-2">Filters</div>
                <mat-chip class="reset-chip" (click)="resetFilters()"><img src="/assets/images/job-filters-reset.svg" alt="job-filters-reset" class="me-1" />Reset</mat-chip>
            </div>

            <div class="card mt-3" style="height: 95%;">
                <div class="card-body">
                    <form [formGroup]="filterForm">
                        <div class="filter-field-container">
                            <div class="filter-label mb-3">Your Search Keyword</div>

                            <mat-chip-grid #chipList>
                                <div *ngFor="let controlName of filterControls">
                                    <mat-chip-row *ngFor="let item of filterForm.get(controlName)?.value" class="reset-chip" [selectable]="selectable" [removable]="removable" (removed)="remove(controlName, item)">
                                        {{ item  | truncateText: 8 }}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip-row>
                                </div>

                                <label class="input-container w-100">
                                    <mat-icon matPrefix class="inp">search</mat-icon>
                                    <input
                                        matInput
                                        [matChipInputFor]="chipList"
                                        placeholder="Search By Keyword"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur"
                                        (matChipInputTokenEnd)="add('keywords', $event)"
                                    />
                                </label>
                            </mat-chip-grid>
                        </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label">
                                        Region
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput [matAutocomplete]="countryAuto" placeholder="Search for country" [formControl]="countryControl" />
                                    <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onOptionSelected($event, 'country')">
                                        <mat-option *ngFor="let country of filteredCountries" [value]="country">
                                            {{ country.countryName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput [matAutocomplete]="stateAuto" placeholder="Search for state" [formControl]="stateControl" />
                                    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onOptionSelected($event, 'state')">
                                        <mat-option *ngFor="let state of filteredStates" [value]="state">
                                            {{ state.stateName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput [matAutocomplete]="cityAuto" placeholder="Search for city" [formControl]="cityControl" />
                                    <mat-autocomplete #cityAuto="matAutocomplete" (optionSelected)="onOptionSelected($event, 'city')">
                                        <mat-option *ngFor="let city of filteredCities" [value]="city">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput multiple placeholder="Filter By Zip Code" formControlName="zipCode" />
                                </mat-form-field>
                            </mat-expansion-panel>
                        </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label">
                                        Brands
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput [matAutocomplete]="brandsAuto" placeholder="Search for Brands" [formControl]="brandControl" />
                                    <mat-autocomplete #brandsAuto="matAutocomplete" (optionSelected)="onOptionSelected($event, 'brand')">
                                        <mat-option *ngFor="let brand of filteredBrands" [value]="brand">
                                            {{ brand.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </mat-expansion-panel>
                        </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label">
                                        Year in Market
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder=" Year in Market" formControlName="yearInMarkets">
                                        <mat-option *ngFor="let year of yearInMarkets" [value]="year">{{ year }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-expansion-panel>
                        </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label">
                                        Turn Over
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-slider min="0" max="200000" class="w-100">
                                    <input formControlName="amountFrom" matSliderStartThumb />
                                    <input formControlName="amountTo" matSliderEndThumb />
                                </mat-slider>
                                <p>
                                    ${{ filterForm.get("amountFrom")?.value }} - ${{ filterForm.get("amountTo")?.value }}
                                </p>
                            </mat-expansion-panel>
                        </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label">
                                        Category
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Filter By Category" formControlName="categories">
                                        <mat-option *ngFor="let category of categories" [value]="category.name">{{ category?.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-expansion-panel>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

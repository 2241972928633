import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { OEMUrls, apiUrls } from 'src/app/utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {

  constructor( private http: HttpClient) { }
  
  getSubscriptionById(id: string): Observable<any> {
    return this.http.get<ApiResponseModel<any>>(`${OEMUrls.subscriptions}/${id}`);
  }
  
  getAllSubscriptions(): Observable<ApiResponseModel<any>> {
    return this.http.get<ApiResponseModel<any>>(`${OEMUrls.subscriptions}/all`);
  }
  
  updateSubscription(id: string, subscription: any): Observable<ApiResponseModel<any>> {
    return this.http.put<ApiResponseModel<any>>(`${OEMUrls.subscriptions}/${id}`, subscription);
  }
  
  deleteSubscription(id: string): Observable<ApiResponseModel<any>> {
    return this.http.delete<ApiResponseModel<any>>(`${OEMUrls.subscriptions}/${id}`);
  }
  
  addSubscription(subscription: any): Observable<ApiResponseModel<any>> {
    return this.http.post<ApiResponseModel<any>>(`${OEMUrls.subscriptions}/add`, subscription);
  }
  
}

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProductsService } from 'src/app/services/products.service';
import { HeaderbreadcrumbService } from 'src/app/services/shared/headerbreadcrumb.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-website-header',
  templateUrl: './website-header.component.html',
  styleUrls: ['./website-header.component.css']
})
export class WebsiteHeaderComponent implements OnInit {


  isDropdownOpen: boolean = false;
  isLoggedin: boolean=false;
  isCategoryDropdownOpen: boolean = false;
  isKnowledgeCenterDropdownOpen: boolean  = false;
  isNewsAndInsightsDropdownOpen: boolean  = false;
  isHealthCenterDropdownOpen: boolean  = false;
  userName: string = '';
  categories: any = [];
  products: any=[];
  oems:any =[];
 webinars:any =[];
  filteredCategories:any =[];
  showDropdown = false;
  randomData: string[] = ["Anvesh","aleti"];
  brands: any = [
    { id: 1, name: 'Brand 1' },
    { id: 2, name: 'Brand 2' },
    { id: 3, name: 'Brand 3' },
    { id: 4, name: 'Brand 4' },
    { id: 5, name: 'Brand 5' }
  ];
  KnowledgeCenterList: string[] = ['Webinars', 'Conferences/ CME', 'Blogs & Community', 'E-Magazines', 'Journals'];
  NewsAndInsightsList: string[] = ['Latest News', 'Events'];
  HealthCenterList: string[] = ['Hospitals', 'Labs', 'Clinics'];

  constructor( private http: HttpClient ,private translateService : TranslateService, private authService: AuthService, private localStorageService: LocalStorageService , private categoryService: CategoryService, private sweetAlertService: SweetAlertService, private router: Router,private productsservice: ProductsService, private headerBreadCrumbService: HeaderbreadcrumbService) { }

  toggleDropdown(open: boolean): void {
    this.isDropdownOpen = open;
  }
  isLanguageDropdownOpen: boolean = false;
  selectedLanguage: string = 'en'; // To store selected language

ngOnInit(): void {
  this.isLoggedIn();
  this.fetchCategories();
  this.localStorageService.getUserDetails()?.subscribe(userDetails => { 
    this.userName = userDetails?.userName;
  });

  
}

  toggleLanguageDropdown(open: boolean): void {
    this.isLanguageDropdownOpen = open;
  }

  selectLanguage(language: string): void {
    this.selectedLanguage = language;
    this.translateService.setDefaultLang(language)
    // You can add logic here to change the language settings in your application
  }

  logout(): void {
    this.sweetAlertService.showConfirmation(
      'Logout',
      'Are you sure you want to logout?',
      'Yes',
      'No'
    ).then((result) => {
      if (result.isConfirmed) {
        console.log("Resr" ,this.userName , " logged out");
        
        this.userName = ''
        this.authService.onLogout();
      }
    });
  }
  isLoggedIn(){
    if (this.authService.isAuthenticated()) {
      this.isLoggedin = true;
    }
  }
  navigateByRole(){
    this.authService.navigateByRole(this.localStorageService.getItem('roleName'));
  }
  navigateMyAccountOptionsByRole(routeName:string, breadcrumbName:string):void{
    this.authService.navigateMyAccountOptionsByRole(this.localStorageService.getItem('roleName'),routeName);
    this.headerBreadCrumbService.setHeaderBreadcrumbs([breadcrumbName])
  }
 
  fetchCategories() {
    this.categoryService.getAllParentCategories().subscribe(
      (response: ApiResponseModel<any[]>) => {
        this.categories = response.data; // Assign fetched categories to local variable
      },
      (error) => {
        console.error('Error fetching categories:', error);
      }
    );
  }
  toggleCategoryDropdown(open: boolean): void {
    this.isCategoryDropdownOpen = open;
  }

  toggleKnowledgeCenterDropdown(open: boolean): void{
    this.isKnowledgeCenterDropdownOpen = open;
  }

  toggleHealthCenterDropdown(open: boolean): void{
    this.isHealthCenterDropdownOpen = open;
  }

  toggleNewsAndInsightsDropdown(open: boolean): void{
    this.isNewsAndInsightsDropdownOpen = open;
  }
  
  search(searchTerm: string): void {
    console.log('search activateddd');
    
    
    if (searchTerm.length <= 2) {
      this.showDropdown = false;
      return;
    }
  
    this.products = [];
    this.oems = [];
    this.filteredCategories = [];
    this.webinars = [];
  
    this.productsservice.searchitemsbykeyword(searchTerm).subscribe(
      response => {
        console.log('search results', response);
        
        if (response?.data && response.data.length > 0) {
          const newProducts: any[] = [];
          const newOems: any[] = [];
          const newCategories: any[] = [];
          const newWebinars: any[] = [];
  
          response.data.forEach((item: any) => {
            if (item?.type === "Product") newProducts.push(item);
            else if (item?.type === 'OEM') newOems.push(item);
            else if (item?.type === 'Category') newCategories.push(item);
            else if (item?.type === 'Webinar') newWebinars.push(item);
          });
  
          this.products = newProducts;
          this.oems = newOems;
          this.filteredCategories = newCategories;
          this.webinars = newWebinars;
          console.log(this.products, this.oems, this.filteredCategories, this.webinars);
          
  
          this.showDropdown = this.products.length > 0 || this.oems.length > 0 || this.webinars.length > 0;
        } else {
          this.showDropdown = false;
        }
      },
      error => {
        console.error('Error fetching data:', error);
        this.showDropdown = false;
      }
    );
  }
  
  navigateTo(route: string[]): void {
    this.router.navigate(route).then(() => {
      window.location.reload();
    });
  }

  navigateToBrandProfile(oemId: string) {
    const navigationExtras: NavigationExtras = {
        queryParams: { type: 'brand' }
    };
    this.router.navigate([`/brands/brand-profile/${oemId}`], navigationExtras).then(() => {
      window.location.reload();
    });
  }

  navigateToWebinar(arg0: any) {
    this.router.navigate([`/webinars/view/${arg0}`]).then(() => {
      window.location.reload();
    });
  }
    // this.router.navigate([`/brands/brand-profile/${oemId}`], navigationExtras);
}



import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MweConstants } from 'src/app/constants/mwe-constants.service';


@Component({
  selector: 'app-mwe-table',
  templateUrl: './mwe-table.component.html',
  styleUrls: ['./mwe-table.component.css']
})
export class MweTableComponent implements OnChanges, AfterViewInit {

  @Input() actions: Array<any> = [];

  @Input()
  showSearch: boolean | undefined;
  @Input()
  showMasterDataSearch: boolean | undefined;

  @Input()
  showFilterByDate: boolean | undefined;

  @Input()
  showAddButton: boolean | undefined;

  @Input() showHeader: boolean = true;

  @Input()
  showFilter: boolean | undefined;

  @Input()
  showExport: boolean | undefined;

  @Input()
  showImport: boolean | undefined;

  @Input()
  showSelected: boolean | undefined;

  @Input()
  columns!: Array<any>;

  @Input()
  data: Array<any> = [];

  @Input()
  actionType: string | undefined;

  @Input() showPagination: boolean = true;

  @Input() showAddButtonName = 'Add';

  @Input()
  title!: string;
  @Output() selectedAction = new EventEmitter<any>();

  @Input() showDropdownFilter!: boolean;

  @Input() filterDropdown: any;
  @Input() currentPageChanged: number = 0;
  @Output() pageChanged = new EventEmitter<{ pageIndex: number, pageSize: number }>();
  @Output() statusChange = new EventEmitter<{ id: string, leadState: string }>();

  @Output() applyyFilter = new EventEmitter<string>();
  @Input() isDynemicPagination: boolean = false;
  @Input() preSelectedList: any[] = [];

  dataSource = new MatTableDataSource<any[]>;
  paginator!: MatPaginator;
  sort!: MatSort;
  pageChange: any;



  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    if (Array.isArray(this.dataSource.data)) {
      this.dataSource.paginator = this.paginator;
    }
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    if (this.dataSource.data) {
      if (this.dataSource.data && Array.isArray(this.dataSource.data)) {
        this.dataSource.data = this.dataSource.data.slice();
      }
      if (this.dataSource.data && Array.isArray(this.dataSource.data)) {
        this.dataSource.sort = this.sort;
      }
    }
  }
  displayedColumns: any[] = [];

  addAction = MweConstants.addAction;
  scheduleCallAction = MweConstants.scheduleCall;
  sendMailAction = MweConstants.sendMail;
  sendRequestAction = MweConstants.sendRequest;
  searchAction = MweConstants.searchAction;
  filterAction = MweConstants.filterAction;
  importAction = MweConstants.importAction;
  exportAction = MweConstants.exportAction;
  fileDownloadAction = MweConstants.fileDownloadAction;
  fillterDropdownAction = MweConstants.fillterDropdownAction;
  filterByDateAction = MweConstants.filterByDateAction;
  checkBox = MweConstants.checkBox;


  constructor(private changeRef: ChangeDetectorRef, private _liveAnnouncer: LiveAnnouncer,) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.currentPage = this.currentPageChanged;
    this.displayedColumns = this.columns.filter(column => column.visible).map(column => column.property);
    this.dataSource = new MatTableDataSource<any[]>(this.data);
    this.updateTableData();
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    if (Array.isArray(this.dataSource.data)) {
      this.dataSource.data = this.dataSource.data.slice();
      this.dataSource.paginator = this.paginator;
    }
    if (this.dataSource.data) {
      if (Array.isArray(this.dataSource.data)) {
        this.dataSource.data = this.dataSource.data.slice();
      }
      if (this.dataSource.data) {
        if (Array.isArray(this.dataSource.data)) {
          this.dataSource.data = this.dataSource.data.slice();
          this.dataSource.sort = this.sort;
        }
      }
    }
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      let matchFound = false;
      for (let column of this.displayedColumns) {
        if (column in data) {
          if (data[column]) {
            matchFound = (matchFound || data[column].toString().trim().toLowerCase().includes(filter.trim().toLowerCase()))
          }
        }
      }
      return matchFound;
    }
    this.changeRef.detectChanges();
  }
 
  announceSortChange(sortState: any) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  updateAction(obj: any, type: string) {
    this.selectedAction.emit({ row: obj, action: type });
  }
  deleteAction(row: any) {
    this.selectedAction.emit({ row, action: 'delete' });
  }

  onStatusChange(row: any, column: any) {
    this.statusChange.emit({ id: row.id, leadState: row[column.property] });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.showMasterDataSearch) {
      this.applyyFilter.emit(filterValue);
    }

    else {
      this.currentPage = 0;
      if (this.paginator) {
        this.paginator.pageIndex = this.currentPage;
        this.dataSource.paginator = this.paginator;
      }
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }


  updateTableData() {
    this.totalData = this.data?.length;

    /*  const startIndex = this.currentPage * this.pageSize;
     const endIndex = startIndex + this.pageSize; */
    if (this.paginator) {
      this.paginator.pageIndex = this.currentPage;
      this.dataSource.paginator = this.paginator;
    }
    // this.dataSource.data = this.data.slice(startIndex, endIndex);
  }
  pageSize = 10;
  currentPage = 0;
  totalData = this.data.length;
  changePage(pageIndex: number) {
    this.currentPage = pageIndex;
    this.pageChanged.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
    this.updateTableData();
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.currentPage = 0;
    this.pageChanged.emit({ pageIndex: this.currentPage, pageSize: this.pageSize });
    this.updateTableData();
  }

  getTotalPages(): number {
    return Math.ceil(this.totalData / this.pageSize);
  }

  getPageNumbers(): (number | string)[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPage;
    const pageNumbers: (number | string)[] = [];
    // Always show the first page
    pageNumbers.push(0);

    // Show ellipsis if there are more than 3 pages before the current page
    if (currentPage > 2) {
      pageNumbers.push('...');
    }

    // Show current page and its neighbors
    for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    // Show ellipsis if there are more than 3 pages after the current page
    if (currentPage < totalPages - 3) {
      pageNumbers.push('...');
    }

    // Always show the last page if total pages is greater than 1
    if (totalPages > 1) {
      pageNumbers.push(totalPages - 1);
    }

    return pageNumbers;
  }
  openUrl(url: any) {
    window.open(url, '_blank')
  }

  getFileName(url: string): any {
    const parts = url.split('/');
    return parts[parts.length - 1];

  }
  calculateSerialNumber(index: number): number {
        return (this.currentPage * this.pageSize) + index + 1;
  }
  isSelected(id: string, preSelectedList: string[] | null): boolean {
    if (!preSelectedList) return false;
    const sanitizedId = id.replace(/['"]+/g, '').trim();
    for (const item of preSelectedList) {
      const sanitizedItem = item.replace(/['"]+/g, '').trim();
      if (sanitizedItem === sanitizedId) {
        return true; 
      }
    }
    return false;
  }
  
  
  



}


<div *ngIf="isPopUpVisible && !isProceed" class="popup-container">
  <div class="popup-content">
    <p class="user-name">Hi, {{ firstName }}</p>
    <p class="message" *ngIf="!remarks">Please fill your company details</p>
    <p class="description" *ngIf="remarks" [innerHTML]="remarks"></p>
    <button class="proceed-button" (click)="proceed()">Proceed</button>
  </div>
</div>

<div *ngIf="!isPopUpVisible || isProceed" class="company-profile-container" style="padding: 15px; margin-top: -10px; padding-bottom: 10px;">
  <div style="top: 20px; left: 30px">
    <div class="tab-group" #tabGroup style="cursor: pointer;">
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === 0 }" (click)="changeTab(0)">
        <p>Company Information</p>
      </div>
      <div class="tab" *ngIf="isPopUpVisible" [ngClass]="{ 'tab-active': selectedTab === 1 }" (click)="changeTab(1)">
        <p>Certificates Information</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 2 : 1)}" (click)="changeTab(isPopUpVisible ? 2 : 1)">
        <p>Company Description</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 3 : 2) }" (click)="changeTab(isPopUpVisible ? 3 : 2)">
        <p>Branches Information</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 4 : 3) }" (click)="changeTab(isPopUpVisible ? 4 : 3)">
        <p>Point of Contacts</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 5 : 4) }" (click)="changeTab(isPopUpVisible ? 5 : 4)">
        <p>Product Categories</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 6 : 5) }" (click)="changeTab(isPopUpVisible ? 6 : 5)">
        <p>Distribution Network</p>
      </div>
      <div class="tab" [ngClass]="{ 'tab-active': selectedTab === (isPopUpVisible ? 7 : 6) }" (click)="changeTab(isPopUpVisible ? 7 : 6)">
        <p>Financial Information</p>
      </div>
    </div>
  </div>
  
  <div>
    <div *ngIf="selectedTab === 0">
      <app-oem-company-information [companyInfo]="companyForm" [isPopUpVisible]="isPopUpVisible"></app-oem-company-information>
    </div>
    <div *ngIf="selectedTab === 1 && isPopUpVisible">
      <app-oem-company-information [companyInfo]="companyForm" [isPopUpVisible]="isPopUpVisible" [isCertificatePopUpVisible]="isCertificatePopUpVisible"></app-oem-company-information>
    </div>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 2 : 1)">
    <app-oem-profile-summary [companyInfo]="companyForm" [attachmentsForm]="companyForm" (onUpdateEvent)="onUpdateEvent()"></app-oem-profile-summary>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 3 : 2)">
    <app-oem-branch-info [branchInfo]="companyForm" (branchAdded)="handleBranchAdded($event)"></app-oem-branch-info>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 4 : 3)">
    <app-oem-point-of-contacts [pointOfContacts]="pointOfContacts" (contactsAdded)="handleBranchAdded($event)"></app-oem-point-of-contacts>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 5 : 4)">
    <app-company-product-categories [productCategoriesForm]="productCategorys"></app-company-product-categories>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 6 : 5)">
    <app-oem-distributor-network [network]="distributorNetwork"></app-oem-distributor-network>
  </div>
  <div *ngIf="selectedTab === (isPopUpVisible ? 7 : 6)">
    <app-company-finacial-info [financialInformationForm]="companyForm" (financialAdded)="handleBranchAdded($event)"></app-company-finacial-info>
  </div>

  <div class="btn-actions" style="margin-top: 5px;">
    <button *ngIf="selectedTab > -1&& selectedTab!= (isPopUpVisible ? 3 : 2)&& selectedTab !== (isPopUpVisible ? 4 : 3) && selectedTab !== (isPopUpVisible ? 7 : 6)" class="mwe-button mwe-button--primary ps-3 pe-3" (click)="save()">Save</button>&nbsp;&nbsp;
    <button *ngIf="selectedTab >-1&& selectedTab< (isPopUpVisible ? 7 : 6)" class="mwe-button mwe-button--secondary ps-3 pe-3" (click)="navigateNext()">Next</button>&nbsp;
    <div style="display: flex; justify-content: center;">
      <button *ngIf="isPopUpVisible && selectedTab === (isPopUpVisible ? 7 : 6)" class="mwe-button mwe-button--primary ps-3 pe-3"  (click)="submitForApproval()">Submit for Approval</button>
    </div>
  </div>
</div>

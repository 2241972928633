<div class="product-category">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- Leaf node template (no children) -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
            <ng-container *ngIf="node.disabled">
                <button mat-icon-button disabled type="button"  matTreeNodeToggle></button>
            </ng-container>

            <ng-container *ngIf="!hasChild(0, node)">
                <mat-checkbox class="checklist-leaf-node"  [checked]="node.selected" (change)="todoLeafItemSelectionToggle(node)">
                    {{ node.item }}
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="hasChild(0, node)">
                {{ node.item }}
            </ng-container>
        </mat-tree-node>

        <!-- Parent node template (has children) -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button type="button"  matTreeNodeToggle [attr.aria-label]="'toggle ' + node.item">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            <ng-container *ngIf="!hasChild(0, node)">
                <mat-checkbox  [checked]="node.selected" (change)="todoItemSelectionToggle(node)">
                    {{ node.item }}
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="hasChild(0, node)">
                {{ node.item }}
            </ng-container>
        </mat-tree-node>
    </mat-tree>
</div>
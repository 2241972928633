import { Component, OnInit } from '@angular/core';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss']
})
export class WebinarComponent implements OnInit {

  webinarsData: any[] = [];
  specialties: any[] = [];
  sponsors: any[] = [];
  doctors: any[] = [];
  viewMoreUpcomingToggle: boolean = true;
  viewMoreArchivedToggle: boolean = true;

  

  upcomingWebinars: any[] = [];
  sponsoredWebinars: any[] = [];
  archivedWebinars: any[] = [];

  specialityViewAll: number = 0;
  sponsorViewAll: number = 0;
  doctorViewAll: number = 0;
  webinars: any;
  filteredData: any = {
    upcoming: {
      orgName: [],
      speakersName: [],
      speciality: []
    },
    archived: {
      orgName: [],
      speakersName: [],
      speciality: []
    }
  };

  filteredParams: { orgName: any[], speakersName: any[], speciality: any[] } = {
    orgName: [],
    speakersName: [],
    speciality: []
  };

  constructor(private webinarService: WebinarService, private router: Router) { }

  ngOnInit(): void {
    this.getDoctorNames();
    this.getSponsorNames();
    this.getSpeciality();
    this.filters(this.filteredParams);
  }

  getDoctorNames() {
    this.webinarService.getSpeakers().subscribe((res: any) => {
      res.data.map((s: any) => {
        this.doctors.push(s.speakersName);
      });
    });
  }

  getSponsorNames() {
    this.webinarService.getSponsors().subscribe((res: any) => {
      res.data.map((s: any) => {
        this.sponsors.push(s.orgName);
      });
    });
  }

  getSpeciality(pageNumber: number = 0, pageSize: number = 100) {
    this.webinarService.getSpeciality(pageNumber, pageSize).subscribe((data: any) => {
      if (data && data.data) {
        const specialties = data.data.map((spec: any) => spec.specialty);
        this.specialties.push(...specialties);
        this.specialties.sort();
        if (data.data.length === pageSize) {
          this.getSpeciality(pageNumber + 1, pageSize);
        }
      }
    });
  }

  filters(filteredData: any) {
    this.webinarService.filters(filteredData).subscribe((res: any) => {
      this.webinars = res.data;
      this.upcomingWebinars = this.webinars.filter((card: any) => new Date(card.webinarDate) >= new Date());
      this.archivedWebinars = this.webinars.filter((card: any) => new Date(card.webinarDate) < new Date());
      console.log('this.archivedWebinars', this.archivedWebinars);
    });
  }

  specialtiesViewAll() {
    this.specialityViewAll = 1;
  }

  sponsorsViewAll() {
    this.sponsorViewAll = 1;
  }

  doctorsViewAll() {
    this.doctorViewAll = 1;
  }

  sponsorSelected(event: any, item: any, type: string) {
    if (type === 'archived') {
      this.updateFilter(event.checked, item, 'archived', 'orgName');
      this.filterWebinars('archived');
    } else if (type === 'specialty') {
      this.updateFilter(event.checked, item, 'upcoming', 'orgName');
      this.filterWebinars('upcoming');
    }
    console.log(this.filteredData);
  }

  specialtySelected(event: any, item: any, type: string) {
    if (type === 'archived') {
      this.updateFilter(event.checked, item, 'archived', 'speciality');
      this.filterWebinars('archived');
    } else if (type === 'specialty') {
      this.updateFilter(event.checked, item, 'upcoming', 'speciality');
      this.filterWebinars('upcoming');
    }
    console.log(this.filteredData);
  }

  doctorSelected(event: any, item: any, type: string) {
    if (type === 'archived') {
      this.updateFilter(event.checked, item, 'archived', 'speakersName');
      this.filterWebinars('archived');
    } else if (type === 'specialty') {
      this.updateFilter(event.checked, item, 'upcoming', 'speakersName');
      this.filterWebinars('upcoming');
    }
    console.log(this.filteredData);
  }

  updateFilter(isChecked: boolean, item: any, category: string, key: string) {
    if (isChecked) {
      this.filteredData[category][key].push(item);
    } else {
      const index = this.filteredData[category][key].indexOf(item);
      if (index > -1) {
        this.filteredData[category][key].splice(index, 1);
      }
    }
  }

  filterWebinars(category: string) {
    if (category === 'archived') {
      this.archivedWebinars = this.webinars
        .filter((card: any) => new Date(card.webinarDate) < new Date())
        .filter((card: any) =>
          (this.filteredData.archived.orgName.length === 0 ||
            this.filteredData.archived.orgName.includes(card.orgName)) &&
          (this.filteredData.archived.speciality.length === 0 ||
            this.filteredData.archived.speciality.includes(card.speciality)) &&
          (this.filteredData.archived.speakersName.length === 0 ||
            this.filteredData.archived.speakersName.includes(card.speakersName))
        );
    } else if (category === 'upcoming') {
      this.upcomingWebinars = this.webinars
        .filter((card: any) => new Date(card.webinarDate) >= new Date())
        .filter((card: any) =>
          (this.filteredData.upcoming.orgName.length === 0 ||
            this.filteredData.upcoming.orgName.includes(card.orgName)) &&
          (this.filteredData.upcoming.speciality.length === 0 ||
            this.filteredData.upcoming.speciality.includes(card.speciality)) &&
          (this.filteredData.upcoming.speakersName.length === 0 ||
            this.filteredData.upcoming.speakersName.includes(card.speakersName))
        );
    }
  }

  view(id: any) {
    this.router.navigate(['/webinars/view', id]);
  }

  viewMore(type: string) {
    switch (type) {
      case 'upcoming':
        this.viewMoreUpcomingToggle = false;
        break;
      
      case 'archived':
        this.viewMoreArchivedToggle = false;
        break;

        break;
    }
  }
}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Inject, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'] 
})
export class ProductFilterComponent implements OnInit{ 
  @Output() filterApplied = new EventEmitter<any>();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);
  productFilterForm!:FormGroup;
  status:any=[{label:'Active',value:'active'},{label:'Inactive',value:'inactive'}];
  createdBy:any=[];
  oems:any=[];
  categories:any=[];
  users:any[] = [];
  isEditMode = false;
  selectedOEM ='';
  addOnBlur = true;
  selectable = true;
  removable = true;
  modifiedOnData: any[] = [];
  oemId : string = ''; 

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<any>,
    private categoryService:CategoryService, 
    private fb:FormBuilder,
    private allProductService : AllProductService
  )  {
    this.productFilterForm = this.fb.group({
      status:[''],
      createdBy:[''],
      oem:[''],
      category:[''],
      startWith: [''],
      createdOn: ['']
    });
  }


  ngOnInit(): void {
    console.log('this.data',this.data);
    if(this.data){
      this.productFilterForm.get('startWith')?.setValue('');
      this.productFilterForm.get('status')?.setValue(this.data.status);
      this.productFilterForm.get('createdBy')?.setValue(this.data.createdBy ? this.data.createdBy.id : '');
      this.productFilterForm.get('oem')?.setValue(this.data.oemId);
      this.productFilterForm.get('category')?.setValue(this.data.categoryId ? this.data.categoryId.id : '');
      this.productFilterForm.get('createdOn')?.setValue(this.data.createdOn ? this.data.createdOn : '');
      if(this.data.oemId) {
        this.selectedOEM = this.data.oemId.name;
        this.isEditMode = true;
      }
      console.log('productFilterForm',this.productFilterForm.value); 
    }

    const id = localStorage.getItem('oemId');
    if(id !== null && id !== undefined && id !== '') {
      this.oemId = id;
    }

    this.getAllUsers();
    this.getCategory();
    this.getModifiedOnByOemId(this.oemId);

    this.productFilterForm.get('startWith')?.valueChanges.subscribe(value => {
      this.getOEM(value)
    }); 
  }

  loadOEM() {
    this.getOEM('');
  }

  getModifiedOnByOemId(id: string): void {
    this.allProductService.getModifiedOnByOemId(id).subscribe(
      (response) => {
        this.modifiedOnData = Array.from(
          new Set(
            response.data.map((item: any) => this.convertTimestampToDate(item.modifiedOn))
          )
        );
        console.log(this.modifiedOnData);
      },
      (error) => {
        console.error('Failed to fetch modified data', error);
      }
    );
  }

  convertTimestampToDate(timestamp: number): string {
    if (!timestamp) return ''; // Return empty string if timestamp is null or undefined
    const date = new Date(timestamp); // Convert timestamp to Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`; // Format the date as YYYY-MM-DD
  }

  getAllUsers() {
    this.allProductService.getAllUsers().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.users = response.data;
        } 
      },
      error: (error) => {
        console.error('Error fetching user data:', error);
      }
    });
  }

  getOEM(startWith : string) {
    this.allProductService.getOEMs(0,10, startWith).subscribe({
      next: (response) => {
        this.oems = response.data;
      },
      error: (error) => {
        console.error('Error fetching oem data:', error);
      }
    });
  }

  onOEMChange(event : any) {
    this.selectedOEM = event.option.value.name
    this.productFilterForm.get('oem')?.setValue( event.option.value);
    this.productFilterForm.get('startWith')?.setValue('');
    this.isEditMode = false;
  }

  removeOEM() {
    this.selectedOEM = '';
    this.productFilterForm.get('oem')?.setValue(null);
    this.productFilterForm.get('startWith')?.setValue('');
    this.isEditMode = false;
  }

  addOEM(event: MatChipInputEvent) {
    event.chipInput!.clear();
  }

  getCategory() {
    this.categoryService.getAllCategories().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.categories = response.data;
        } 
      },
      error: (error) => {
        console.error('Error fetching category data:', error);
      }
    });
  }

  filterProduct(){
    const formData = this.productFilterForm.value;
    console.log(formData);
    
    const category = this.categories.find((c: { id: any; }) => c.id === formData.category);
    const createdBy = this.users.find((u: { id: any; }) => u.id === formData.createdBy);
    const createdOn = formData.createdOn ?  formData.createdOn : '';

    const payload = {
        oemId:  formData.oem,
        categoryId : category === undefined ? '' : category,
        status : formData.status,
        createdBy:createdBy === undefined ? '' : createdBy,
        createdOn:createdOn === undefined ? '' : createdOn
     };
     console.log(payload);
       
   this.dialogRef.close(JSON.parse(JSON.stringify(payload)));
  }

  clearFilter(){
    this.productFilterForm.reset();
    this.dialogRef.close(null);
  }

  closeDialog(){
    this.filterProduct();
  }

}

import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService { 

  CANCEL = Swal.DismissReason.cancel;

  constructor() { }

  showAlert(options: SweetAlertOptions): void {
    Swal.fire(options);
  }

  showSuccess(title: string, text: string): void {
    Swal.fire({
      position : 'center',
      title: title,
      text: text,
      iconHtml: '<img src="./assets/images/confirm-btn.svg">',
      confirmButtonText: 'OK',
      confirmButtonColor : '#0f74a9',
    });
  }

  showError(title: string, text: string): void {
    Swal.fire({
      position : 'center',
      title: title,
      text: text,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor : '#0f74a9',
    });
  }

  showWarning(title: string, text: string): void {
    Swal.fire({
      position : 'center',
      title: title,
      text: text,
      iconHtml: '<img src="./assets/images/group-delete.svg">',
      iconColor : 'white',
      confirmButtonText: 'OK',
      confirmButtonColor : '#0f74a9',
    });
  }

  showInfo(title: string, text: string): void {
    Swal.fire({
      position : 'center',
      title: title,
      text: text,
      iconHtml: '<img src="./assets/images/confirm-btn.svg">',
      iconColor : 'white',
      confirmButtonText: 'OK',
      confirmButtonColor : '#0f74a9',
    });
  }

  showConfirmation(title: string, text: string, confirmButtonText: string, cancelButtonText: string): Promise<any> {
    return Swal.fire({
      position : 'center',
      title: title,
      text: text,
      iconHtml: '<img src="./assets/images/group-delete.svg">',
      iconColor : 'white',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      confirmButtonColor : '#0f74a9',
      cancelButtonText: cancelButtonText,  
      cancelButtonColor : '#6c757d'
    });
  }

  showWelcomeMessage(): void {
    Swal.fire({
      title: 'Welcome to Medworld Expo!',
      html: 'Your premier digital platform for connecting<br>buyers and sellers across the world.',
      icon: 'success',
      timer: 3000,
      timerProgressBar: true,
      willClose: () => {
        this.promptEmailInput();
      }
    });
  }

  promptEmailInput(): void {
    Swal.fire({
      title: 'Enter your email',
      input: 'email',
      inputLabel: 'Your email address',
      inputPlaceholder: 'Enter your email address',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        } else if (!this.validateEmail(value)) {
          return 'Invalid email address!';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const email = result.value;
        // Handle the email, e.g., send it to the server or save it locally
        console.log('Email entered:', email);
        // You can also show a success message or proceed with further logic
        Swal.fire('Thank you!', 'Your email has been recorded.', 'success');
      }
    });
  }

  validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }


}



<div class="modal-popup" mat-dialog-content>
    <h2 style="color: #0D87C8">{{ actionType === 'view' ? 'View Specififcation' : (actionType === 'edit' ? 'Edit Specififcation' : 'Add Specififcation') }}</h2>
  
    <form [formGroup]="specifications" (ngSubmit)="onFormSubmit()">
      <div class="specifications-container">
        <div class="field">
          <p class="label">Select Type</p>
          <mat-form-field appearance="outline" class="fields-input">
            <mat-select formControlName="type" placeholder="Select" (selectionChange)="onSelectionChange($event)"  > 
              <mat-option *ngFor="let type of types" [value]="type">{{ type }}</mat-option>
            </mat-select>
            <mat-error class="error-message error-msg" *ngIf="specifications.get('type')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Type is Required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
            <p class="label">Name</p>
            <mat-form-field appearance="outline" class="fields-input">  
              <input matInput formControlName="name" required> 
              <mat-error class="error-message error-msg" *ngIf="specifications.get('name')?.hasError('required')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
              </mat-error>
            </mat-form-field>
          </div>

        <div class="field" *ngIf="selectedValue === 'Number'">
          <p class="label">Unit Of Measure</p>
          <mat-form-field appearance="outline" class="fields-input"> 
            <mat-select formControlName="unitOfMeasure" placeholder="Select" (selectionChange)="onPrimaryUnitSelect($event)">
              <mat-option *ngFor="let unit of units" [value]="unit.code">{{ unit.code }}</mat-option>
            </mat-select>
            <mat-error class="error-message error-msg" *ngIf="specifications.get('unitOfMeasure')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Unit Of Measure is Required
            </mat-error>
          </mat-form-field>
        </div>


        <div formArrayName="alternativeMeasures" *ngIf="selectedValue === 'Number'">
          <div *ngFor="let measure of alternativeMeasures.controls; let i = index" [formGroupName]="i" class="measure-row">
            <mat-form-field appearance="outline">
              <input matInput type="number" formControlName="fromValue" placeholder="From Value">
            </mat-form-field>

            <div class="selected-unit">
              <span *ngIf="actionType==='view'" style="opacity: .5;" >{{selectedPrimaryUnit}}</span>
              <span *ngIf="actionType!=='view'">{{selectedPrimaryUnit}}</span>
            </div>
            <mat-form-field appearance="outline">
              <input matInput type="number" formControlName="toValue" placeholder="To Value">
            </mat-form-field>
.
            <mat-form-field appearance="outline">
              <mat-select formControlName="toUnit" placeholder="Select" required (selectionChange)="onToUnitSelect($event)">
                <mat-option *ngFor="let unit of filterUnits(measure.get('fromUnit')?.value)" [value]="unit.code">{{ unit.code }}</mat-option>
              </mat-select>
            </mat-form-field>
           
            <div class="measure-delete" *ngIf="actionType !== 'view'">
              <button class="delete" (click)="removeAlternativeMeasure(i)"><img alt="" src="./assets/images/small-delete.svg"></button>
            </div>
          </div>
          
        </div>
      
        <button *ngIf="selectedValue === 'Number' && actionType !== 'view'" class="measure-btn" type="button" (click)="addAlternativeMeasure()" >+ Add Alternative Unit of Measure</button>

        <div class="field">
          <p class="label">Status</p>
          <mat-radio-group appearance="outline" formControlName="active">
            <mat-radio-button value="true"  ><span style="color: #858585;">Active</span></mat-radio-button>
            <mat-radio-button value="false" ><span style="color: #858585;">Inactive</span></mat-radio-button>
        </mat-radio-group>
        </div>
      </div>
      <div class="popup-buttons">
        <button mat-raised-button class="save-btn" type="submit" *ngIf="actionType !== 'view'">{{data ? 'Update': 'Save'}}</button>
        <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">{{actionType === 'view' ? 'Close' : 'Cancel'}}</button>
      </div>

    </form>
</div>


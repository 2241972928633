<app-oem-filters [formColumns]="formColumns"></app-oem-filters>
<div class="cards">
    <div class="cards-container">
        <mat-card class="example-card" *ngFor="let request of oemData" appearance="outlined">
            <div class="d-flex justify-content-center position-relative">
                <div class="labimage">
                    <img [src]="oemIds[this.cardIds[request.id]].logo" alt="" width="310px" height="80px" >
                </div>
                <div class="shieldimage">
                    <img src="/assets/images/shield icon.svg" alt="" class="shield">
                </div>
                <div class="floating-icons d-flex g-2">
                    <div class="moreoptions">
                        <img src="/assets/images/more info icon.svg" class="more-options" alt="">
                    </div>
                    <div class="profileimage">
                        <img [src]="oemIds[this.cardIds[request.id]].profilePic" alt="">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end heading-container">
                <h3 style="font-size: 14px;margin-top: 2px !important">{{ oemIds[this.cardIds[request.id]].distributorName }}</h3>
            </div>
            <mat-card-content style="padding: 0px 5px 10px 0px">
                <div class="company-info">
                    <div class="div card-content mt-2" style="    padding: 5px 5px 10px 5px;;">
                        <div class="row " style="width: 344px; ">
                            <span style="width: 180px; padding-left: 15px;" class="heading">{{oemIds[this.cardIds[request.id]].companyName}}</span>
                        </div>
                        <div class="row d-flex">
                            <span style="font-size: 11px;">More than {{ oemIds[this.cardIds[request.id]].noOfEmployees }} Employees</span>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6 d-flex justify-content-start">
                                <div class="company-name">
                                    <span class="heading" style="font-size: 11px;">Established in {{oemIds[this.cardIds[request.id]].year}}</span>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex justify-content-end ">
                                <div class="company-name p-1" style="background-color:#0d87c8;border-radius: 1rem;">
                                    <span style="font-size: 14px;font-weight: 600; color: #fff; display: flex;">
                                        <img src="./../../../assets/images/phone rings.svg" alt="" class="phone-icon">
                                       {{oemIds[this.cardIds[request.id]].phoneNumber}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Address</span>
                            <p>{{ oemIds[this.cardIds[request.id]].address }} {{ oemIds[this.cardIds[request.id]].city }}</p>
                        </div>
                    </div>

                    <hr style="border-top: 1px solid #35673d ;margin:0px;">

                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Description</span>
                            <p>{{oemIds[this.cardIds[request.id]].description}}</p>
                        </div>
                    </div>
                    <hr style="border-top: 1px solid #35673d ;margin:0px;">
                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Distributing Brands</span>
                            <mat-chip-set aria-label="brands" *ngFor="let brand of oemIds[this.cardIds[request.id]].distributionBrands">
                                <mat-chip> {{ brand }} </mat-chip>
                            </mat-chip-set>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <div class="buttons">
                <mat-card-actions>
                    <button *ngIf="isAll" class="button button-primary" [routerLink]="['/brands/brand-profile', request.distributorId ]" [queryParams]="{ type: 'distributor' }" >View Profile</button>
                    <button *ngIf="isAll"class="button button-inverse mx-2" (click)="sendMessage(request)" >Send Message</button>
                    <button *ngIf="isRequested" class="button button-primary">Accept</button>
                    <button *ngIf="isRequested" class="button button-inverse mx-2">Reject</button>
                    <button *ngIf="isRequestedBy" class="button button-primary" (click)="cancelRequest(request.id)">Cancel Request</button>
                    <button *ngIf="isRequestedBy" class="button button-inverse mx-2" (click)="sendMessage(request)" >Send Message</button>
                </mat-card-actions>
            </div>
        </mat-card>
    </div>
</div>
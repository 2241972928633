<div class="cross d-flex">
    <img src="../../../assets/images/register-cross-icon.svg" alt="Close" type="button" (click)="onClose()">
</div>
<div class="container view-webinar d-flex flex-column p-5">
    <form [formGroup]="registerForm" (ngSubmit)="submit()">
        <h1>Register to Webinar</h1>
        
        <p class="heading">Name</p>
        <mat-form-field appearance="outline" class="input-font w-100">
            <input matInput formControlName="name" placeholder="Name" required alphabetOnly>
            <mat-error *ngIf="registerForm.get('name')?.hasError('required') && clicked">
                Name is Required
            </mat-error>
        </mat-form-field>
        
        <p class="heading">Email Address</p>
        <mat-form-field appearance="outline" class="input-font w-100">
            <input matInput formControlName="email" placeholder="Enter Email Address" required email>
            <mat-error *ngIf="registerForm.get('email')?.hasError('required') && clicked">
                Email is Required
            </mat-error>
            <mat-error *ngIf="registerForm.get('email')?.hasError('email') && clicked">
                Enter a valid Email Address
            </mat-error>
        </mat-form-field>
        
        <p class="heading">Phone</p>
        <mat-form-field appearance="outline" class="input-font w-100">
            <input matInput formControlName="phone" placeholder="Phone" required onlyNumbers maxlength="15">
            <mat-error *ngIf="registerForm.get('phone')?.hasError('required') && clicked">
                Phone is Required
            </mat-error>
        </mat-form-field>
        
        <button type="submit" class="btn btn-primary d-flex flex-row align-items-center justify-content-center py-2 gap-3">
            <span class="button-txt d-flex flex-row">Register</span>
        </button>
    </form>
</div>

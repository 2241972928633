import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-awards-profile',
  templateUrl: './awards-profile.component.html',
  styleUrls: ['./awards-profile.component.scss']
})
export class AwardsProfileComponent implements OnInit{

  awardsColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true ,isText: true},
    {name:'Association',property:'association',visible:true,isModelProperty:true,isText:true},
    {name:'Award Date',property:'awardDate',visible:true,isModelProperty:true,isDatePipe:true},
  ] as ListColumn[];

  @Input() awardsHonorsForm!:FormGroup;
  data:any[]=[];

  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyAwardsForm:any;
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  constructor(private fb:FormBuilder){}

  ngOnInit(): void {
   
    this.data=JSON.parse(JSON.stringify(this.awards(0).value));
   
   }
 
   get moreInfos(){
     return this.awardsHonorsForm.controls['moreInfos'] as FormArray;
    }

    awards(i:number){
      return (this.moreInfos.at(i) as FormGroup).controls['awards'] as FormArray;
    }
  
    getMoreInfoFormGroup(i:number){
      return (this.moreInfos.at(i) as FormGroup);
    }

    addAward(){
      this.copyAwardsForm=JSON.parse(JSON.stringify(this.awardsHonorsForm.value));
      this.awards(0).push(
        
        this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
          "title"  :new FormControl(null),
           "association": new FormControl(null),
           "awardDate": new FormControl(null),
           // "awardYear": new FormControl(null),
           "summary": new FormControl(null),
         }),
      )
      this.showEditForm=true;
    }

    
  editAward(){
    this.copyAwardsForm=JSON.parse(JSON.stringify(this.awardsHonorsForm.value));
    this.showEditForm=true;
  }
  update(){
 
    this.onUpdate.emit(110);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify(this.awards(0).value));
  }

  cancel(){
    
    this.awards(0).clear();
     if(this.copyAwardsForm?.moreInfos && this.copyAwardsForm.moreInfos.length>0&&
      this.copyAwardsForm.moreInfos[0]?.awards &&this.copyAwardsForm.moreInfos[0].awards.length>0
     ){
          for(let i=0;i<this.copyAwardsForm.moreInfos[0].awards.length;i++){
            this.awards(0).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "title"  :new FormControl(null),
              "association": new FormControl(null),
              "awardDate": new FormControl(null),
              "summary": new FormControl(null),
            }));
          }
          this.awards(0).patchValue(this.copyAwardsForm.moreInfos[0].awards);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.awards(0).value));
  
  
  }
  
    deleteAward(i:number,j:number){
      this.awards(i).removeAt(j);
    }

}

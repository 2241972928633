import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, catchError, map, of } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { Catalog } from 'src/app/models/oem-models';
//import { ProductRangeData } from 'src/app/models/product-settings-model';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { CatalogService } from 'src/app/services/shared/catalog.service';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
//import { ProductRangeService } from 'src/app/services/shared/product-settings/product-range.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-add-catalog',
  templateUrl: './add-catalog.component.html',
  styleUrls: ['./add-catalog.component.scss']
})
export class AddCatalogComponent implements OnInit {

  @Input() oemId!: string;
  isEditMode = false;
  isImage:boolean = true;
  catalogId!: string;
  addCatalogForm!: FormGroup;
  fileName = '';
  currentFile?: File; 
  selectedFileName: string | null = null;
  lastNodeList$!: Observable<any[]>; 
  isViewmode: boolean = true;
  catalogImage: string ='/src/assets/images/pdf-icon-image.svg';
  imageUrl: string='';
  allProductList$!: Observable<any[]>; 
  

  constructor(
    private fileUploadService: FileUploadService,
    private categoryService: CategoryService,
    private dialogRef: MatDialogRef<AddCatalogComponent>,
    private catalogService: CatalogService,
    private sweetAlertService: SweetAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private allProductService : AllProductService
  ) {
    this.oemId = this.data.oemId;
    this.isEditMode = !!this.data.catalog;
    this.isViewmode = this.data?.isViewmode;
    if (this.isEditMode) {
      this.catalogId = this.data.catalog.id;
      this.fileName = this.data.fileName || '';
    }
    
  }

  ngOnInit(): void {
    
    this.initializeForm();
    this.getLastNodeCategories();    
    if (this.isEditMode) {
      // this.viewMode = true;
      this.populateForm(this.data.catalog);
    }
    if(this.data?.actionType===MweConstants.viewAction){
      this.addCatalogForm.disable();
    }
    this.getAllProductsInfo();
    // if(this.isViewmode){
    //   this.addCatalogForm.disable();
    // }
  }
  
  private populateForm(catalog: any): void {
    this.catalogImage = catalog.catalog;
    this.addCatalogForm.patchValue({
      title: catalog.title,
      catalog: catalog.catalog,
      productInfoId: catalog.productInfoId,
      attachProduct: catalog.attachProduct,
      isAssociated: catalog.productInfoId?true:false
    });
    this.fileName = catalog.catalog.split('/').pop() || ''; 
    this.selectedFileName = this.fileName; 
    this.imageUrl = catalog.catalog;
    this.isImage=this.isImageFile(this.imageUrl); 
    
    
  }
  private initializeForm(): void {
    this.addCatalogForm = new FormGroup({
      title: new FormControl('', Validators.required),
      catalog: new FormControl('', Validators.required),
      productInfoId: new FormControl('', Validators.required),
      attachProduct: new FormControl(false, Validators.required),
      isAssociated: new FormControl(false, Validators.required)
    });
  }

  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/catalogs/');

    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; 
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }
  isImageFile(filePath: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.img','.png'];
   
    return imageExtensions.some(ext => filePath.toLowerCase().endsWith(ext));
  }

  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || ''; // Extracting file name from URL
  }

  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.getFileNameFromUrl(file.name); // Extracting file name from URL
      this.selectedFileName = this.fileName;
  
      this.uploadFile(file).subscribe((response) => {
        this.addCatalogForm.get('catalog')?.setValue(response);
        this.imageUrl = response;
       this.isImage=this.isImageFile(this.imageUrl);
         // Ensure the image check is done after the URL is set
      });
    }
  }
  

  clearSelectedFile(): void {
    this.currentFile = undefined;
    this.selectedFileName = null;
    this.fileName = '';
    this.imageUrl = '';
    this.addCatalogForm.patchValue({ catalog: '' });
  }

  onSubmit(): void {
    
    if (this.currentFile) {
      this.uploadFile(this.currentFile).subscribe(
        (response) => {
          this.addCatalogForm.get('catalog')?.setValue(response);
          this.submitCatalog();
        },
       
      );
    } else {
      this.submitCatalog();
    }

  }

  private submitCatalog() {
    const catalog: Catalog = {
      id: this.isEditMode ? this.catalogId : '', 
   
      title: this.addCatalogForm.get('title')?.value,
      catalog: this.addCatalogForm.get('catalog')?.value ,
      productInfoId: this.addCatalogForm.get('productInfoId')?.value,
      oemId: this.oemId,
      distributorId: '' // Hardcoded for now
    };
    if (this.isEditMode) {
      console.log("inside edit mode", catalog);
      this.catalogService.updateCatalog(this.catalogId, catalog).subscribe(
        (response: any) => {
          this.handleSuccessResponse(response);
        },
        (error) => {
          this.dialogRef.close(true);
          this.sweetAlertService.showError('Error', 'There was an error updating the catalog.');
        }
      );
    } else {
      
      this.catalogService.createCatalog(catalog).subscribe(
        (response: any) => {
          this.handleSuccessResponse(response);
        },
        (error) => {
          this.dialogRef.close(true);
          this.sweetAlertService.showError('Error', 'There was an error creating the catalog.');
        }
      );
    }
  }

  private handleSuccessResponse(response: any) {
    if (response.success || response.status === 'success' || response.data) {
      this.dialogRef.close(true);
      this.sweetAlertService.showSuccess('', 'Catalog ' + (this.isEditMode ? 'Updated' : 'Created') + ' Successfully!');
    } else {
     
      this.sweetAlertService.showError('Error', 'Catalog ' + (this.isEditMode ? 'update' : 'creation') + ' encountered an issue.');
    }
  }
  getLastNodeCategories(): void {
    this.getAllProductsInfo()
    this.lastNodeList$ = this.categoryService.getLastNodeCategory().pipe(
      map(response => response.data),
      catchError((error) => {
       
        return of([]); // Handle error and return an observable of an empty array or appropriate default value
      })
    );
  }


  getAllProductsInfo(): void {
    
    
    if (this.oemId) {
      this.allProductService.getAllProductByOemId(0, 100, this.oemId).pipe(
        map(response => response.data)
      ).subscribe(
        (response) => {
          const allProductData = response;
          this.allProductList$ = of(allProductData);
    
          
        },
       
      );
    }
  }

  private filterProducts(value: string): Observable<any[]> {
    const filterValue = value.toLowerCase();
    return this.allProductList$.pipe(
      map(products => products.filter(option => option.name.toLowerCase().includes(filterValue)))
    );
  }

  onOptionSelected(event: any): void {
    const selectedProduct = event.option.value;
    this.addCatalogForm.get('productInfoId')?.setValue(selectedProduct.id);
  }
  

}

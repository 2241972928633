<h2 style="color: #0d87c8" mat-dialog-title>
  {{ data ? "Update Product" : "Add Product" }}
</h2>

<div class="tab-group">
    <div class="tab" [ngClass]="{ 'tab-active': selectedTab === 1 }" (click)="changeTab(1)">
        <p>Product Category</p>
    </div>
    <div class="tab" [ngClass]="{ 'tab-active': selectedTab === 2 }" (click)="changeTab(2)">
        <p>Product Details</p>
    </div>
    <div class="tab" [ngClass]="{ 'tab-active': selectedTab === 3 }" (click)="changeTab(3)">
        <p>Upload Images/Videos/Catalogs</p>
    </div>
    <div class="tab" [ngClass]="{ 'tab-active': selectedTab === 4 }" (click)="changeTab(4)">
        <p>Distributors</p>
    </div>
</div>

<div class="modal-action" mat-dialog-content>
    <form [formGroup]="allProducts" (ngSubmit)="onFormSubmit()">
        <div *ngIf="selectedTab === 1">
            <!------------------ Product Category-------------------->
            <div class="product-category">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                    <!-- Leaf node template (no children) -->
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                        <ng-container *ngIf="node.disabled">
                            <button mat-icon-button disabled type="button"  matTreeNodeToggle></button>
                        </ng-container>

                        <ng-container *ngIf="!hasChild(0, node)">
                            <mat-checkbox class="checklist-leaf-node" [disabled]="isAnyNodeSelected && !node.selected" [checked]="node.selected" (change)="todoLeafItemSelectionToggle(node)">
                                {{ node.item }}
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="hasChild(0, node)">
                            {{ node.item }}
                        </ng-container>
                    </mat-tree-node>

                    <!-- Parent node template (has children) -->
                    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                        <button mat-icon-button type="button"  matTreeNodeToggle [attr.aria-label]="'toggle ' + node.item">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                            </mat-icon>
                        </button>
                        <ng-container *ngIf="!hasChild(0, node)">
                            <mat-checkbox [disabled]="isAnyNodeSelected && !node.selected" [checked]="node.selected" (change)="todoItemSelectionToggle(node)">
                                {{ node.item }}
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="hasChild(0, node)">
                            {{ node.item }}
                        </ng-container>
                    </mat-tree-node>
                </mat-tree>
            </div>


            <div class="popup-buttons">
                <button mat-raised-button class="save-btn" type="button" (click)="next(selectedTab)">
                    Next
                </button>
                <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="closeDialog()">
                    Cancel
                </button>
            </div>
        </div>

        <div *ngIf="selectedTab === 2">
            <!------------------ Product Details-------------------->

            <app-product-details [productDetails]="allProducts"></app-product-details>
            <div class="popup-buttons-product">
                <button mat-raised-button class="save-btn" type="button" (click)="next(selectedTab)">
                    Next
                </button>
                <button mat-raised-button class="back-btn" type="button" (click)="back(selectedTab)">
                    Back
                </button>
                <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="closeDialog()">
                    Cancel
                </button>
            </div>
        </div>

        <div *ngIf="selectedTab === 3">
            <!------------------ Upload Images/Videos/Catalogs-------------------->
            <div class="image-video-container">
                <div class="upload-container">
                    <div>
                        <mat-label class="label">Product Images*</mat-label>
                    </div>
                    <mat-error class="error-message error-msg" *ngIf="showImageError">
                        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Product Images is Required
                    </mat-error>
                    <mat-error class="error-message image-error" *ngIf="invalidFileType">
                        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Invalid file type {{fileType}}, Only image files are allowed.
                    </mat-error>
                    <mat-card class="upload-card">
                        <div class="upload-area" (click)="fileInput.click()">
                            <label class="d-flex justify-content-center text" for="upload" mat-raised-button>
                                <img alt="" src="./../../assets/images/photo-add.svg" />Drag files here to upload (or) Select Files
                            </label>
                        </div>
                        <input #fileInput type="file" multiple accept="image/*" (change)="uploadPhoto($event, fileInput)" style="display: none" />
                        <div class="file-list">
                            <mat-card *ngFor="let file of imageFiles; let i = index" class="file-card">
                                <div class="file-item">
                                    <div class="file-details">
                                        <img [src]="file.url" class="file-thumbnail" alt="{{ file.name }}" />
                                        <div class="file-info">
                                            <span>{{ file.name.length > 30 ? file.name.slice(0, 30) + '...' : file.name }} ({{ file.size }} KB)</span>
                                        </div>
                                    </div>
                                    <!-- <mat-progress-bar class="file-progress" mode="determinate" [value]="file.progress"></mat-progress-bar> -->
                                    <button mat-icon-button color="warn" (click)="deleteImage(i)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </mat-card>
                        </div>
                    </mat-card>
                </div>

                <div class="upload-container">
                    <div>
                      <mat-label class="label">Product Video (Source URL)*</mat-label>
                    </div>
                  
                    <mat-form-field appearance="outline" class="fields w-100">
                      <input matInput formControlName="video">
                      <mat-error *ngIf="video?.invalid && (video?.touched || video?.dirty)">
                        <ng-container *ngIf="video?.errors?.['required']">URL is required.</ng-container>
                        <ng-container *ngIf="video?.errors?.['invalidUrl']" style="color: red;">
                          Invalid URL. Must start with 'https://'.
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  

                 <div class="upload-container">
                    <div>
                        <mat-label class="label">Product Catalogs</mat-label>
                    </div>
                    <mat-error class="error-message image-error" *ngIf="invalidCatalogsType">
                        <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Invalid file type {{catalogFileType}}, Only image, pdf and doc files are allowed.
                    </mat-error>
                    <mat-card class="upload-card">
                        <div class="upload-area" (click)="fileCatalog.click()">
                            <label class="d-flex justify-content-center text" for="catelog" mat-raised-button>
                                <img alt="" src="./../../assets/images/photo-add.svg" />Drag files here to upload (or) Select Files
                            </label>
                        </div>
                        <input #fileCatalog type="file" accept="image/*,.pdf,.doc,.docx" multiple (change)="uploadCatelog($event, fileCatalog)" style="display: none" />

                        <div class="file-list">
                            <mat-card *ngFor="let file of catalogFiles; let i = index" class="file-card">
                                <div class="file-item">
                                    <div class="file-details">
                                        <img [src]="file.url" class="file-thumbnail" alt="{{ file.name }}" />
                                        <div class="file-info">
                                            <span *ngIf="file.name">{{ file.name.length > 50 ? file.name.slice(0, 50) + '...' : file.name }} ({{ file.size }} KB)</span>
                                        </div>
                                    </div>
                                <!-- <mat-progress-bar class="file-progress" mode="determinate" [value]="file.progress"></mat-progress-bar>  -->
                                    <button mat-icon-button color="warn" (click)="deleteCatelog(i)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </mat-card>
                        </div>
                    </mat-card>
                </div> 
            </div>

            <div class="popup-buttons-images">
                <button mat-raised-button class="save-btn" type="button" (click)="next(selectedTab)">
                    Next
                </button>
                <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="closeDialog()">
                    Cancel
                </button>
            </div>
        </div>
        <div *ngIf="selectedTab === 4">
            <!------------------ Distributors-------------------->
            <div class="">
                <div class="row mt-2">
                    <div class="col-lg-12">
                        <div>
                            <mat-label class="label">Distributors</mat-label>
                        </div>
                        <mat-radio-group formControlName="selectedDistributorCtrl" (change)="onDistributorSelect($event.value)">
                            <mat-radio-button value="assignDistributor">Assign Distributors</mat-radio-button>
                            <mat-radio-button value="lookingForDistributor">Looking For Distributors</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div *ngIf="selectedDistributors === 'assignDistributor'">
                    <div class="distributor-container">
                        <table mat-table [dataSource]="distributerData" class="full-width-table" matSort aria-label="Elements">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    Select
                                    <!-- <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox> -->
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- Continent Column -->
                            <ng-container matColumnDef="continent">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 0px">
                                    Continent
                                </th>
                                <td mat-cell *matCellDef="let element" style="padding-right: 0px">
                                    {{ element.distributorContinent }}
                                </td>
                            </ng-container>

                            <!--  Country Column -->
                            <ng-container matColumnDef="country">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Country
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.distributorCountry }}
                                </td>
                            </ng-container>

                            <!-- State Column -->
                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.distributorState }}
                                </td>
                            </ng-container>

                            <!-- City Column -->
                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.distributorCity }}
                                </td>
                            </ng-container>

                            <!-- Distributor Name Column -->
                            <ng-container matColumnDef="distributorName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Distributor Name
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.distributorName }}
                                </td>
                            </ng-container>

                            <!-- Define columns -->
                            <tr mat-header-row *matHeaderRowDef="distributersDisplyColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: distributersDisplyColumns"></tr>
                        </table>
                        <h2 *ngIf="showNoDistributorFounds" class="no-records">No distributor found </h2> 
                    </div>

                    <div class="popup-buttons-distributors">
                        <button mat-raised-button class="save-btn" type="submit">
                            Save
                        </button>
                        <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="closeDialog()">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="selectedDistributors === 'lookingForDistributor'">
                <div class="col-lg-3 dist-continent">
                    <div>
                        <mat-label class="label">Continents</mat-label>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-select formControlName="continent" placeholder="Select" (selectionChange)="onContinentChange($event)" multiple>
                                <mat-option *ngFor="let continent of continents" [value]="continent.continentName">
                                    {{ continent?.continentName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div>
                        <mat-label class="label">Country</mat-label>
                    </div>
                    <div>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Country" [formControl]="countryControl" [matAutocomplete]="countryAuto" />
                            <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="toggleSelection($event)">
                            <!-- <mat-select formControlName="country" placeholder="Select"> -->
                                <mat-option #option type *ngFor="let country of filteredCountries" [value]="country">
                                    {{ country?.countryName }}
                                </mat-option>
                            <!-- </mat-select> -->
                        </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>

                <div class="selectedCountries" *ngIf="allSelectedCountries.length > 0" [class.activeAllCountires]="showAllCountries" style="height: fit-content;">
                    <span style="font-weight: bold; margin-top:10px;height: fit-content;">Looking for distributors in this region:</span> 
                    <div *ngFor="let country of (showAllCountries ? allSelectedCountries : allSelectedCountries.slice(0, 3))">
                        <span class="selectedCountryData" [class.activeSelected]="showAllCountries">{{ country }}</span>
                    </div><div *ngIf="allSelectedCountries.length > 3 && !showAllCountries " style="margin-left: 10px;margin-top: 5px; cursor:pointer; " (click)="moreSelectedCountries()"><b>...</b></div>
                </div>
                <div class="popup-buttons" [class.active-popup-button]="showAllCountries">
                    <button mat-raised-button class="save-btn" type="submit" >
                        Save
                    </button>
                    <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button" (click)="closeDialog()">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
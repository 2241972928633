import { Injectable } from '@angular/core';
import { UserDetails } from '../models/authData';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private userDetailsSubject!: BehaviorSubject<UserDetails>;


  constructor() {
    const userDetailsString = localStorage.getItem('userDetails');
    const userDetails = userDetailsString ? JSON.parse(userDetailsString) : null;
    this.userDetailsSubject = new BehaviorSubject<UserDetails>(userDetails);
   }

  /**
   * Sets the value for the specified key in localStorage.
   * @param key The key to set.
   * @param value The value to store.
   */
  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves the value associated with the specified key from localStorage.
   * @param key The key to retrieve.
   * @returns The value associated with the key, or null if the key does not exist.
   */
  getItem(key: string): any {
    const item = localStorage.getItem(key);
    return item ?? null;
  }

  /**
   * Removes the item with the specified key from localStorage.
   * @param key The key of the item to remove.
   */
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  getUserDetails(): Observable<UserDetails> {
    return this.userDetailsSubject.asObservable();
  }

  updateUserDetails(updatedDetails: UserDetails): void {
    localStorage.setItem('userDetails', JSON.stringify(updatedDetails));
    this.userDetailsSubject.next(updatedDetails);
  }


  /**
   * Clears all items from localStorage.
   */
  clear(): void {
    localStorage.clear();
  }
}

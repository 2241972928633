
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../local-storage.service';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { OEMUrls } from 'src/app/utils/apiUrls';


@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  MASTERDATA_BASE_URL = environment.MASTERDATA_BASE_URL;
  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  

  userId:any;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,) {
    this.localStorageService.getUserDetails()?.subscribe(data=>{
      this.userId=data?.userId;
    })
   }

 
  public getAllCategories(): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(OEMUrls.categories);
  }

  public getAllParentCategories(): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(OEMUrls.categories + '/parent-categories');
  }

  public getCategoriesByPage(page: number, size: number, startWith?: string): Observable<ApiResponseModel<any[]>> {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = new HttpParams();
    if (startWith) {
      params = params.set('startWith', startWith);
    }
    return this.http.get<ApiResponseModel<any[]>>(`${OEMUrls.categories}/list?page=${page}&size=${size}`, {headers, params});
  }

  public createCategory(name: string, image : string, parentCategoryId?: string ): Observable<any>{
    let params = new HttpParams().set('name', name).append('image',image);
    if (parentCategoryId) {
      params = params.set('parentCategoryId', parentCategoryId);
    }
    return this.http.post(OEMUrls.categories, {}, { params: params });
  }

  public updateCategory(name: string, image : string, categoryId?: string ): Observable<any>{
    let params = new HttpParams().set('name', name).append('image',image);
    params.set('image',image);
    if (categoryId) {
      params = params.set('id', categoryId);
    }
  
    return this.http.put(OEMUrls.categories, {}, { params: params });
  }

  deleteCategory(categoryId: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.delete(`${OEMUrls.categories}/${categoryId}`, { headers: headers });
  }

  public uploadFile(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
    return this.http.post(this.AUTH_BASE_URL + url, body, { headers: headers });
  }
 
  public getLastNodeCategory(): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(OEMUrls.categories + '/last-nodes');
  }
  public getCategoriesbyId(id: String): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(OEMUrls.categories + '/' + id);
  }


  public getBreadcrumb(categoryId : string): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(OEMUrls.categories + '/' + categoryId +'/breadcrumb');
  }
}



    <mat-card-content>
      <div *ngIf="data">
        <div class="brand-header"  *ngIf="data.type === 'brand'">
          <ng-container *ngIf="data?.logo">
            <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
          </ng-container>
          <div class="brand-details">
            <h1>{{ data.companyName | camelcase}}</h1>
            <ng-container *ngIf="data?.url">
              <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
            </ng-container>
          </div>
        </div>

        <div class="brand-header" *ngIf="data.type === 'distributor'">
          <ng-container *ngIf="data?.logo">
            <img [src]="data.logo" class="brand-logo" alt="{{data.companyName}}">
          </ng-container>
          <div class="brand-details">
            <h1>{{ data.companyName | camelcase}}</h1>
            <ng-container *ngIf="data?.url">
              <a target="_blank" href="{{ data.url }}">{{ data.url }}</a>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="data">
        <div [innerHTML]="data.description"></div>
      </ng-container>
    
    
      <!-- <div class="grid-container">
        <div class="grid-item item1">
          <iframe [src]="videoUrl" title="YouTube video player" frameborder="0" allowfullscreen></iframe>
        </div>
        <div class="grid-item" *ngFor="let image of visibleImages; let i = index">
          <img [src]="image" alt="Image">
        </div>
        <div class="grid-item show-more" *ngIf="!showMore && images.length > 6" (click)="toggleShowMore()">
          <div class="more-image-container">
            <img [src]="images[5]" alt="Image" class="more-image">
            <div class="overlay">+{{ hiddenImagesCount }}</div>
          </div>
        </div>
        <ng-container *ngIf="showMore">
          <div class="grid-item" *ngFor="let image of images.slice(6)">
            <img [src]="image" alt="Image">
          </div>
        </ng-container>
      </div> -->
      

      
    </mat-card-content>

  

import { Component } from '@angular/core';
import { DasboardService } from 'src/app/services/super-admin/dasboard.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent {

  content: string = '';

  constructor(private dashboardService: DasboardService) { }

  ngOnInit(): void {
    this.fetchPrivacyPolicies();
  }

  fetchPrivacyPolicies(): void {
    this.dashboardService.getContent('privacy').subscribe( (response) => {
        this.content = response.data.content;
      },
    );
  }
}

<div class="filter-popup">
  <div class="filter-heading">
    <h2 style="color: #0d87c8">Filter OEMs</h2>
  </div>
  <form [formGroup]="filterForm">
    <div class="filter-row">
      <div class="filter-column">
        <div class="field">
          <p class="label">Added On</p>
          <mat-form-field class="filter-input" appearance="outline">
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="Start"
                formControlName="startDate"
              />
              <input matEndDate placeholder="End" formControlName="endDate" />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="field">
          <p class="label">Country</p>
          <mat-form-field appearance="outline" class="filter-input">
            <input
              matInput
              [matAutocomplete]="countryAuto"
              placeholder="Search for country"
              [formControl]="countryControl"
            />
            <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onCountryChange($event)">
              <mat-option *ngFor="let country of filteredCountries" [value]="country">
                {{ country.countryName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        

        <div class="field">
          <p class="label">State</p>
          <mat-form-field appearance="outline" class="filter-input">

            <input  matInput
            [matAutocomplete]="stateAuto"
            placeholder="Search for state"
            [formControl]="stateControl" />

            <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateChange($event.option.value)">
              <mat-option *ngFor="let state of filteredStates" [value]="state">
                {{ state.stateName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="filter-row">
      <div class="filter-column">
        <div class="field">
          <p class="label">City</p>
          <mat-form-field appearance="outline" class="filter-input">

            <input  matInput
            [matAutocomplete]="cityAuto"
            placeholder="Search for city"
            [formControl]="cityControl" />

            <mat-autocomplete #cityAuto="matAutocomplete" (optionSelected)="onCityChange($event.option.value)">
              <mat-option *ngFor="let city of filteredCities" [value]="city">
                {{ city.cityName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="field" *ngIf="data.type !== 'distributor'">
          <p class="label">Plan Type</p>
          <mat-form-field appearance="outline" class="filter-input">
            <mat-select formControlName="planType">
              <mat-option
                *ngFor="let planType of subscriptionPlans"
                [value]="planType.name"
                >{{ planType.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="field">
          <p class="label">Status</p>
          <mat-form-field appearance="outline" class="filter-input">
            <mat-select formControlName="status">
              <mat-option *ngFor="let status of statusTypes" [value]="status">{{
                status
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="chips?.length" class="row-container-2">
      <span class="paragraph-medium">Filters applied: </span>
      <mat-chip
        class="chip-item"
        *ngFor="let chip of chips"
        (removed)="remove(chip)"
      >
        {{ chip?.name }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </div>
    <div class="footer">
      <button mat-raised-button class="filter-btn" (click)="submitFilter()">
        Filter
      </button>
      <button mat-raised-button class="clear-btn" (click)="clearFilter()">
        Clear
      </button>
      <button mat-raised-button class="cancel-btn" (click)="cancel()">
        Cancel
      </button>
    </div>
  </form>
</div>

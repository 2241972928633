import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss']
})
export class ProfileAboutComponent implements OnInit{

  @Input() showSpecialisationTypes:boolean=false;
  /* @Input() showSocialMediaHeading:boolean=false; */
  @Input() aboutYouForm!:FormGroup;

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  copyAboutForm:any;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '150px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],

};
specialisation=[];
  specialisations:any=[];
  showEditForm:boolean=false;

  specialityData:any=[];
  constructor(private endCustomerService:EndCustomerService) {}

  ngOnInit(): void {
    if(this.showSpecialisationTypes){
      this.getSpecialities();
    }
   
  }

  cancel(){
    this.showEditForm=false;
    if(this.copyAboutForm?.aboutId ){
      if(this.copyAboutForm.aboutId && this.copyAboutForm.aboutId?.about){
        this.aboutId.get('about')?.patchValue(this.copyAboutForm.aboutId.about); 
      }else{
        this.aboutId.get('about')?.reset();
      }
      if(this.copyAboutForm.aboutId && this.copyAboutForm.aboutId?.facebookUrl){
        this.aboutId.get('facebookUrl')?.patchValue(this.copyAboutForm.aboutId.facebookUrl); 
      }else{
        this.aboutId.get('facebookUrl')?.reset();
      }
      if(this.copyAboutForm.aboutId && this.copyAboutForm.aboutId?.linkedin){
        this.aboutId.get('linkedin')?.patchValue(this.copyAboutForm.aboutId.linkedin); 
      }else{
        this.aboutId.get('linkedin')?.reset();
      }
      if(this.copyAboutForm.aboutId && this.copyAboutForm.aboutId?.twitterUrl){
        this.aboutId.get('twitterUrl')?.patchValue(this.copyAboutForm.aboutId.twitterUrl); 
      }else{
        this.aboutId.get('twitterUrl')?.reset();
      }
      if(this.copyAboutForm.aboutId && this.copyAboutForm.aboutId?.instagramUrl){
        this.aboutId.get('instagramUrl')?.patchValue(this.copyAboutForm.aboutId.instagramUrl); 
      }else{
        this.aboutId.get('instagramUrl')?.reset();
      }
    }else{
      this.aboutId.reset();
    }
   
    this.aboutYouForm.get('specialityInfos')?.patchValue(this.copyAboutForm?.specialityInfos); 
    this.getSpecialityData(); 
  
  }

  update(){
    this.aboutId.markAllAsTouched();
    if(this.aboutId.invalid){
      return;
    }
    this.onUpdate.emit(10);
  }


  editAboutYou(){
    this.copyAboutForm=JSON.parse(JSON.stringify(this.aboutYouForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    if(this.showSpecialisationTypes){
      this.getSpecialityData();
    }
   
  }

  getSpecialities(){
    this.endCustomerService.executeGetRequestURL(MweConstants.specialtyUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.specialisations=data.data;
      /*   if(this.actionType==MweConstants.editAction && this.aboutYouForm.get('specialityInfos')?.value &&this.aboutYouForm.get('specialityInfos')?.value.length>0){
          const specialityInfos=this.specialisations.filter((obj1:any)=>{
            return this.aboutYouForm.get('specialityInfos')?.value.some((obj2:any)=>{
              return obj1.id===obj2.id;
            })
          })
          this.aboutYouForm.get('specialityInfos')?.patchValue(specialityInfos);
        } */
        if(data && data?.data && data.data.length>0){
          this.getSpecialityData();
        }
      }
    },error=>{

    })
  }

  getSpecialityData(){
    
    const speciality=this.specialisations;
    
    const specialityInfos=speciality.filter((obj1:any)=>{
      return this.endCustomerData.specialityInfos.some((obj2:any)=>{
        return obj1.id===obj2.specialityInfoId;
      })
    })
  
    this.specialityData=specialityInfos;
  }
 
  remove(i:number): void {
    this.aboutYouForm.get('specialityInfos')?.value.splice(i,1);
    this.aboutYouForm.get('specialityInfos')?.patchValue(this.aboutYouForm.get('specialityInfos')?.value);
}

getSpecility(id:any){
  return this.specialisations.find((obj:any)=>obj?.id===id)?.name;
 }
get aboutId(){
  return this.aboutYouForm.controls['aboutId'] as FormGroup;
}

openUrl(url:any){
  window.open(url,'_blank')
}
}

<div class="p-2" style="background-color: white; ">
  <div class="d-flex justify-content-between align-items-center" style="background-color: white; height: 64px;"
    *ngIf="showHeader">
    <div>
      <h2 class="mx-2 page-heading">{{ title }}</h2>
    </div>
    <div class="d-flex align-items-center">
      <mat-form-field appearance="outline" class="m-2 mr-1 search-bar" *ngIf="showDropdownFilter">
        <mat-select placeholder="{{filterDropdown?.placeholder}}" [(ngModel)]="filterDropdown.filterOption"
          (selectionChange)="updateAction(filterDropdown.filterOption, fillterDropdownAction)">
          <mat-option *ngFor="let option of filterDropdown.options" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="m-2 mr-1 search-bar " *ngIf="showSearch">
        <!-- <mat-icon matPrefix class="searchicon">search</mat-icon> -->
        <div class="d-flex searchbox ">
          <img alt="" src="assets/images/search.svg">
          <input matInput placeholder="Search" (keyup)="applyFilter($event)">
        </div>
      </mat-form-field>
      <mat-form-field appearance="outline" class="m-2 mr-1 search-bar " *ngIf="showMasterDataSearch">
        <!-- <mat-icon matPrefix class="searchicon">search</mat-icon> -->
        <div class="d-flex searchbox ">
          <img alt="" src="assets/images/search.svg">
          <input matInput placeholder="Search" (keyup)="applyFilter($event)">
        </div>
      </mat-form-field>


      <mat-form-field appearance="outline" class="m-2 mr-1 search-bar" *ngIf="showFilterByDate">
        <input matInput [matDatepicker]="datePicker" placeholder="Choose a date"
          (selectionChange)="updateAction(null, filterByDateAction)">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>

      <button class="header-btn m-2 mr-1 export-btn" *ngIf="showImport" mat-raised-button
        (click)="updateAction(null, importAction)">
        <img src="assets/images/icons/Export.svg" alt=""> Import
      </button>
      <button class="header-btn m-2 mr-1 export-btn" *ngIf="showExport" mat-raised-button
        (click)="updateAction(null, exportAction)">
        <img src="assets/images/icons/Export.svg" alt=""> Export
      </button>
      <button class="header-btn m-2 mr-1 filter-btn fbtn" *ngIf="showFilter" mat-raised-button
        (click)="updateAction(null, filterAction)">
        <img src="assets/images/filter.svg" alt=""> <span class="filter ps-2">Filter</span>
      </button>
      <button class="header-btn m-2 add-btn" *ngIf="showAddButton" mat-raised-button
        (click)="updateAction(null, addAction)">
        <img alt="" style="fill: white;"><mat-icon>add</mat-icon> {{showAddButtonName}}
      </button>
    </div>
  </div>
  <div class="table-wrapper">
    <table [dataSource]="dataSource" mat-table matSort>
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="!column.isModelProperty" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell  class="sno">{{ column.name }}</th><!--mat-sort-header-->
          <td mat-cell *matCellDef="let element; let i = index" class="sno"><span
              class="mx-3">{{calculateSerialNumber(i) }}</span></td>
        </ng-container>
        <ng-container *ngIf="column.isModelProperty" [matColumnDef]="column.property">
          <th *matHeaderCellDef mat-header-cell >{{ column.name }}</th><!--mat-sort-header-->
          <td *matCellDef="let row" mat-cell [style.width]="column.width">
            <span *ngIf="column.isText">{{ (row[column.property] | truncateText: 30 ) }} </span>
            <span *ngIf="column.isArray">
              <ng-container *ngFor="let nestedRow of row[column.property]">
                {{ nestedRow.primaryUnit }}
                <ng-container *ngFor="let secondary of nestedRow.secondaryMeasure">
                  {{ secondary.unit }}
                </ng-container>
              </ng-container>
            </span>
            <span *ngIf="column.isRole">
              <div class=" name-container">
                <div *ngFor="let role of row[column.property]" class="my-2">
                  <span class=" speciality ">
                    <label class="med-world-basic-font med-world-basic-font__grey">{{role}}</label>
                    <!-- <mat-icon class="close">close</mat-icon> -->

                  </span>
                  <span class="mx-1"></span>
                </div>
              </div>

            </span>
            <span *ngIf="column.isObject">
              <div class="name-container">
                <div *ngFor="let key of getObjectKeys(row[column.property])" class="my-2">
                  <span class="speciality">
                    <label class="med-world-basic-font med-world-basic-font__grey">{{ row[column.property][key]
                      }}</label>
                    <!-- <mat-icon class="close">close</mat-icon> -->
                  </span>
                  <span class="mx-1"></span>
                </div>
              </div>
            </span>

            <span *ngIf="column.hasChildProperty">{{ row[column.property]?row[column.property][column.childPropertyKey]
              :'' }}</span>
            <span *ngIf="column.isDatePipe">{{ row[column.property] | date:'dd/MM/YYYY'}}</span>
            <span *ngIf="column.isUserImage" class="d-flex align-items-center"><img [src]="row[column.imageKey]"
                class="img-fluid rounded-circle image " alt="">
              <p class="mx-2 mt-3">{{ row[column.property] }}</p>
            </span>
            <span *ngIf="column.isImage"><img [src]="row[column.property]" class="img-fluid rounded-circle image"
                alt=""></span>

                <!-- <span   *ngIf="column.isCheckBox"> <mat-checkbox 
                  class="check custom-checkbox" 
                  [checked]="row[column.property]" (change)="updateAction(row, column.actionName)">
                </mat-checkbox></span> -->
               
            <button *ngIf="column.isStatus" class="active" [class.inactive]="!row[column.property]">{{
              row[column.property] | active }}</button>
            <button *ngIf="column.isRequestStatus" [ngClass]="row[column.property]">{{ row[column.property]}}</button>
            <span *ngIf="column.isLink"><a class="file-download" (click)="openUrl(row[column.property])">{{
                row[column.property] }}</a></span>
            <span *ngIf="column.isSecondaryLink">
              <ng-container *ngIf="row[column.property] === 0; else elseNotDone">
                {{row[column.property] }}
              </ng-container>
              <ng-template #elseNotDone>
                <a class="file-download" (click)="updateAction(row, column.secondaryAction)">{{ row[column.property]
                  }}</a>
              </ng-template>
            </span>
            <span *ngIf="column.isFile">
              <a class="file-download" (click)=" openUrl(row[column.property])">{{ getFileName(row[column.property])
                }}</a>
            </span>

            <span *ngIf="column.isFileDownload"><a class="file-download"
                (click)="updateAction(row, fileDownloadAction)">{{ row[column.property] }}</a></span>
            <div *ngIf="column.hasAction" class="action-container">
              <div *ngFor="let action of column.actions">
                <mat-checkbox style="padding: 0px;" *ngIf="action.isCheckBox" [checked]="isSelected(row.id,preSelectedList)" (change)="updateAction(row, action.actionType)"></mat-checkbox>
                <mat-icon *ngIf="!action.isImage && !action.isCheckBox" class="action_icon" (click)="updateAction(row, action.actionType)">{{
                  action.iconName }}</mat-icon>
                <img
                  *ngIf="action.isImage && (action.hasCondition?(row[action.conditionProperty]===action.conditionValue):true)"
                  class="image_action" (click)="updateAction(row, action.actionType)" alt="" [src]="action.imageUrl">
              </div>
            </div>
            <div *ngIf="column.isMoreAction" class="action-container">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button style="color: #0D87C8 !important;" mat-menu-item *ngFor="let action of column.actions"
                  (click)="updateAction(row, action.actionType)">
                  <mat-icon style="color: #0D87C8 !important;">{{action.iconName}}</mat-icon>
                  <span>{{action.name}}</span>
                </button>
              </mat-menu>
            </div>
            <!-- Dropdown  -->
            <div *ngIf="column.isDropdown">
              <mat-form-field appearance="outline" style="flex-direction: row;" class="input-font w-100">
                <mat-select [(ngModel)]="row[column.property]" (selectionChange)="onStatusChange(row, column)">
                  <mat-option value="initial_contact">Initial Contact</mat-option>
                  <mat-option value="schedule_appointment">Schedule Appointment</mat-option>
                  <mat-option value="qualified">Qualified</mat-option>
                  <mat-option value="account_created">Account Created</mat-option>
                  <mat-option value="on_hold">On Hold</mat-option>
                </mat-select>
                
              </mat-form-field>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.hasAction && column.actionType === 'delete'" [matColumnDef]="'delete'">
          <th *matHeaderCellDef mat-header-cell></th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="deleteAction(row)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns sticky: true" mat-header-row></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator *ngIf="showPagination" class="mat-pagination d-none" [length]="dataSource.data?.length"
    [pageSize]="pageSize">
  </mat-paginator>

  <div class="paginator-container" *ngIf="showPagination">
    <div class="paginator-info mt-3">
      <p class="show-entries">Show </p>
      <mat-form-field appearance="outline" class="page-number-option mx-2">
        <mat-select [(ngModel)]="pageSize" (selectionChange)="changePageSize($event.value)">
          <mat-option *ngFor="let size of [10, 20, 30, 40, 50]" [value]="size">
            {{ size }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p class="show-entries ">entries </p>
    </div>

    <div class="page-numbers">
      <!-- <button mat-icon-button class="arrow" (click)="changePage(0)" [disabled]="currentPage === 0">
             <img src="assets/images/pagination_first.svg" alt="" srcset="">
          </button> -->
      <button mat-icon-button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
        <!--  <mat-icon>chevron_left</mat-icon> -->
        <img src="assets/images/pagination_prev.svg" alt="" srcset="">
      </button>

      <ng-container>
        <button mat-button>
          {{ currentPage + 1 }}
        </button>
      </ng-container>

      <!-- <ng-container *ngFor="let page of getPageNumbers()">
            <button mat-button *ngIf="page !== '...'" [class.page-active]="currentPage === page" (click)="changePage(+page)">
              {{ +page + 1 }}
            </button>
            <span *ngIf="page === '...'">...</span>
          </ng-container> -->

      <button *ngIf="!isDynemicPagination" mat-icon-button (click)="changePage(currentPage + 1)"
        [disabled]="currentPage >= getTotalPages() - 1">
        <img src="assets/images/pagination_next.svg" alt="" srcset="">
      </button>

      <button *ngIf="isDynemicPagination" mat-icon-button (click)="changePage(currentPage + 1)"
        [disabled]="totalData < 10">
        <img src="assets/images/pagination_next.svg" alt="" srcset="">
      </button>

      <!-- <button mat-icon-button (click)="changePage(getTotalPages() - 1)" [disabled]="totalData < 10">
           <img src="assets/images/pagination_last.svg" alt="" srcset="">
          </button> -->
    </div>
  </div>


</div>
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-info-card',
  templateUrl: './product-info-card.component.html',
  styleUrls: ['./product-info-card.component.scss']
})
export class ProductInfoCardComponent {
  @Input() id: string = '';
  @Input() newBadge: boolean = false;
  @Input() productImage: string = '';
  @Input() title: string = '';
  @Input() subtitleImage: string = '';
  
  @Input() hoverText: string = '';
  @Input() isCategoryCard: boolean = false;
  @Input() contentItems: { name: string; value: string; fromValue?: string; fromUnit?: string }[] = [];


  
}

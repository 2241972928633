
<section class="job-filters-section p-3"> 
  <div class="">
      <div class="">
          <div class="d-flex">
              <div class="filters-heading me-2">Filters</div>
              <mat-chip class="reset-chip" (click)="resetFilters()"><img
                      src="/assets/images/job-filters-reset.svg" alt="job-filters-reset" 
                      class="me-1">Reset</mat-chip>
          </div>

          <div class="card mt-3" style="height: 95%;">
              <div class="card-body">

                  <form [formGroup]="filterForm">

                      <div class="filter-field-container">
                          <div class="filter-label mb-3">Your Search Keyword</div>

                          <mat-chip-grid #chipList>
                              <div *ngFor="let controlName of filterControls">
                                <mat-chip-row *ngFor="let item of filterForm.get(controlName)?.value" 
                                class="reset-chip" [selectable]="selectable" [removable]="removable" 
                                (removed)="remove(controlName, item)">
                                  {{ item }}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip-row>
                              </div>
                              

                              <label class="input-container w-100">
                                  <mat-icon matPrefix class="inp">search</mat-icon>
                                  <input matInput [matChipInputFor]="chipList" placeholder="Search By Keyword"
                                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                      [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add('keywords',$event)">
                              </label>

                          </mat-chip-grid>
                      </div>

                      <div class="filter-field-container">
                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="filter-label"> Region </mat-panel-title>
                              </mat-expansion-panel-header>
                              <mat-form-field appearance="outline" class="input-font w-100">
                                  <mat-select multiple placeholder="Search By Country" formControlName="countries">
                                      <mat-option *ngFor="let country of countries"
                                          [value]="country.countryName">{{
                                          country?.countryName }}</mat-option>
                                  </mat-select>
                              </mat-form-field>

                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Search By State" formControlName="states">
                                        <mat-option *ngFor="let state of states"
                                            [value]="state.stateName">{{
                                                state?.stateName }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Search By City" formControlName="cities">
                                        <mat-option *ngFor="let city of cities"
                                            [value]="city.cityName">{{
                                                city?.cityName }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput multiple placeholder="Search By Zip Code" formControlName="zipCode">
                                </mat-form-field>
                          </mat-expansion-panel>
                      </div>

                        <div class="filter-field-container">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="filter-label"> Brands </mat-panel-title>
                                </mat-expansion-panel-header>
                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Search By Brand" formControlName="brands">
                                        <mat-option *ngFor="let brand of brands"
                                            [value]="brand.name">{{
                                                brand?.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>   
                            </mat-expansion-panel>
                        </div>

                  </form>
              </div>
          </div>
      </div>
  </div>
</section>



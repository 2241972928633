import { LocalStorageService } from './../../../../services/local-storage.service';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppointmentComponent } from '../appointment/appointment.component';
import { ActivatedRoute } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exhibitions',
  templateUrl: './exhibitions.component.html',
  styleUrls: ['./exhibitions.component.scss']
})

export class ExhibitionsComponent {
  @Input() data : any;
  itemsPerPage = 9;
  products: any[] = [];
  originalData: any[] = [];
  showNoRecordsFounds: boolean = false;
  isSignIn: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute, 
    private fb: FormBuilder,
    public dialog: MatDialog,
    private oemService : OemService
    ) { 
      this.isSignIn = localStorageService.getItem('x-guest-token');
  }

  ngOnInit(): void {
    this.getExhibitionsByCreatedBy(this.data.id);
  }

  paginatedItems: { files: string[], title: string, address: string, description: string, showMore: boolean }[] = [];

  getExhibitionsByCreatedBy(brandId: string) {
    this.oemService.getExhibitionsByCreatedBy(brandId).subscribe({
      next: (response) => {
        this.products = response.data;         
        this.originalData = response.data;
        if (this.products) {
          this.paginatedItems = this.products.slice(0, this.itemsPerPage);
        }
      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
    });
  }

  onPageChanged(paginatedData: any[]): void {
    this.paginatedItems = paginatedData;
  }

  filteredData = this.products;

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.products = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
  }


  toggleShowMore(product: any): void {
    product.showMore = !product.showMore;
  }

  openDialog(product : any): void {
    if(this.isSignIn){
      Swal.fire({
        icon: 'warning',
        title: 'Cannot Book an Appointment!',
        text: 'Please sign in to book an appointment',
        confirmButtonText: 'OK'
      });
    }
    else{

      const dialogRef = this.dialog.open(AppointmentComponent, {
        width: '550px',
        height: 'auto',
        data : product,
        autoFocus: false
      });
      
      dialogRef.afterClosed().subscribe(result => {
      });
    } 
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RssfeedsService{

  constructor(
    private http: HttpClient
  ) { }

  data: any = `<?xml version="1.0" encoding="UTF-8"?>
<rss version="2.0" xmlns:media="https://search.yahoo.com/mrss/" xmlns:atom="https://www.w3.org/2005/Atom">
  <channel>
    <title><![CDATA[Companies]]></title>
    <link>https://www.business-standard.com/companies</link>
    <description><![CDATA[Companies News: Get latest company news, company results, company interviews, company financials, company analysis, company disclosure, corporate news, company announcements at Business Standard.]]></description>
    <copyright>Copyright business-standard.com</copyright>
    <lastBuildDate>Mon, 16 Sep 2024 17:37:52 +0530</lastBuildDate>
    <language>en-us</language>
    <image>
      <title>Companies</title>
      <url>https://www.business-standard.com/companies</url>
      <link>https://www.business-standard.com/companies</link>
    </image>
    <atom:link href="https://www.business-standard.com/rss/companies-101.rss" rel="self" type="application/rss+xml"/>
    <item>
      <title><![CDATA[No power of attorney given to Baba Kalyani by late mother: Kalyani Group]]></title>
      <link>https://www.business-standard.com/companies/news/no-power-of-attorney-given-to-baba-kalyani-by-late-mother-kalyani-group-124091600862_1.html</link>
      <guid>https://www.business-standard.com/companies/news/no-power-of-attorney-given-to-baba-kalyani-by-late-mother-kalyani-group-124091600862_1.html</guid>
      <description><![CDATA[No power of attorney was executed by late S N Kalyani in favour of her son Kalyani Group Chairman, Baba Kalyani, and he has nothing to do with any of the litigations that his mother had initiated during her lifetime, the group said on Monday.
Refuting reports that alleged that Baba Kalyani coerced his mother and exercised undue influence, a group spokesperson said the claims made in an affidavit of late S N Kalyani as produced by Gaurishankar Kalyani, Baba Kalyani's brother "are fabricated, incorrect and denied".
"Baba Kalyani will appropriately represent his case before the Pune District Court," the spokesperson said in a statement.
Late S N Kalyani and Gaurishankar Kalyani (and his family) have been staying at their residence Parvti Nivas, when she made her will in 2012. Hence, all allegations insinuating that Baba Kalyani coerced her and exercised undue influence are incorrect and denied, it added.
The spokesperson alleged vested interests of spreading information with "several ]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600862.jpg"/>
      <pubDate>Mon, 16 Sep 2024 17:27:16 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Mercedes-Benz to manufacture EQS SUV 580 in India, first outside the US]]></title>
      <link>https://www.business-standard.com/companies/news/mercedes-benz-to-manufacture-eqs-suv-580-in-india-first-outside-the-us-124091600883_1.html</link>
      <guid>https://www.business-standard.com/companies/news/mercedes-benz-to-manufacture-eqs-suv-580-in-india-first-outside-the-us-124091600883_1.html</guid>
      <description><![CDATA[Luxury carmakers are focusing on bringing in more models that will be made in India]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600883.jpg"/>
      <pubDate>Mon, 16 Sep 2024 17:20:44 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Tata Steel's Kalinganagar expansion to make Odisha top investment site]]></title>
      <link>https://www.business-standard.com/companies/news/tata-steel-s-kalinganagar-expansion-to-make-odisha-top-investment-site-124091600842_1.html</link>
      <guid>https://www.business-standard.com/companies/news/tata-steel-s-kalinganagar-expansion-to-make-odisha-top-investment-site-124091600842_1.html</guid>
      <description><![CDATA[Tata Steel on Monday said Odisha would emerge as the company's single-largest investment destination after the phase-II expansion of its Kalinganagar plant from 3 million tonnes per annum to 8 mtpa.
The steel major has invested Rs 27,000 in phase-II of the Kalinganagar plant expansion, and the company is on the verge of commissioning its expanded capacity at the unit, it said in a release.
The ongoing expansion in Kalinganagar will play an important role in Tata Steel's ambition to achieve its target of 40 mtpa capacity in India by 2030, the company said.
The phase-II expansion of Tata Steel's Kalinganagar plant in Jajpur district of Odisha... will also catapult the eastern state to an enviable position of becoming the single-largest investment destination for the country's oldest steel maker, the release said.
Along with the Tata Steel Meramandali (formerly Bhushan Steel Ltd) plant in Dhenkanal district, the company's total investment in Odisha stands at over Rs 100,000 crore.
Ev]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600842.jpg"/>
      <pubDate>Mon, 16 Sep 2024 17:11:21 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Godrej Consumer MD and CEO Sudhir Sitapati throws weight behind Dhaval Buch]]></title>
      <link>https://www.business-standard.com/pti-stories/national/godrej-consumer-md-ceo-sudhir-sitapati-throws-weight-behind-dhaval-buch-124091600843_1.html</link>
      <guid>https://www.business-standard.com/pti-stories/national/godrej-consumer-md-ceo-sudhir-sitapati-throws-weight-behind-dhaval-buch-124091600843_1.html</guid>
      <description><![CDATA[Godrej Consumer Products MD & CEO Sudhir Sitapati has thrown his support behind Dhaval Buch, husband of embattled Sebi Chairperson Madhabi Puri Buch, stating that it could be the side effects of what happens when the worlds of government and the private sector collide.
Dhaval and Madhabi have been facing allegations of conflict of interest over providing consultancy services to corporates.
In a post on networking platform LinkedIn, he said unless the context of the top professionals coming from the corporate world is understood, there could be a situation where no senior person from the private sector would ever consider a government job.
Given the cusp of change India is at, "there is a lot of talent in the private sector that the government could use, and we probably need to reconsider our old socialist biases against everything private," he said.
"I have known of and then known Dhaval Buch for the 22 years that I worked at Hindustan Unilever. I was much his junior, but in a ...]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600843.jpg"/>
      <pubDate>Mon, 16 Sep 2024 17:02:54 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Salesforce unveils 'Agentforce' AI agents to enhance employee productivity]]></title>
      <link>https://www.business-standard.com/companies/news/salesforce-unveils-agentforce-ai-agents-to-enhance-employee-productivity-124091600857_1.html</link>
      <guid>https://www.business-standard.com/companies/news/salesforce-unveils-agentforce-ai-agents-to-enhance-employee-productivity-124091600857_1.html</guid>
      <description><![CDATA[The low-code tools in Agentforce enable organisations to easily build and deploy custom agents, said the company]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600857.jpg"/>
      <pubDate>Mon, 16 Sep 2024 17:02:27 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[How Intel lost Sony PlayStation deal worth potential $30 billion revenue]]></title>
      <link>https://www.business-standard.com/technology/tech-news/how-intel-lost-sony-playstation-deal-worth-potential-30-billion-revenue-124091600829_1.html</link>
      <guid>https://www.business-standard.com/technology/tech-news/how-intel-lost-sony-playstation-deal-worth-potential-30-billion-revenue-124091600829_1.html</guid>
      <description><![CDATA[Dispute over profit margins blocked Intel-Sony deal, sources say]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600829.jpg"/>
      <pubDate>Mon, 16 Sep 2024 16:46:00 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[HCLTech tops TIME's World's Best Companies 2024 list among Indian firms]]></title>
      <link>https://www.business-standard.com/companies/news/hcltech-tops-time-s-world-s-best-companies-2024-list-among-indian-firms-124091600709_1.html</link>
      <guid>https://www.business-standard.com/companies/news/hcltech-tops-time-s-world-s-best-companies-2024-list-among-indian-firms-124091600709_1.html</guid>
      <description><![CDATA[Indian IT firm HCLTech has been named the no.1 India-headquartered company in TIME magazine's World's Best Companies 2024 list.
The Noida-headquartered firm has also secured a place in the global top 10 list within the Professional Services category.
"This accolade underscores HCLTech's commitment to excellence and innovation. It reinforces our leadership in the industry and our status as a top employer.
"Aligned with our purpose of 'Supercharging Progress' and 'Find Your Spark' employee value proposition, we are dedicated to maximizing employee potential, advancing community and social responsibility initiatives and achieving sustainability goals," said Rahul Singh, Chief Operating Officer of Corporate Functions, HCLTech.
The World's Best Companies 2024 ranking identifies top-performing companies across the globe on the parameters of employee satisfaction, revenue growth, and sustainability.
HCLTech posted a consolidated net profit of Rs 4,257 crore in the June quarter of FY25, u]]></description>
      <pubDate>Mon, 16 Sep 2024 16:44:08 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Adani, Wilmar likely to start stake sale in joint venture from October]]></title>
      <link>https://www.business-standard.com/companies/news/adani-wilmar-expected-to-start-stake-sale-in-joint-venture-from-october-124091600819_1.html</link>
      <guid>https://www.business-standard.com/companies/news/adani-wilmar-expected-to-start-stake-sale-in-joint-venture-from-october-124091600819_1.html</guid>
      <description><![CDATA[The majority owners of Adani Wilmar are planning to sell a roughly 13 per cent interest in the company  - valued at about $736 million]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600819.jpg"/>
      <pubDate>Mon, 16 Sep 2024 16:34:03 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Tata Electronics to build two semiconductor manufacturing fabs in Gujarat]]></title>
      <link>https://www.business-standard.com/companies/news/tata-electronics-to-build-two-semiconductor-manufacturing-fabs-in-gujarat-124091600811_1.html</link>
      <guid>https://www.business-standard.com/companies/news/tata-electronics-to-build-two-semiconductor-manufacturing-fabs-in-gujarat-124091600811_1.html</guid>
      <description><![CDATA[Construction Tata Electronics' first chipmaking unit in Gujarat is already underway with its first phase valued at Rs 91,000 crore]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600811.jpg"/>
      <pubDate>Mon, 16 Sep 2024 16:31:54 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Emperium targets Rs 775 cr revenue from Yamunanagar, Gurugram projects]]></title>
      <link>https://www.business-standard.com/companies/news/emperium-targets-rs-775-cr-revenue-from-yamunanagar-gurugram-projects-124091600699_1.html</link>
      <guid>https://www.business-standard.com/companies/news/emperium-targets-rs-775-cr-revenue-from-yamunanagar-gurugram-projects-124091600699_1.html</guid>
      <description><![CDATA[Realty firm Emperium Pvt Ltd will develop a 40-acre township project in Yamunanagar and a housing project in Gurugram over the next three years with an estimated revenue potential of Rs 775 crore.
Since its inception, Emperium has delivered 1.7 million square feet of residential spaces, completing 1,320 units in Panipat City alone, with a total revenue of Rs 341 crore.
"In the next three years, Emperium plans to develop an additional 2.1 million square feet across 1,055 units," the company said in a statement on Monday.
It will develop a luxury residential project PREMIO in Gurugram and a 40-acre township project EMPERIUM RESORTICO in Yamunanagar.
Ravi Saund, Founder Director, Emperium Pvt Ltd, said, the projected revenue for these two new projects is Rs 775 crore.
In Gurugram project, the company will develop 216 apartments. In Yamunanagar township, it is offering villas, plots, floors, and SCOs (shops cum offices).
Saund said the company has delivered high-quality projects acro]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600699.jpg"/>
      <pubDate>Mon, 16 Sep 2024 16:01:36 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Axis Bank expands wealth management services across 15 new cities]]></title>
      <link>https://www.business-standard.com/companies/news/axis-bank-expands-wealth-management-services-across-15-new-cities-124091600689_1.html</link>
      <guid>https://www.business-standard.com/companies/news/axis-bank-expands-wealth-management-services-across-15-new-cities-124091600689_1.html</guid>
      <description><![CDATA[Axis Bank on Monday said its private banking business Burgundy Private will expand its wealth management services to 15 new cities, increasing its presence to 42 locations across India.
With this strategic move, Burgundy Private will now offer its bespoke wealth management services tailored to the unique needs of discerning clients in India's rapidly evolving Tier 2 markets, Axis Bank said in a statement.
The new locations, to start with, include Bhubaneswar, Patna, Raipur, Agra, Ghaziabad, Jodhpur, Udaipur, Jalandhar, Meerut, Belgaum, Kozhikode, Thiruvananthapuram, Aurangabad, Nagpur, and Gandhidham, it said.
By leveraging its expertise, technology, and data analytics, Burgundy Private aims to deliver personalised solutions that meet the evolving expectations of affluent clients in these emerging geographies, it said.
Burgundy Private has an AUM of nearly Rs 2.07 trillion, a 33 per cent increase YoY, and currently manages wealth for over 13,000 families across 27 cities.]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600689.jpg"/>
      <pubDate>Mon, 16 Sep 2024 15:56:29 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Eros aims Rs 900 cr revenue from new housing project in Greater Noida West]]></title>
      <link>https://www.business-standard.com/companies/news/eros-aims-rs-900-cr-revenue-from-new-housing-project-in-greater-noida-west-124091600653_1.html</link>
      <guid>https://www.business-standard.com/companies/news/eros-aims-rs-900-cr-revenue-from-new-housing-project-in-greater-noida-west-124091600653_1.html</guid>
      <description><![CDATA[Realty firm Eros Group is expecting around Rs 900 crore revenue from its new housing project in Greater Noida (West) on strong demand for premium residential properties.
The company has recently launched its new housing project 'Eros Sampoornam 3' in Greater Noida West comprising a total of 726 units. The project is spread over 5.5 acre and is scheduled to be delivered by 2028.
To begin with, Eros Group has opened 318 units across five towers for sale and has already sold around 180 units valuing Rs 250 crore.
Avneesh Sood Director, Eros Group said, "The early sales success reflects the trust and confidence that homebuyers have in our brand."
A company official said the total sales realisation for the entire 726 units is expected to be around Rs 900 crore. The carpet area of flats ranges from 531.63 square feet to 1,068.67 square feet.
Eros Group has already delivered its 12.5 acre housing project 'Sampoornam' comprising 1,768 units. It will soon offer possession for 'Sampoornam 1]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600653.jpg"/>
      <pubDate>Mon, 16 Sep 2024 15:55:12 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[India gets $386 bn funding for RE target; RIL, Adani pledge commitments]]></title>
      <link>https://www.business-standard.com/companies/news/india-gets-386-bn-funding-for-re-target-ril-adani-pledge-commitments-124091600697_1.html</link>
      <guid>https://www.business-standard.com/companies/news/india-gets-386-bn-funding-for-re-target-ril-adani-pledge-commitments-124091600697_1.html</guid>
      <description><![CDATA[India added slightly more than 18 GW of renewable energy capacity in fiscal 2024, compared with 15.3 GW a year earlier, taking its total to 153 GW]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600697.jpg"/>
      <pubDate>Mon, 16 Sep 2024 15:47:19 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Adani Group warns against fake press releases linked to its Kenya projects]]></title>
      <link>https://www.business-standard.com/companies/news/adani-group-warns-against-fake-press-releases-linked-to-its-kenya-projects-124091600671_1.html</link>
      <guid>https://www.business-standard.com/companies/news/adani-group-warns-against-fake-press-releases-linked-to-its-kenya-projects-124091600671_1.html</guid>
      <description><![CDATA[The Adani Group on Monday dismissed circulating press releases about its Kenya operations as fraudulent, blaming 'vested interests with malicious intent' for the incident]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600671.jpg"/>
      <pubDate>Mon, 16 Sep 2024 15:02:01 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[RPower secures 500 MW storage contract marking entry into renewable energy]]></title>
      <link>https://www.business-standard.com/companies/news/rpower-secures-500-mw-storage-contract-marking-entry-into-renewable-energy-124091600675_1.html</link>
      <guid>https://www.business-standard.com/companies/news/rpower-secures-500-mw-storage-contract-marking-entry-into-renewable-energy-124091600675_1.html</guid>
      <description><![CDATA[Other bidders in the auction included Avaada Energy, ACME Cleantech, Gensol, and Indigrid, with the highest quoted tariff reaching Rs 4.9 lakh per MW per month]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600675.jpg"/>
      <pubDate>Mon, 16 Sep 2024 14:59:15 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Reliance Power bags 500 MW battery energy storage contract through auction]]></title>
      <link>https://www.business-standard.com/companies/news/reliance-power-bags-500-mw-battery-energy-storage-contract-through-auction-124091600557_1.html</link>
      <guid>https://www.business-standard.com/companies/news/reliance-power-bags-500-mw-battery-energy-storage-contract-through-auction-124091600557_1.html</guid>
      <description><![CDATA[Reliance Power on Monday announced that it has secured a battery storage contract of 500 MW through the e-reverse auction (eRA) conducted by the Solar Energy Corporation of India (SECI).
The auction, held on September 11, 2024, is part of SECI's initiative to enhance energy storage capabilities across the country.
The contract involves the installation of a total of 1,000 MW of standalone BESS units, awarded on a build-own-operate (BOO) model for "On Demand" usage under a tariff-based competitive bidding process.
Reliance Power has secured 500 MW MWh of this capacity, marking a significant entry into the renewable energy and storage sector, a company spokesperson said.
The competitive bidding saw Reliance Power submitting a tariff bid of Rs 3.81999 lakh/MW/month.
This new tariff benchmark represents one of the lowest rates for BESS tenders at the 400 KV level in India to date.
The delivery point for the project shall be 400 kV Fatehgarh(ATL) PS, Rajasthan. The scheduled commissio]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600557.jpg"/>
      <pubDate>Mon, 16 Sep 2024 14:41:41 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Mercedes expands BEV range; rolls out 2nd locally produced model in India]]></title>
      <link>https://www.business-standard.com/companies/news/mercedes-expands-bev-range-rolls-out-2nd-locally-produced-model-in-india-124091600538_1.html</link>
      <guid>https://www.business-standard.com/companies/news/mercedes-expands-bev-range-rolls-out-2nd-locally-produced-model-in-india-124091600538_1.html</guid>
      <description><![CDATA[Luxury car maker Mercedes-Benz India on Monday expanded its Battery Electric Vehicle (BEV) range in the country with the launch of the locally produced EQS 580 4MATIC.
India is the first country outside the US to start the local production of the seven-seater electric SUV.
EQS SUV 580 4MATIC is the second model, after the EQS sedan, to be locally produced at company's Pune-based manufacturing plant.
"This will be our sixth EV in India, and the second car to be locally produced in India. We are also proud that India becomes the first market out of the US to locally manufacture the EQS," Mercedes-Benz India Managing Director and CEO Santosh Iyer told PTI in an interaction.
The move to manufacture the product locally outlines the company's commitment to electrification and increasing product portfolio offering choices to customers, he added.
"EQS SUV's localisation underpins our deep commitment to grow the Indian luxury electric car market and reinforce our commitment for a greener a]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600538.jpg"/>
      <pubDate>Mon, 16 Sep 2024 14:20:49 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[JSW MG to launch 4 luxury cars in 2 years, to be sold through new channel]]></title>
      <link>https://www.business-standard.com/companies/news/jsw-mg-to-launch-4-luxury-cars-in-2-years-to-be-sold-through-new-channel-124091600610_1.html</link>
      <guid>https://www.business-standard.com/companies/news/jsw-mg-to-launch-4-luxury-cars-in-2-years-to-be-sold-through-new-channel-124091600610_1.html</guid>
      <description><![CDATA[The first luxury car will be launched in the first quarter of 2025, and the company plans to open 12 MG Select showrooms]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600610.jpg"/>
      <pubDate>Mon, 16 Sep 2024 14:08:29 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Hinduja group firm to raise Rs 3,000 crore as debentures to fund RCap buy]]></title>
      <link>https://www.business-standard.com/companies/news/hinduja-group-firm-to-raise-rs-3-000-crore-as-debentures-to-fund-rcap-buy-124091600563_1.html</link>
      <guid>https://www.business-standard.com/companies/news/hinduja-group-firm-to-raise-rs-3-000-crore-as-debentures-to-fund-rcap-buy-124091600563_1.html</guid>
      <description><![CDATA[The zero-coupon debenture issue will open and close on Monday and attract a yield rate of 14.5 per cent]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600563.jpg"/>
      <pubDate>Mon, 16 Sep 2024 13:47:04 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Sri Lanka's JVP vows to cancel Adani wind energy project if elected]]></title>
      <link>https://www.business-standard.com/world-news/sri-lanka-s-jvp-vows-to-cancel-adani-wind-energy-project-if-elected-124091600482_1.html</link>
      <guid>https://www.business-standard.com/world-news/sri-lanka-s-jvp-vows-to-cancel-adani-wind-energy-project-if-elected-124091600482_1.html</guid>
      <description><![CDATA[The Marxist Janatha Vimukthi Peramuna (JVP) on Monday vowed to cancel the Adani Group's wind power project in Sri Lanka if it gets elected in the presidential election scheduled for the weekend.
JVP leader Anura Kumara Dissanayake, the presidential candidate from the broader front National People's Power (NPP), told a political chat show here that they would annul the project.
Asked if the project posed a threat to the island nation's energy sector sovereignty, Dissanayake said: Yes. We will definitely cancel it as it threatens our energy sovereignty."
The JVP, which led a bloody anti-India rebellion in the island nation between 1987 and 1990 following India's direct intervention in the Lankan civil war through the Indo-Lanka Peace Accord, is believed to be leading in the unofficial polls ahead of the September 21 election.
The JVP dubbed the Indo-Lanka Accord a betrayal of the nation and killed the then-ruling party members, supporters and other political activists who supported t]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600482.jpg"/>
      <pubDate>Mon, 16 Sep 2024 13:29:39 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[JSPL, JRPL sign pact to invest in green hydrogen production in Odisha]]></title>
      <link>https://www.business-standard.com/companies/news/jspl-jrpl-sign-pact-to-invest-in-green-hydrogen-production-in-odisha-124091600426_1.html</link>
      <guid>https://www.business-standard.com/companies/news/jspl-jrpl-sign-pact-to-invest-in-green-hydrogen-production-in-odisha-124091600426_1.html</guid>
      <description><![CDATA[The steel producer said on Monday that this represents the largest investment in green hydrogen by any Indian steelmaker]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600426.jpg"/>
      <pubDate>Mon, 16 Sep 2024 12:02:06 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Zomato bans AI-generated images from food menus to preserve authenticity]]></title>
      <link>https://www.business-standard.com/companies/news/zomato-bans-ai-generated-images-from-food-menus-to-preserve-authenticity-124091600368_1.html</link>
      <guid>https://www.business-standard.com/companies/news/zomato-bans-ai-generated-images-from-food-menus-to-preserve-authenticity-124091600368_1.html</guid>
      <description><![CDATA[Effective from Monday (September 16), restaurants that fail to comply with the new rule against AI images will be removed from Zomato]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600368.jpg"/>
      <pubDate>Mon, 16 Sep 2024 11:32:28 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Reliance Retail to transfer FMCG brands to RCPL, plans for major expansion]]></title>
      <link>https://www.business-standard.com/companies/news/reliance-retail-to-transfer-fmcg-brands-to-rcpl-plans-for-major-expansion-124091600375_1.html</link>
      <guid>https://www.business-standard.com/companies/news/reliance-retail-to-transfer-fmcg-brands-to-rcpl-plans-for-major-expansion-124091600375_1.html</guid>
      <description><![CDATA[Reliance Retail will transfer its FMCG brands, including Campa and private labels, to its new arm RCPL to accelerate business expansion with a dedicated focus]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600375.jpg"/>
      <pubDate>Mon, 16 Sep 2024 11:32:17 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Kalyani family feud spirals with revelation of new will: Details explained]]></title>
      <link>https://www.business-standard.com/companies/people/kalyani-family-feud-spirals-with-revelation-of-new-will-details-explained-124091600371_1.html</link>
      <guid>https://www.business-standard.com/companies/people/kalyani-family-feud-spirals-with-revelation-of-new-will-details-explained-124091600371_1.html</guid>
      <description><![CDATA[Baba Kalyani and his brother Gaurishankar are embroiled in a legal dispute over their late mother Sulochana's will, intensifying the ongoing battle over the assets with their sister's children]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600371.jpg"/>
      <pubDate>Mon, 16 Sep 2024 11:30:48 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[JSW MG Motor to foray into accessible luxury segment, sets up new channel]]></title>
      <link>https://www.business-standard.com/companies/news/jsw-mg-motor-to-foray-into-accessible-luxury-segment-sets-up-new-channel-124091600340_1.html</link>
      <guid>https://www.business-standard.com/companies/news/jsw-mg-motor-to-foray-into-accessible-luxury-segment-sets-up-new-channel-124091600340_1.html</guid>
      <description><![CDATA[JSW MG Motor India will enter the 'accessible luxury' segment with plans to introduce four new energy vehicles in the next two years for which it is setting up a new retail channel brand, 'MG Select', according to company Chief Growth Officer, Gaurav Gupta.
The company plans to set up exclusive experience centres in 12 cities across India in the first year.
"With MG Select we will be bringing in four new products over the next two years to begin with," Gupta told PTI here.
"We will be bringing in fresh, new platforms and products and these will be across the new energy vehicles (NEV) platform. So, it will have plug-in hybrids, hybrids and electric vehicles," said Gupta, however, he clarified that MG Select is not purely an EV channel.
"The MG Select channel is for 'premium entry' of MG Motor India. It will bring in accessible luxury products for the new age customer of today," he added.
At present the entry luxury car segment in India is at a price range of around Rs 45 lakh with ]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600340.jpg"/>
      <pubDate>Mon, 16 Sep 2024 11:19:16 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Police detain nearly 104 Samsung workers, union leaders for protest march]]></title>
      <link>https://www.business-standard.com/companies/news/police-detain-nearly-100-samsung-workers-union-leaders-for-protest-march-124091600356_1.html</link>
      <guid>https://www.business-standard.com/companies/news/police-detain-nearly-100-samsung-workers-union-leaders-for-protest-march-124091600356_1.html</guid>
      <description><![CDATA[The detention marks an escalation of a strike by workers at a Samsung home appliance plant near Chennai]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600356.jpg"/>
      <pubDate>Mon, 16 Sep 2024 11:13:26 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[LIC appoints Infosys to build NextGen platform as part of DIVE programme]]></title>
      <link>https://www.business-standard.com/companies/news/lic-appoints-infosys-to-build-nextgen-platform-as-part-of-dive-programme-124091600227_1.html</link>
      <guid>https://www.business-standard.com/companies/news/lic-appoints-infosys-to-build-nextgen-platform-as-part-of-dive-programme-124091600227_1.html</guid>
      <description><![CDATA[LIC expects this digital platform to be the foundation for building high-value business applications]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600227.jpg"/>
      <pubDate>Mon, 16 Sep 2024 10:47:29 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Torrent Power commits Rs 64,000 crore investment for green projects]]></title>
      <link>https://www.business-standard.com/companies/news/torrent-power-commits-rs-64-000-crore-investment-for-green-projects-124091600181_1.html</link>
      <guid>https://www.business-standard.com/companies/news/torrent-power-commits-rs-64-000-crore-investment-for-green-projects-124091600181_1.html</guid>
      <description><![CDATA[Torrent Power on Monday pledged Rs 64,000 crore investment for green or renewable energy projects having employment potential for 26,000 people.
Torrent Power Ltd, the integrated power utility of diversified Torrent Group, has reaffirmed its commitment towards green and sustainable future as it submitted two 'Shapath Patras' to the Union Ministry of New and Renewable Energy at the 4th Edition of RE-Invest at Gandhinagar on Monday, a company statement said.
RE-INVEST is being organised by the Ministry of New and Renewable Energy in partnership with CII.
According to the statement, the company has submitted a Shapath Patra' to achieve 10 Gigawatt (GW) of installed Renewable Energy (RE) capacity by 2030, with an investment of Rs 57,000 crore.
This investment is expected to generate direct and indirect employment for around 25,000 people.
As part of these commitments, Torrent Power on Monday signed a Memorandum of Understanding (MoU) with the Gujarat government for execution of a 5 GW]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600181.jpg"/>
      <pubDate>Mon, 16 Sep 2024 10:18:29 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Kenya awards $1.3 bn transmission deal to Adani amid airport lease protests]]></title>
      <link>https://www.business-standard.com/companies/news/kenya-awards-1-3-bn-transmission-deal-to-adani-amid-airport-lease-protests-124091600142_1.html</link>
      <guid>https://www.business-standard.com/companies/news/kenya-awards-1-3-bn-transmission-deal-to-adani-amid-airport-lease-protests-124091600142_1.html</guid>
      <description><![CDATA[Kenya has awarded a $1.3 billion public-private partnership contract to India's Adani Group and Africa50 for the construction of power transmission lines]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240916/thumb-124091600142.jpg"/>
      <pubDate>Mon, 16 Sep 2024 09:50:19 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[US lenders counter Byju's claim, says edtech has to repay full $1.2 bn]]></title>
      <link>https://www.business-standard.com/companies/start-ups/us-lenders-counter-byju-s-claim-says-edtech-has-to-repay-full-1-2-bn-124091500534_1.html</link>
      <guid>https://www.business-standard.com/companies/start-ups/us-lenders-counter-byju-s-claim-says-edtech-has-to-repay-full-1-2-bn-124091500534_1.html</guid>
      <description><![CDATA[US lenders represented by Glas Trust have countered BYJU's founder Byju Raveendran's claim on verified debt on the company being merely around Rs 20 crore, and have said that the beleaguered edtech firm will have to pay the entire USD 1.2 billion Term Loan B along with interest.
The founder of Think and Learn (TLPL) and owner of BYJU'S brand, Byju Raveendran, had about a week ago said that verified debt on the company is only Rs 20 crore as per insolvency proceedings and he may not have to pay any money to the US lenders represented by Glas Trust.
"Neither Byju nor the IRP have the authority to disqualify any term loan lender 'and even if they did, BYJU's would still be obligated to repay the full amount of the loan plus interest. Any argument otherwise is illegitimate, and Byju knows it," a steering committee of the ad hoc group of term loan lenders to Byju's Alpha, Inc said in a statement.
Glas Trust has sued BYJU'S for the recovery of USD 1.2 bn TLB in US as well as in India.
Th]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500534.jpg"/>
      <pubDate>Sun, 15 Sep 2024 20:36:48 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Piyush Goyal to launch Bharat Startup Knowledge Access Registry on Monday]]></title>
      <link>https://www.business-standard.com/companies/start-ups/piyush-goyal-to-launch-bharat-startup-knowledge-access-registry-on-monday-124091500517_1.html</link>
      <guid>https://www.business-standard.com/companies/start-ups/piyush-goyal-to-launch-bharat-startup-knowledge-access-registry-on-monday-124091500517_1.html</guid>
      <description><![CDATA[Commerce and Industry Minister Piyush Goyal will on Monday launch the Bharat Startup Knowledge Access Registry (BHASKAR) initiative which will serve as a central hub where startups, investors, service providers, and government bodies can come together to collaborate, exchange ideas, and accelerate growth.
According to an official statement, it will also enhance collaboration among key stakeholders within the entrepreneurial ecosystem, including startups, investors, mentors, service providers, and government bodies.
The primary goal of BHASKAR is to build the world's largest digital registry for stakeholders within the startup ecosystem.
To achieve this, the platform will offer several key features such as networking and collaboration. providing centralized access to resources, creating personalized identification, enhancing discoverability, and supporting India's global brand.
"BHASKAR will bridge the gap between startups, investors, mentors, and other stakeholders, allowing for ..]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500517.jpg"/>
      <pubDate>Sun, 15 Sep 2024 19:38:10 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Temasek nears deal to acquire a significant minority stake in VFS Global]]></title>
      <link>https://www.business-standard.com/companies/news/temasek-nears-deal-to-acquire-a-significant-minority-stake-in-vfs-global-124091500498_1.html</link>
      <guid>https://www.business-standard.com/companies/news/temasek-nears-deal-to-acquire-a-significant-minority-stake-in-vfs-global-124091500498_1.html</guid>
      <description><![CDATA[Blackstone will remain the majority shareholder in VFS after the transaction, the people said. Considerations are ongoing and no final decisions have been made, they added]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500498.jpg"/>
      <pubDate>Sun, 15 Sep 2024 19:03:20 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[India very important market, looking at more frequencies: Malaysia Airlines]]></title>
      <link>https://www.business-standard.com/companies/news/india-very-important-market-looking-at-more-frequencies-malaysia-airlines-124091500455_1.html</link>
      <guid>https://www.business-standard.com/companies/news/india-very-important-market-looking-at-more-frequencies-malaysia-airlines-124091500455_1.html</guid>
      <description><![CDATA[Malaysia Airlines is keen to expand its operations into India, which is a "very important market", according to a top airline official.
The airline, which currently flies to nine Indian cities, is eyeing new destinations as well as looking to increase frequencies to Trivandrum and Ahmedabad.
Part of the Malaysia Aviation Group (MAG), Malaysia Airlines operates daily flights to Delhi, Mumbai, Bengaluru, Amritsar, Chennai, Hyderabad and Kochi. It also has four weekly services from Ahmedabad and Trivandrum.
"We continue to remain excited about the Indian market. It has been driven by robust economic movement," MAG's Group Managing Director Datuk Captain Izham Ismail told PTI in an interview.
"We aspire to expand more into India... that will happen only next year... India is a very important market," he said and added that it will look at expansion once it gets more aircraft.
Currently, Malaysia Airlines operates 71 weekly flights to India. The flights to Amritsar were increased from ]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500455.jpg"/>
      <pubDate>Sun, 15 Sep 2024 18:58:18 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[JSW Energy arm commissions 300 MW wind power plant at Tuticorin in TN]]></title>
      <link>https://www.business-standard.com/companies/news/jsw-energy-arm-commissions-300-mw-wind-power-plant-at-tuticorin-in-tn-124091500437_1.html</link>
      <guid>https://www.business-standard.com/companies/news/jsw-energy-arm-commissions-300-mw-wind-power-plant-at-tuticorin-in-tn-124091500437_1.html</guid>
      <description><![CDATA[JSW Energy on Sunday announced that its step-down subsidiary, JSW Renew Energy Two Ltd, has successfully commissioned a 300 MW wind power plant at Tuticorin in Tamil Nadu.
The ISTS-connected (Inter-state transmission system) wind power project was awarded by Solar Energy Corporation of India (SECI) in tranche X, a company statement said.
This marks the first-ever greenfield wind power plant commissioned by the company for the SECI, it noted.
The newly commissioned wind power project will contribute significantly to the renewable energy portfolio, supporting the vision of a greener and sustainable future, the company said.
The additional 150 MW of wind capacity awarded under SECI Tranche X located at Dharapuram, Tamil Nadu, is also nearing completion with 138 MW already commissioned, it informed.
Subsequent to this, total current installed capacity stands at 7,726 MW while the under- construction capacity stands at 2,114 MW, that is expected to be commissioned by the end of FY ...]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500437.jpg"/>
      <pubDate>Sun, 15 Sep 2024 18:35:15 +0530</pubDate>
    </item>
    <item>
      <title><![CDATA[Black Box expects rise in India biz share, hire 400 people in 2 yrs]]></title>
      <link>https://www.business-standard.com/companies/news/black-box-expects-rise-in-india-biz-share-hire-400-people-in-2-yrs-124091500373_1.html</link>
      <guid>https://www.business-standard.com/companies/news/black-box-expects-rise-in-india-biz-share-hire-400-people-in-2-yrs-124091500373_1.html</guid>
      <description><![CDATA[Essar Group's IT arm Black Box expects the share of India business to grow by 1-2 percentage points in its course to become a USD 2 billion revenue company by 2027-28, a top official of the company said.
The company specialises in building IT infrastructure for critical technology applications including hyperscaler data centres and planning to bring its learning to the Indian market as many of the company's global customers are looking to invest in the country, Black Box CEO Sanjeev Verma told PTI.
He said that the company plans to increase employee headcount across all levels in India to around 1,000 people from about 600 people at present.
"Currently, India is 6 per cent of our business. We do not expect it to become much larger, but if we have to go to USD 2 billion then it will be significantly important for us to move it to 7- 8 per cent," Verma said.
The company has reported revenue of around USD 750 in FY2024.
Verma said that Black Box want to bring some value to India base]]></description>
      <media:content url="https://bsmedia.business-standard.com/_media/bs/img/article/default/1240915/thumb-124091500373.jpg"/>
      <pubDate>Sun, 15 Sep 2024 17:34:32 +0530</pubDate>
    </item>
  </channel>
</rss>`;

  getRssFeed(){
    // Get the RSS feed and return the parsed XML
    // return this.http.get(url, {
    //   responseType: 'text',
    //   headers: new HttpHeaders({
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    //     'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept, Origin, Authorization'
    //   })
    // }).pipe(
    //   map((response) => this.parseXML(response))
    // );
    // this.parseXML(this.data);
  }

  parseXML(xmlStr: any): any {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlStr, 'text/xml');
    console.log('parser', parser, xml);
    
    const items = xml.querySelectorAll('item');
    const parsedItems = Array.from(items).map(item => {
      const title = item.querySelector('title')?.textContent ?? null;
      const link = item.querySelector('link')?.textContent ?? null;
      const description = item.querySelector('description')?.textContent ?? null;
      const guid = item.querySelector('guid')?.textContent ?? null;
      const pubDate = item.querySelector('pubDate')?.textContent ?? null;
    
      // Get the image from media:content using the correct namespace
      const mediaContent = item.getElementsByTagNameNS('https://search.yahoo.com/mrss/', 'content')[0];
      const image = mediaContent ? mediaContent.getAttribute('url') : null;
    
      return { title, link, description, guid, pubDate, image };
    });
    
    
    
    console.log('parsed items', parsedItems,  );
    
    return parsedItems;
  }

}

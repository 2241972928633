<h2 mat-dialog-title class="dialog-title">Send Message to {{ (roleName === 'OEM')? ('Distributor'): ('OEM') }} </h2>

<div mat-dialog-content class="dialog-content">
  <p><strong>Receiver Name:</strong> {{ data.enqCustomerName }}</p>
  

  <!-- <mat-label>Message</mat-label>
  <mat-form-field appearance="fill" class="message-field">
    <textarea matInput [(ngModel)]="data.messageContent"></textarea>
  </mat-form-field> -->

  <div class="mb-3 w-125">
    <label for="message" class="form-label">Add Message</label>
    <textarea id="message" class="form-control" [(ngModel)]="data.message" rows="4" placeholder="Type your message here.."></textarea>
  </div>

</div>

<div mat-dialog-actions class="dialog-actions">
  <button class="button-inverse btn btn-primary" type="button" (click)="onCancelClick()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]=" data.message === '' " (click)="onSendClick()" class="send-button">Send</button>
</div>

<app-website-header></app-website-header>
<div class="container">
    <div class="row">
        <div class="col-md-12 mt-3 terms">
            <h2>Privacy Policy</h2>
        </div>
    </div>

    <div class="row">
        <div>
            <p [innerHTML]="content" ></p>
        </div>
    </div>
</div>
<app-website-footer></app-website-footer>
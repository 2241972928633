import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { ACCESS_TOKEN, EXPIRES_IN, GUEST_TOKEN } from '../utils/constants';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const expiry = localStorage.getItem(EXPIRES_IN);
    const isguestTokenPresent = localStorage.getItem(GUEST_TOKEN);
    let authReq = req;
    if (req.url.includes('/refresh-token')) {
      // If it is a token refresh request, pass it through without intercepting
      return next.handle(req);
    }
    if (token && expiry && Number(expiry) > Date.now() && !isguestTokenPresent) {

     
      
      authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      });
      return next.handle(authReq);
    } else if (token && expiry && Number(expiry) <= Date.now() && !isguestTokenPresent) {

      
      console.log('expired');
      
      return this.authService.getAccessTokenExpiry().pipe(
        switchMap((newToken: any) => {      
          localStorage.setItem(ACCESS_TOKEN, newToken.body.data.accessToken);
          localStorage.setItem(EXPIRES_IN, (Date.now() + (15 * 60 * 1000)).toString());
          authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${newToken.body.data.accessToken}`)
          });
          return next.handle(authReq);
        })
      );
    } else {
      let guestToken = localStorage.getItem(ACCESS_TOKEN);
     
      if(!isguestTokenPresent || isguestTokenPresent&& Number(expiry) <= Date.now()) {
      guestToken = this.generateGuestToken();
      localStorage.setItem(ACCESS_TOKEN, guestToken);
      localStorage.setItem(EXPIRES_IN, (Date.now() + (15 * 60 * 1000)).toString());
      localStorage.setItem(GUEST_TOKEN, 'true');
      }

      authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${guestToken}`).set('x-guest-token', 'true')
      });
      
      return next.handle(authReq);
    }
  }

  generateGuestToken(): string {
    const payload = {
      sub: 'guest',
      exp: Math.floor(Date.now() / 1000) + (15 * 60 *24),
      role: 'guest'
    };

    const header = {
      alg: 'HS256',
      typ: 'JWT'
    };

    const encodedHeader = btoa(JSON.stringify(header));
    const encodedPayload = btoa(JSON.stringify(payload));
    const signature = btoa('medworld-app-secret-key' + encodedHeader + encodedPayload);

    return `${encodedHeader}.${encodedPayload}.${signature}`;
  }
  
}
  
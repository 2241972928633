import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomerPreviewDialogComponent } from './customer-preview-dialog/customer-preview-dialog.component';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { ShareResumeComponent } from '../shared/share-resume/share-resume.component';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { Router } from '@angular/router';




@Component({
  selector: 'app-end-customer',
  templateUrl: './end-customer.component.html',
  styleUrls: ['./end-customer.component.scss']
})
export class EndCustomerComponent implements OnInit {

handleWorkExperience($event: Event) {
throw new Error('Method not implemented.');
}

  @Output() workExperienceChanged = new EventEmitter<boolean>();
  @Input() selectedTabIndex: number = 0;
  @Input() addAction: boolean = false;
  tabName: string = 'myProfile';

  showSavedJobs!: boolean;
  applicantId: any;
  userName: any;

  messages: any = [];
  isEnquiry:boolean = false;
  isProductEnquiry: boolean = false;
  endCustomerType: string = '';

  constructor(private readonly endCustomerService: EndCustomerService,
    private readonly localStorageService: LocalStorageService,private dialog: MatDialog , private fileUploadService: FileUploadService , private spinnerService: SpinnerService, private router: Router ) { }

  ngOnInit(): void {
    this.localStorageService.getUserDetails().subscribe((data: any) => {
      this.getEndCustomerByUserId(data.userId);
    });
    
    this.isProductEnquiry = window.history?.state.isProductEnquiry;

    if (this.isProductEnquiry) {
      this.tabName = 'productEnquiry';
      this.isProductEnquiry = false;
    } else {
      this.tabName = 'myProfile';
    }
  }

  JobId: string = '';
  actionView(event: any){
    this.JobId = event;
    this.tabName = 'viewJob';
  }

  selectedAction(event: any){
    console.log('selected action is ', event);
    switch (event.type) {

      case 'add':
        console.log('add clicked');
        this.tabName = 'postJob';
        break;

      case 'update':
        console.log('update clicked');
        this.JobId = event.id;
        this.tabName = 'updateJob';
        break;
     
      default:
        break;
    }
    
  }

  actionUpdate(event: any){
    this.JobId = event;
    this.tabName = 'postJob';
  }
  viewAllJobs: string = '';

  showAllJobs(event: any){
    this.tabName = 'ViewAllJobs';
  }

  postAJob(event: any){
    this.tabName = 'postJob';
  }

  activateCurrentab(selectedName: string): void {
    this.tabName = selectedName;
  }

  messageFromPostJob(event: any){
    if(event === 'cancel'){
      this.tabName = 'ViewAllJobs';
    }
  }

  backToAllJobs(event: any){
    this.tabName = 'ViewAllJobs';
  }

  public downloadResumefromHtml(): void {
    this.downloadPdf('carrers', 'Resume', true);

  }
 
  public downloadPdf(htmlElementId: string, pdfFileName: string, isDownload: boolean = true): Promise<Blob | void> {
    return new Promise((resolve, reject) => {
      const element = document.getElementById(htmlElementId);

      if (element) {
        html2canvas(element, { scale: 1 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg', 0.7);
          const pdf = new jsPDF('p', 'mm', 'a4');
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
         this.userName = element.querySelector('#user-name')?.textContent;
          pdfFileName = this.userName ? `${this.userName} - ${pdfFileName}` : 'Resume';
          const margin = 10;
          const usableWidth = pdfWidth - 2 * margin;
          const usableHeight = pdfHeight - 2 * margin;
  
          const imgProps = pdf.getImageProperties(imgData);
          const imgWidth = usableWidth;
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
  
          let heightLeft = imgHeight;
          let position = margin;
          pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight, undefined, 'FAST');
          heightLeft -= usableHeight;
  
          while (heightLeft > 0) {
            pdf.addPage();
            position = heightLeft - imgHeight + margin;
            pdf.addImage(imgData, 'JPEG', margin, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= usableHeight;
          }
  
          // If the `isDownload` flag is true, trigger the download
          if (isDownload) {
            pdf.save(`${pdfFileName}.pdf`);
            resolve(); // No need to return anything
          } else {
            const pdfBlob = pdf.output('arraybuffer'); // Get the array buffer
            const blob = new Blob([pdfBlob], { type: 'application/pdf' }); // Convert to Blob
            resolve(blob); // Return the Blob for further operations
          }
        }).catch((error) => {
          reject(error);
        });
      } else {
        reject(new Error('Element not found'));
      }
    });
  }
  


  


  public async shareResume(): Promise<void> {
    try {
  this.spinnerService.showSpinner('Loading...');
      const pdfBlob = await this.downloadPdf('carrers', 'Resume', false); // Generate PDF as a Blob
  
      if (pdfBlob instanceof Blob) {
        const formData = new FormData();
        formData.append('file', pdfBlob , 'Resume - ' + new Date().toISOString() + '.pdf');
        formData.append('destinationKey', 'resumes/');
  
        // Call the file upload service
        this.fileUploadService.uploadFile(formData).subscribe((response) => {
          if (response && response.data) {
            const url = response.data;
            this.spinnerService.hideSpinner();
            this.dialog.open(ShareResumeComponent, {
              width: '20%',
              height: '11%',
              position: { top: '10%', left: '40%' },
              data: { url  : url  ,
                user : this.userName
              }
              
            })
          }
        });
      } else {
      }
    } catch (error) {
    }
  }
  

  openPreview(htmlElementId: string): void {
    const element = document.getElementById(htmlElementId);
    if (element) {
      const htmlContent = element.innerHTML;
      this.dialog.open(CustomerPreviewDialogComponent, {
        width: '65%',
        //height: '80%',
        autoFocus:false,
        position:{top:'40px'},
        data: { htmlContent }
      });
    } else {
    }
  }

  private getEndCustomerByUserId(userId: any) {
    this.endCustomerService.executeGetRequestURL(MweConstants.getCustomerByUserId + userId, null).subscribe({
      next: (response: any) => {
        this.endCustomerType = response.data.account.accountType.name;
        if (response && response?.data) {
          this.applicantId = response?.data.id;
        }
      }, error: (error: any) => {}
    });
  }
  
  showMyProfileJobs(event: boolean) {
    this.tabName = "profileJobs";
    this.showSavedJobs = event;
  }
  onWorkExperience(event : {type: string, value: boolean})  {
    const type= event.type;
    this.tabName = "myProfile";
    setTimeout(() => {
      if (type === 'work') {
        const workingExperiencesElement = document.getElementById('workingExperiences') as HTMLElement;
        if (workingExperiencesElement) {
          workingExperiencesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else if (type === 'education') {
        const educationElement = document.getElementById('education') as HTMLElement;
        if (educationElement) {
          educationElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }, 500);
        }

       

      }
  //  if (type === 'work') {
    //   const workingExperiencesElement = document.getElementById('workingExperiences') as HTMLElement;
    //   if (workingExperiencesElement) {
    //     workingExperiencesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      
    //   }
    // }, 2000);
    // } else if (type === 'education') {
    //   const educationElement = document.getElementById('education') as HTMLElement;
    //   if (educationElement) {
    //     educationElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        
    //   
  
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterWebinarComponent } from '../register-webinar/register-webinar.component';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { WebinarService } from 'src/app/services/shared/webinar.service';

@Component({
  selector: 'app-webinar-view',
  templateUrl: './webinar-view.component.html',
  styleUrls: ['./webinar-view.component.scss']
})
export class WebinarViewComponent implements OnInit {
  webinarId: any;
  register: any = 0;
  companies: any = [1,2,3,4,5,6,7,8,9,10];
  webinar: any;
  webinarDateTime: string = '';
  iswebinarActive: boolean = true;

  constructor(private dialog: MatDialog, private sweetAlertService: SweetAlertService, private route: ActivatedRoute, private webinarService: WebinarService){}

  ngOnInit(): void {
    this.route.params.subscribe((p: any)=>{
      this.webinarId = p.id      
    })
    this.getWebinar(this.webinarId);
  }

  timeToWebinar: any[] = [];
  formatwebinarDate: any;
  formatwebinarTime: any;

  timeLeft(webinarDate: string, webinarTime: any): void {
    const now = new Date();
    const webinarDateTime = new Date(`${webinarDate} ${webinarTime}`);
    const timeDifference = webinarDateTime.getTime() - now.getTime();

    if (timeDifference > 0) {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      const timeLeft = `${days} days ${hours} hours ${minutes} minutes and ${seconds} seconds`;
      this.timeToWebinar = [days, hours, minutes, seconds];
      setInterval(() => {
        this.timeLeft(this.formatwebinarDate, this.formatwebinarTime);
      }, 1000);
    } else {
    }
  }


  // Reformats webinar duration
  reformatDuration(duration: any): string {
    const hours = duration[0] < 10 ? '0' + duration[0] : duration[0];
    const minutes = duration[1] < 10 ? '0' + duration[1] : duration[1];
    let seconds = '00';
    if (duration.length > 2) {
      seconds = duration[2] < 10 ? '0' + duration[2] : duration[2];
    }
    return `${hours}:${minutes}:${seconds}`;
  }

  // Reformats webinar time into 12-hour AM/PM format
  reformatTime(time: any): string {
    let hours = time[0];
    const minutes = time[1] < 10 ? '0' + time[1] : time[1];
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minutes} ${ampm}`;
  }

  getDate(list: number[]){
    return list.map(n=> n.toString().length === 1 ? `0${n}` : n).join('-')
  }

  getTime(list: number[]){
    return list.map(n=> n.toString().length === 1 ? `0${n}` : n).join(':')
  }

  getWebinar(id: string){
    this.webinarService.getWebinarById(id).subscribe((res: any)=>{
      this.webinar = res.data.webinar
      // this.timeLeft(this.webinar.webin)
      this.formatwebinarDate = this.webinar.webinarDate[0] + '/' + this.webinar.webinarDate[1] + '/' + this.webinar.webinarDate[2]
      this.formatwebinarTime = this.reformatTime(this.webinar.webinarTime)
      this.timeLeft(this.formatwebinarDate, this.formatwebinarTime);
      this.webinarDateTime += this.getDate(this.webinar.webinarDate) + ' ' + this.getTime(this.webinar.webinarTime)
      if(new Date(this.webinar.webinarDate) >= new Date()){
        this.iswebinarActive = false;
      }
      console.log(this.iswebinarActive, new Date(this.webinar.webinarDate));
      
    })
  }

  registerToWebinar(){
    const dialogRef = this.dialog.open(RegisterWebinarComponent, {
      width: '520px',
      panelClass: 'center-dialog',
      data: { webinarId: this.webinarId,
        flag: false
       }
    });
 
    dialogRef.afterClosed().subscribe((res: any) => {
      // this.sweetAlertService.showSuccess('', 'You have successfully registered for the webinar. The Webinar link and details will be sent to your email address.');
      
      // const confirmButton = Swal.getConfirmButton();
      // if(confirmButton){
      //   confirmButton.textContent =  'close'; 
      // }

      // const container = Swal.getHtmlContainer()
      // if(container){
      //   container.style.color = '#0D87C8';
      //   container.style.fontWeight = '500';
      //   container.style.fontSize = '24px';
      //   container.style.lineHeight = '36px';
      // }

      // const pop = Swal.getPopup();
      // if(pop){
      //   pop.style.borderRadius = '30px';
      //   pop.style.boxShadow = '0px 10px #94C743'
      //   // pop.style.width = '526px'
      //   // pop.style.height = '524px'
      // }
    })
  }
}

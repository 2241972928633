
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DistributorLead, DistributorOEMManagement } from 'src/app/models/distributor-model';
import { AuthService } from 'src/app/services/auth.service';
import { DistributorService } from 'src/app/services/distributor/distributor.service';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-add-distributor',
  templateUrl: './add-distributor.component.html',
  styleUrls: ['./add-distributor.component.scss']
})
export class AddDistributorComponent implements OnInit{
  adminAccountTypeId!: string;
  lead:boolean=false;

constructor(private fb: FormBuilder,private distributorService: DistributorService,
 private authService: AuthService,private dialogRef: MatDialogRef<AddDistributorComponent>,
@Inject(MAT_DIALOG_DATA) public data: any) { }

ngOnInit(): void {
  this.getAccountType();
  this.lead=this.data.leadAddNewDistributor;
}

cancelAddDistributor() {
  this.distributorForm.reset();
  this.dialogRef.close();
}

distributorForm = this.fb.group({
  firstName: ['', Validators.required],
  lastName: ['', Validators.required],
  companyName: ['', Validators.required],
  email: ['', Validators.email],
  phoneNumber: ['', Validators.required],
  address: ['', Validators.required],
  role: ["DISTRIBUTOR"],
  accountType: [''],
  network: true
});

getAccountType() {
  this.authService.getAccounttypes('admin').subscribe((data: any) => {
    const adminAccountType = data.data.find((account: any) => account.accountType.name === 'admin');
    if (adminAccountType) {
      this.distributorForm.get('accountType')?.setValue(adminAccountType.id);
    } else {
      console.error('Admin account type not found.');
    }
  });
}

createDistributor() {
  if (this.distributorForm.valid) {
    // Transform the form values to ensure they match the DistributorOEMManagement type
    const formValues = this.distributorForm.value;

    

    if(!this.lead){
      const distributorData: DistributorOEMManagement = {

        firstName: formValues.firstName || '',
        lastName: formValues.lastName || '',
        companyName: formValues.companyName || '',
        email: formValues.email || '',
        phoneNumber: formValues.phoneNumber || '',
        address: formValues.address || '',
        role: formValues.role || '',
        accountType: formValues.accountType || this.adminAccountTypeId || '',
        network: true
      };
      this.distributorService.addDistributorOemManagement(distributorData).subscribe(
        response => {
          Swal.fire({
            icon: 'success',
            title: 'Distributor Created successfully.',
          });
        },
        error => {
          console.error('Error:', error);
          Swal.fire(
            'Error',
            'Error occurred.',
            'error'
          );
        }
      );
      this.dialogRef.close();

    }
    else{
      const distributorData: DistributorLead = {

        companyName:formValues.companyName || '',
        email: formValues.email || '',
        phone: formValues.phoneNumber || '',
        address: formValues.address || '',
        
      };
      Swal.fire({
        icon: 'success',
        title: 'Distributor Added successfully.',
      });
      this.dialogRef.close(distributorData);

    }

   
    
  }
  else{
    Swal.fire('Error', 'Please fill all required fields.', 'error');
    
  }
  
}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-courses-profile',
  templateUrl: './courses-profile.component.html',
  styleUrls: ['./courses-profile.component.scss']
})
export class CoursesProfileComponent implements OnInit{

  coursesColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true ,isText: true},
    {name:'Institution',property:'institution',visible:true,isModelProperty:true,isText:true},
    {name:'Course Date',property:'courseDate',visible:true,isModelProperty:true,isDatePipe:true},
  ] as ListColumn[];

  @Input() coursesAndTrainingForm!:FormGroup;
  data:any[]=[];

  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyCourseTrainingsForm:any;

  @Input() isIndividualProfile:boolean=false;
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  constructor(private fb:FormBuilder){}
  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify(this.courseTrainings(0).value));
  }

  get moreInfos(){
    return this.coursesAndTrainingForm.controls['moreInfos'] as FormArray;
   }

   courseTrainings(i:number){
     return (this.moreInfos.at(i) as FormGroup).controls['courseTrainings'] as FormArray;
   }
 
   getMoreInfoFormGroup(i:number){
     return (this.moreInfos.at(i) as FormGroup);
   }
   addCourseTraining(){
    this.copyCourseTrainingsForm=JSON.parse(JSON.stringify(this.coursesAndTrainingForm.value));
    this.courseTrainings(0).push(
      
      this.fb.group({
        id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
        "title"  :new FormControl(null),
         "institution": new FormControl(null),
         "courseDate": new FormControl(null),
         // "courseYear": new FormControl(null),
         "summary": new FormControl(null),
       }),
    )
    this.showEditForm=true;
  }

  cancel(){
    
    this.courseTrainings(0).clear();
     if(this.copyCourseTrainingsForm?.moreInfos && this.copyCourseTrainingsForm.moreInfos.length>0&&
      this.copyCourseTrainingsForm.moreInfos[0]?.courseTrainings &&this.copyCourseTrainingsForm.moreInfos[0].courseTrainings.length>0
     ){
          for(let i=0;i<this.copyCourseTrainingsForm.moreInfos[0].courseTrainings.length;i++){
            this.courseTrainings(0).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "title"  :new FormControl(null),
              "institution": new FormControl(null),
              "courseDate": new FormControl(null),
              "summary": new FormControl(null),
            }));
          }
          this.courseTrainings(0).patchValue(this.copyCourseTrainingsForm.moreInfos[0].courseTrainings);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.courseTrainings(0).value));
  
  
  }

  deleteCourseTraining(i:number,j:number){
    this.courseTrainings(i).removeAt(j);
  }

   editCourseTrainings(){
    this.copyCourseTrainingsForm=JSON.parse(JSON.stringify(this.coursesAndTrainingForm.value));
    this.showEditForm=true;
  }
  update(){
 
    this.onUpdate.emit(120);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify(this.courseTrainings(0).value));
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-publications-profile',
  templateUrl: './publications-profile.component.html',
  styleUrls: ['./publications-profile.component.scss']
})
export class PublicationsProfileComponent {

  publicationColumns: ListColumn[] = [
    { name: 'Title', property: 'title', visible: true, isModelProperty: true ,isText: true},
    {name:'Url',property:'url',visible:true,isModelProperty:true,isLink:true},
    {name:'Published By',property:'publishedby',visible:true,isModelProperty:true,isText:true,},
    {name:'Publication Date',property:'publicationDate',visible:true,isModelProperty:true,isDatePipe:true},
    {name:'Author(s)',property:'authors',visible:true,isModelProperty:true,isText:true},
  ] as ListColumn[];

  @Input() publicationsForm!:FormGroup;

  data:any[]=[];

  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyPublicationsForm:any;
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  constructor(private fb:FormBuilder){}

 
  
  ngOnInit(): void {
   
   this.data=JSON.parse(JSON.stringify(this.publications(0).value));
   
  
  }

  get moreInfos(){
    return this.publicationsForm.controls['moreInfos'] as FormArray;
   }
 
   getMoreInfoFormGroup(i:number){
     return (this.moreInfos.at(i) as FormGroup);
   }
   
 
   publications(i:number){
     return this.getMoreInfoFormGroup(i).controls['publications'] as FormArray;
   }

   addPublication(){
    this.copyPublicationsForm=JSON.parse(JSON.stringify(this.publicationsForm.value));
    this.publications(0).push(
      
      this.fb.group({
        id:new FormControl(null),
        active:new FormControl(null),
        createdBy:new FormControl(null),
        createdOn:new FormControl(null),
        modifiedBy:new FormControl(null),
        modifiedOn:new FormControl(null),
        "title"  :new FormControl(null),
         "url":  ['',[Validators.pattern(/^(https:\/\/|www\.).*/)]],
         "publishedby": new FormControl(null),
         "publicationDate": new FormControl(null),
         "authors": new FormControl(null),
         "summary": new FormControl(null),
       }),
    )
    this.showEditForm=true;
  }

  editPublication(){
    this.copyPublicationsForm=JSON.parse(JSON.stringify(this.publicationsForm.value));
    this.showEditForm=true;
  }
  update(){
 
    this.onUpdate.emit(100);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify(this.publications(0).value));
  }

  cancel(){
    
    this.publications(0).clear();
     if(this.copyPublicationsForm?.moreInfos && this.copyPublicationsForm.moreInfos.length>0&&
      this.copyPublicationsForm.moreInfos[0]?.publications &&this.copyPublicationsForm.moreInfos[0].publications.length>0
     ){
          for(let i=0;i<this.copyPublicationsForm.moreInfos[0].publications.length;i++){
            this.publications(0).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "title"  :new FormControl(null),
         "url": new FormControl(null),
         "publishedby": new FormControl(null),
         "publicationDate": new FormControl(null),
         "authors": new FormControl(null),
         "summary": new FormControl(null),
            }));
          }
          this.publications(0).patchValue(this.copyPublicationsForm.moreInfos[0].publications);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.publications(0).value));
  
  
  }

  deletePublication(i:number,j:number){
    this.publications(i).removeAt(j);
  }

  selectedAction(event:any){
    switch (event.action) {
      case MweConstants.fileDownloadAction:
        window.open(event.row?.url,'_blank');
        break;
    
      default:
        break;
    }
  }
}

<div class="background">
    <app-website-header></app-website-header>
    <div class="row">
      <img src="../../../assets/images/landerpage.png" alt="landingpage" class="landingpageimage" />
    </div>
  
    <div class="mx-3 mt-2">
      <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="">
      <a class="breadcrummb ps-2" style="color:#858585 ;">Home</a>
      <a class="breadcrummb" style="color:#858585 ;">/</a>
      <a class="breadcrummb" style="color:#000000 ;">subscriptions</a>
    </div>
  
    <div class="row mt-3 p-4" style="background-color: #F5F5F5;">
      <div class="col-lg-9">  
        <div class="d-flex flex-row justify-content-between">
          <h1 style="border-bottom: 2px solid #0D87C8;  margin: 0; margin-left: .5rem; display: inline-block; color: #0D87C8; font-size: medium; font-weight: 00; font-family: 'work sans';">subscriptions</h1>
          <h1 style="margin: 0; margin-left: .5rem; display: inline-block; color: #1F1F1F; font-size: medium; font-weight: 00; font-family: 'work sans';">Available subscriptions: {{ subscriptions.length }} </h1>
        </div>     
          <h1 style="border: none; border-top: 1px solid #0D87C8;  width: 100%; margin: 0; margin-left: .5rem;"></h1>
        <div class="row">
            <div class="col-md-4" *ngFor="let subscription of subscriptions" style="max-height: 400px;">
                <div class="d-flex card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D; width: 360px; height: 374px;">
                    <div class="card-img">
                        <img [src]="subscription.image" width="300px" height="150px">
                    </div>
                    <div class="card-info p-2"> 
                        <mat-label class="name d-flex flex-row w-100 justify-content-center"> {{ subscription.name }} </mat-label>
                        <div class="info d-flex flex-column gap-1">
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/tick-mark-icon-bg-black.svg" width="13px" height="13px">
                                </div>
                                <div class="inner-info-data w-100"> {{ subscription.description | truncateText: 35 }}</div>
                            </div>
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/tick-mark-icon-bg-black.svg" width="13px" height="13px">
                                </div>
                                <div class="inner-info-data"> Montly Plan -  <span style="font-weight: 800; font-size:  14px; margin-left: 2px;">  {{ subscription.monthlyPrice }}/-</span> </div>
                            </div>
                            <div class="inner-info">
                                <div class="inner-info-icon">
                                    <img src="../../assets/images/icons/tick-mark-icon-bg-black.svg" width="13px" height="13px">
                                </div>
                                <div class="inner-info-data"> Annual Plan -  <span style="font-weight: 800; font-size:  14px; margin-left: 2px;">  {{ subscription.annualPrice }}/-</span> </div>
                            </div>
                            <div class="inner-info">
                              <div class="inner-info-icon">
                                  <img src="../../assets/images/icons/tick-mark-icon-bg-black.svg" width="13px" height="13px">
                              </div>
                              <div class="inner-info-data"> Trail days -  <span style="font-weight: 800; font-size:  14px; margin-left: 2px;">  {{ subscription.trialDays }}</span> </div>
                          </div>
                        </div>
                        <br>

                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- <div class="pagination-container">
        <app-pagination [itemsPerPage]="10" [data]="subscriptions" (pageChanged)="onPageChanged($event)"></app-pagination>
      </div> -->
    </div>
    <app-website-footer></app-website-footer>
  
    
 <div class="categories" *ngIf="!isviewCategories">
    <div class="d-flex justify-content-between align-items-center heading">
      <div class="d-flex">
          <button class="header-btn m-2 add-categories-btn" mat-raised-button (click)="openParentCategory()">+ Add</button> 
          <button class="header-btn m-2 add-categories-btn" mat-raised-button (click)="viewAllCategories()">View All</button>
      </div>
  </div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-label class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-label>
  </mat-tree-node>
  
  <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
    <button mat-icon-button disabled></button>
  </mat-tree-node>
  
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding> 
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-label [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-label>
    <button mat-icon-button (click)="addNewItem(node)"><mat-icon class="add-btn-1">add</mat-icon></button>
    <button mat-icon-button (click)="editItem(node)"><img src="assets/images/edit.svg"/></button>
    <button mat-icon-button (click)="deleteItem(node)"><img src="assets/images/bin.svg"/></button>
  </mat-tree-node> 
</mat-tree>


</div>

<ng-container *ngIf="isviewCategories">
  <app-view-category [dataSource]="dataSource" [treeControl]="treeControl" [categoryData] ="categoryData"   [isViewCategories]="isviewCategories" (isViewCategoriesChange)="onViewCategoriesChanged($event)" ></app-view-category>
</ng-container>


<div class="categories">
  <button class="header-btn m-2 add-categories-btn" mat-raised-button (click)="onClose()">Back</button>


  <div style="color: green; display: flex; align-items: center;">
    <span style="font-size: 20px;font-weight: 500; margin: 10px;">Categories Tree</span> <!-- Bullet point -->
    <span style="flex-grow: 1; text-align: right;"> • Last level categories which user can add  products are shown in green color</span>
    <mat-icon style="cursor: pointer;"></mat-icon>
  </div>
  

  <div class="tree">
    <ul>
      <ng-container *ngFor="let node of dataSource.data">
        <li>
          <span [ngClass]="{'tree-node-label': hasChildren(node), 'checklist-leaf-node': !hasChildren(node)}">
            {{ node.item }}
          </span>

          <!-- Recursive template for children -->
          <ul *ngIf="node.children && node.children.length > 0">
            <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: node.children }"></ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>

  <!-- Recursive Template -->
  <ng-template #recursiveTree let-nodes>
    <ng-container *ngFor="let childNode of nodes">
      <li>
        <span [ngClass]="{'tree-node-label': hasChildren(childNode), 'checklist-leaf-node': !hasChildren(childNode)}">
          {{ childNode.item }}
        </span>

        <!-- Recurse if the node has children -->
        <ul *ngIf="childNode.children && childNode.children.length > 0">
          <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: childNode.children }"></ng-container>
        </ul>
      </li>
    </ng-container>
  </ng-template>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notifications-pop-up',
  templateUrl: './notifications-pop-up.component.html',
  styleUrls: ['./notifications-pop-up.component.scss']
})
export class NotificationsPopUpComponent {
  notifications: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { notifications: any[] },
    private dialogRef: MatDialogRef<NotificationsPopUpComponent>
  ) {
    // Assign the passed notifications to the component
    this.notifications = data.notifications;
  }

  // Method to close the dialog
  closeDialog() {
    this.dialogRef.close();
  }
}

<app-personal-infomation  [personalForm]="hospitalForm" (onUpdate)="onUpdate($event)"></app-personal-infomation>
<app-profile-about [aboutYouForm]="hospitalForm" [endCustomerData]="endCustomerData" [showSpecialisationTypes]="true" (onUpdate)="onUpdate($event)"></app-profile-about>
<app-specialitiess [specialityForm]="hospitalForm" [endCustomerData]="endCustomerData"  (onUpdate)="onUpdate($event)"></app-specialitiess>
<app-member-profile [membersForm]="hospitalForm" (onUpdate)="onUpdate($event)"></app-member-profile>
<app-infra-profile [infraStructureForm]="hospitalForm" [endCustomerData]="endCustomerData"  (onUpdate)="onUpdate($event)"></app-infra-profile>
<app-equipments [equipmentForm]="hospitalForm" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)" ></app-equipments>
<app-services-profile [servicesForm]="hospitalForm" [endCustomerData]="endCustomerData"  (onUpdate)="onUpdate($event)"></app-services-profile>
<app-certification-profile [certificationsForm]="hospitalForm" [endCustomerData]="endCustomerData"  (onUpdate)="onUpdate($event)"></app-certification-profile>
<app-more-info-profile [moreInfos]="hospitalForm" [endCustomerData]="endCustomerData" (onUpdateMoreInfo)="onUpdate($event)"></app-more-info-profile>
<app-photos-profile [photoForm]="hospitalForm" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"></app-photos-profile>

import { Component, Input, OnInit ,ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { MasterDataService } from 'src/app/services/master-data.service';
import { concatMap, takeWhile } from 'rxjs';
 import { forkJoin } from 'rxjs';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-oem-point-of-contacts',
  templateUrl: './oem-point-of-contacts.component.html',
  styleUrls: ['./oem-point-of-contacts.component.scss']
})
export class OemPointOfContactsComponent {
  @Input() pointOfContacts!: FormGroup;
  @Output() contactsAdded = new EventEmitter<number>();

columns: ListColumn[] = [
  { name: 'Sr.no', property: 'sNo', visible: true, isModelProperty: false },
  { name: 'Continent', property: 'continent', visible: true, isModelProperty: true, isText: true },
  { name: 'Country', property: 'country', visible: true, isModelProperty: true, isText: true },
  { name: 'State', property: 'state', visible: true, isModelProperty: true, isText: true },
  { name: 'City', property: 'city', visible: true, isModelProperty: true, isText: true },
  { name: 'Name', property: 'employeeName', visible: true, isModelProperty: true, isText: true },
  { name: 'Position', property: 'employeePosition', visible: true, isModelProperty: true, isText: true },
  { name: 'Email', property: 'email', visible: true, isModelProperty: true, isText: true },
  { name: 'Phone Number', property: 'phone', visible: true, isModelProperty: true, isText: true },
  {
    name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
    actions: [
      { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
      { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
    ]
  }
] as ListColumn[];

data: any[] = [];
contactsForm!: FormGroup;
actionType: any;
index: number = 0;
continents!: any[];
countries!: any[];
states!: any[];
isMore: boolean = false;
isProceed: boolean = false;
cities!: any[];

constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef,
  private sweetAlertService: SweetAlertService, private masterDataService: MasterDataService,private spinnerService: SpinnerService) {}

ngOnInit(): void {
  this.initializeContactsForm();
  this.data = this.pointOfContactArray().value;
  if (this.data.length > 0) {
      this.isMore = true;
  }
  this.loadMasterData();
}

private async loadMasterData() {
  this.spinnerService.showSpinner();
    
  try {
      const results = await forkJoin({
          continents: this.masterDataService.getContinents(),
         
      }).toPromise();

      this.continents = results?.continents.data;
      

      this.setFormValues();
      this.spinnerService.hideSpinner();
  } catch (error) {
      console.error('Error fetching master data:', error);
      this.spinnerService.hideSpinner();
  } 
 
}

private async setFormValues() {
  const lead = this.contactsForm?.value
  if (lead) {
      await this.loadCountries([lead.continent]);
      await this.loadStates([lead.country]);
      await this.loadCities([lead.state]);
  }
}


initializeContactsForm() {
  this.contactsForm = this.fb.group({
    id: new FormControl(''),
    oemId: new FormControl(''),
    address: new FormControl(''),
    positionId: new FormControl(''),
    employeeName: new FormControl(''),
    employeePosition: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    continent: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    distributorId: new FormControl(''),
    active: new FormControl(true),
    createdBy: new FormControl(''),
    createdOn: new FormControl(''),
    modifiedBy: new FormControl(''),
    modifiedOn: new FormControl(''),
  });
}

toggleIsProceed() {
  this.isProceed = true;
  this.actionType=null;
  this.contactsForm.reset();
  this.cdr.detectChanges();
}

toggleCancel(){
  this.isProceed = false;
}


addBranchInformation() {
 
  if (!this.areAllPropertiesNull(this.contactsForm.value) && this.contactsForm.valid) {
    if (this.actionType === MweConstants.editAction) {
      this.pointOfContactArray().at(this.index).patchValue(JSON.parse(JSON.stringify(this.contactsForm.value)));
      this.contactsAdded.emit(2);
    } else {
      
      this.pointOfContactArray().push(this.fb.group({
        id: new FormControl(this.contactsForm.get('id')?.value || ''),
        oemId: new FormControl(this.contactsForm.get('oemId')?.value),
        address: new FormControl(this.contactsForm.get('address')?.value),
        positionId: new FormControl(this.contactsForm.get('positionId')?.value),
        employeeName: new FormControl(this.contactsForm.get('employeeName')?.value),
        employeePosition: new FormControl(this.contactsForm.get('employeePosition')?.value),
        phone: new FormControl(this.contactsForm.get('phone')?.value),
        email: new FormControl(this.contactsForm.get('email')?.value),
        continent: new FormControl(this.contactsForm.get('continent')?.value),
        country: new FormControl(this.contactsForm.get('country')?.value),
        state: new FormControl(this.contactsForm.get('state')?.value),
        city: new FormControl(this.contactsForm.get('city')?.value),
        distributorId: new FormControl(this.contactsForm.get('distributorId')?.value),
        active: new FormControl(this.contactsForm.get('active')?.value),
        createdBy: new FormControl(this.contactsForm.get('createdBy')?.value),
        createdOn: new FormControl(this.contactsForm.get('createdOn')?.value),
        modifiedBy: new FormControl(this.contactsForm.get('modifiedBy')?.value),
        modifiedOn: new FormControl(this.contactsForm.get('modifiedOn')?.value),
      }));
      this.contactsAdded.emit(1);
    }
    
    this.isMore = true;
    this.actionType = null;
    this.index = 0;
    this.isProceed = false;
    this.removeValidators();
    this.contactsForm.reset();
    this.cdr.detectChanges();
    this.fun();
  
    
  }
}

fun(){
  this.data = this.pointOfContacts.get('addedPOCs')?.value;
}


areAllPropertiesNull(obj: any): boolean {
  const requiredFields = ['phone', 'employeeName', 'employeePosition', 'email', 'continent', 'country', 'state', 'city'];
  for (const key of requiredFields) {
    const value = obj[key];
    if (value == null || (typeof value === 'string' && value.trim() === '')) {
      this.sweetAlertService.showError('Error', 'Please fill all required fields.');
      this.showValidators();
      return true;
    }
  }

  return false; 
}

removeValidators() {
  this.contactsForm.get('phone')?.clearValidators();
  this.contactsForm.get('employeeName')?.clearValidators();
  this.contactsForm.get('employeePosition')?.clearValidators();
  this.contactsForm.get('email')?.clearValidators(); 
  this.contactsForm.get('continent')?.clearValidators();
  this.contactsForm.get('country')?.clearValidators();
  this.contactsForm.get('state')?.clearValidators();
  this.contactsForm.get('city')?.clearValidators();

  this.contactsForm.get('phone')?.updateValueAndValidity();
  this.contactsForm.get('employeeName')?.updateValueAndValidity();
  this.contactsForm.get('employeePosition')?.updateValueAndValidity();
  this.contactsForm.get('email')?.updateValueAndValidity();
  this.contactsForm.get('continent')?.updateValueAndValidity();
  this.contactsForm.get('country')?.updateValueAndValidity();
  this.contactsForm.get('state')?.updateValueAndValidity();
  this.contactsForm.get('city')?.updateValueAndValidity();
}


showValidators() {
  this.contactsForm.get('phone')?.setValidators([Validators.required]);
  this.contactsForm.get('employeeName')?.setValidators([Validators.required]);
  this.contactsForm.get('employeePosition')?.setValidators([Validators.required]);
  this.contactsForm.get('email')?.setValidators([Validators.required, Validators.email]);
  this.contactsForm.get('continent')?.setValidators([Validators.required]);
  this.contactsForm.get('country')?.setValidators([Validators.required]);
  this.contactsForm.get('state')?.setValidators([Validators.required]);
  this.contactsForm.get('city')?.setValidators([Validators.required]);


  this.contactsForm.get('phone')?.updateValueAndValidity();
  this.contactsForm.get('employeeName')?.updateValueAndValidity();
  this.contactsForm.get('employeePosition')?.updateValueAndValidity();
  this.contactsForm.get('email')?.updateValueAndValidity();
  this.contactsForm.get('continent')?.updateValueAndValidity();
  this.contactsForm.get('country')?.updateValueAndValidity();
  this.contactsForm.get('state')?.updateValueAndValidity();
  this.contactsForm.get('city')?.updateValueAndValidity();
}
 

selectedAction(event: any) {
  
  this.index = this.data.findIndex(item => item.id === event.row.id);
  if (this.index === -1) return; 
  switch (event.action) {
    case MweConstants.editAction:
      this.actionType = MweConstants.editAction;
      this.isProceed = true;
      this.contactsForm.patchValue(this.pointOfContacts.get('addedPOCs')?.value[this.index]);
      this.loadCountries([event.row.continent])
      .then(() => {
        this.spinnerService.showSpinner();
        if (event.row.country) {
          this.contactsForm.get('country')?.setValue(event.row.country);
          return this.loadStates([event.row.country]);
        }
        return Promise.resolve(); 
      })
      .then(() => {
        if (event.row.state) {
          this.contactsForm.get('state')?.setValue(event.row.state);
          return this.loadCities([event.row.state]);
        }
        return Promise.resolve(); 
      })
      .then(() => {
        if (event.row.city) {
          this.contactsForm.get('city')?.setValue(event.row.city);
        }
        this.spinnerService.hideSpinner();
      })
      .catch(error => {
        console.error('Error loading dependent data:', error);
        this.spinnerService.hideSpinner();
      });
      

      break;
    case MweConstants.deleteAction:
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.contactsForm.reset();
          this.actionType = null;
          this.addDeletedPOC(this.index);
          this.pointOfContactArray().removeAt(this.index);
          this.data = this.pointOfContactArray().value;
          if (this.data.length == 0){
            this.isMore = false;
          }
          this.index = 0;
          this.contactsAdded.emit(3);
          this.cdr.detectChanges();
        }
        else{
          Swal.fire('Cancelled', 'Record is safe :)', 'error');
        }
      });
      break;
    default:
      break;
  }
}

pointOfContactArray() {
  return this.pointOfContacts.get('addedPOCs') as FormArray;
}
deletedPOCsArray() {
  return this.pointOfContacts.get('deletedPOCs') as FormArray;
}
numberOnly(event: any): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
}

onlyText(event: any): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 32 || charCode === 32) {
    return true;
  }
  return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
}

addDeletedPOC(index : number): void {
 
  const deletedPOCs = this.pointOfContacts.get('deletedPOCs') as FormArray;
  const pointOfContactValue = this.pointOfContactArray().at(index).value;
  const ids = pointOfContactValue.id;
  deletedPOCs.push(new FormControl(ids));
}

loadContinents(): Promise<void> {
  return new Promise((resolve, reject) => {
    this.masterDataService.getContinents().subscribe(data => {
      this.continents = data.data;
      resolve();
    }, error => {
      reject(error);
    });
  });
}

loadCountries(continentNames: string[]): Promise<void> {
  return new Promise((resolve, reject) => {
    let pageNumber = 0;
    const pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames);
        })
      )
      .subscribe(
        data => {
          if (data.data.length === 0) {
            morePages = false;
          } else {
            allCountries = allCountries.concat(data.data);
          }
          this.countries = allCountries;
          resolve();
        },
        error => {
          reject(error);
        },
        () => {
          resolve();
        }
      );
  });
}



loadStates(countryNames: string[]): Promise<void> {
  return new Promise((resolve, reject) => {
    let pageNumber = 0;
    const pageSize = 500;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', countryNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', countryNames);
        })
      )
      .subscribe(
        data => {
          if (data.data.length === 0) {
            morePages = false;
          } else {
            allStates = allStates.concat(data.data);
          }
          this.states = allStates;
          resolve();
        },
        error => {
          reject(error);
        },
        () => {
          resolve();
        }
      );
  });
}


loadCities(stateNames: string[]): Promise<void> {
  return new Promise((resolve, reject) => {
    let pageNumber = 0;
    const pageSize = 500;
    let allCities: any[] = [];
    let morePages = true;

    this.masterDataService.getCities(pageNumber, pageSize, '', stateNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', stateNames);
        })
      )
      .subscribe(
        data => {
          if (data.data.length === 0) {
            morePages = false;
          } else {
            allCities = allCities.concat(data.data);
          }
          this.cities = allCities;
          resolve();
        },
        error => {
          reject(error);
        },
        () => {
          resolve();
        }
      );
  });
}



onContinentChange(event: any) {
  const continentName = event?.value;
  if (continentName) {
    this.loadCountries([continentName]).then(() => {
      // Set default or existing value if needed
      const countryName = this.contactsForm.get('country')?.value;
      if (countryName) {
        this.loadStates([countryName]);
      }
    });
  }
}

onCountryChange(event: any) {
  const countryName = event?.value;
  if (countryName) {
    this.loadStates([countryName]).then(() => {
      // Set default or existing value if needed
      const stateName = this.contactsForm.get('state')?.value;
      if (stateName) {
        this.loadCities([stateName]);
      }
    });
  }
}

onStateChange(event: any) {
  const stateName = event?.value;
  if (stateName) {
    this.loadCities([stateName]);
  }
}

onCityChange(event: any) {
  
}
}

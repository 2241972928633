import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OEM_ID } from 'src/app/utils/constants';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { ViewChild, ElementRef } from '@angular/core';
import { PopupVisibityService } from 'src/app/services/shared/popup-visibity.service';


@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
 @Input() isPopUpVisible!: boolean 
 isCertificatePopUpVisible: boolean=false;
 @Output() closePopUpEvent = new EventEmitter<void>();
  selectedTab: number = 0;
  showTabs: boolean = true;
  companyForm!: FormGroup;
  isSave:boolean=true;
  isNotGet:boolean=false;
  isDelete:boolean=false;
  companyData:any
  oemId:string='';
  name:string='';
  remarks:string='';
  isProceed:boolean=false;
  @Output() selectedTabEvent = new EventEmitter<number>();
  @ViewChild('tabGroup', { static: false }) tabGroup!: ElementRef;
  status: string | undefined;

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private http: HttpClient ,
     private localStorageService: LocalStorageService,private sweetAlertService: SweetAlertService,
      private spinnerService: SpinnerService,private oemService:OemService
    ) {}

  ngOnInit(): void {
    if(this.isPopUpVisible){
      this.isCertificatePopUpVisible = true;
    }
  
    this.scrollToSelectedTab();
    this.initializeCompanyForm();
    const id = this.localStorageService.getItem(OEM_ID);
    this.oemId = id;
    this.localStorageService.getUserDetails().subscribe(data => {
      this.name = data.userName;
      this.status = data.status;
      
    });
    this.spinnerService.showSpinner();
    this.update(id); 
    this.spinnerService.hideSpinner();
    if (localStorage.getItem('isPopupVisible')) {
      this.isPopUpVisible = localStorage.getItem('isPopupVisible') === 'true' ? true : false;
      console.log("this.isPopUpVisible",this.isPopUpVisible);
      
    } 
    
  }

  get firstName(): string {
    return this.name.split(' ')[0];
  }

  proceed() {
    this.isProceed = true;
  }
  

  submitForApproval(): void {
    this.isPopUpVisible = false;
    this.closePopUpEvent.emit();
     
  }

  informationFields = [
    { name: 'userName', label: 'User Name' },
    { name: 'email', label: 'Email' },
    { name: 'uniqueId', label: 'Unique ID' },
    { name: 'name', label: 'Name' },
    {name:'phoneNumber',label:'Phone Number'},
    {name:'brandName',label:'Brand Name'},
    {name:'crcopy',label:'CR Copy'},
    {name:'cecopy',label:'CE Copy'},
    {name:'businessLicense',label:'Business License'},
    { name: 'year', label: 'Year' },
    { name: 'url', label: 'URL' },
    { name: 'employeeCount', label: 'Employee Count' },
    { name: 'continent', label: 'Continent' },
    { name: 'country', label: 'Country' },
    { name: 'state', label: 'State' },
    { name: 'city', label: 'City' },
    { name: 'address', label: 'Address' },
    { name: 'zipCode', label: 'Zip Code' }
  ];

  validateInformationFields(): boolean {
    let isValid = true;
    let allFieldsFilled = true;
  
    this.informationFields.forEach(field => {
      const control = this.companyForm.get(`companyInformation.${field.name}`);
  
      if (this.isPopUpVisible) {
        if (this.selectedTab === 0 && 
            !(field.name === 'crcopy' || field.name === 'cecopy' || field.name === 'businessLicense')) {
              if (control && (control.value === null || control.value === '')) {
                isValid = false;
                allFieldsFilled = false;
                control.markAsTouched();
                control.setErrors({ required: true });
              }
        }
        if (this.selectedTab === 1) {
          if (control && (control.value === null || control.value === '')) {
            isValid = false;
            allFieldsFilled = false;
            control.markAsTouched();
            control.setErrors({ required: true });
          }
        }
      } else {

        if (this.selectedTab === 0) {
          if (control && (control.value === null || control.value === '')) {
            isValid = false;
            allFieldsFilled = false;
            control.markAsTouched();
            control.setErrors({ required: true });
          }
        }
      }
    });
  
    // Additional check for logo
    const logoControl = this.companyForm.get('companyInformation.logo');
    const isLogoUploaded = logoControl && logoControl.value;
  
    if (!allFieldsFilled || (!isLogoUploaded && this.selectedTab === 0)) {
      isValid = false;
      if (!isLogoUploaded && allFieldsFilled) {
        this.sweetAlertService.showError('Error', 'Please upload logo.');
      } else {
        this.sweetAlertService.showError('Validation Error', 'Please fill in all required fields.');
      }
    }
  
    return isValid;
  }
  
  

  update(id?: string) {
    if (!id) return;
  
    this.spinnerService.showSpinner();
    this.oemService.getOemWithManagementId(id).subscribe(
      data => {
        if (data?.data) {
          this.companyData = data.data;
          this.remarks=this.companyData.companyInformation.remarks
          if (this.companyData.companyInformation.status !== this.status) {
            this.localStorageService.getUserDetails().subscribe(userDetails => {
              userDetails.status = this.companyData.companyInformation.status;
              this.status = this.companyData.companyInformation.status;
              this.localStorageService.updateUserDetails(userDetails);
            });
            if (this.status === 'InComplete') {
              this.isPopUpVisible = true;
              localStorage.setItem('isPopupVisible', 'true');
            
              
            } 
          }
          this.companyForm.patchValue(this.companyData);
          this.companyForm.get('companyInformation')?.get('distributors')?.setValue(true);
          this.updateBranches();
          this.updateProductCategorys();
          this.updatedistributorsNetwork();
          this.updatefinancials();
          this.updatePointOfContacts();
          this.updatePhotos();
          this.updateVideo();
        }
        this.spinnerService.hideSpinner();
      },
      error => {
        this.spinnerService.hideSpinner();
        console.error('Error:', error);
      }
    );
  }
  updatePhotos() {
    
    if (this.companyData?.photos && this.companyData.photos.length > 0) {
      
      const photosFormArray = this.companyForm.get('photos') as FormArray;
      photosFormArray.clear();
      this.companyData.photos.forEach((photo: string) => {
        photosFormArray.push(this.fb.control(photo));
      });
      
    }
    
  }
  
  

  updateVideo() {
    if (this.companyData?.videos && this.companyData.videos.length > 0) {
      
      const videoArray = this.companyForm.get('videos') as FormArray;
      videoArray.clear();
      this.companyData.videos.forEach((video: string) => {
        videoArray.push(this.fb.control(video));
      })
    }
 
  }
updatePointOfContacts() {
  if (this.companyData?.pointOfContactWithOEMManagement?.addedPOCs && this.companyData.pointOfContactWithOEMManagement.addedPOCs.length > 0) {
    const pointOfContacts = this.companyData.pointOfContactWithOEMManagement.addedPOCs.map((obj: any) => {
      return this.fb.group({
        id: new FormControl(obj.id),
        oemId: new FormControl(obj.oemId),
        address: new FormControl(obj.address),
        positionId: new FormControl(obj.positionId),
        employeeName: new FormControl(obj.employeeName),
        employeePosition: new FormControl(obj.employeePosition),
        phone: new FormControl(obj.phone),
        email: new FormControl(obj.email),
        continent: new FormControl(obj.continent),
        country: new FormControl(obj.country),
        state: new FormControl(obj.state),
        city: new FormControl(obj.city),
        distributorId: new FormControl(obj.distributorId),
        active: new FormControl(obj.active),
        createdBy: new FormControl(obj.createdBy),
        createdOn: new FormControl(obj.createdOn),
        modifiedBy: new FormControl(obj.modifiedBy),
        modifiedOn: new FormControl(obj.modifiedOn),
       
      });
    });

    const pointOfContactsFormArray = this.companyForm.get('pointOfContactWithOEMManagement')?.get('addedPOCs') as FormArray;
    if (pointOfContactsFormArray) {
      pointOfContactsFormArray.clear();
      pointOfContacts.forEach((pointOfContact: FormGroup) => {
        pointOfContactsFormArray.push(pointOfContact);
      });
    }
  }
}

updatefinancials() {
  if (this.companyData?.financialInformation && this.companyData.financialInformation.length > 0) {
    const financialInformation = this.companyData.financialInformation.map((obj: any) => {
      return this.fb.group({
        
        oemId: new FormControl('3d5325c9-0389-4285-b9a6-9d91f8b43480'),
        financialInfoId: new FormControl(obj.financialInfoId),
        financialYear: new FormControl(obj.financialYear),
        currency: new FormControl(obj.currency),
        netWorth: new FormControl(obj.netWorth),
        turnover: new FormControl(obj.turnover),
        profit: new FormControl(obj.profit),
        yearOfGrowth: new FormControl(obj.yearOfGrowth),
      });
    });

    const financialsFormArray = this.companyForm.get('financialInformation') as FormArray;
    financialsFormArray.clear();
    financialInformation.forEach((financial: FormGroup) => financialsFormArray.push(financial));
  }
}

updateProductCategorys() {
  if (this.companyData?.productCategories && this.companyData.productCategories.length > 0) {
    const productCategories = this.companyData.productCategories.map((category: string) => {
      return this.fb.control(category);
    });

    const productCategoriesFormArray = this.companyForm.get('productCategories') as FormArray;
    productCategoriesFormArray.clear();
    productCategories.forEach((category: FormControl) => productCategoriesFormArray.push(category));
    console.log(productCategoriesFormArray.value);
    console.log(this.companyForm.get('productCategories')?.value);
  }
}
  updateBranches() {
    if (this.companyData?.branches && this.companyData.branches.length > 0) {
      const branches = this.companyData.branches.map((obj: any) => {
        return this.fb.group({
          branchId: new FormControl(obj.branchId),          
          oemId: new FormControl(obj.oemId),
          brandName: new FormControl(obj.brandName),
          address: new FormControl(obj.address),
          contactPerson: new FormControl(obj.contactPerson),
          contactPhone: new FormControl(obj.contactPhone),
          contactEmail: new FormControl(obj.contactEmail),
        });
      });

      const branchesFormArray = this.companyForm.get('branches') as FormArray;
      branchesFormArray.clear();
      branches.forEach((branch: FormGroup) => branchesFormArray.push(branch));
    }
  }
  updatedistributorsNetwork() {
    console.log(this.companyData);

    if (this.companyData?.distributorNetwork) {
        const distributorNetwork = this.companyData.distributorNetwork;
        if (distributorNetwork.selectedIds && distributorNetwork.selectedIds.length > 0) {
            const selectedIdsFormArray = this.companyForm.get('distributorNetwork.selectedIds') as FormArray;
            selectedIdsFormArray.clear();
            distributorNetwork.selectedIds.forEach((obj: any) => {
                selectedIdsFormArray.push(this.fb.control(obj));
            });
        }
        if (distributorNetwork.removedIds && distributorNetwork.removedIds.length > 0) {
            const removedIdsFormArray = this.companyForm.get('distributorNetwork.removedIds') as FormArray;
            removedIdsFormArray.clear();
            distributorNetwork.removedIds.forEach((obj: any) => {
                removedIdsFormArray.push(this.fb.control(obj));
            });
        }
    }
}

  initializeCompanyForm() {
    this.companyForm = this.fb.group({
      oemId: ['', Validators.required],
      companyInformation: this.fb.group({
        userName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        oemId: ['', Validators.required],
        phoneNumber: ['', Validators.required],
        phoneCode: ['', Validators.required],
        uniqueId: ['', Validators.required],
        year: [0, Validators.required],
        name: ['', Validators.required],
        status: ['', Validators.required],
        employeeCount: [0, Validators.required],
        continent: ['', Validators.required],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
        address: ['', Validators.required],
        zipCode: ['', Validators.required],
        logo: ['', Validators.required],
        url: ['', Validators.required],
        brandName: ['', Validators.required],
        crcopy: ['', Validators.required],
        businessLicense: ['', Validators.required],
        cecopy: ['', Validators.required],
        otherFiles: ['', Validators.required],
        europeanCertificate: ['', Validators.required],
        subscriptionInfoId: ['', Validators.required],
        distributors: [true, Validators.required],
        description : ['', Validators.required],
        branchesPresent: [true, Validators.required],
      }),
      branches: this.fb.array([]),
      financialInformation: this.fb.array([]),
      productCategories: new FormArray([]),
      distributorNetwork: this.fb.group({
        selectedIds: this.fb.array([]),
        removedIds: this.fb.array([]),
      }),
      pointOfContactWithOEMManagement: this.fb.group({
        addedPOCs: this.fb.array([]),
        deletedPOCs: this.fb.array([]),
      }),

      photos: this.fb.array([]),
      videos: this.fb.array([]),
    });
  }
 
  get pointOfContacts() {
    return this.companyForm.get('pointOfContactWithOEMManagement') as FormGroup;
  }
  get productCategorys() {
    return this.companyForm.get('productCategories') as FormArray;
  }
  get distributorNetwork() {
    return this.companyForm.get('distributorNetwork') as FormGroup;
  }
  handleBranchAdded(event: number) {
    if (event === 1) {
      this.isSave=true;
      this.isNotGet=false;
      this.isDelete=false;
      this.save();
    }
    else if (event === 2) {
      this.isNotGet=true;
      this.isSave=false;
      this.isDelete=false;
      this.save();
    }
    else if(event === 3){
      this.isNotGet=true;
      this.isDelete=true;
      this.save();
    }
  }
  

  save() {
    if(this.validateInformationFields()){
      const oemId = this.localStorageService.getItem(OEM_ID)
  
      const branches = this.companyForm.get('branches') as FormArray;
      branches.controls.forEach((control: AbstractControl) => {
        const branch = control as FormGroup;
        branch.get('oemId')?.setValue(oemId);
      });
    
  
      const financials = this.companyForm.get('financialInformation') as FormArray;
      financials.controls.forEach((control: AbstractControl) => {
        const financial = control as FormGroup;
        financial.get('oemId')?.setValue(oemId);
      });
    
      
      const pointOfContacts = this.companyForm.get('pointOfContactWithOEMManagement.addedPOCs') as FormArray;
      pointOfContacts.controls.forEach((control: AbstractControl) => {
        const pointOfContact = control as FormGroup;
        pointOfContact.get('oemId')?.setValue(oemId);
      });
    
      this.oemService.oemWithManagementUpdate(this.companyForm.value)
        .subscribe(
          (data) => {
            if(this.isDelete){
              this.sweetAlertService.showSuccess('', 'Deleted successfully !!')
              this.isDelete=false
            }
           else if(!this.isSave){
            this.sweetAlertService.showSuccess('', 'Updated successfully !!')
            this.isSave=true
           }
           else{
            this.sweetAlertService.showSuccess('', 'Saved successfully !!')
           }

            if(!this.isNotGet){
              this.spinnerService.showSpinner();
            this.update(this.oemId);
            this.spinnerService.hideSpinner();

            }
            
          },
            
          (error) =>   
            this.sweetAlertService.showError('', 'Failed to update !!'),
        );
      }
    
   
  }
  changeTab(index: number) {
    if(this.validateInformationFields()){
      this.selectedTab = index;
   
     }
     this.scrollToSelectedTab();
    
  }

  private scrollToSelectedTab() {
    setTimeout(() => {
      const tabs = this.tabGroup.nativeElement?.querySelectorAll('.tab');
      if (tabs[this.selectedTab]) {
        tabs[this.selectedTab].scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }, 0);
  }

  navigateNext() {
    const maxTabIndex = 7;
    if (this.selectedTab < maxTabIndex && this.validateInformationFields()) {
      this.selectedTab++;
      this.selectedTabEvent.emit(this.selectedTab);
    }
    this.scrollToSelectedTab();
  }

  updateProductCategories(categories: any[]) {
    const productCategories = this.fb.array(categories.map(category => this.fb.control(category)));
    this.companyForm.setControl('productCategories', productCategories);
  }
  onUpdateEvent() {
    console.log(this.companyForm.value);
    
    this.save();
  }
}

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  @Input() data : any;
  showNoRecordsFounds: boolean = false;
  itemsPerPage = 9;
  products: any[] = [];
  originalData: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oemService : OemService,
    private allProductService: AllProductService
) { }

  ngOnInit(): void {
    console.log('product this.data', this.data);
    
    this.getAllProductsById(this.data.id);
  }

  getAllProductsById(brandId: string) {
    this.allProductService.getAllProductsByOemId(brandId).subscribe({
      next: (response) => {
       if(response.data.length == 0){ 
        this.showNoRecordsFounds = true
       } else {
        this.showNoRecordsFounds = false
        this.products = response.data; 
        this.originalData  =response.data;
        this.paginatedItems = this.products.slice(0, this.itemsPerPage);
       }
      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
    });
  }

  paginatedItems: { images: string[], name: string, oemName: string, description: string, tags: string[]  }[] = [];

  onPageChanged(paginatedData: any[]): void {
    this.paginatedItems = paginatedData;
  }

  filteredData = this.products;

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.products = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
    console.log('Received filtered data:', filtered);
  }

  onViewDetails(product: any) {
    this.router.navigateByUrl(`/product-details/${product.id}`);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Lead } from 'src/app/models/lead-model';
import { apiUrls } from 'src/app/utils/apiUrls'; // Ensure you have the correct path to apiUrls

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  constructor(private http: HttpClient) { }

  public addLead(leadData: any): Observable<any> {
    console.log('Posting to URL:', apiUrls.salesLeads); // Add this line to log the URL
    return this.http.post<any>(apiUrls.salesLeads, leadData);
  }
  public getLeadSummary(): Observable<any> {
    return this.http.get<any>(apiUrls.leadSummary);
  }

  public updateLead(leadData: any): Observable<any> {
    console.log('Getting from URL:', apiUrls.salesLeadss); // Add this line to log the URL
    return this.http.put(apiUrls.salesLeadss, leadData);
  }

  public getPrimaryCategory(): Observable<any> {
    return this.http.get<any>(apiUrls.primaryCategory);
  }
  public  createAccount(leadId: string):Observable<any> {
    return this.http.get<any>(apiUrls.createAccount + leadId);
  }

  public getSecondaryCategory(): Observable<any> {
    return this.http.get<any>(apiUrls.secondaryCategory);
  }

  // Future methods for product range and product type
  public getTertiaryCategory(): Observable<any> {
    return this.http.get<any>(apiUrls.tertiaryCategory);
  }

  public getLeadById(leadId: string): Observable<any> {
    return this.http.get<any>(apiUrls.salesLeadss + leadId);
    console.log('Getting from URL:', apiUrls.salesLeadss + leadId ); // Add this line to log the URL
    
  }

  public getProductRange(): Observable<any> {
    return this.http.get<any>(apiUrls.productRange);
  }

  public getLeads(): Observable<Lead[]> {
    console.log('Getting from URL:', apiUrls.salesLeads); // Add this line to log the URL
    return this.http.get<Lead[]>(apiUrls.salesLeads).pipe(map((res: any) =>  res?.data));
  }
  public sendTableLeads(leadData:any,page?: number, size?: number,contains?: string): Observable<Lead[]> {
    const params = new HttpParams()
      .set('page', page? page.toString() : '0')
      .set('size', size ? size.toString(): '10')
      .set('contains', contains ? contains.toString() : '');


      return this.http.post<Lead[]>(apiUrls.salesTableLeads, leadData, { params }).pipe(
        map((res: any) => res?.data)
      );
   
   
  
  }

  public getLead(leadId: string): Observable<Lead[]> {
    console.log('Getting from URL:', apiUrls.salesLeadss); // Add this line to log the URL
    return this.http.get<Lead[]>(apiUrls.salesLeadss + leadId).pipe(map((res: any) =>  res?.data));
  }

  public deleteLead(leadId: string): Observable<any> {
    console.log('Getting from URL:', apiUrls.salesLeadss + leadId ); // Add this line to log the URL
    return this.http.delete(apiUrls.salesLeadss + leadId);
  }

}

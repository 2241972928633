import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogService } from 'src/app/services/shared/catalog.service';

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.scss']
})
export class CatalogsComponent {
  @Input() data : any;
  @Input() filterColumns: any;
  itemsPerPage = 12;
  products: any[] = [];
  originalData: any[] = [];
  showNoRecordsFounds: boolean = false;
  newData : any[] = [];

  constructor(
    private route: ActivatedRoute,
    private catalogService: CatalogService
  ) {
  }

  ngOnInit(): void {
    this.getCatalogByOemId(this.data.id);
    console.log('msg from dupli', this.data);
    
  }

  paginatedItems: { catalog: string, title: string, type: string }[] = [];

  getCatalogByOemId(brandId: string) {
    this.catalogService.getCatalogs(brandId).subscribe({
      next: (response) => {
        if(response === null || response.data === null) {
          this.products = [];
        } else {
          this.newData = response.data.map((item: { catalog: string; }) => ({
            ...item,
            type: this.getFileType(item.catalog)
          }));
          console.log('response.data calalogs', this.newData);
          this.products = this.newData; 
          this.originalData  = this.newData;
          this.paginatedItems = this.products.slice(0, this.itemsPerPage);
        }
        this.paginatedItems = this.products.slice(0, this.itemsPerPage);
      console.log('this.paginatedItems', this.paginatedItems);

      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
      
    });
  }

  getFileType(url: string): string {
    const extension = url.split('.').pop();
    switch (extension) {
      case 'pdf':
        return 'pdf';
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image';
      default:
        return 'unknown';
    }
  }

  onPageChanged(paginatedData: any[]): void {
    this.paginatedItems = paginatedData;
  }
  
  filteredData = this.products;

  onFilteredData(filtered: any[]): void {
    this.filteredData = filtered;
    this.products = filtered;
    this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
    if(this.paginatedItems.length == 0){ 
      this.showNoRecordsFounds = true
    } else {
      this.showNoRecordsFounds = false
    }
    console.log('Received filtered data:', filtered);
  }

  viewCatalog(catalog: any) {
    window.open(catalog.catalog, '_blank');
  }

  downloadCatalog(catalog: any) {
    const link = document.createElement('a');
    link.href = catalog.catalog;
    link.download = '';
    link.target = '_blank';
    link.click();
  }
}

<div class="personal-infomation">
    <div class="d-flex">
      <h2 class="profile-heading">Photos</h2>&nbsp;&nbsp;
      <img class="edit-profile" *ngIf="data.length > 0 && !showEditForm" (click)="addPhoto()" alt="" src="../../assets/images/editprofile.svg">
    </div>
  
    <div class="mt-1" *ngIf="data.length == 0 && !showEditForm">
      <button class="profile-btn" (click)="addPhoto()">
        <mat-icon>add</mat-icon>
        <p class="ps-2">Add Photos</p>
      </button>
    </div>
  
    <div class="row mt-2 mb-2" *ngIf="data.length > 0 || showEditForm">
      <div class="card p-4">
        <div class="name-container">
          <div *ngFor="let photo of data; let i = index;" class="mt-2">
            <div class="img-container">
              <img [src]="photo" alt="" class="photo"> <!-- Bind photo string URL to the src attribute -->
              <div class="dot-circle dots-icon" *ngIf="showEditForm" [matMenuTriggerFor]="menu">
                <img src="./../../assets/images/vector_dots.svg" class="dots-icon" mat-icon-button alt="">
                <mat-menu #menu="matMenu">
                  <mat-icon class="delete-icon" (click)="clearPhoto(i)">delete</mat-icon>
                </mat-menu>
              </div>
            </div>
            <span class="mx-2"></span>
          </div>
  
          <div class="mt-2" *ngIf="showEditForm">
            <input class="d-none" type="file" id="upload" accept=".png,.jpg,.jpeg,.svg" (change)="uploadPhoto($event)" />
            <label class="add-button" for="upload" mat-raised-button>
              <mat-icon>add</mat-icon>
            </label>
          </div>
        </div>
      </div>
    </div>
  
    <div class="profile-action mt-3" *ngIf="showEditForm">
      <button class="mwe-button mwe-button--secondary ps-3 pe-3"  (click)="cancel()" >Cancel</button>
    </div>
</div>


<div class="personal-infomation">
  <div class="d-flex">
      <h2 class="profile-heading">Videos</h2>&nbsp;&nbsp;
      <img class="edit-profile" *ngIf="videoUrls.length > 0 && !showVideoForm" (click)="addVideos()" alt="" src="../../assets/images/editprofile.svg">
    </div>
 
    <div class="mt-1" *ngIf="videoUrls.length == 0 && !showVideoForm">
      <button class="profile-btn" (click)="addVideos()">
        <mat-icon>add</mat-icon>
        <p class="ps-2">Add Videos</p>
      </button>
    </div>

    <div  *ngIf="videoUrls.length > 0 || showVideoForm">
    <div *ngFor="let video of videoUrls.controls; let i = index" class="video-container">
      <!-- <input [formControl]="getVideoFormControl(i)" class="video-input" placeholder="Enter video URL" /> -->
       <span (click)="getUrls(getVideoFormControl(i).value)" style="cursor: pointer; color: #0D87C8;">{{getVideoFormControl(i).value}}</span>
      <button mat-icon-button (click)="removeVideo(i)" *ngIf="showVideoForm">
        <mat-icon style="color: red; position: relative; top:6px;">delete</mat-icon>
      </button>
    </div>
   
       
<div class="add-video" *ngIf="showVideoForm">
  <!-- <input class="video-input" type="text" [(ngModel)]="newVideoUrl" placeholder="Enter video URL" style="padding: 6px; border-radius: 2px;" /> -->
        <mat-form-field appearance="outline" class="mt-3">
            <input matInput class="video-input" type="text" [(ngModel)]="newVideoUrl" placeholder="Enter video URL">
        </mat-form-field>
  <button mat-raised-button color="primary" style="background-color: #0D87C8; margin-left: 30px; margin-top: -6px;"  (click)="addVideo()">Add</button>
</div>
    </div>
    <div class="profile-action mt-3" *ngIf="showVideoForm" style="margin-top: -20px;">
      <button class="mwe-button mwe-button--secondary ps-3 pe-3"  (click)="cancel2()" >Cancel</button>
    </div>
</div>


 
  
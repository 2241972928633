import { MasterDataService } from 'src/app/services/master-data.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { Enquiry } from 'src/app/models/messaging-models';
import { Product } from 'src/app/models/product-model';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProductsService } from 'src/app/services/products.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-product-distribution-request',
  templateUrl: './product-distribution-request.component.html',
  styleUrls: ['./product-distribution-request.component.scss']
})
export class ProductDistributionRequestComponent  implements OnInit{

  enquiryForm!: FormGroup;
  endCustomerService: any;
  organisationInfoForm: any;
  productId: string = '';
  id: any;
  oemData: any;
  image: File | null = null;
  imageUrl: string | null = null;
  selectedFile: string | undefined;
  imageURL: string | undefined;
  showImage:string | undefined;
  followUp: string[] = [];
  prodData: any;
  roleName: string = '';
  countries: any;
  customCountry: string = '';
  receiver1Id: string = '';
  receiver2Id: string = '';
  countryCodes: any[] = [];
  filteredCountryCodes: any[] = [];
  searchControl = new FormControl('');
  showDropdown: boolean = false;
  
  

  constructor(private fb: FormBuilder,
    private websocketService: WebsocketService,
    private localStorageService: LocalStorageService,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private sweetAlertService: SweetAlertService,
    private fileUploadService: FileUploadService,
    private masterDataService: MasterDataService,
    private oemService: OemService,
    private authService : AuthService,
  ) {
      this.initializeenquiryFrom();
  }

  ngOnInit(): void {
    this.productId = this.route.snapshot.params['id'];
    this.productInfoById(this.productId);
    this.localStorageService.getUserDetails().subscribe(userDetails => {
    });
    if(localStorage.getItem('roleName') === 'USER'){
      this.id = localStorage.getItem('userId');
    } else if(localStorage.getItem('roleName') === 'OEM'){
      this.id = localStorage.getItem('oemId');
    } else if(localStorage.getItem('roleName') === 'DISTRIBUTOR'){
      this.id = localStorage.getItem('distributorId');
      this.roleName = 'DISTRIBUTOR';
    }
    this.websocketService.joinChat(this.id);
    this.loadCountries(this.customCountry);
    this.getCountryCodes();

    this.searchControl.valueChanges.subscribe((term) => {
      this.filterCountryCodes(term ?? '');
    });
    }
    getCountryCodes(): void { 
      this.authService.getCountrycodes(0, 300).subscribe(
        (response: { data: any[]; }) => {
          this.countryCodes = response.data;
          this.filteredCountryCodes = this.countryCodes;
          
          
        },
        (error: any) => {
        
        }
      );
    }
    updatePhoneCode(event: Event): void {
      const selectElement = event.target as HTMLSelectElement;
      const selectedCountryCode = selectElement.value;
      
      
      const selectedCode = this.countryCodes.find(code => code.countryCode === selectedCountryCode);
      if (selectedCode) {
        this.enquiryForm.patchValue({
          phoneCode: selectedCode.phoneCode
        });
      }
    }
    
  

    handleCountry(e: any){
      this.customCountry = e.target.value;
      this.loadCountries(this.customCountry);
    }

    loadCountries(customCountry: string){
      this.masterDataService.getCountries(0, 200, customCountry).subscribe((res: any) => {
        this.countries = res.data;        
      })

      
    }
    

  initializeenquiryFrom(){
    this.enquiryForm = this.fb.group({
      countryName: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      phoneCode: new FormControl('', [Validators.required]),
      profileURL:['', [Validators.required, Validators.pattern(/^(https:\/\/|www\.).*/)]],
      portfilio:['', [Validators.required, Validators.pattern(/^(https:\/\/|www\.).*/)]],
      logo: new FormControl(null, []),
      productId: new FormControl(null, []),
      // senderId: new FormControl(this.id, [Validators.required]),
      // receiver1Id: new FormControl(this.receiver1Id, [Validators.required]),
      // receiver2Id: new FormControl(this.receiver2Id, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
    })
  }

  initializeEnquiryForm(productInfo: Product) {
    this.enquiryForm = this.fb.group({
      countryName: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      phoneCode: new FormControl('', [Validators.required]),
      profileURL:['', [Validators.required, Validators.pattern(/^(https:\/\/|www\.).*/)]],
      portfilio:['', [Validators.required, Validators.pattern(/^(https:\/\/|www\.).*/)]],
      logo: new FormControl(null, []),
      productId: new FormControl(productInfo?.id, []),
      // senderId: new FormControl(this.id, [Validators.required]),
      //senderId:    new FormControl('0f7871d9-008f-4d0a-ab46-5566dc830a24',[Validators.required]),
      // receiver1Id: new FormControl(this.receiver1Id, [Validators.required]),
      // receiver2Id: new FormControl(this.receiver2Id, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
    })
  }

  productInfoById(id: string) {
    this.productsService.getProductDetails(id).subscribe((productData: ApiResponseModel<any>) => {
      this.prodData = productData.data.productInfoDTO
      this.oemData = productData.data;
      if (productData && productData.data) {
        this.receiver1Id = productData?.data?.productInfoDTO?.oemId;
        this.receiver2Id = productData?.data?.productInfoDTO?.distributorsId?.[0] ?? null;
        this.initializeEnquiryForm(productData.data);
      }
    });
  }

  cancel() {
    // this.dialogRef.close();
  }


  uploadLogo(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.selectedFile = file.name;
      this.uploadFile(file).subscribe(
        (url: string) => {
          this.imageURL = url;
          this.enquiryForm.patchValue({ image: url });
        },
        (error: any) => {
          
        }
      );
    }
  }

  removeFile(event: Event): void {
    event.preventDefault();
    this.selectedFile = '';
    this.enquiryForm.patchValue({ image: '' });
  }

  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/subscriptions/');
    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; // Assuming response.data contains the URL of the uploaded file
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }

  save() {
    
    this.enquiryForm.markAllAsTouched();
    // if (this.enquiryForm.invalid) {
    //   
    //   return;
    // }
   
    let req: any;
    req = JSON.parse(JSON.stringify(this.enquiryForm.value));
    const reqObj = {
      enqCustomerName: req.companyName,
      email: req.email,
      phone: req.phone,
      senderId: this.id,
      receiver1Id: this.receiver1Id,
      receiver2Id: this.receiver2Id,
      message: req.message,
      isGroup: true,
      enquiryType: 'DISTRIBUTOR',
      receiverId : [this.receiver1Id, this.receiver2Id, this.id]
    };
    this.oemService.saveEnquiry(reqObj).subscribe(
      (response) => {
        this.sweetAlertService.showSuccess('', 'Enquiry submitted successfully !!');
        this.enquiryForm.reset();
      },
      (error) => {
       
        this.sweetAlertService.showError('', 'Failed to submit enquiry !!');
      }
    );
   
   
    // this.sendMessage(reqObj);
    // this.sweetAlertService.showSuccess('Success', 'Enquiry sent successfully');

    // this.endCustomerService.executePostRequest(MweConstants.saveEndCustomerUrl,req).subscribe(data=>{
   
    //   this.dialogRef.close();
    //   this.snackbarService.openSnackBar("Customer created successfully",'ok');
    // },error=>{
    //   this.snackbarService.openSnackBar(error.error.error,'');
    // })
    this.enquiryForm.reset();
  }

  areAllPropertiesNull(obj: any): boolean {
    for (const key in obj) {
      if (!(obj[key] === null || obj[key] === '')) {
        return false;
      }
    }
    return true;
  }

  sendMessage(message: any): void {
    this.websocketService.sendMessage(message);
    //this.websocketService.connect('ws://localhost:8089/onboarding/ws').next(message);
  }
  concatCountryCodeAndPhone(): string {
    const phoneCode = this.enquiryForm.get('phoneCode')?.value || '';
   
    
    const phone = this.enquiryForm.get('phone')?.value || '';
    return `${phoneCode}${phone}`;
  }

  get f() { return this.enquiryForm.controls; }
  get phone() {
    return this.enquiryForm.get('phone');
  }

  get isPhoneInvalid() {
    return this.enquiryForm.get('phone')?.invalid && (this.enquiryForm.get('phone')?.dirty || this.enquiryForm.get('phone')?.touched);
  }

  showDropdownList(): void {
    // Reset the filtered list to show all country codes
    this.filteredCountryCodes = this.countryCodes;
    this.showDropdown = true;
  }
  filterCountryCodes(term: string): void {
    this.filteredCountryCodes = term ? 
      this.countryCodes.filter(code =>
        code.countryCode.toLowerCase().includes(term.toLowerCase())
      ) 
      : this.countryCodes; 
  }
  hideDropdown(): void {
    setTimeout(() => this.showDropdown = false, 200);
  }
  selectCountryCode(code: { countryCode: string; phoneCode: string }): void {
    this.enquiryForm.patchValue({
      countryCode: code.countryCode,
      phoneCode: code.phoneCode
    });
    this.searchControl.setValue(code.countryCode);
    this.showDropdown = false; 
  }
  

}


import {  Component, OnInit,Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Distributor } from 'src/app/models/distributor-model';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { AssignMoreDistributorsComponent } from '../assign-more-distributors/assign-more-distributors.component';
import Swal from 'sweetalert2';
import { AddDistributorComponent } from '../add-distributor/add-distributor.component';
import { SweetAlertService } from 'src/app/services/sweetalert.service';


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit{

  @Input() network!: FormGroup;
  data!: Distributor[];
  distributorsNetwork!: Distributor[];
  allDistributors!: Distributor[];
  existingDistributors: string[] = [];
  removedIds: string[] = [];
  filteredDistributors: Distributor[] = [];
  isText: boolean = false;
  leadAddNewDistributor: boolean = false;
  distributorForm!: FormGroup;

  isFilterShow: boolean = false;
  
  columns =  [
    { name: 'Company Name', property: 'companyName', visible: true, isModelProperty: true, isText: true },
    { name: 'First Name', property: 'firstName', visible: true, isModelProperty: true, isText: true },
  { name: 'Last Name', property: 'lastName', visible: true, isModelProperty: true, isText: true },
  { name: 'Continent', property: 'continent', visible: true, isModelProperty: true, isText: true },
  { name: 'Country', property: 'country', visible: true, isModelProperty: true, isText: true },
  { name: 'state', property: 'state', visible: true, isModelProperty: true, isText: true },
  { name: 'City', property: 'city', visible: true, isModelProperty: true, isText: true },
  {name:'Status',property:'active',visible:true,isModelProperty:true,isStatus:true},
  { name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
    actions: [
      { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
    ]
  }
] as ListColumn[];
  
  constructor(private distributorService: DistributorService,private fb: FormBuilder, private dialog : MatDialog, private spinnerService: SpinnerService,private sweetAlertService: SweetAlertService) { }
   

    selectedAction(event: any) {
      switch (event.action) {
       
        case MweConstants.deleteAction:
          this.removeDistributor(event.row.id);
          break;
  
        default:
          break;
      }
    }


    removeDistributor(id: any) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover the deleted record!',
        iconHtml: '<img src="./assets/images/group-delete.svg">',
        iconColor : 'white',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete It',
        confirmButtonColor : '#0f74a9',
        cancelButtonText: 'Cancel',  
        cancelButtonColor : '#6c757d'
      }).then((result) => {
        if (result.value) {
        this.deleteDistributor(id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire({
            title: 'Cancelled',
            text: 'Item is safe.',
            icon : 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor : '#0f74a9',
          })         
        }
      })
     
    }
    
    deleteDistributor(id: any) {
      
      this.distributorsNetwork = this.distributorsNetwork.filter(d => d.id !== id);
      const selectedIdsFormArray = this.network.get('existingDistributors') as FormArray;
      const index = selectedIdsFormArray.controls.findIndex(control => control.value === id);
      if (index !== -1) {
        selectedIdsFormArray.removeAt(index);
      }
      if(this.distributorsNetwork.length <= 0){
        this.isText=false
      }
    }
    
    
 
    ngOnInit() {
      this.intialze();

      const selectedIdsFormArray = this.network.get('existingDistributors') as FormArray;
      this.existingDistributors = selectedIdsFormArray.value || [];
      this.getAllDistributors();
    }

    intialze(){
      this.distributorForm = this.fb.group({
        continents: '',
        countries: '',
        states: '',
        cities: '',
        date: ''
      });
    }

    getAllDistributors() {
      this.distributorService.getAllDistributorss(this.distributorForm.value).subscribe(res => {
        this.data = res.data; 
        this.allDistributors=this.data;
        this.distributorsNetwork = this.data.filter(d => this.existingDistributors.includes(d.id)).map(d => d);  
       if (this.existingDistributors.length > 0) {
         this.isText = true;
       } 
       
      })
    }

    filterDistributors(filters: any): void {
      this.filteredDistributors = this.data.filter(distributor => {
        return (!filters.continent || distributor.continent === filters.continent) &&
          (!filters.country || distributor.country === filters.country) &&
          (!filters.state || distributor.state === filters.state) &&
          (!filters.city || distributor.city === filters.city);
      });
    
      this.distributorsNetwork.forEach(selected => {
        if (!this.filteredDistributors.find(d => d.id === selected.id)) {
          this.filteredDistributors.push(selected);
        }
      });
    }

    addNewDistributor(){
      const dialogRef = this.dialog.open(AddDistributorComponent, {
        width: '40%',
        maxHeight: '80vh',
        data:{
          leadAddNewDistributor:true
        }
        
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          const newDistributors = this.network.get('newDistributors') as FormArray;
          newDistributors.push(new FormControl(result));
        }
      });
    
    }

    assignMoreDistributors() {
      const dialogRef = this.dialog.open(AssignMoreDistributorsComponent, {
        width: 'auto',
        height: '',
        data: { selectedDistributors: this.existingDistributors },
        autoFocus: false
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const selectedIdsFormArray = this.network.get('existingDistributors') as FormArray;
          result.forEach((id: string) => {
            if (!this.existingDistributors.includes(id)) {
              this.existingDistributors.push(id);
              console.log("existing",this.existingDistributors);
            selectedIdsFormArray.push(new FormControl(id)); 
            }
          });
         
          this.filteredDistributors = this.allDistributors.filter(d => this.existingDistributors.includes(d.id));
          this.distributorsNetwork = this.filteredDistributors;
          if(this.distributorsNetwork.length > 0 && this.existingDistributors.length > 0){
            this.isText=true; 
          }
        }
      });
    }
  
}

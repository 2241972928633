import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebsocketService } from 'src/app/services/websocket.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-websocket',
  templateUrl: './websocket.component.html',
  styleUrls: ['./websocket.component.scss']
})
export class WebsocketComponent implements OnInit, OnDestroy {
  private socketSubscription!: Subscription;
  public messages: string[] = [];

  constructor(private websocketService: WebsocketService) {}

  ngOnInit(): void {
    const url = 'ws://dev.medworld.com';
   // const wsSubject: Subject<MessageEvent> ='';//this.websocketService.connect(url);

    // this.socketSubscription = wsSubject.subscribe(
    //   (message) => {
    //     console.log('Received message:', message.data);
    //     this.messages.push(message.data);
    //   },
    //   (err) => {
    //     console.error('WebSocket error:', err);
    //   },
    //   () => {
    //     console.log('WebSocket connection closed');
    //   }
    // );
  }

  ngOnDestroy(): void {
    this.socketSubscription.unsubscribe();
  }

  sendMessage(message: any): void {
    //this.websocketService.connect('http://localhost:8089/onboarding/ws').next(message);
  }
}
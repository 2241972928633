import { Webinar } from 'src/app/models/oem-models';
import { Component, Input, OnInit } from '@angular/core';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-webinar-user',
  templateUrl: './webinar-user.component.html',
  styleUrls: ['./webinar-user.component.scss']
})
export class WebinarUserComponent implements OnInit {
  @Input() selectedTabIndex:number=0;

  webinars: any[] = [];
  displayWebinars: any;
  userId: string = '';
  userDetails: any;
  tabName: string = 'upcomingwebinar';

  constructor(
    private router: Router,
    private webinarService: WebinarService, 
    private localStorageService: LocalStorageService
  ){}

  ngOnInit(): void {
    this.userDetails = (this.localStorageService.getItem('userDetails'))
    if (this.userDetails) {
      this.userDetails = JSON.parse(this.userDetails);
      this.userId = this.userDetails.userId || '';
    }
    this.getWebinars(this.userId);
  }

  viewWebinar(webinar: any){
    console.log('view webinar', webinar);
    this.router.navigate(['/webinars/view', webinar.id]);

    
  }
  
  getWebinars(id: string){
    this.webinarService.getRegWebinars(id).subscribe((res: any) => {
      this.webinars = res.data;
      this.activateCurrentab('upcomingwebinar')
    });
  }

  activateCurrentab(selectedName: string): void{
    this.tabName = selectedName;
    if(selectedName === 'upcomingwebinar'){
      this.displayWebinars = this.webinars.filter(webinar => (new Date(webinar.webinarDate) > new Date()))
    }
    else{
      this.displayWebinars = this.webinars.filter(webinar => (new Date(webinar.webinarDate) <= new Date()))
    }
  }
}

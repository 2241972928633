<div class="mt-4">
    <div class="d-flex">
        <p class="title mt-1 mx-1">Associations & Organizations</p>
        <img class="edit-profile mx-1" (click)="addAssociation()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.associations &&this.endCustomerData.moreInfos[0].associations.length>0) || showEditForm" alt="" src="../../assets/images/add.svg">
    <img class="edit-profile " (click)="editAssociation()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.associations &&this.endCustomerData.moreInfos[0].associations.length>0)" alt=""
        src="../../assets/images/editprofile.svg">
    </div>
    

    <div class="mt-2"
    *ngIf="!(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
this.endCustomerData.moreInfos[0]?.associations &&this.endCustomerData.moreInfos[0].associations.length>0) && !showEditForm">
    <button (click)="addAssociation()" class="profile-btn">
        <mat-icon>add</mat-icon> <p class="ps-2">Add Associations & Organizations</p></button>
</div>
<div class="mt-2"
    *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.associations &&this.endCustomerData.moreInfos[0].associations.length>0)&& !showEditForm">
    <app-mwe-table [columns]="associationColumns" [showPagination]="false" [showHeader]="false"
        [data]="data"></app-mwe-table>
</div>
<div class="mt-2" *ngIf="showEditForm">
    <form [formGroup]="associationsAndOrganizationsForm">
        <div formArrayName="moreInfos">
            <div *ngFor="let moreInfoForm of moreInfos.controls;let i=index;" [formGroupName]="i">
                <div formArrayName="associations">
                    <div *ngFor="let associationForm of associations(i).controls;let j=index;" [formGroupName]="j">
                        <div class="row">
                            <div class="card p-4 mt-2 ">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Association Name</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput formControlName="associationName">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Position Held</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput formControlName="positionHeld">
                                            </mat-form-field>
                                        </div>
                                    </div>


                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> From</p>
                                        </div>

                                        <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                            <input matInput [matDatepicker]="fromDate"  formControlName="fromDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="fromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #fromDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> Until</p>
                                        </div>

                                        <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                            <input matInput [matDatepicker]="toDate"  formControlName="toDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="toDate"></mat-datepicker-toggle>
                                            <mat-datepicker #toDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Summary</p>
                                        </div>
                                        <div>

                                            <mat-form-field appearance="outline" class=" input-font b-r w-100  description">
                                                <textarea matInput formControlName="summary"  rows="4" 
                                                    ></textarea>
                                            </mat-form-field>
                                        </div>


                                    </div>
                                </div>
                                <div class="row mt-2" >
                                    <div class="col-lg-3">
                                        <button class=" delete" (click)="deleteAssociation(i,j)" ><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>   
                    </div>
                        </div>
                        </div>
                        </div>
                        </form>
                        <div class="profile-action my-3" >
            
    
                            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                        
                        
                            
                        
                        
                            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                        
                        
                        
                        </div>
                        </div>
</div>

<div class="modal-popup" mat-dialog-content>
    <h2 style="color: #0D87C8">{{data.isAddItem ? 'Add Category': 'Update Category'}}</h2>
      <form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()" >
        <div >
          <div class="upload-container my-3">
            <div>
              <mat-label class="label">Upload Image</mat-label> 
            </div>
            <mat-card class="upload-card">
              <div class="upload-area" (click)="fileInput.click()" *ngIf="!image">
                <label class="d-flex justify-content-center text" for="upload" mat-raised-button>
                  <img class="d-flex flex-row align-items-center justify-content-center " alt="" src="./../../assets/images/photo-add.svg"> <span style="color: #0D87C8 " class="d-flex flex-row align-items-center justify-content-center gap-1"> Select File</span> 
                </label>
              </div>
              <input #fileInput type="file" accept="image/*" (change)="selectFile($event, fileInput)" style="display: none;" >
              <div *ngIf="image">
                <div class="file-item">
                  <div class="file-details">
                    <img [src]="image" class="file-thumbnail" alt="">
                    <div class="file-info" >
                      <span>{{fileName}}</span>
                    </div>
                  </div>
                  <button mat-icon-button color="warn" (click)="deleteFile()">
                    <mat-icon >close</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card>
            <mat-error class="error-message image-error" *ngIf="fileError">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Image is Required
            </mat-error>
            <mat-error class="error-message image-error" *ngIf="invalidFileType">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Invalid file type {{fileType}}, Only image files are allowed.
            </mat-error>
          </div>

          <div class="mb-3">  
            <div>
              <mat-label class="label">Parent Category</mat-label> 
            </div>
            <mat-form-field appearance="outline" class="fields w-100">
              <input matInput formControlName="name" >
              <mat-error class="error-message error-msg" *ngIf="categoryForm.get('name')?.hasError('required')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />Category is Required
              </mat-error>
            </mat-form-field> 
          </div>
        </div>
        <div class="popup-buttons">
          <button mat-raised-button class="save-btn" type="submit">Save</button>
          <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">Cancel</button>
        </div>  
      </form>
  </div>
  
  
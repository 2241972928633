<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Services Available</h2>
        <img class="edit-profile " (click)="editService()"
            *ngIf="endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length>0&& !showEditForm" alt=""
            src="../../assets/images/editprofile.svg">

    </div>
    <div class="mt-2" *ngIf="!(endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length>0) && !showEditForm">
        <button (click)="editService()" class="profile-btn">
            <mat-icon>add</mat-icon> <p class="ps-2">Add Services Available</p></button>
    </div>
    <div class="mt-2 name-container"
        *ngIf="endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length>0 && !showEditForm">
       <!--  <span class=" speciality " *ngFor="let service of servicesData">
            <label class="med-world-basic-font med-world-basic-font__grey">{{service?.name}}</label>
        </span> -->
        <div *ngFor="let service of servicesData" class="my-3">
            <span class=" speciality " >
                <label class="med-world-basic-font med-world-basic-font__grey">{{service?.name}}</label> 
                
            </span>
            <span class="mx-1"></span>
        </div>

    </div>

    <div *ngIf="showEditForm" class="mt-2">
        <div class="row" [formGroup]="servicesForm">
              
            <div
                class="col-lg-12">
                <div >
                    <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color ">Types of Services Available</p>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100 equipment"
                        [class.equipment_increase]="servicesForm.get('serviceInfos')?.value.length>4">
                        <mat-select placeholder="Select" formControlName="serviceInfos" multiple
                            >
                            <mat-select-trigger>

                                <mat-chip-listbox>
                                    <mat-chip class="chip" *ngFor="let topping of servicesForm.get('serviceInfos')?.value ; let i=index"
                                        [removable]="true" (removed)="remove(i)">
                                        {{ getService(topping)}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </mat-select-trigger>
                            <mat-option *ngFor="let service of services"
                                [value]="service.id">{{service?.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="profile-action " >
            
    
            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
        
        
            
        
        
            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
        
        
        
        </div>
    </div>
</div>
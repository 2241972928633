import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUs!: FormGroup;

  constructor(
    private fb: FormBuilder,
  ){}

  ngOnInit(): void {
    this.contactUs = this.fb.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      message: new FormControl('')
    })
      
  }

  faq: any = [
    {
      question: 'How to book a hotel?',
      answer: 'To book a hotel, you can visit our booking page and enter your desired dates, guests, and preferences. We will then process your booking and provide you with a confirmation.',
      display: false
    },
    {
      question: 'How to cancel a booking?',
      answer: 'To cancel a booking, you can visit your booking page and click on the "Cancel Booking" button. We will then process your cancellation and provide you with a confirmation.',
      display: false
    },
    {
      question: 'How to change a booking?',
      answer: 'To change a booking, you can visit your booking page and click on the "Edit Booking" button. We will then process your changes and provide you with a confirmation.',
      display: false
    },
    {
      question: 'How to pay for a booking?',
      answer: 'We accept a variety of payment methods, including credit cards and PayPal. You can enter your payment details during the booking process.',
      display: false
    },
    {
      question: 'Is it safe to use this website?',
      answer: 'Yes, our website is safe and secure. We use industry-standard security measures to protect your data and ensure that your booking is processed securely.',
      display: false
    },
    {
      question: 'How to contact customer support?',
      answer: 'You can contact our customer support team via email or phone. We are available 24/7 to assist you with any questions or concerns you may have.',
      display: false
    }
  ];

  showAnswer(idx: number){
    this.faq[idx].display = !this.faq[idx].display
  }

  sendMessage(){
    console.log('sending message!!', this.contactUs.value);   
    
  }

}

<app-website-header></app-website-header>
<div class="header">
    <div class="header">
        <div id="carouselExampleControls" class="carousel slide">
          <div class="carousel-inner">
            <div
              class="carousel-item"
              *ngFor="let slide of slides; let i = index"
              [class.active]="i === currentSlide"
            >
              <img class="d-block w-100" [src]="slide?.image" [alt]="slide.alt" />
            </div>
          </div>
          <button class="carousel-control-prev" (click)="prevSlide()">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button class="carousel-control-next" (click)="nextSlide()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div>
      </div>
      
    <div class="header-name">
        <p>
            Embrace the Future with the World’s <br />
            Premier Digital Medical Exhibition
        </p>
    </div>
</div>

<div>
  <div class="row mt-2">
    <div class="d-flex align-items-center mt-5">
      <img
        src="../../../assets/images/products.svg"
        alt="landingpage"
        class="products ml-auto"
        style="margin-left: 2%"
      />
      <span class="products mx-2"> Top Products </span>
    </div>

    <div class="products-container d-flex flex-row flex-wrap m-5" style="width: 100%; margin-left: 2%;">
        <ng-container *ngFor="let product of products.slice(0, 4)">
            <div class="categories d-flex flex-column flex-wrap" style="flex: 1;">
                <app-product-info-card
                    [id]="product.id"
                    [productImage]="product.images[0]"
                    [title]="product.productname"
                    [isCategoryCard]="false"
                    [subtitleImage]="product.oemlogo"
                    [hoverText]="product.description"
                    [contentItems]="product.specifications"
                >
                </app-product-info-card>
            </div>
        </ng-container>
    </div>

    <div style="margin-right: 3rem;">
        <div class="row mt-2">
            <div class="d-flex align-items-center mt-5 mb-4">
                <img src="../../../assets/images/icons/upcoming-icon.svg" alt="landingpage" class="products ml-auto" style="margin-left: 2%;" />
                <span class="products mx-2"> Upcoming Webinars </span>
            </div>
        </div>

        <div class="products-container d-flex flex-row flex-wrap" style="width: 100%;">
            <ng-container *ngFor="let webinar of upcomingwebinars.slice(0, 4)">
                <div class="categories d-flex flex-column flex-wrap" style="flex: 1; margin-left: 50px;">
                    <div class="d-flex card my-2" style="border: 1px solid #f5f5f5; box-shadow: 0px 4px 16px 0px #0000000d; width: 260px;" (click)="view(webinar.id)">
                        <div class="card-img">
                            <img [src]="webinar.image" width="100%" height="130px" />
                        </div>
                        <div class="card-heading elipses">
                            <p>{{ webinar.webinarTitle }}</p>
                        </div>
                        <div class="card-info">
                            <div class="card-info-calender d-flex">
                                <img src="../../../assets/images/icons/calender-icon-blue.svg" style="padding: 0px 5px;" />
                                <div class="d-flex flex-column justify-content-around elipses">
                                    <p class="date-time">
                                        {{ webinar.webinarDate }} {{ webinar.webinarTime }}
                                    </p>
                                    <p class="specialization m-0">{{ webinar.speciality }}</p>
                                </div>
                            </div>
                            <div class="card-info-person d-flex">
                                <!-- <img src="../../../assets/images/webiste-webinar-card-person.svg"> -->
                                <img [src]="webinar.hostImage" width="100%" height="auto" style="max-width: 32px;" />

                                <div class="d-flex flex-column justify-content-around elipses">
                                    <p class="person-name">{{ webinar.speakersName }}</p>
                                    <p class="specialization m-0">{{ webinar.speciality }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>


<div style="margin-right: 3rem;">
  <div class="row mt-2">
    <div class="d-flex align-items-center mt-5 mb-4">
      <img
        src="../../../../assets/images/Frame.svg"
        alt="landingpage"
        class="products ml-auto"
        style="margin-left: 2%"
      />
      <span class="products mx-2"> News & Trends </span>
    </div>
  </div>

  <div class="products-container d-flex flex-row flex-wrap" style="width: 100%" >
    <ng-container *ngFor="let news of rssData.slice(0, 4)">
        <div class="categories d-flex flex-column flex-wrap" style="flex: 1; margin-left: 50px">
    <div class="d-flex news-card my-2" style="border: 1px solid #F5F5F5; box-shadow: 0px 4px 16px 0px #0000000D; width: 260px;" >
        <div class="card-img">
            <img [src]="news.image" width="100%" height="130px">
        </div>
        <div class="card-heading" style="font-weight: bold; color: black;">
            <p> {{news.title}} </p>
        </div>
        <div class="">
            <div class="d-flex">
                <div class="news-card-info d-flex">
                    <p> {{news.description}} </p>
                </div>
            </div>
            
        </div>
        <div class="view-more">
          <!-- <b>View More</b> -->
          <a [href]="news.link" target="_blank" >View More</a>
        </div>
    </div>
  </div>
  </ng-container>
</div>

<div style="margin-right: 3rem;">

  </div>

        <div class="exhibit d-flex m-5 position-relative">
            <div class="txt d-flex flex-column justify-content-end" routerLink="/signup">
                <p class="exhibit-t m-0">Exhibit With Us</p>
                <p class="exhibit-b">Develop your Business!</p>
            </div>
            <div class="d-flex">
                <img src="../../assets/images/people-handshake.svg" alt="" class="handshake-image" />
            </div>

            <div class="end-txt d-flex align-items-center" style="background-image: url('../../assets/images/landingPage-bg.svg');">
                <p>
                    Join us to build a more connected, innovative, and accessible medical community together.
                </p>
            </div>
        </div>
    </div>


    
    <app-website-footer></app-website-footer>
</div>


<div class="paginator-container" >
    <div class="page-numbers">
      <button mat-icon-button class="arrow" (click)="changePage(0)" [disabled]="currentPage === 0">
         <img src="assets/images/pagination_first.svg" alt="" srcset="">
      </button>
      <button mat-icon-button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
       <img src="assets/images/pagination_prev.svg" alt="" srcset=""> 
      </button>
     
      <ng-container *ngFor="let page of getPageNumbers()">
        <button mat-button *ngIf="page !== '...'" [class.page-active]="currentPage === page" (click)="changePage(+page)">
          {{ +page + 1 }}
        </button>
        <span *ngIf="page === '...'">...</span>
      </ng-container>

      <button mat-icon-button (click)="changePage(currentPage + 1)" [disabled]="currentPage >= getTotalPages() - 1">
        <img src="assets/images/pagination_next.svg" alt="" srcset="">
      </button>

      <button mat-icon-button (click)="changePage(getTotalPages() - 1)" [disabled]="totalData < 10">
       <img src="assets/images/pagination_last.svg" alt="" srcset="">
      </button>
    </div>
</div>

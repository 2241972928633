<div class="mt-4">
    <div class="d-flex">
        <p class="title mt-1 mx-1">Volunteering & Causes</p>
        <img class="edit-profile mx-1" (click)="addVolunteering()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.volunteerings &&this.endCustomerData.moreInfos[0].volunteerings.length>0) || showEditForm" alt="" src="../../assets/images/add.svg">
    <img class="edit-profile " (click)="editVolunteering()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.volunteerings &&this.endCustomerData.moreInfos[0].volunteerings.length>0)" alt=""
        src="../../assets/images/editprofile.svg">
    </div>
    

    <div class="mt-2"
    *ngIf="!(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
this.endCustomerData.moreInfos[0]?.volunteerings &&this.endCustomerData.moreInfos[0].volunteerings.length>0) && !showEditForm">
    <button (click)="addVolunteering()" class="profile-btn">
        <mat-icon>add</mat-icon><p class="ps-2">Add Volunteering & Causes</p> </button>
</div>
<div class="mt-2"
    *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.volunteerings &&this.endCustomerData.moreInfos[0].volunteerings.length>0)&& !showEditForm">
    <app-mwe-table [columns]="volunteeringColumns" [showPagination]="false" [showHeader]="false"
        [data]="data"></app-mwe-table>
</div>

<div class="mt-2" *ngIf="showEditForm">
    <form [formGroup]="volunteeringForm">
        <div formArrayName="moreInfos">
            <div *ngFor="let moreInfoForm of moreInfos.controls;let i=index;" [formGroupName]="i">
                <div formArrayName="volunteerings">
                    <div *ngFor="let volunteeringForm of volunteerings(i).controls;let j=index;" [formGroupName]="j">
                        <div class="row">
                            <div class="card p-4 mt-2 ">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Organization Name</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="organizationName">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Position Held</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="positionHeld">
                                            </mat-form-field>
                                        </div>
                                    </div>


                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> From</p>
                                        </div>
                                      <!--   <div class="d-flex">
                                            <mat-form-field appearance="outline" class="fields w-40 ">
                                                <mat-select placeholder="Month">
                                                    <mat-option *ngFor="let month of monthDropdown"
                                                        [value]="month">{{month}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="fields w-40 mx-1">
                                                <mat-select placeholder="Year">
                                                    <mat-option *ngFor="let year of yearDropdown"
                                                        [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                            <input matInput [matDatepicker]="fromDate"  formControlName="fromDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="fromDate"></mat-datepicker-toggle>
                                            <mat-datepicker #fromDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> Until</p>
                                        </div>
                                        <!-- <div class="d-flex">
                                            <mat-form-field appearance="outline" class="fields w-40 ">
                                                <mat-select placeholder="Month">
                                                    <mat-option *ngFor="let month of monthDropdown"
                                                        [value]="month">{{month}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="fields w-40 mx-1">
                                                <mat-select placeholder="Year">
                                                    <mat-option *ngFor="let year of yearDropdown"
                                                        [value]="year">{{year}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                        <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                            <input matInput [matDatepicker]="toDate"  formControlName="toDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="toDate"></mat-datepicker-toggle>
                                            <mat-datepicker #toDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Summary</p>
                                        </div>
                                        <div>
                                            <!-- <mat-form-field appearance="outline"
                                                class="w-100 mat-dropdown-mrgn-btm mrgn-btm-40">
                                                <textarea matInput formControlName="summary" appAutoHeight></textarea>
                                            </mat-form-field> -->
                                            <mat-form-field appearance="outline" class=" input-font w-100 b-r description">
                                                <textarea matInput formControlName="summary" rows="4" 
                                                    ></textarea>
                                            </mat-form-field>
                                        </div>



                                    </div>
                                </div>
                                <div class="row mt-2" >
                                    <div class="col-lg-3">
                                        <button class=" delete" (click)="deleteVolunteering(i,j)" ><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </form>
                        <div class="profile-action my-3" >
            
    
                            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                        
                        
                            
                        
                        
                            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                        
                        
                        
                        </div>
                        </div>

</div>
<div class="table-container" fxFlexFill>
  <mat-table [dataSource]="jsonData">
    <!-- Columns -->
    <ng-container matColumnDef="{{column}}" *ngFor="let column of columns; let i = index">
      <mat-header-cell *matHeaderCellDef> {{ column }} </mat-header-cell>
      <mat-cell *matCellDef="let item; let idx = index">
        <!-- Check if the column is 'Sr.No' -->
        <ng-container *ngIf="column === 'Sr.No'; else defaultColumn">
         <div style="margin-left:10px;"> {{ idx + 1 }}</div>
        </ng-container>
        <ng-container *ngIf="column === 'Actions'; else defaultColumn">
          <img alt="" class="image_action" src="assets/images/edit.svg">
          <img alt="" class="image_action" src="assets/images/bin.svg">
        </ng-container>
        <ng-container *ngIf="column === 'Enquiry Id'; else defaultColumn">
          {{item.groupName}}
        </ng-container>
        <ng-container *ngIf="column === 'Product Name'; else defaultColumn">
          {{item.productName}}
        </ng-container>
        <ng-container *ngIf="column === 'Status'; else defaultColumn">
          {{ item.status }}
        </ng-container>

       
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;"></mat-row>
  </mat-table>
</div>

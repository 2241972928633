<!-- breadcrumb.component.html -->
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <ng-container *ngFor="let cat of breadcrumb; let isLast = last">
      <li [ngClass]="{'breadcrumb-item': true, 'active': isLast}" [routerLink]="['/category', cat.id]">
        <a (click)="isLast || navigateToCategory(cat.id)">
          <span>{{ cat.name }}</span>
        </a>
      </li>
    </ng-container>
  </ol>
</nav>


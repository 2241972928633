import { SendMessagePopupComponent } from 'src/app/components/shared/send-message-popup/send-message-popup.component';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DistributorNetworkManagementService } from 'src/app/services/oem/distributor-network-management.service';
import { DISTRIBUTOR_ID, OEM_ID, USER_ID } from 'src/app/utils/constants';

@Component({
  selector: 'app-mydistributors',
  templateUrl: './mydistributors.component.html',
  styleUrls: ['./mydistributors.component.scss'] 
})
export class MydistributorsComponent implements OnInit {

  formColumns: string[] = ['country', 'state', 'city', 'verification', 'brands', 'ratings']
  
  @Input() isMyDistributors=false;
  userId: any;
  requests: any;
  oemData: any;
  ids: { id: string, senderId: string }[] = [];
  distributorId: string='';
  body: any= 
  {
      senderId: this.localStorageService.getItem(OEM_ID),
      receiverId: null,
      senderRole: 'OEM',
      receiverRole: 'DISTRIBUTOR',
      status: 'PENDING'
    }
  distributorIds: any[]=[];
  
  constructor(
    private dialog: MatDialog,
    private http : HttpClient, private localStorageService: LocalStorageService,private distributorNetworkService: DistributorNetworkManagementService) {
        this.localStorageService.getUserDetails().subscribe(userDetails => {
          this.userId = userDetails.userId;this.userId = this.localStorageService.getItem('roleName') === 'DISTRIBUTOR' ? this.localStorageService.getItem('distributorId') : 
          (this.localStorageService.getItem('roleName') === 'OEM' ? this.localStorageService.getItem('oemId') : this.localStorageService.getItem(USER_ID));
    });
  }
  
  sendMessage(data : any) {
    console.log('sending message', this.oemData, data);
    
    const message: any = {
      message: '',
      senderId: data.oemId,
      isGroup: true,
      receiverId: [data.distributorId , data.oemId],
      enqCustomerName: data.distributorName,
      enquiryType: 'DISTRIBUTOR'
    };
    const dialog = this.dialog.open(SendMessagePopupComponent, {
      width: '500px',
      data: message
    })
  }

  cancelRequest(id: any) {
    console.log('clicked on cancel req', this.oemData);
    
    const request = this.requests.find((request: { id: string, reciever: string }) => request.reciever === id);
    this.distributorNetworkService.cancelRequest(request.id).subscribe((response) => {
      console.log('Request sent successfully', response);
    });
  }

  ngOnInit(): void {
  if(this.isMyDistributors){
    
    this.getMyDistributors(this.userId);
  }
  else{
    this.getRequests();
  }
  }

  getMyDistributors(id : String) {
    console.log("id",id);
    
    this.distributorNetworkService.getMyDistributors(id).subscribe(data => {
      console.log("distributorId",data);    
      this.oemData = data.data
    })
  }

  

  getRequests() {
    this.distributorNetworkService.getSentPendingRequests(this.userId).subscribe(data => {
      this.requests = data.data.map((request: { id: any; receiverId: any; }) => ({ id: request.id, reciever: request.receiverId }));
      this.ids=data.data;
      this.ids.forEach((request: any) => this.getDistributorById(request.receiverId));
      this.ids=[];
    })
  }
  getDistributorById(id:any){
    this.distributorNetworkService.getDistributorById(id).subscribe(data => {
      // this.oemData = data.data;
      console.log('oem data ius ', this.oemData);
      
    });
    
  }


productImage: any="/assets/images/lab equipment.svg";
title: any;
isCategoryCard: any;
subtitleImage: any;
contentItems: any;
hoverText: any;
@Input() isAll: boolean = true;
@Input() isRequested: boolean = false;
@Input() isRequestedBy: boolean = false;

 

 


tabSliderPosition = 0; // Initial position of the tab slider
  tabSliderWidth = 0; // Initial width of the tab slider
  activeTab = 0;
  previousTab: number = 0;
  animateContent: boolean = false;


  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();   
    }
  }

  
  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }


}

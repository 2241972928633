import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { forkJoin } from 'rxjs';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-deal-details',
  templateUrl: './deal-details.component.html',
  styleUrls: ['./deal-details.component.scss']
})
export class DealDetailsComponent implements OnInit {
  @Input() dealDetailsForm!: FormGroup;
  continents!: any[];
  countries!: any[];
  states!: any[];
  cities!: any[];
  leadSources!: any[];
  maxDate: Date = new Date();
  role: string = '';
  roleMappingId: any;
  showNoRecordsFounds: boolean = true

  constructor(
    private masterDataService: MasterDataService, 
    private spinnerService: SpinnerService,private localStorageService: LocalStorageService, private endCustomerService: EndCustomerService
  ) {}

  ngOnInit(): void {
    this.loadMasterData()
    this.getAllStaff();
  }

  staffData: any[] = [];

  getAllStaff(){
    this.role=this.localStorageService.getItem('roleName');
    if(this.role=='OEM'){
      this.roleMappingId=this.localStorageService.getItem('oemId');
    } else if(this.role=='DISTRIBUTOR'){
      this.roleMappingId=this.localStorageService.getItem('distributorId');
      console.log(this.roleMappingId, this.role);
      
    }
    this.showNoRecordsFounds=false;
    this.endCustomerService.executeGetRequestURL(MweConstants.getAllUserUrl, {role:this.role,roleMappingId:this.roleMappingId}).subscribe((data: any)=>{
      if(data && data?.data && data.data.length>0){
        this.staffData=data.data;
        console.log('dataaa', this.staffData);
        
      }
    }) 
  }

  async  loadMasterData() {
    this.spinnerService.showSpinner();
    forkJoin({
      continents: this.masterDataService.getContinents(),
      leadSources: this.masterDataService.getLeadSources()
    }).subscribe({
      next: (results) => {
        this.continents = results.continents.data;
        this.leadSources = results.leadSources.data.map((source: any) => ({ name: source.sourceName }));
        this.setFormValues();
        this.spinnerService.hideSpinner();
      },
      error: (error) => {
        console.error('Error fetching master data:', error);
        this.spinnerService.hideSpinner();
      }
    });
  }

  private setFormValues() {
    const lead = this.dealDetailsForm.get('lead')?.value;
    
    if (lead) {
      console.log('Lead:', lead);
      console.log('Lead continent:', lead.continent);
      this.loadCountries([lead.continent]).then(() => {
        // Set country only after countries are loaded
        this.dealDetailsForm.get('lead')?.get('country')?.setValue(lead.country);
  
        this.loadStates([lead.country]).then(() => {
          // Set state only after states are loaded
          this.dealDetailsForm.get('lead')?.get('state')?.setValue(lead.state);
  
          this.loadCities([lead.state]).then(() => {
            // Set city only after cities are loaded
            this.dealDetailsForm.get('lead')?.get('city')?.setValue(lead.city);
          });
        });
      });
    }
  }
  
  

  loadCountries(continentNames: string[]): Promise<void> {
    console.log('Loading countries:', continentNames);
    return new Promise((resolve, reject) => {
      this.masterDataService.getCountries(0, 100, '', continentNames).subscribe({
        next: (data) => {
          console.log('Countries:', data.data);
          this.countries = data.data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching countries:', error);
          reject(error);
        }
      });
    });
  }

  loadStates(countryNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.masterDataService.getStates(0, 500, '', countryNames).subscribe({
        next: (data) => {
          this.states = data.data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching states:', error);
          reject(error);
        }
      });
    });
  }

  loadCities(stateNames: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.masterDataService.getCities(0, 500, '', stateNames).subscribe({
        next: (data) => {
          this.cities = data.data;
          resolve();
        },
        error: (error) => {
          console.error('Error fetching cities:', error);
          reject(error);
        }
      });
    });
  }

  onContinentChange(event: any) {
    const continentName = event?.value;
    this.loadCountries([continentName]);
  }

  onCountryChange(event: any) {
    const countryName = event?.value;
    this.loadStates([countryName]);
  }

  onStateChange(event: any) {
    const stateName = event?.value;
    this.loadCities([stateName]);
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onAccountTypeChange(selectedValue: string) {
    this.dealDetailsForm.patchValue({ lead: { accountType: selectedValue } });
  }
}

<div #scrollContainer class="summary-container" >
 
    <span>Company Description</span>
    <ng-container *ngIf="!isEditMode; else editModeTemplate">
      <div [innerHTML]="this.companyInformationGroup.get('description')?.value"></div>
    </ng-container>
    <ng-template #editModeTemplate>
      <angular-editor [(ngModel)]="editedDescription" [config]="editorConfig"></angular-editor>
    </ng-template>
    <div class="button-container">
      <button class="mwe-button mwe-button--primary ps-3 pe-3" *ngIf="!isEditMode" (click)="toggleEditMode()" >Edit</button>
      <button class="mwe-button mwe-button--primary ps-3 pe-3" *ngIf="isEditMode" (click)="saveEdit()">Save</button>
      <button class="mwe-button mwe-button--secondary ps-3 pe-3" *ngIf="isEditMode" (click)="cancelEdit()">Cancel</button>
    </div>
  </div>

  <app-company-attachments [attachmentsForm]="attachmentsForm"></app-company-attachments>


 
  
  
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-volunteering-profile',
  templateUrl: './volunteering-profile.component.html',
  styleUrls: ['./volunteering-profile.component.scss']
})
export class VolunteeringProfileComponent implements OnInit{

  volunteeringColumns: ListColumn[] = [
    { name: 'Organization Name', property: 'organizationName', visible: true, isModelProperty: true ,isText: true},
    {name:'Position Held',property:'positionHeld',visible:true,isModelProperty:true,isText:true},
    {name:'From',property:'fromDate',visible:true,isModelProperty:true,isDatePipe:true},
    {name:'To',property:'toDate',visible:true,isModelProperty:true,isDatePipe:true},
  ] as ListColumn[];

  @Input() volunteeringForm!:FormGroup;
  data:any[]=[];

  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyVolunteeringsForm:any;
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  constructor(private fb:FormBuilder){}
  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify(this.volunteerings(0).value));
  }

  get moreInfos(){
    return this.volunteeringForm.controls['moreInfos'] as FormArray;
   }

   volunteerings(i:number){
     return (this.moreInfos.at(i) as FormGroup).controls['volunteerings'] as FormArray;
   }
 
   getMoreInfoFormGroup(i:number){
     return (this.moreInfos.at(i) as FormGroup);
   }

   addVolunteering(){
    this.copyVolunteeringsForm=JSON.parse(JSON.stringify(this.volunteeringForm.value));
    this.volunteerings(0).push(
      
      this.fb.group({
        id:new FormControl(null),
        active:new FormControl(null),
        createdBy:new FormControl(null),
        createdOn:new FormControl(null),
        modifiedBy:new FormControl(null),
        modifiedOn:new FormControl(null),
        "organizationName"  :new FormControl(null),
         "positionHeld": new FormControl(null),
         "fromDate": new FormControl(null),
         "toDate": new FormControl(null),
         "summary": new FormControl(null),
       }),
    )
    this.showEditForm=true;
  }

  cancel(){
    
    this.volunteerings(0).clear();
     if(this.copyVolunteeringsForm?.moreInfos && this.copyVolunteeringsForm.moreInfos.length>0&&
      this.copyVolunteeringsForm.moreInfos[0]?.volunteerings &&this.copyVolunteeringsForm.moreInfos[0].volunteerings.length>0
     ){
          for(let i=0;i<this.copyVolunteeringsForm.moreInfos[0].volunteerings.length;i++){
            this.volunteerings(0).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "organizationName"  :new FormControl(null),
              "positionHeld": new FormControl(null),
              "fromDate": new FormControl(null),
              "toDate": new FormControl(null),
              "summary": new FormControl(null),
            }));
          }
          this.volunteerings(0).patchValue(this.copyVolunteeringsForm.moreInfos[0].volunteerings);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.volunteerings(0).value));
  
  
  }

  deleteVolunteering(i:number,j:number){
    this.volunteerings(i).removeAt(j);
  }

   editVolunteering(){
    this.copyVolunteeringsForm=JSON.parse(JSON.stringify(this.volunteeringForm.value));
    this.showEditForm=true;
  }
  update(){
 
    this.onUpdate.emit(140);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify(this.volunteerings(0).value));
  }
}

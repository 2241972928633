import { PointOfContact } from 'src/app/models/oem-models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CombinedData } from '../../models/oem-models';
import { apiUrls, OEMUrls } from 'src/app/utils/apiUrls';
import { ApiResponseModel } from 'src/app/models/shared-model';

@Injectable({
  providedIn: 'root'
})
export class OemService {
  
  userId: any;
  constructor(private http: HttpClient) {}

  searchOEMs(keyword: string): Observable<ApiResponseModel<any[]>> {
    return this.http.get<ApiResponseModel<any[]>>(`${OEMUrls.oem}/search?keyword=${keyword}`);
  }

  // filterDistributors(filter: any): Observable<ApiResponseModel<any[]>> {
  //   return this.http.post<ApiResponseModel<any[]>>(this.ONBOARDING_BASE_URL + `/distributor/filter`, filter);
  // }

  public deleteOEMById(id: string){
    return this.http.delete<any>(`${OEMUrls.oem}/${id}`)
  }

  addOem(data: CombinedData): Observable<any> {
    return this.http.post<any>(`${OEMUrls.oem}stastics?id=`, data).pipe(
      tap(response => console.log('OEM added:', response)),
      catchError(this.handleError)
    );
  }
  createMeeting(data: any): Observable<any> {
    return this.http.post<any>(`${OEMUrls.createMeeting}`, data).pipe(
      tap(response => console.log('meeting created:', response)),
      catchError(this.handleError)
    );
  }



  getAppoinmentByExhibitionId(id: string): Observable<any> {
    const url = `${OEMUrls.exhibition}/appointments/${id}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  updateAppointment(id: string, appointmentData: any) {
    const url = `${OEMUrls.exhibition}/appointment/edit/${id}`;
    return this.http.put(url, appointmentData);
  }
  getAppoinmentById(id: string): Observable<any> {
    const url = `${OEMUrls.exhibition}/appointment/get/${id}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }
  
  getoems(list ?: any): Observable<any> {
    return this.http.get<any>(`${OEMUrls.oem}/list?page=0&size=10${list}`).pipe(
      tap(response => console.log('OEMs:', response)),
      catchError(this.handleError)
    );
  }

  getStaticData(id: string): Observable<any> {
    return this.http.get<any>(`${OEMUrls.oemStatistics}${id}`).pipe(
      tap(response => console.log('Static data:', response)),
      catchError(this.handleError)
    );
  }
  getOEMdetailswithUserId(id: string): Observable<any> {
    const url = `${OEMUrls.oemDetails}${id}`;
    return this.http.get<any>(url).pipe(
      tap(response => console.log('OEM details:', response)),
      catchError(this.handleError)
    );
  }

  getExhibitions(Id: string): Observable<any> {
    const url = `${OEMUrls.exhibition}/createdby/${Id}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }

  saveExhibition(exhibitionData: any): Observable<any> {
    const url = `${OEMUrls.exhibition}/save`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('userId', 'yourUserId'); 

    return this.http.post<any>(url, exhibitionData, { headers }).pipe(
      tap(response => console.log('Exhibition saved:', response)),
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }

 getExhibitionById(exhibitionId: string): Observable<any> {
    const url = `${OEMUrls.exhibition}/get/${encodeURIComponent(exhibitionId)}`;
    return this.http.get<any>(url).pipe(
      tap(response => console.log('Exhibition data:', response)),
      catchError(this.handleError)
    );
  }

  executeDeleteRequestURL(passedurl: string) : Observable<any>{
      const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId',this.userId);
      const deleteURL = `${OEMUrls.exhibition}/${passedurl}`;
    return this.http.delete(deleteURL);
  }

  updateExhibition(exhibitionData: any, exhibitionId: string): Observable<any> {
    const url = `${OEMUrls.exhibition}/edit/${exhibitionId}`;
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('userId', 'yourUserId');

    return this.http.put<any>(url, exhibitionData, { headers }).pipe(
      tap(response => console.log('Exhibition updated:', response)),
      catchError(this.handleError)
    );
  }

  getstaff(type: string): Observable<any> {
    const url = `${OEMUrls.staff}${type}`;
    return this.http.get<any>(url).pipe(
      catchError(this.handleError)
    );
  }

  public addDistributorOemManagement(oem: any): Observable<ApiResponseModel<string[]>> {
    return this.http.post<ApiResponseModel<string[]>>(apiUrls.signup, oem);
  }

filterExhibitions(oemId : any,filterurl : any): Observable<any> {
  const url=`${OEMUrls.exhibition}/filters/${oemId}`;
  return this.http.post<any>(url, filterurl).pipe(
    catchError(this.handleError)
  )
}

getBrandsList(): Observable<any> {
  return this.http.get<any>(`${OEMUrls.brandList}`).pipe(
    tap(response => console.log('OEMs Brands List:', response)),
    catchError(this.handleError)
  );
}

getOEMById(id: string): Observable<any> {
  const url = `${OEMUrls.oemById}${id}`;  
  return this.http.get<any>(url).pipe(
    catchError(this.handleError)
  );
}

setPointOfContact(pocData: any, id: string): Observable<any>{
  const headers = new HttpHeaders().set('userId', id);
  return this.http.post<any>(OEMUrls.poc, pocData, { headers });
}

getOemWithManagementId(id: string): Observable<any> {
  const url = `${OEMUrls.oemWithManagementId}${id}`;  
  return this.http.get<any>(url).pipe(
    catchError(this.handleError)
  );
}

getOemDistributorById(id: string): Observable<any> {
  const url = `${OEMUrls.oemDistributorById}${id}`;  
  return this.http.get<any>(url).pipe(
    tap(response => console.log('OEM Distributor details by id :', response)),
    catchError(this.handleError)
  );
}

oemWithManagementUpdate( data: any): Observable<any> {
  const url = `${OEMUrls.oemWithManagementUpdate}`;  
  return this.http.put<any>(url, data).pipe(
    tap(response => console.log('OEM Distributor details by id :', response)),
    catchError(this.handleError)
  );
  
}

getOEMsByDistributorId(id: string): Observable<any> {
  const url = `${OEMUrls.oemByDistributorId}${id}`;  
  return this.http.get<any>(url).pipe(
    tap(response => console.log('OEM Distributor details by id :', response)),
    catchError(this.handleError)
  );
}

getExhibitionsByCreatedBy(createdbyId: string): Observable<any> {
  const url = `${OEMUrls.exhibition}/${createdbyId}`;
  return this.http.get<any>(url).pipe(
    catchError(this.handleError)
  );
}

saveAppointment(data: any): Observable<any> {
  const url = `${OEMUrls.exhibition}/appointment/save`;
  const headers = new HttpHeaders()
    .set('Accept', 'application/json')
  return this.http.post<any>(url, data, { headers }).pipe(
    tap(response => console.log('Appointment saved:', response)),
    catchError(this.handleError)
  );
}

saveEnquiry(data: any): Observable<any> {
  const url = `${OEMUrls.enquiry}`;
  const headers = new HttpHeaders()
    .set('Accept', 'application/json')
  return this.http.post<any>(url, data, { headers }).pipe(
    tap(response => console.log('Enquiry saved:', response)),
    catchError(this.handleError)
  );
}

  requestedDetails(data: any){
    return this.http.post<any>(`${OEMUrls.respondRequest}/requested-details`, data);
  }
  public updateStatus(status: string , role : string , id : string): Observable<any> {
    const params = new HttpParams()
      .set('status', status)
      .set('role', role)
      .set('id', id);
    return this.http.post<any>(`${OEMUrls.updateStatus}`, null, { params });
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditUnitOfMeasureComponent } from './add-edit-unit-of-measure/add-edit-unit-of-measure.component';
import Swal from 'sweetalert2';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { UnitMeasureService } from 'src/app/services/shared/product-settings/unit-measure.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { MweConstants } from 'src/app/constants/mwe-constants.service';

@Component({
  selector: 'app-unit-of-measure',
  templateUrl: './unit-of-measure.component.html',
  styleUrls: ['./unit-of-measure.component.css']
})
export class UnitOfMeasureComponent implements OnInit{
  displayedColumns: string[] = ['srNo', 'name', 'code', 'status', 'actions'];
  searchValue: string | null = null;
  showNoRecordsFounds:boolean=false;
  unitOfMeasureData: any[] = [];
  actionType! : string;
  constructor(
    private dialog: MatDialog,
    private sweetAlertService : SweetAlertService,
    private unitMesaureService : UnitMeasureService 
  ) {
  }

  ngOnInit(): void {
   this.getAllUnitMeasure();
  }

  getAllUnitMeasure() {
    this.showNoRecordsFounds=false;
    this.unitMesaureService.getAllUnitMeasure().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.unitOfMeasureData = response.data;
          this.unitOfMeasureData = response.data.map((item, index) => ({ ...item, srNo: index + 1 }));
        } else {
          this.showNoRecordsFounds=true;
        }
      },
      error: (error) => {
        console.error('Error fetching unit measure data:', error); 
      }
    });
  }
  
  openAddEditForm() {
    const dialogRef = this.dialog.open(AddEditUnitOfMeasureComponent, {
      width: 'auto',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        if (val) {
          this.getAllUnitMeasure();
        }
      },
    });
  }

  openEditForm(data: any) {
    console.log(data)
    data = {
      ...data,
      actionType : this.actionType
    };
    const dialogRef = this.dialog.open(AddEditUnitOfMeasureComponent, {
      width: 'auto',
      height: 'auto',
      data,
    });

    dialogRef.afterClosed().subscribe({
      next: (val) => {
        this.getAllUnitMeasure();
      },
    });
  }
  

  delete(id: number) {
    this.sweetAlertService.showConfirmation('Are you sure?', 'You will not be able to recover the deleted record!', 'Yes, Delete It', 'Cancel')
    .then((result) => {
      if (result.value) {
        this.unitMesaureService.deleteUnitMeasure(id).subscribe(
          (response) => {
            this.getAllUnitMeasure();
            this.sweetAlertService.showSuccess('Removed', 'Unit of measure deleted successfully !!');
          },
          (error) => {
            console.log("error", error);
            this.sweetAlertService.showError('Failed', 'Failed to delete unit of measure !!');
          }
        );
      } else if(result.dismiss === this.sweetAlertService.CANCEL){
        this.sweetAlertService.showError('Cancelled', 'Item is safe.');
      }
    });

  }


  columns: ListColumn[] = [
    { name: 'Sr No', property: 'srNo',visible:true,isModelProperty:false},
    {name:'Name',property:'name',visible:true,isModelProperty:true,isText:true},
    { name: 'Code', property: 'code',visible:true,isModelProperty:true, isText: true},
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true  ,hasAction: true,
    actions:[
            {imageUrl:'assets/images/eye-visble.svg', isImage:true, actionType:MweConstants.viewAction},
            {imageUrl:'assets/images/edit.svg', isImage:true, actionType:MweConstants.editAction,hasCondition:true,conditionProperty:'active',conditionValue:true},
            {imageUrl:'assets/images/bin.svg', isImage:true, actionType:MweConstants.deleteAction,hasCondition:true,conditionProperty:'active',conditionValue:true}]
          },
  ] as ListColumn[];

selectedAction(event:any){
  switch (event.action) {
    case MweConstants.addAction:
      this.actionType=event.action;
      this.openAddEditForm()
      
      break;
    case MweConstants.editAction:
      this.actionType=event.action;
      this.openEditForm(event.row);
      break;
    case MweConstants.deleteAction:
      this.actionType=event.action;
      this.delete(event.row.id);
      break;
    case MweConstants.viewAction:
      this.actionType=event.action;
      this.openEditForm(event.row);
      break;
    default:
      break;
  }
}
    
}

import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, concatMap } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit {

  
  jobPost: any;
  countries!: any[];
  @Input() from: string = '';
  @Output() backToAllJobs = new EventEmitter<boolean>();



  constructor(private readonly careerService: CareerService,
    private snackbarService: SnackbarService, private readonly masterDataService: MasterDataService,
    private readonly router: Router, private route: ActivatedRoute,) { }


  ngOnInit(): void {
    this.loadCountries('');
    this.getJobPostById();
  }

  loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  jobPostId: any;
  private getJobPostById() {
    if(this.from !== ''){
      this.jobPostId = this.from
    }
    else{
      this.jobPostId = this.router.url.split('?')[0].split('/').pop();
    }
    this.jobPost = {};
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat('/', this.jobPostId), null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          const jobPostResponse = response?.data;
          jobPostResponse.skills = this.convertStringToArray(jobPostResponse.skills);
          jobPostResponse.tags = this.convertStringToArray(jobPostResponse.tags);
          this.jobPost = jobPostResponse;
        }
      }, error: (error: any) => {
        this.snackbarService.openSnackBar(error.error.error, '');
      }
    });
  }

  private convertStringToArray(field: any) {
    return (field !== undefined && field !== null && field.length > 0) ? field.split(",") : [];
  }

  getCurrencySymbol(currency: string) {
    return this.countries?.find(country => country.currency === currency).currencySymbol;
  }

  onBack() {
    if(this.from !== ''){
      this.backToAllJobs.emit(true);
      return;
    }
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

}

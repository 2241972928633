import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-photos-profile',
  templateUrl: './photos-profile.component.html',
  styleUrls: ['./photos-profile.component.scss']
})
export class PhotosProfileComponent implements OnInit{
 
  @Input() photoForm!:FormGroup;

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  showEditForm:boolean=false

  copyPhotoForm:any;

  data:any=[];

  constructor(private endCustomerService:EndCustomerService,private fb:FormBuilder){}

  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify( this.photoForm.get('photos')?.value));
  }

  get photos(){
    return (this.photoForm.controls['photos'] as FormArray);
  }

  uploadPhoto(event:any){
    let uploadData = new FormData();
     uploadData.append('file', event.target.files[0]);
     let randomNumber = Math.floor(Math.random() * 10) + 1
     uploadData.append('destinationKey', randomNumber+'');
 
     this.endCustomerService.executePostRequestForAuth(MweConstants.uploadFile,uploadData).subscribe(data=>{
       if(data && data?.data){
       this.photos .push(
          this.fb.group({ 
            "photoLink"  :new FormControl(data.data),
            fileName:event.target.files[0].name
      }))
      this.data=JSON.parse(JSON.stringify( this.photoForm.get('photos')?.value));
       console.log(this.data)
       }
     },error=>{
 
     })
  }

  clearPhoto(i:number){
    this.photos.removeAt(i);
    this.data=JSON.parse(JSON.stringify( this.photoForm.get('photos')?.value));
  }

  addPhoto(){
    this.copyPhotoForm=JSON.parse(JSON.stringify(this.photoForm.value));
    this.showEditForm=true;
  }

  cancel(){
    
    this.photos.clear();

    if(this.copyPhotoForm?.photos && this.copyPhotoForm.photos.length>0
     ){
          for(let i=0;i<this.copyPhotoForm.photos.length;i++){
            this.photos.push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "photoLink"  :new FormControl(null),
              "fileName": new FormControl(null),
            }));
          }
          this.photos.patchValue(this.copyPhotoForm.photos);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.photos.value));
  }

  update(){
 
    this.onUpdate.emit(60);
  }

  refresh(){
    this.showEditForm=false;
   this.data=JSON.parse(JSON.stringify(this.photos.value));
  }
}

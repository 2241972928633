<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">More Info</h2>
    </div>
    <app-publications-profile [publicationsForm]="moreInfos" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"></app-publications-profile>
    <app-awards-profile [awardsHonorsForm]="moreInfos" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"></app-awards-profile>
    <app-courses-profile [coursesAndTrainingForm]="moreInfos" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"  *ngIf="isIndividualProfile"></app-courses-profile>
    <app-associations-profile [associationsAndOrganizationsForm]="moreInfos" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"></app-associations-profile>
    <app-volunteering-profile [volunteeringForm]="moreInfos" [endCustomerData]="endCustomerData" (onUpdate)="onUpdate($event)"></app-volunteering-profile>
</div>

// table.component.ts
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { saveAs } from 'file-saver';
import { ProductCotnent } from 'src/app/models/product-model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements AfterViewInit, OnChanges {
  
  displayedColumns: string[] = ['rank', 'image', 'OEM', 'views', 'added', 'action'];
  @Input() productsData!: ProductCotnent[];

  dataSource = new MatTableDataSource(this.productsData);
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  // Define variables to track pagination
  currentPage = 0;
  itemsPerPage = 10;
  totalItems = this.productsData?.length ?? 0;

  // Define additional properties for pagination
  startIndex = 0;
  endIndex = Math.min(this.totalItems, this.itemsPerPage);

  // Define page size options
  pageSizeOptions: number[] = [5, 10, 25, 100];

  ngOnInit(): void {
    console.log(this.productsData);
    this.dataSource = new MatTableDataSource(this.productsData);
    
  }


  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.paginator = this.paginator;
    this.paginator.pageSize = 10;
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.productsData);
    this.totalItems = this.productsData?.length ?? 0;
    this.updatePaginationInfo();
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  

  exportToCSV() {
    const csvData = this.convertToCSV(this.dataSource.filteredData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    saveAs(blob, 'products.csv');
  }

  convertToCSV(data: any[]): string {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(item => Object.values(item).join(','));
    return `${header}\n${rows.join('\n')}`;
  }

  updatePaginationInfo() {
    if (this.totalItems > 0) {
      this.startIndex = (this.paginator.pageIndex * this.paginator.pageSize) + 1;
      this.endIndex = Math.min((this.paginator.pageIndex + 1) * this.paginator.pageSize, this.totalItems);
    } else {
      this.startIndex = 0;
      this.endIndex = 0;
    }
  }

  pageChanged(event: any) {
    this.updatePaginationInfo();
    // Add any other logic here if needed
  }
}

<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">About You</h2>
        <img class="edit-profile " *ngIf="!showEditForm" alt="" src="../../assets/images/editprofile.svg" (click)="editAboutYou()">

    </div>
   <div *ngIf="!showEditForm">
    <div class="mt-1    ">
        <p  class="label" [innerHTML]="aboutId.get('about')?.value"></p>
    </div>
    <div class="mt-1">
        <h4 class="profile-h4">Social Media Links</h4>
    </div>
    <div class="mt-2 d-flex">
        <div class="social-media-border mx-1" *ngIf="aboutId.get('facebookUrl')?.value" (click)="openUrl(aboutId.get('facebookUrl')?.value)">
            <img  src="../../assets/images/FacebookAppSymbol.svg"  alt="" srcset="">

        </div>
        <div class="social-media-border mx-1" *ngIf="aboutId.get('twitterUrl')?.value" (click)="openUrl(aboutId.get('twitterUrl')?.value)">
            <img  src="../../assets/images/Twitter.svg" alt="" srcset="">

        </div>
        <div class="social-media-border mx-1" *ngIf="aboutId.get('linkedin')?.value" (click)="openUrl(aboutId.get('linkedin')?.value)">
            <img  src="../../assets/images/LinkedinLogo.svg" alt="" srcset="">

        </div>
        <div class="social-media-border mx-1" *ngIf="aboutId.get('instagramUrl')?.value" (click)="openUrl(aboutId.get('instagramUrl')?.value)">
            <img  src="../../assets/images/Instagram.svg" alt="" srcset="">

        </div>
    </div>
    <!-- <div class="mt-2" *ngIf="showSpecialisationTypes">
        <h4 class="profile-h4">Specialities</h4>
    </div> -->
    <!-- <div class="name-container" *ngIf="showSpecialisationTypes">
            <div *ngFor="let speciality of specialityData" class="my-3">
                <span class=" speciality " >
                    <label class="med-world-basic-font med-world-basic-font__grey">{{speciality?.name}}</label>
                    
                </span>
                <span class="mx-2"></span>
            </div>
    </div> -->
   </div>
   <div *ngIf="showEditForm" class="mt-2">
    <form [formGroup]="aboutYouForm">
        <div formGroupName="aboutId">
            <div class="row">
                <div class="col-lg-12">
                    <!-- <div >
                        <p class="med-world-basic-font secondary_black_color">About</p>
                    </div> -->
                    <div>
                        <angular-editor  [config]="editorConfig"
                            formControlName="about"></angular-editor>
                        
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <h4 class="profile-h4 profile-h4__color">Social Media Links</h4>
                <div class="col-lg-6">
                    <div >
                        <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Facebook URL</p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font  w-100 mt-1">
                            <input matInput  formControlName="facebookUrl">
                            <mat-error *ngIf="aboutId.get('facebookUrl')?.hasError('pattern')">
                                Enter a valid Facebook URL
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div >
                        <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Twitter URL </p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font  w-100  mt-1">
                            <input matInput  formControlName="twitterUrl">
                            <mat-error *ngIf="aboutId.get('twitterUrl')?.hasError('pattern')">
                                Enter a valid Twitter URL
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div >
                        <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0  ">LinkedIn URL</p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font  w-100  mt-1">
                            <input matInput  formControlName="linkedin">
                            <mat-error *ngIf="aboutId.get('linkedin')?.hasError('pattern')">
                                Enter a valid LinkedIn URL
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div >
                        <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Instagram URL</p>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="input-font  w-100 mt-1">
                            <input matInput  formControlName="instagramUrl">
                            <mat-error *ngIf="aboutId.get('instagramUrl')?.hasError('pattern')">
                                Enter a valid Instagram URL
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
       
        <!-- <div class="row" *ngIf="showSpecialisationTypes">
            <h4 class="profile-h4 profile-h4__color">Specialities</h4>
            <div
                class="col-lg-12">
                <div >
                    <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color ">Select Specialization</p>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100 equipment"
                        [class.equipment_increase]="aboutYouForm.get('specialityInfos')?.value.length>4">
                        <mat-select placeholder="Select" formControlName="specialityInfos" multiple
                            >
                            <mat-select-trigger>

                                <mat-chip-listbox>
                                    <mat-chip class="chip" *ngFor="let topping of aboutYouForm.get('specialityInfos')?.value ; let i=index"
                                        [removable]="true" (removed)="remove(i)">
                                        {{ getSpecility(topping)}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-listbox>
                            </mat-select-trigger>
                            <mat-option *ngFor="let specialisation of specialisations"
                                [value]="specialisation.id">{{specialisation?.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div> -->
    </form>
    
   </div>
   <div class="profile-action " *ngIf="showEditForm">
            
    
    <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>


    


    <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>



</div>
</div>
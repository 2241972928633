<mat-card class="mt-4" >
    <mat-card-content>

        <div class="row">
            <div class=" d-flex justify-content-between ">
                <!-- <h4 class="profile-heading" style="font-weight: bold;" >Doctors in {{data.companyName}} hospital</h4> -->
                <p class="reviews">Reviews</p>
            </div>
            <div class="reviews-container">
                <div class="customer-reviews" *ngFor="let item of [1,2,3,4,5,6]">
                  <div *ngIf="data?.logo" class="customer-profile">
                    <p>NC</p>
                  </div>
                  <div class="review-section">
                    <div>
                      <p style="font-weight: bold; font-size: 16px; padding:none; margin:0px;">Nicolas Cage</p>
                    </div>
                    <div class="ratings-star">
                        <div  *ngFor="let j of [1, 2, 3, 4, 5]">
                          <img  
                                src="../../../assets/images/full-star-rating.svg" />
                                <!-- *ngIf="j <= averageRating" />  -->
                          <img  
                                src="../../../assets/images/empty-star-rating.svg" 
                                *ngIf="j > averageRating" />
                        </div>
                        <!-- <div class="d-flex align-items-center justify-content-center">
                          5.0 out of <strong class="mx-1"> ({{reviews.length}}) </strong> Reviews
                        </div><br><br><hr> -->
                      </div>
                    <p class="customer-review">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur distinctio similique voluptatum minima soluta. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur distinctio similique voluptatum minima soluta.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur distinctio similique voluptatum minima soluta.</p>
                    <hr style="width: 60%; color:#CCC; border:1px solid"/><br>
                  </div>
                
                </div>
              </div>
              <div class=" d-flex justify-content-between ">
                <!-- <h4 class="profile-heading" style="font-weight: bold;" >Doctors in {{data.companyName}} hospital</h4> -->
                <p class="reviews">Show all Reviews (175)</p>
            </div>
        </div>
    </mat-card-content>
    </mat-card>
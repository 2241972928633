<div class="table-container">
  <div class="container header-container">
    <h2 class="title">Top 100 Products</h2>
    
    <div class="controls search-bar">
      <mat-form-field appearance="outline" style="display: flex;flex-wrap: wrap; flex-direction: row;">
        <button mat-button matPrefix>
          <img src="assets/images/icons/fi-rr-search.svg" alt="">
        </button>
        <input matInput placeholder="Search product. . ." (keyup)="applyFilter($event)" style="margin-left:-11% ;letter-spacing: 0;">
      </mat-form-field>
      
      <button class="btn-gray m-2 export-btn" mat-raised-button (click)="exportToCSV()">
        <img src="assets/images/icons/Export.svg" alt=""> Export
      </button>
    </div>
  </div>
  
  <div class="table-data">
    <table mat-table [dataSource]="productsData" class="full-width-table" matSort aria-label="Elements">
      <!-- Rank Column -->
      <ng-container matColumnDef="rank">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rank</th>
        <td mat-cell *matCellDef="let element" class="rank">{{element.rank}}</td>
      </ng-container>
  
      <!-- Image Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product Name</th>
        <td mat-cell *matCellDef="let element" class="product"><img [src]="element.image" alt="Product" width="50" > {{element.productname}}</td>
      </ng-container>
  
      <!-- OEM Column -->
      <ng-container matColumnDef="OEM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>OEM Name</th>
        <td mat-cell *matCellDef="let element" class="oem">{{element.oemname}}</td>
      </ng-container>
  
      <!-- Views Column -->
      <ng-container matColumnDef="views">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Views</th>
        <td mat-cell *matCellDef="let element" class="views">{{element.view}}</td>
      </ng-container>
  
      <!-- Added Column -->
      <ng-container matColumnDef="added">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Added</th>
        <td mat-cell *matCellDef="let element" class="added">{{element.createdbyname}}</td>
      </ng-container>
  
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>action</th>
        <td mat-cell *matCellDef="let element"><img src="assets/images/icons/eye.svg" alt="">   </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="border-bottom: 1px solid var(--Neutral-Gray-Gray-50, #F0F1F3);
      background: var(--Neutral-Gray-Gray-25, #F9F9FC);"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="pagination-wrapper">
      <span class="pagination-info">Showing {{startIndex}}-{{endIndex}} from {{totalItems}}</span>
      <mat-paginator [length]="totalItems"
                     [pageSize]="itemsPerPage"
                     [pageSizeOptions]="pageSizeOptions"
                     showFirstLastButtons
                     (page)="pageChanged($event)">
      </mat-paginator>
    </div>
  </div>

  <!-- Pagination -->
  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator> -->
  
  
  
</div>
<div class="card mt-4">
    <div class="card-body">
        <div class="body-title" style="color: rgba(0, 0, 0, 1);">Career Portfolio</div>
        <div class="d-flex mt-3">
            <img src="/assets/images/carrers-apply-tip-icon.svg" alt="carrers-apply-tip" class="me-2">
            <div class="career-tips">Tips to complete your profile</div>
        </div>
        <ul class="tips">
            <li><button  class="tips-button" (click)="onWorkExperience('work')">Add Work Experience</button></li>
            <li><button  class="tips-button" (click)="onWorkExperience('education')">Add Education</button></li>
            <!-- <li><button  class="tips-button" (click)="onWorkExperience('references')">Add References</button></li> -->
        </ul>
        <hr>
        <div class=" container " [ngClass]="{'selected': isSavedJobsSelected && isCareersTabActive}">
            <div class="d-flex " role="button" (click)="onOpenProfileJobs(true)">
                <img src="/assets/images/careers-saved-jobs.svg" alt="carrers-apply-tip" class="me-2">
                <div *ngIf="endCustomerType === 'Individual'" class="career-tips">Saved Jobs</div>
                <div *ngIf="endCustomerType === 'Organization'" class="career-tips">All Jobs</div>
            </div>
        </div>
        <hr>
        <div class ="container" [ngClass]="{'selected': isAppliedJobsSelected && isCareersTabActive}">
            <div class="d-flex" role="button" (click)="onOpenProfileJobs(false)">
                <img src="/assets/images/careers-applied-jobs.svg" alt="carrers-apply-tip" class="me-2">
                <div *ngIf="endCustomerType === 'Individual'" class="career-tips">Applied Jobs</div>
                <div *ngIf="endCustomerType === 'Organization'" class="career-tips">Post a Job</div>
            </div>
        </div>
        <hr>
        <div class="d-flex  p-2" role="button" (click)="onManagePassword()">
            <img src="/assets/images/careers-manage-password.svg" alt="carrers-apply-tip" class="me-2">
            <div class="career-tips">Manage Password</div>
        </div>
        <hr>
        <div class="d-flex p-2" role="button" (click)="onLogout()">
            <img src="/assets/images/careers-logout.svg" alt="carrers-apply-tip" class="me-2">
            <div class="career-tips">Logout</div>
        </div>
        <hr>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent implements OnInit {
  @Input() remarksForm!: FormGroup;
  isEditing: boolean = false;
  
  remarkForm!: FormGroup;
  userName: string = '';
  remarks: any[] = [];
  isAdding: boolean = true; // Flag to control form visibility
  currentEditingIndex: number | null = null; // Index of the remark being edited

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '150px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(private fb: FormBuilder, private localServiceStorage: LocalStorageService) {}

  ngOnInit(): void {
    this.localServiceStorage.getUserDetails().subscribe(data => {
      this.userName = data.userName;
      this.initializeRemarkForm();
    });
    this.remarks = this.remarksForm.get('leadRemarks')?.value
      .filter((remark: any) => Object.values(remark).some(value => value !== null)) || [];
      this.isAdding = false; 
      this.currentEditingIndex = null; 
  }
  
  initializeRemarkForm() {
    this.remarkForm = this.fb.group({
      remarksText: new FormControl('', Validators.required),
    });
  }

  // Add or update a remark
  addRemark() {
    if (this.remarkForm.valid) {
      const newRemark = {
        id: this.currentEditingIndex !== null ? this.remarks[this.currentEditingIndex].id : null,
        active: null,
        createdBy: this.currentEditingIndex !== null ? this.remarks[this.currentEditingIndex].createdBy : null,
        createdOn: this.currentEditingIndex !== null ? this.remarks[this.currentEditingIndex].createdOn : new Date(),
        modifiedBy: null,
        modifiedOn: new Date(),
        personName: this.userName,
        remarksText: this.remarkForm.get('remarksText')?.value,
        leadId: null,
         // Reset isEditing flag
      };

      if (this.currentEditingIndex !== null) {
        // Update existing remark
        this.remarks[this.currentEditingIndex] = newRemark;
        this.currentEditingIndex = null;
      } else {
        // Add new remark
        this.remarks.push(newRemark);
      }

      this.updateParentFormArray();
      this.remarkForm.reset();
      this.toggleFormVisibility(); // Hide the form after adding/updating a remark
    }
  }

  // Show the form for adding a new remark
  add() {
    this.isAdding = true;
    this.currentEditingIndex = null;
    this.remarkForm.reset();
  }

  updateParentFormArray() {
    const formArray = this.fb.array(this.remarks.map(remark => this.fb.group(remark)));
    this.remarksForm.setControl('leadRemarks', formArray);
  }

  // Toggle form visibility based on the number of remarks or if editing
  toggleFormVisibility() {
    this.isAdding = this.remarks.length === 0 || this.currentEditingIndex !== null;
  }

  // Enable editing of a remark
  editRemark(index: number) {
    this.currentEditingIndex = index;
    this.remarkForm.setValue({ remarksText: this.remarks[index].remarksText });
    this.isAdding = true; // Show the form with "Add", "Cancel", and "Delete"
  }

  // Save the edited remark
  addEditedRemark() {
    this.addRemark(); // Reuse addRemark for saving edited remark
  }

  // Delete a remark
  deleteRemark(index: number) {
    this.remarks.splice(index, 1);
    this.updateParentFormArray();
    this.toggleFormVisibility();
    this.remarkForm.reset();
    this.isAdding = false;
    this.currentEditingIndex = null;
  
   // Re-evaluate the visibility of the form after deletion
  }

  cancelConfig() {
    this.isAdding = false;
    this.currentEditingIndex = null;
    this.remarkForm.reset();
  }
}

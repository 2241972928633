<div *ngIf="showPersonalInfo" class=" head mt-4">
    <div class="row d-flex align-items-center">
        <ng-container *ngIf="jobPost!==undefined && jobPost!=={}; else elsePersonalInfo">
            <div class="col-lg-2 ext-center mt-3 d-flex justify-content-end">
                <img class="ps-2" src="/assets/images/careers-apply-job.svg" alt="careers-apply-job">
            </div>
            <div class="col-lg-10  mt-3">
                <div class="med-world-basic-font med-world-basic-font__large "></div>
                <div class="personal-info-value">You are applying for the Job <strong>“{{jobPost?.title}}”</strong> at
                    {{jobPost?.company}}</div>
            </div>
        </ng-container>
        <ng-template #elsePersonalInfo>
            <div class="col-lg-2 ext-center mt-3 d-flex justify-content-end">
                <img class="ps-2" src="../../assets/images/manageprofile.svg">
            </div>
            <div class="col-lg-10  mt-3">
                <div class="med-world-basic-font med-world-basic-font__large "></div>
                <div class="personal-info-value">Much of the information listed below is used to build your portfolio, a
                    customized
                    professional webpage designed to highlight you and your career.</div>
            </div>
        </ng-template>
    </div>
</div>
<div class="personal-infomation p-4 " [ngClass]="{'mt-4':!showPersonalInfo    }" [formGroup]=" personalForm">

    <div class=" d-flex justify-content-between  ">
            <h2 class="profile-heading label">{{showPersonalInfo?'Personal':'Organization'}} Information</h2>
        <img class="edit-profile " *ngIf="!showEditForm" (click)="editPersonalInfo()"
            src="../../assets/images/editprofile.svg">
    </div>

    <div class="row  mt-2" *ngIf="showEditForm">
        <!--             <div class="col-lg-6 label">Profile Image</div> -->
        <div class="d-flex">
            <div class="avatar" *ngIf="!personalForm.get('logo')?.value">
            </div>
            <img class="logo" alt="Profie Picture" *ngIf="personalForm.get('logo')?.value"
                src="{{personalForm.get('logo')?.value}}">
        </div>
    </div>
    <div *ngIf="showEditForm" class="d-flex mt-2 gap-2">
        <input class="d-none" type="file" id="profile" accept=".png,.jpg,.jpeg,.svg" (change)="uploadLogo($event)" />

        <!-- <div class="button-container" style="display: flex; flex-direction: row;  gap: .5rem; padding-top: 1rem;">
            <div>
                <label class="hover-pointer mwe-button mwe-button--primary upload med-world-basic-font med-world-basic-font__medium ps-3 pe-3 mt-0" for="profile">Upload</label>
            </div>
            <div>
                <button *ngIf="personalForm.get('logo')?.value" class="mwe-button mwe-button--danger med-world-basic-font med-world-basic-font__medium ps-3 pe-3 p-1 mwe-button--secondary" (click)="removeLogo()">Remove</button>
            </div>
        </div> -->
        <label class="mwe-button mwe-button--primary med-world-basic-font upload med-world-basic-font__medium ps-3 pe-3"
            for="profile">Upload
        </label>
        <button *ngIf="personalForm.get('logo')?.value"
            class="mwe-button remove  med-world-basic-font med-world-basic-font__medium  ps-3 pe-3   mx-2"
            [ngClass]="{'mwe-button--danger':true}" (click)="removeLogo()">Remove</button>



    </div>
    <div class="row mt-2" *ngIf="showEditForm">
        <div class="col-lg-6">

            <div class="row ">
                <h4 class="profile-h4">General Information</h4>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Name </div>
                <div class="col-lg-6 personal-info-value "  id="user-name" *ngIf="!showEditForm">{{personalForm.get('name')?.value}}
                </div>
                <div class="col-lg-7 " *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class=" w-100  input-font">
                        <input matInput required formControlName="name">
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('name')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="showPersonalInfo">
                <div class="label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Date of Birth</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">{{personalForm.get('dob')?.value |
                    date:'dd/MM/yyyy'}}</div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput [matDatepicker]="datepicker" formControlName="dob" [max]="maxDate"
                            placeholder="mm/dd/yyyy">
                        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker color="primary"></mat-datepicker>
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('dob')?.hasError('required')"><img class="error-icon" loading="lazy"
                                alt="" src="./../../assets/images/alert.svg" /> DOB is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="!showPersonalInfo">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Established In</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">
                    {{personalForm.get('establishedIn')?.value | date:'dd/MM/yyyy'}}</div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput [matDatepicker]="datepicker" formControlName="establishedIn"
                            placeholder="mm/dd/yyyy">
                        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker color="primary"></mat-datepicker>
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('establishedIn')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                            Established In is Required</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="!showPersonalInfo">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">No. of Employees
                </div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">
                    {{personalForm.get('noEmployees')?.value}}</div>
                <div class="col-lg-7" *ngIf="showEditForm">

                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput required (keypress)="numberOnly($event)" formControlName="noEmployees">

                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('noEmployees')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> No. of
                            Employees is Required</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row  " [class.mb-2]="!showEditForm">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Email Address</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">{{personalForm.get('email')?.value}}
                </div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput required formControlName="email" readonly>
                        <!--    <mat-error class="error-message error-msg"
                            *ngIf="organisationInfoForm.get('email')?.hasError('required')"><img
                                class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" /> Email
                            Address is Required</mat-error>
                            <mat-error class="error-message error-msg"
                            *ngIf="organisationInfoForm.get('email')?.hasError('pattern')"><img
                                class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" /> 
                             Enter valid email</mat-error> -->
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm} ">Phone</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">{{personalForm.get('phone')?.value}}
                </div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput required formControlName="phone">
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('phone')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone
                            Number is Required</mat-error>
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('phone')?.hasError('pattern')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Enter valid phone
                            number</mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="showPersonalInfo">
                <div class=" label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Gender</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">{{personalForm.get('gender')?.value}}
                </div>
                <div class="col-lg-7" style="margin-top: -10px;margin-bottom: 10px;" *ngIf="showEditForm">
                    <mat-radio-group required class="d-flex" [formControl]="gender">
                        <mat-radio-button value="Male">Male</mat-radio-button>
                        <mat-radio-button value="Female">Female</mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="error-message "
                        *ngIf="personalForm.controls['gender']?.invalid && personalForm.controls['gender']?.touched"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Gender is
                        Required</mat-error>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="showPersonalInfo">
                <div class="label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}"> Position</div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">
                    {{personalForm.get('position')?.value?.name}}</div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <mat-select placeholder="Select" formControlName="position">
                            <mat-option *ngFor="let position of positionDropdown"
                                [value]="position">{{position?.name}}</mat-option>
                        </mat-select>
                        <!-- <input matInput required formControlName="position" > -->

                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('position')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Position is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm" *ngIf="showPersonalInfo">
                <div class="label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">Currently Working on
                </div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm">
                    {{personalForm.get('workingIn')?.value}}</div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font  w-100">
                        <input matInput required formControlName="workingIn">
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('workingIn')?.hasError('required')"><img class="error-icon"
                                loading="eager" alt="" src="./../../assets/images/alert.svg" />
                            Currently Working in is Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="label" [ngClass]="{'col-lg-5':showEditForm,'col-lg-6':!showEditForm}">{{ showPersonalInfo ? 'Portfolio' : ' Website ' }} URL </div>
                <div class="col-lg-6 personal-info-value " *ngIf="!showEditForm"><a class="link"
                        (click)="openLink(personalForm.get('url')?.value)">{{personalForm.get('url')?.value}}</a></div>
                <div class="col-lg-7" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font  w-100">
                        <input matInput required formControlName="url">
                        <mat-error class="error-message error-msg" *ngIf="personalForm.get('url')?.hasError('required')">
                            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Website URL is Required
                        </mat-error>
                        <mat-error class="error-message error-msg" *ngIf="personalForm.get('url')?.hasError('pattern')">
                            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Please enter a valid URL
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

        </div>
        <div class="col-lg-6 ">
            <div class="row ">
                <h4 class="profile-h4">Address Info</h4>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="col-lg-4 label">Country </div>
                <div class="col-lg-8 personal-info-value ps-0" *ngIf="!showEditForm">
                    {{personalForm.get('country')?.value}} </div>
                <div class="col-lg-8" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="country"
                            #autoCompleteCountryInput="matAutocompleteTrigger" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getStatesDropdown()">
                            <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName">
                                {{ option?.countryName }}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('country')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Country is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="col-lg-4 label">State </div>
                <div class="col-lg-8 personal-info-value ps-0" *ngIf="!showEditForm">
                    {{personalForm.get('state')?.value}} </div>
                <div class="col-lg-8" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="state"
                            [matAutocomplete]="stateAuto" #autoCompleteStateInput="matAutocompleteTrigger">
                        <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="getCityDropdown()">
                            <mat-option *ngFor="let option of stateDropdown" [value]="option?.stateName">
                                {{ option?.stateName }}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('state')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> State is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="col-lg-4 label">City </div>
                <div class="col-lg-8 personal-info-value ps-0" *ngIf="!showEditForm">{{personalForm.get('city')?.value}}
                </div>
                <div class="col-lg-8" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="city"
                            [matAutocomplete]="cityAuto" #autoCompleteCityInput="matAutocompleteTrigger">
                        <mat-autocomplete #cityAuto="matAutocomplete">
                            <mat-option *ngFor="let option of cityDropdown" [value]="option?.cityName">
                                {{ option?.cityName }}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('city')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> City is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="col-lg-4 label">ZIP </div>
                <div class="col-lg-8 personal-info-value ps-0" *ngIf="!showEditForm">
                    {{personalForm.get('zipCode')?.value}} </div>
                <div class="col-lg-8" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput required formControlName="zipCode">
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('zipCode')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> ZIP Code is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row  " [class.mb-2]="!showEditForm">
                <div class="col-lg-4 label">Address </div>
                <div class="col-lg-8 personal-info-value ps-0" *ngIf="!showEditForm">
                    {{personalForm.get('address')?.value}}</div>
                <div class="col-lg-8" *ngIf="showEditForm">
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput required formControlName="address">
                        <mat-error class="error-message error-msg"
                            *ngIf="personalForm.get('address')?.hasError('required')"><img class="error-icon"
                                loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Address is
                            Required</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4" *ngIf="!showEditForm">
        <div class="col-lg-2 d-flex justify-content-center">
            <img alt="" class="profile_pic" src="{{personalForm.get('logo')?.value}}" >
        </div>
        <div class="col-lg-4 d-flex flex-column justify-content-center">
            <h1 class="star-hosp mb-0" *ngIf="this.personalForm?.get('account')?.value?.accountType?.name === 'Individual'" id="user-name">{{personalForm.get('name')?.value}}</h1>
            <h1 class="star-hosp mb-1" *ngIf="this.personalForm?.get('account')?.value?.accountType?.name === 'Organization'" id="user-name">{{personalForm.get('organization')?.value}}</h1>
            <div class="d-flex m-0" *ngIf="!showPersonalInfo">
                <label class="etsablish">Established: &nbsp;</label>
                <p class="reg-date"> {{personalForm.get('establishedIn')?.value | date:'dd/MM/yyyy'}}</p>
            </div>
            <div class="d-flex m-0" *ngIf="showPersonalInfo">
                <label class="etsablish">Date of Birth: &nbsp;</label>
                <p class="reg-date"> {{personalForm.get('dob')?.value | date:'dd/MM/yyyy'}}</p>
            </div>

            <!--  <button class="mwe-button med-world-basic-font med-world-basic-font__medium  ps-3 pe-3  p-1 ">Change
                Photo</button> -->
            <div class="d-flex m-0">
                <label class="address">Address:&nbsp;</label>
                <p> {{personalForm.get('address')?.value}} - {{personalForm.get('zipCode')?.value}}</p>
            </div>
        </div>
        <div class="col-lg-1  vertical-line">

        </div>
        <div class="col-lg-5">
            <div class="d-flex">

                <p class="org-label">Email: &nbsp;</p>{{personalForm.get('email')?.value}}
            </div>
            <div class="d-flex " style="margin-top: -6px;">

                <p class="org-label">Phone: &nbsp;</p>{{personalForm.get('phone')?.value}}

            </div>
            <div class=" d-flex" style="margin-top: -6px;" *ngIf="!showPersonalInfo">
                <p class="org-label">Employees: &nbsp;</p>{{personalForm.get('noEmployees')?.value}}

            </div>
            <div class=" d-flex" style="margin-top: -6px;" *ngIf="!showPersonalInfo">
                <p class="org-label">Website Url: &nbsp;</p><a href="{{personalForm.get('url')?.value}}"><span
                        style="color: #0d87c8">{{personalForm.get('url')?.value}}</span> </a>

            </div>
            <div class="" style="margin-top: -6px;" *ngIf="showPersonalInfo">
                <div class="d-flex">
                    <p class="org-label">Currently Working: &nbsp;</p>{{personalForm.get('workingIn')?.value}}
                </div>
                <!--                 <div class="col-lg-6"> </div> -->
            </div>
            <div class="" style="margin-top: -6px;" *ngIf="showPersonalInfo">
                <div class="d-flex">
                    <p class="org-label">Position: &nbsp;</p> {{personalForm.get('position')?.value?.name}}
                </div>
                <!-- <div class="col-lg-9"></div> -->
            </div>

            <div class=" d-flex" style="margin-top: -6px;" *ngIf="showPersonalInfo">

                <p class="org-label">Portfolio URL: &nbsp;</p>
                <a class="link" style="text-decoration: none;"
                    (click)="openLink(personalForm.get('url')?.value)">{{personalForm.get('url')?.value}}</a>
            </div>


        </div>
    </div>








    

    <div class="action " *ngIf="showEditForm">


        <button class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3"
            (click)="update()">Update</button>
        <button class="mwe-button mwe-button--secondary ps-3 pe-3 " (click)="cancel()">Cancel</button>



        <!--   <span *ngIf="showErrorMsg">
            <mat-error class="p-2 error-message error-msg"
            ><img class="error-icon"
                loading="eager" alt="" src="./../../assets/images/alert.svg" /> please enter all required fields</mat-error>
        </span> -->
    </div>
</div>
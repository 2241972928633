import { SweetAlertService } from './../../../../services/sweetalert.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-webinar',
  templateUrl: './register-webinar.component.html',
  styleUrls: ['./register-webinar.component.scss']
})

export class RegisterWebinarComponent implements OnInit {
  registerForm!: FormGroup;
  userDetails: any;
  webinarId: string = '';
  userId: string = '';
  clicked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private localStorageService: LocalStorageService,
    private sweetAlertService: SweetAlertService,
    private dialogRef: MatDialogRef<RegisterWebinarComponent>,
    private webinarService: WebinarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.webinarId = data.webinarId;
    this.initializeForm();
  }

  ngOnInit(): void {
    this.userDetails = this.localStorageService.getItem('userDetails');
    if (this.userDetails) {
      this.userDetails = JSON.parse(this.userDetails);
      this.userId = this.userDetails.userId || '';
      this.registerForm.patchValue({
        name: this.userDetails.userName || '',
        email: this.userDetails.email || '',
        phone: this.userDetails.phone || ''
      });
    }
  }

  initializeForm() {
    console.log('Starting initialization');
    this.registerForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern('^[0-9]{5,15}$')]],
    });
    console.log('Initialization done');
  }

  submit() {
    this.clicked = true;
    if (this.registerForm.valid) {
      this.registerWebinar();
      this.dialogRef.close();
      
    }
    console.log('submittion done');
  }

  registerWebinar() {
    const regData = {
      customerName: this.registerForm.value.name,
      email: this.registerForm.value.email,
      phoneNumber: String(this.registerForm.value.phone),
      webinarPostId: this.webinarId,
      userId: this.userId,
    };

    this.webinarService.register(regData).subscribe(
      (res: any) => {
        this.showSuccessAlert();
        // Handle successful registration
      },
      (error: any) => {
        this.sweetAlertService.showError('Registration failed!!', 'You have already registered for this webinar.');
        console.error('Registration failed', error);
      }
    );
  }

  showSuccessAlert() {
    this.sweetAlertService.showSuccess(
      '',
      'You have successfully registered for the webinar. The Webinar link and details will be sent to your email address.'
    );

    const confirmButton = Swal.getConfirmButton();
    if (confirmButton) {
      confirmButton.textContent = 'Close';
    }

    const container = Swal.getHtmlContainer();
    if (container) {
      container.style.color = '#0D87C8';
      container.style.fontWeight = '500';
      container.style.fontSize = '24px';
      container.style.lineHeight = '36px';
    }

    const pop = Swal.getPopup();
    if (pop) {
      pop.style.borderRadius = '30px';
      pop.style.boxShadow = '0px 10px #94C743';
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

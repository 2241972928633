<div class="d-flex justify-content-between mb-2">
    <h2 class="page-heading" style="cursor: pointer;" (click)="onBack()">Back to all jobs</h2>
    <img src="/assets/images/download.svg" style="cursor: pointer;" (click)="downloadPdf()" alt="apply-icon"
        *ngIf="downloadProfile">
</div>

<div class="job-applicant-details-container" id="careers">

    <div class="row">
        <div class="col-lg-10">

            <div class="card-container">

                <h2 class="page-heading">Personal Information</h2>

                <div class="profile-card mt-1">
                    <div class="profile-info">
                        <img [src]="jobApplicant?.logo" alt="Profile Image">
                        <div class="profile-details">
                            <div class="name">{{jobApplicant?.name}}</div>
                            <div class="profession">{{jobApplicant?.position?.name}}</div>
                            <div class="industry">Healthcare &amp; Medical</div>
                        </div>
                    </div>
                    <div class="status-update" *ngIf="!downloadProfile">
                        <label for="application-status" class="form-label">Update Application Status</label>
                        <select id="application-status" class="form-select" #applicationStatus
                            (change)='updateStatus(applicationStatus.value)'>
                            <option selected>Select</option>
                            <option value="SHORTLISTED">Shortlisted</option>
                            <option value="PENDING">Pending</option>
                            <option value="REJECTED">Rejected</option>
                        </select>
                    </div>
                </div>

                <table class="table m-3">
                    <thead>
                        <tr>
                            <th colspan="2">Personal Information</th>
                            <th colspan="2">Address Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{{jobApplicant?.name}}</td>
                            <td>Country</td>
                            <td>{{jobApplicant?.country}}</td>
                        </tr>
                        <tr>
                            <td>Date of Birth</td>
                            <td>{{jobApplicant?.dob | date:'dd/MM/YYYY'}}</td>
                            <td>State</td>
                            <td>{{jobApplicant?.state}}</td>
                        </tr>
                        <tr>
                            <td>Email Address</td>
                            <td>{{jobApplicant?.email}}</td>
                            <td>City</td>
                            <td>{{jobApplicant?.city}}</td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td>({{jobApplicant?.phoneCode}}) {{jobApplicant?.phone}}</td>
                            <td>ZIP</td>
                            <td>{{jobApplicant?.zipCode}}</td>
                        </tr>
                        <tr>
                            <td>Gender</td>
                            <td>{{jobApplicant?.gender}}</td>
                            <td>Address</td>
                            <td>{{jobApplicant?.address}}</td>
                        </tr>
                        <tr>
                            <td>Currently Working on</td>
                            <td>{{jobApplicant?.workingIn}}</td>
                        </tr>
                        <tr>
                            <td>Position</td>
                            <td>{{jobApplicant?.position?.name}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="card-container">

                <div class="page-heading">About You</div>

                <p class="about-info" [innerHTML]="jobApplicant?.aboutId?.about"></p>

                <div class="media-links-label">Social Media Links</div>

                <div class="d-flex mt-2 px-4 pb-3">
                    <div class="social-media-border mx-1" *ngIf="jobApplicant?.aboutId?.facebookUrl">
                        <img src="/assets/images/FacebookAppSymbol.svg" alt="" srcset="">

                    </div>
                    <div class="social-media-border mx-1" *ngIf="jobApplicant?.aboutId?.twitterUrl">
                        <img src="/assets/images/Twitter.svg" alt="" srcset="">

                    </div>
                    <div class="social-media-border mx-1" *ngIf="jobApplicant?.aboutId?.linkedin">
                        <img src="/assets/images/LinkedinLogo.svg" alt="" srcset="">

                    </div>
                    <div class="social-media-border mx-1" *ngIf="jobApplicant?.aboutId?.instagramUrl">
                        <img src="/assets/images/Instagram.svg" alt="" srcset="">

                    </div>
                </div>
            </div>

            <div class="card-container">
                <div class="page-heading">Specialities</div>
                <div class="px-4 pb-3">
                    <mat-chip *ngFor="let speciality of jobApplicant.specialityInfos"
                        class="me-2 mb-2">{{getSpecialityName(speciality.specialityInfoId)}}</mat-chip>
                </div>
            </div>

            <div class="card-container">

                <div class="page-heading">Education Details</div>
                <div class="px-4 pb-3">
                    <app-card-profile image="assets/images/degree-icon.svg" [data]="jobApplicant?.educationInfos"
                        [isEducationCard]="true"></app-card-profile>
                </div>
            </div>

            <div class="card-container">

                <div class="page-heading">Work Experience</div>
                <div class="px-4 pb-3">
                    <app-card-profile image="assets/images/hospital.svg" [data]="jobApplicant?.workInfos"
                        [isWorkCard]="true" [keys]="workExpColumns">
                    </app-card-profile>
                </div>
            </div>

            <div class="card-container">

                <div class="page-heading">Skill Set</div>
                <div class="px-4 pb-3">
                    <mat-chip *ngFor="let skill of jobApplicant.skillsets"
                        class="me-2 mb-2">{{getSkillSetName(skill.skillsetInfoId)}}</mat-chip>
                </div>
            </div>

            <div class="card-container">

                <div class="page-heading">Certifications & Licenses</div>
                <div class="px-4 pb-3">

                    <ng-container *ngFor="let certification of jobApplicant?.certifications; let i = index">
                        <div class="d-flex align-items-center mt-2">
                            <img class="me-3" src="assets/images/Certifications.svg" alt="Card Profile" />
                            <div>
                                <div style="font-weight: 600;">{{ certification.name }}</div>
                                <a style="text-decoration: none;" href="{{certification.certificate}}" target="_blank"
                                    *ngIf="certification.name">
                                    <div style="color: #0d87c8;text-decoration: underline">View Certificate</div>
                                </a>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>

            <div class="card-container">

                <div class="page-heading">More Info</div>

                <div class="px-3 pb-3">
                    <div class="media-links-label" style="padding-left: 0.5rem;">Publications</div>
                    <app-mwe-table [columns]="publicationColumns" [showPagination]="false" [showHeader]="false"
                        [data]="getSpecifiedData('publications')"></app-mwe-table>
                </div>

                <div class="px-3 pb-3">
                    <div class="media-links-label" style="padding-left: 0.5rem;">Awards & Honors</div>
                    <app-mwe-table [columns]="awardsColumns" [showPagination]="false" [showHeader]="false"
                        [data]="getSpecifiedData('awards')"></app-mwe-table>
                </div>

                <div class="px-3 pb-3">
                    <div class="media-links-label" style="padding-left: 0.5rem;">Courses & Training</div>
                    <app-mwe-table [columns]="coursesColumns" [showPagination]="false" [showHeader]="false"
                        [data]="getSpecifiedData('courseTrainings')"></app-mwe-table>
                </div>

                <div class="px-3 pb-3">
                    <div class="media-links-label" style="padding-left: 0.5rem;">Associations & Organizations</div>
                    <app-mwe-table [columns]="associationColumns" [showPagination]="false" [showHeader]="false"
                        [data]="getSpecifiedData('associations')"></app-mwe-table>
                </div>

                <div class="px-3 pb-3">
                    <div class="media-links-label" style="padding-left: 0.5rem;">Volunteering & Causes</div>
                    <app-mwe-table [columns]="volunteeringColumns" [showPagination]="false" [showHeader]="false"
                        [data]="getSpecifiedData('volunteerings')"></app-mwe-table>
                </div>

            </div>

        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-skill-set-profile',
  templateUrl: './skill-set-profile.component.html',
  styleUrls: ['./skill-set-profile.component.scss']
})
export class SkillSetProfileComponent implements OnInit{

  @Input() skillSetForm!:FormGroup;

  skillSets:any=[];

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  showEditForm:boolean=false

  copySkillSetForm:any;

  skillSetsData:any=[];

  constructor(private endCustomerService:EndCustomerService){}

  ngOnInit(): void {
    this.getSkillSets();
  }

  getSkillSets(){
    this.endCustomerService.executeGetRequestURL(MweConstants.skillSetUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.skillSets=data.data;
        if( this.skillSetForm.get('skillSets')?.value &&this.skillSetForm.get('skillSets')?.value.length>0){
          this.getSkillSetData();
        }
      }
    },error=>{

    })
  }

  update(){
    this.onUpdate.emit(80);
  }

  cancel(){
    this.showEditForm=false;
  
       this.skillSetForm.get('skillSets')?.patchValue([]); 
       
  if(this.copySkillSetForm?.skillSets && this.copySkillSetForm?.skillSets.length>0){
    this.skillSetForm.get('skillSets')?.patchValue(this.copySkillSetForm?.skillSets); 
  
   
  }
  this.getSkillSetData(); 
   
  
  }

  editSkillSet(){
    this.copySkillSetForm=JSON.parse(JSON.stringify(this.skillSetForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    this.getSkillSetData();
  }

  getSkillSetData(){

    const skillSet=this.skillSets;
    
    let skillsetInfoIds:any=[];
    
    if(this.endCustomerData?.skillSets && this.endCustomerData.skillSets.length>0){
      skillsetInfoIds= skillSet.filter((obj1:any)=>{
        
        return this.endCustomerData.skillSets.some((obj2:any)=>{
         
          return obj1.id===obj2.skillsetInfoId;
        })
      })
    }
    

    this.skillSetsData=skillsetInfoIds;
  }

 remove(i:number): void {
  this.skillSetForm.get('skillSets')?.value.splice(i,1);
  this.skillSetForm.get('skillSets')?.patchValue(this.skillSetForm.get('skillSets')?.value);
}

getSkillSet(id:any){
  return this.skillSets.find((obj:any)=>obj?.id===id)?.name;
 }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage.service';
import { UserDetails } from 'src/app/models/authData';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  userData!: UserDetails;
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.localStorageService.getUserDetails().subscribe(userDetails => { 
      this.userData = userDetails;
    });
   }

  public uploadFile(file: FormData): Observable<ApiResponseModel<string>> {
    return this.http.post<ApiResponseModel<string>>("https://dev.medworldexpo.com/auth/api/" + 's3/upload', file);
  }

  public uploadFilesAndGetUrls(files: File[], destinationKey: string): Observable<string[]> {
    const formDataArray: FormData[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('destinationKey', this.userData.userName + '/' + destinationKey + '/');
      formDataArray.push(formData);
    }

    const uploadRequests: Observable<ApiResponseModel<string>>[] = [];
    for (const formData of formDataArray) {
      uploadRequests.push(this.uploadFile(formData));
    }

    return forkJoin(uploadRequests).pipe(
      map(responses => responses.map(response => response.data))
    );
  }

}

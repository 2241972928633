<div class="bottom-footer">
  
  <footer class="medworldexpo-footer p-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6"> 
          <img src="./assets/images/account_type/whitelogo.svg" alt="logo of medworld">
          <p class="text-white mt-2">
            At Digiworld Expo, we help everyone with a B2B project make the right purchasing decision.
            We offer them carefully organized information by selecting quality products and suppliers from around the world.
            Day after day, our incentive is to help businesses carry out their projects.
            A lack of quality information will never again be an obstacle to their success.
          </p>
        </div>
        <div class="col-md-2"></div>
        <div class="col-md-2 pt-4 mx-auto">
          <h4 class="text-white">Useful Links</h4>
          <ul class="text-white usefullinks">
           <a> <li [routerLink]="['/']">Products</a> 
           <a> <li [routerLink]="['/brands']">Brands</a> 
           <a> <li [routerLink]="['/events']">Events</a> 
           <a> <li [routerLink]="['/']">News & Trends</a> 
           <a> <li [routerLink]="['/']">Knowledge Center</a> 
           <a> <li [routerLink]="['/']">Careers</a> 
           <a> <li [routerLink]="['/subscriptions']">Subscriptions</a> 
          </ul>
        </div>

        <div class="col-md-2 pt-4">
          <h4 class="text-white">Know More</h4>
          <ul class="text-white knowmore">
            <a> <li [routerLink]="['/aboutus']">About Us</a> 
            <a> <li [routerLink]="['/how-it-work']">How It Works</a> 
            <a> <li [routerLink]="['/signup']">Exhibit With Us</a> 
            <a> <li [routerLink]="['/privacy-policy']">Privacy Policy</a> 
            <a> <li [routerLink]="['/terms-and-conditions']">Terms & Conditions</a> 
            <a> <li [routerLink]="['/contactus']">Contact Us</a> 
          </ul>
        </div>
      </div>
    </div>
  </footer>
  
  <div class="sub-container bg-dark p-2">
    <div class="container-subfooter">
      <p class="m-0 text-white">© 2024 MedWorld. All rights reserved.</p>
      <div class="social-media-links" style="display: flex; flex-direction: row; gap: 20px">
        <img src="./assets/images/account_type/Facebook App Symbol.svg" alt="f" class="social-media-imgs">
        <img src="./assets/images/account_type/Twitter.svg" alt="f" class="social-media-imgs">
        <img src="./assets/images/account_type/Youtube.svg" alt="f" class="social-media-imgs">
        <img src="./assets/images/account_type/Linkedin Big Logo.svg" alt="f" class="social-media-imgs">
        <img src="./assets/images/account_type/Instagram.svg" alt="InstagramImg" class="social-media-imgs">
      </div>
    </div>
  </div>
  
</div>
import { Component, Input ,OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MasterDataService } from 'src/app/services/master-data.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { LocationManagementComponent } from '../location-management/location-management.component';
import Swal from 'sweetalert2';
import { catchError, of } from 'rxjs';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { BreadcrumbService } from 'src/app/services/shared/breadcrumb.service';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.css']
})
export class MasterDataComponent implements OnInit {
  @Input() public id!: string;
  Data: any[] = [];
  columns: any[] = []; 
  activeContent: string = '';
  pageIndex: number = 0;
  showNoRecordsFounds: boolean = true;
  isSearch: boolean = false;
  startsWith: string = '';
  pageSize: number = 10;
  paginationState: { [key: string]: { pageIndex: number, pageSize: number} } = {};
  buttons = [
    { id: 'Continent', label: 'Continent'},
    { id: 'Country', label: 'Country' },
    { id: 'State', label: 'States' },  
    { id: 'City', label: 'Cities' },
    {id:"Account Type",label:"Account Types"},
    {id:"Speciality",label:"Specialities"},
    {id:"Position",label:"Positions"},
    {id:"Lead Source",label:"Lead Sources"},
    {id:"Job Category",label:"Job Categories"},
    {id:"Job Skill",label:"Job Skills"},
    {id:"Job Type",label:"Job Types"},
    {id:"Experience Level",label:"Experience Level"},
    {id:"Degree Level",label:"Degree Level"},
    {id:"Job Tag",label:"Job Tags"},
  ];

  

  constructor(public dialog: MatDialog, private masterDataService: MasterDataService, private  spinnerService : SpinnerService , private breadCrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    if (this.buttons.length > 0) {
      this.activeContent = this.buttons[0].id;
    }
    this.breadCrumbService.setBreadcrumbs(['Dashboard' , this.activeContent]);
    this.toggleContent(this.activeContent);
    this.startsWith = '';
    this.isSearch = false;
  
  }

  applyFilter(event: any) {
    this.isSearch = true;
    this.startsWith = event;
    this.pageIndex = 0;
    this.toggleContent(this.activeContent);
  }
  onPageChanged(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.toggleContent(this.activeContent);
  }
  toggleContent(contentId: string) {

    if(this.activeContent!==contentId){
      this.startsWith = '';
      this.pageIndex = 0;
          
    }
    this.activeContent = contentId;
    
    this.breadCrumbService.setBreadcrumbs(['Dashboard' , this.activeContent]);
   
    switch (this.activeContent) {
      case 'Continent':
        if(!this.isSearch){
                if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        }
        
        this.masterDataService.getContinents().subscribe(data => {
          if(!this.isSearch){
            this.spinnerService.hideSpinner();
          }
          
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Continent Code', property: 'continentCode', visible: true, isModelProperty: true, isText: true },
            { name: 'Continent Name', property: 'continentName', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
                
              ]
            }
          ]);
        });
        break;
      case 'Country':
         
      if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getCountries(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          if(!this.isSearch){
            this.spinnerService.hideSpinner();
          }
          this.setData(data, [
            { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Country Name', property: 'countryName', visible: true, isModelProperty: true, isText: true },
            { name: 'Continent Code', property: 'continentCode', visible: true, isModelProperty: true, isText: true },
            { name: 'Country Code', property: 'countryCode', visible: true, isModelProperty: true, isText: true },
            { name: 'Phone Code', property: 'phoneCode', visible: true, isModelProperty: true, isText: true },
            { name: 'Currency Symbol', property: 'currencySymbol', visible: true, isModelProperty: true, isText: true },
            { name: 'Currency', property: 'currency', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
                
              ]
            },
          ]);
        });
          
        break;
        case 'State':
                if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
          this.masterDataService.getStates(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
            this.spinnerService.hideSpinner();
            this.setData(data, [
              { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
              { name: 'State Name', property: 'stateName', visible: true, isModelProperty: true, isText: true },
              { name: 'State Code', property: 'stateCode', visible: true, isModelProperty: true, isText: true },
              { name: 'Country Name', property: 'countryName', visible: true, isModelProperty: true, isText: true },
              { name: 'Country Code', property: 'countryCode', visible: true, isModelProperty: true, isText: true },
              {
                name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
                actions: [
                  { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                  { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                  { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
                ]
              },
            ]);
          });
          
            
          break;
      case 'City':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getCities(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
            { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'City Name', property: 'cityName', visible: true, isModelProperty: true, isText: true },
            { name: 'State Name', property: 'stateName', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
         
       
        break;

    case 'Account Type':
            if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
      this.masterDataService.getAccountTypes(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
        this.spinnerService.hideSpinner();
        this.setData(data, [
          { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
          { name: 'Account Type', property: 'accountType', visible: true, isModelProperty: true, isText: true },
          {name:'Account Category',property:'accountCategory',visible:true,isModelProperty:true,isText:true},
          {
            name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
            actions: [
              { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
              { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
              { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
            ]
          },
        ]);
      });
      
        
      break;
      case 'Speciality':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getSpecialities(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
            { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Speciality', property: 'specialty', visible: true, isModelProperty: true, isText: true },
            {name:'Account Type',property:'accountsTypes',visible:true,isModelProperty:true,isObject:true},
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
         
          
        break;
      case 'Position':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getPositions(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Positions', property: 'position', visible: true, isModelProperty: true, isText: true },
            { name: 'Account Type', property: 'accountType', visible: true, isModelProperty: true, isObject: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);  
        });
        
          
        break;
      case 'Lead Source':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getLeadSources(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Source Name', property: 'sourceName', visible: true, isModelProperty: true, isText: true },
           
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
          
        });
         
        
        break;
      case 'Job Category':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getJobCategories(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Category Name', property: 'categoryName', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
         
        
        break;
      case 'Job Skill':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getJobSkills(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Job Skill', property: 'skillName', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
         
        
        break;
      case 'Job Type':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getJobTypes(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Job Type', property: 'jobType', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
        
          
        break;
      case 'Experience Level':
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getCareerLevels(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Experience Level', property: 'experienceLevel', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        });
        
          
        break;
      case "Degree Level":
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getDegreeLevels(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Degree Level', property: 'degreeLevel', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        })
         
        
        break;
      case "Job Tag":
              if(!this.isSearch){
        this.spinnerService.showSpinner();
      }
        this.masterDataService.getJobTags(this.pageIndex, this.pageSize,this.startsWith).subscribe(data => {
          this.spinnerService.hideSpinner();
          this.setData(data, [
             { name: 'Sr. No', property: 'sNo', visible: true, isModelProperty: false, isText: true },
            { name: 'Job Tag', property: 'jobTags', visible: true, isModelProperty: true, isText: true },
            {
              name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
              actions: [
                { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
                { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
                { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
              ]
            },
          ]);
        })
         
        
        break;
      default:
        console.error('Invalid id');
        break;
    }
  }
  
  private setData(data: any, columns: any[]): void {
    
    this.Data = data.data;
    if(this.Data.length==0){
      this.showNoRecordsFounds=true;
    }
    else{
      this.showNoRecordsFounds=false;
    }
    this.columns = columns;
  }
openAddLocation() {
    const dialogRef = this.dialog.open(LocationManagementComponent, {
      width: '20%',
      data: {
        type: this.activeContent,
        headings: this.columns
          .filter(column => !column.hasAction && column.property !== 'sNo')
          .map(column => ({ name: column.name, property: column.property }))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.toggleContent(this.activeContent);
      }
    });
  }
  openEditLocation(rowData: any) {
    const dialogRef = this.dialog.open(LocationManagementComponent, {
      width: '20%',
      data: {
        type: this.activeContent,
        headings: this.columns
          .filter(column => !column.hasAction && column.property !== 'sNo')
          .map(column => ({ name: column.name, property: column.property })),
        rowData: rowData
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.toggleContent(this.activeContent);
      }
    });
  }
  
  openViewLocation(rowData: any) {
    const dialogRef = this.dialog.open(LocationManagementComponent, {
      width: '20%',
      data: {
        type: this.activeContent,
        headings: this.columns
          .filter(column => !column.hasAction && column.property !== 'sNo')
          .map(column => ({ name: column.name, property: column.property })),
        rowData: rowData,
        viewMode: true 
      }
    });
  
    
  }
  deleteItem(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.masterDataService.deleteItem(this.activeContent, id).pipe(
          catchError(err => {
            Swal.fire(
              'Error!',
              'An error occurred while deleting the item.',
              'error'
            );
            return of(null);
          })
        ).subscribe(() => {
          Swal.fire(
            'Deleted!',
            'Item has been deleted.',
            'success'
          );
          this.toggleContent(this.activeContent);
        });
      }
    });
  }


  selectedAction(event:any) {
    switch (event.action) {
      case MweConstants.addAction:
        this.openAddLocation();
        break;
      case MweConstants.editAction:
        this.openEditLocation(event.row);
        break;
      case MweConstants.deleteAction:
        this.deleteItem(event.row.id);
        break;
      case MweConstants.viewAction:
        this.openViewLocation(event.row);
        break;
    
      default:
        break;
    }
  }
}

import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { TodoItemFlatNode, TodoItemNode } from 'src/app/models/product-settings-model';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { ChecklistDatabase } from 'src/app/services/shared/product-settings/check-list-database';

@Component({
  selector: 'app-add-product-categories',
  templateUrl: './add-product-categories.component.html',
  styleUrls: ['./add-product-categories.component.scss']
})
export class AddProductCategoriesComponent implements OnInit{

  treeControl!: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener!: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedNode = new TodoItemFlatNode();
  categoryData : any;
  isEditMode = false;
  isAnyNodeSelected = false;


  
  constructor(private _database: ChecklistDatabase,
    private categoryService : CategoryService) {
      this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
        this.isExpandable, this.getChildren);
      this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  
      _database.dataChange.subscribe(data => {
        this.dataSource.data = data;
      });
    }
  ngOnInit(): void {
      this.getAllCategories();
  }

  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable =!!node.children && node.children.length > 0
    flatNode.id = node.id;  // Ensure id is set here
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.selectedNode = node;
    node.selected = !node.selected;
    this.updateSelectionState();
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  updateSelectionState(): void {
    this.dataSource.data = [...this.dataSource.data]; // Trigger change detection
  }

  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.selectedNode = node;
    const categoryPayload = {
      id:  node.id,
      name:  node.item,
   };
    // this.category?.setValue(categoryPayload); 
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    node.selected = !node.selected;
    this.updateSelectionState();
  }

  markSelectedNodes() {
    if (this.isEditMode) 
      { 
        this.treeControl.dataNodes.forEach(node => {
          if (node.id === this.selectedNode.id) {
              node.selected = true;
          }
        });
          if(!this.isAnyNodeSelected)  this.todoLeafItemSelectionToggle(this.selectedNode)
            this.treeControl.expandAll();
      }
  }

  getAllCategories() {
    this.categoryService.getAllParentCategories().subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          console.log('response.data-1', response.data);
          this.categoryData = response.data;
          this._database.initialize(this.categoryData);
        } 
      },
      error: (error) => {
        console.error('Error fetching levels data:', error);
      }
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ListColumn } from 'src/app/models/list-column.model';

@Component({
  selector: 'app-associations-profile',
  templateUrl: './associations-profile.component.html',
  styleUrls: ['./associations-profile.component.scss']
})
export class AssociationsProfileComponent implements OnInit{

  associationColumns: ListColumn[] = [
    { name: 'Association Name', property: 'associationName', visible: true, isModelProperty: true ,isText: true},
    {name:'Position Held',property:'positionHeld',visible:true,isModelProperty:true,isText:true},
    {name:'From',property:'fromDate',visible:true,isModelProperty:true,isDatePipe:true},
    {name:'To',property:'toDate',visible:true,isModelProperty:true,isDatePipe:true},
  ] as ListColumn[];

  data:any[]=[];

  @Input() associationsAndOrganizationsForm!:FormGroup;

  @Input() endCustomerData:any;
  showEditForm:boolean=false;
  copyAssociationsForm:any;
  

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  constructor(private fb:FormBuilder){}
  ngOnInit(): void {
    this.data=JSON.parse(JSON.stringify(this.associations(0).value));
  }

  get moreInfos(){
    return this.associationsAndOrganizationsForm.controls['moreInfos'] as FormArray;
   }

   associations(i:number){
     return (this.moreInfos.at(i) as FormGroup).controls['associations'] as FormArray;
   }
 
   getMoreInfoFormGroup(i:number){
     return (this.moreInfos.at(i) as FormGroup);
   }

   addAssociation(){
    this.copyAssociationsForm=JSON.parse(JSON.stringify(this.associationsAndOrganizationsForm.value));
    this.associations(0).push(
      
      this.fb.group({
        id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
        "associationName"  :new FormControl(null),
         "positionHeld": new FormControl(null),
         "fromDate": new FormControl(null),
         "toDate": new FormControl(null),
         "summary": new FormControl(null),
       }),
    )
    this.showEditForm=true;
  }

  deleteAssociation(i:number,j:number){
    this.associations(i).removeAt(j);
  }

   editAssociation(){
    this.copyAssociationsForm=JSON.parse(JSON.stringify(this.associationsAndOrganizationsForm.value));
    this.showEditForm=true;
  }
  update(){
 
    this.onUpdate.emit(130);
  }

  refresh(){
    this.showEditForm=false;
   // this.getSpecialityData();
   this.data=JSON.parse(JSON.stringify(this.associations(0).value));
  }

  cancel(){
    
    this.associations(0).clear();
     if(this.copyAssociationsForm?.moreInfos && this.copyAssociationsForm.moreInfos.length>0&&
      this.copyAssociationsForm.moreInfos[0]?.associations &&this.copyAssociationsForm.moreInfos[0].associations.length>0
     ){
          for(let i=0;i<this.copyAssociationsForm.moreInfos[0].associations.length;i++){
            this.associations(0).push( this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
              "associationName"  :new FormControl(null),
              "positionHeld": new FormControl(null),
              "fromDate": new FormControl(null),
              "toDate": new FormControl(null),
              "summary": new FormControl(null),
            }));
          }
          this.associations(0).patchValue(this.copyAssociationsForm.moreInfos[0].associations);
        }
       
    this.showEditForm=false;
    this.data=JSON.parse(JSON.stringify(this.associations(0).value));
  
  
  }
}

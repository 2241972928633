<div class="account-type-screen">
    <div class="top-menu">
      <div class="contact-information">
          <div class="contact-number">
              <div class="call-icon">
                  <img src="./../../../assets/images/phone rings.svg" alt="call-icon">
                  <h6>+91 9876543210</h6>
              </div>
             
      </div>
      <div class="contact-email">
          <div class="email-icon">
              <img src="./../../../assets/images/mail.svg" alt="email-icon">
              <h6>contact@medworldexpo.com</h6>
          </div>
         
      </div>
      </div>
      <div class="top-menu-rightside">
          <div class="account-icon">
              <img  src="./../../../assets/images/user round.svg" alt="account-icon">
          <h6>{{ 'MY_ACCOUNT' | translate }}</h6>
          <img class="dropdownarrow-black" src="./../../../assets/images/angle arrow small down.svg" alt="arrow-down-icon">
          </div>
          <div class="language-selection">
              <img src="./../../../assets/images/earth.svg" alt="language-icon">
          <h6>{{ 'LANGUAGE' | translate }}</h6>
          <img class="dropdownarrow-black" src="./../../../assets/images/angle arrow small down.svg" alt="arrow-down-icon">
          </div>
          <div class="currency">
              <h6>{{ 'CURRENCY' | translate }}</h6>
              <img class="dropdownarrow-black" src="./../../../assets/images/angle arrow small down.svg" alt="arrow-down-icon">
          </div>
          <button class="button" style="width:fit-content">
              <img src="./../../../assets/images/Exhibition.svg" alt="Exhibition-icon">
             <h6 style="font-size: 14px;">{{ 'EXHIBITION' | translate }}</h6>  
          </button>
          </div>
        
        
        </div>
   
   
          <!-- -------------------------navigation bar ---------------- -->
  <div  class="navigation-bar-container" style=" position: sticky;  top: 0;">
          <div class="navigationbar" id="navbar">
              <div class="nav-logo">
                  <img src="./../../../assets/images/logomedworldwhite.svg" alt="logo">
              </div>
             
              <div class="searchbar">
                  <img src="./../../../assets/images/search.svg" alt="searchbar">
                  <input type="text" placeholder="Search Products, Brands by Keyword here">
   
              </div>
              <div class="navlinks">
                  <div class="links">
                      <a href="#">{{ 'HOME' | translate }}</a>
                  </div>
                  <div class="links">
                      <a href="#">{{ 'PRODUCTS' | translate }}</a>
                      <img src="./../../../assets/images/Vector.svg" alt="arrow-down-icon">
                  </div>
                  <div class="links">
                      <a href="#">{{ 'OEM' | translate }}</a>
                  </div>
                  <div class="links">
                      <a href="#">{{'DISTRIBUTOR' | translate}}</a>
                  </div>
                  <div class="links">
                      <a href="#">{{ 'Brands' | translate }}</a>
                  </div>
                  <div class="links">
                      <a href="#">{{ 'Webinars' | translate }}</a>
                  </div>
                  <div class="links">
                      <a href="#">{{ 'Careers' | translate }}</a>
                  </div>
              </div>
             
          </div>
          <div class="green-line">
          </div>
        </div>
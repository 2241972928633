import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DistributorNetworkManagementService } from 'src/app/services/oem/distributor-network-management.service';
import { DISTRIBUTOR_ID, OEM_ID } from 'src/app/utils/constants';

@Component({
  selector: 'app-all-distributors',
  templateUrl: './all-distributors.component.html',
  styleUrls: ['./all-distributors.component.scss']
})
export class AllDistributorsComponent implements OnInit {
  Data!: any[];
  allDistributors: any[] = [];
  oemId?: any;

  body: any= 
  {
      senderId: this.localStorageService.getItem(OEM_ID),
      receiverId: null,
      senderRole: 'OEM',
      receiverRole: 'DISTRIBUTOR'
    }
showNoRecordsFounds: boolean= false;


constructor(
  private http: HttpClient,
  private localStorageService: LocalStorageService,  
  private snackBar: MatSnackBar,
  private distributorNetworkService: DistributorNetworkManagementService 
) { }

  ngOnInit(): void {
    this.getAllDistributors();
  }
  
  getAllDistributors() {
    this.distributorNetworkService.getAllDistributors().subscribe(data => {
      this.allDistributors=data.data;
      this.Data = this.transformData(this.allDistributors);
    })
    if(this.Data?.length<1){
      console.log(this.Data,"length");
      
      this.showNoRecordsFounds=true
    }
  }

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.viewAction:        
        break;
      case MweConstants.sendRequest:
        this.body.receiverId=event.row.id
        this.sendRequest(this.body)
        break;
      default:
        break;
    }
}

sendRequest(body: any) {
  console.log(body);
  
  this.distributorNetworkService.sendRequestToDistributor(body).subscribe({
    next: (data) => {
      console.log("Request sent successfully", data);
      this.snackBar.open('Request is sent.', 'Close', {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: 'end', // Positioning from left/right
        verticalPosition: 'top', // Positioning from top/bottom
        panelClass: ['custom-snackbar'] // Custom CSS class
      });
    },
    error: (error) => {
      console.log("An Error Occured");
      
      console.error('Error sending request', error);
      if (error.error.statusCode === "409" && error.error.message === 'A request with the same key already exists.') {
        this.snackBar.open('A request with has already been exists.', 'Close', {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: 'end', // Positioning from left/right
          verticalPosition: 'top', // Positioning from top/bottom
          panelClass: ['custom-snackbar'] // Custom CSS class
        });
      } else {
        this.snackBar.open('An error occurred. Please try again.', 'Close', {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: 'end', // Positioning from left/right
          verticalPosition: 'top', // Positioning from top/bottom
          panelClass: ['custom-snackbar'] // Custom CSS class
        });
      }
    }
  });
}
  columns: ListColumn[] = [
    { name: 'Sr.no', property: 'sr_no', visible: true, isModelProperty: true, isText: true },
    { name: 'id', property: 'image', visible: false, isModelProperty: true, isText: true },
    { name: 'Contact Name', property: 'Contact Name', visible: true, isModelProperty: true, isText: true },
    { name: 'Company Name', property: 'Company Name', visible: true, isModelProperty: true, isText: true },
    { name: 'Employees', property: 'Employees', visible: true, isModelProperty: true, isText: true },
    { name: 'Contact Number', property: 'Contact Number', visible: true, isModelProperty: true, isText: true },
    { name: 'Address', property: 'Address', visible: true, isModelProperty: true, isText: true },
    {name:'Brands', property: 'id', visible: false, isModelProperty: true, isText: true},
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true,isMoreAction: true,
      actions: [
        { actionType: MweConstants.sendRequest, iconName: 'send', name: 'send' },
        // { actionType: MweConstants.sendMail, iconName: 'mail', name: 'mail' },
        // { actionType: MweConstants.scheduleCall, iconName: 'call', name: 'call' },
      ]
    },
  ] as ListColumn[];

  transformData(data: any[]): any[] {
    return data.map((item, index) => {
      return {
        "id": item.id || "Not available",
        "sr_no": (index + 1).toString(),
       "Contact Name": (item.firstName || "") + " " + (item.lastName || ""),
        "Company Name": item.companyName || "",
        "Employees": item.noOfEmployees != null ? item.noOfEmployees.toString() : "",
        "Contact Number": item.phoneNumber || "",
        "Address": item.address 
      };
    });
  }
  

}

import { state } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit{
  ngOnInit(): void {
  }
  @Input() metric!: any;
  @Input() cardType!: string;
  @Input() routerLink!: string;
 
 
  constructor( private router: Router) {}

    ngOninit(): void {
      console.log('metric is ', this.metric);
      
    }

    navigateToRoute(url: string): void {
      if (!url) return;  // Guard clause to handle empty or null URLs
   
      const [routePath, queryString] = url.split('?');
      const queryParamObj = queryString
        ? queryString.split('&').reduce((obj : any , param) => {
            const [key, value] = param.split('=');
            if (key) obj[key] = value;  // Only add valid key-value pairs
            return obj;
          }, {})
        : {};
   
      this.router.navigate([routePath], { queryParams: queryParamObj });
    }
   
    moreClicked(){
      this.router.navigate([this.metric.routeLink], {state: this.metric})
    }
 
}
<div class="modal-popup">
  <div class="popup-heading">
    <h2 style="color: #0D87C8">
      {{ Edit ? 'Edit ' : (isViewMode ? 'View ' : 'Add ') }}
      {{ locationType }}
    </h2>
    
  </div>

  <form [formGroup]="locationForm" (ngSubmit)="onFormSubmit()">
    <div *ngFor="let heading of headings" class="field">
      <p class="label">{{ heading.name }}</p>
      
      <mat-form-field appearance="outline" class="fields-input">
        <ng-container *ngIf="locationType === 'Positions' && heading.property === 'accountType' || locationType === 'Speciality' && heading.property === 'accountsTypes'; else defaultInput">
          <mat-select [formControlName]="heading.property" multiple>
            <mat-option *ngFor="let accountType of accountTypes" [value]="accountType">{{ accountType }}</mat-option>
          </mat-select>
        </ng-container>
      
        <ng-template #defaultInput>
          <input matInput [formControlName]="heading.property">
        </ng-template>
        <mat-error *ngIf="locationForm.get(heading.property)!.hasError('required')">
          {{ heading.name }} is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="popup-buttons">
      <button mat-raised-button class="save-btn" type="submit" *ngIf="!isViewMode">Save</button>
      <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">Cancel</button>
    </div>
  </form>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OemService } from 'src/app/services/oem/oem.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-viewexhibition',
  templateUrl: './viewexhibition.component.html',
  styleUrls: ['./viewexhibition.component.scss']
})
export class ViewexhibitionComponent implements OnInit {

  isAvailable: boolean = true;
  @Output() backEvent = new EventEmitter<any>();
  @Output() closeToBack = new EventEmitter<any>();

  @Input() viewExhibitionId?: string;
  data: any;

  selectedAppointmentIndex: number | null = null;

  constructor(private http: HttpClient, private oemService: OemService,private datePipe: DatePipe) {}
  
  ngOnInit() {
    if (this.viewExhibitionId) {
      this.getMethod(this.viewExhibitionId);
      
    } 
  }
  
  getMethod(viewExhibitionId: string) {
    
    this.oemService.getExhibitionById(viewExhibitionId).subscribe((res: any) => {
        this.data = res.data;
        this.data.exhibitionStartDate = (new Date(this.data.exhibitionStartDate).toLocaleDateString());
        this.data.exhibitionEndDate = (new Date(this.data.exhibitionEndDate).toLocaleDateString());

        this.data.appointments.map((item: any)=>{
          item.appointmentDate = (new Date(item.appointmentDate).toLocaleDateString());
          item.startTime = this.datePipe.transform(new Date(item.startTime), 'h:mm:ss a', 'UTC');
          item.endTime =   this.datePipe.transform(new Date(item.endTime), 'h:mm:ss a', 'UTC');
          item.breakTime =   this.datePipe.transform(new Date(item.breakTime), 'h:mm:ss a', 'UTC');
          item.perSlotTime =   this.datePipe.transform(new Date(item.perSlotTime), 'h:mm:ss a', 'UTC');
        })
      },
      
    );
  }

  toggleAppointmentDetails(index: number) {
    this.selectedAppointmentIndex = this.selectedAppointmentIndex === index ? null : index;
  }

  back() {
    this.closeToBack.emit(true);
    this.backEvent.emit({ actionType: '' });
  }
}

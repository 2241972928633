import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { concatMap, takeWhile } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';

@Component({
  selector: 'app-hospital-filters',
  templateUrl: './hospital-filters.component.html',
  styleUrls: ['./hospital-filters.component.scss']
})
export class HospitalFiltersComponent {
  @Input() data: any[] = [];
  @Output() filteredData = new EventEmitter<any[]>(); // Send data to parent component

  dataObj : any [] = [];
  filteredObj : any [] = [];

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  showNoRecordsFounds: boolean = true;
  filterObject: any;
  countries!: any[];
  states!: any[];
  cities!: any[];
  brands!: any[];
  specializations!: any[];
  yearInMarkets!: any[];

  filterForm: FormGroup = new FormGroup({
    keywords: new FormControl(null),
    countries: new FormControl([]),
    states: new FormControl(null),
    cities: new FormControl(null),
    zipCode: new FormControl(null),
    yearInMarkets: new FormControl(null),
    amountFrom: new FormControl(0),
    amountTo: new FormControl(0),
    specializations: new FormControl(null),
  });

  filterControls = ['keywords', 'countries', 'states', 'cities', 'zipCode', 'yearInMarkets', 'amountFrom', 'amountTo', 'specializations'];
  // filterControls=['countries']

  constructor(
    private readonly masterDataService: MasterDataService,
    private oemService : OemService,
    private categoryService: CategoryService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataObj = this.data;  
  }

  ngOnInit(): void {
    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilter();
    });

    this.loadCountries('');
    this.loadStates('');
    this.loadCities('');
    this.loadBrands();  //Temporary data of brands to get "yearsInMarket" value
    this.loadcategories();

  }

  applyFilter(): void {
    console.log('apply filter', this.dataObj);
    let filtered = this.dataObj;
    const {keywords, countries, states, cities, zipCode, yearInMarkets, amountFrom, amountTo, specializations } = this.filterForm.value;
    console.log({keywords, countries, states, cities, zipCode, yearInMarkets, amountFrom, amountTo, specializations });
    
    if (keywords && keywords.length > 0) {
      const lowerCaseKeywords = keywords.map((keyword: string) => keyword.toLowerCase());
      filtered = filtered.filter(item =>
        (item.name && lowerCaseKeywords.some((keyword: any) => item.name.toLowerCase().includes(keyword))) ||
        (item.oemName && lowerCaseKeywords.some((keyword: any) => item.name.toLowerCase().includes(keyword))) ||
        (item.country && lowerCaseKeywords.some((keyword: any) => item.country.toLowerCase().includes(keyword))) ||
        (item.state && lowerCaseKeywords.some((keyword: any) => item.state.toLowerCase().includes(keyword))) ||
        (item.city && lowerCaseKeywords.some((keyword: any) => item.city.toLowerCase().includes(keyword)))
      );
    }

    if (countries && countries.length > 0) {
      filtered = filtered.filter(item =>
        item.country && countries.some((country: string) => item.country.toLowerCase() === country.toLowerCase())
      );
      this.loadStates(countries);
    }

    if (states && states.length > 0) {
      filtered = filtered.filter(item =>
        item.state && states.some((state: string) => item.state.toLowerCase() === state.toLowerCase())
      );
      this.loadCities(states);
    }

    if (cities && cities.length > 0) {
      filtered = filtered.filter(item =>
        item.city && cities.some((city: string) => item.city.toLowerCase() === city.toLowerCase())
      );
    }

    if (zipCode && zipCode.length > 0) {
      filtered = filtered.filter(item => 
        item.zipCode && String(item.zipCode) === String(zipCode));
    }

    if (yearInMarkets && yearInMarkets.length > 0) {
      filtered = filtered.filter(item => 
        item.year && yearInMarkets.includes(item.year));
    }
    
    if (amountTo) {
      filtered = filtered.filter(item => {
        const turnOver = item.turnOver ? parseFloat(item.turnOver) : null;
        return turnOver !== null && turnOver >= amountFrom && turnOver <= amountTo;
      });
    }

    if (specializations && specializations.length > 0) {
      // Map the selected category names to their corresponding IDs
      const selectedCategoryIds = specializations.map((specializationName: string) => {
        const matchedCategory = this.specializations.find(specialization => specialization.name.toLowerCase() === specializationName.toLowerCase());
        return matchedCategory ? matchedCategory.id : null;
      }).filter((id: null) => id !== null); // Filter out any null values

      // Apply the filter using the mapped IDs
      if (selectedCategoryIds.length > 0) {
        filtered = filtered.filter(item =>
          item.productCategories &&
          item.productCategories.some((categoryId: string) =>
            selectedCategoryIds.includes(categoryId)
          )
        );
      }
    }

    if (!keywords && !countries && !states && !cities && !zipCode && !yearInMarkets && !specializations && !amountFrom && !amountTo) {
      filtered = this.dataObj;
    }

    console.log('data filter after', filtered);
    this.filteredData.emit(filtered);   //Temporary data
  }


  loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  loadStates(countryName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', [countryName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', [countryName]);
        })
      )
      .subscribe(data => {
        allStates = allStates.concat(data.data);
        this.states = allStates;
      });
  }

  loadCities(stateName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCities: any[] = [];
    let morePages = true;

    this.masterDataService.getCities(pageNumber, pageSize, '', [stateName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', [stateName]);
        })
      )
      .subscribe(data => {
        allCities = allCities.concat(data.data);
        this.cities = allCities;
      });
  }

  loadBrands() {
    this.oemService.getBrandsList().subscribe({
      next: (response) => {
        this.brands = response.data; 
        this.yearInMarkets = [...new Set(response.data
          .map((item: { year: any; }) => item.year)
          .filter((year: any) => year != null && year !== '' && year !== 0)
      )];
         console.log('yearInMarkets', this.yearInMarkets); 
      },
      error: (error) => {
        console.error('Error fetching brand list data:', error);
      }
    });
  }

 
  loadcategories() {
    this.categoryService.getLastNodeCategory().subscribe(data => {
      if (data && data.data && data.data.length > 0) {
        this.specializations = data.data; // Temporary data
      }
    }, error => {
      console.error("Error fetching categories", error);
    }); 
  }

  resetFilters() {
    this.filterForm.reset();
    this.applyFilter();
  }

  add(controlName: string, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const currentValues = this.filterForm.get(controlName)?.value || [];
      this.filterForm.get(controlName)?.setValue([...currentValues, value.trim()]);
    }

    if (input) {
      input.value = '';
    }
  }

  remove(controlName: string, item: string | number): void {
    const control = this.filterForm.get(controlName);
    if (control) {
      const currentValues = control.value || [];
      const index = currentValues.indexOf(item);
      if (index >= 0) {
        currentValues.splice(index, 1);
        control.setValue(currentValues);
      }
      this.applyFilter();
    }
  }
}

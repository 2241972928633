import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hospital-profile-info',
  templateUrl: './hospital-profile-info.component.html',
  styleUrls: ['./hospital-profile-info.component.scss']
})
export class HospitalProfileInfoComponent {

  @Input() data: any;
  specialityData!: any;
  averageRating: any;
}

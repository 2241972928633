import { Component } from '@angular/core';

@Component({
  selector: 'app-cmspages',
  templateUrl: './cmspages.component.html',
  styleUrls: ['./cmspages.component.css']
})
export class CmspagesComponent {

}

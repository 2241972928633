<div class="mt-4">
    <div class="d-flex">
        <p class="title mt-1 mx-1">Courses & Training</p>
       
        <img class="edit-profile mx-1" (click)="addCourseTraining()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.courseTrainings &&this.endCustomerData.moreInfos[0].courseTrainings.length>0) || showEditForm" alt="" src="../../assets/images/add.svg">
    <img class="edit-profile " (click)="editCourseTrainings()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.courseTrainings &&this.endCustomerData.moreInfos[0].courseTrainings.length>0)" alt=""
        src="../../assets/images/editprofile.svg">
    </div>
    

    <div class="mt-2"
    *ngIf="!(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
this.endCustomerData.moreInfos[0]?.courseTrainings &&this.endCustomerData.moreInfos[0].courseTrainings.length>0) && !showEditForm">
    <button (click)="addCourseTraining()" class="profile-btn">
        <mat-icon>add</mat-icon><p class="ps-2"> Add Courses & Training</p></button>
</div>
<div class="mt-2"
    *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.courseTrainings &&this.endCustomerData.moreInfos[0].courseTrainings.length>0)&& !showEditForm">
    <app-mwe-table [columns]="coursesColumns" [showPagination]="false" [showHeader]="false"
        [data]="data"></app-mwe-table>
</div>
<div class="mt-2" *ngIf="showEditForm">
    <form [formGroup]="coursesAndTrainingForm">
        <div formArrayName="moreInfos">
            <div *ngFor="let moreInfoForm of moreInfos.controls;let i=index;" [formGroupName]="i">
                <div formArrayName="courseTrainings">
                    <div *ngFor="let courseTrainingForm of courseTrainings(i).controls;let j=index;" [formGroupName]="j">
                        <div class="row">
                            <div class="card p-4 mt-2 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Title</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields  input-font w-100 b-r">
                                                <input matInput formControlName="title">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Institution</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                                <input matInput formControlName="institution">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0"> Course Date</p>
                                        </div>

                                        <mat-form-field appearance="outline" class="fields input-font w-100 b-r">
                                            <input matInput [matDatepicker]="courseDate"  formControlName="courseDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="courseDate"></mat-datepicker-toggle>
                                            <mat-datepicker #courseDate color="primary"></mat-datepicker>
                                           
                                        </mat-form-field>
                                        
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div >
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Summary</p>
                                        </div>
                                        <div>

                                            <mat-form-field appearance="outline" class=" input-font w-100 b-r description">
                                                <textarea matInput formControlName="summary" rows="4" 
                                                    ></textarea>
                                            </mat-form-field>
                                        </div>




                                    </div>
                                </div>
                                <div class="row mt-2" >
                                    <div class="col-lg-3">
                                        <button class=" delete" (click)="deleteCourseTraining(i,j)" ><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>   
                    </div>
                        </div>
                        </div>
                        </div>
                        </form>
                        <div class="profile-action my-3" >
            
    
                            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                        
                        
                            
                        
                        
                            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                        
                        
                        
                        </div>
                        </div>
</div>
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from './../../../services/local-storage.service';
import { Component, EventEmitter, HostListener, Input, Output, OnInit } from '@angular/core';
import { SignoutPopupComponent } from '../../shared/signout-popup/signout-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  @Output() viewProfileJobs = new EventEmitter<boolean>();
  @Output() workExperience = new EventEmitter<{type: string, value: boolean}>();
  @Output() viewAllJobs = new EventEmitter<any>();
  @Output() postJobs = new EventEmitter<any>();

  isSavedJobsSelected: boolean = false;
  isAppliedJobsSelected: boolean = false;
  @Input() isCareersTabActive!: boolean ;
  userId: string = '';
  endCustomerType: string = '';

  constructor(
    public dialog: MatDialog,
    private sweetAlertService: SweetAlertService, 
    private authService: AuthService,
    private router : Router,
    private localStorageService: LocalStorageService,
    private endCustomerService: EndCustomerService
  ) { 
      this.userId = localStorageService.getItem('userId');
      this.getEndCustomerDetails(this.userId)
    }
    
  ngOnInit(): void {
  }

  getEndCustomerDetails(id: string){
      this.endCustomerService.getEndCustomerdetails(id).subscribe((res: any)=>{
        this.endCustomerType = res.data.account.accountType.name;
        console.log('res isssss', res, this.endCustomerType);
      })
  }

  onOpenProfileJobs(isSavedJobs :boolean) {
    this.isSavedJobsSelected = isSavedJobs;
    this.isAppliedJobsSelected = !isSavedJobs;
    if(this.endCustomerType === 'Individual'){
      this.viewProfileJobs.emit(isSavedJobs);
    }else{
    if(isSavedJobs){
      this.viewAllJobs.emit('viewAllJobs');
      return;
    }
    this.postJobs.emit('postJobs');
    }
  }
  
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.d-flex')) {
      this.isSavedJobsSelected = false;
      this.isAppliedJobsSelected = false;
    }
  }

  onWorkExperience(type :string) {
    this.workExperience.emit({type: type, value: true});
    if (type === 'work') {
      const workingExperiencesElement = document.getElementById('workingExperiences') as HTMLElement;
      if (workingExperiencesElement) {
        workingExperiencesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (type === 'education') {
      const educationElement = document.getElementById('education') as HTMLElement;
      if (educationElement) {
        educationElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (type === 'references') {
      const referencesElement = document.getElementById('references') as HTMLElement;
      if (referencesElement) {
        referencesElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

      } }
  }

  onManagePassword(){
    this.router.navigate(['/manage-password']);
  }

  onLogout() {
      this.sweetAlertService.showConfirmation(
        'Logout',
        'Are you sure you want to logout?',
        'Yes',
        'No'
      ).then((result) => {
        if (result.isConfirmed) {
          this.authService.onLogout();
        }
      });
    }
}
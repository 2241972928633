<div class="medworld-background-container">
    <div class="form-conatiner">
      <div class="medworld-form-container">
<div class="center">
    <mat-card class="profile-settings" style="box-shadow: none; width: 100%;">
      <mat-card-content [formGroup]="passwordForm">
        <div>
          <span class="profile-heading">Change password</span>
          <div class="form-text">Details about your account’s password</div>
        </div>
        <div class="medworld-label-inputs">
          <span class="form-heading">Old Password</span>
          <input  class=" form-control input-element" matInput type="password" formControlName="password">
          <mat-error
            *ngIf="passwordForm.get('password')?.invalid && (passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched)">
            Old Password is required.
          </mat-error>
        </div>
        <div class="element-container medworld-label-inputs">
          <span class="form-heading">New Password</span>
          <input class=" form-control input-element"  matInput type="password" formControlName="newPassword">
          <mat-error
            *ngIf="passwordForm.get('newPassword')?.hasError('required') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
            New Password is required.
          </mat-error>
          <mat-error
            *ngIf="passwordForm.get('newPassword')?.hasError('minlength') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
            New Password must be at least 8 characters long.
          </mat-error>
          <mat-error
            *ngIf="passwordForm.get('newPassword')?.hasError('passwordRequirements') && (passwordForm.get('newPassword')?.dirty || passwordForm.get('newPassword')?.touched)">
            New Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special
            character.
          </mat-error>
        </div>
        <div class="element-container medworld-label-inputs">
          <span class="form-heading">Confirm Password</span>
          <input class=" form-control input-element"  matInput type="password" formControlName="confirmPassword">
          <mat-error
          *ngIf="passwordForm.get('confirmPassword')?.touched && !passwordForm.get('confirmPassword')?.value">
          Confirm Password is required.
        </mat-error>
          <mat-error
            *ngIf="passwordForm.get('confirmPassword')?.value && passwordForm.hasError('notSame') && (passwordForm.get('confirmPassword')?.dirty || passwordForm.get('confirmPassword')?.touched)">
            Passwords do not match.
          </mat-error>
        </div>
        
      </mat-card-content>
    </mat-card>
    <button mat-button class="update-password-btn" (click)="onSubmit()"><span
         class="button button-primary">Update Password</span></button>
  </div>
  </div>
  </div>
  </div>
  
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormArray, Validators, FormControl, FormBuilder } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';

@Component({
  selector: 'app-infra-profile',
  templateUrl: './infra-profile.component.html',
  styleUrls: ['./infra-profile.component.scss']
})
export class InfraProfileComponent implements OnInit{
  
  @Input() infraStructureForm!:FormGroup;
  @Input() actionType:any;
  
  
  equipments:any=[];

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();

  showEditForm:boolean=false

  copyInfraForm:any;

  equipmentData:any=[];
  constructor(private endCustomerService:EndCustomerService,private fb:FormBuilder){}

  ngOnInit(): void {
    this.getEquipments();
  }

  getEquipments(){
    this.endCustomerService.executeGetRequestURL(MweConstants.equipmentUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.equipments=data.data;
       /*  if(this.actionType==MweConstants.editAction && this.infraStructureForm.get('equipments')?.value &&this.infraStructureForm.get('equipments')?.value.length>0){
          const equipments=this.equipments.filter((obj1:any)=>{
            return this.infraStructureForm.get('equipments')?.value.some((obj2:any)=>{
              return obj1.id===obj2.id;
            })
          })
          this.infraStructureForm.get('equipments')?.patchValue(equipments);
        } */
        console.log(this.infraStructureForm.get('equipments')?.value)
        if(this.infraStructureForm.get('equipments')?.value &&this.infraStructureForm.get('equipments')?.value.length>0){
          this.getEquipmentData();
        }
      }
    },error=>{

    })
  }
  get infras() {
    return this.infraStructureForm.controls["infras"] as FormArray;
  }
 
  remove(i:number): void {
    this.infraStructureForm.get('equipments')?.value.splice(i,1);
    this.infraStructureForm.get('equipments')?.patchValue( this.infraStructureForm.get('equipments')?.value);
}
 
 
  
   isRoomAvailable(i:number){
   return this.infras.at(i).get('roomsAvailable') as FormControl;
  }

  get laboratoryAvailable(){
return this.infraStructureForm.get('laboratoryAvailable') as FormControl;
  }

  updateLaboratoryAvailable(){
    if(this.infraStructureForm.get('laboratoryAvailable')?.value != null){
      if(this.infraStructureForm.get('laboratoryAvailable')?.value==='yes'){
        this.infraStructureForm.get('equipments')?.enable();
        this.infraStructureForm.get('equipments')?.reset();
        this.infraStructureForm.get('equipments')?.patchValue([]);
      }else{
        this.infraStructureForm.get('equipments')?.reset();
        this.infraStructureForm.get('equipments')?.disable();
      }
    }
   
  }
  
  getEquipment(id:any){
    return this.equipments.find((obj:any)=>obj?.id===id)?.name;
   }

   update(){
    this.onUpdate.emit(30);
  }

  cancel(){
    this.showEditForm=false;
    (this.infraStructureForm.get('infras')as FormArray).clear();
    if(this.copyInfraForm?.infras && this.copyInfraForm.infras.length>0){
         for(let i=0;i<this.copyInfraForm.infras.length;i++){
           (this.infraStructureForm.get('infras')as FormArray).push( this.fb.group({
            id:new FormControl(null),
            active:new FormControl(null),
            createdBy:new FormControl(null),
            createdOn:new FormControl(null),
            modifiedBy:new FormControl(null),
            modifiedOn:new FormControl(null),
        
            totalRooms: new FormControl(null),
          
            totalIcuRooms: new FormControl(null),
            totalBeds: new FormControl(null),
            patientCapacity: new FormControl(null),
           }));
         }
         (this.infraStructureForm.get('infras')as FormArray).patchValue(this.copyInfraForm.infras);
       }
       this.infraStructureForm.get('equipments')?.patchValue([]); 
       this.infraStructureForm.get('laboratoryAvailable')?.patchValue('No');
  if(this.copyInfraForm?.equipments && this.copyInfraForm?.equipments.length>0){
    this.infraStructureForm.get('equipments')?.patchValue(this.copyInfraForm?.equipments); 
    this.infraStructureForm.get('laboratoryAvailable')?.patchValue('Yes');
   
  }
  this.getEquipmentData(); 
   
  
  }

  editInfra(){
    this.copyInfraForm=JSON.parse(JSON.stringify(this.infraStructureForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    this.getEquipmentData();
  }

   getEquipmentData(){
   console.log(this.infraStructureForm.get('equipments')?.value)
    const equipment=this.equipments;
    
    let equipmentInfo:any=[];
    
    if(this.endCustomerData?.equipments && this.endCustomerData.equipments.length>0){
      equipmentInfo= equipment.filter((obj1:any)=>{
        
        return this.endCustomerData.equipments.some((obj2:any)=>{
         
          return obj1.id===obj2.equipmentInfoId;
        })
      })
    }
    

    this.equipmentData=equipmentInfo;
  }
 


}
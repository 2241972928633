<div class="background">
  <app-website-header></app-website-header>
  <div class="row">
    <div class="image-container">
      <img src="../../../assets/images/brands-banner.svg" alt="landingpage" class="landingpageimage" />
      <div class="header-name">
        <p>Brands</p>
      </div>
    </div>
  </div>
  
  <div class="mx-3 mt-2">
    <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="">
    <a class="breadcrummb ps-2" style="color:#858585 ;">Home</a>
    <a class="breadcrummb" style="color:#858585 ;">/</a>
    <a class="breadcrummb" style="color:#000000 ;">Brands</a>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <app-brand-filters [data]="originalData" (filteredData)="onFilteredData($event)"></app-brand-filters>
      </div>
      <div class="col-lg-9">
        <div class="row">
          <div class="d-flex justify-content-between ">
            <h2 class="brands">Brands</h2>
            <p class="end-text">Total Brands: {{brands.length}}</p>
          </div>
          <div class="mt-2">
            <hr style="border: none; height: 2px; background-color: #0d87c8; margin-top: -1rem;" />
          </div>
        </div>
        <ng-container *ngIf="showNoRecordsFounds">
          <div class="no-records-found"> No records Found </div>
        </ng-container>
        <div class="row">
          <div class="col-lg-4" *ngFor="let brand of paginatedItems; let i = index">
            <!-- <div (click)="viewPortfolio(brand)" class="card" (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = -1"
              [ngStyle]="{'background-color': hoveredIndex === i ? 'rgba(0, 0, 0, 0.6)' : 'white', 'color': hoveredIndex === i ? '#fff' : 'inherit',}">
              <img height="150" [src]="brand.logo" alt="{{brand.name}}" 
                [style.opacity]="hoveredIndex === i ? 0.1 : 1"/>
              <p class="brand-name" [style.opacity]="hoveredIndex === i ? 0.1 : 1">{{brand.name}}</p>
              <p [style.display]="hoveredIndex === i ? 'block' : 'none'"
                [style.background-color]="hoveredIndex === i ? '' : 'rgba(0, 0, 0, 0.6)'"
                [style.color]="hoveredIndex === i ? '#fff' : 'rgba(0, 0, 0, 0.6)'" [style.font-weight]="'600'">
                View {{brand.name}} Portfolio
              </p>
            </div> -->
            <div style="position: relative;" (click)="viewPortfolio(brand)" class="card" (mouseenter)="hoveredIndex = i" (mouseleave)="hoveredIndex = -1"
              [ngStyle]="{'background-color': hoveredIndex === i ? 'rgba(0, 0, 0, 0.6)' : 'white', 'color': hoveredIndex === i ? '#fff' : 'inherit',}">
              <img height="220" [src]="brand.logo" alt="{{brand.name}}" 
                [style.opacity]="hoveredIndex === i ? 0.1 : 1"/>
              <p class="brand-name" [style.opacity]="hoveredIndex === i ? 0.1 : 1">{{brand.name}}</p>
              <p class="text-center" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" [style.display]="hoveredIndex === i ? 'block' : 'none'"
                [style.background-color]="hoveredIndex === i ? '' : 'rgba(0, 0, 0, 0.6)'"
                [style.color]="hoveredIndex === i ? '#fff' : 'rgba(0, 0, 0, 0.6)'" [style.font-weight]="'600'">
                View {{brand.name}} Portfolio
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination-container">
      <app-pagination [itemsPerPage]="itemsPerPage" [data]="brands" (pageChanged)="onPageChanged($event)"></app-pagination>
    </div>
  </div>
  <app-website-footer></app-website-footer>
import { filter } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { RssfeedsService } from '../rssfeeds.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {
  items: any[] = [];
  rows: any[] = [];

  products: any[] = [];
  upcomingwebinars: any[] = [];
  specifications: any[] = [];
  

  constructor(
    private rssfeedsService: RssfeedsService,
    private productsService: ProductsService, private router: Router, private webinarService: WebinarService ) {
    this.items = Array.from({ length: 12 }, (_, i) => i);
    this.rows = this.groupItems(this.items, 5);
  }

  ngOnInit(): void {
    // this.getProductsByCategory('a23b3d3b-fb40-42b1-8ec2-b3ddd6184754')
    this.getWebiars()
    this.getTopProducts();
    this.getNewsAndTrends();
    this.startAutoSlide();
  }

  rssData: any;
  getNewsAndTrends(){
    const url = 'https://www.business-standard.com/rss/companies-101.rss';
    // const url = 'https://cors-anywhere.herokuapp.com/https://www.business-standard.com/rss/elections-117.rss';

    // this.rssfeedsService.getRssFeed(url).subscribe(
    //   (data) => {
    //     console.log('Parsed XML:', data);
    //     this.rssData = data;
    //   },
    //   (error) => {
    //     console.error('Error fetching RSS feed', error);
    //   }
    // );
    const data = this.rssfeedsService.data;
    
    this.rssData = this.rssfeedsService.parseXML(data);
    console.log('rssdata is ', this.rssData);
    
    
  }

  getTopProducts() {
    this.productsService.getTopProducts().subscribe((res: any) => {
      this.products = res.data;
      
      
      this.products.forEach((product: any) => {
        const productSpecs: any[] = [];
        product.specifications.forEach((spec: any) => {
          if (spec.isRange === 'false') {
            productSpecs.push({
              name: spec.name,
              value: spec.fromValue+' '+spec.fromUnit
            });
          } else {
            productSpecs.push({
              name: spec.name,
              value: `${spec.fromValue} ${spec.fromUnit} - ${spec.toValue} ${spec.fromUnit}`
            });
          }
        });
        this.specifications = productSpecs.slice(0, 3);

      });
    });
  }
  

  getWebiars(){
    this.webinarService.filters({}).subscribe((res: any) => {
      this.upcomingwebinars = res.data.filter((f: any)=>new Date(f.webinarDate)>new Date())
    }
  )}

  view(id: any){
    this.router.navigate(['/webinars/view', id]);
  }

  groupItems(items: any[], itemsPerRow: number): any[][] {
    const rows = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    return rows;
  }
  
  
  slides = [
    { image: '../../../assets/images/landerpage.png', alt: 'First slide' },
    { image: '../../../assets/images/brands-banner.svg', alt: 'dummy slide' },
    // { image: '../../../assets/images/landingpage2.jpg', alt:'second slide' },
    // { image: '../../../assets/images/landingpage3.jpg', alt: 'third slide' },
    // { image: '../../../assets/images/landingpage4.jpg', alt: 'fourth slide' },
    // { image: '../../../assets/images/landingpage5.jpg', alt: 'fifth slide' },
    // { image: '../../../assets/images/landingpage6.jpg', alt: 'sixth slide' }
  ];
  currentSlide = 0;
  autoSlideInterval: any;

  startAutoSlide(): void {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 2000); 
  }

  stopAutoSlide(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  prevSlide(): void{
    this.currentSlide-=1;
    this.stopAutoSlide();
    if(this.currentSlide< 0){
      this.currentSlide = this.slides.length - 1;
    }
     this.startAutoSlide();
   }

  nextSlide(): void {
    this.currentSlide+=1;
     this.stopAutoSlide();
    if(this.currentSlide >= this.slides.length){
      this.currentSlide = 0;
    }
     this.startAutoSlide();
  }
}

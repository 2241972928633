import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DistributorService } from 'src/app/services/distributor/distributor.service';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { CatalogComponent } from '../../dashboard/oem/catalog/catalog.component';
import { CatalogService } from 'src/app/services/shared/catalog.service';


@Component({
  selector: 'app-brand-profile',
  templateUrl: './brand-profile.component.html',
  styleUrls: ['./brand-profile.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class BrandProfileComponent implements OnInit {
  @ViewChild(CompanyProfileComponent) companyProfileComponent!: CompanyProfileComponent;
  tabSliderPosition = 0; // Initial position of the tab slider
  tabSliderWidth = 0; // Initial width of the tab slider
  activeTab = 0;
  previousTab: number = 0;
  animateContent: boolean = false;
  selectedTabName : string = 'Company Profile';
  id: string | null = null;
  type : string | null = null;
  isDistributorProfile : boolean = false;
  profileData: any;
  brandData: any;
  captureMode: boolean = false;
  products: any[] = [];
  distributorNetwork: any[] = [];
  brands: any[] = [];

  constructor(
    private oemService : OemService,
    private distributorService: DistributorService,
    private route: ActivatedRoute,
    private router: Router,
    private allProductService: AllProductService,
  ) { }
  

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.route.queryParamMap.subscribe(params => {
      this.type = params.get('type');
    });

    if (this.id && this.type) {
      this.fetchProfileData(this.id, this.type);
    }

    console.log(this.id, this.type);
    
  }

  fetchProfileData(id: string, type: string): void {
    if (type === 'distributor') {
    //  Call distributor API
    this.getDistributorById(id);
    this.getAllProductsById(id);
    this.getBrandsByDistributorId(id);
    } else if(type === 'brand') {
      // Call brand API
      this.getOEMsBYId(id);
      this.getAllProductsById(id);
      this.getAllDistributorById(id);
    }
  }

  getOEMsBYId(id: string): void { 
    this.oemService.getOEMById(id).subscribe({
      next: (response) => {
        console.log('brand oem - ',response.data);
        this.profileData = {
          ...response.data,
          type: 'brand'
        };
      },
      error: (error) => {
        console.error('Error fetching OEM by id:', error);
      }
    });
  }

  getDistributorById(id: string): void { 
    this.distributorService.getDistributorById(id).subscribe({
      next: (response) => {
        console.log('brand distributor - ',response.data);
        this.profileData = {
          id: response.data.id,
          companyName : response.data.companyName,
          name: response.data.firstName + ' ' + response.data.lastName,
          logo: response.data.logo,
          url: response.data.url,
          description: response.data.description,
          address: response.data.address,
          country : response.data.country,
          state: response.data.state,
          city : response.data.city,
          email : response.data.email,
          phoneNumber : response.data.phoneNumber,
          type: 'distributor'
        };
      },
      error: (error) => {
        console.error('Error fetching distributor by id:', error);
      }
    });
  }

  getAllProductsById(brandId: string) {
    this.allProductService.getAllProductsByOemId(brandId).subscribe({
      next: (response) => {
        this.products = response.data; 
        console.log('products',this.products);
        
      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
    });
  }

  getAllDistributorById(brandId: string) {
    this.oemService.getOemDistributorById(brandId).subscribe({
      next: (response) => {
        this.distributorNetwork = response.data; 
      console.log('distributorNetwork',this.distributorNetwork);
      
      },
      error: (error) => {
        console.error('Error fetching oem by id:', error);
      }
    });
  }

  getBrandsByDistributorId(brandId : string) {
    this.oemService.getOEMsByDistributorId(brandId).subscribe({
      next: (response) => {
        this.brands = response.data; 
        console.log('distributor brand',this.brands);
      },
      error: (error) => {
        console.error('Error fetching brand list data:', error);
      }
    });
  }


  setTabSliderWidth() {
    const tabs = document.querySelectorAll('.tab');
    if (tabs.length > 0) {
      this.tabSliderWidth = (tabs[0] as HTMLElement).offsetWidth;
    }
  }

  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }

  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();
      this.selectedTabName = this.getTabName(index);
    }
  }

  handleSelectDistributor(distributor: any): void {
    this.router.navigate([`brands/brand-profile/${distributor.id}`],{
      queryParams: { type: 'distributor' }
    }).then(() => {
      this.getDistributorById(distributor.id);
      this.selectTab(0);
    });
  }

  handleSelectOEM(brand: any): void {
    this.router.navigate([`brands/brand-profile/${brand.id}`],{
      queryParams: { type: 'brand' }
    }).then(() => {
      this.getOEMsBYId(brand.id);
      this.selectTab(0);
    });
  }

  getTabName(index: number): string {
    switch (index) {
      case 0:
        return 'Company Profile';
      case 1:
        return 'Products';
      case 2:
        return 'Distributor Network';
      case 3:
      return 'Connected Brands';
      case 4:
        return 'Catalogs';
      case 5:
        return 'Exhibitions';
      case 6:
        return 'Contact Us';
      default:
        return 'Unknown';
    }
  }

  navigate(): void {
    this.router.navigate(['./brands'], {});
  }

  showContentForPdf = false;
  
  private async generateProfilePdf(elementIds: string[], pdfFileName: string, isDownload: boolean = true): Promise<void> {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const usableWidth = pageWidth - 2 * margin;
    let yOffset = margin;
  
    for (const elementId of elementIds) {
      const element = document.getElementById(elementId);
      if (element) {
        const canvas = await html2canvas(element, {
          scale: 1.5, // Lower scale to reduce image resolution
          useCORS: true // Use CORS if images are hosted on different domains
        });
  
        const imgData = canvas.toDataURL('image/jpeg', 0.7); // Convert to JPEG and reduce quality to 70%
        const imgProps = pdf.getImageProperties(imgData);
        const imgWidth = usableWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
  
        if (yOffset + imgHeight > pdf.internal.pageSize.getHeight() - margin) {
          pdf.addPage();
          yOffset = margin;
        }
  
        pdf.addImage(imgData, 'JPEG', margin, yOffset, imgWidth, imgHeight, '', 'FAST'); // Use JPEG and compression
        yOffset += imgHeight + 10; // Space below the image
      }
    }
  
    if (isDownload) {
      pdf.save(`${pdfFileName}.pdf`);
    } else {
      const pdfBlob = pdf.output('blob');
      const url = URL.createObjectURL(pdfBlob);
      window.open(url);
    }
  
    this.captureMode = false;
  }
  


  
  viewProfile(): void {
    this.captureMode = true;
    setTimeout(() => {
      this.generateProfilePdf(['companyInfo-page'], this.profileData.companyName, false);
    }, 1000);
  }
  
  downloadProfile(): void {
    this.captureMode = true;
    setTimeout(() => {
      this.generateProfilePdf(['companyInfo-page'], this.profileData.companyName, true);
    }, 1000);
  }
  


}

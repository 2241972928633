<app-website-header></app-website-header>
<div class="row">
  <div class="image-container">
    <img src="assets/images/lab.svg" alt="labimage" class="landingpageimage" />
    <div class="header-name">
      <p>{{ category?.name }}</p>
    </div>
  </div>
</div>
<!-- <div class="image-container">
  <img
    src=""
    alt="labimage"
    class="landingpageimage"
  />
  <h1 class="image-text">{{ category?.name }}</h1>
</div> -->
<div class="page" style="background-color: rgba(69, 79, 89, 0.04);">
  
  <div class="row mt-1 mb-3">
    <div class="d-flex align-items-start">
      <img src="assets/images/home.svg" alt="landingpage" class="products " style="margin: 2.5px 0px 0px 18px;">
      <span class="products mx-2">
        <app-bread-crumb [breadcrumb]="breadcrumb"></app-bread-crumb>
      </span>
    </div>
  </div>
  <div class="category-display" style="margin-left: 20px; margin-right: 20px;">
      <div class="row d-flex" style="justify-content: space-between;">
        <div class="col-md-3 col-12" >
  <!-- <app-products-filter *ngIf="category.subcategories?.length === 0" (filteredData)="onFilteredData($event)"></app-products-filter> -->
  <app-brand-product-filter *ngIf="category?.subcategories?.length === 0" [filterColumns]="filterColumns" [data]="originalData" (filteredData)="onFilteredData($event)" ></app-brand-product-filter>

  </div>

<div class="right col-12 col-md-9 " >
        <div class="row " >
          <ng-container *ngFor="let subcategory of category?.subcategories">
            <div class="categories col-12 col-sm-6 col-md-6 col-lg-3 mb-4">
                <app-product-info-card
                [id]="subcategory.id"
                  [productImage]="subcategory.image"
                  [title]="subcategory.name"
                  [contentItems]="contentItems"
                  [isCategoryCard]="true">
                </app-product-info-card>
              </div>
            
          </ng-container>
          
          <ng-container *ngIf="category?.subcategories?.length === 0">
            <ng-container *ngFor="let product of products">
              <div class="categories col-12 col-sm-6 col-md-6 col-lg-3 mb-4">
                <div class="d-flex flex-column">
                  <app-product-info-card
                  [id]="product.id"
                    [productImage]="product.productImage"
                    [title]="product.title"
                    [isCategoryCard]="false">
                  </app-product-info-card>
                </div>
              </div>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</div>
<app-website-footer></app-website-footer>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-oem-success-popup',
  templateUrl: './add-oem-success-popup.component.html',
  styleUrls: ['./add-oem-success-popup.component.scss']
})
export class AddOemSuccessPopupComponent implements OnInit{
  dynamicText: string = 'Created';
  constructor(private router: Router, private dialogRef: MatDialogRef<AddOemSuccessPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ){}

  ngOnInit(): void {
    if (this.data?.editMode) {
      this.dynamicText = 'Created';
    } else {
      this.dynamicText = 'Updated';
    }
  }
  public ok(){
    this.dialogRef.close();
  }

}

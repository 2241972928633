import { Component, Input, OnInit ,ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { BranchService } from 'src/app/services/shared/branch.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-oem-branch-info',
  templateUrl: './oem-branch-info.component.html',
  styleUrls: ['./oem-branch-info.component.scss']
})
export class OemBranchInfoComponent implements OnInit {

@Input() branchInfo!: FormGroup;
@Output() branchAdded = new EventEmitter<number>();
columns: ListColumn[] = [
  { name: 'Sr.no', property: 'sNo', visible: true, isModelProperty: false },
  { name: 'Branch Name', property: 'brandName', visible: true, isModelProperty: true, isText: true },
  { name: 'Name', property: 'contactPerson', visible: true, isModelProperty: true, isText: true },
  { name: 'Phone Number', property: 'contactPhone', visible: true, isModelProperty: true, isText: true },
  { name: 'Email', property: 'contactEmail', visible: true, isModelProperty: true, isText: true },
  { name: 'Address', property: 'address', visible: true, isModelProperty: true, isText: true },
  {
    name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
    actions: [
      { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
      { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
    ]
  }
] as ListColumn[];

data: any[] = [];
branchForm!: FormGroup;
isMore: boolean = false;
actionType: any;
index: number = 0;
isProceed: boolean = false;

constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef , private branchService: BranchService,private sweetAlertService: SweetAlertService) {}

ngOnInit(): void {
  this.initializeBranchForm();
  this.data = this.branchInformationArray().value;
  if(this.data.length > 0){
    this.isMore = true;
  }
 
}

initializeBranchForm() {
  this.branchForm = this.fb.group({
    branchId: new FormControl(''),
    oemId: new FormControl(''),
    brandName: new FormControl(''),
    address: new FormControl(''),
    contactPerson: new FormControl(''),
    contactPhone: new FormControl(''),
    contactEmail: new FormControl(''),
  });
}

addBranchInformation() {
  

  if (!this.areAllPropertiesNull(this.branchForm.value) && this.branchForm.valid) {
    if (this.actionType === MweConstants.editAction) {
      this.branchInformationArray().at(this.index).patchValue(JSON.parse(JSON.stringify(this.branchForm.value)));
      this.branchAdded.emit(2);
    } else {
      this.branchInformationArray().push(this.fb.group({
        branchId: new FormControl(this.branchForm.get('branchId')?.value || ''),
        oemId: new FormControl(this.branchForm.get('oemId')?.value),
        brandName: new FormControl(this.branchForm.get('brandName')?.value),
        address: new FormControl(this.branchForm.get('address')?.value),
        contactPerson: new FormControl(this.branchForm.get('contactPerson')?.value),
        contactPhone: new FormControl(this.branchForm.get('contactPhone')?.value),
        contactEmail: new FormControl(this.branchForm.get('contactEmail')?.value),
      }));
      this.branchAdded.emit(1);
    }
    this.data = this.branchInfo.get('branches')?.value;
    this.branchForm.reset();
    this.removeValidators();
    this.actionType = null;
    this.isMore = true;
    this.isProceed = false;
    this.fun();
    

  } else {
    console.warn('Please fill in all required fields.');
  }
  
}

fun(){
  console.log("g",this.branchInfo.get('branches')?.value);
}

areAllPropertiesNull(obj: any): boolean {
  
  const requiredFields = ['brandName', 'address', 'contactPerson', 'contactPhone', 'contactEmail'];

 
  for (const key of requiredFields) {
    if (!obj[key] || obj[key].trim() === '') {
      console.log(key);
      this.sweetAlertService.showError('Failed to add branch', 'Please fill all the required fields');
      this.showValidators()
      return true; 
    }
  }
  
  return false; 
}

removeValidators() {
  this.branchForm.get('brandName')?.clearValidators();
  this.branchForm.get('address')?.clearValidators();
  this.branchForm.get('contactPerson')?.clearValidators();
  this.branchForm.get('contactPhone')?.clearValidators(); 
  this.branchForm.get('contactEmail')?.clearValidators();

  this.branchForm.get('brandName')?.updateValueAndValidity();
  this.branchForm.get('address')?.updateValueAndValidity();
  this.branchForm.get('contactPerson')?.updateValueAndValidity();
  this.branchForm.get('contactPhone')?.updateValueAndValidity();
  this.branchForm.get('contactEmail')?.updateValueAndValidity();
}


showValidators() {
  this.branchForm.get('brandName')?.setValidators([Validators.required]);
  this.branchForm.get('address')?.setValidators([Validators.required]);
  this.branchForm.get('contactPerson')?.setValidators([Validators.required]);
  this.branchForm.get('contactPhone')?.setValidators([Validators.required]);
  this.branchForm.get('contactEmail')?.setValidators([Validators.required, Validators.email]);

  this.branchForm.get('brandName')?.updateValueAndValidity();
  this.branchForm.get('address')?.updateValueAndValidity();
  this.branchForm.get('contactPerson')?.updateValueAndValidity();
  this.branchForm.get('contactPhone')?.updateValueAndValidity();
  this.branchForm.get('contactEmail')?.updateValueAndValidity();
}

toggleIsProceed() {
  this.isProceed = true;
  this.actionType=null;
  this.branchForm.reset();
}

toggleCancel(){
  this.isProceed = false;
}
  

selectedAction(event: any) {
  for (let i = 0; i < this.data.length; i++) {
    if (this.data[i].brandName === event.row.brandName &&
        this.data[i].address === event.row.address &&
        this.data[i].contactPerson === event.row.contactPerson &&
        this.data[i].contactPhoneNumber === event.row.contactPhoneNumber &&
        this.data[i].contactEmail === event.row.contactEmail) {
      this.index = i;
      break;
    }
  }
  switch (event.action) {
    case MweConstants.editAction:
      this.actionType = MweConstants.editAction;
      this.branchForm.patchValue(this.branchInfo.get('branches')?.value[this.index]);
      this.isProceed=true;
      break;
    case MweConstants.deleteAction:
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          const branchesFormArray = this.branchInfo.get('branches') as FormArray;
          const branch = branchesFormArray.at(this.index).value;
          if (branch.branchId) {
            this.branchService.deleteBranchById(branch.branchId).subscribe(
              response => {
                this.branchForm.reset();
                this.actionType = null;
                branchesFormArray.removeAt(this.index);
                this.data = this.branchInformationArray().value;
                this.cdr.detectChanges();
                this.sweetAlertService.showSuccess('', 'Deleted successfully !!')
                if(branchesFormArray.length === 0){
                  this.isMore = false;
                }
              },
              error => {
                Swal.fire(
                  'Failed!',
                  'Item could not be deleted.',
                  'error'
                );
              }
            );
          } else {
            Swal.fire(
              'Failed!',
              'Item could not be deleted.',
              'error'
            );
          }
          this.branchForm.reset();
          this.actionType = null;
          this.cdr.detectChanges();
        }
        else{
          Swal.fire('Cancelled', 'Record is safe :)', 'error');
        }
      });
      break;
    default:
      break;
  }
}

branchInformationArray() {
  return this.branchInfo.get('branches') as FormArray;
}
numberOnly(event: any): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
}

onlyText(event: any): boolean {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode < 32 || charCode === 32) {
    return true;
  }
  return (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122);
}
}

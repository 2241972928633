
<app-mwe-table [columns]="columns" [data]="data" [showPagination]="false" [showHeader]="false" (selectedAction)="selectedAction($event)"
></app-mwe-table>

<form [formGroup]="financialForm" >
 
    <div class="card p-4 mt-2 mb-2" *ngIf="isProceed">
    <div class="row" >
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Financial Year</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <mat-select  formControlName="financialYear">
                        <mat-option *ngFor="let year of yearDropdown" [value]="year">{{year}}</mat-option>
                    </mat-select>
                    <!-- <mat-error class="error-message error-msg"
                        *ngIf="financialInformationForm.get('financialYear')?.hasError(' ')"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />Financial year
                         is  </mat-error>
                         -->
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Currency</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    
                    <mat-select  formControlName="currency">
                        <mat-option *ngFor="let item of currencies" [value]="item?.currency">{{item?.currency}}</mat-option>
                    </mat-select>
                        
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Networth</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <input matInput   formControlName="netWorth" (keypress)="numberOnly($event)">
                    <!-- <mat-error class="error-message error-msg"
                        *ngIf="financialInformationForm.get('netWorth')?.hasError(' ')"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Networth
                       is  </mat-error>
                         -->
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Turnover</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <input matInput   formControlName="turnover" (keypress)="numberOnly($event)">
                    <!-- <mat-error class="error-message error-msg"
                        *ngIf="financialInformationForm.get('turnover')?.hasError(' ')"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />Email
                         is  </mat-error> -->
                        
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Profit</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <input matInput   formControlName="profit" (keypress)="numberOnly($event)">
                    <!-- <mat-error class="error-message error-msg"
                        *ngIf="financialInformationForm.get('profit')?.hasError(' ')"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />profit
                         is  </mat-error> -->
                        
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-2">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Yearly Growth %</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <input matInput   formControlName="yearOfGrowth" (keypress)="numberOnly($event)">
                    <!-- <mat-error class="error-message error-msg"
                        *ngIf="financialInformationForm.get('yearlyGrowth')?.hasError(' ')"><img
                            class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />yearly growth
                         is  </mat-error> -->
                        
                </mat-form-field>
            </div>
        </div>
        

    </div>

    <div class="mt-4">

        <button class="mwe-button mwe-button--primary ps-3 pe-3" (click)=" addFinancialInformation()"
           >
            <span class="deltext">Save</span>
        </button>&nbsp;&nbsp;
        <button class="mwe-button mwe-button--secondary ps-3 pe-3" (click)=" toggleCancel()"
        >
         <span class="deltext">Cancel</span>
     </button>
    </div>
        </div>
    
        <div *ngIf="!isProceed&&!isMore" class="mt-2 mb-2" style="margin-left: 10px;">
            <button class="d-flex more" (click)=" toggleIsProceed()"
            ><mat-icon>add</mat-icon>
             <span class="deltext">Add</span>
         </button>
        </div>
        <div *ngIf="!isProceed&&isMore" class="mt-2 mb-2" style="margin-left: 10px;" >
         <button class="d-flex more" (click)="toggleIsProceed()"
         ><mat-icon>add</mat-icon>
          <span class="deltext">Add More</span>
      </button>
    </div>
    </form>


      
import { Component, Inject, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-preview-dialog',
  templateUrl: './customer-preview-dialog.component.html',
  styleUrls: ['./customer-preview-dialog.component.scss']
})
export class CustomerPreviewDialogComponent implements AfterViewInit {
  @ViewChild('dialogContent', { static: false }) dialogContent!: ElementRef<HTMLDivElement>;

  constructor(
    public dialogRef: MatDialogRef<CustomerPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { htmlContent: string },
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dialogContent && this.dialogContent.nativeElement) {
        this.dialogContent.nativeElement.scrollTop = 0;
        const elements = this.dialogContent.nativeElement.querySelectorAll('*');
        elements.forEach((el: Element) => {
          this.renderer.setStyle(el, 'max-width', '100%');
        });
        const cardContent = this.dialogContent.nativeElement.querySelectorAll('.card-content');
        cardContent.forEach((content: Element) => {
          this.renderer.setStyle(content, 'padding-left', '10px');
        });
        this.renderer.setStyle(this.dialogContent.nativeElement, 'overflow-x', 'hidden');
      }
    }, 0);
  }

  close(): void {
    this.dialogRef.close();
  }
}

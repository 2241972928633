import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PersonalInfomationComponent } from '../personal-infomation/personal-infomation.component';
import { ProfileAboutComponent } from '../profile-about/profile-about.component';
import { MemberProfileComponent } from '../member-profile/member-profile.component';
import { InfraProfileComponent } from '../infra-profile/infra-profile.component';
import { ServicesProfileComponent } from '../services-profile/services-profile.component';
import { CertificationProfileComponent } from '../certification-profile/certification-profile.component';
import { MoreInfoProfileComponent } from '../more-info-profile/more-info-profile.component';
import { PhotosProfileComponent } from '../photos-profile/photos-profile.component';
import { SpecialitiesComponent } from '../specialities/specialities.component';

@Component({
  selector: 'app-hospital-profile',
  templateUrl: './hospital-profile.component.html',
  styleUrls: ['./hospital-profile.component.scss']
})
export class HospitalProfileComponent {

  @Input() hospitalForm!:FormGroup;

  @Input() endCustomerData:any

  @Output() onUpdateEvent:EventEmitter<any>=new EventEmitter(); 
 
  @ViewChild(PersonalInfomationComponent) personalInfomationComponent!:PersonalInfomationComponent;
  @ViewChild(ProfileAboutComponent) profileAboutComponent!:ProfileAboutComponent;
  @ViewChild(SpecialitiesComponent) specialitiesComponent!:SpecialitiesComponent;
  @ViewChild(MemberProfileComponent) memberProfileComponent!:MemberProfileComponent;
  @ViewChild(InfraProfileComponent) infraProfileComponent!:InfraProfileComponent;
  @ViewChild(ServicesProfileComponent) servicesProfileComponent!:ServicesProfileComponent;
  @ViewChild(CertificationProfileComponent) certificationProfileComponent!:CertificationProfileComponent;
  @ViewChild(MoreInfoProfileComponent) moreInfoProfileComponent!:MoreInfoProfileComponent;
  @ViewChild(PhotosProfileComponent) photosProfileComponent!:PhotosProfileComponent;

  
  onUpdate(event:any){
    console.log(event);
    this.onUpdateEvent.emit(event);
  }
}

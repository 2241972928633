import { Injectable } from '@angular/core';
import { Stomp } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { Enquiry, Message } from '../models/messaging-models';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private stompClient: any;
  constructor() {

    this.initConnectionSocket();
  }
  ngOnInit() {

  }



  initConnectionSocket() {
    const socket = new SockJS(environment.WEBSOCKET_URL);
    this.stompClient = Stomp.over(socket);
  }



  newMessageReceived: Subject<Enquiry> = new Subject<Enquiry>();

  joinChat(userId: string) {
    const headers = {
      Authorization: `Bearer`,

    };
    const body = {};
    this.stompClient.connect({
      headers: headers,
      userId: userId,
      token: "token"
    }, () => {

      this.stompClient.subscribe('/user/' + userId + '/private', (recData: any) => {

        this.newMessageReceived.next( JSON.parse(recData?.body));
        console.log('The received message is = ',recData?.body);
      })
    });

  }

  sendMessage(message: Enquiry) {
    const headers = {
      Authorization: `Bearer`,
    };
    const body = { message };
    console.log('message', message);
    
    this.stompClient.send('/app/private-message', headers, JSON.stringify(message));

  }
  sendPrivateMessage(message: Message) {
    const headers = {
      Authorization: `Bearer`,
    };
    const body = { message };
    console.log('message', message);
    
    this.stompClient.send('/app/private-messages', headers, JSON.stringify(message));

  }
}

<mat-card class="mt-4" >
    <mat-card-content>

        <div class="row">
            <div class=" d-flex justify-content-between ">
                <!-- <h4 class="profile-heading" style="font-weight: bold;" >Doctors in {{data.companyName}} hospital</h4> -->
                <p class="doctors-profile">Doctors in Medanta hospital</p>
            </div>
            <div class="doctors-container">
                <div class=" doctors-profile-info" *ngFor="let item of [1,2,3,4,5,6]">
                  <div *ngIf="data?.logo" class="profile-image">
                    <img [src]="data?.logo" alt="doctors-profile-image">
                  </div>
                  <div class="doctor-details">
                    <div class="mt-2 mb-2">
                      <h4 class="doctor-name" style="font-weight: bold;">Clark Kent</h4>
                    </div>
                    <p class="">MBBS, MD - General Medicine, DM - Cardiology</p>
                    <p class="">22 years experience overall</p>
                    <p class="">Cardiologist</p>
                  </div>
                </div>
            </div>
              <div class=" d-flex justify-content-between ">
                <!-- <h4 class="profile-heading" style="font-weight: bold;" >Doctors in {{data.companyName}} hospital</h4> -->
                <p class="doctors-profile">Show all Doctors (35)</p>
            </div>
        </div>
    </mat-card-content>
    </mat-card>


import { Component } from '@angular/core';

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.css']
})
export class ProductConfigurationComponent {

}

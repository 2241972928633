import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerSubject = new BehaviorSubject<{ show: boolean, message?: string }>({ show: false });

  spinnerState$ = this.spinnerSubject.asObservable();

  showSpinner(message?: string) {
    this.spinnerSubject.next({ show: true, message });
  }

  hideSpinner() {
    this.spinnerSubject.next({ show: false });
  }
}

import { Component, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { MweTableComponent } from '../mwe-table/mwe-table.component';
import { ListColumn } from 'src/app/models/list-column.model';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import { OemService } from 'src/app/services/oem/oem.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})

export class WebinarsComponent implements OnInit {
  products: any[] = [];
  @Output() addWebinar: boolean = false;
  @Output() updateWebinar: boolean = false;
  @Output() updateWebinarID?: string;
  @Output() viewWebinar: boolean = false;
  @Output() viewWebinarID: any;

  oemId: any;
  dateTime: any;

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, private webinarService: WebinarService, private oemService: OemService, private cdr: ChangeDetectorRef) {
    
    if(this.localStorageService.getItem('roleName')==='DISTRIBUTOR'){
      this.oemId = this.localStorageService.getItem('distributorId')
    }
    else if(this.localStorageService.getItem('roleName')==='OEM'){
      this.oemId = this.localStorageService.getItem('oemId')
    }
    else{
      this.oemId = this.localStorageService.getItem('userId')
    }
  }

  columns: ListColumn[] = [
    { name: 'S.No', property: 'srNo', visible: true, isModelProperty: false },
    { name: 'Webinar ID', property: 'webinarSequenceId', visible: true, isModelProperty: true, isText: true },
    { name: 'Webinar Title', property: 'webinarTitle', visible: true, isModelProperty: true, isText: true },
    { name: 'Organiser Name ', property: 'orgName', visible: true, isModelProperty: true, isText: true },
    { name: 'Date/Time', property:'webinarTime', visible: true, isModelProperty: true, isText: true},
    { name: 'Timezone', property: 'timezone', visible: true, isModelProperty: true, isText: true},
    { name: 'Speakers', property: 'speakersName', visible: true, isModelProperty: true, isRole: true},
    { name: 'Speciality', property: 'speciality', visible: true, isModelProperty: true, isText: true},
    { name: 'Registrations', property: 'registeredCustomerCount', visible: true, isModelProperty: true, isText: true},
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/eye-visble.svg', isImage: true, actionType: MweConstants.viewAction },
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];
  
  ngOnInit(): void {
    this.displayData();
  }

  data: any = []

  displayData(){
    this.webinarService.getWebinar(this.oemId).subscribe(data => {
      this.data = data.data;
      this.transformData();
      this.cdr.detectChanges();
    });
    
  }

  deleteAction(row: any) {
    Swal.fire({
      position: 'center',
      title: 'Are you sure?',
      text: 'You will not be able to recover the deleted record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete It',
      cancelButtonText: 'Cancel'   
    }).then((result) => {
      if (result.value) {
        this.webinarService.deleteWebinar(row.id).subscribe(
          () => {
            Swal.fire(
              'Removed!',
              'Item Removed Successfully.',
              'success'
            );
            this.displayData(); 
          },
          (error) => {
            Swal.fire(
              'Error',
              'There was an error deleting the item.',
              'error'
            );
            console.error('Error occurred:', error);
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Item is safe.',
          'error'
        );
      }
    });
  }

  transformData(){
    if(this.data){
      this.data.map((d: any)=>{
        d.webinarTime = d.webinarDate + ' ' + d.webinarTime;
      })
    }
  }

  close(event:any){
    this.addWebinar = false;
    this.updateWebinar = false;
    this.viewWebinar = false;
    this.displayData();
  }
  
  closeToBack(event:any){
    this.addWebinar = false;
    this.displayData();
   }

  selectedAction(event: any) {
    switch (event.action) {
      case MweConstants.addAction:
        this.updateWebinar = false;
        this.addWebinar = true;
        break;

      case MweConstants.editAction:
        this.updateWebinar = true;
        this.updateWebinarID = event.row.id;
        break;

      case MweConstants.deleteAction:
        this.deleteAction(event.row)
        break;

      case MweConstants.viewAction:
        this.viewWebinar = true;
        this.viewWebinarID = event.row.id;
        break;

      default:
        break;
    }
    
  }
}

<div class="filter-popup">
    <div class="filter-heading">
        <h2 style="color: #0D87C8">Filter OEMs</h2>
    </div>
    <form [formGroup]="filterForm">
        <div class="filter-row">
          <div class="filter-column">
           
            <div class="field">
              <p class="label">Country</p>
              <mat-form-field appearance="outline" class="filter-input">
                <mat-select formControlName="country" (selectionChange)="onCountryChange($event.value)" placeholder="Select">
                  <mat-option *ngFor="let country of countries" [value]="country">{{ country.countryName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
              <p class="label">State</p>
              <mat-form-field appearance="outline" class="filter-input">
                <mat-select formControlName="state" (selectionChange)="onStateChange($event.value)" placeholder="Select">
                  <mat-option *ngFor="let state of states" [value]="state">{{ state.stateName }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field">
                <p class="label">City</p>
                <mat-form-field appearance="outline" class="filter-input">
                  <mat-select formControlName="city" (selectionChange)="onCityChange($event.value)" placeholder="Select">
                    <mat-option *ngFor="let city of cities" [value]="city">{{ city.cityName }}</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
          </div>
        </div>
        <div class="filter-row">
          <div class="filter-column">
            <div class="field">
                <p class="label">Account Type</p>
                <mat-form-field appearance="outline" class="filter-input">
                    <mat-select formControlName="accountType" placeholder="Select">
                    <mat-option value="OEM">OEM</mat-option>
                    <mat-option value="Distributor">Distributor</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
            <div class="field">
              <p class="label">Company Name</p>
              <mat-form-field appearance="outline" class="filter-input">
                <input matInput placeholder="Enter Company Name" formControlName="companyName">
              </mat-form-field>
            </div>
            <div class="field">
                <p class="label">Stage</p>
                <mat-form-field appearance="outline" class="filter-input">
                  
                    <mat-select formControlName="leadState" placeholder="Select">
                        <mat-option value="initial_contact">Initial Contact</mat-option>
                        <mat-option value="schedule_appointment">Schedule Appointment</mat-option>
                        <mat-option value="qualified">Qualified</mat-option>
                        <mat-option value="account_created">Account Created</mat-option>
                        <mat-option value="on_hold">On Hold</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>
          </div>
        </div>
        <div class="filter-row">
            <div class="filter-column">
             
              <div class="field">
                <p class="label">Added By</p>
                <mat-form-field appearance="outline" class="filter-input">
                  <mat-select formControlName="addedBy" placeholder="Select">
                    <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                      {{ staff.user_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        <div class="footer">
            <button mat-raised-button class="filter-btn" (click)="submitFilter()">Filter</button>
            <button mat-raised-button class="clear-btn" (click)="clearFilter()">Clear</button>&nbsp;&nbsp;
            <button mat-raised-button class="clear-btn" (click)="cancelFilter()">Cancel</button>
        </div>
      </form>

      
      
</div>
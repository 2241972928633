<div class="chat-container">
  
  <div [class]="oemPersona ? 'chat-list oem' : 'chat-list non-oem'">
    <div class="chat-navigation" *ngIf="enquiryTabVisible">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select placeholder="Select Enquiry Type" [value]="enquiryType" (selectionChange)="selectEnquiryType($event)">
          <mat-option value="All">All</mat-option>
          <mat-option value="GENERAL">General Enquiry</mat-option>
          <mat-option value="PRODUCT">Product Enquiry</mat-option>
          <mat-option value="DISTRIBUTOR">Distributor Enquiry</mat-option>
        </mat-select>
      </mat-form-field>

    </div>    
    <mat-form-field appearance="outline" class="search-bar">
      <div class="d-flex searchbox">
        <img alt="" src="assets/images/search.svg" /> 
        <input matInput placeholder="Search" 
        (input)="filterByGroupName($event)"/>
      </div>
    </mat-form-field>

    <div *ngFor="let chatId of getChatIds(enquiryType)">
      <div class="">
        <button
          class="chat-names-button"
          title="name"
          (click)="goToChatById(chatId)"  
          [class.selected-button]="selectedChatItem === chatId"
          style="border: none;"
        >
        <div *ngIf="chats[chatId].group; else notGroup">
          <div
            class="chat-initials"
            [ngStyle]="{ 'background-color': getRandomColor(chatId) }"
            style="margin-right: 5px;"> {{chats[chatId].groupName.substring(0, 2).toUpperCase()  || 'PE'}}</div>
        </div>
        <ng-template #notGroup>
          <div>
            <img
            class="chat-initials" 
              [src]="companyDetailsMap[chatId]?.logo || 'assets/images/Ellipse 2 (1).svg'"
              style="margin-right: 5px;"
            />
          </div>
        </ng-template>  
          <span class="chat-name">{{ chats[chatId].group? chats[chatId].groupName : companyDetailsMap[chatId]?.name }}</span>  <!-- Adjust to display groupName or default -->
        </button>
        <hr/>
      </div>
    </div>
    
    
  </div>
  
  <div class="chat-conversation" *ngIf="!selecteChat">

    <span class=" select-chat" style="margin-top: 5px; margin-left: 50px;">Select a chat to start messaging</span>
  </div>

  <div [ngClass]="{'chat-conversation': true, 'with-sidebar': sidebarVisible}" *ngIf="selecteChat">
    <div class="chat">
      <div [class]="oemPersona ? 'card oem' : 'card non-oem'">
        <div class="card-header text-white d-flex align-items-center justify-content-between" style="background-color: white;">
          <div class="d-flex align-items-center">
            <div class="chat-top-image" *ngIf="selecteChat">
              <div *ngIf="selecteChat.group; else notGroup">
                <div
                  class="chat-initials"
                  [ngStyle]="{ 'background-color': getRandomColor(selecteChat.id) }"
                  style="margin-right: 5px;"
                >
                  {{selecteChat.groupName.substring(0, 2).toUpperCase()}}
                </div>
              </div>
              <ng-template #notGroup>
                <div>
                  <img
                  class="chat-image"

                    [src]="companyDetailsMap[selecteChat.id]?.logo"
                    style="margin-right: 5px; width: 40px; height: 40px;"
                  />
                </div>
              </ng-template>
            </div>
            <div class="ml-5">
              <span class="chat-name" style="color: #333; font-size: 16px; margin-left: 5px;">{{ selecteChat?.group ? selecteChat.groupName : companyDetailsMap[selecteChat?.id]?.name }}</span>
              <!-- <span class="chat-name" style="color: #333; font-size: 16px; margin-left: 5px;">{{ selecteChat?.groupName }}</span> -->
            </div>
          </div>
        </div>
        <div [class]="oemPersona ? 'card-body overflow-auto msg_card_body oem' : 'card-body overflow-auto msg_card_body non-oem'" #scrollMe [scrollTop]="scrollMe.scrollHeight">
          <div *ngFor="let msg of chatMessages">
            <div class="d-flex justify-content-end mb-4" *ngIf="msg.senderId === userId">
                <div class="p-3 bg-light text-dark rounded msg_cotainer_send">
                  <span [innerHTML]="msg.message"></span>
                  <span class="d-block text-right text-muted msg_time_send">{{ msg.createdOn | date: 'MMM d, y, h:mm:ss a' }}</span>
                </div>
              <div>
                <img [src]="companyDetailsMap[msg.senderId]?.logo || 'assets/images/Ellipse 2 (1).svg'" alt="" class="chat-logo" style="margin-top: -20px;" >
              </div>
            </div>
            <div class="d-flex justify-content-start mb-4" *ngIf="msg.senderId !== userId">
              <div>
                <img [src]="companyDetailsMap[msg.senderId]?.logo || 'assets/images/Ellipse 2 (1).svg'" alt="" class="chat-logo"  (click)="openProfile(msg.senderId)" style="cursor: pointer;">
              </div>
              <div>
                <div class="name">{{ companyDetailsMap[msg.senderId]?.name }}</div>
                <div class="p-3 text-dark rounded msg_cotainer" style="background-color: rgba(13, 135, 200, 0.1);">
                  <span [innerHTML]="msg.message"></span>
                  <span class="d-block text-left text-muted msg_time">{{ msg.createdOn | date: 'MMM d, y, h:mm:ss a' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-send">
          <form [formGroup]="chatForm">
            <div class="input-group" style="position: relative;">

              <!-- <textarea name="" rows="1" class="form-control type_msg"  [placeholder]="uploadedFileName ? '' : 'Type your message...'" formControlName="replymessage" (keyup.enter)="sendMessage()" style="border-radius: 8px; padding-right: 40px;"></textarea> -->
              <angular-editor formControlName="replymessage" class="form-control type_msg" [config]="config" [placeholder]="uploadedFileName ? '' : 'Type your message...'" formControlName="replymessage" (keyup.enter)="sendMessage()" style="border-radius: 8px; padding-right: 40px;" ></angular-editor>

              <div class="file-container">
                <div *ngFor="let uploadFile of attachedFiles; let i = index;">
                  <span *ngIf="uploadFile" class="file-info attach_file_info">
                    {{ uploadFile }}
                    <span class="remove-file" (click)="removeUploadedFile(i)">
                      <img src="assets/images/cancel.svg" alt="" height="18px" width="20px">
                    </span>
                  </span>
                </div>
              </div>
              <span class="attach_btn" style="position: absolute; right: 130px; top: 50%; transform: translateY(-50%); cursor: pointer;">
                <i class="fa fa-paperclip" (click)="fileInput.click()" style="font-size:24px; color: gray;"></i>
                <input type="file" #fileInput (change)="uploadPhoto($event)" style="display: none;" multiple />
              </span>&nbsp;&nbsp;
              <div class="input-group-append send">
                <button type="button" class="mwe-button mwe-button--primary ps-3 pe-3 send_btn" (click)="sendMessage()" style="display:flex; align-items: center; justify-content: center;">
                  <img src="assets/images/send.svg" alt="">
                  <span class="mwe-button__text" style="text-align: center; margin-left: 7px; font-size: 15px">Send</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </div>
  <div class="sidebar" *ngIf="sidebarVisible && !scheduleVisible && isOem">
    <div class="profile-content">
        <img [src]="selectedProfile.logo" alt="Profile Picture" class="profile-picture">
        <h2>{{ selectedProfile.name }}</h2>
        <table class="table">
          <tbody>
              <tr>
                  <td class="view">
                      <img src="assets\images\profileimg.svg" alt="">
                  </td>
                  <td class="overview-text">{{ selectedProfile.roleName }}</td>
                  
              </tr>
              <tr>
                <td class="view"><img src="assets\images\phone.svg" alt=""></td>
                <td class="overview-text "> {{ selectedProfile.phoneNumber }}</td>
              </tr>
                  <tr>
                    <td class="view" ><img src="assets\images\email_sidebar.svg" alt=""></td>
                    <td class="overview-text"> {{ selectedProfile.email }}
                    </td></tr>
                  </tbody></table>
                    
        <button type="button" class="mwe-button mwe-button--primary ps-3 pe-3 "  style="display:flex; align-items: center; justify-content: space-around;" (click)="toggleSidebar()">
          <div style="padding:8px 16px;">
          <img src="assets\images\monitor 2 copy.svg" alt="">
          <span class="mwe-button__text" style="text-align:start;font-size: 14px ; width: 12rem;padding-left: 4px;">Schedule Meeting</span>
        </div>
        </button>

        <div class="chat-navigation" style="margin-top: 20px;">
          <button type="button" class="chat-navigation-btn" [class.active]="tab===0" (click)="mediaTab()">Media</button>
          <button type="button" class="chat-navigation-btn" [class.active]="tab===1" (click)="documentsTab()">Docs</button>
        </div> 

       

    </div>
<!-- Sidebar content here -->
<button (click)="closeSidebar()" class="close-btn"><img src="assets\images\left_arrow.svg" alt=""></button>

</div>

 
      <div class="sidebar2"  *ngIf="scheduleVisible && sidebarVisible">
        <div>
        <h2 class="page-heading">Schedule Meeting </h2>
        
        
        <form [formGroup]="scheduleForm">
          <div>
            <div style="margin-top: 8px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Meeting Subject</p>
            </div>
            <input class="input-font w-100" id="subject" formControlName="subject">
          </div>
          <div>
            <div style="margin-top: 3px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">StartTime</p>
            </div>
            <input class="input-font w-100" id="startTime" type="datetime-local" formControlName="startTime">
          </div>
          <div style="margin-top: 3px;">
            <div>
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">End Time</p>
            </div>
            <input class="input-font w-100" id="endTime" type="datetime-local" formControlName="endTime">
          </div>

          
          <div formArrayName="attendees" style="margin-top: 3px;" >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Attendees</p>
            <div class="inside">
            <mat-chip-list>
              <mat-chip class="chip-item" *ngFor="let attendee of attendees.controls; let i = index" [formGroupName]="i" removable="true" [title]="attendee.value.name" >
                {{ attendee.value.name }}
                
              <mat-icon *ngIf="!attendeeGroups.includes(attendee.value.email)" matChipRemove (click)="removeAttendee(i)">
                cancel
              </mat-icon>

              </mat-chip>
            </mat-chip-list>
          </div>
            
            <div *ngIf="!attendeeAdded">
              <div style="margin-top: 3px;">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Email</p>
                <input type="email" class="input-font w-100" [formControl]="newEmail" placeholder="Enter email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required>
              </div>
              <div style="margin-top: 3px;">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Name</p>
                <input class="input-font w-100" [formControl]="newName" placeholder="Enter name" required>
              </div>
              <div style="display: flex; flex-direction: row;">
              <button class="d-flex more" (click)="addAttendee()" style="margin: 10px 0px;">
                <mat-icon>add</mat-icon>
                <span class="deltext">Add</span>
              </button>&nbsp;&nbsp;
              <button class="d-flex more" type="button" (click)="clear()" style="margin: 10px 0px;">
                <span class="deltext">Cancel</span>
              </button>
            </div>
            </div>
            
          </div>
          <button class="d-flex more" *ngIf="attendeeAdded" (click)="add()" style="margin: 10px 0px;" >
            <mat-icon>add</mat-icon>
            <span class="deltext">Add</span>
            
          </button>
          
          <!-- <button class="d-flex more" *ngIf="!attendeeAdded" (click)="addAttendee()" style="margin: 10px 0px;" >
            <mat-icon>add</mat-icon>
            <span class="deltext">Add</span>
          </button> -->
          <button class="mwe-button mwe-button--primary ps-3 pe-3" (click)="save()">Save</button>&nbsp;&nbsp;
          <button class="mwe-button mwe-button--secondary ps-3 pe-3" (click)="closescheduleSidebar()">Cancel</button>

          
          
        </form>
      </div>
      <button (click)="closescheduleSidebar()" class="close-btn2"><img src="assets\images\left_arrow.svg" alt="fgfgf"></button>

        

        
      </div>
</div>




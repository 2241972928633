<app-website-header></app-website-header>
<div class="imagecontent">
    <div class="overlay-text"><br><span class="display-6" style="display: flex; margin-bottom: 0; "> Products </span> </div>
    <img src="../../../assets/images/landerpage.png" alt="landingpage" class="landingpageimage">
</div>
<app-bread-crumb [breadcrumb]="breadcrumb"></app-bread-crumb>
<div class="containerr">
    <div class="d-flex containerr-top" style="height: auto; display: block;">
        <div class="p-2 d-flex containerr-images">
            <div class="d-flex align-items-start flex-column imgs-s" style="height: auto;">
                <div class="p-2 image-border m-2" *ngIf="data?.images?.length>1">
                    <img [src] = 'data.images[1]' class="img-wrap-s" (click)="smallImgs(1)" width="100px" height="100px" />
                </div>
                <div class="p-2 image-border m-2" *ngIf="data?.images?.length>2">
                    <img [src] = 'this.data.images[2]' class="img-wrap-s" (click)="smallImgs(2)" width="100px" height="100px" />
                </div>
                <div class="p-2 image-border m-2" *ngIf="data?.images?.length>3">                    
                    <img [src] = 'this.data.images[3]' class="img-wrap-s" (click)="smallImgs(3)" width="100px" height="100px" />
                </div>
                <div class="p-2 image-border m-2 small-imgs-last" *ngIf="data?.images?.length>4">  
                    <img *ngIf="this.data?.images?.length>4" [src] = 'this.data.images[4]' class="img-wrap-s" width="100px" height="100px" style="opacity: .5;" />
                    <img *ngIf="this.data?.images?.length<4" [src] = 'this.data.images[4]' class="img-wrap-s" (click)="smallImgs(4)" width="100px" height="100px" />
                    <p *ngIf="data?.images?.length>4" class="more-imgs">+4</p>
                </div>
            </div>
            <div class="p-3 px-5 image-border">
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img [src]="data?.images?.[currImg] || ''" class="img-wrap fade-right" width="400px" height="400px" />
                      </div>
                    </div>
                    <button class="carousel-control-prev" role="button" data-slide="prev" (click)="btnPrev()" >
                        <span aria-hidden="true" style="margin-right: 7em"> <img src="../../assets/images/icons/left-arrow.svg" width="20px"> </span>
                        <span class="sr-only">Previous</span>
                    </button>
                    <button class="carousel-control-next right-arrow" role="button" data-slide="next" (click)="btnNxt()" >
                        <span aria-hidden="true" style="margin-left: 7em"> <img src="../../assets/images/icons/right-arrow.svg" width="20px"> </span>
                        <span class="sr-only">Next</span>
                    </button>
                  </div>
            </div>
        </div>
        <div class="d-flex" style="height: fit-content; width: fit-content; min-height: 500px; max-height: 400px;">
                <div class="d-flex align-items-start flex-column" style="height: 200px;"> 
                    <div class="p-2 heading-div">
                        <div class="d-flex flex-wrap">
                            <div class="pr-2"> <p style="font-size: 2.5em;" class="heading-name"> {{ data?.name }} </p> </div>
                            <div class="pl-2" style="margin-top: 0px; display: flex; flex-wrap: wrap;" >  
                              <span class="seller mx-1" *ngFor="let seller of data?.tags" style="padding: 10px; margin: 1px; font-size: 1em;">  {{seller}} </span> 
                              <span class="seller mx-1" *ngFor="let seller of data?.certificates" style="padding: 10px; margin: 1px; font-size: 1em;"> {{seller?.name}} </span> 
                            </div>
                        </div>
                    </div>
                    <div class="p-2">
                        <div class="d-flex flex-wrap align-items-center">
                            <div class="rating-container">
                                <div class="stars">
                                  <span>
                                    <img src="assets/images/full-star-rating.svg" class="star" />
                                    <img src="assets/images/full-star-rating.svg" class="star" />
                                    <img src="assets/images/full-star-rating.svg" class="star" />
                                    <img src="assets/images/full-star-rating.svg" class="star" />
                                    <img src="assets/images/empty-star-rating.svg" class="star" />
                                  </span>
                                </div>
                            </div>
                            <div class="pt-2">
                                <div class="rating-container">
                                    <div class="stars">
                                      <span>
                                        <img src="../../assets/images/message-productpage.svg" class="star">
                                        <span> 5.0 out of <strong>({{reviews.length}})</strong> Reviews</span>
                                      </span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="d-flex my-2">
                        <strong class="mx-2"> {{ data?.soldTillNow }} </strong> <span style="font-weight: 500;">Sold Now</span>
                    </div>
                    <div class="d-flex my-3">
                        <div class="mx-2 align-content-center">
                            <img *ngIf="oemData?.oemLogo" [src]="oemData?.oemLogo" width="84px" height="33px" />
                        </div>
                        <div class="d-flex flex-column">
                            <div>      
                                <span style="font-size: 16px; font-weight: 400;" >{{data?.oemName}}</span><br>
                                <a *ngIf="data?.oemId" [routerLink]="['/brands/brand-profile', data.oemId]" [queryParams]="{ type: 'brand' }" style="text-decoration: none; font-size: 16px; font-weight: 600;">
                                  View {{ data?.oemName.split(' ')[0] }} Profile
                                </a>                                
                            </div>
                        </div>
                    </div>
                    <div class="p-2 mb-3">
                        <div class="row" style="position: relative;">
                            <div *ngIf="!signin" class="signin"> 
                              <p class="d-flex align-content-center justify-content-center flex-column"> Sign in for more </p>
                              <p class="d-flex align-content-center justify-content-center flex-column"> Information </p>
                            </div>
                            <div class="col-1 my-2"><img src="../../assets/images/icons/location-icon-productpage.svg" class="star"></div>
                            <div class="col-1 my-2"><strong>Office</strong></div>
                            <div class="col-9 my-2" *ngIf="!signin" style="filter: blur(4px);"><strong>{{oemData?.address}}</strong></div>
                            <div class="col-9 my-2" *ngIf="signin" ><strong>{{oemData?.address}}</strong></div>
                            <div class="w-100"></div>
                            <div class="col-1 my-2"><img  src="../../assets/images/icons/phone-icon-productpage.svg" class="star"></div>
                            <div class="col-1 my-2"><strong>Phone</strong></div>
                            <div class="col-9 my-2" *ngIf="!signin" style="filter: blur(4px);"><strong>{{oemData.phone}}</strong></div>
                            <div class="col-9 my-2" *ngIf="signin"><strong>{{oemData?.phone}}</strong></div>
                            <div class="w-100"></div>
                            <div class="col-1 my-2"><img src="../../assets/images/icons/email-icon-productpage.svg" class="star"></div>
                            <div class="col-1 my-2"><p><strong>Email</strong></p></div>
                            <div class="col-9 my-2" *ngIf="!signin" style="filter: blur(4px);"><strong>{{oemData.email}}</strong></div>
                            <div class="col-9 my-2" *ngIf="signin"><strong>{{oemData?.email}}</strong></div>
                        </div>
                    </div>

                    <div class="flex submit align-items-end " style="max-width: 500px;">
                        <button *ngIf="data" [routerLink]="['/user/products/productEnquiryDistributors', data.id]" class="btn" style="background-color: #0D87C8; color: white; width: 80%;">
                            <img src="../../assets/images/icons/send-an-enquiry.svg" >
                           <span>&nbsp;Send an Enquiry</span>
                        </button>
                        <button class="btn" style="width: 80%; white-space: nowrap;" (click)="applyForDistributor()">
                          <img src="../../assets/images/icons/apply-for-distributor.svg" style="vertical-align: middle;">
                          <span>&nbsp;Apply&nbsp;for&nbsp;Distributor</span>
                      </button>
                      
                    </div>
                </div>
        </div>
    </div>
    <div class="tab-container">
        <div class="tab-header">
          <div class="tab" [class.active]="activeTab === 0" (click)="selectTab(0)">Product Description</div>
          <div class="tab" [class.active]="activeTab === 1" (click)="selectTab(1)">Specifications</div>
          <div class="tab" [class.active]="activeTab === 2" (click)="selectTab(2)">Videos</div>
          <div class="tab" [class.active]="activeTab === 3" (click)="selectTab(3)">Catalogs</div>
          <div class="tab" [class.active]="activeTab === 4" (click)="selectTab(4)">Reviews ({{ reviews.length }})</div>
          <div class="tab-slider" [style.width.px]="tabSliderWidth" [style.transform]="'translateX(' + tabSliderPosition + 'px)'"></div>
        </div>
        <div *ngIf="activeTab === 0" class="tab-content" ><div [innerHtml]="data?.description"></div></div>
        <div *ngIf="activeTab === 1" class="tab-content" >
            
          <div class="row" *ngFor="let spec of data.specifications">
            <div class="col-2 my-2" ><strong>{{ spec.name }}</strong></div>
            <div class="col-9 my-2" *ngIf="spec.isRange == 'false'"><strong> {{ spec.fromValue }} {{ spec.fromUnit }}</strong></div>
            <div class="col-9 my-2" *ngIf="spec.isRange == 'true'"><strong> {{ spec.fromValue }} {{ spec.fromUnit }} - {{ spec.toValue }} {{ spec.toUnit }}</strong></div>
            <div class="w-100"></div>
          </div>
            
      </div>
        <div *ngIf="activeTab === 2" class="tab-content" >
          <div class="video-container">
            <!-- <iframe width="560" height="315" [src]="data.video | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
            <iframe width="560" height="315" [src]="getEmbedUrl(data.video) | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </div> 
        </div>
        <div *ngIf="activeTab === 4" class="tab-content">

          <!-- Reviews Start -->
          <span style="color: rgba(13, 135, 200, 1);
          font-size: 35px;
          font-weight: 600;
          line-height: 28px;
          "> {{ averageRating || 0 }} out of 5 </span> <br>
          <div class="ratings-star">
            <div  *ngFor="let j of [1, 2, 3, 4, 5]">
              <img  
                    src="../../assets/images/full-star-rating.svg" 
                    *ngIf="j <= averageRating" /> 
              <img  
                    src="../../assets/images/empty-star-rating.svg" 
                    *ngIf="j > averageRating" />
            </div>
            <div class="d-flex align-items-center justify-content-center">
              5.0 out of <strong class="mx-1"> ({{reviews.length}}) </strong> Reviews
            </div><br><br><hr>
          </div>

          <div class="reviews-container d-flex flex-row gap-5">
            <div>
              <div *ngFor="let i of ratingCount; let k = index">
                <div class="d-flex flex-row gap-2 align-items-center">
                  <!-- <progress [value]="calculatePercentage(i)" max="100"></progress> -->
                  <div class="rating-bar d-flex align-items-center">
                    <div class="bar">
                      
                      <div *ngIf="calculatePercentage(i) !== 0 && averageRating; else emptyState" class="fill" [style.width.%]="calculatePercentage(i)"></div>
                      <ng-template #emptyState>
                        <div style="padding: 3px;"></div>
                      </ng-template>

                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center" *ngFor="let j of [1, 2, 3, 4, 5]">
                    <img  
                         src="../../assets/images/full-star-rating.svg" 
                         width="10px" 
                         *ngIf="(k+j)<=5" /> 
                    <img  
                         src="../../assets/images/empty-star-rating.svg" 
                         width="10px" 
                         *ngIf="(k+j)>5" />
                  </div>
                  {{ calculatePercentage(i) || 0 }}%
                </div>
              </div>
              <br><br>
              <div *ngIf="isLoggedIn">
                <button class="button-primary btn btn-primary d-flex align-items-center" (click)="reviewInput()" *ngIf="!toggleWriteReview"> <img src="../../assets/images/review.svg" /> Write a Review</button>
              </div>
              <div class="d-flex flex-column " *ngIf="toggleWriteReview" >
                <div class="ratings-star">
                  <div *ngFor="let i of [1,2,3,4,5]">
                    <img src="../../assets/images/full-star-rating.svg" (click)="starClicked(i)" *ngIf="starIndex>=i" />
                    <img src="../../assets/images/empty-star-rating.svg" (click)="starClicked(i)" *ngIf="starIndex<i" />
                  </div>
                </div>
                  <angular-editor [(ngModel)] = "review" class="editor" [config]="config" ></angular-editor>
                <div class="buttons mt-3 gap-3 d-flex flex-row w-25">
                  <button class="button-primary btn btn-primary d-flex align-items-center" style="border: none; padding: 0px 50px;" type="submit" (click)="addReview('add')" >Add</button>
                  <button class="button-inverse btn btn-primary d-flex align-items-center" style="border: none; padding: 0px 50px;" type="button" (click)="addReview('cancel')" >cancel</button>
                </div>
              </div><br><br>
            </div>

            <div>
              <div *ngFor="let review of reviews | slice : 0: displayReviews; let i = index" >
                <img src="../../assets/images/account-profile.svg" width="24px" />  <span style="font-weight: 600;"> {{review.userName}} </span> <span style="color: #888; margin-bottom: 5px;">  {{ calculateDaysAgo(review.date) }} <span *ngIf="review.userId===userId" style="cursor: pointer;"> <img src="../../assets/images/edit.svg" (click)="editReview(review.reviewId)" />   <img src="../../assets/images/icons/delete-icon.svg" (click)="deleteReview(review.reviewId)" />  </span>  </span> 
                  <br><span *ngFor="let i of [1,2,3,4,5]" style="margin-top: 10px;">
                    <img src="../../assets/images/full-star-rating.svg" *ngIf="review.rating>=i" />
                    <img src="../../assets/images/empty-star-rating.svg" *ngIf="review.rating<i" />
                  </span>
                <br> {{review.description}}<br>
                <hr>
              </div>
              <b class="view-more-reviews" *ngIf="reviews.length > displayReviews" (click)="viewMoreReviews()">View More</b>
              <br>
            </div>
          </div>
          
          <!-- Reviews End -->
        </div>
        <div *ngIf="activeTab === 3" class="tab-content">
          <div class="catalog-container">
            <div class="card" *ngFor="let catalog of catalogFiles">
              <div *ngIf="catalog.type === 'image'">
                <img [src]="catalog.url" alt="catalog"/>
              </div>
              <div *ngIf="catalog.type === 'pdf'">
                <div class="iframe-container">
                  <iframe [src]="catalog.url | safe" class="main-iframe"></iframe>
                </div>
              </div>

              <div class="card-actions">
                <h3 *ngIf="catalog.fileViewName">{{ catalog.fileViewName.length > 20 ? catalog.fileViewName.slice(0, 20) + '...' : catalog.fileViewName | camelcase}} </h3>
                <!-- <p>3 pages</p> -->
                 <p></p>
              </div>
              
              <div class="buttons">
                <div class="buttons">
                  <button class="view-button" (click)="viewCatalog(catalog)"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
                  <button class="download-button" (click)="downloadCatalog(catalog)"><img src="assets/images/download.svg" alt="Download"/>Download</button>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
        <br><br>

    <h1 style="border-bottom: 2px solid #0D87C8;  margin: 0; margin-left: .5rem; display: inline-block; color: #0D87C8; font-size: medium; font-weight: 00; font-family: 'work sans';">Similar Products</h1>
    <h1 style="border: none; border-top: 1px solid #0D87C8;  width: 100%; margin: 0; margin-left: .5rem;"></h1>
    
    <div class="products-container d-flex flex-row flex-wrap m-5" style="width: 100%;margin-left: 2%;" >
      <ng-container *ngFor="let product of products?.slice(0, 5)">
          <div class="categories d-flex flex-column flex-wrap" style="flex: 1">
            <app-product-info-card
              [id]="product.id"
              [productImage]="product.images[0]"
              [title]="product.productname"
              [isCategoryCard]="false"
              [subtitleImage]='product.oemlogo'
              [hoverText]="product.description"
              [contentItems]="product.specifications">
            </app-product-info-card>
        </div>
      </ng-container>
    </div>
</div>
<app-website-footer></app-website-footer>
// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { apiUrls, OEMUrls } from '../utils/apiUrls';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private selectedComponentSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  apiUrl = apiUrls.notifications;
  constructor(private http: HttpClient) {}

 

  getNotifications(userId?: string, pageNumber?: number, pageSize?: number): Observable<any> {
    const url = `${this.apiUrl}?userid=${userId}&pageNumber=${pageNumber || 0}&pageSize=${pageSize || 10}`;
    return this.http.get<any>(url);
  }
  setSelectedComponent(component: string): void {
    console.log('Selected component: sdfasdf', component);
    this.selectedComponentSubject.next(component);
  }

  getSelectedComponent(): Observable<string> {
    return this.selectedComponentSubject.asObservable();
  }


}

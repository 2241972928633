<div>
    <app-mwe-table [columns]="columns" [data]="data" [showPagination]="false" [showHeader]="false"  (selectedAction)="selectedAction($event)"></app-mwe-table>
    <form [formGroup]="contactForm">
   <!--style="background-color: #F0F0F0; padding: 20px 10px;" -->
    
      <div class="card p-4 mt-2 mb-2" *ngIf="isProceed">
          <div class="row">
            <div class="col-lg-3">
                <div>
                  <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Salutation</p>
                </div>
                <div>
                  <mat-form-field appearance="outline" class="fields input-font w-100">
                    <mat-select formControlName="salutation" >
                      <mat-option value="Mr">Mr</mat-option>
                      <mat-option value="Ms">Ms</mat-option>
                    </mat-select>
                    <mat-error *ngIf="contactForm.get('salutation')?.hasError('required')">
                      Salutation is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            <div class="col-lg-3">
              <div>
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Contact Name</p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                  <input matInput formControlName="contactName">
                  <mat-error *ngIf="contactForm.get('contactName')?.hasError('required')">
                    Contact name is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-3">
              <div>
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Phone Number</p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                  <input matInput formControlName="phoneNumber" (keypress)="numberOnly($event)">
                  <mat-error *ngIf="contactForm.get('phoneNumber')?.hasError('required')">
                    Phone number is required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-3">
              <div>
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Email</p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                  <input matInput formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                  
                </mat-form-field>
              </div>
            </div>
          </div>
        
          <div class="row mt-4">
            <div class="col-lg-12">
               <button class="mwe-button mwe-button--primary ps-3 pe-3" (click)="addPointOfContact()" >
                <span class="deltext">{{isEdit ? 'Save' : 'Add'}}</span>
          
              </button>&nbsp;&nbsp;

              <button class="mwe-button mwe-button--secondary ps-3 pe-3" (click)=" toggleCancel()"
                   >
                    <span class="deltext">Cancel</span>
                </button>
            </div>
          </div> 
        </div>
        <div *ngIf="!isProceed&&!isMore" class="mt-2 mb-2" style="margin-left: 10px;">
          <button class="d-flex more" (click)=" toggleIsProceed()"
          ><mat-icon>add</mat-icon>
           <span class="deltext">Add</span>
       </button>
      </div>
      <div *ngIf="!isProceed&&isMore" class="mt-2 mb-2" style="margin-left: 10px;" >
       <button class="d-flex more" (click)=" toggleIsProceed()"
       ><mat-icon>add</mat-icon>
        <span class="deltext">Add More</span>
    </button>
  </div>
    
   
    </form>
</div>
  
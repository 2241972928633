<mat-card (click)="navigateToRoute(routerLink)"   class="dashboard-card" style="width: 90%;" [ngClass]="{
  'oem-card': cardType === 'oem',
  'distributor-card': cardType === 'distributor',
  'end-customer-card': cardType === 'end-customer',
  'revenue-card': cardType === 'revenue',
  'oem-dashboard-card': cardType === 'oem-dashboard',
  'distributor-dashboard-card': cardType === 'distributor-dashboard'
}" >
    <mat-card-content class="dashboard-card-content" >
        <div class="metric-info" >
          <span><img src="{{metric?.icon}}" hieght="45px" width="45px"></span>
          <div class="label-value">
            <h3>
              <span *ngIf="cardType === 'end-customer'; else defaultLabel">
                {{ metric?.label | pascalToReadable }}
              </span>
              <ng-template #defaultLabel>
                {{ metric?.label }}
              </ng-template>
            </h3>
            <h2 class="value" clas=" align">{{ metric?.value }}</h2>
          </div>
         
        </div>
        <div *ngIf="cardType === 'oem-dashboard'" class="metric-button" >
          <a (click)="moreClicked()" mat-button  class="metric-button-text" >more</a>
        </div>
        <div *ngIf="cardType === 'distributor-dashboard'" class="metric-button">
          <a (click)="moreClicked()" mat-button  class="distributor-metric-button-text" >more</a>
        </div>
        <ng-content></ng-content>
    </mat-card-content>
     
 </mat-card>
<div class="containerr">
    <button class="header-btn m-2 add-btn" mat-raised-button (click)="back()">
        <mat-icon>arrow_back</mat-icon> Back
    </button>
    <div class="header">
        <img [src]="webinarData.image" max-width="100%" height="300px" />

        <div class="time">
            <div class="days">
            <span class="number"> {{timeToWebinar.length>0?timeToWebinar[0]:'00'}} </span>
            <hr>
                <span class="string">DAYS</span>
            </div>
            <div class="hrs">
                <span class="number"> {{timeToWebinar.length>0?timeToWebinar[1]:'00'}} </span> 
                <hr>
                <span>HRS</span>
            </div>

            <div class="mins">
                <span class="number"> {{timeToWebinar.length>0?timeToWebinar[2]:'00'}} </span>
                <hr>
                <span>MINS</span>
            </div>   

            <div class="secs">
                <span class="number"> {{timeToWebinar.length>0?timeToWebinar[3]:'00'}} </span>
                <hr>
                <span>SECS</span>
            </div>
        </div>
    </div><br><br>

    <div class="txt">
        <h1 style="color: #0D87C8; font-weight: 500;" class="font-work-sans"> {{ webinarData.webinarTitle }} </h1>
        <div class="inner-txt">
            <div class="txt-l">
                    <div class="top font-work-sans">
                        <p class="font-work-sans" style="font-weight: 500;" [innerHTML]="webinarData.aboutWebinar" ></p>
                    </div>
            </div>
            <div class="txt-r">
                <img [src]="webinarData.hostImage" width="100%" height="300px" />
            </div>
        </div>
    </div>

    <br><br>

    <div class="SS font-work-sans">
        <div class="speakers">
            <h1 class="font-work-sans headings-all">Speakers Name</h1>
            <div class="inner-seapkers">
                <p *ngFor="let speaker of webinarData.speakersName    " class="font-work-sans"> {{ speaker }} </p>
            </div>
        </div>
        <div class="speciality ">
            <h1 class="font-work-sans headings-all">Speciality</h1>
            <p class="font-work-sans"> {{ webinarData.speciality }} </p>
        </div>
    </div>
    <br><br>
    <div class="org-info">
        <h1 class="font-work-sans headings-all">Organiser Info</h1>
            <table class="table-organiser">
                <tr class="table-header font-work-sans" >
                    <th class="font-work-sans">Name</th>
                    <th class="font-work-sans">Email</th>
                    <th class="font-work-sans">Phone</th>
                </tr>
                <tr class="data">
                    <td class="font-work-sans">{{this.webinarData.orgName}}</td>
                    <td class="font-work-sans">{{this.webinarData.orgEmail}}</td>
                    <td class="font-work-sans">{{this.webinarData.orgPhone}}</td>
                </tr>
            </table>
    </div>
    <br><br>

    <div class="org-info">
        <h1 class="headings-all">Webinar Date & Time</h1>
            <table class="table-organiser">
                <tr class="table-header" >
                    <th class="font-work-sans">Date</th>
                    <th class="font-work-sans">Time</th>
                    <th class="font-work-sans">Timezome</th>
                    <th class="font-work-sans">Duration</th>
                </tr>
                <tr class="data">
                    <td class="font-work-sans">{{formatwebinarDate}}</td>
                    <td class="font-work-sans">{{formatwebinarTime}}</td>
                    <td class="font-work-sans">{{webinarData.timezone}}</td>
                    <td class="font-work-sans">{{formatwebinarDuration}}</td>
                </tr>
            </table>
    </div>

    <br>
    <div class="org-info">
        <h1  class="font-work-sans headings-all">Organiser Info</h1>
            <table class="table-organiser">
                <tr class="table-header" >
                    <th class="font-work-sans">Source</th>
                    <th class="font-work-sans">URL</th>
                </tr>
                <tr class="data">
                    <td class="font-work-sans">{{this.webinarData.webinarSource}}</td>
                    <td class="font-work-sans">{{this.webinarData.webinarUrl}}</td>
                </tr>
            </table>
    </div>
    <div>
        <app-mwe-table [columns]="columns" [data]="regiteredCustomersData" [showHeader]="true" ></app-mwe-table>
    </div>
</div>
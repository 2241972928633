<div class="personal-infomation p-4 mt-4">
    <div class=" d-flex justify-content-between ">
        <h2 class="profile-heading">Specialites</h2>
        <img class="edit-profile " (click)="editSpeciality()"
            *ngIf="endCustomerData?.specialityInfos && this.endCustomerData.specialityInfos.length>0 && !showEditForm" alt=""
            src="../../assets/images/editprofile.svg">

    </div>

    <div class="mt-2"
        *ngIf="!(endCustomerData?.specialityInfos && this.endCustomerData.specialityInfos.length>0) && !showEditForm">
        <button (click)="editSpeciality()" class="profile-btn">
            <mat-icon>add</mat-icon>
            <p class="ps-2">Add Specialities</p>
        </button>
    </div>
    <div class="mt-2 name-container"
        *ngIf="endCustomerData?.specialityInfos && this.endCustomerData.specialityInfos.length>0 && !showEditForm">
        <div *ngFor="let speciality of specialityData" class="my-3">
            <span class=" speciality " >
                <label class="med-world-basic-font med-world-basic-font__grey">{{speciality?.name}}</label>
                
            </span>
            <span class="mx-1"></span>
        </div>
        
    </div>

    <div *ngIf="showEditForm" class="mt-2">
        <div class="row" [formGroup]="specialityForm">
              
            <div
                class="col-lg-12">
                <div >
                    <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color ">Select Specialization</p>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100 equipment"
                    [class.equipment_increase]="specialityForm.get('specialityInfos')?.value.length>4">
                    <mat-select placeholder="Select" formControlName="specialityInfos" multiple
                        >
                        <mat-select-trigger>

                            <mat-chip-listbox>
                                <mat-chip class="chip" *ngFor="let topping of specialityForm.get('specialityInfos')?.value ; let i=index"
                                    [removable]="true" (removed)="remove(i)">
                                    {{ getSpecility(topping)}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-listbox>
                        </mat-select-trigger>
                        <mat-option *ngFor="let specialisation of specialisations"
                            [value]="specialisation.id">{{specialisation?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                </div>
            </div>
        </div>
        <div class="profile-action " >
            
    
            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
        
        
            
        
        
            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
        
        
        
        </div>
    </div>

</div>
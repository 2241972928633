<div class="modal-popup" mat-dialog-content>
    <div class="popup-heading">
      <h2 style="color: #0D87C8" class="d-flex">{{subscriptionId ? isViewmode ? 'View Subscription Plan' : 'Edit Subscription Plan' : 'Create Subscription Plan'}}</h2>
    </div>
  
    <form [formGroup]="addEditSubscriptionForm" (ngSubmit)="onFormSubmit()">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Plan Image*</p>
          </div>
          <div>
            <input
              class="file-input-container"
              type="file"
              id="upload"
              accept=".png,.jpg,.jpeg,.svg"
              (change)="uploadLogo($event)"
             *ngIf="!isViewmode"/>
            <label class="d-flex justify-content-center text align-items-center" for="upload" mat-raised-button>
              <img style="margin-top:-4px" alt="" src="./../../assets/images/photo-add.svg" *ngIf="!selectedFile">
              <span *ngIf="!selectedFile">Drag files here to upload (or) Select Files</span>
              <span class="drag-drop-text2 d-flex g-2" *ngIf="selectedFile">
               
                <span [ngClass]="{'viewmode': isViewmode}"> <span  ><img style="width:4rem; height: 4rem ;margin-right: 1rem;" alt="" [src]="imageURL "></span> </span>
               

                <button  type="button" class="close-icon mr-3" (click)="removeFile($event)" *ngIf="!isViewmode"><mat-icon style="color: #0D87C8 ">delete</mat-icon></button>
              </span></label>

          
            <mat-error
              class="error-message error-msg"
              *ngIf="addEditSubscriptionForm.get('image')?.hasError('required')"
            >
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Image is Required
            </mat-error>
          </div>
        </div>
      </div>
  
      <div class="row mt-2">
        <div class="col-lg-6">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Plan Name*</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fields input-font w-100">
              <input matInput formControlName="name" required />
              <mat-error class="error-message error-msg" *ngIf="addEditSubscriptionForm.get('name')?.hasError('required')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="col-lg-6">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Monthly Price*</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fields input-font w-100">
              <span matPrefix class="prefix-padding">₹</span>
              <input matInput formControlName="monthlyPrice" required />
              <mat-error
                class="error-message error-msg"
                *ngIf="addEditSubscriptionForm.get('monthlyPrice')?.hasError('required')"
              >
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Monthly Price is Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="col-lg-6">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Annual Price*</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fields input-font w-100">
              <span matPrefix class="prefix-padding">₹</span>
              <input matInput formControlName="annualPrice" required />
              <mat-error
                class="error-message error-msg"
                *ngIf="addEditSubscriptionForm.get('annualPrice')?.hasError('required')"
              >
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Annual Price is Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
  
        <div class="col-lg-6">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Trial Days*</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fields input-font w-100">
              <mat-select formControlName="trialDays" required>
                <mat-option value="">Select</mat-option>
                <mat-option value="7">7</mat-option>
                <mat-option value="14">14</mat-option>
                <mat-option value="30">30</mat-option>
              </mat-select>
              <mat-error
                class="error-message error-msg"
                *ngIf="addEditSubscriptionForm.get('trialDays')?.hasError('required')"
              >
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Trial Days is Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="row mt-2">
        <div class="col-lg-12">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Description*</p>
          </div>
          <div>
            <textarea matInput formControlName="description" id="note" required></textarea>
            <mat-error
              class="error-message error-msg"
              *ngIf="addEditSubscriptionForm.get('description')?.touched && addEditSubscriptionForm.get('description')?.hasError('required')"
            >
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Description is Required
            </mat-error>
          </div>
        </div>
      </div>
  
      <div class="row mt-2">
        <div class="col-lg-12">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Status*</p>
          </div>
          <div>
            <mat-radio-group formControlName="active" required>
              <mat-radio-button value="true">Active</mat-radio-button>
              <mat-radio-button value="false">Inactive</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
  
      <div class="row mt-2">
        <div class="col-lg-12 text-right d-flex gap-3">
          <button *ngIf="!subscriptionId" type="submit" class="mwe-button mwe-button--primary" [disabled]="!addEditSubscriptionForm.valid">Save</button>
          <button *ngIf="subscriptionId && !isViewmode" type="button" class="mwe-button mwe-button--primary" (click)="updateSubscription()" >Update</button>
          <button type="button" class="mwe-button mwe-button--secondary" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
  
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserDetails } from 'src/app/models/authData';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements  OnInit {

  userName: any;
  password: any;
  errorMessage = '';
  showError = false;
  rememberMeFlag: boolean = true;
  showPassword: boolean = false;
  showPasswordError: boolean = false;
  showUserNameError: boolean = false;

  user: User = { email: '', password: '' } as User;
  constructor(private http: HttpClient, private router: Router, private authService: AuthService, private localStorageService: LocalStorageService, private spinnerService: SpinnerService) {
  }

  ngOnChanges() {
    console.log("onChanges");

    this.showError = false;
    
    this.errorMessage = '';
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      console.log("already logged in");
      const accountName=this.localStorageService.getItem('accountName');
      if(accountName=='Staff'){
        this.authService.navigateStaff(this.localStorageService.getItem('preferences'),this.localStorageService.getItem('roleName'))
      }else{
        this.authService.navigateByRole(this.authService.getRoleName());
      }
    }else{
      console.log("not logged in");
    }
  }

  onSubmit() {
    
    if (!this.user || (!this.user.email || !this.user.email.includes('@')) || !this.user.password) {
      this.showUserNameError = !this.user.email || !this.user.email.includes('@');
      this.showPasswordError = !this.user.password;
      
      return;
    }

    this.spinnerService.showSpinner("Logging in...");

    this.authService.login(this.user).subscribe(
      response => {
        this.spinnerService.hideSpinner();
        if (response.status === 'OK') {

          this.authService.saveTokens(response.data,this.rememberMeFlag);
          if(response.data?.accountType=='Staff'){
            this.authService.navigateStaff(response.data?.preferences,response.data?.roleName)
          }else{
            this.authService.navigateByRole(this.authService.getRoleName());
          }

          const profilePhotoObject = {
            url: "https://imgs.search.brave.com/Xdyth7rNTqdKToxvQBZ71Q6KTIqXj6waovokkppRYo0/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by8zZC1idXNpbmVz/cy1wZW9wbGVzLXBl/cnNvbi1pY29uXzc0/NTUyOC00NjEyMi5q/cGc"
          };
          
          // localStorage.setItem('profilePhoto', JSON.stringify(profilePhotoObject));
            let userDetails: UserDetails = {
            userId: response.data?.userId,
            userName: response.data?.userName,
            email: this.user.email,
            role: response.data?.role,
            profilePic: response.data?.profilePic ?? profilePhotoObject.url,
            verified: response.data?.userVerified,
            accountName: response.data?.accountType,
            accessToken: response.data?.accessToken,
            idToken: response.data?.idToken,
            refreshToken: response.data?.refreshToken,
            expiresIn: response.data?.expiresIn,
            preferences:response.data?.preferences,
            status : response.data?.status
            }
  
          this.localStorageService.updateUserDetails(userDetails);

        } else {
          this.showError = true;
          this.errorMessage = response.error.message;
        }
        
      },
      (error: any) => {
        this.spinnerService.hideSpinner();
        this.showError = true;
        this.errorMessage = error.error.message;
        console.error("Login error:", error); // Log the error for debugging purposes
      }
    );
  }


  


  onUserNameChange(): void {
    this.showUserNameError = false;
 
   
  }

  onPasswordChange(): void {
  
    this.showPasswordError = false;
 
  
  }

  clearError() {
    this.showError = false;
    this.errorMessage = '';
  }

}

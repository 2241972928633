<div class="mt-4">
    <div class="d-flex">
        <p class="title mt-1 mx-1">Awards & Honors</p>
        <img class="edit-profile mx-1" (click)="addAward()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.awards &&this.endCustomerData.moreInfos[0].awards.length>0) || showEditForm" alt="" src="../../assets/images/add.svg">
    <img class="edit-profile " (click)="editAward()"
        *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.awards &&this.endCustomerData.moreInfos[0].awards.length>0)" alt=""
        src="../../assets/images/editprofile.svg">
    </div>
    

    <div class="mt-2"
    *ngIf="!(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
this.endCustomerData.moreInfos[0]?.awards &&this.endCustomerData.moreInfos[0].awards.length>0) && !showEditForm">
    <button (click)="addAward()" class="profile-btn">
        <mat-icon>add</mat-icon><p class="ps-2">Add Awards & Honors</p> </button>
</div>
<div class="mt-2"
    *ngIf="(this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length>0&&
    this.endCustomerData.moreInfos[0]?.awards &&this.endCustomerData.moreInfos[0].awards.length>0)&& !showEditForm">
    <app-mwe-table [columns]="awardsColumns" [showPagination]="false" [showHeader]="false"
        [data]="data"></app-mwe-table>
</div>
<div class="mt-2" *ngIf="showEditForm">
    <form [formGroup]="awardsHonorsForm">
        <div formArrayName="moreInfos">
            <div *ngFor="let moreInfoForm of moreInfos.controls;let i=index;" [formGroupName]="i">
                <div formArrayName="awards">
                    <div *ngFor="let awardForm of awards(i).controls;let j=index;" [formGroupName]="j">
                        <div class="row">
                            <div class="card p-4 mt-2 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div>
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">Title
                                            </p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput formControlName="title">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div>
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">
                                                Association</p>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                                <input matInput formControlName="association">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div>
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">
                                                Award Date</p>
                                        </div>

                                        <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                                            <input matInput [matDatepicker]="awardDate" formControlName="awardDate">
                                            <mat-datepicker-toggle matIconSuffix [for]="awardDate"></mat-datepicker-toggle>
                                            <mat-datepicker #awardDate color="primary"></mat-datepicker>
                
                                        </mat-form-field>
                                    </div>
                
                                </div>
                
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div>
                                            <p class="med-world-basic-font med-world-basic-font__large med-world-basic-font__weight secondary_black_color m-0">
                                                Summary</p>
                                        </div>
                                        <div>
                                           
                                            <mat-form-field appearance="outline" class=" input-font w-100 b-r description">
                                                <textarea matInput formControlName="summary" rows="4" ></textarea>
                                            </mat-form-field>
                                        </div>
                
                                      
                                    </div>
                                </div>
                                <div class="row mt-2" >
                                    <div class="col-lg-3">
                                        <button class=" delete" (click)="deleteAward(i,j)" ><img alt="" src="./../../assets/images/Trash bin.svg">
                                            <span class="deltext">Delete</span>
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </form>
                        <div class="profile-action my-3" >
            
    
                            <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="update()">Update</button>
                        
                        
                            
                        
                        
                            <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="cancel()">Cancel</button>
                        
                        
                        
                        </div>
</div> 
    
</div>
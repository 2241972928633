import { Component, Input } from '@angular/core';
import { Category } from 'src/app/models/product-model';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {
  @Input() breadcrumb!: Category[];

  navigateToCategory(categoryId: string): void {
    // Implement your navigation logic here, e.g., route to '/categories/:id'
    console.log('Navigating to category:', categoryId + ' breadcrumb', this.breadcrumb);
    // Replace with your actual navigation logic based on the route configuration
  }

}

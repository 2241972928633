import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Enquiry } from 'src/app/models/messaging-models';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { SidebarServiceService } from 'src/app/services/sidebar-service.service';
@Component({
  selector: 'app-product-enquiry',
  templateUrl: './product-enquiry.component.html',
  styleUrls: ['./product-enquiry.component.scss']
})
export class ProductEnquiryComponent implements OnInit {
  private messageSubscription!: Subscription;
  userId: string = '';
  stompClient: any;
  messages: Enquiry[] = [];
  
  
  receiver1Id: any;
selectedProfile: any;
 // chats: any[] = [];

  constructor(
    private webSocketService: WebsocketService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private sidebarService: SidebarServiceService
  ) { }

  ngOnInit() {
    const roleName = localStorage.getItem('roleName') ?? '';
    this.userId = (roleName === 'USER' ? localStorage.getItem('userId') :
                   roleName === 'OEM' ? localStorage.getItem('oemId') :
                   roleName === 'DISTRIBUTOR' ? localStorage.getItem('distributorId') : '') ?? '';

                console.log('userId', this.userId, roleName );
                
    this.webSocketService.joinChat(this.userId);
   

  }
  
  enquiry: Enquiry = {
    subject: "HI",
    message: "SendIns",
    senderId: this.userId,
    receiver1Id: "e5634368-673b-44f6-821c-5bc9abbb266c",
    receiver2Id: "e5634368-673b-44f6-821c-5bd9abbb266b",
    attachments: [],
    distributorId: "d5634368-673b-44f6-821c-5bc9abbb266b",
  };

  loadMessages() {
    this.http.get<Enquiry[]>(`https://dev.medworlexpo.com/onboarding/enquiry/all?userId=${this.userId}`).subscribe(data => {
     
    this.messages = data; ;
    //this.chats = [...new Set(this.messages.map(item => item.receiver1Id))];
      console.log('Data received:', data);
      console.log('Data received:', this.messages);
    });
  }
  


}


<div *ngIf="!showViewProduct" class="d-flex justify-content-between align-items-center" style="background-color: white; height: 64px;">
    <div>
        <h2 class="mx-2 page-heading">Product Management</h2>
    </div>
    <div class="d-flex align-items-center">
        <mat-form-field appearance="outline" class="m-2 mr-1 search-bar ">
         <div class="d-flex searchbox ">
            <img  alt="" src="assets/images/search.svg" >
            <input matInput [formControl]="searchControl" placeholder="Search">
         </div>  
        </mat-form-field>
        <button class="header-btn m-2 mr-1 export-btn" mat-raised-button (click)="openImport()">
          <img src="assets/images/icons/Export.svg"  alt=""> Import
      </button>
      <button class="header-btn m-2 mr-1 export-btn" mat-raised-button (click)="openExportData()">
          <img src="assets/images/icons/Export.svg"  alt=""> Export
      </button>
        <button class="header-btn m-2 mr-1 filter-btn fbtn" mat-raised-button (click)="openFilter()">
            <img src="assets/images/filter.svg"  alt=""> <span class="filter ps-2">Filter</span>
        </button>
        <button class="header-btn m-2 add-btn" mat-raised-button (click)="openAddEditForm()">
            <img alt="" style="fill: white;"><mat-icon>add</mat-icon> Add
        </button>
    </div>
</div>

<div class="filter-bar" *ngIf="selectedFilters.length > 0">
  <div class="filters">
    <img src="assets/images/filter.svg" alt="Filter Icon"/>
    <span class="filters-text">Filters Applied:</span>
    <mat-chip-list>
      <mat-chip *ngFor="let filter of selectedFilters" (removed)="removeFilter(filter)">
        {{filter.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <button mat-raised-button class="clear-filters" (click)="clearFilters()">Clear Filters</button>
</div>

<app-mwe-table  *ngIf="!showViewProduct" [columns]="columns" [data]="filteredData" [title]="'All Products'" [showAddButton]="true" [showHeader]="false" (selectedAction)="selectedAction($event)" (pageChanged)="onPageChanged($event)" [currentPageChanged]="pageIndex" [isDynemicPagination]="true"></app-mwe-table>

<h2 *ngIf="showNoRecordsFounds && !showViewProduct" class="no-records">No records found</h2> 

<app-view-products *ngIf="showViewProduct" [selectedViewData]="selectedViewData" (backEvent)="close($event)" (closeToBack)="closeToBack($event)"></app-view-products>




  

<div class="notifications-container">
    <h2>All Notifications</h2>
    <div *ngIf="allNotifications.length > 0; else noNotifications">
      <div *ngFor="let notification of allNotifications" class="notification-item">
        <h3><b>{{ notification.status }}</b></h3>
        <p style="margin-bottom: 0px;">{{ notification.content}}</p>
        <div class="d-flex">
        <span>{{ notification.createdOn | date: 'short' }}</span>
        <mat-icon style="margin-left: 10px;">delete</mat-icon></div>
      </div>
    </div>
    <ng-template #noNotifications>
      <p>No notifications to display.</p>
    </ng-template>
  </div>
  
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MasterDataService } from 'src/app/services/master-data.service';
import { concatMap, count, takeWhile } from 'rxjs/operators';
import { GeographicalType } from 'src/app/enums/product-enum';
import { GeographicalChip } from 'src/app/models/product-model';
import { City, Continent, Country, State } from 'src/app/models/master-data.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  filterForm!: FormGroup;
  continents!: Continent[];
  countries!: Country[];
  states!: State[];
  cities!: City[];
  selectedContinents: Continent[] = [];
  selectedCountries: Country[] = [];
  selectedStates: State[] = [];
  selectedCities: City[] = [];

  chips: GeographicalChip[] = [];
  @Output() filterChangeEvent: EventEmitter<any> = new EventEmitter<any>(); // Add 'filter' property


  constructor(
    private formBuilder: FormBuilder,
    private masterDataService: MasterDataService
  ) { }

  ngOnInit() {
    this.initForm();
    this.loadMasterData();
    this.filterForm.valueChanges.subscribe(data => {
      this.addChipFromObject(data);
      
    });
  }

  initForm() {
    this.filterForm = this.formBuilder.group({
      continent: [''],
      country: [''],
      state: [''],
      city: [''],
      startDate: [''],
      endDate: ['']
    });
    this.emitFilterData();
  }

  createChipStructure(name: string, type: GeographicalType, priority: number, id: number): GeographicalChip {
    return {
      id: id,
      name: name,
      type: type,
      priority: priority
    };
  }

  loadContinents() {
    this.masterDataService.getContinents().subscribe(data => {
      this.continents = data.data;
    });
  }

  onContinentChange(continent: Continent) {
    if (!this.selectedContinents?.some(c => c.id === continent.id)) {
      this.selectedContinents?.push(continent);
    }
    const chip = this.createChipStructure(continent.continentName, GeographicalType.CONTINENT, 1, continent.id);

    this.addNewChip(chip);
    const continentNames = this.chips.filter(chip => chip.type === GeographicalType.CONTINENT).map(chip => chip.name);
    this.loadCountries(continentNames);
  }

  loadCountries(continentNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  onCountryChange(country: Country) {
    // Add the selected country if it's not already in the list

    if (!this.selectedCountries?.some(c => c.id === country.id)) {
      this.selectedCountries?.push(country);
    }
    this.removeContinentForCountry(country);
    // Add a new chip for the selected country
    this.addNewChip(this.createChipStructure(country.countryName, GeographicalType.COUNTRY, 2, country.id));
    const countryNames = this.chips.filter(chip => chip.type === GeographicalType.COUNTRY).map(chip => chip.name);
  
    // Load states for the selected countries
    this.loadStates(countryNames);
  }
  
  removeContinentForCountry(country: Country) {
    const continentCode = country.continentCode;
    // Find and remove the continent corresponding to the country
    this.selectedContinents = this.selectedContinents?.filter(continent => continent.continentCode !== continentCode);
  }

  removeCountryForState(state: State) {
    const countryName = state.countryName;
    
    const country = this.selectedCountries?.find(c => c.countryName === countryName);
    if (country) {
      this.removeContinentForCountry(country);
      // Find and remove the country corresponding to the state
      this.selectedCountries = this.selectedCountries?.filter(country => country.countryName !== countryName);
    }
  }

  removeStateForCity(city: City) {
    const stateName = city.stateName;
    
    const state = this.selectedStates?.find(c => c.stateName === stateName);
    if (state) {
      this.removeCountryForState(state);
      // Find and remove the state corresponding to the city
      this.selectedStates = this.selectedStates?.filter(state => state.stateName !== stateName);
    }
  }


  loadStates(countryNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', countryNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', countryNames);
        })
      )
      .subscribe(data => {
        allStates = allStates.concat(data.data);
        this.states = allStates;
      });
  }

  onStateChange(state: State) {
    if (!this.selectedStates?.some(c => c.id === state.id)) {
      this.selectedStates?.push(state);
    }
    this.removeCountryForState(state);
    this.addNewChip(this.createChipStructure(state.stateName, GeographicalType.STATE, 3, state.id));
    const stateNames = this.chips.filter(chip => chip.type === GeographicalType.STATE).map(chip => chip.name);
    this.loadCities(stateNames);
  }

  onCityChange(city: City) {
    if (!this.selectedCities?.some(c => c.id === city.id)) {
      this.selectedCities?.push(city);
    }
    this.removeStateForCity(city);
    this.addNewChip(this.createChipStructure(city.cityName, GeographicalType.CITY, 4, city.id));
  }
  
  removeOldChips(type: GeographicalType) {
    this.chips = this.chips.filter(chip => chip.type !== type);
  }

  loadCities(stateNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCities: any[] = [];
    let morePages = true;
    this.masterDataService.getCities(pageNumber, pageSize, '', stateNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', stateNames);
        })
      )
      .subscribe(data => {
        allCities = allCities.concat(data.data);
        this.cities = allCities;
      });
  }

  addNewChip(newChip: GeographicalChip) {
    // Check if the chip with the same name already exists in the array
    const chipExists = this.chips.some(chip => chip.name === newChip.name);

    // If the chip does not exist, push it to the array
    if (!chipExists && typeof(newChip.name) === 'string' && newChip.name.trim() !== '') {
      this.chips.push(newChip);
      this.emitFilterData();
    }
  }


  remove(chip: GeographicalChip) {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      switch (chip.type) {
        case GeographicalType.CONTINENT:
          this.selectedContinents = this.selectedContinents.filter(c => c.continentName !== chip.name);
          this.filterForm.patchValue({ continent: this.selectedContinents[this.selectedContinents.length - 1] || '' });
          this.loadCountries(this.selectedContinents.map(c => c.continentName));
          this.loadStates(this.selectedCountries.map(c => c.countryName));
          this.loadCities(this.selectedStates.map(c => c.stateName));
          break;
        case GeographicalType.COUNTRY:
          const country = this.countries.find(c => c.id === chip.id);
          if (country) {
            const continent = this.continents.find(c => c.continentCode === country.continentCode);
            const continentAvailableInFilter = this.chips.find(c => c.type === GeographicalType.CONTINENT && c.name === continent?.continentName);
            if (continent && continentAvailableInFilter) {
              this.selectedContinents.push(continent);
            }
          }
          this.selectedCountries = this.selectedCountries.filter(c => c.countryName !== chip.name);
          this.filterForm.patchValue({ country: this.selectedCountries[this.selectedCountries.length - 1] || '' });
          this.loadStates(this.selectedCountries.map(c => c.countryName));
          this.loadCities(this.selectedStates.map(c => c.stateName));
          break;
        case GeographicalType.STATE:
          const state = this.states.find(c => c.id === chip.id);
          if (state) {
            const countryForSelectedState = this.countries.find(c => c.countryName === state.countryName);
            const countryAvailableInFilter = this.chips.find(c => c.type === GeographicalType.COUNTRY && c.name === countryForSelectedState?.countryName);
            if (countryForSelectedState && countryAvailableInFilter) {
              this.selectedCountries.push(countryForSelectedState);
            }
          }
          this.selectedStates = this.selectedStates.filter(c => c.stateName !== chip.name);
          this.filterForm.patchValue({ state: this.selectedStates[this.selectedStates.length - 1] || '' });
          this.loadCities(this.selectedStates.map(c => c.stateName));
          break;
        case GeographicalType.CITY:
          const city = this.cities.find(c => c.id === chip.id);
          if (city) {
            const stateForSelectedCity = this.states.find(c => c.stateName === city.stateName);
            const stateAvailableInFilter = this.chips.find(c => c.type === GeographicalType.STATE && c.name === stateForSelectedCity?.stateName);
            if (stateForSelectedCity && stateAvailableInFilter) {
              this.selectedStates.push(stateForSelectedCity);
            }
          }
          this.selectedCities = this.selectedCities.filter(c => c.cityName !== chip.name);
          this.filterForm.patchValue({ city: this.selectedCities[this.selectedCities.length - 1] || '' });
          break;
        default:
          break;
      }
      this.emitFilterData();
    }
  }


  private loadMasterData(){
    this.loadContinents();
    this.loadCountries(['']);
    this.loadStates(['']);
    this.loadCities(['']);
  }

  public emitFilterData(){
    
    const payload = {
      continents: this.selectedContinents?.map(continent => continent.continentName),
      countries: this.selectedCountries?.map(country => country.countryName),
      states: this.selectedStates?.map(state => state.stateName),
      cities: this.selectedCities?.map(city => city.cityName),
      startDate: this.filterForm.value.startDate,
      endDate: this.filterForm.value.endDate
    };
    this.filterChangeEvent.emit(payload);
  }

  public clearAllFilters() {
    this.chips = [];
    this.selectedContinents = [];
    this.selectedCountries = [];
    this.selectedStates = [];
    this.selectedCities = [];
    this.filterForm.reset();
    this.loadMasterData();
    this.emitFilterData();
  }

  addChipFromObject(data: any) {
    if(data.startDate || data.endDate) {
      this.emitFilterData();
    }
  }
  
  
  resetDateRange() {
    this.filterForm.get('startDate')?.reset();
    this.filterForm.get('endDate')?.reset();
  }
  hasDateRange(): boolean {
    return this.filterForm.get('startDate')?.value || this.filterForm.get('endDate')?.value;
  }
}

<div class="company-info">
    <div class="info">

        <h4 class="info-header">Information</h4>
        <div class="dynamic-content">
            <p><span>Name</span> <span class="dynamic-data">{{oemData?.companyInformation?.userName}}</span></p>
            <p><span>Company Logo</span> <img [src]="oemData?.companyInformation?.logo" alt="logo" height="25px" width="75px"></p>
            <p><span>Company Name</span> <span class="dynamic-data">{{oemData?.companyInformation?.name}}</span></p>
            <p><span>Established in</span> <span class="dynamic-data">{{oemData?.companyInformation?.year}}</span></p>
            <p><span>Website URL</span> <span class="dynamic-data"><a href="{{oemData?.companyInformation?.url}}" target="_blank" class="file-name">{{oemData?.companyInformation?.url}}</a> </span></p>
            <p><span>No.of Employees</span> <span class="dynamic-data">{{oemData?.companyInformation?.employeeCount}}</span></p>
            <p><span>Continent</span> <span class="dynamic-data">{{oemData?.companyInformation?.continent}}</span></p>
            <p><span>Country</span> <span class="dynamic-data">{{oemData?.companyInformation?.country}}</span></p>
            <p><span>State</span> <span class="dynamic-data">{{oemData?.companyInformation?.state}}</span></p>
            <p><span>City</span> <span class="dynamic-data">{{oemData?.companyInformation?.city}}</span></p>
            <p><span>Address</span> <span class="dynamic-data">{{oemData?.companyInformation?.address}}</span></p>
            <p><span>Zip Code</span> <span class="dynamic-data">{{oemData?.companyInformation?.zipCode}}</span></p>
        </div>
        
    </div>
    <div class="other-info">
        <div class="certificates">
            <h4 class="info-header">Certificates</h4>
            <div class="dynamic-content">
                <p><span>CR Copy</span> <span class="dynamic-data file-name"><a href="{{oemData?.companyInformation?.crcopy}}" target="_blank" class="dynamic-data file-name">
                    {{getFileNameFromUrl(oemData?.companyInformation?.crcopy)}}
                  </a></span></p>
                <p><span>Business License</span> <span class="dynamic-data file-name"> <a href="{{oemData?.companyInformation?.businessLicense}}" target="_blank" class="dynamic-data file-name"> {{getFileNameFromUrl(oemData?.companyInformation?.businessLicense)}} </a></span></p>
                <p><span>European Certificate</span> <span class="dynamic-data file-name"><a href="{{oemData?.companyInformation?.europeanCertificate}}" target="_blank" class="dynamic-data file-name">{{getFileNameFromUrl(oemData?.companyInformation?.europeanCertificate)}}</a></span></p>
                <p><span>Other Certificates (If Any)</span> <span *ngFor="let url of oemData?.companyInformation?.otherFiles" class="dynamic-data file-name"><a href="{{url}}" target="_blank" class="dynamic-data file-name">{{getFileNameFromUrl(url)}}</a></span></p>
    
            </div>
        </div>
        <div class="subscription">
            <h4 class="info-header">Subscription</h4>
            <img [src]="getPlanImage(oemData?.companyInformation?.subscriptionInfoId ?? '')" alt="Plan" class="plan-image"> {{subscriptionName}}
        </div>
        <div class="status">
            <h4 class="info-header">Status</h4>
            <p>{{oemData?.companyInformation?.status}}</p> 
        </div>
    </div>
</div>

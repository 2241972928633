
<div class="container p-4"> 
    <div class="row">
<h4 class="head">Assign More OEMs into your network</h4>
    </div>
    <form [formGroup]="assignMoreOEMsForm">
    <div class="row">
        <div class="col-lg-3" >
            <div >
                <mat-label class="label">Country</mat-label>
            </div>           
                <div>
                    <mat-form-field appearance="outline" class="fields input-font w-100">

                        <mat-chip-grid #chipGridOEM >
                            <mat-chip class="oem-chip" *ngIf="selectedCountry !==''"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeOEM()">
                                {{selectedCountry}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip>
                            <input color="primary" *ngIf="!selectedCountry"
                            [class.hidden]="isEditMode || selectedCountry !== ''"
                                placeholder="Select"
                                class="custom-select-input"
                                [formControl]="country"
                                [matChipInputFor]="chipGridOEM"
                                [matAutocomplete]="auto"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (keydown)="onKeyDown($event)"
                                (click)="loadOEM()"
                                (matChipInputTokenEnd)="addOEM($event)"/>
                        </mat-chip-grid>
                        <!-- <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="country" [matAutocomplete]="auto" (keydown)="onKeyDown($event)"> -->
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCountrySelected($event)">
                          <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName" >
                            {{ option?.countryName }}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
        </div>
        <div class="col-lg-3" >
            <div >
                <mat-label class="label">State</mat-label>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100">
                    <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="state" [matAutocomplete]="stateAuto">
                    <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelected($event)" >
                      <mat-option *ngFor="let option of stateDropdown" [value]="option?.stateName">
                        {{ option?.stateName }}
                      </mat-option>
                    </mat-autocomplete>
                    
                </mat-form-field>
            </div>
        </div>
        <div class="col-lg-3 mb-4" >
            <div >
                <mat-label class="label">City</mat-label>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100">
                    <input type="text" placeholder="Select" aria-label="Number" matInput [formControl]="city" [matAutocomplete]="cityAuto">
                    <mat-autocomplete #cityAuto="matAutocomplete"  (optionSelected)="onCitySelected($event.option.value)">
                      <mat-option *ngFor="let option of cityDropdown" [value]="option?.cityName">
                        {{ option?.cityName }}
                      </mat-option>
                    </mat-autocomplete>
                    
                </mat-form-field>
            </div>
        </div>
       
    </div>
    </form>
    <div class="table-content">

        <app-mwe-table [columns]="columns" [showHeader]="false" [data]="OEMsNetwork" [preSelectedList]="selectedIds" (selectedAction)="selectedAction($event)" [isDynamicPagination]="true" (pageChanged)="onPageChanged($event)" [currentPageChanged]="pageIndex"></app-mwe-table>
    </div>
    <div class="popup-action mt-4">
        <button  class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3" (click)="addOEMs()">Add</button>
        
        <!-- <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="clearFilter()">Clear</button>
        <button  class="mwe-button mwe-button--secondary ps-3 pe-3 "  (click)="closeDialog()">Cancel</button> -->
    </div>
</div>

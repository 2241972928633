import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  email:string="";
  emailSent: boolean = false;
  showError = false;
  errorMessage = '';
  
  constructor(private authService: AuthService,public router: Router) {
  
  }
  
  onSubmit(){
    if(!this.email){
      this.showError = true;
      this.errorMessage = 'Enter your email address to receive a passsword reset link';
      return;
    }
   
    this.authService.forgotPassword(this.email).subscribe(response => {
      if (response.status === 'OK') {
        this.emailSent = true;
        console.log(response);
      }
     
    },
    (error: any) => {
      this.showError = true;
      this.errorMessage = error.error.message;
      console.error("Email sent error:",  error.error.message); // Log the error for debugging purposes
    });
  
  }

  onUsernameChange(){
    this.showError = false;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-end-customers',
  templateUrl: './end-customers.component.html',
  styleUrls: ['./end-customers.component.css']
})
export class EndCustomersComponent {

}

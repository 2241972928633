import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-reject-distributor-remarks-popup',
  templateUrl: './reject-distributor-remarks-popup.component.html',
  styleUrls: ['./reject-distributor-remarks-popup.component.scss']
})
export class RejectDistributorRemarksPopupComponent {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '220px',
    minHeight: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: false,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  remarkForm!: FormGroup;

  constructor(private dialogRef: MatDialogRef<RejectDistributorRemarksPopupComponent>, private formBuilder: FormBuilder ) { }

  ngOnInit() {
    this.remarkForm = this.formBuilder.group({
      remarks: [''],
    })
  }

  // addRemarks() : void{
  //   console.log("remarks added!!")
  //   this.isReject = this.data?.isReject;
  // }

  /**
   * Close the dialog with a value of 'rejected!!'. This is used to let the parent component know that the request was rejected.
   */
  rejectRequest() {
    const remarks = this.remarkForm.get('remarks')?.value
    console.log("Request rejected!!")
    this.dialogRef.close(remarks);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListColumn } from 'src/app/models/list-column.model';
import { HttpClient } from '@angular/common/http';
import { WebinarService } from 'src/app/services/shared/webinar.service';

@Component({
  selector: 'app-view-webinar',
  templateUrl: './view-webinar.component.html',
  styleUrls: ['./view-webinar.component.scss']
})
export class ViewWebinarComponent implements OnInit { 

  @Input() viewWebinar?: boolean;
  @Input() viewWebinarID? : any;

  @Output() backEvent = new EventEmitter<any>();
  @Output() closeToBack = new EventEmitter<any>();

  constructor(private http: HttpClient, private webinarService: WebinarService ){}

  webinarData: any;
  specialitiesData: any;
  formatwebinarDate: any;
  formatwebinarTime: any;
  formatwebinarDuration: any;
  regiteredCustomersData: any;

  date: any;

  ngOnInit(): void {
     this.getWebinarByID(this.viewWebinarID);
  }

    columns: ListColumn[] = [
      { name: 'S.No', property: 'srNo', visible: true, isModelProperty: false },
      { name: 'Customer Name', property: 'customerName', visible: true, isModelProperty: true, isText: true },
      { name: 'Email', property: 'email', visible: true, isModelProperty: true, isText: true },
      { name: 'Phone', property: 'phoneNumber', visible: true, isModelProperty: true, isText: true },
    ]

    timeToWebinar: any[] = [];

    timeLeft(webinarDate: string, webinarTime: any): void {
      const now = new Date();
      const webinarDateTime = new Date(`${webinarDate} ${webinarTime}`);
      const timeDifference = webinarDateTime.getTime() - now.getTime();
  
      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        const timeLeft = `${days} days ${hours} hours ${minutes} minutes and ${seconds} seconds`;
        this.timeToWebinar = [days, hours, minutes, seconds];
        setInterval(() => {
          this.timeLeft(this.formatwebinarDate, this.formatwebinarTime);
        }, 1000);
      } else {
      }
    }

  
    // Reformats webinar duration
    reformatDuration(duration: any): string {
      const hours = duration[0] < 10 ? '0' + duration[0] : duration[0];
      const minutes = duration[1] < 10 ? '0' + duration[1] : duration[1];
      let seconds = '00';
      if (duration.length > 2) {
        seconds = duration[2] < 10 ? '0' + duration[2] : duration[2];
      }
      return `${hours}:${minutes}:${seconds}`;
    }
  
    // Reformats webinar time into 12-hour AM/PM format
    reformatTime(time: any): string {
      let hours = time[0];
      const minutes = time[1] < 10 ? '0' + time[1] : time[1];
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      return `${hours}:${minutes} ${ampm}`;
    }

      getWebinarByID(id: string){
        this.webinarService.getWebinarById(id).subscribe((res: any)=>{
          this.webinarData = res.data.webinar;
          this.regiteredCustomersData = res.data.regiteredCustomers;
          this.formatwebinarDate = this.webinarData.webinarDate[0] + '/' + this.webinarData.webinarDate[1] + '/' + this.webinarData.webinarDate[2]
          this.formatwebinarTime = this.reformatTime(this.webinarData.webinarTime)
          this.timeLeft(this.formatwebinarDate, this.formatwebinarTime);
          this.formatwebinarDuration = this.reformatDuration(this.webinarData.duration)
        })
      }

      getSpecialities(){
        this.webinarService.speciality().subscribe((res: any)=>{
          const foundSpeciality = res.data.find((x: any) => x.id === this.webinarData.webinarSpecialityId);
          if (foundSpeciality) {
            this.specialitiesData = foundSpeciality.name;
          }
        })
      }

      getAllWebinars(){
        this.webinarService.getAllWebinars().subscribe((res: any)=>{
        })
      }
    
      back(): void {
        this.closeToBack.emit(true);
        this.backEvent.emit({ actionType: '' });
        this.viewWebinar = false;
      }
}
<mat-sidenav-container class="sidenav-container">




  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
   >
    <mat-toolbar><img routerLink="/" src="assets/images/Layer_1.png" alt="logo" class="mwe-logo"></mat-toolbar>
    <mat-nav-list [class.disabled]="isPopUpVisible">
      <ng-container *ngIf="roleName === 'SUPER_ADMIN' && accountName !=='Staff'">
        <ng-container *ngFor="let link of navLinks['super-admin']">
          <a   mat-list-item   [routerLink]="['/' + link.route]" [ngClass]="{'active': isActive(link.route)}"
            (click)="setHeaderLabel(link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">
            <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
              <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
              {{ link.label }}
              <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
                {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </mat-icon>
            </span>
          </a>
      
          <ng-container *ngIf="link.children && isExpanded(link.label)">
            <ng-container *ngFor="let child of link.children">
              <a mat-list-item    [routerLink]="['/' + (child.route || '')]" [ngClass]="{'active': isActive(child.route || '')}"
                (click)="setHeaderLabel(child.label || '')" class="sidenav-nav-item sub-menu">
                <span class="sidenav-nav-item-span sub-menu" style="display: flex; align-items: center;">
                  <img src="{{child.icon}}" class="svg-color" [ngClass]="{'active': isActive(child.route)}" alt="icon" style="margin-right: 8px; margin-left: 8px;">
                  {{ child.label || '' }}
                </span>
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="roleName === 'DISTRIBUTOR'&& accountName !=='Staff'">
        <ng-container *ngFor="let link of navLinks['distributor']">

          <a *ngIf="!isPopUpVisible"   mat-list-item   [routerLink]="['/' + link.route]" [ngClass]="{'active': isActive(link.route)}"
          (click)="setHeaderLabel(link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">
          <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
            <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
            {{ link.label }}
            <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
              {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </span>
        </a>

        <a *ngIf="isPopUpVisible"  mat-list-item [routerLink]="['/' + link.route]"  [ngClass]="{'active': isActive(link.route)}"
          (click)="blockDashboard( 'distributor' , link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">
          <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
            <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
            {{ link.label }}
            <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
              {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </span>
        </a>
          <!-- <a  mat-list-item  class="sidenav-nav-item"    [ngClass]="{'active': isActive(link.route)}"  [routerLink]="['/' + link.route]" (click)="setHeaderLabel(link.label)"><span class="sidenav-nav-item-span"><img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" >{{ link.label }}</span></a> -->
          <ng-container *ngIf="link.children && isExpanded(link.label) && !isPopUpVisible">
            <ng-container *ngFor="let child of link.children">
              <a  mat-list-item   [routerLink]="['/' + (child.route || '')]" [ngClass]="{'active': isActive(child.route || '')}"
                (click)="setHeaderLabel(child.label || '')" class="sidenav-nav-item sub-menu">
                <span class="sidenav-nav-item-span  sub-menu" style="display: flex; align-items: center;">
                  <img src="{{child.icon}}" class="svg-color" [ngClass]="{'active': isActive(child.route)}" alt="icon" style="margin-right: 8px; margin-left: 5px;">
                  {{ child.label || '' }}
                </span>
              </a>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="roleName === 'OEM'&& accountName !=='Staff'">
        <ng-container *ngFor="let link of navLinks['oem']">

          <a *ngIf="!isPopUpVisible"  mat-list-item [routerLink]="['/' + link.route]" [ngClass]="{'active': isActive(link.route)}"
          (click)="setHeaderLabel(link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">

          <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
            <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
            {{ link.label }}
            <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
              {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </span>
        </a>

        <a *ngIf="isPopUpVisible"  mat-list-item [routerLink]="['/' + link.route]" [ngClass]="{'active': isActive(link.route)}"
          (click)="blockDashboard('oem', link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">

          <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
            <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
            {{ link.label }}
            <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
              {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </span>
        </a>
    
        <ng-container *ngIf="link.children && isExpanded(link.label) && !isPopUpVisible">
          <ng-container *ngFor="let child of link.children">
            <a  mat-list-item   [routerLink]="['/' + (child.route || '')]" [ngClass]="{'active': isActive(child.route || '')}"
              (click)="setHeaderLabel(child.label || '')" class="sidenav-nav-item sub-menu">
              <span class="sidenav-nav-item-span  sub-menu" style="display: flex; align-items: center;">
                <img src="{{child.icon}}" class="svg-color" [ngClass]="{'active': isActive(child.route)}" alt="icon" style="margin-right: 8px; margin-left: 5px;">
                {{ child.label || '' }}
              </span>
            </a>
          </ng-container>
        </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf=" accountName ==='Staff'">
        <ng-container *ngFor="let link of filteredMenu">
          <a   mat-list-item   [routerLink]="['/' + link.route]" [ngClass]="{'active': isActive(link.route)}"
          (click)="setHeaderLabel(link.label); toggleCategoryLevels(link.label)" class="sidenav-nav-item">
          <span class="sidenav-nav-item-span" style="display: flex; align-items: center;">
            <img src="{{link.icon}}" class="svg-color" [ngClass]="{'active': isActive(link.route)}" alt="icon" style="margin-right: 8px;">
            {{ link.label }}
            <mat-icon *ngIf="link.children" class="sidenav-nav-item-icon" style="margin-left: auto;">
              {{ isExpanded(link.label) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </mat-icon>
          </span>
        </a>
    
        <ng-container *ngIf="link.children && isExpanded(link.label)">
          <ng-container *ngFor="let child of link.children">
            <a  mat-list-item   [routerLink]="['/' + (child.route || '')]" [ngClass]="{'active': isActive(child.route || '')}"
              (click)="setHeaderLabel(child.label || '')" class="sidenav-nav-item sub-menu">
              <span class="sidenav-nav-item-span sub-menu" style="display: flex; align-items: center;">
                <img src="{{child.icon}}" class="svg-color" [ngClass]="{'active': isActive(child.route)}" alt="icon" style="margin-right: 8px; margin-left: 5px;">
                {{ child.label || '' }}
              </span>
            </a>
          </ng-container>
        </ng-container>
        </ng-container>
      </ng-container>
      <!-- Add more ng-container blocks for other roles -->
    </mat-nav-list>

  </mat-sidenav>
 
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="toolbar">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="header-container">
        <span class="component-heading">{{ headerLabel }}</span>
        <span class="breadcrumb-container">
          <nav *ngIf="breadcrumbs.length > 1">
            <ol class="breadcrumb">
              <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
                <li class="breadcrumb-item" [class.breadCrumb-first-item]="i === 0" [class.breadCrumb-second-item]="i !== 0">{{ breadcrumb }}</li>
              </ng-container>
            </ol>
          </nav>
        </span>
      </div>
      
      <div class="right-icons">
        <!-- <button mat-icon-button> -->
          <!-- <img src="assets/images/icons/settings-outlined.svg" alt=""> -->
        
       <!-- Notification Button -->
        <div class="notification-container">
          <button mat-icon-button (click)="toggleNotifications()" style="margin-top: -10px;">
            <img src="assets/images/icons/notification-with-badge.svg" alt="icon">
          </button>

          
          <div *ngIf="showNotificationDropdown" class="notification-dropdown">
            <h2>Notifications</h2>
            <div *ngIf="noData" class="notification-items">No Notifications</div>
            <div *ngFor="let notification of limitedNotifications" class="notification-item">
              <strong>{{ notification.status }}</strong>
              <p>{{ notification.content }}</p>
            </div>
            <button mat-button (click)="openAllNotifications()">Show All Notifications</button>
          </div>
        </div>

        <!-- <button mat-icon-button> -->
        <img class="header-user-profile-pic"
          [src]="profilePhoto"
          alt="">
        <!-- </button> -->
        <div class="container">
          <div class="row">
            <span class="user-header-display-name">{{userName}}</span>
          </div>
          <div class="row">
            <span class="user-header-display-role">{{roleName}}</span>
          </div>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <img src="assets/images/icons/caret-down.svg" alt="">
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="getProfileRouteBasedOnRole(roleName)" (click)="setHeaderLabel('My Profile')">
            <img src="assets/images/icons/user-circle.svg" alt="">
            Profile
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="signOut()">
            <img src="assets/images/icons/sign-out.svg" alt="">
            Logout
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>
    <div class="dashboard">
      <router-outlet></router-outlet>
    </div>
    
  </mat-sidenav-content>
</mat-sidenav-container>


import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, concatMap } from 'rxjs';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { CareerService } from 'src/app/services/career.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  jobPost: any;
  applicantId: any;
  countries!: any[];
  salaryHide: boolean = false;
  companyDetailsHide: boolean = false;
  
  constructor(private readonly careerService: CareerService, private route: ActivatedRoute, private readonly router: Router,
    private readonly masterDataService: MasterDataService, private readonly endCustomerService: EndCustomerService,
    private readonly localStorageService: LocalStorageService,
    private snackbarService: SnackbarService) { }


  ngOnInit(): void {
    this.getJobPostById();
    this.loadCountries('');
   
    this.localStorageService.getUserDetails().subscribe(data => {
      this.getEndCustomerByUserId(data.userId);
    });
    
  }

  private getJobPostById() {
    this.jobPost = {};
    this.careerService.executeGetRequestURL(MweConstants.getJobPostUrl.concat('/', this.careerService.getJobId()), null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          const jobPostResponse = response?.data;
          this.companyDetailsHide = jobPostResponse.postAnonymously;
          this.salaryHide = jobPostResponse.hideSalary;
          jobPostResponse.skills = this.convertStringToArray(jobPostResponse.skills);
          jobPostResponse.tags = this.convertStringToArray(jobPostResponse.tags)
          this.jobPost = jobPostResponse;
        }
      }, error: (error: any) => {
      }
    });
  }

  private convertStringToArray(field: any) {
    return (field !== undefined && field !== null && field.length > 0) ? field.split(",") : [];
  }

  loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  onBack() {
    this.router.navigate(['../view-all-jobs'], { relativeTo: this.route });
  }

  onApplyJob(jobPostId: any) {
    this.careerService.setJobId(jobPostId);
    this.router.navigate(['../job-apply'], { relativeTo: this.route });
  }

  getCurrencySymbol(currency: string | undefined): string {
    if (!currency || !this.countries) {
      return ''; 
    }
  
    const country = this.countries.find(country => country.currency === currency);
  
    if (country && country.currencySymbol) {
      return country.currencySymbol;
    }
  
    return ''; 
  }
  
  getEndCustomerByUserId(userId: any) {
    this.endCustomerService.executeGetRequestURL(MweConstants.getCustomerByUserId + userId, null).subscribe({
      next: (response: any) => {
        if (response && response?.data) {
          this.applicantId = response?.data.id;
        }
      }, error: (error: any) => {
      }
    });
  }

  onSaveJob(jobPost: any) {
    const body = { jobApplicantKey: { jobPostId: jobPost.id, applicantId: this.applicantId } };
    this.careerService.executePostRequest(MweConstants.getJobPostUrl.concat("/applicant/jobs"), body).subscribe({
      next: () => {
        Swal.fire('', 'Job has been saved successfully !!', 'success');
      }, error: (error: any) => {
        this.snackbarService.openSnackBar(error.error.message.includes("Duplicate Record") ? 'Job has already been saved' : 'There is some unknown issue. Please try again later', '');
      }
    });
  }

  ngOnDestroy(): void {
    this.careerService.setJobId(null);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OemService } from 'src/app/services/oem/oem.service';

@Component({
  selector: 'app-connected-brands',
  templateUrl: './connected-brands.component.html',
  styleUrls: ['./connected-brands.component.scss']
})
export class ConnectedBrandsComponent {
  @Output() viewOEMProfileEvent = new EventEmitter<any>();
  @Input() data: any;
  showNoRecordsFounds: boolean = false;
  hovered: boolean = false;
  hoveredIndex: any;
  itemsPerPage = 9;
  brands: any[] = [];
  originalData: any[] = [];
  paginatedItems: { name: string, logo: string }[] = [];
  
    ngOnInit(): void {
      this.getBrandsByDistributorId();
    }
  
    constructor(
      private oemService :OemService,
      private router: Router, 
      private readonly route: ActivatedRoute,
    ) { }

  
    onPageChanged(paginatedData: any[]): void {
      this.paginatedItems = paginatedData;
    }
  
    getBrandsByDistributorId() {
      this.oemService.getOEMsByDistributorId(this.data.id).subscribe({
        next: (response) => {
          this.brands = response.data; 
          console.log(this.brands);
          
          this.originalData = this.brands;
          this.paginatedItems = this.brands.slice(0, this.itemsPerPage);
        },
        error: (error) => {
          console.error('Error fetching brand list data:', error);
        }
      });
    }
  
    filteredData = this.brands;
  
    onFilteredData(filtered: any[]): void {
      this.filteredData = filtered;
      this.brands = filtered;
      this.paginatedItems = this.filteredData.slice(0, this.itemsPerPage);
      if(this.paginatedItems.length == 0){ 
        this.showNoRecordsFounds = true
      } else {
        this.showNoRecordsFounds = false
      }
      console.log('Received filtered data:', filtered);
    }

    viewPortfolio(brand : any): void {
      console.log('brand', brand);
      
      const dataToSend = {
        id: brand.oemId,
        name: brand.oemName,
        logo : brand.logo,
        url : brand.url,
      };
      this.viewOEMProfileEvent.emit(dataToSend);
    }
}

<div class="remarks-list mt-2">
  <div *ngFor="let remark of remarks; let i = index" class="remark-item">
    <div class="remark-header">
      <img src="../../assets/images/Ellipse 2 (1).svg" alt="Remark Icon" class="remark-icon" />
      <p class="remark-person-name">{{ remark.personName }}</p>
      <p class="remark-date">{{ remark.createdOn | date: 'MM/dd/yyyy' }}</p>

      <!-- Edit and Delete icons -->
      <button *ngIf="!isEditing && currentEditingIndex !== i" (click)="editRemark(i)" style="border:none; background: none; margin-top:-8px;">
        <img alt="" src="../../assets/images/editprofile.svg" class="edit-profile">
      </button>

      <button *ngIf="isEditing && currentEditingIndex === i" (click)="deleteRemark(i)" style="border:none; background: none;">
        <mat-icon style="color: red; position: relative;">delete</mat-icon>
      </button>
    </div>

    <!-- Render remark text or editor based on editing mode -->
    <div class="remark-text">
      <p *ngIf="!isEditing" [innerHTML]="remark.remarksText"></p>
      <div *ngIf="isEditing">
        <angular-editor [config]="editorConfig" [(ngModel)]="remark.remarksText"></angular-editor>
      </div>
    </div>
  </div>
</div>

<!-- Show form only if isAdding is true or editing an existing remark -->
<div *ngIf="isAdding || currentEditingIndex !== null">
  <form [formGroup]="remarkForm">
    <div class="row">
      <div class="col-lg-6">
        <div>
          <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Type your comment here</p>
        </div>
        <div>
          <angular-editor [config]="editorConfig" formControlName="remarksText"></angular-editor>
        </div>
      </div>
    </div>
    <div class="row mb-2 mt-3">
      <div class="col-lg-6">
        <div class="d-flex align-items-center gap-2">
          <button class="d-flex more" type="button" (click)="addEditedRemark()">
            <span class="deltext">Add</span>
          </button>
          <button class="d-flex more" type="button" (click)="cancelConfig()">
            <span class="deltext">Cancel</span>
          </button>
          <!-- Show Delete button if editing a remark -->
          <button *ngIf="currentEditingIndex !== null" class="d-flex more" type="button" (click)="deleteRemark(currentEditingIndex)">
            <mat-icon style="color: red; position: relative;">delete</mat-icon>
           
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Show "Add Remark" button when the form is hidden -->
<div *ngIf="!isAdding && currentEditingIndex === null">
  <button class="d-flex more mb-3" type="button" (click)="add()">
    <mat-icon>add</mat-icon>
    <span class="deltext">Add Remarks</span>
  </button>
</div>

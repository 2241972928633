import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponseModel } from '../models/shared-model'; ``
import { Currency } from '../models/master-data.model';
import { apiUrls, OEMUrls } from '../utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  private apiUrl = apiUrls.masterdata;

  constructor(private http: HttpClient) { }

  getContinents(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/continents?pageNumber=0&pageSize=20`);
  }

  getCountries(pageNumber?: number, pageSize?: number, startsWith?: string, continentNames?: string[]): Observable<any> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '10')
      .set('startsWith', startsWith || '');

    if (continentNames && continentNames.length > 0) {
      params = params.set('continentName', continentNames.join(','));
    }
    console.log("in service",params);

    return this.http.get<any>(`${this.apiUrl}/countries`, { params });
    
  }

  getCountriesByStartsWith(startsWith: string): Observable<any> {
    let params = new HttpParams()
      .set('startsWith', startsWith)
      .set('pageNumber', '0')
      .set('pageSize', '10');

    return this.http.get<any>(`${this.apiUrl}/countries`, { params });
  }

  getTimezones(): Observable<any>{
    return this.http.get<any>(`${this.apiUrl}/timezones`);
  }
  
  getStates(pageNumber?: number, pageSize?: number, startsWith?: string, countryNames?: string[]): Observable<any> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');
  
    if (countryNames && countryNames.length > 0) {
      countryNames.forEach(name => {
        params = params.append('countryName', name);
      });
    }
  
    return this.http.get<any>(`${this.apiUrl}/states/v1`, { params });
  }

  getStatesByStartsWith(startsWith: string): Observable<any> {
    let params = new HttpParams()
      .set('startsWith', startsWith)
      .set('pageNumber', '0')
      .set('pageSize', '10');

    return this.http.get<any>(`${this.apiUrl}/states/v1`, { params });
  }
  
  

  getCities(pageNumber?: number, pageSize?: number, startsWith?: string, stateNames?: string[]): Observable<any> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      // Add stateName if provided
      if (stateNames && stateNames.length > 0) {
        stateNames.forEach(name => {
          params = params.append('stateName', name);
        });
      }

      params = params.set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('startsWith', startsWith || '');

    return this.http.get<any>(`${this.apiUrl}/cities/v1`, { params });
  }

  getCitiesByStartsWith(startsWith: string): Observable<any> {
    let params = new HttpParams()
      .set('startsWith', startsWith)
      .set('pageNumber', '0')
      .set('pageSize', '10');

    return this.http.get<any>(`${this.apiUrl}/cities/v1`, { params });
  }
  
  deleteSpeciality(id: string): Observable<any> {
    const url = `${this.apiUrl}/Specialty/${id}?id=${id}`;
    return this.http.delete(url);
  }

  
  addContinent(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/continent`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }
  addCountry(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/country`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }


  addState(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/state`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }


  addCity(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/city`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }
  
  addAccountType(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/account`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addSpeciality(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/specialty`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addPosition(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/position`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addLeadSource(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/leadsource`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addJobCategory(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/jobcategory`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addJobSkill(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/jobskill`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addJobType(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/jobtype`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addExperience(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/experiencelevel`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addDegree(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/degreelevel`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  addJobTags(data: any): Observable<any> {
    const apiUrl = `${this.apiUrl}/jobtag`;
    return this.http.post(apiUrl, data, { responseType: 'text' });
  }

  executeGetRequestURL(passedurl: string, queryparams: any): Observable<any> {
    let queryParameters = new HttpParams();
    if (queryparams) {

      Object.keys(queryparams).forEach(key => {
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/json")

    return this.http.get(this.apiUrl + passedurl, { headers: headers, params: queryParameters });
  }

  getCurrencies(pageNumber: number, pageSize: number): Observable<ApiResponseModel<Currency[]>> {
    const url = `${this.apiUrl}/Currencies?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    return this.http.get<ApiResponseModel<Currency[]>>(url);
  }

  // Api's for input of multiple geographical data

  getCountriesList(pageNumber?: number, pageSize?: number, startsWith?: string, continentNames?: string[]): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('continentName', continentNames?.join(',') ?? '');
    return this.http.get<any>(`${this.apiUrl}/countries/v1`, { params });
  }

  getStatesList(pageNumber?: number, pageSize?: number, startsWith?: string, countryName?: string[]): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('countryName', countryName?.join(',') ?? '');

    return this.http.get<any>(`${this.apiUrl}/states/v1`, { params });
  }

  getCitiesList(pageNumber?: number, pageSize?: number, startsWith?: string, stateName?: string[]): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('stateName', stateName?.join(',') ?? '');

    return this.http.get<any>(`${this.apiUrl}/cities/v1`, { params });
  }

  getJobCategories(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('startsWith', startsWith ? startsWith : '');

    return this.http.get<any>(`${this.apiUrl}/jobcategories`, { params });
  }

  getPhoneCodes(pageNumber: number, pageSize: number){
    return this.http.get<any>(`${this.apiUrl}/phonecodes?/pageNumber=${pageNumber}&pageSize=${pageSize}`)
  }

  getSpecialities(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('startsWith', startsWith ? startsWith : '');

    return this.http.get<any>(`${this.apiUrl}/specialities`, { params });
  }

  getJobSkills(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('pageSize', pageSize ? pageSize.toString() : '20')
      .set('startsWith', startsWith ? startsWith : '');

    return this.http.get<any>(`${this.apiUrl}/jobskills`, { params });
  }

  getAccountTypes(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');
    return this.http.get<any>(`${this.apiUrl}/account-types`, { params });
  }

  getLeadSources(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');

    return this.http.get<any>(`${this.apiUrl}/leadsources`, { params });
  }
 getPositions(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
   const params = new HttpParams()
     // .set('startsWith', startsWith)
     .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
     .set('startsWith', startsWith ? startsWith : '')
     .set('pageSize', pageSize ? pageSize.toString() : '30');
   return this.http.get<any>(`${this.apiUrl}/positions`, { params });
 }

  getCareerLevels(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');

    return this.http.get<any>(`${this.apiUrl}/experiencelevels`, { params });
  }

  getDegreeLevels(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');

    return this.http.get<any>(`${this.apiUrl}/degreelevels`, { params });
  }

  getJobTypes(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '20');

    return this.http.get<any>(`${this.apiUrl}/jobtypes`, { params });
  }

  getJobTags(pageNumber?: number, pageSize?: number, startsWith?: string): Observable<any> {
    const params = new HttpParams()
      // .set('startsWith', startsWith)
      .set('pageNumber', pageNumber ? pageNumber.toString() : '0')
      .set('startsWith', startsWith ? startsWith : '')
      .set('pageSize', pageSize ? pageSize.toString() : '30');

    return this.http.get<any>(`${this.apiUrl}/jobtags`, { params });
  }

  deleteItem(contentType: string, id: string) {
    switch (contentType) {
      case 'Continent':
        return this.http.delete(`${this.apiUrl}/${id}?id=${id}`);
      case 'Country':
        return this.http.delete(`${this.apiUrl}/country/${id}?id=${id}`);
      case 'States':
        return this.http.delete(`${this.apiUrl}/state${id}?id=${id}`);;
      case 'Cities':
        return this.http.delete(`${this.apiUrl}/city/${id}?id=${id}`);
      case 'AccountTypes':
        return this.http.delete(`${this.apiUrl}/account?id=${id}`);
      case 'Speciality':
        return this.http.delete(`${this.apiUrl}/Specialty/${id}?id=${id}`);
      case 'Positions':
        return this.http.delete(`${this.apiUrl}/position/${id}?id=${id}`);
      case 'LeadSources':
        return this.http.delete(`${this.apiUrl}/leadsource/${id}?id=${id}`);
      case 'JobCategories':
        return this.http.delete(`${this.apiUrl}/jobcategory/${id}?id=${id}`);
      case 'JobSkills':
        return this.http.delete(`${this.apiUrl}/jobskill/${id}?id=${id}`);
      case 'JobTypes':
        return this.http.delete(`${this.apiUrl}/jobtype/${id}?id=${id}`);
      case 'ExperienceLevel':
        return this.http.delete(`${this.apiUrl}/experiencelevel/${id}?id=${id}`);
      case 'DegreeLevel':
        return this.http.delete(`${this.apiUrl}/degreelevel/${id}?id=${id}`);
      case 'JobTags':
        return this.http.delete(`${this.apiUrl}/jobtags/${id}?id=${id}`);
      default:
        throw new Error('Invalid content type');
    }
  }

  updateContinent(id: string, data: any,options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/${id}?id=${id}`, data,options);
  }
  updateJobType(id: string, data: any,options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/jobtype/${id}?id=${id}`, data,options);
  }

  updateCountry(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/country/${id}?id=${id}`, data, options);
  }
  
  updateState(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/state${id}?id=${id}`, data, options);
  }
  
  updateCity(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/city/${id}?id=${id}`, data, options);
  }
  
  updateAccountType(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/account/${id}?id=${id}`, data, options);
  }
  
  updateSpeciality(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/Specialty/${id}?id=${id}`, data, options);
  }
  
  updatePosition(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/position/${id}?id=${id}`, data, options);
  }
  
  updateLeadSource(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/leadsource/${id}?id=${id}`, data, options);
  }
  
  updateJobCategory(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/jobcategory/${id}?id=${id}`, data, options);
  }
  
  updateJobSkill(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/jobskill/${id}?id=${id}`, data, options);
  }
  
  updateExperience(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/experiencelevel/${id}?id=${id}`, data, options);
  }
  
  updateDegree(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/degreelevel/${id}?id=${id}`, data, options);
  }
  
  updateJobTags(id: string, data: any, options?: any): Observable<any> {
    return this.http.put(`${this.apiUrl}/jobtags/${id}?id=${id}`, data, options);
  }
  getAccountType(): Observable<string[]> {
    return this.http.get<{status: string, timestamp: string, message: string, data: any[]}>(`${this.apiUrl}/account-types?pageNumber=0&pageSize=20`)
      .pipe(
        map(response => {
          const accountTypes = new Set<string>();
          response.data.forEach(item => {
            accountTypes.add(item.accountType);
          });
          return Array.from(accountTypes);
        })
      );
  }
  
  
  
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-all-my-distributors',
  templateUrl: './all-my-distributors.component.html',
  styleUrls: ['./all-my-distributors.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class AllMyDistributorsComponent implements OnInit, AfterViewInit {

isAll: boolean = true;
isRequested:boolean=false;
isRequestedBy:boolean=false;



  tabSliderPosition = 0; // Initial position of the tab slider
  tabSliderWidth = 0; // Initial width of the tab slider
  subTabSliderPosition = 0; // Initial position of the sub-tab slider
  subTabSliderWidth = 0; // Initial width of the sub-tab slider
  activeTab = 0;
  subactiveTab = 3; // Initialize subactiveTab to the first sub-tab index
  previousTab: number = 0;
  animateContent: boolean = false;

  constructor(){

  }

  ngOnInit() {
    const state = window.history.state;  
    console.log('naigation', state);
    if(state.property === "requestedDistributors"){
      this.activeTab = 2;
      this.subactiveTab = 3;
      this.selectSubTab(this.subactiveTab);
    }
    else if(state.property === "publishedDistributors"){
      this.activeTab = 2;
      this.subactiveTab = 4;
      this.selectSubTab(this.subactiveTab);
    }
    this.setTabSliderWidth();
    this.setSubTabSliderWidth();
    console.log("helo",this.isAll);

    
    
  }

  ngAfterViewInit() {
    this.setTabSliderPosition();
    this.setSubTabSliderPosition();
  }

  setTabSliderWidth() {
    const tabs = document.querySelectorAll('.tab-header .tab');
    if (tabs.length > 0) {
      this.tabSliderWidth = (tabs[0] as HTMLElement).offsetWidth;
    }
  }

  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }

  setSubTabSliderWidth() {
    const subTabs = document.querySelectorAll('.tab-header .tab');
    if (subTabs.length > 0) {
      this.subTabSliderWidth = (subTabs[0] as HTMLElement).offsetWidth;
    }
  }

  setSubTabSliderPosition() {
    this.subTabSliderPosition = (this.subactiveTab - 2) * this.subTabSliderWidth; // Adjust position based on subactiveTab starting index
  }

  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();
    }
  }

  selectSubTab(index: number) {
    if (index !== this.subactiveTab) {
      console.log('index', index, this.subactiveTab);
      
      this.subactiveTab = index;
      this.setSubTabSliderPosition();
    }
  }


}

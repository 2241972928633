<div class="bg-container" [ngClass]="{'overlay-active': isPopupVisible}" >
  <div *ngIf="isPopupVisible" >
  <div class="popup-container">
  <div class="popup" >
    <app-company-profile [isPopUpVisible]="isPopupVisible" (closePopUpEvent)="onClosePopUp()"></app-company-profile>
  </div>
</div>
</div>
<div class="scrolling-container">
  <div class="grid-container">
    <h1 class="welcome-label">Welcome, {{userName}} !</h1>
    <mat-tab-group class="invisible-tabs" header-less-tabs>
      <mat-tab>
        <div class="container">
          <mat-grid-list
            cols="{{ (cardLayout | async)?.columns }}"
            rowHeight="135px"
          >`
            <mat-grid-tile
              *ngFor="let metric of defaultMetrics"
              [colspan]="(cardLayout | async)?.miniCard?.cols"
              [rowspan]="(cardLayout | async)?.miniCard?.rows"
            >
              <app-card [metric]="metric" [routerLink]="metric.routeLink" cardType="oem-dashboard"> </app-card>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="content-row bg-color d-flex justify-content-between" fxLayout="row" fxLayoutAlign="start center">
    <div class = d-flex>
    <img
      src="../../../../../assets/images/users.svg"
      class="img-fluid"
      alt="Users Image"
    />
    <h2 class="oem-summary-text">Recent Product Enquiries</h2>
    <div class="bubble-blue" style="margin-left: 20px;">{{ productEnquiries.length === 0 ? 0 : productEnquiries.length }} Enquiries</div></div>
  
    <div class="btn button-primary medium-btn" style="position: relative; z-index: 200; margin-right: 40px;" (click)="navigate('Product Enquiry')">View All</div>
  </div>
  
  <app-dynamic-table
    [columns]="['Sr.No', 'Enquiry Id', 'Product Name', 'Status', 'Actions']"
    [jsonData]="productEnquiries.slice(0,3)"
  >
  </app-dynamic-table>
  
  <div class="content-row bg-color d-flex justify-content-between" fxLayout="row" fxLayoutAlign="start center">
    <div class = d-flex>
    <img
      src="./../../../../../assets/images/total_distributors.svg"
      class="img-fluid"
      alt="Users Image"
    />
    <h2 class="oem-summary-text">Recent Distributors Enquiries</h2>
    <div class="bubble-blue" style="margin-left: 20px;">{{ distributorEnquiries.length === 0 ? 0 : distributorEnquiries.length}} Enquiries</div>
    </div>
    <div style="margin-right: 40px; position: relative; z-index: 200;" class="btn button-primary medium-btn" (click)="navigate('Distributor Enquiry')"> View All</div>
  </div>
  
  <app-dynamic-table
    [columns]="['Sr.No', 'Enquiry Id', 'Product Name', 'Status', 'Actions']"
    [jsonData]="distributorEnquiries.slice(0,3)"
  >
  </app-dynamic-table>
</div>
</div>


import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noWhitespaceValidator } from 'src/app/models/product-settings-model';
import { UnitMeasureService } from 'src/app/services/shared/product-settings/unit-measure.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-add-edit-unit-of-measure',
  templateUrl: './add-edit-unit-of-measure.component.html',
  styleUrls: ['./add-edit-unit-of-measure.component.css']
})
export class AddEditUnitOfMeasureComponent implements OnInit{
  addEditUnitOfMeasureForm: FormGroup;
  mesaureCategories : string[] = ['New Measure','Existing Measure'];
  existMesaureCategories : any[] = [];
  selectedMeasure: string = '';
  id : any;
  actionType! : string;
  constructor(
    private dialogRef: MatDialogRef<AddEditUnitOfMeasureComponent>,
    private formBuilder: FormBuilder,
    private sweetAlertService : SweetAlertService,
    private unitMeasureService : UnitMeasureService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.addEditUnitOfMeasureForm = this.formBuilder.group({
      measureCategory : ['',[Validators.required, noWhitespaceValidator()]],
      name: ['', [Validators.required, noWhitespaceValidator()]],
      code: ['', [Validators.required, noWhitespaceValidator()]],
      active: ['true', Validators.required]
    });
  }

  ngOnInit() {
    this.addEditUnitOfMeasureForm.patchValue(this.data);
    
    if(this.data) {
      this.id = this.data.id;
      this.actionType = this.data.actionType;
      if(this.data.active) {
      this.addEditUnitOfMeasureForm.get('active')?.setValue('true');
      } else{
      this.addEditUnitOfMeasureForm.get('active')?.setValue('false');
      }

      if (this.data.actionType==='edit') {
        this.addEditUnitOfMeasureForm.get('measureCategory')?.enable();
        this.addEditUnitOfMeasureForm.get('name')?.enable();
        this.addEditUnitOfMeasureForm.get('code')?.enable();
        this.addEditUnitOfMeasureForm.get('active')?.enable();
      } else {
        this.addEditUnitOfMeasureForm.get('measureCategory')?.disable();
        this.addEditUnitOfMeasureForm.get('name')?.disable();
        this.addEditUnitOfMeasureForm.get('code')?.disable();
        this.addEditUnitOfMeasureForm.get('active')?.disable();
      }

    }

   // this.getAllMeasureCategories();
  }

  onSelectionChange(event: any): void {
    this.selectedMeasure = event.value; 
  }
  
  getAllMeasureCategories() {
    this.unitMeasureService.getAllMeasureCategories().subscribe({
      next: (response) => {
        if(response.data.length > 0) {
          this.existMesaureCategories = response.data;
        } 
      },
      error: (error) => {
        console.error('Error fetching measure data:', error);
      }
    });
  }

  onFormSubmit() {
    if (this.addEditUnitOfMeasureForm.valid) {
      console.log(this.addEditUnitOfMeasureForm.value) 
      if (this.data) {
        const jsonData = JSON.stringify(this.addEditUnitOfMeasureForm.value);
        this.unitMeasureService.updateUnitMeasure(jsonData, this.id).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Unit of Measure updated successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to update Unit of Measure !!');
          }
        );
      } else {
        console.log(this.addEditUnitOfMeasureForm.value);
        const jsonData = JSON.stringify(this.addEditUnitOfMeasureForm.value);
        this.unitMeasureService.saveUnitMeasure(jsonData).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Unit of Measure added successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to add Unit of Measure !!');
          }
        );
      }
    } else {
      this.addEditUnitOfMeasureForm.markAllAsTouched();
    }
  }
}

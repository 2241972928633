import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OemRoutingModule } from './oem-routing.module';
import { AddOemComponent } from './add-oem/add-oem.component';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { CustomMaterialModule } from 'src/app/material.module';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ExhibitionsComponent } from './exhibitions/exhibitions.component';
import { AddexhibitionComponent } from './addexhibition/addexhibition.component';
import { CatalogComponent } from './catalog/catalog.component';
import { ProductsManagementComponent } from './products-management/products-management.component';
import { ViewexhibitionComponent } from './viewexhibition/viewexhibition.component';
import { WebinarsComponent } from '../../shared/webinars/webinars.component';
import { AddWebinarComponent } from '../../shared/add-webinar/add-webinar.component';
import { AddEditAllProductsComponent } from './products-management/add-edit-all-products/add-edit-all-products.component';
import { ProductFilterComponent } from './products-management/product-filter/product-filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTreeModule } from '@angular/material/tree';
import { ProductDetailsComponent } from './products-management/product-details/product-details.component';
import { ImportComponent } from './products-management/import/import.component';
import { ExportComponent } from './products-management/export/export.component';
import { ExhibitionfilterComponent } from './exhibitionfilter/exhibitionfilter.component';
import { MydistributorsComponent } from './mydistributors/mydistributors.component';
import { ViewProductsComponent } from './products-management/view-products/view-products.component';
import { ProductFileViewerComponent } from './products-management/product-file-viewer/product-file-viewer.component';

import { AllMyDistributorsComponent } from './all-my-distributors/all-my-distributors.component';
import { DistributorRequestsComponent } from './distributor-requests/distributor-requests.component';
import { AllDistributorsComponent } from './all-distributors/all-distributors.component';
import { ExhibitionAppointmentsComponent } from './exhibition-appointments/exhibition-appointments.component';
import { ViewappointmentComponent } from './viewappointment/viewappointment.component';
import { CompanyProfilePopupComponent } from './company-profile-popup/company-profile-popup.component';
import { DistributorRequestedByComponent } from './distributor-requested-by/distributor-requested-by.component';

@NgModule({
  declarations: [
    AddOemComponent,
    DashboardComponent,
    CompanyProfileComponent,
    ExhibitionsComponent,
    AddexhibitionComponent,
    CatalogComponent,
    ProductsManagementComponent,
    ViewexhibitionComponent,
    AddEditAllProductsComponent,
    ProductFilterComponent,
    ProductDetailsComponent,
    ImportComponent,
    ExportComponent,
    ExhibitionfilterComponent,
    MydistributorsComponent,
    AllMyDistributorsComponent,
    DistributorRequestsComponent,
    ViewProductsComponent,
    ProductFileViewerComponent,
    AllDistributorsComponent,
    ExhibitionAppointmentsComponent,
    ViewappointmentComponent,
    CompanyProfilePopupComponent,
    DistributorRequestedByComponent
  ],exports:[
MydistributorsComponent,
CatalogComponent
  ],

  imports: [
    CommonModule,
    OemRoutingModule,
    CustomMaterialModule,
    SharedModule,
    CommonModule,
    FormsModule,
    CustomMaterialModule,
    TranslateModule,
    RouterModule,
    CustomMaterialModule,
    TranslateModule,
    RouterModule,
    CustomMaterialModule,
    TranslateModule,
    RouterModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    AngularEditorModule,
    MatChipsModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTreeModule,
  
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class OemModule { }


  <app-website-header></app-website-header>
  <div class="row">
    <div class="image-container">
      <img width="100%" height="auto" src="../../../assets/images/brands-banner.svg" alt="landingpage" class="landingpageimage" />
      <div class="header-name">
        <p>Brands</p>
      </div>
    </div>
  </div>

  <div class="mx-3 mt-2">
    <img class="breadcrumb-icon" src="../../../assets/images/icons/home-icon.svg" alt="">
    <a class="breadcrummb ps-2" style="color:#858585 ;" (click)="navigate()">Home</a>
    <a class="breadcrummb" style="color:#858585 ;">/</a>
    <a class="breadcrummb" style="color:#000000 ;"  (click)="navigate()">Brands</a>
    <a class="breadcrummb" style="color:#000000 ;">/</a>
    <a class="breadcrummb" style="color:#000000 ;" *ngIf="profileData && !profileData.companyName">{{selectedTabName}} </a>
    <a class="breadcrummb" style="color:#000000 ;" *ngIf="profileData && profileData.companyName">{{profileData.companyName | titlecase}} / {{selectedTabName}}</a>
  </div>

  <mat-card>
    <div class="tab-container">
      <div class="tab-header">
        <div class="tab" [class.active]="activeTab === 0" (click)="selectTab(0)">Company Profile</div>
        <div class="tab" [class.active]="activeTab === 1" (click)="selectTab(1)">Products</div>
        <div *ngIf="profileData && profileData.type === 'brand'" class="tab" [class.active]="activeTab === 2" (click)="selectTab(2)">Distributor Network</div>
        <div *ngIf="profileData && profileData.type === 'distributor'" class="tab" [class.active]="activeTab === 3" (click)="selectTab(3)">Connected Brands</div>
        <div class="tab" [class.active]="activeTab === 4" (click)="selectTab(4)">Catalogs </div>
        <div class="tab" [class.active]="activeTab === 5" (click)="selectTab(5)">Exhibitions</div>
        <div class="tab" [class.active]="activeTab === 6" (click)="selectTab(6)">Contact Us</div>
        <div class="action-buttons">
          <button class="view-button" (click)="viewProfile()"><img src="assets/images/white-eye.svg" alt="view"/>View</button>
          <button class="download-button" (click)="downloadProfile()"><img src="assets/images/download.svg" alt="Download"/>Download</button>
        </div>
        <div class="tab-slider" [style.width.px]="tabSliderWidth" [style.transform]="'translateX(' + tabSliderPosition + 'px)'"></div>
      </div>
  
      <div *ngIf="activeTab === 0" class="tab-content" [@slideInOut]>
        <app-company-profile  [data]="profileData"></app-company-profile>
      </div>
      <div *ngIf="activeTab === 1" class="tab-content" [@slideInOut]>
        <app-products [data]="profileData"></app-products>
      </div>
      <div *ngIf="activeTab === 2" class="tab-content" [@slideInOut]>
        <app-distributors-network [data]="profileData" (viewProfileEvent)="handleSelectDistributor($event)"></app-distributors-network>
      </div>
      <div *ngIf="activeTab === 3" class="tab-content" [@slideInOut]>
        <app-connected-brands [data]="profileData" (viewOEMProfileEvent)="handleSelectOEM($event)"></app-connected-brands>
      </div>
      <div *ngIf="activeTab === 4" class="tab-content" [@slideInOut]>
        <app-catalogs [data]="profileData"></app-catalogs>
      </div>
      <div *ngIf="activeTab === 5" class="tab-content" [@slideInOut]>
        <app-exhibitions [data]="profileData"></app-exhibitions>
      </div>
      <div *ngIf="activeTab === 6" class="tab-content" [@slideInOut]>
        <app-brand-contact-us [data]="profileData"></app-brand-contact-us>
      </div>
    </div>
  </mat-card>

  <app-website-footer></app-website-footer> 

<!-- Info to Download and View Profile as pdf-->

<div>
  <div id="companyInfo-page" *ngIf="captureMode" class="company-info-container">
    <div class="top-section">
      <div class="brand-info-container">
        <div class="brand-info">
          <div>
            <h1>{{ profileData.companyName }}</h1>
            <ng-container *ngIf="profileData?.url">
              <a target="_blank" href="{{ profileData.url }}">{{ profileData.url }}</a>
            </ng-container>
          </div>
        </div>
        <div class="vertical-line"></div>
      </div>

      <div class="address-container">
        <p *ngIf="profileData.address">{{ profileData.address }}</p>
        <p *ngIf="profileData.country || profileData.state || profileData.city || profileData.zipCode">
            {{ profileData.country }}<span *ngIf="profileData.country && (profileData.state || profileData.city || profileData.zipCode)">, </span>
            {{ profileData.state }}<span *ngIf="profileData.state && (profileData.city || profileData.zipCode)">, </span>
            {{ profileData.city }}<span *ngIf="profileData.city && profileData.zipCode">, </span>
            {{ profileData.zipCode }}
        </p>
        <p *ngIf="profileData.email || profileData.phoneCode || profileData.phoneNumber">
            <span *ngIf="profileData.email">{{ profileData.email }}</span>
            <span *ngIf="profileData.email && (profileData.phoneCode || profileData.phoneNumber)">, </span>
            <span *ngIf="profileData.phoneCode">{{ profileData.phoneCode }}</span>
            <span *ngIf="profileData.phoneCode && profileData.phoneNumber">-</span>
            <span *ngIf="profileData.phoneNumber">{{ profileData.phoneNumber }}</span>
        </p>
      </div>
    </div>

    <div *ngIf="profileData.description" class="description-box">
      <h2 class="box-heading">Company Information</h2>
      <div class="description-container">
        <div [innerHTML]="profileData.description" class="description-content"></div>
      </div>
    </div>

    <div *ngIf="products.length > 0" class="description-box">
      <h2 class="box-heading">Product Details</h2>
      <table class="table-content">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Category</th>
            <th>Certificates</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products">
            <td>{{ product.name }}</td>
            <td>{{ product.categoryName }}</td>
            <td>
              <span *ngFor="let certificate of product.certificates; let last = last">{{certificate}}{{last ? '' : ', '}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="profileData && profileData.type === 'brand' && distributorNetwork.length > 0" class="description-box">
      <h2 class="box-heading">Distributor Network</h2>
      <table class="table-content">
        <thead>
          <tr>
            <th>Distributor Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Mobile Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let distributor of distributorNetwork">
            <td>{{ distributor.distributorName }}</td>
            <td>{{ distributor.address }} {{ distributor.city }}, {{ distributor.country }}</td>
            <td>{{ distributor.email }}</td>
            <td>{{ distributor.mobileNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="profileData && profileData.type === 'distributor' && brands.length > 0" class="description-box">
      <h2 class="box-heading">Connected Brands</h2>
      <table class="table-content">
        <thead>
          <tr>
            <th>Brand Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Mobile Number</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let brand of brands">
            <td>{{ brand.oemName }}</td>
            <td>{{ brand.address }} {{ brand.city }}, {{ brand.country }}</td>
            <td>{{ brand.email }}</td>
            <td>{{ brand.phoneNumber }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>






 

  

  

  
<app-website-header></app-website-header>

<div class="signup-container">
  <div class="background-container">
    <div class="signup-form-header-container">
      <div class="signup-form-logo">
        <img src="./../../../assets/images/Frame 534.svg" />
      </div>
      <div class="signup-form-banner-container ml-2">
        <div class="">
          <span style="font-weight: 600; font-size: 24px; color: #0d87c8">Signup with Us</span><br />
          <span><b>Compare, negotiate and accept quotes directly online.</b></span>
        </div>
      </div>
    </div>

    <div class="signup-main-container">
      <div class="row signup-Page">
        <div class="col-lg-7 col-md-12 col-sm-12 signup-left" *ngIf="emailSent">
          <form class="form-conatiner" (ngSubmit)="submitForm(form)" #form="ngForm">


            <div class="Account-type">
              <p class="top-text">Account Type<span class="required-asterisk">*</span></p>
              <select class="input-field" [(ngModel)]="UserSignup.accountType" name="accounttype" style="width: 100%;"
                (change)="selectedaccountcategory(UserSignup.accountType)">
                <option *ngFor="let code of accounttype" [value]="code.id">{{ code.name }}</option>
              </select>
              <div *ngIf="!isSelected && isProceed " class="error-message"
              style="display: flex; align-items: center">
              <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" />
              <span class="error-message">Account type is required.</span>
            </div>
             
            </div>
          

            <div *ngIf="isindividual" class="hospitalname">
              <label class="label-styles" for="">Name of the Organization <span class="required-asterisk">*</span></label><br />
              <input style="width: 100%;" class="input-field" type="text" [(ngModel)]="UserSignup.HospitalName"
                name="hospitalname" required #HospitalName="ngModel" (keypress)="onlyText($event)"  />
          
                <div *ngIf="HospitalName?.touched && HospitalName?.errors?.['required'] || (HospitalName?.errors?.['required'] && isProceed)" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Organization name is required.</span>
                </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">First Name <span class="required-asterisk">*</span></label><br />
                <input class="input-field" type="text" [(ngModel)]="UserSignup.firstName" name="firstName" required
                  #firstNameField="ngModel" (keypress)="onlyText($event)"  />
                <div *ngIf="firstNameField?.touched && firstNameField?.errors?.['required'] || (firstNameField?.errors?.['required'] && isProceed)" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">First name is required.</span>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Last Name <span class="required-asterisk">*</span></label><br />
                <input class="input-field" type="text" [(ngModel)]="UserSignup.lastName" name="lastName" required
                  #lastNameField="ngModel" (keypress)="onlyText($event)"  />
                <div *ngIf="lastNameField?.touched && lastNameField?.errors?.['required'] || (lastNameField?.errors?.['required'] && isProceed)" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" />
                  <span style="margin-left: 5px">Last name is required.</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Email<span class="required-asterisk">*</span></label><br />
                <input class="input-field" type="email" style="height: 40px; padding: 0px 5px 0px 15px; width: 18.2rem;"
                  [(ngModel)]="UserSignup.email" name="email" required #emailField="ngModel"
                  (ngModelChange)="emailValidation(UserSignup.email)" autocomplete="new-password" />
                <div *ngIf="emailField?.touched && emailField?.errors?.['required'] || (emailField?.errors?.['required'] && isProceed)" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Email is required.</span>
                </div>

                <div *ngIf="emailInvalid && emailField?.value" class="error-message" style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">
                    Invalid Email.</span>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Phone<span style="color: red">*</span></label><br />
                <div class="row" style="display: flex;">
                  <div style="display: flex ; flex-direction:column ">
                    <div class="input-group   rounde outline-none" style="border-radius: 6px;">
                      <!-- <div class=" no-outline" style="width: 60px; outline: #fff;"> -->
                        <input class="input-field  " placeholder="IN" style="border-radius: 6px 0px 0px 6px; width: 60px;"
                          aria-label="Select your country code" [value]="countryCode" [(ngModel)]="UserSignup.countryCode" name="countryCode" required
                          (input)="filterOptions(UserSignup.countryCode)" 
                          (click)="filterOptions(UserSignup.countryCode)" (keypress)="onlyText2($event)" (focus)="showOptions = true" autocomplete="new-password"   #countryCodeInput >
                        <div style="width: 75px; outline: none;;" class="custom-dropdown" *ngIf="showOptions">
                          <ul>
                            <li class="no-outline" style="width: 60px;" *ngFor="let code of filteredCountryCodes"
                              (click)="selectOption(code)">{{ code.countryCode | uppercase }}</li>
                          </ul>
                        </div>
                      <!-- </div> -->
                      

                      <input type="text" class="border-0 no-interaction text-center no-outline" style="width: 60px;"
                        placeholder="+91" [(ngModel)]="UserSignup.phoneCode" name="phoneCode" required #phoneCodeField="ngModel">
                      <input type="text" class=" border-0 no-interaction no-outline" style="width: 10.83rem ;border-radius: 0px 6px 6px 0px"
                        [(ngModel)]="UserSignup.phoneNumber" name="phoneNumber"  required
                        #phonenumberfield="ngModel"
                        (ngModelChange)="phonenumbervalidation(UserSignup.phoneNumber)" (keypress)="numberOnly($event)" >
                    </div>
                
                    <div *ngIf="phoneCodeField?.touched && phoneCodeField?.errors?.['required'] || (phoneCodeField?.errors?.['required'] && isProceed && !phonenumberfield?.errors?.['required'])" class="error-message"
                      style="display: flex; align-items: center; ">
                      <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                        style="margin-left: 5px" class="error-message">Phone Code is required.</span>
                    </div>
                    <div *ngIf="phonenumberfield?.touched && phonenumberfield?.errors?.['required'] || (phonenumberfield?.errors?.['required'] && isProceed)" class="error-message"
                      style="display: flex; align-items: center; ">
                      <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                        style="margin-left: 5px" class="error-message">Phone Number is required.</span>
                    </div>
                    <!-- <div *ngIf="!validNumber" class="error-message" style="display: flex; align-items: center">
                      <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                      <span style="margin-left: 5px" class="error-message">
                        Please Enter valid phone number.</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="isindividual" class="col-md-12 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Website<span class="required-asterisk">*</span></label><br />
                <input class="input-field" style="width: 100%;" type="text" [(ngModel)]="UserSignup.website"
                  name="website" required #websiteField="ngModel" />
                <div *ngIf="websiteField?.touched && websiteField?.errors?.['required'] || websiteField?.errors?.['required'] && isProceed" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Website is required.</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Password<span class="required-asterisk">*</span></label><br />
                <div class="d-flex">
                <input class="input-field" [type]="showPassword ? 'text' : 'password'" name="password" required [(ngModel)]="UserSignup.password"
                  #passwordField="ngModel" (ngModelChange)="passwordValidation(UserSignup.password)"
                  (cut)="preventCopyPaste($event)"
                (copy)="preventCopyPaste($event)"
                (paste)="preventCopyPaste($event)"
                (contextmenu)="preventContextMenu($event)"
                autocomplete="new-password"
                style="border-radius:6px; width: 100%;" />
                  <button
                  type="button"
                  class="eye-button  bg-transparent"
                  (click)="showPassword = !showPassword"
                  style="border:none; position: absolute;" 
                >
                <img
                class="eye-icon"
                loading="lazy"
                alt=""
                [src]="showPassword ? './../../../../assets/images/Eye.svg' : './../../../../assets/images/Blind.svg'"
              /></button>
                </div>
                <div *ngIf="passwordField?.touched && passwordField?.errors?.['required'] || passwordField?.errors?.['required'] && isProceed" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Password is required.</span>
                </div>
                <div *ngFor="let error of passwordValidation(UserSignup.password)" class="error-message"
                  style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">
                    {{error}}</span>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-12 inside-fields">
                <label class="label-styles" for="">Confirm Password<span class="required-asterisk">*</span>
                </label><br />
              
                <div class="d-flex">
                <input class="input-field" [type]="showConfirmPassword ? 'text' : 'password'" name="confirmPassword" required
                  [(ngModel)]="UserSignup.confirmPassword" #confirmPasswordField="ngModel"
                  (cut)="preventCopyPaste($event)"
                (copy)="preventCopyPaste($event)"
                (paste)="preventCopyPaste($event)"
                (contextmenu)="preventContextMenu($event)"
                style="border-radius:6px; width: 100%;"/>
                  <button
                  type="button"
                  class="eye-button  bg-transparent"
                  (click)="showConfirmPassword = !showConfirmPassword"
                  style="border:none; position: absolute;" 
                >
                <img
                class="eye-icon" style="background-color: none;border:none;"
                loading="lazy"
                alt=""
                [src]="showConfirmPassword ? './../../../../assets/images/Eye.svg' : './../../../../assets/images/Blind.svg'"
              /></button>  
                </div>
                <div *ngIf="confirmPasswordField?.touched && confirmPasswordField?.errors?.['required'] || confirmPasswordField?.errors?.['required'] && isProceed"
                  class="error-message" style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Confirm password is required.</span>
                </div>
                <div *ngIf="
                      UserSignup.password !== UserSignup.confirmPassword &&
                      confirmPasswordField?.touched && !confirmPasswordField?.errors?.['required'] 
                    " class="error-message" style="display: flex; align-items: center">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                  <span style="margin-left: 5px" class="error-message">Passwords don't match.</span>
                </div>
              </div>
            </div>
            <div class="additional-content">
              <input type="checkbox" id="sampleCheckbox" name="confirmPassword" required
                (change)="updateSignupButtonState($event)" />
              <label for="sampleCheckbox">I agree to Medworld Expo's
                <a routerLink="/terms-and-conditions" style="color: #0d87c8">Terms of Use</a> and
                <a routerLink="/privacy-policy" style="color: #0d87c8">Privacy policy</a>
              </label><br />
              <button type="submit" class="button-styles button-primary"
                [style.opacity]="isSignupButtonEnabled ? '1' : '0.5'" [disabled]="!isSignupButtonEnabled">
                Sign Up <img src="./assets/images/arrow-right.svg" alt="" />
              </button>
            </div>
            <div *ngIf="badRequest" class="error-message" style="display: flex; align-items: center">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
              <span style="margin-left: 5px" class="error-message">{{ errorMessage }}</span>
            </div>
            <div class="signin-link">
              <p>Already have an account? <a routerLink="/login" class="links">Sign in</a></p>
            </div>
          </form>
        </div>
        <div class="mailsent d-flex text-center align-items-center p-0">
          <div class="col-lg-7 col-md-12 col-sm-12 signup-left" *ngIf="!emailSent">
            <div class="verification-container">
              <div class="verification-text mb-3">
                <p>Verify your email</p>
              </div>
              <div class="verification-message mb-3">
                <p>Hi {{ UserSignup.lastName }}, Please verify your email address by clicking the link sent to<br />{{ UserSignup.email }}
              </div>
              <div class="resend-button mb-3">
                <button (click)="resendVerificationMail()" class="button" style="width: 40%;">Resend Verification Email</button>
              </div>
              <div class="support-email mb-3">
                <p>Questions? Email us at<br /><span>SupportMedworldexpo.com</span></p>
              </div>
            </div>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12 signup-right">
            <div class="top-right">
              <p class="top-text" style="color: #0d87c8">
                Why Choose Medworld Expo
              </p>
              <div class="signup-leads">
                <img src="assets/images/webinar.png" alt="" height="45px" width="45px" />
                <div class="text">
                 <p class="role"><b>
                    Exclusive Webinars, CMEs and Career Options
                  </b></p>
                </div>
              </div>
              <div class="signup-leads">
                <img src="assets\images\social-services.png" alt="" height="45px" width="45px" />
                <div class="text">
                  <p class="role"><b>
                    Interactive Health Communities
                  </b></p>
                </div>
              </div>
              <div class="signup-leads">
                <img src="assets\images\customer-engagement.png" alt="" height="45px" width="45px" />
                <div class="text">
                <p class="role"><b>
                    Enhanced Collaboration Opportunities
                  </b></p>
                </div>
              </div>
              <div class="signup-leads">
                <img src="assets\images\analytics.png" alt="" height="45px" width="45px" />
                <div class="text">
                  <p class="role"><b>
                    Up-to-Date Industry Insights
                  </b></p>
                </div>
              </div>
            </div>
            <div class="bottom-right">
              <img class="distributor-bottom-img" src="/assets/images/account_type/Frame (2).svg" alt="">
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ourmission-section d-flex justify-content-center mt-5 mb-3 align-items-center p-0 gap-4">
  <div class="mission-image">
    <img src="/assets/images/mission.svg" alt="mission image">
  </div>
  <div class="mission-text">
    <span class="mission-title"><strong>Our Mission</strong></span>
    <!-- <h1>Lorem ipsum dolor, sit amet consectetur adipisicing.</h1> -->
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;</span><li style=list-style-type:none>To revolutionize the health-care industry by providing a premier digital platform for seamless interaction.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>Commit to empowering Manufacturers, Distributors, and Health Care Providers with cutting-edge tools for growth and connectivity.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;</span><li style=list-style-type:none>Dedicated to nurturing a vibrant ecosystem where the medical community thrives together.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>To democratize access to medical solutions, driving forward the industry with integrity, innovation, and insight.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>Facilitate career advancement by offering a platform for job opportunities and professional networking within the medical industry.</li>

    </div>
    
  </div>
</div>
<app-website-footer></app-website-footer>
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { noWhitespaceValidator } from 'src/app/models/product-settings-model';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-add-edit-category',
  templateUrl: './add-edit-category.component.html',
  styleUrls: ['./add-edit-category.component.scss']
})
export class AddEditCategoryComponent implements OnInit{ 
  @ViewChild('fileInput') fileInput!: ElementRef;
  categoryForm!: FormGroup;
  fileName = 'Select File';
  currentFile?: File;
  image: string | null = null;
  selectedFileName: string | null = null;
  fileError: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddEditCategoryComponent>,
    private formBuilder: FormBuilder,
    private sweetAlertService : SweetAlertService,
    private categoryService : CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.categoryForm = this.formBuilder.group({
      image: ['', Validators.required],
      name: ['',[Validators.required, noWhitespaceValidator()]] 
    });

  }

  ngOnInit(){
    console.log(' AddEditCategoryComponent data', this.data)
    if(!this.data.isAddItem) {
      this.categoryForm.get('name')?.setValue(this.data.parentNode.item);
      if(this.data.parentNode.image) {
        this.image = this.data.parentNode.image;
        const lastSlashIndex = this.data.parentNode.image.lastIndexOf('/');
        if (lastSlashIndex !== -1) {
          this.fileName = this.data.parentNode.image.substring(lastSlashIndex + 1);
        }
        this.categoryForm.get('image')?.setValue(this.data.parentNode.image);
        this.fileError = false;
      }
    }
  }

  onFormSubmit() {
    if (!this.fileInput.nativeElement.files.length && !this.image) {
      this.fileError = true;
    } else {
      this.fileError = false;
    }

    if (this.categoryForm.invalid || this.fileError) {
      this.categoryForm.markAllAsTouched();
      return;
    }

    console.log('this.categoryForm', this.categoryForm.value.image);
    if (this.categoryForm.valid) {
      if(this.data.isParentcategory) { 
        this.categoryService.createCategory(this.categoryForm.value.name, this.categoryForm.value.image).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Category added successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to category level !!');
          }
        );
      } else if (this.data.isAddItem && !this.data.isParentcategory) {
        // save category
        this.categoryService.createCategory(this.categoryForm.value.name, this.categoryForm.value.image, this.data.parentNode.id).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Category added successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to category !!');
          }
        );
      } else if(!this.data.isAddItem) {
         // update category
         this.categoryService.updateCategory(this.categoryForm.value.name, this.categoryForm.value.image, this.data.parentNode.id).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Category updated successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to category !!');
          }
        );
      }
    } else {
      this.categoryForm.markAllAsTouched();
    }
  }

  invalidFileType = false;
  fileType = '';
  selectFile(event: any, fileInput: HTMLInputElement): void {
    if (event.target.files && event.target.files[0]) {
      if(!event.target.files[0].type.includes('image/')){
        this.fileType = event.target.files[0].type;
        this.invalidFileType = true;
        return;
      }
      this.fileError = false;
      this.invalidFileType = false
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
      this.selectedFileName = file.name;
      let uploadData = new FormData();
      uploadData.append('file', this.currentFile);
      uploadData.append('destinationKey',MweConstants.productSetting+'category/');
      fileInput.value = '';
      this.categoryService.uploadFile(MweConstants.uploadFile, uploadData).subscribe(data=>{
        if(data && data?.data){
          console.log('data.data', data.data)
          this.image = data.data;
          this.categoryForm.get('image')?.setValue(data.data);
        }
      },error=>{
        console.log(error); 
        this.sweetAlertService.showError('', 'Failed to upload file !!');
      });
    } else {
      this.fileName = 'Select File';
    }
  }
  
  deleteFile () {
    this.selectedFileName = null;
    this.image = null;
    this.fileName = 'Select File';
  }
}

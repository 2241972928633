import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit{
  ngOnInit(): void {
    //this.updateData();
  }

  @Input() itemsPerPage: number = 0;
  @Input() data: any[] = [];
  @Output() pageChanged = new EventEmitter<any[]>();
  pageChange: any;
  pageSize = 0;
  currentPage = 0;
  totalData = this.data.length;

  ngOnChanges(changes: SimpleChanges): void {
    this.pageSize = this.itemsPerPage;
    this.updateData();
  }

  updateData() {
    console.log(this.data);
    
    this.totalData = this.data?.length;
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    const paginatedData = this.data.slice(start, end);
    this.pageChanged.emit(paginatedData);
  }

  
  changePage(pageIndex: number) {
    this.currentPage = pageIndex;
    this.updateData();
  }

  
  getTotalPages(): number {
    return Math.ceil(this.totalData / this.pageSize);
  }

  getPageNumbers(): (number | string)[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPage;
    const pageNumbers: (number | string)[] = [];
    // Always show the first page
    pageNumbers.push(0);

    // Show ellipsis if there are more than 3 pages before the current page
    if (currentPage > 2) {
      pageNumbers.push('...');
    }

    // Show current page and its neighbors
    for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages - 2, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    // Show ellipsis if there are more than 3 pages after the current page
    if (currentPage < totalPages - 3) {
      pageNumbers.push('...');
    }

    // Always show the last page if total pages is greater than 1
    if (totalPages > 1) {
      pageNumbers.push(totalPages - 1);
    }

    return pageNumbers;
  }



}

<div class="popup-content">
    <h2 class="title">
        <span style="font-family: Georgia, serif;  font-weight: bold; font-style: italic; white-space: nowrap; font-size: 16px;">Join us this September 2024 </span><br/>
        <span style="font-family: Georgia, serif; font-weight: bold; font-style: italic; white-space: nowrap; ">for the grand launch</span> <br/>
        <span style="font-family: Georgia, serif; font-weight: bold; font-style: italic; white-space: nowrap; ">of</span> <br/>
        <span style="font-family: Georgia, serif;font-weight: bold; font-style: italic; white-space: nowrap;">the world's largest</span> <br/>
        <span style="font-family: Georgia, serif; font-weight: bold; font-style: italic; white-space: nowrap;font-size: large;">Digital Medical Expo</span> <br/>

      </h2>
      

    <p class="subtext">Stay informed & be the first to know when we go live </p>
    <div class="text-box d-flex flex-column align-items-center">
        <form (ngSubmit)="onNotifyMeSubmit()" #notifyMeForm="ngForm">
            <input type="email" placeholder="Enter your email "
             class="input-element input-fields" name="email" [(ngModel)]="email" (ngModelChange)="emailValidation(email)" required  #emailField="ngModel" />
            <div *ngIf="emailField?.touched && emailInvalid" class="error-message">Invalid email address</div>
            
             <div class="button-container d-flex justify-content-center">
                
                 <button  type="submit" class="button" style="width: 50%;"  [disabled]="!notifyMeForm.form.valid">Notify Me</button>
             </div>
        </form>
    
    </div>
</div>


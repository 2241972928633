<div class="modal-popup" mat-dialog-content>
    <h2 style="color: #0D87C8">Export</h2>
    <div class="download-csv">
        <button class="download-csv-btn" mat-raised-button (click)="downloadCSVFile()">
            <img src="assets/images/download.svg" alt=""> Download CSV file
        </button>
    </div> 
    <div class="popup-buttons">
        <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">Close</button>
    </div> 
</div>



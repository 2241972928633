import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterPayload } from 'src/app/models/product-model';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { OEMSSummaryData, DistributorsSummaryData, EndCustomerSummaryData } from 'src/app/models/super-admin-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DasboardService {

  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL = environment.AUTH_BASE_URL;
  constructor(private http: HttpClient) { }
  public getOEMSSummary(requestBody: FilterPayload): Observable<ApiResponseModel<OEMSSummaryData>> {
    const url = this.ONBOARDING_BASE_URL + '/OEM/summary';
    return this.http.post<ApiResponseModel<OEMSSummaryData>>(url, requestBody);
  }

  public getDistributorsSummary(requestBody: FilterPayload): Observable<ApiResponseModel<DistributorsSummaryData>> {
    const url = this.ONBOARDING_BASE_URL + '/distributor/summary';
    return this.http.post<ApiResponseModel<DistributorsSummaryData>>(url, requestBody);
  }

  public getEndCustomerSummary(requestBody: FilterPayload): Observable<ApiResponseModel<EndCustomerSummaryData>> {
    const url = this.ONBOARDING_BASE_URL + '/end-customer/summary';
    return this.http.post<ApiResponseModel<EndCustomerSummaryData>>(url, requestBody);
  }

  public saveSuperAdminProfile(requestBody: any): Observable<ApiResponseModel<any>> {
    const url = this.AUTH_BASE_URL + 'user/super-admin/update-profile';
    return this.http.put<ApiResponseModel<any>>(url, requestBody);
  }

  public getContent(type: string): Observable<ApiResponseModel<any>> {
    const url = `${this.ONBOARDING_BASE_URL}/content/${type}`;
    return this.http.get<ApiResponseModel<any>>(url);
  }

  public updateContent(type: string, content: string): Observable<ApiResponseModel<any>> {
    const url = `${this.ONBOARDING_BASE_URL}/content/${type}`;
    return this.http.put<ApiResponseModel<any>>(url, content);
  }
}

import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/shared/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  show = false;
  message?: string;

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.spinnerService.spinnerState$.subscribe(state => {
      
      
      this.show = state.show;
      this.message = state.message;
    });
  } 
}

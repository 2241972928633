import { Component, OnInit } from '@angular/core';
import { SubscriptionsService } from 'src/app/services/super-admin/subscriptions.service';


@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {

  subscriptions: any[] = [];

  constructor(private subscriptionsService: SubscriptionsService){}

  ngOnInit(): void {
    this.getSubscriptions();
  }

  getSubscriptions(){
    this.subscriptionsService.getAllSubscriptions().subscribe((res: any)=>{
      this.subscriptions = res.data;
      
    })
  }

}

<div class="medworld-background-container">
  <div class="form-conatiner">
    <div class="medworld-form-container">
      <div class="logo-container">
        <img
          loading="lazy"
          alt=""
          src="./../../assets/images/logomedworld32full.svg"
        />
      </div>
      <div *ngIf="!emailSent" class="forgot-password-container">
        <div class="medworld-label">{{ "FORGOT_PASSWORD" | translate }}</div>
        <form #forgotForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="medworld-descrption" style="margin-top: 15px;">
            {{ "FORGOT_PASSWORD_DESC" | translate }}
          </div>

          <div class="input-element-container" style="width: 100%;">
            <label class="medworld-label-inputs" for="email"
              >{{ 'EMAIL' | translate }}</label
            >
            <div class="input-element">
              <img
                class="mail-icon"
                loading="lazy"
                alt=""
                src="./../../assets/images/mail.svg"
              />
              <input
                [(ngModel)]="email"
                (input)="onUsernameChange()"
                type="email"
                class="form-control-input"
                id="email"
                name="userName"
                style="margin-top: 4px"
                placeholder="{{ 'Enter_Email' | translate }}"
                required
                #userNameField="ngModel"
              />
            </div>
            <!-- Display error message if the field is touched and has errors -->
           
          </div>
          

          <div
            *ngIf="showError"
            class="error-message"
            style="display: flex; align-items: center"
          >
            <img
              class="error-icon"
              loading="lazy"
              alt=""
              src="./assets/images/alert.svg"
            />
            <span style="margin-left: 10px">{{ errorMessage | translate }}</span>
          </div>
          
          <button class="button button-primary mt-3" type="submit">
            {{ "FORGOT_PASSWORD" | translate }}
          </button>
          <button class="button button-inverse"  [routerLink]="['/login']"  type="submit">
            {{ "BACK_TO_LOGIN" | translate }}
          </button>
        </form>
       
      </div>
      <div *ngIf="emailSent" class="forgot-password-container">
        <div class="medworld-label">{{ "EMAIL_SENT_SUCCESSFULLY" | translate }}</div>
        <form #forgotForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="medworld-descrption">
            {{ "EMAIL_SENT_DESC" | translate }}
          </div>
          <div class="email-sent-icon">
            <img
            style="display: block; margin: auto;"
            loading="lazy"
            alt=""
            src="./../../assets/images/email-sent.svg"
          />

          </div>
          <button class="button button-inverse"  [routerLink]="['/login']"  type="submit">
            {{ "BACK_TO_LOGIN" | translate }}
          </button>
        </form>
       
      </div>
    </div>
  </div>

  <div class="copyright">{{ "COPYRIGHT" | translate }}</div>
</div>
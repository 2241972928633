import { LocalStorageService } from './../../../../services/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterLinkWithHref } from '@angular/router';
import { Logger } from 'html2canvas/dist/types/core/logger';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-add-oem-network',
  templateUrl: './add-oem-network.component.html',
  styleUrls: ['./add-oem-network.component.scss']
})
export class AddOemNetworkComponent implements OnInit {

  oemInfo: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddOemNetworkComponent>,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
   }

  ngOnInit(): void {
    this.getAccountType();
  }

  oemForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    companyName: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    role: ['OEM'],
    distributorId: [this.localStorageService.getItem('distributorId')],
    accountType: ['', Validators.required],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    network: true
  });

  getAccountType() {
    this.authService.getAccounttypes('admin').subscribe((data: any) => {
      const adminAccountType = data.data.find((account: any) => account.accountType.name === 'admin');
      if (adminAccountType) {
        const adminAccountTypeId = adminAccountType.id as number;
        this.oemForm.get('accountType')?.setValue(adminAccountTypeId as never);
      } else {
        console.error('Admin account type not found.');
      }
    });
  }

  save() {
      this.oemInfo = this.oemForm.value;
      this.addNewOEM();
      this.oemForm.reset();

  }
  
  checkFormValidity(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        console.log(`${field} is invalid: ${control.invalid}`);
      } else if (control instanceof FormGroup) {
        this.checkFormValidity(control);
      }
    });
  }
  

  addNewOEM() {
    console.log('Adding OEM');
    if(this.oemForm.get('distributorId')?.value){
      this.authService.signup(this.oemInfo).subscribe(
        (res: any) => {
          console.log('From signup API', res);
        },
        (err) => {
          console.log('Signup failed', err);
        }
      );
      this.dialogRef.close();
    }
    else{
      const obj = {
        active: true,
        companyName: this.oemForm.get('companyName')?.value,
        email: this.oemForm.get('email')?.value,
        phone: this.oemForm.get('phoneNumber')?.value,
        address: '',
      }
      this.dialogRef.close(obj);
    }
  }

  cancel(){
    this.dialogRef.close()
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalog } from 'src/app/models/oem-models';
import { OEMUrls } from 'src/app/utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private http: HttpClient) {}

  public createCatalog(catalog: Catalog): Observable<any> {
    return this.http.post<any>(OEMUrls.catalog, catalog);
  }

  public updateCatalog(catalogId: string, catalog: Catalog): Observable<any> {
    return this.http.put<any>(`${OEMUrls.catalog}?id=${catalogId}`, catalog);
  }

  public getDistributorCatalogs(distributorId: string,pageNumber: number = 0, size: number = 10, contains: string = ''): Observable<any> {
    return this.http.get<any>(`${OEMUrls.catalog}/distributor/${distributorId}?pageNumber=${pageNumber}&size=${size}&contains=${contains}`);
 
  }

  public getCatalogs(oemId: string, pageNumber: number = 0, size: number = 10, contains: string = ''): Observable<any> {
    return this.http.get<any>(`${OEMUrls.catalog}?id=${oemId}&PageNumber=${pageNumber}&size=${size}&contains=${contains}`);
}

  public deleteCatalog(catalogId: string): Observable<any> {
    return this.http.delete<any>(`${OEMUrls.catalog}?id=${catalogId}`);
  }
}

import { Component, Input, OnInit ,ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import Swal from 'sweetalert2';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-branch-information',
  templateUrl: './branch-information.component.html',
  styleUrls: ['./branch-information.component.scss']
})
export class BranchInformationComponent implements OnInit {
  @Input() branchInformationForm!: FormGroup;
  @Input() isEdit: boolean = false;
  @Output() branchAdded = new EventEmitter<number>();

  columns: ListColumn[] = [
    { name: 'Sr.no', property: 'sNo', visible: true, isModelProperty: false },
    { name: 'Company Name', property: 'companyName', visible: true, isModelProperty: true, isText: true },
    { name: 'Brand Name', property: 'brandName', visible: true, isModelProperty: true, isText: true },
    { name: 'Address', property: 'address', visible: true, isModelProperty: true, isText: true },
    { name: 'Contact Person', property: 'contactPerson', visible: true, isModelProperty: true, isText: true },
    { name: 'Phone Number', property: 'contactPhoneNumber', visible: true, isModelProperty: true, isText: true },
    { name: 'Email', property: 'contactEmail', visible: true, isModelProperty: true, isText: true },
    {
      name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];

  data: any[] = [];
  branchForm!: FormGroup;
  actionType: any;
  index: number = 0;
  isMore: boolean = false;
  isProceed: boolean = false;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef,private sweetAlertService: SweetAlertService) {}

  ngOnInit(): void {
    this.initializeBranchForm();
    this.data = this.branchInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
    if(this.data.length>0){
      this.isMore=true
     }
   
  }

  initializeBranchForm() {
    this.branchForm = this.fb.group({
      id: new FormControl(null),
      active: new FormControl(null),
      createdBy: new FormControl(null),
      createdOn: new FormControl(null),
      modifiedBy: new FormControl(null),
      modifiedOn: new FormControl(null),
      companyName: new FormControl(null),
      brandName: new FormControl(null),
      address: new FormControl(null),
      contactPerson: new FormControl(null),
      contactPhoneNumber: new FormControl(null),
      contactEmail: new FormControl(null),
      leadId: new FormControl(null),
    });
  }

  addBranchInformation() {
    if (!this.areAllPropertiesNull(this.branchForm.value)) {
      if (this.actionType === MweConstants.editAction) {
        this.branchInformationArray().at(this.index).patchValue(JSON.parse(JSON.stringify(this.branchForm.value)));
        if(this.isEdit){
          this.branchAdded.emit(2);
          }
      } else {
        this.branchInformationArray().push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          companyName: new FormControl(this.branchForm.get('companyName')?.value),
          brandName: new FormControl(this.branchForm.get('brandName')?.value),
          address: new FormControl(this.branchForm.get('address')?.value),
          contactPerson: new FormControl(this.branchForm.get('contactPerson')?.value),
          contactPhoneNumber: new FormControl(this.branchForm.get('contactPhoneNumber')?.value),
          contactEmail: new FormControl(this.branchForm.get('contactEmail')?.value),
          leadId: new FormControl(null),
        }));
        if(this.isEdit){
          this.branchAdded.emit(1);
          }
      }
      this.data = this.branchInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
      this.branchForm.reset();
      this.isProceed = false;
      this.isMore = true;
      this.actionType = null;
    }
  }

  areAllPropertiesNull(obj: any): boolean {
    for (const key in obj) {
      if (!(obj[key] === null || obj[key] === '') && !(key === 'id' || key === 'active' || key === 'createdBy' || key === 'createdOn' || key === 'modifiedBy' || key === 'modifiedOn')) {
        return false;
      }
    }
    return true;
  }
  toggleIsProceed() {
    this.isProceed = true;
    this.actionType=null;
    this.branchForm.reset();
  }
  
  toggleCancel(){
    this.isProceed = false;
  }

  selectedAction(event: any) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].companyName === event.row.companyName &&
          this.data[i].address === event.row.address &&
          this.data[i].contactPerson === event.row.contactPerson &&
          this.data[i].contactPhoneNumber === event.row.contactPhoneNumber &&
          this.data[i].contactEmail === event.row.contactEmail) {
        this.index = i;
        break;
      }
    }
    switch (event.action) {
      case MweConstants.editAction:
        this.isProceed = true;
        this.actionType = MweConstants.editAction;
        this.branchForm.patchValue(event.row);
        break;
      case MweConstants.deleteAction:
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.branchInformationArray().removeAt(this.index);
        this.branchForm.reset();
        this.actionType = null;
        this.data = this.branchInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
        if(this.data.length==0){
          this.isMore=false
        }
        if(this.isEdit){
          this.branchAdded.emit(3);
          }
          else{
            this.sweetAlertService.showSuccess('', 'Deleted successfully !!');
          }
        this.cdr.detectChanges();
            
          }
          else {
            Swal.fire(
              'Failed!',
              'Item could not be deleted.',
              'error'
            );
          }

        });
            
       
        
        break;
        
        
     
      default:
        break;
    }
  }

  branchInformationArray() {
    return this.branchInformationForm.get('leadBranchInfo') as FormArray;
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}

<app-website-header></app-website-header>
<div class="signup-container">
  <div class="background-container">
    <div class="signup-form-header-container">
      <div class="signup-form-logo">
        <img src="./../../../assets/images/Factory.svg" />
      </div>
      <div class="signup-form-banner-container">
        <div>
          <span style="font-weight:600;font-size:24px;color:#0d87c8">Exhibit with Us</span><br />
          <span><b>Sign up and showcase your products, Hire new Distributors & receive international leads.</b></span>
        </div>
      </div>
    </div>
    <div class="row signup-Page">
      <div class="col-lg-7 col-md-12 col-sm-12 signup-left" *ngIf="emailSent" style="width: 63%;">
        <form class="form-conatiner" (ngSubmit)="submitForm(form)" #form="ngForm">
          <div class="salutation">
            <p class="top-text">Salutation</p>
            <input type="radio" id="mr" name="salutation" value="mr" /><label for="mr">Mr.</label>&nbsp;
            <input type="radio" id="ms" name="salutation" value="ms" /><label for="ms">Ms.</label>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">First Name<span style="color: red">*</span></label><br />
              <input class="input-field" type="text" [(ngModel)]="OemSignup.firstName" name="firstName" required
                #firstNameField="ngModel" (keypress)="onlyText($event)" />
              <div *ngIf="(firstNameField?.touched && firstNameField?.errors?.['required']) || (firstNameField?.errors?.['required'] && isProceed)" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                style="margin-left: 5px"  class="error-message">First name is required.</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Last Name <span style="color: red">*</span></label><br />
              <input class="input-field" type="text" [(ngModel)]="OemSignup.lastName" name="lastName" required
                #lastNameField="ngModel" (keypress)="onlyText($event)" />
              <div *ngIf="(lastNameField?.touched && lastNameField?.errors?.['required'] || (lastNameField?.errors?.['required'] && isProceed) )" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                  style="margin-left: 5px">Last name is required.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Email<span style="color: red">*</span></label><br />
              <input class="input-field" type="email" style="height: 40px; padding: 0px 5px 0px 15px; width: 300px"
                [(ngModel)]="OemSignup.email" name="email" required #emailField="ngModel"
                (ngModelChange)="emailValidation(OemSignup.email)" autocomplete="new-password" />
              <div *ngIf="(emailField?.touched && emailField?.errors?.['required'] || (emailField?.errors?.['required'] && isProceed))" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Email is required.</span>
              </div>
              <div *ngIf="emailInvalid && emailField?.value" class="error-message" style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                <span style="margin-left: 5px" class="error-message">
                  Invalid Email.</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12 inside-fields">


              <label class="label-styles" for="">Phone<span style="color: red">*</span></label><br />
              <div class="row" style="display: flex; ">
                <div style="display: flex ; flex-direction:column ">
                           <div class="input-group   rounde outline-none" style="border-radius: 6px;">
                     
                        <input class="input-field" placeholder="IN" style="border-radius: 6px 0px 0px 6px; width: 60px;"
                          aria-label="Select your country code" [value]="countryCode"
                          [(ngModel)]="OemSignup.countryCode" name="countryCode" required
                          (input)="filterOptions(OemSignup.countryCode)"
                          (click)="filterOptions(OemSignup.countryCode)" (keypress)="onlyText2($event)" (focus)="showOptions = true" autocomplete="new-password"   #countryCodeInput  >
                        <div style="width: 75px; outline: none;;" class="custom-dropdown" *ngIf="showOptions">
                          <ul>
                            <li class="no-outline" style="width: 60px;" *ngFor="let code of filteredCountryCodes"
                              (click)="selectOption(code)">{{ code.countryCode | uppercase }}</li>
                          </ul>
                        </div>
                     
                      

                      <input type="text" class="border-0 no-interaction text-center no-outline" style="width: 60px;"
                        placeholder="+91" [(ngModel)]="OemSignup.phoneCode" name="phoneCode" required #phonecodefield="ngModel">
                      <input type="text" class=" border-0 no-interaction no-outline" style="width: 10.83rem ;border-radius: 0px 6px 6px 0px"
                        [(ngModel)]="OemSignup.phoneNumber" name="phoneNumber"  required
                        #phonenumberfield="ngModel" title="Enter a valid mobile number"
                        (ngModelChange)="phonenumbervalidation(OemSignup.phoneNumber)" (keypress)="numberOnly($event)">
                    </div>
                 
                    <div *ngIf="(phonecodefield?.touched && phonecodefield?.errors?.['required'] || phonecodefield?.errors?.['required'] && isProceed  )"  class="error-message" style="display: flex; align-items: center;"
                    style="display: flex; align-items: center; ">
                    <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                      style="margin-left: 5px" class="error-message">phone Code is required.</span></div>

                  <div *ngIf="(phonenumberfield?.touched && phonenumberfield?.errors?.['required'] || phonenumberfield?.errors?.['required'] && isProceed && !phonecodefield?.errors?.['required'] )" class="error-message"
                    style="display: flex; align-items: center; ">
                    <img class="error-icon" loading="lazy" alt="" src="./../../../assets/images/alert.svg" /><span
                      style="margin-left: 5px" class="error-message">Phone Number is required.</span>
                  </div>
                  <!-- <div *ngIf="!validNumber" class="error-message" style="display: flex; align-items: center">
                    <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                    <span style="margin-left: 10px" class="error-message">
                      Please Enter valid phone number.</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Company Name <span style="color: red">*</span></label><br />
              <input class="input-field" type="text" [(ngModel)]="OemSignup.companyName" name="companyName" required
                #companyNameField="ngModel" (keypress)="onlyText($event)" />
              <div *ngIf="(companyNameField?.touched && companyNameField?.errors?.['required'] || companyNameField?.errors?.['required'] && isProceed)" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Company name is required.</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Website URL<span style="color: red">*</span></label><br />
              <input class="input-field" type="text" [(ngModel)]="OemSignup.website" name="website" required
                #websiteField="ngModel" (keyup)="validateUrl($event)"/>
                <div *ngIf="websiteField?.value && urlInvalid" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Please enter valid URL.</span>
              </div>

              <div *ngIf="(websiteField?.touched && websiteField?.errors?.['required'] || websiteField?.errors?.['required'] && isProceed)" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Website URL is required.</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Password<span class="required-asterisk">*</span></label><br />
              <div class="d-flex">
                <input
                  class="input-field"
                  [type]="showPassword ? 'text' : 'password'"
                  name="password"
                  required
                  [(ngModel)]="OemSignup.password"
                  #passwordField="ngModel"
                  (ngModelChange)="passwordValidation(OemSignup.password)"
                  (cut)="preventCopyPaste($event)"
                  (copy)="preventCopyPaste($event)"
                  (paste)="preventCopyPaste($event)"
                  (contextmenu)="preventContextMenu($event)"
                  autocomplete="new-password"
                  style="border-radius:6px; width: 100%;"
                />
                <button
                  type="button"
                  class="eye-button bg-transparent"
                  (click)="showPassword = !showPassword"
                  style="border:none; position: absolute;"
                >
                  <img
                    class="eye-icon"
                    loading="lazy"
                    alt=""
                    [src]="showPassword ? './../../../../assets/images/Eye.svg' : './../../../../assets/images/Blind.svg'"
                  />
                </button>
              </div>
            
              <div *ngIf="(passwordField?.touched && passwordField?.errors?.['required'] || passwordField?.errors?.['required'] && isProceed)" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                <span style="margin-left: 5px" class="error-message">Password is required.</span>
              </div>
              <div *ngFor="let error of passwordValidation(OemSignup.password)" class="error-message"
                style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
                <span style="margin-left: 5px" class="error-message">
                  {{error}}</span>
              </div>
            </div>
            
            <div class="col-md-6 col-sm-12 col-12 inside-fields">
              <label class="label-styles" for="">Confirm Password<span style="color: red">*</span> </label><br />
              <div class="d-flex">
              <input class="input-field" [type]="showConfirmPassword ? 'text' : 'password'" name="password" name="confirmPassword" required
                [(ngModel)]="OemSignup.confirmPassword" #confirmPasswordField="ngModel"
                (cut)="preventCopyPaste($event)"
                (copy)="preventCopyPaste($event)"
                (paste)="preventCopyPaste($event)"
                (contextmenu)="preventContextMenu($event)"
                style="border-radius:6px; width: 100%;" />
                <button
                type="button"
                class="eye-button  bg-transparent"
                (click)="showConfirmPassword = !showConfirmPassword"
                
                style="border:none; position: absolute;"
              >
              <img
              class="eye-icon"
              loading="lazy"
              alt=""
              [src]="showConfirmPassword ? './../../../../assets/images/Eye.svg' : './../../../../assets/images/Blind.svg'"
            /></button>
              </div>
              <div *ngIf="(confirmPasswordField?.touched && confirmPasswordField?.errors?.['required'] || confirmPasswordField?.errors?.['required'] && isProceed)"
                class="error-message" style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Confirm password is required.</span>
              </div>
              <div *ngIf="OemSignup.password !== OemSignup.confirmPassword && confirmPasswordField?.touched && !confirmPasswordField?.errors?.['required'] "
                class="error-message" style="display: flex; align-items: center">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /><span
                  style="margin-left: 5px" class="error-message">Passwords don't match.</span>
              </div>
            </div>
          </div>
          <div class="additional-content">
            <input type="checkbox" id="sampleCheckbox" name="confirmPassword" required
              (change)="updateSignupButtonState($event)" />
            <label for="sampleCheckbox">I agree to Medworld Expo's
              <a routerLink="/terms-and-conditions" style="color: #0d87c8">Terms of Use</a> and
              <a routerLink="/privacy-policy" style="color: #0d87c8">Privacy policy</a>
            </label><br />
            <button type="submit" class="button-styles button-primary"
              [style.opacity]="isSignupButtonEnabled ? '1' : '0.5'" [disabled]="!isSignupButtonEnabled">
              Sign Up <img src="./assets/images/arrow-right.svg" alt="" />
            </button>
          </div>
          <div *ngIf="badRequest" class="error-message" style="display: flex; align-items: center">
            <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />
            <span style="margin-left: 5px" class="error-message">{{ errorMessage }}</span>
          </div>
          <div class="signin-link">
            <p>Already have an account? <a routerLink="/login" class="links">Sign in</a></p>
          </div>
        </form>
      </div>
      <div class="col-lg-7 col-md-12 col-sm-12 signup-left" *ngIf="!emailSent">
        <div class="verification-container">
          <div class="verification-text">
            <p>Verify your email</p>
          </div>
          <div class="verification-message">
            <p>Hi {{ OemSignup.lastName }}, Please verify your email address by clicking the link sent to<br />{{ OemSignup.email }}
            </p>
          </div>
          <div class="resend-button">
            <button (click)="resendVerificationMail()" class="button-styles button-primary">Resend Verification Email</button>
          </div>
          <div class="support-email">
            <p>Questions? Email us at<br /><span>SupportMedworldexpo.com</span></p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-sm-12 signup-right">
        <div class="top-right">
          <p class="top-text" style="color:#0d87c8">Why Choose Medworld Expo</p>
          <div class="signup-leads">
            <img src="assets/images/world-svgrepo-com.svg" alt="" height="45px" width="45px" />
            <div class="text">
              <p class="role"><b>Global Reach & Exposure</b></p>
            </div>
          </div>
          <div class="signup-leads">
            <img src="assets/images/protest-svgrepo-com.svg" alt="" height="45px" width="45px" />
            <div class="text">
              <p class="role"><b>Seamless Product Demonstrations</b></p>
            </div>
          </div>
          <div class="signup-leads">
            <img src="assets/images/investment.svg" alt=""  height="45px" width="45px"/>
            <div class="text">
              <p class="role"><b>Cost-Effective Solution</b></p>
            </div>
          </div>
          <div class="signup-leads">
            <img src="assets/images/organization-chart.svg" alt="" height="45px" width="45px" />
            <div class="text">
              <p class="role"><b>Advanced Networking Opportunities</b></p>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <img class="oem-bottom-img" src="./../../../../assets/images/Frame-signup.svg" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ourmission-section d-flex justify-content-center mt-5 mb-3 align-items-center p-0 gap-4">

  <div class="mission-image">
    <img src="/assets/images/mission.svg" alt="mission image">

  </div>
  <div class="mission-text">
    <span class="mission-title"><strong>Our Mission</strong></span>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;</span><li style=list-style-type:none>To revolutionize the health-care industry by providing a premier digital platform for seamless interaction.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>Commit to empowering Manufacturers, Distributors, and Health Care Providers with cutting-edge tools for growth and connectivity.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;</span><li style=list-style-type:none>Dedicated to nurturing a vibrant ecosystem where the medical community thrives together.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>To democratize access to medical solutions, driving forward the industry with integrity, innovation, and insight.</li>

    </div>
    <div style="display: flex; ">
      <span><img src="/assets/images/check.svg">&nbsp;&nbsp;&nbsp;</span><li style=list-style-type:none>Facilitate career advancement by offering a platform for job opportunities and professional networking within the medical industry.</li>

    </div>
    
  </div>
</div>
<div class="about">
  <div class="image-container">
    <img src="assets/images/Rectangle 325.svg" alt="About Image" class="full-width-image" />
    <div class="content-overlay">
      <div class="heading">The Med World Expo Difference</div>
      <div class="paragraph">It's always good to know that someone has your back. <br>Whether you have a question, need advice or just want to check in, we offer incomparable customer supportShare your company's biggest challenges with us,and we'll do everything possible to deliver a tailored service that helps you reach your business goals.</div>
    </div>
  </div>
</div>



<app-website-footer></app-website-footer>
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadsService } from 'src/app/services/super-admin/leads.service';
import { SpinnerService } from 'src/app/services/shared/spinner.service';
import { SweetAlertService } from 'src/app/services/sweetalert.service';


@Component({
  selector: 'app-add-leads',
  templateUrl: './add-leads.component.html',
  styleUrls: ['./add-leads.component.scss']
})
export class AddLeadsComponent implements OnInit {
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabEvent = new EventEmitter<number>();
  isLaptopView: boolean = false;
  leadsForm!: FormGroup;
  leadData: any;
  isOEMSelected: boolean = false;
  isEdit: boolean = false;
  isView: boolean = false;
  isSave: boolean = false;
  isNotGet: boolean = false;
  isDelete: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private leadsService: LeadsService,
    private route: ActivatedRoute,
    private  spinnerService : SpinnerService,
    private sweetAlertService: SweetAlertService
  ) { }

  async ngOnInit(): Promise<void> {
    this.initializeLeadsForm();
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      
      if (id) {
        this.getUserById(id);
        this.isEdit = true;
      } else {
        this.initializeEmptyLeadsForm();
      }
    });
   
    this.callOem();
    this.route.queryParamMap.subscribe(queryParams => {
      const isView = queryParams.get('isView') === 'true';
      
      if (isView) {
        this.leadsForm.disable();
        this.isView = true;
      }
    });
  }
  initializeEmptyLeadsForm() {
    this.leadsForm.reset();
    this.initializeLeadsForm();
  }

  handleBranchAdded(event: number) {
    if (event === 1) {
      this.isSave=true;
      this.isNotGet=false;
      this.save();
    }
    if (event === 2) {
      this.isNotGet=true;
      this.isSave=false;
      this.save();
    }
    if(event === 3){
      this.isNotGet=true;
      this.isDelete=true;
      this.save();
    }
  }

  getUserById(id: string) {
    this.spinnerService.showSpinner();
    this.leadsService.getLeadById(id).subscribe(
      response => {
        this.leadData = response.data;
        this.populateLeadsForm(this.leadData);
        setTimeout(() => {
          this.spinnerService.hideSpinner();
        }, 10000);  
      },
      error => {
        console.error('Error:', error);
        this.spinnerService.hideSpinner();
      }
    );
  }
  


  populateLeadsForm(leadData: any) {
    
    const establishedInDate = leadData.lead.establishedIn ? new Date(leadData.lead.establishedIn) : null;
    
    this.leadsForm.patchValue({
      lead: {
        id: leadData.lead.id || null,
        active: leadData.lead.active || true,
        createdBy: leadData.lead.createdBy || null,
        createdOn: leadData.lead.createdOn || null,
        modifiedBy: leadData.lead.modifiedBy || null,
        modifiedOn: leadData.lead.modifiedOn || null,
        leadSource: leadData.lead.leadSource || '',
        leadState: leadData.lead.leadState || '',
        addedBy: leadData.lead.addedBy || '',
        accountType: leadData.lead.accountType || '',
        name: leadData.lead.name || '',
        companyName: leadData.lead.companyName || '',
        brandName: leadData.lead.brandName || '',
        establishedIn: establishedInDate,
        numberOfEmployees: leadData.lead.numberOfEmployees || 0,
        websiteUrl: leadData.lead.websiteUrl || '',
        city: leadData.lead.city || '',
        state: leadData.lead.state || '',
        continent: leadData.lead.continent || '',
        country: leadData.lead.country || '',
        description: leadData.lead.description || '',
        address: leadData.lead.address || '',
        zipCode: leadData.lead.zipCode || 0,
        crCopy: leadData.lead.crCopy || '',
        businessLicense: leadData.lead.businessLicense || '',
        europeanCertificate: leadData.lead.europeanCertificate || '',
        otherCertificates: leadData.lead.otherCertificates || '',
        email:leadData.lead.email || ''
      }
    });
   
    
    
   
    
    const leadBranchInfoArray = this.leadsForm.get('leadBranchInfo') as FormArray;
    leadBranchInfoArray.clear();
    leadData.leadBranchInfo.forEach((branch: any) => {
      leadBranchInfoArray.push(this.fb.group(branch));
    });
  
    
    const leadContactArray = this.leadsForm.get('leadContact') as FormArray;
    leadContactArray.clear();
    leadData.leadContact.forEach((contact: any) => {
      leadContactArray.push(this.fb.group(contact));
    });
  
    
    const leadFinancialInfoArray = this.leadsForm.get('leadFinancialInfo') as FormArray;
    leadFinancialInfoArray.clear();
    leadData.leadFinancialInfo.forEach((financial: any) => {
      leadFinancialInfoArray.push(this.fb.group(financial));
    });
  
    
    const leadProductInfoArray = this.leadsForm.get('leadProductInfo') as FormArray;
    leadProductInfoArray.clear();
    leadData.leadProductInfo.forEach((product: string) => {
      leadProductInfoArray.push(new FormControl(product));
      
     
    });
    
    
  
    
    const leadRemarksArray = this.leadsForm.get('leadRemarks') as FormArray;
    leadRemarksArray.clear();
    leadData.leadRemarks.forEach((remark: any) => {
      leadRemarksArray.push(this.fb.group(remark));
    });
  
    
    const leadDistributorInfoGroup = this.leadsForm.get('leadDistributorInfo') as FormGroup;
    
   
    const existingDistributorsArray = leadDistributorInfoGroup.get('existingDistributors') as FormArray;
    existingDistributorsArray.clear();
    leadData.leadDistributorInfo.existingDistributors.forEach((distributor: string) => {
      existingDistributorsArray.push(new FormControl(distributor));
    });
  
    
    const newDistributorsArray = leadDistributorInfoGroup.get('newDistributors') as FormArray;
    newDistributorsArray.clear();
    leadData.leadDistributorInfo.newDistributors.forEach((distributor: any) => {
      newDistributorsArray.push(this.fb.group(distributor));
    });




  
    
  }
  
  
  callOem() {
    this.leadsForm.get('lead.accountType')?.valueChanges.subscribe(value => {
      this.isOEMSelected = value === 'OEM';
    });
  }

initializeLeadsForm() {
    this.leadsForm = this.fb.group({
      lead: this.fb.group({
        id: [null],
        active: [true],
        createdBy: [null],
        createdOn: [null],
        modifiedBy: [null],
        modifiedOn: [null],
        leadSource: [''],
        leadState: [''],
        addedBy: [''],
        accountType: [null,[Validators.required]],
        name: [''],
        companyName: [''],
        brandName: [''],
        establishedIn: [''],
        numberOfEmployees: [null],
        websiteUrl:  [null,[Validators.pattern(/^(https:\/\/|www\.).*/)]],
        city: ['' ],
        state: [''],
        continent: [''],
        country: [''],
        description: [''],
        address: [''],
        zipCode: [null],
        crCopy: [''],
        businessLicense: [''],
        europeanCertificate: [''],
        otherCertificates: [''],
        email:[null,[Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      }),
      leadBranchInfo: this.fb.array([
        {}
      ]),
      leadContact: this.fb.array([{}]),
      leadFinancialInfo: this.fb.array([{}]),
      leadProductInfo: this.fb.array([]),
      leadRemarks: this.fb.array([{}]),
      leadDistributorInfo: this.fb.group({
        existingDistributors: this.fb.array([]),
        newDistributors: this.fb.array([])}),

      leadOEMInfo: this.fb.group({
        existingOEMs: this.fb.array([]),
        newOEMs: this.fb.array([])
      }),
  
    });
  }

  get productInfo() {
    return this.leadsForm.get('leadProductInfo') as FormArray;
  }
  get leadDistributorInfo() {
    return this.leadsForm.get('leadDistributorInfo') as FormGroup;
  }
  get leadOEMInfo(){
    return this.leadsForm.get('leadOEMInfo') as FormGroup;
  }
  get leadProductInfo() {
    return this.leadsForm.get('leadProductInfo') as FormArray;
  }

  onTabChange(tab: number) {
    const emailControl = this.leadsForm.get('lead.email');
    const accountTypeControl = this.leadsForm.get('lead.accountType');
  
    if (emailControl?.invalid && accountTypeControl?.invalid) {
      
      emailControl.markAsTouched();
      accountTypeControl.markAsTouched();

      
      return;
    }
    this.selectedTabIndex = tab;
    this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    this.selectedTabEvent.emit(this.selectedTabIndex);
  }

  navigateNext() {
    const emailControl = this.leadsForm.get('lead.email');
    const accountTypeControl = this.leadsForm.get('lead.accountType');
  
    if (emailControl?.invalid && accountTypeControl?.invalid) {
      emailControl.markAsTouched();
      accountTypeControl.markAsTouched();
      return;
    }
  
    const maxTabIndex = 7;
    if (this.selectedTabIndex < maxTabIndex) {
      this.selectedTabIndex++;
      this.selectedTabEvent.emit(this.selectedTabIndex);
      this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    }
  }
  

  backtoleads() {
    this.router.navigate(['/dashboard/super-admin/leads']);
    
  }
  save() {
    const payload = this.leadsForm.value;
    payload.leadBranchInfo = this.filterEmptyArrayObjects(payload.leadBranchInfo);
    payload.leadContact = this.filterEmptyArrayObjects(payload.leadContact);
    payload.leadFinancialInfo = this.filterEmptyArrayObjects(payload.leadFinancialInfo);
    payload.leadRemarks = this.filterEmptyArrayObjects(payload.leadRemarks);
    // payload.leadDistributorInfo.existingDistributors = this.filterEmptyArrayObjects(payload.leadDistributorInfo.existingDistributors);
    // payload.leadDistributorInfo.newDistributors = this.filterEmptyArrayObjects(payload.leadDistributorInfo.newDistributors);
  
    if (this.isEdit) {
      this.updateLead(payload);
    } else {
      this.addLead(payload);
    }
  }
  
  filterEmptyArrayObjects(array: any[]): any[] {
    return array.filter(obj => Object.values(obj).some(value => value !== null && value !== ''));
  }
  
  addLead(payload: any) {
    this.leadsService.addLead(payload).subscribe(
      response => {
        this.sweetAlertService.showSuccess('', 'Lead added successfully !!');
        this.backtoleads();
      },
      error => {
        this.sweetAlertService.showError('', 'Failed to add lead !!');
        if (error.status === 404) {
          console.error('Not Found:', error.error);
        } else {
          console.error('An error occurred:', error);
        }
      }
    );
  }
  
  updateLead(payload: any) {
    this.leadsService.updateLead(payload).subscribe(
      response => {
        if(this.isDelete){
          this.sweetAlertService.showSuccess('', 'Lead Deleted successfully !!');

        }
        else if(!this.isSave){
          this.sweetAlertService.showSuccess('', 'Lead updated successfully !!');
        }
        else{
          this.sweetAlertService.showSuccess('', 'Lead saved successfully !!');
          
        }
        
        
      },
      error => {
        this.sweetAlertService.showError('', 'Failed to update lead !!');
        if (error.status === 404) {
          console.error('Not Found:', error.error);
        } else {
          console.error('An error occurred:', error);
        }
      }
    );
  }
  

  back() {
    if (this.selectedTabIndex > 0) {
      this.selectedTabIndex--;
      this.selectedTabEvent.emit(this.selectedTabIndex);
      this.router.navigate(['/your-route-path'], { queryParamsHandling: 'preserve' });
    }
  }

  cancel() {
    this.router.navigate(['/dashboard/super-admin/leads']);
  }
}


import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import { concatMap, takeWhile } from 'rxjs';
import { MasterDataService } from 'src/app/services/master-data.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { SpecificationService } from 'src/app/services/shared/product-settings/specification.service';

@Component({
  selector: 'app-brand-product-filter',
  templateUrl: './brand-product-filter.component.html',
  styleUrls: ['./brand-product-filter.component.scss']
})
export class BrandProductFilterComponent {
  @Input() data: any[] = [];
  @Input() filterColumns: any;
  @Output() filteredData = new EventEmitter<any[]>();

  dataObj : any [] = [];
  filteredObj : any [] = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  showNoRecordsFounds: boolean = true;
  filterObject: any;
  countries!: any[];
  states!: any[];
  cities!: any[];
  brands!: any[];
  specialities!: any[];
  products!: any[];
  specifications!: any[];
  shapes!: any[];
  models!: any[];
  sizes!: any[];
  sampleTypes!: any[];
  applications!: any[];
  textSpecifications: any;

  brandId : string = '';
  brandName : string = '';

  filterForm: FormGroup = new FormGroup({
    keywords: new FormControl(null),
    countries: new FormControl(null),
    states: new FormControl(null),
    cities: new FormControl(null),
    zipCode: new FormControl(null),
    brands: new FormControl(null),
    year: new FormControl(null),
    amountFrom: new FormControl(0),
    amountTo: new FormControl(14500),
    specialities: new FormControl(null),
    models: new FormControl(null),
    shapes: new FormControl(null),
    sampleTypes: new FormControl(null),
    sizes: new FormControl(null),
    applications: new FormControl(null),
  });

  filterControls = ['keywords', 'countries', 'states', 'cities', 'zipCode', 'brands','categories'];
  constructor(
    private readonly masterDataService: MasterDataService,
    private oemService : OemService,
    private allProductService: AllProductService,
    private route: ActivatedRoute,
    private specificationService: SpecificationService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataObj = this.data;  
    console.log('dataobj', this.data);
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params !== undefined && params !== null && Object.entries(params).length > 0) {
        console.log('params brandId', params['brandId']);
        this.brandId = params['brandId'];
        this.brandName = params['brandName'];
      }
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilter();
    });

    this.loadCountries('');
    this.loadStates('');
    this.loadCities('');
    this.loadBrands();
    this.loadSpecialities();
    this.loadProducts(this.brandId);
    this.loadSpecifications();
    this.loadTextSpecifications();
  }

  applyFilter(): void {
    // console.log('apply filter', this.dataObj);
    let filtered = this.dataObj;
    const {keywords, countries, states, cities, zipCode, brands, year, specialities, models, shapes, sampleTypes, sizes, applications } = this.filterForm.value;
    console.log({keywords, countries, states, cities, zipCode, brands, specialities, models, shapes, sampleTypes, sizes, applications});
    
    if (keywords && keywords.length > 0) {
      console.log("fsdfsdfsd", keywords);
      console.log("aaaaa",filtered)
      const lowerCaseKeywords = keywords.map((keyword: string) => keyword.toLowerCase());
      filtered = filtered.filter(item =>
        (item.name && item.name.length > 0 && lowerCaseKeywords.some((keyword: any) => item.name.toLowerCase().includes(keyword))) ||
        (item.companyName && item.companyName.length > 0 && lowerCaseKeywords.some((keyword: any) => item.companyName.toLowerCase().includes(keyword))) ||
        (item.email && item.email.length > 0 && lowerCaseKeywords.some((keyword: any) => item.email.toLowerCase().includes(keyword))) ||
        (item.address && item.address.length > 0 && lowerCaseKeywords.some((keyword: any) => item.address.toLowerCase().includes(keyword))) ||
        (item.title && item.title.length > 0 && lowerCaseKeywords.some((keyword: any) => item.title.toLowerCase().includes(keyword))) ||
        (item.oemName && item.oemName.length > 0 && lowerCaseKeywords.some((keyword: any) => item.oemName.toLowerCase().includes(keyword))) ||
        (item.country && item.country.length > 0 && lowerCaseKeywords.some((keyword: any) => item.country.toLowerCase().includes(keyword))) ||
        (item.state && item.state.length > 0 && lowerCaseKeywords.some((keyword: any) => item.state.toLowerCase().includes(keyword))) ||
        (item.city && item.city.length > 0 && lowerCaseKeywords.some((keyword: any) => item.city.toLowerCase().includes(keyword))) ||
        (item.specifications && item.specifications.length > 0 && item.specifications.some((spec: { name: string; fromValue: string; }) => 
          (spec.name === "Model" && spec.fromValue.length > 0 && lowerCaseKeywords.some((keyword: string) => spec.fromValue.toLowerCase().includes(keyword))) ||
          (spec.name === "Size(in CM)" && spec.fromValue.length > 0 && lowerCaseKeywords.some((keyword: string) => spec.fromValue.toLowerCase().includes(keyword))) ||
          (spec.name === "Shape" && spec.fromValue.length > 0 && lowerCaseKeywords.some((keyword: string) => spec.fromValue.toLowerCase().includes(keyword))) ||
          (spec.name === "Application" && spec.fromValue.length > 0 && lowerCaseKeywords.some((keyword: string) => spec.fromValue.toLowerCase().includes(keyword))) ||
          (spec.name === "Sample Type" && spec.fromValue.length > 0 && lowerCaseKeywords.some((keyword: string) => spec.fromValue.toLowerCase().includes(keyword)))
        )
      )
      );
    }

    if (countries && countries.length > 0) {
      filtered = filtered.filter(item =>
        item.country && countries.some((country: string) => item.country.toLowerCase() === country.toLowerCase())
      );
      this.loadStates(countries);
    }

    if (states && states.length > 0) {
      filtered = filtered.filter(item =>
        item.state && states.some((state: string) => item.state.toLowerCase() === state.toLowerCase())
      );
      this.loadCities(states);
    }

    if (cities && cities.length > 0) {
      filtered = filtered.filter(item =>
        item.city && cities.some((city: string) => item.city.toLowerCase() === city.toLowerCase())
      );
    }

    if (zipCode && zipCode.length > 0) {
      filtered = filtered.filter(item => 
        item.zipCode && String(item.zipCode) === String(zipCode));
    }
    
    if (brands && brands.length > 0) {
      filtered = filtered.filter(item =>
        (item.name && brands.some((name: string) => item.name.toLowerCase() === name.toLowerCase())) ||
        (item.oemName && brands.some((name: string) => item.oemName.toLowerCase() === name.toLowerCase()))
      );
    }

    if (year && year.length > 0) {
      filtered = filtered.filter(item => 
        item.year && String(item.year) === String(year));
    }

    if (specialities && specialities.length > 0) {
      filtered = filtered.filter(item =>
        item.name && specialities.some((name: string) => item.name.toLowerCase() === name.toLowerCase())
      );
    }

    if (models && models.length > 0) {
      console.log('Filtering by models:', models);
      filtered = filtered.filter(item =>
        item.specifications && item.specifications.some((spec: { name: string; fromValue: string; }) => spec.name === "Model" && models.some((model: string) => {
          console.log('Comparing model:', model.toLowerCase(), 'with spec.fromValue:', spec.fromValue.toLowerCase());
          return spec.fromValue.toLowerCase() === model.toLowerCase();
        }))
      );
    }

    if (shapes && shapes.length > 0) {
      console.log('Filtering by shapes:', shapes);
      filtered = filtered.filter(item =>
        item.specifications && item.specifications.some((spec: { name: string; fromValue: string; }) => spec.name === "Shape" && shapes.some((shape: string) => {
          console.log('Comparing shape:', shape.toLowerCase(), 'with spec.fromValue:', spec.fromValue.toLowerCase());
          return spec.fromValue.toLowerCase() === shape.toLowerCase();
        }))
      );
    }

    if (sizes && sizes.length > 0) {
      console.log('Filtering by sizes:', sizes);
      filtered = filtered.filter(item =>
        item.specifications &&item.specifications.some((spec: { name: string; fromValue: string; }) => spec.name === "Size(in CM)" && sizes.some((size: string) => {
          console.log('Comparing size:', size.toLowerCase(), 'with spec.fromValue:', spec.fromValue.toLowerCase());
          return spec.fromValue.toLowerCase() === size.toLowerCase();
        }))
      );
    }
  
    if (sampleTypes && sampleTypes.length > 0) {
      console.log('Filtering by sample types:', sampleTypes);
      filtered = filtered.filter(item =>
        item.specifications && item.specifications.some((spec: { name: string; fromValue: string; }) => spec.name === "Sample Type" && sampleTypes.some((sampleType: string) => {
          console.log('Comparing sample type:', sampleType.toLowerCase(), 'with spec.fromValue:', spec.fromValue.toLowerCase());
          return spec.fromValue.toLowerCase() === sampleType.toLowerCase();
        }))
      );
    }

    if (applications && applications.length > 0) {
      console.log('Filtering by applications:', applications);
      filtered = filtered.filter(item =>
        item.specifications && item.specifications.some((spec: { name: string; fromValue: string; }) => spec.name === "Application" && applications.some((application: string) => {
          console.log('Comparing applications:', application.toLowerCase(), 'with spec.fromValue:', spec.fromValue.toLowerCase());
          return spec.fromValue.toLowerCase() === application.toLowerCase();
        }))
      );
    }

    if (!keywords && !countries && !states && !cities && !zipCode && !brands && !year && !specialities && !models && !shapes && !sampleTypes && !sizes && !applications) {
      filtered = this.dataObj;
    }

    console.log('data filter afteeeeeeeeeeer', filtered);
    this.filteredData.emit(filtered);
  }

  loadTextSpecifications(): void {
    this.allProductService.getTextSpecificationsByNames().subscribe(data => {
      if (data && data.data) {
        this.textSpecifications = data.data;
    
      if (this.textSpecifications) {
        console.log('textSpecifications', this.textSpecifications);
        this.models = this.textSpecifications["Model Number"] || [];
        this.sizes = this.textSpecifications["Size(in CM)"] || [];
        this.shapes = this.textSpecifications["Shape"] || [];
        this.sampleTypes = this.textSpecifications["Sample Type"] || [];
        this.applications = this.textSpecifications["Application"] || [];
      } else {
        console.log('textSpecifications is null');
      }

        console.log('applications', this.applications);
        
      }
    });
  }

  loadProducts(brandId : string) { 
    if(this.brandId) {
      this.allProductService.getAllProductsByOemId(brandId).subscribe({
        next: (response) => {
          console.log('products',response.data)
          this.products = response.data;
        },
        error: (error) => {
          console.error('Error fetching product info data:', error);
        }
      });
    }
  }

  loadSpecifications() { 
      this.specificationService.getAllSpecifications().subscribe({
        next: (response) => {
          console.log('specifications',response.data)
          this.specifications = response.data;
        },
        error: (error) => {
          console.error('Error fetching specifications info data:', error);
        }
      });
  }

  loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
      });
  }

  loadStates(countryName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', [countryName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', [countryName]);
        })
      )
      .subscribe(data => {
        allStates = allStates.concat(data.data);
        this.states = allStates;
      });
  }

  loadCities(stateName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCities: any[] = [];
    let morePages = true;

    this.masterDataService.getCities(pageNumber, pageSize, '', [stateName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', [stateName]);
        })
      )
      .subscribe(data => {
        allCities = allCities.concat(data.data);
        this.cities = allCities;
      });
  }

  loadBrands() {
    this.oemService.getBrandsList().subscribe({
      next: (response) => {
        this.brands = response.data; 
      },
      error: (error) => {
        console.error('Error fetching brand list data:', error);
      }
    });
  }

 
  loadSpecialities() {
    let pageNumber = 0;
    let pageSize = 100;
    let allSpecialities: any[] = [];
    let morePages = true;

    this.masterDataService.getSpecialities(pageNumber, pageSize, '')
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allSpecialities = allSpecialities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getSpecialities(pageNumber, pageSize, '');
        })
      )
      .subscribe(data => {
        allSpecialities = allSpecialities.concat(data.data);
        this.specialities = allSpecialities;
        console.log('allSpecialities', allSpecialities);
        
      });
  }

  resetFilters() {
    this.filterForm.reset();
    this.applyFilter();
  }

  add(controlName: string, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const currentValues = this.filterForm.get(controlName)?.value || [];
      this.filterForm.get(controlName)?.setValue([...currentValues, value.trim()]);
    }

    if (input) {
      input.value = '';
    }
  }

  remove(controlName: string, item: string | number): void {
    const control = this.filterForm.get(controlName);
    if (control) {
      const currentValues = control.value || [];
      const index = currentValues.indexOf(item);
      if (index >= 0) {
        currentValues.splice(index, 1);
        control.setValue(currentValues);
      }
      this.applyFilter();
    }
  }
}

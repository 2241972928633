export enum PlanType {
    FREE_PLAN = 'Free Plan',
    SILVER_PLAN = 'Silver Plan',
    GOLD_PLAN = 'Gold',
    PLATINUM_PLAN = 'Platinum',
}

export enum StatusType {
    REQUESTED = 'REQUESTED',
    PUBLISH = 'PUBLISH',
    HIDE = 'HIDE',
    INCOMPLETE = 'INCOMPLETE'
}

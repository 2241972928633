<div class="container p-4">
    <h4 class="head"><b>Filter Jobs</b></h4>
    <form [formGroup]="careerFilterForm">
        <div class="row">
            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Posted Date</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields input-font b-r w-100">
                        <input matInput [matDatepicker]="postedDate" formControlName="postedDate"
                            placeholder="dd/mm/yyyy">
                        <mat-datepicker-toggle matIconSuffix [for]="postedDate"></mat-datepicker-toggle>
                        <mat-datepicker #postedDate color="primary"></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Company</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput type="text" formControlName="company">
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Job Category</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <mat-select formControlName="jobCategories" placeholder="Select">
                            <mat-option *ngFor="let jobCategory of jobCategories" [value]="jobCategory.categoryName">{{
                                jobCategory?.categoryName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Skills</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <mat-select formControlName="skills" placeholder="Select">
                            <mat-option *ngFor="let skill of jobSkills" [value]="skill.skillName">{{
                                skill?.skillName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Experience</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input matInput type="number" formControlName="experience">
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">Country</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="countries"
                            #autoCompleteCountryInput="matAutocompleteTrigger" [matAutocomplete]="countryAuto">
                        <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onCountrySelected($event)">
                            <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName">
                                {{ option?.countryName }}
                            </mat-option>
                        </mat-autocomplete>
                        <span class="custom-dropdown-arrow">&#9662;</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">State</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="state"
                            [matAutocomplete]="stateAuto">
                        <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelected($event)">
                            <mat-option *ngFor="let option of states" [value]="option?.stateName">
                                {{ option?.stateName }}
                            </mat-option>
                        </mat-autocomplete>
                        <span class="custom-dropdown-arrow">&#9662;</span>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
                <div>
                    <mat-label class="label">City</mat-label>
                </div>
                <div>
                    <mat-form-field appearance="outline" class="fields input-font w-100">
                        <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="city"
                            [matAutocomplete]="cityAuto">
                        <mat-autocomplete #cityAuto="matAutocomplete">
                            <mat-option *ngFor="let option of cities" [value]="option?.cityName">
                                {{ option?.cityName }}
                            </mat-option>
                        </mat-autocomplete>
                        <span class="custom-dropdown-arrow">&#9662;</span>
                    </mat-form-field>
                </div>
            </div>

        </div>

        <div class="button-container">
            <button type="button" class="button button-primary" (click)="filterCareer()">Filter</button>
            <button class="button button-inverse" (click)="clearCareerFilter()">Clear</button>
            <button class="button button-inverse" (click)="cancelCareerFilter()">Cancel</button>
        </div>
    </form>
</div>
<div class="container mt-5">
    <div class="row mb-3">
        <div class="col">
            <div class="page-title">{{isSavedJobs ? 'Saved' : 'Applied'}} Jobs</div>
            <hr>
        </div>
    </div>
    <div class="row g-3 mb-4">

        <ng-container *ngIf="showNoRecordsFound">
            <div class="no-records-found"> No records Found </div>
        </ng-container>

        <ng-container *ngFor="let job of jobs | async;">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="card-header-job">
                            <h5 class="card-title">{{job.company}}</h5>
                            <h6 class="card-subtitle mb-2">{{job.title}}</h6>
                        </div>
                        <div class="d-flex mt-2" style="gap: 10px;">
                            <div class="job-details-header"><img src="/assets/images/careers-map-pin.svg" alt="">
                                {{job.country}}</div>

                            <div class="job-details-header"><img src="/assets/images/careers-clock.svg" alt="">
                                {{job.jobType}}</div>
                        </div>

                        <div class="d-flex mt-2 mb-2" style="gap: 10px;">
                            <div class="job-details-header"><img src="/assets/images/careers-dollar.svg" alt="">
                                {{job?.salaryFrom | salary}} - {{job?.salaryTo | salary}}
                            </div>
                            <div class="job-details-header"><img src="/assets/images/careers-calendar.svg" alt="">
                                {{getPostedDate(job?.jobPostedDate)}} day(s) ago</div>
                        </div>

                        <div class="card-text">{{job?.description}}</div>

                        <hr>

                        <div class="d-flex justify-content-between align-items-center mt-2 mb-2">
                            <div class="job-details-header"><img
                                    [src]="'/assets/images/icons/' + (isSavedJobs ? 'save-icon' : 'applied-icon') + '.svg'"
                                    alt="">
                                {{isSavedJobs ? 'Saved' : 'Applied'}} on {{job?.createdDate | date:'dd/MM/YYYY'}}
                            </div>

                            <ng-container *ngIf="isSavedJobs;else appliedJobs">
                                <button type="button" class="button button-primary w-auto" (click)="unSaveJob(job)">
                                    <img src="/assets/images/icons/unsave-button-icon.svg" alt="unsave-button-icon" style="margin-top: -4px;">
                                    Unsave</button>
                            </ng-container>

                            <ng-template #appliedJobs>
                                <button type="button"
                                    [class]="'button button-primary w-auto ' + (job?.applicationStatus)">{{job?.applicationStatus}}</button>
                            </ng-template>

                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>


    <mat-paginator class="mat-pagination d-none" [length]="dataSource.data?.length" [pageSize]="pageSize"
        *ngIf="!showNoRecordsFound">
    </mat-paginator>

    <div class="paginator-container" *ngIf="!showNoRecordsFound">
        <div class="page-numbers">
            <button mat-icon-button class="arrow" (click)="changePage(0)" [disabled]="currentPage === 0">
                <img src="assets/images/pagination_first.svg" alt="pagination_first" srcset="">
            </button>
            <button mat-icon-button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 0">
                <img src="assets/images/pagination_prev.svg" alt="pagination_prev" srcset="">
            </button>

            <ng-container *ngFor="let page of getPageNumbers()">
                <button mat-button *ngIf="page !== '...'" [class.page-active]="currentPage === page"
                    (click)="changePage(+page)">
                    {{ +page + 1 }}
                </button>
                <span *ngIf="page === '...'">...</span>
            </ng-container>
            <button mat-icon-button (click)="changePage(currentPage + 1)"
                [disabled]="currentPage >= getTotalPages() - 1">
                <img src="assets/images/pagination_next.svg" alt="pagination_next" srcset="">
            </button>
            <button mat-icon-button (click)="changePage(getTotalPages() - 1)"
                [disabled]="currentPage >= getTotalPages() - 1">
                <img src="assets/images/pagination_last.svg" alt="pagination_last" srcset="">
            </button>
        </div>
    </div>

</div>
<app-mwe-table [columns]="columns" [data]="data" [showPagination]="false" [showHeader]="false" (selectedAction)="selectedAction($event)"
></app-mwe-table>
<form [formGroup]="contactsForm">


    <div class="card p-4 mt-2 mb-2" *ngIf="isProceed">
        <div class="row mt-2" >
            <div class="col-lg-3">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select Continent</p>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <mat-select formControlName="continent" (selectionChange)="onContinentChange($event)">
                        <mat-option *ngFor="let continent of continents" [value]="continent.continentName">
                            {{ continent?.continentName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select Country</p>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                        <mat-option *ngFor="let country of countries" [value]="country.countryName">
                            {{ country?.countryName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select State</p>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <mat-select formControlName="state" (selectionChange)="onStateChange($event)">
                        <mat-option *ngFor="let state of states" [value]="state.stateName">
                            {{ state?.stateName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select City</p>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <mat-select formControlName="city" (selectionChange)="onCityChange($event)">
                        <mat-option *ngFor="let city of cities" [value]="city.cityName">
                            {{ city?.cityName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    <div class="row mt-2">
        <div class="col-lg-3">
            <div >
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Employee Name</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="fields input-font w-100 ">
                    <input matInput   formControlName="employeeName" (keypress)="onlyText($event)">
                </mat-form-field>
            </div>
        </div>
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Employee Position</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput   formControlName="employeePosition" (keypress)="onlyText($event)" >     
            </mat-form-field>
        </div>
    </div>
    
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Email</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput  formControlName="email" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            </mat-form-field>
        </div>
    </div>
    <div class="col-lg-3">
        <div >
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Phone Number</p>
        </div>
        <div>
            <mat-form-field appearance="outline" class="fields input-font w-100 ">
                <input matInput  formControlName="phone" (keypress)="numberOnly($event)" >
            </mat-form-field>
        </div>
    </div>
   
</div>
        

<div class="mt-2">

    <button class="mwe-button mwe-button--primary ps-3 pe-3" (click)="addBranchInformation()"
       >
        <span class="deltext">Save</span>
    </button>&nbsp;&nbsp;
    <button class="mwe-button mwe-button--secondary ps-3 pe-3" (click)=" toggleCancel()"
    >
     <span class="deltext">Cancel</span>
 </button>
</div>
    </div>

    <div *ngIf="!isProceed&&!isMore" class="mt-2 mb-2" style="margin-left: 10px;">
        <button class="d-flex more" (click)=" toggleIsProceed()"
        ><mat-icon>add</mat-icon>
         <span class="deltext">Add</span>
     </button>
    </div>
    <div *ngIf="!isProceed&&isMore" class="mt-2 mb-2" style="margin-left: 10px;" >
     <button class="d-flex more" (click)="toggleIsProceed()"
     ><mat-icon>add</mat-icon>
      <span class="deltext">Add More</span>
  </button>
</div>
</form>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ProductFileViewerComponent } from '../product-file-viewer/product-file-viewer.component';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/models/shared-model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('0.3s ease-in-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class ViewProductsComponent implements OnInit{
  @Output() closeToBack = new EventEmitter<any>();
  @Input() selectedViewData:any;
  catalogFiles: any[] = [];
  descriptionContent : string = '';
  selectedMainImage : any;
  zoomStyle = {};
  rating: number = 4;
  stars: number[] = [1, 2, 3, 4, 5];
  tabSliderPosition = 0; // Initial position of the tab slider
  tabSliderWidth = 0; // Initial width of the tab slider
  selectedFile: string = '';
  selectedFileType: string = '';
  activeTab = 0;
  previousTab: number = 0;
  animateContent: boolean = false;
  averageRating: any;
  reviews: any[] = [];
  review: string = '';
  myReviews: any[] = [];
  totalReviews: number = 0;
  ratingCount: any[] = [0, 0, 0, 0, 0]
  starIndex = 0;
  toggleWriteReview: boolean = false;
  id: string = '';
  userId!: string;
  isEditReview: boolean = false;
  editReviewData: any;
  data: any;
  productDetails$!: Observable<ApiResponseModel<any> | null>;
  displayReviews: number = 5;
  reviewsBatchSize: number = 5;

  config: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      translate: 'yes',
      enableToolbar: true,
      showToolbar: false,
      placeholder: 'Write your review here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  constructor(
    private dialog: MatDialog,
    private allProductService : AllProductService,
    private productService: ProductsService,
  ) {
  }

  ngOnInit(): void {
    console.log('started oninit', this.selectedViewData);
    
    this.selectedMainImage = this.selectedViewData.images[0]
    // this.processCatalogs(this.selectedViewData.catalogs);
    this.id = this.selectedViewData.id;
    this.setTabSliderWidth();
    this.setTabSliderPosition();
    this.getReviews(this.id);
    this.getProductDetails(this.id);
  }

  
  getProductDetails(id: string){
    this.productDetails$ = this.productService.getProductDetails(id);
    this.productDetails$.subscribe((res: any) => {
      this.data = res.data.productInfoDTO;
      this.selectedViewData = this.data
      this.processCatalogs(this.selectedViewData.catalogs);
    });
  }

  processCatalogs(urls: string[]): void {
    if (!urls || urls.length === 0) {
      console.error('No catalogs found to process.');
      return;
    }
  
    console.log('Processing catalogs:', urls);
  
    urls.forEach(url => {
      if (url) {
        console.log('Processing URL:', url);
        this.fetchCatalogsDetails(url);
      } else {
        console.warn('Encountered an empty URL.');
      }
    });
  
    console.log('Catalogs processed:', this.catalogFiles);
  }

  fetchCatalogsDetails(url: string): void {
    console.log('fetchCatalogsDetails');
    
    const fileName = this.extractFileName(url);
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.'); // Remove file extension
    const fileExtension = url.split('.').pop(); 
    const definedExtensions = ['jpeg', 'png', 'jpg']; 
    const fileType = definedExtensions.includes(fileExtension as string) ? 'image' : 'pdf'; 
    
    const file = {
      name: fileName,
      size: Math.floor(Math.random() * 1024 * 1024), 
      url: url,
      progress: Math.floor(Math.random() * 100),
      type: fileType
    };

    this.catalogFiles.push({
      file: file,
      name: file.name,
      size: (file.size / 1024).toFixed(2), 
      url: file.url,
      type: fileType,
      fileViewName : fileNameWithoutExtension,
      progress: file.progress
    });

    console.log('catalogFiles view-----', this.catalogFiles);
    
  }

  extractFileName(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  back(){
    this.closeToBack.emit(true);
  }

  setMainImage(image: any) {
    this.selectedMainImage = image;
  }

  zoomIn() {
    this.zoomStyle = {
      transform: 'scale(1.2)',
      transition: 'transform 0.2s ease-in-out'
    };
  }

  zoomOut() {
    this.zoomStyle = {
      transform: 'scale(1)',
      transition: 'transform 0.2s ease-in-out'
    };
  }

  setTabSliderWidth() {
    const tabs = document.querySelectorAll('.tab');
    if (tabs.length > 0) {
      this.tabSliderWidth = (tabs[0] as HTMLElement).offsetWidth;
    }
  }

  setTabSliderPosition() {
    this.tabSliderPosition = this.activeTab * this.tabSliderWidth;
  }

  selectTab(index: number) {
    if (index !== this.activeTab) {
      this.previousTab = this.activeTab;
      this.activeTab = index;
      this.setTabSliderPosition();
    }
  }

  viewCatalog(catalog: any) {
    window.open(catalog.url, '_blank');
  }

  downloadCatalog(catalog: any) {
    const link = document.createElement('a');
    link.href = catalog.url;
    link.download = '';
    link.target = '_blank';
    link.click();
  }
  
  getInitials(name: string): string {
    return name.split(' ').map(n => n[0]).join('');
  }

  getReviews(id: any) {
    this.totalReviews = 0;
    this.ratingCount = [0, 0, 0, 0, 0];  
    this.productService.getProductReviews(id, 0, 100).subscribe((res: any) => {
      if (res && res.data && res.data.reviews) {
        this.reviews = res.data.reviews.map((t: any) => {
          t.date = String(new Date(t.date));
          this.ratingCount[5 - t.rating]++;
          return t;
        });
        this.totalReviews = this.reviews.length;
        this.averageRating = res.data.averageRating;
        this.reviews = this.reviews.reverse();
      }
    });
  }

  writeReview(reviewData: any){
    this.productService.postReview(reviewData).subscribe((res: any)=>{
    })
  }

  calculatePercentage(rating: number): number {

    return Math.round((rating * 100) / this.totalReviews) || 0;
  }
  
  calculateDaysAgo(reviewDate: string): string {
    const reviewDateObj = new Date(reviewDate);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - reviewDateObj.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (dayDifference <= 0) {
        return 'Today';
    } else if (dayDifference === 1) {
        return 'Yesterday';
    } else if (dayDifference < 30) {
        return `${dayDifference} days ago`;
    } else if (dayDifference < 60) {
        return 'a month ago';
    } else if (dayDifference < 365) {
        const months = Math.floor(dayDifference / 30);
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (dayDifference < 730) {
        return 'a year ago';
    } else {
        const years = Math.floor(dayDifference / 365);
        return `${years} year${years > 1 ? 's' : ''} ago`;
    }
}  

  reviewInput(){
    this.toggleWriteReview = true;
    this.getReviews(this.id)
  }

  starClicked(i: number){
    if(this.starIndex===i){
      this.starIndex = 0
    }
    else{
      this.starIndex = i;
    }
  }

  addReview(type: string) {
    if (type === 'add') {
      const reviewData = {
        'productId': this.data.id,
        'description': this.review,
        'rating': this.starIndex,
        'userId': this.userId
      };   
      if (this.isEditReview) {
        this.putReview(reviewData, this.editReviewData.reviewId);
        this.isEditReview = false;
        this.getReviews(this.id);
      } else {
        this.writeReview(reviewData);
        this.getReviews(this.id);
      }
      this.starIndex = 0;
      this.review = '';
      this.toggleWriteReview = false;
      this.getReviews(this.id);
    } else {
      this.starIndex = 0;
      this.review = '';
      this.toggleWriteReview = false;
      this.getReviews(this.id);
    }
  }

  editReview(reviewId: string){
    this.toggleWriteReview = true;
    this.isEditReview = true;

    this.editReviewData = this.reviews.find((t: any)=>t.reviewId===reviewId);
    
    this.starIndex = this.editReviewData.rating;
    this.review = this.editReviewData.description;
  }

  deleteReview(reviewId: string){
    this.productService.deleteProductReview(reviewId).subscribe((res: any)=>{
      this.getReviews(this.id)
    })
  }

  putReview(data: any, id: string){
    this.productService.editProductReview(data, id).subscribe((res: any)=>{
    })
  }

  viewMoreReviews(){
    this.displayReviews += this.reviewsBatchSize;
  }
}

import { Injectable } from '@angular/core';
import { ACCOUNT_NAME, PREFERENCES, ROLE_NAME } from '../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor() { }

  getSuperAdminMenu(){
    return [
      { label: 'Dashboard', route: 'dashboard/super-admin',icon: 'assets/images/dashboard.svg',permissions:['DASHBOARD_VIEW','DASHBOARD_CREATE','DASHBOARD_EDIT','DASHBOARD_DELETE'] },
      { label: "MasterData",route: 'dashboard/super-admin/master-data',icon: '/assets/images/data-master.svg',permissions:['MASTER_VIEW','MASTER_CREATE','MASTER_EDIT','MASTER_DELETE']},
      { label: 'Product Settings', route: 'dashboard/super-admin/categories',
        children: [
          { label: 'Categories', route: 'dashboard/super-admin/categories', icon: '/assets/images/icons/categories.svg' ,permissions:['CATEGORY_VIEW','CATEGORY_CREATE','CATEGORY_EDIT','CATEGORY_DELETE'] },
          { label: 'Unit of Measure', route: 'dashboard/super-admin/unit-of-measure',icon: '/assets/images/icons/unitOfMeasure.svg',permissions:['UNIT_OF_MEASURE_VIEW','UNIT_OF_MEASURE_CREATE','UNIT_OF_MEASURE_EDIT','UNIT_OF_MEASURE_DELETE'] },
          { label: 'Specifications', route: 'dashboard/super-admin/specifications', icon: '/assets/images/icons/specifications.svg',permissions:['SPECIFICATION_VIEW','SPECIFICATION_CREATE','SPECIFICATION_EDIT','SPECIFICATION_DELETE'] }, 
          { label: 'All Products', route: 'dashboard/super-admin/all-products', icon: '/assets/images/icons/allProducts.svg',permissions:['ALL_PRODUCTS_VIEW','ALL_PRODUCTS_CREATE','ALL_PRODUCTS_EDIT','ALL_PRODUCTS_DELETE'] }
        ],icon: '/assets/images/base left.svg'
      },
      { label: 'OEM', route: 'dashboard/super-admin/oem-management',icon: 'assets/images/oems.svg',permissions:['OEM_VIEW','OEM_CREATE','OEM_EDIT','OEM_DELETE'] },
      { label: 'Distributor', route: 'dashboard/super-admin/distributor', icon:'/assets/images/import-warehouse.svg',permissions:['DISTRIBUTOR_VIEW','DISTRIBUTOR_CREATE','DISTRIBUTOR_EDIT','DISTRIBUTOR_DELETE'] },
      { label: 'End Customers', route: 'dashboard/super-admin/end-customer',icon: 'assets/images/ec.svg',permissions:['END_CUSTOMER_VIEW','END_CUSTOMER_CREATE','END_CUSTOMER_EDIT','END_CUSTOMER_DELETE'] },
      { label: 'Subscription Plans', route: ' ',icon: '/assets/images/subscription-plans.svg',permissions:['SUBSCRIPTION_VIEW','SUBSCRIPTION_CREATE','SUBSCRIPTION_EDIT','SUBSCRIPTION_DELETE'] },
      { label: 'Payments', route: '',icon: '/assets/images/payments.svg',permissions:['PAYMENT_VIEW','PAYMENT_CREATE','PAYMENT_EDIT','PAYMENT_DELETE'] },
      { label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions',icon: '/assets/images/StaffManagment.svg' ,
         children: [
        { label: 'Roles ', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg',permissions:['ROLE_PERMISSION_VIEW','ROLE_PERMISSION_CREATE','ROLE_PERMISSION_EDIT','ROLE_PERMISSION_DELETE'] },
        { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg' ,permissions:['STAFF_VIEW','STAFF_CREATE','STAFF_EDIT','STAFF_DELETE'] },
       
      ]},
      { label: 'Webinars', route: ' ',icon: '/assets/images/webinar.svg' ,permissions:['WEBINAR_VIEW','WEBINAR_CREATE','WEBINAR_EDIT','WEBINAR_DELETE'] },
      { label: 'Exhibitions', route: 'dashboard/super-admin/exhibitions',icon: 'assets/images/Exhibitions.svg',permissions:['EXHIBITION_VIEW','EXHIBITION_CREATE','EXHIBITION_EDIT','EXHIBITION_DELETE'] },
      { label: 'Careers', route: ' ',icon: '/assets/images/careers.svg',permissions:['CAREER_VIEW','CAREER_CREATE','CAREER_EDIT','CAREER_DELETE'] },
      { label: 'Leads', route: 'dashboard/super-admin/leads',icon: '/assets/images/leads.svg',permissions:['LEAD_VIEW','LEAD_CREATE','LEAD_EDIT','LEAD_DELETE'] },
      { label: 'Analytics', route: ' ',icon: '/assets/images/analytics.svg',permissions:['ANALYTIC_VIEW','ANALYTIC_CREATE','ANALYTIC_EDIT','ANALYTIC_DELETE'] },
      { label: 'CMS Pages', route: 'dashboard/super-admin/cms-pages',icon: '/assets/images/cms pages.svg',permissions:['CMS_PAGE_VIEW','CMS_PAGE_CREATE','CMS_PAGE_EDIT','CMS_PAGE_DELETE'] },
      { label: 'Notification', route: ' ',icon: '/assets/images/notification.svg',permissions:['NOTIFICATION_VIEW','NOTIFICATION_CREATE','NOTIFICATION_EDIT','NOTIFICATION_DELETE'] },
      { label: 'Settings', route: ' ',icon: '/assets/images/settings.svg',permissions:['SETTING_VIEW','SETTING_CREATE','SETTING_EDIT','SETTING_DELETE'] },
      { label: 'Support Tickects', route: ' ',icon: '/assets/images/support1.svg',permissions:['SUPPORT_TICKET_VIEW','SUPPORT_TICKET_CREATE','SUPPORT_TICKET_EDIT','SUPPORT_TICKET_DELETE'] },
      { label: 'Knowledge Base', route: ' ',icon: '/assets/images/knowledge-base.svg',permissions:['KNOWLEDGE_BASE_VIEW','KNOWLEDGE_BASE_CREATE','KNOWLEDGE_BASE_EDIT','KNOWLEDGE_BASE_DELETE'] }

    ];
  }

  getOemMenu(){
    return [
      { label: 'Dashboard', route: 'dashboard/oem',icon: 'assets/images/dashboard.svg' ,permissions:['DASHBOARD_VIEW','DASHBOARD_CREATE','DASHBOARD_EDIT','DASHBOARD_DELETE']}, 
      { label: 'Company Profile', route: 'dashboard/oem/company-details',icon: 'assets/images/company-profile.svg' ,permissions:['COMPANY_PROFILE_VIEW','COMPANY_PROFILE_CREATE','COMPANY_PROFILE_EDIT','COMPANY_PROFILE_DELETE']},
      { label: 'Products Management', route: 'dashboard/oem/products-management',icon: 'assets/images/ProductsManagement.svg',permissions:['PRODUCT_MANAGEMENT_VIEW','PRODUCT_MANAGEMENT_CREATE','PRODUCT_MANAGEMENT_EDIT','PRODUCT_MANAGEMENT_DELETE'] },
      { label: 'Catalogs', route: 'dashboard/oem/catalogs',icon: 'assets/images/Catalogs.svg',permissions:['CATALOG_VIEW','CATALOG_CREATE','CATALOG_EDIT','CATALOG_DELETE'] },
      { label: 'Exhibitions', route: 'dashboard/oem/exhibitions',icon: 'assets/images/Exhibitions.svg',permissions:['EXHIBITION_VIEW','EXHIBITION_CREATE','EXHIBITION_EDIT','EXHIBITION_DELETE'] },
      { label: 'Product Enquiry', route: 'dashboard/oem/enquiry',icon: 'assets/images/ProductEnquiry.svg',permissions:['PRODUCT_ENQUIRY_VIEW','PRODUCT_ENQUIRY_CREATE','PRODUCT_ENQUIRY_EDIT','PRODUCT_ENQUIRY_DELETE'] },
      { label: 'Distributor Network', route: 'dashboard/oem/distributor-network',icon: 'assets/images/DistributorNetwork.svg',permissions:['DISTRIBUTOR_NETWORK_VIEW','DISTRIBUTOR_NETWORK_CREATE','DISTRIBUTOR_NETWORK_EDIT','DISTRIBUTOR_NETWORK_DELETE']  },
      { label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions',
        children: [
          { label: 'Roles', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/ellipse-bottom.svg',permissions:['ROLE_PERMISSION_VIEW','ROLE_PERMISSION_CREATE','ROLE_PERMISSION_EDIT','ROLE_PERMISSION_DELETE']  },
          { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/ellipse-7.svg',permissions:['STAFF_VIEW','STAFF_CREATE','STAFF_EDIT','STAFF_DELETE'] },     
        ],icon: 'assets/images/StaffManagment.svg' },
      { label: 'Careers', route: 'dashboard/oem/careers',icon: 'assets/images/careers.svg',permissions:['CAREER_VIEW','CAREER_CREATE','CAREER_EDIT','CAREER_DELETE']  },
      { label: 'Webinars', route: 'dashboard/oem/webinars',icon: 'assets/images/Webinars.svg',permissions:['WEBINAR_VIEW','WEBINAR_CREATE','WEBINAR_EDIT','WEBINAR_DELETE'] },
      { label: 'Notifications', route: 'dashboard/oem/notifications',icon: 'assets/images/notification.svg',permissions:['NOTIFICATION_VIEW','NOTIFICATION_CREATE','NOTIFICATION_EDIT','NOTIFICATION_DELETE']  },
      { label: 'Support', route: 'dashboard/oem/support',icon: 'assets/images/Support.svg',permissions:['SUPPORT_VIEW','SUPPORT_CREATE','SUPPORT_EDIT','SUPPORT_DELETE']  },     
    ];
  }

  getDistributorMenu(){
    return [
      { label: 'Dashboard', route: 'dashboard/distributor',icon: 'assets/images/dashboard.svg',permissions:['DASHBOARD_VIEW','DASHBOARD_CREATE','DASHBOARD_EDIT','DASHBOARD_DELETE'] }, 
      { label: 'Company Profile', route: 'dashboard/distributor/company-details',icon: 'assets/images/company-profile.svg' ,permissions:['COMPANY_PROFILE_VIEW','COMPANY_PROFILE_CREATE','COMPANY_PROFILE_EDIT','COMPANY_PROFILE_DELETE']},
      { label: 'Distributing Products', route: 'dashboard/distributor/distributing-products',icon: 'assets/images/ProductsManagement.svg',permissions:['DISTRIBUTING_PRODUCT_VIEW','DISTRIBUTING_PRODUCT_CREATE','DISTRIBUTING_PRODUCT_EDIT','DISTRIBUTING_PRODUCT_DELETE'] },
      { label: 'Exhibitions', route: 'dashboard/distributor/exhibitions',icon: 'assets/images/Exhibitions.svg',permissions:['EXHIBITION_VIEW','EXHIBITION_CREATE','EXHIBITION_EDIT','EXHIBITION_DELETE'] },
      { label: 'Product Enquiry', route: 'dashboard/distributor/product-enquiry',icon: 'assets/images/ProductEnquiry.svg',permissions:['PRODUCT_ENQUIRY_VIEW','PRODUCT_ENQUIRY_CREATE','PRODUCT_ENQUIRY_EDIT','PRODUCT_ENQUIRY_DELETE']  },
      { label: 'Partner Requests', route: 'dashboard/distributor/partner-requests',icon: 'assets/images/partner-request.svg',permissions:['PARTNER_REQUEST_VIEW','PARTNER_REQUEST_CREATE','PARTNER_REQUEST_EDIT','PARTNER_REQUEST_DELETE']  },
      { label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions',icon: '/assets/images/StaffManagment.svg' ,
        children: [
       { label: 'Roles ', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg' ,permissions:['ROLE_PERMISSION_VIEW','ROLE_PERMISSION_CREATE','ROLE_PERMISSION_EDIT','ROLE_PERMISSION_DELETE']},
       { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg'  ,permissions:['STAFF_VIEW','STAFF_CREATE','STAFF_EDIT','STAFF_DELETE'] },
      
     ]},
      { label: 'Careers', route: 'dashboard/distributor/careers',icon: 'assets/images/careers.svg',permissions:['CAREER_VIEW','CAREER_CREATE','CAREER_EDIT','CAREER_DELETE']  },
      { label: 'Webinars', route: 'dashboard/distributor/webinars',icon: 'assets/images/Webinars.svg' ,permissions:['WEBINAR_VIEW','WEBINAR_CREATE','WEBINAR_EDIT','WEBINAR_DELETE']},
      { label: 'Notifications', route: 'dashboard/distributor/notifications',icon: 'assets/images/notification.svg',permissions:['NOTIFICATION_VIEW','NOTIFICATION_CREATE','NOTIFICATION_EDIT','NOTIFICATION_DELETE'] },
      { label: 'Support', route: 'dashboard/distributor/support',icon: 'assets/images/Support.svg',permissions:['SUPPORT_VIEW','SUPPORT_CREATE','SUPPORT_EDIT','SUPPORT_DELETE']  }
    ]
  }

  getStaffMenu(){
    return [
      { label: 'Dashboard', route: 'dashboard/super-admin',icon: 'assets/images/dashboard.svg' },
      { label: "MasterData",route: 'dashboard/super-admin/master-data',icon: '/assets/images/data-master.svg',permissions:['MASTER_VIEW','MASTER_CREATE','MASTER_EDIT','MASTER_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false},
      { label: 'Product Settings', route: 'dashboard/super-admin/categories',
        children: [
          { label: 'Categories', route: 'dashboard/super-admin/categories', icon: '/assets/images/icons/categories.svg' ,isSuperAdmin:true,isOem:true,isDistributor:false },
          { label: 'Unit of Measure', route: 'dashboard/super-admin/unit-of-measure',icon: '/assets/images/icons/unitOfMeasure.svg',isSuperAdmin:true,isOem:true,isDistributor:false },
          { label: 'Specifications', route: 'dashboard/super-admin/specifications', icon: '/assets/images/icons/specifications.svg',isSuperAdmin:true,isOem:true,isDistributor:false }, 
          { label: 'All Products', route: 'dashboard/super-admin/all-products', icon: '/assets/images/icons/allProducts.svg',isSuperAdmin:true,isOem:true,isDistributor:false }
        ],icon: '/assets/images/base left.svg',permissions:['PRODUCT_VIEW','PRODUCT_CREATE','PRODUCT_EDIT','PRODUCT_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false 
      },
      { label: 'OEM', route: 'dashboard/super-admin/oem-management',icon: 'assets/images/oems.svg',permissions:['OEM_VIEW','OEM_CREATE','OEM_EDIT','OEM_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false },
      { label: 'Distributor', route: 'dashboard/distributor/distributor', icon:'/assets/images/import-warehouse.svg',permissions:['DISTRIBUTOR_VIEW','DISTRIBUTOR_CREATE','DISTRIBUTOR_EDIT','DISTRIBUTOR_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false },
      
      { label: 'End Customers', route: 'dashboard/super-admin/end-customer',icon: 'assets/images/ec.svg',permissions:['END_CUSTOMER_VIEW','END_CUSTOMER_CREATE','END_CUSTOMER_EDIT','END_CUSTOMER_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false },
      { label: 'Subscription Plans', route: ' ',icon: '/assets/images/subscription-plans.svg',permissions:['SUBSCRIPTION_VIEW','SUBSCRIPTION_CREATE','SUBSCRIPTION_EDIT','SUBSCRIPTION_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false },
      { label: 'Payments', route: '',icon: '/assets/images/payments.svg' ,permissions:['PAYMENT_VIEW','PAYMENT_CREATE','PAYMENT_EDIT','PAYMENT_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:false },
      { label: 'Company Profile', route: 'dashboard/distributor/company-details',icon: 'assets/images/company-profile.svg' ,isSuperAdmin:false,isOem:true,isDistributor:true },
      { label: 'Distributing Products', route: 'dashboard/distributor/distributing-products',icon: 'assets/images/ProductsManagement.svg',isSuperAdmin:false,isOem:true,isDistributor:true },
      { label: 'Exhibitions', route: 'dashboard/distributor/exhibitions',icon: 'assets/images/Exhibitions.svg',isSuperAdmin:false,isOem:true,isDistributor:true },
      { label: 'Product Enquiry', route: 'dashboard/distributor/product-enquiry',icon: 'assets/images/ProductEnquiry.svg',isSuperAdmin:false,isOem:true,isDistributor:true },
      { label: 'Partner Requests', route: 'dashboard/distributor/partner-requests',icon: 'assets/images/partner-request.svg',isSuperAdmin:false,isOem:true,isDistributor:true },
      { label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions',icon: '/assets/images/StaffManagment.svg' ,
        children: [
       { label: 'Roles ', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg',isSuperAdmin:true,isOem:true,isDistributor:true  },
       { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg' ,isSuperAdmin:true,isOem:true,isDistributor:true  },
      
     ],isSuperAdmin:true,isOem:true,isDistributor:true},
      { label: 'Webinars', route: ' ',icon: '/assets/images/webinar.svg' ,permissions:['WEBINAR_VIEW','WEBINAR_CREATE','WEBINAR_EDIT','WEBINAR_DELETE'],isSuperAdmin:true,isOem:true,isDistributor:true },
      { label: 'Careers', route: ' ',icon: '/assets/images/careers.svg',permissions:['CAREER_VIEW','CAREER_CREATE','CAREER_EDIT','CAREER_DELETE'] ,isSuperAdmin:true,isOem:true,isDistributor:true},
      { label: 'Leads', route: 'dashboard/super-admin/leads',icon: '/assets/images/leads.svg',permissions:['LEAD_VIEW','LEAD_CREATE','LEAD_EDIT','LEAD_DELETE'],isSuperAdmin:true,isOem:false,isDistributor:false },
      { label: 'Analytics', route: ' ',icon: '/assets/images/analytics.svg',isSuperAdmin:true,isOem:false,isDistributor:false  },
      { label: 'CMS Pages', route: ' ',icon: '/assets/images/cms pages.svg',permissions:['CMS_PAGE_VIEW','CMS_PAGE_CREATE','CMS_PAGE_EDIT','CMS_PAGE_DELETE'] ,isSuperAdmin:true,isOem:false,isDistributor:false},
      { label: 'Notification', route: ' ',icon: '/assets/images/notification.svg',isSuperAdmin:true,isOem:true,isDistributor:true  },
      { label: 'Settings', route: ' ',icon: '/assets/images/settings.svg' ,isSuperAdmin:true,isOem:false,isDistributor:false },
      { label: 'Support Tickects', route: ' ',icon: '/assets/images/support1.svg',permissions:['SUPPORT_TICKET_VIEW','SUPPORT_TICKET_CREATE','SUPPORT_TICKET_EDIT','SUPPORT_TICKET_DELETE'],isSuperAdmin:true,isOem:false,isDistributor:false },
      { label: 'Knowledge Base', route: ' ',icon: '/assets/images/knowledge-base.svg',isSuperAdmin:true,isOem:false,isDistributor:false  }
    ];
  }

  hasAnyPermission(requiredPermissions: string[],permissions:any[]): boolean {
    return requiredPermissions.some(permission => permissions.includes(permission));
  }

  hasPermission(requiredPermission: string,permissions:any[]): boolean {
    return  permissions.includes(requiredPermission);
  }

hasStaffAccesss(url:string){

  const roleName = localStorage.getItem(ROLE_NAME);
  const accountName=localStorage.getItem(ACCOUNT_NAME);
  if(accountName=='Staff'){
    const permissions:any=localStorage.getItem(PREFERENCES);
    const staffMenu=roleName=='SUPER_ADMIN'?this.getSuperAdminMenu():roleName==='OEM'?this.getOemMenu():this.getDistributorMenu();
   // this.getStaffNavLink()
   const filterMenu:any=staffMenu.filter((item:any) =>{
    if(item?.children && item.children.length>0){
     const children= item.children.filter((child:any)=>{
        return child.route===url && this.hasAnyPermission(child.permissions,permissions);
      })
      if(children && children.length>0){
        item.route=children[0].route;
        item.children=children;
        return true;
      }
      return false;
    }else{
      return item.route===url && this.hasAnyPermission(item.permissions,permissions)
    }

  } )
  return filterMenu && filterMenu.length>0;
  }

  
}


}

import { MasterDataService } from 'src/app/services/master-data.service';
import { Component, Input, OnInit, EventEmitter, Output, inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { routes } from 'src/app/app.routes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Webinar } from 'src/app/models/oem-models';
import { OemService } from 'src/app/services/oem/oem.service';
import { WebinarService } from 'src/app/services/shared/webinar.service';
import Swal from 'sweetalert2';
import { map, Observable, Subscription } from 'rxjs';
import { FileUploadService } from 'src/app/services/shared/file-upload.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-webinar',
  templateUrl: './add-webinar.component.html',
  styleUrls: ['./add-webinar.component.scss']
})

export class AddWebinarComponent implements OnInit {
  @Input() addWebinar?: boolean;
  @Input() updateWebinar?: boolean = false;
  @Input() updateWebinarID?: any;
  @Output() backEvent = new EventEmitter<any>();
  @Output() closeToBack = new EventEmitter<any>();
  @Output() data = new EventEmitter<string>();

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  addWebinarForm!: FormGroup;
  announcer = inject(LiveAnnouncer);

  valid: boolean = false;
  
  speakersName:string[] = [];
  oemId: any;
  timezones: any[] = [];

  isImage:boolean = true;
  fileName = '';
  fileNameHost = '';
  currentFile?: File; 
  currentFileHost?: File; 
  selectedFileName: string | null = null;
  selectedFileNameHost: string | null = null; 
  imageUrl: string = '';
  imageUrlHost: any=undefined;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '0rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
hostImageUrl: any  = '';

  constructor(private fb: FormBuilder, private http: HttpClient, private localStorageService: LocalStorageService,  private webinarService: WebinarService, private oemService: OemService, private fileUploadService: FileUploadService, private MasterDataService: MasterDataService) {
    if(this.localStorageService.getItem('roleName')==='DISTRIBUTOR'){
      this.oemId = this.localStorageService.getItem('distributorId')
    }
    else if(this.localStorageService.getItem('roleName')==='OEM'){
      this.oemId = this.localStorageService.getItem('oemId')

    }
    else{
      this.oemId = this.localStorageService.getItem('userId')
    }
  }

  specialityData: any[] = [];

  addSpeakers(event: MatChipInputEvent) {
    const value = (event.value || '').trim();
    if (value) {
      this.speakersName.push(value);
      
      // this.addWebinarForm.get('speakersName')?.value.push(value);
      
      
      
    }
    event.chipInput!.clear();
  }

  removeSpeaker(certificate : any) {
    const index = this.speakersName.indexOf(certificate);
    if (index >= 0) {
      this.speakersName.splice(index, 1);
      this.announcer.announce(`Removed ${certificate}`);
    }
  }

  ngOnInit(): void {
    this.fetchAllSpecialities();  
    this.timeZones();
    this.addWebinarForm = this.fb.group({
      webinarTitle: new FormControl('', [Validators.required]),
      image: new FormControl('', [Validators.required]),
      speakersName: new FormControl([], [Validators.required]),
      orgName: new FormControl('', [Validators.required]),
      orgEmail: new FormControl('', [Validators.required, Validators.email]),
      orgPhone: new FormControl('', [Validators.required]),
      speciality: new FormControl('', [Validators.required]),
      hostImage: new FormControl('', [Validators.required]),
      aboutWebinar: new FormControl('', [Validators.required]),
      webinarDate: new FormControl('', [Validators.required]),
      webinarTime: new FormControl('', [Validators.required]),
      timezone: new FormControl('', [Validators.required]),
      estdH: new FormControl('', [Validators.required]),
      estdM: new FormControl('', [Validators.required]),
      estdS: new FormControl('', [Validators.required]),
      webinarSource: new FormControl('', [Validators.required]),
      webinarUrl: new FormControl('', [Validators.required]),
    });
       
    if(this.updateWebinar){
      this.updateWebinarMethod(this.updateWebinarID);
    }
  }

  // validate(): boolean {
  //   const form = this.addWebinarForm;
  //   const hasErrors = 
  //     form.get('webinarTitle')?.hasError('required') ||
  //     form.get('orgName')?.hasError('required') ||
  //     form.get('orgEmail')?.hasError('required') ||
  //     form.get('orgPhone')?.hasError('required') ||
  //     form.get('aboutWebinar')?.hasError('required') 
  //     form.get('webinarDate')?.hasError('required') ||
  //     form.get('webinarTime')?.hasError('required') ||
  //     form.get('timezone')?.hasError('required') ||
  //     form.get('speciality')?.hasError('required') ||
  //     form.get('estdH')?.hasError('required') ||
  //     form.get('estdM')?.hasError('required') ||
  //     form.get('estdS')?.hasError('required') ||
  //     form.get('webinarSource')?.hasError('required') ||
  //     form.get('webinarUrl')?.hasError('required')
  //     form.get('image')?.hasError('required') ||
  //     form.get('hostImage')?.hasError('required')
  //   if ((hasErrors ) || this.fetchAllSpecialities.length!==0 ){
  //     Swal.fire('Please fill all required fields', '', 'warning');      
  //     return false;
  //   }
  //   return true;
  // }

  fetchAllSpecialities(pageNumber: number = 0, pageSize: number = 100): void {
    this.webinarService.getSpeciality(pageNumber, pageSize).subscribe((data: any) => {
      if (data && data.data) {
        const specialties = data.data.map((spec: any) => spec.specialty);
        this.specialityData.push(...specialties);
        this.specialityData.sort();
        if (data.data.length === pageSize) {
          this.fetchAllSpecialities(pageNumber + 1, pageSize);
        }
      }
    });
  }

  timeZones(){
    this.MasterDataService.getTimezones().subscribe((res: any)=>{
      res.data.map((d: any)=>{
        this.timezones.push(d.viewTime)
      })    
    })
  }
  
  formatTime(time: string): string {
    return `2022-01-11T${time}:00.000Z`;
  }
  formatDate(date: any):string{
    return `${date}T00:00.000Z`
  }

  estdHH = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', 
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 
    '21', '22', '23'
  ];
  estdMM = [
    '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', 
    '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', 
    '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', 
    '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', 
    '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', 
    '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
  ];
  estdSS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
  sources = ['Google Meet', 'Zoom Meet', 'Skype', 'Other'];


  private uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('destinationKey', '/webinar/');

    return this.fileUploadService.uploadFile(formData).pipe(
      map((response: any) => {
        if (response && response.data) {
          return response.data; 
        } else {
          throw new Error('Invalid response format from server.');
        }
      })
    );
  }
  isImageFile(filePath: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.img'];    
    return imageExtensions.some(ext => filePath.toLowerCase().endsWith(ext));
  }

  private getFileNameFromUrl(url: string): string {
    return url.split('/').pop() || ''; 
  }

  selectFile(event: any, type: string): void {
      if (event.target.files && event.target.files[0]) {
        const file: File = event.target.files[0];
        this.currentFile = file;
        this.fileName = this.getFileNameFromUrl(file.name); 
        this.selectedFileName = this.fileName;
        this.uploadFile(file).subscribe((response) => {
          if(type==='host'){
            this.hostImageUrl = response;
            this.addWebinarForm.get('hostImage')?.setValue(response);

          }
          else if(type==='featured'){
            this.addWebinarForm.get('image')?.setValue(response);
             this.imageUrl = response;
          }
        })
      }
  }

  clearSelectedFile(type: string): void {
    if(type==='featured'){
      this.currentFile = undefined;
      this.selectedFileName = null;
      this.fileName = '';
      this.imageUrl='';
      this.addWebinarForm.patchValue({ image: '' });
    }
    else if(type==='host'){
      this.hostImageUrl='';
      this.currentFile = undefined;
      this.selectedFileName = null;
      this.fileName = '';
      this.addWebinarForm.patchValue({ image: '' });
    }
  }

  back(): void {
    this.closeToBack.emit(true);
    this.backEvent.emit({ actionType: '' });
  }

  formatDateToISO(date: Date): string {
    if(!date){
      return '';
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  swalError(){
    Swal.fire(
      'Please fill all fields',
      '',
      'warning'
    );
  }

  swalConfirm(){
    Swal.fire({
      icon: 'success',
      title: 'Webinar updated successfully.',
    });
    this.back();
  }


  image: string ='/src/assets/images/pdf-icon-image.svg';


  saveForm(): void {
    if (!this.isFormValid()) {
       this.addWebinarForm?.get('speakersName')?.markAsTouched();
      this.addWebinarForm?.get('image')?.markAsTouched();
      this.addWebinarForm?.get('hostImage')?.markAsTouched();
      this.addWebinarForm?.get('aboutWebinar')?.markAsTouched();
      this.addWebinarForm?.get('webinarTime')?.markAsTouched();
    }
    
    if(this.addWebinarForm.valid){
      
      
      const formData = this.addWebinarForm.value;
      const formattedDate = this.formatDateToISO(formData.webinarDate);
      const formattedTime = `${formData.webinarTime.hour}:${formData.webinarTime.minute}:${formData.webinarTime.second}`;
      let dur = '00:00:00';
      if(formData.estdH && formData.estdS && formData.estdS){
        dur = `${formData.estdH}:${formData.estdM}:${formData.estdS}`;
      }
      this.formatDate(formData.webinarDate)
      const submissionData = {
        webinarTitle: formData.webinarTitle,
        image: this.imageUrl || null, 
        speakersName: this.speakersName,
        orgName: formData.orgName,
        orgEmail: formData.orgEmail,
        orgPhone: String(formData.orgPhone),
        hostImage:  this.hostImageUrl || null,
        aboutWebinar: formData.aboutWebinar,
        webinarDate: formattedDate,
        webinarTime: formData.webinarTime,
        timezone: formData.timezone, 
        applicationCloseDate: formData.applicationCloseDate,
        webinarSource: formData.webinarSource,
        webinarUrl: formData.webinarUrl,
        speciality: formData.speciality,
        duration: dur, 
        createdBy: this.oemId ,
      };
      if(!this.updateWebinar){
        this.webinarService.createWebinar(submissionData).subscribe((res: any)=>{
          Swal.fire({
            icon: 'success',
            title: 'Webinar Added successfully.',
          });
          this.back();
        },
        error => {
         
          // Handle error, e.g., show an error message
          if(error) {
            Swal.fire(
              'Please fill all fields',
              '',
              'warning'
            );
          }
        }
      )
      }
    else{
      const formData = this.addWebinarForm.value;
      this.webinarService.updateWebinar(this.updateWebinarID, submissionData).subscribe( (res: any)=>{
        this.swalConfirm();
      },
      error => {
        if(error) {
          this.swalError();
        }
      }
    )};
    this.back();
    }
    else{
      
      
      this.swalError();
      
      }
  }
  isFormValid(): boolean {
    
    const nameValid = this.addWebinarForm?.get('speakersName')?.valid ?? false;
    const imageValid = this.addWebinarForm?.get('image')?.valid ?? false;
    const hostImageValid = this.addWebinarForm?.get('hostImage')?.valid ?? false;
    const webinarValid = this.addWebinarForm?.get('aboutWebinar')?.valid ?? false;
    const timeValid = this.addWebinarForm?.get('webinarTime')?.valid ?? false;

  
    return webinarValid && timeValid && nameValid && imageValid && hostImageValid;
  }
  
  reformatTime(t: any): string {
    let hours = t[0];
    const minutes = t[1];
    const formattedHours = hours < 10 ? '0' + hours : hours;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes}`;
  }

  reformatDuration(duration: any){
    if(duration.length===2){
      duration.push(0);
    }
    let h = String(duration[0])
    let m = String(duration[1])
    let s = String(duration[2])
    if(h.length===1){
      h = '0'+h
      }
    if(m.length===1){
      m = '0'+m
      }
    if(s.length===1){
      s = '0'+s
      }
    return [h,m,s]
  }

  getFileName(url: string) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
  
  updateWebinarMethod(id: string){
    this.webinarService.getWebinarById(id).subscribe((res: any)=>{
      const d = res.data.webinar
      this.speakersName = d.speakersName;
      const dur = this.reformatDuration(d.duration) ;
      const timee = this.reformatTime(d.webinarTime)
      let dur_toggle = false;
      (dur)?(dur_toggle=true):( dur_toggle=false)
      const formattedDate = `${d.webinarDate[1]}/${d.webinarDate[2]}/${d.webinarDate[0]}`;
      this.addWebinarForm.patchValue({
        webinarTitle: d.webinarTitle,
        orgName: d.orgName,
        orgEmail: d.orgEmail,
        orgPhone: d.orgPhone,
        image: d.image,
        hostImage: d.hostImage,
        aboutWebinar: d.aboutWebinar,
        webinarDate: (formattedDate) ? new Date(formattedDate) : null ,
        timezone: d.timezone,
        webinarSource: d.webinarSource,
        webinarUrl: d.webinarUrl,
        estdH: dur[0] ,
        estdM: dur[1] ,
        estdS: dur[2] ,
        speciality: d.speciality,
        speakersName: d.speakersName,
        webinarTime: timee,
      })
      this.imageUrl=d.image;
      this.hostImageUrl = d.hostImage    
      this.speakersName = d.speakersName 
      
    })
  }
}

<div>
    <button class="header-btn m-2 add-btn" mat-raised-button (click)="back()">
      <mat-icon>arrow_back</mat-icon> Back
    </button>
  
    <form [formGroup]="addWebinarForm" >
      <div style="background-color: #fff; margin-left: .2rem; border-radius: 10px; padding: 10px;">
        <div class="containerr">
          <!-- Webinar Title -->
          <div class="col">
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Webinar Title <span class="text-danger">*</span></p>
            </div>
            <div>
              <mat-form-field appearance="outline" class="input-font  w-100">
                <input matInput formControlName="webinarTitle">
                <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('webinarTitle')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
    
          <!-- Add Featured Image -->
          <div>
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Add Featured Image <span class="text-danger">*</span></p>
            </div>
            <div class="file-container">
              <label class="d-flex justify-content-center  med-world-basic-font med-world-basic-font__medium secondary_black_color text" for="upload_featured" mat-raised-button>
                <img alt="" src="./../../assets/images/photo-add.svg" > Drag files here to upload (or) Select Files
                <input type="file" accept=".pdf,.jpg,.jpeg,.png,.img" id="upload_featured"  style="display: none;"  (change)="selectFile($event, 'featured')" />  
              </label>   
            </div>
            <div class="d-flex align-items-center" style="gap: 8px; margin-top: 10px;">
              <div class="d-flex align-items-center"  >
                <img *ngIf="imageUrl!== ''"  style="width:4rem; height: 4rem ;margin-right: 1rem;" alt="image" [src]="imageUrl ">
                <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('image')?.touched && !addWebinarForm.get('image')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />Image is Required
                </mat-error>
                <!-- <img ngIf="isImage"  style="width:4rem; height: 4rem ;margin-right: 1rem;" alt="image" [src]="imageUrl "> -->
                <!-- <img  *ngIf="!isImage" style="width:4rem; height: 4rem; margin-right: 1rem;" src="../../assets/images/pdf-icon.svg" alt="pdf Image"> -->
                <ng-container>
                <button *ngIf="imageUrl!== ''"  mat-icon-button color="warn" (click)="clearSelectedFile('featured')">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
              </div> 
                 
    
            </div>
          </div><br>
          
          <!-- Speakers Name -->
          <div>
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Speakers Name <span class="text-danger">*</span></p>
            </div>
            <div class="chip-container">
              <mat-chip-grid #chipGrid1 aria-label="Enter certificate">
                <mat-chip-row class="chip-display" *ngFor="let speaker of speakersName" (removed)="removeSpeaker(speaker)">
                  {{speaker}}
                  <button matChipRemove [attr.aria-label]="'remove ' + speaker">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input
                  matInput
                  class="chip-input"
                  formControlName="speakersName"
                  [matChipInputFor]="chipGrid1"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addSpeakers($event)"
                />
              </mat-chip-grid><br>
              
            </div>
            <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('speakersName')?.touched && !addWebinarForm.get('speakersName')?.valid">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Speaker(s) name is Required
            </mat-error>
              
          </div><br>
    
          <!-- Organiser Info -->
          <div class="col">
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Organiser Info <span class="text-danger">*</span></p>
            </div>
            <div class="organiser">
              <!--Organiser name -->
              <mat-form-field appearance="outline" class="input-font  w-100">
                <input matInput formControlName="orgName" placeholder="Name">
                <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('orgName')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Organizer name is Required
                </mat-error>
              </mat-form-field>
    
              <!--Organiser email -->
              <mat-form-field appearance="outline" class="input-font  w-100">
                <input matInput formControlName="orgEmail" placeholder="Email">
                <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('orgEmail')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Organizer email is Required
                </mat-error>
              </mat-form-field>
    
              <!--Organiser phone -->
              <mat-form-field appearance="outline" class="input-font  w-100">
                <input matInput formControlName="orgPhone" placeholder="Phone" type="text" onlyNumbers>
                <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('orgPhone')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Organizer phone is Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
    
          <!-- Speciality -->
          <div style="margin-bottom: 5px;">
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Speciality <span class="text-danger">*</span></p>
          </div>
          <mat-form-field appearance="outline" class="input-font w-100">
            <mat-select formControlName="speciality" placeholder="Select">
              <mat-option *ngFor="let s of specialityData" [value]="s"> {{ s }} </mat-option>
            </mat-select>
            <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('speciality')?.valid">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Speciality is Required
            </mat-error>
          </mat-form-field>
    
          <!-- Host image -->
          <div>
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Add Host Image <span class="text-danger">*</span></p>
            </div>
            <div class="file-container">
              <label class="d-flex justify-content-center  med-world-basic-font med-world-basic-font__medium secondary_black_color text" for="upload_host" mat-raised-button>
                <img alt="" src="./../../assets/images/photo-add.svg" > Drag files here to upload (or) Select Files
                <input type="file" accept=".pdf,.jpg,.jpeg,.png,.img" id="upload_host"  (change)="selectFile($event, 'host')" />  
              </label>   
            </div>
            <div class="d-flex align-items-center" style="gap: 8px; margin-top: 10px;">
              <div  class="d-flex align-items-center" >
                <img style="width:4rem; height: 4rem ;margin-right: 1rem;" alt="" [src]="hostImageUrl " *ngIf="hostImageUrl!==''">
                <ng-container>
                <button mat-icon-button color="warn" (click)="clearSelectedFile('host')" *ngIf="hostImageUrl!==''">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
              </div>
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('hostImage')?.touched && !addWebinarForm.get('hostImage')?.valid">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" />Host Image is Required
              </mat-error>    
    
            </div>
          </div><br>
      
              <!-- About Webinar -->
              <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">About Webinar <span class="text-danger">*</span></p>
              </div>
              <div>
              <angular-editor formControlName="aboutWebinar" [config]="config" ></angular-editor>
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('aboutWebinar')?.touched && !addWebinarForm.get('aboutWebinar')?.valid">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> About Webinar is Required
              </mat-error> 
          </div><br>
    
          <!-- Webinar Date & Time -->
          <div class="dateTime">
            <!-- Date --> 
            <div class="dateTime-inner">
              <div style="margin-bottom: 5px;">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Date <span class="text-danger">*</span></p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="input-font w-100">
                  <input matInput [matDatepicker]="datepicker" formControlName="webinarDate" placeholder="mm/dd/yyyy">
                  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                  <mat-datepicker #datepicker color="primary"></mat-datepicker>
                  <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('aboutDate')?.valid">
                    <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Date is Required
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
    
            <!-- Time -->
            <div class="dateTime-inner">
              <div style="margin-bottom: 5px;">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0" style="font-size: 15px;">Time <span class="text-danger">*</span></p>
              </div>
              <input type="time" class="input-font w-100 timeInpt" formControlName="webinarTime" style="font-size: 15px;" />
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('webinarTime')?.touched && !addWebinarForm.get('webinarTime')?.valid">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Timezone is Required
              </mat-error>
            </div>
            
    
            <!-- Time Zone -->
            <div class="dateTime-inner">
              <div style="margin-bottom: 5px;">
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Timezone <span class="text-danger">*</span> </p>
              </div>

              <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="timezone" placeholder="Select">
                  <mat-option *ngFor="let tz of timezones" [value]="tz">
                    {{ tz }}
                  </mat-option>
                </mat-select>
                <mat-error class="error-message error-msg" *ngIf="!addWebinarForm.get('timezone')?.valid">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Timezone is Required
                </mat-error>
              </mat-form-field>
              

            </div>
          </div>
    
          <!-- Estimated Duration -->
          <div style="margin-bottom: 5px;">
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 ">Estimated Duration <span class="text-danger">*</span></p>
          </div>
    
          <div class="estduration">
            <!-- estd hour -->
            <div class="estduration-inner">
              <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="estdH" placeholder="hh">
                  <mat-option *ngFor="let hr of estdHH" [value]="hr"> {{ hr }} </mat-option>
                </mat-select>
                <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('estdH')?.hasError('required')">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Hour is Required
                </mat-error>
              </mat-form-field>
            </div>
    
            <!-- estd min -->
            <div class="estduration-inner">
              <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="estdM" placeholder="mm">
                  <mat-option *ngFor="let mth of estdMM" [value]="mth"> {{ mth }} </mat-option>
                </mat-select>
                <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('estdM')?.hasError('required')">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Minute is Required
                </mat-error>
              </mat-form-field>
            </div>
    
            <!-- estd seconds -->
            <div class="estduration-inner">
              <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="estdS" placeholder="ss">
                  <mat-option *ngFor="let snd of estdSS" [value]="snd"> {{ snd }} </mat-option>
                </mat-select>
                <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('estdS')?.hasError('required')">
                  <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Second is Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
    
          <!-- Source -->
          <div class="source">
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Source <span class="text-danger">*</span> </p>
            </div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <mat-select formControlName="webinarSource" placeholder="Select">
                <mat-option *ngFor="let src of sources" [value]="src"> {{ src }} </mat-option>
              </mat-select>
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('webinarSource')?.hasError('required')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Source is Required
              </mat-error>
            </mat-form-field>
          </div>
    
          <!-- URL -->
          <div class="url">
            <div style="margin-bottom: 5px;">
              <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">URL <span class="text-danger">*</span> </p>
            </div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput formControlName="webinarUrl" />
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('webinarUrl')?.hasError('required')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> URL is Required
              </mat-error>
              <mat-error class="error-message error-msg" *ngIf="addWebinarForm.get('webinarUrl')?.hasError('pattern')">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> URL must start with "www." or "https://"
              </mat-error>
            </mat-form-field>
          </div>
          
    
          <div class="row buttons mt-3 gap-3">
            <button class="button-primary btn btn-primary" type="submit" (click)="saveForm()"> {{ (updateWebinar) ? ('update') : 'save' }} </button>
            <button class="button-inverse btn btn-primary" type="button" (click)="back()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  
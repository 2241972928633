<form [formGroup]="dealDetailsForm">
    <div formGroupName="lead" >
      <div class="row mt-2">
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Lead Source</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="w-100 input-font">
              <mat-select formControlName="leadSource">
                <mat-option *ngFor="let source of leadSources" [value]="source.name">
                  {{ source.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Added by</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <mat-select formControlName="addedBy">
                <mat-option *ngFor="let staff of staffData" [value]="staff.user_name">
                  {{ staff.user_name }}
                </mat-option>
              </mat-select>
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Lead State</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <mat-select formControlName="leadState">
                <mat-option value="initial_contact">Initial Contact</mat-option>
                <mat-option value="schedule_appointment">Schedule Appointment</mat-option>
                <mat-option value="qualified">Qualified</mat-option>
                <mat-option value="account_created">Account Created</mat-option>
                <mat-option value="on_hold">On Hold</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Account Type<span class="text-danger">*</span></p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <mat-select formControlName="accountType" (selectionChange)="onAccountTypeChange($event.value)">
                <mat-option value="OEM">OEM</mat-option>
                <mat-option value="Distributor">Distributor</mat-option>
              </mat-select>
              <mat-error *ngIf="dealDetailsForm?.get('lead')?.get('accountType')?.hasError('required')"
                                        class="error-message error-msg">
                                        <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                                         Please select Account Type.
                                    </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Name</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="w-100 input-font">
              <input matInput    formControlName="name">
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Company Name</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput    formControlName="companyName">
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Brand Name</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput    formControlName="brandName">
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3">
        <div  >
          <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Established In</p>
      </div>
      <div>
          <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput [matDatepicker]="datepicker" 
                  formControlName="establishedIn" placeholder="mm/dd/yyyy">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker color="primary"></mat-datepicker>
               
          </mat-form-field>

      </div>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-3">
          
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Number of Employees</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput    formControlName="numberOfEmployees" (keypress)="numberOnly($event)">
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-3">
          
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Email<span class="text-danger">*</span></p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput    formControlName="email">
              <mat-error *ngIf="dealDetailsForm?.get('lead')?.get('email')?.hasError('required')"
                                        class="error-message error-msg">
                                        <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                                        Email is required.
                                    </mat-error>
                                    <mat-error *ngIf="dealDetailsForm?.get('lead')?.get('email')?.hasError('pattern')"
                                        class="error-message error-msg">
                                        <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                                        Please enter a valid email address.
                                    </mat-error>
                                    <mat-error *ngIf="dealDetailsForm?.get('lead')?.get('email')?.hasError('emailExists')"
                                        class="error-message error-msg">
                                        <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                                        Email already exists.
                                    </mat-error>
              
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-6">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Website URL</p>
          </div>
          <div>
            <mat-form-field appearance="outline" class="input-font w-100">
              <input matInput    formControlName="websiteUrl">
              <mat-error *ngIf="dealDetailsForm?.get('lead')?.get('websiteUrl')?.hasError('pattern')"><img class="error-icon"
                loading="eager" alt="" src="./../../assets/images/alert.svg" />
                Enter a valid URL with https:// or www.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
       
      
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select Continent</p>
          </div>
            <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="continent" (selectionChange)="onContinentChange($event)">
                    <mat-option *ngFor="let continent of continents" [value]="continent.continentName">
                        {{ continent?.continentName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select Country</p>
          </div>
            <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="country" (selectionChange)="onCountryChange($event)">
                    <mat-option *ngFor="let country of countries" [value]="country.countryName">
                        {{ country?.countryName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select State</p>
          </div>
            <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="state" (selectionChange)="onStateChange($event)">
                    <mat-option *ngFor="let state of states" [value]="state.stateName">
                        {{ state?.stateName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-lg-3">
          <div>
            <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Select City</p>
          </div>
            <mat-form-field appearance="outline" class="input-font w-100">
                <mat-select formControlName="city" >
                    <mat-option *ngFor="let city of cities" [value]="city.cityName">
                        {{ city?.cityName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
      <div class="row">
            <div class="col-lg-6">
              <div>
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">Address</p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="input-font w-100">
                  <input matInput    formControlName="address">
                  
                </mat-form-field>
              </div>
            </div>
          
          
            <div class="col-lg-3">
              <div>
                <p class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0">ZIP Code</p>
              </div>
              <div>
                <mat-form-field appearance="outline" class="input-font w-100">
                  <input matInput    formControlName="zipCode" (keypress)="numberOnly($event)">
                  
                </mat-form-field>
              </div>
            </div>
          </div>
          </div>
        
          </form>

          
  
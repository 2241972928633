

<h2 mat-dialog-title>Job Preview</h2>
<mat-dialog-content>
    <div class="view-job-container mt-2 p-4">

        <div class="row">
            <div class="col-lg-12">
    
    
                <div style="height: 400px; overflow: hidden;">
                    <img [src]="data?.image" alt="featured-image" style="width: 100%;max-height: 100%;object-fit: cover">
                </div>
    
                <div class="title">{{data?.title}}</div>
                <div class="description mt-1" [innerHTML]="data?.content"></div>
    
    
                <div class="row job-fields" style="flex: 1 1 auto;">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-job-category.svg" alt="">
                                <p class="overview-text">Job Category</p>
                                <p class="overview-label-job">{{data?.category}}</p>
                            </div>
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-job-speciality.svg" alt="">
                                <p class="overview-text">Speciality</p>
                                <p class="overview-label-job">{{data?.speciality}}</p>
                            </div>
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-job-type.svg" alt="">
                                <p class="overview-text">Job Type</p>
                                <p class="overview-label-job">{{data?.jobType}}</p>
                            </div>
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-career-level.svg" alt="">
                                <p class="overview-text">Career Level</p>
                                <p class="overview-label-job">{{data?.careerLevel}}</p>
                            </div>
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-career-experience.svg" alt="">
                                <p class="overview-text">Experience</p>
                                <p class="overview-label-job">{{data?.experience}}</p>
                            </div>
                            <div class="overview-border">
                                <img src="/assets/images/careers/frame-degree-level.svg" alt="">
                                <p class="overview-text">Degree Level</p>
                                <p class="overview-label-job">{{data?.degreeLevel}}</p>
                            </div>
                        </div>
                    </div>
                </div>
    
    
                <p class="job-label">Skills required</p>
                <mat-chip *ngFor="let skill of data.skills; let i = index" class="me-2 mb-2">{{skill}}</mat-chip>
    
                <p class="job-label">Tags</p>
                <mat-chip *ngFor="let tag of data.tags; let i = index" class="me-2 mb-2">{{tag}}</mat-chip>
    
                <p class="job-label">Description</p>
                <div [innerHtml]="data?.description"></div>
                <!-- <p class="description">{{jobPost?.description}}</p> -->
    
                <p class="company-details-header">Company Details</p>
                <p class="company-name">{{data?.company}}</p>
                <p class="company-address">{{data?.address}}</p>
    
                <p class="company-details-header">Job Overview</p>
    
                <table class="table">
                    <tbody>
                        <tr>
                            <td class="overview-label">
                                <img src="/assets/images/careers/frame-total-positions.svg" alt="" class="me-1">
                                Total Positions
                            </td>
                            <td class="overview-text">{{data?.totalPositions}}</td>
                        </tr>
                        <tr>
                            <td class="overview-label">
                                <img src="/assets/images/careers/frame-posted-by.svg" alt="" class="me-1">
                                Posted by
                            </td>
                            <td class="overview-text">{{data?.postedBy}}</td>
                        </tr>
                        <tr><td class="overview-label"> <img src="/assets/images/careers/frame-salary-range.svg" alt="" class="me-1">
                            Salary Range </td>
                            <td class="overview-text"> {{ data.salaryCurrency }} {{ data.salaryFrom }} - {{ data.salaryTo }} {{ data.salaryRange }}</td></tr>
                        
                        <tr>
                            <td class="overview-label">
                                <img src="/assets/images/careers/frame-application-start-date.svg" alt="" class="me-1">
                                Applications Start Date
                            </td>
                            <td class="overview-text">{{data?.applicationStartDate | date:'dd/MM/YYYY'}}</td>
                        </tr>
                        <tr>
                            <td class="overview-label">
                                <img src="/assets/images/careers/frame-application-end-date.svg" alt="" class="me-1">
                                Applications Close Date
                            </td>
                            <td class="overview-text">{{data?.applicationCloseDate | date:'dd/MM/YYYY'}}</td>
                        </tr>
                    </tbody>
                </table>
    
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button type="button" mat-dialog-close class="button button-primary">Close</button>
</mat-dialog-actions>
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { concatMap, Observable, startWith, takeWhile } from 'rxjs';
import { City, Country, State } from 'src/app/models/master-data.model';
import { MasterDataService } from 'src/app/services/master-data.service';
import { OemService } from 'src/app/services/oem/oem.service';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';

@Component({
  selector: 'app-brand-filters',
  templateUrl: './brand-filters.component.html',
  styleUrls: ['./brand-filters.component.scss']
})

export class BrandFiltersComponent {
  @Input() data: any[] = [];
  @Output() filteredData = new EventEmitter<any[]>();

  dataObj : any [] = [];
  filteredObj : any [] = [];

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  showNoRecordsFounds: boolean = true;
  filterObject: any;
  countries!: any[];
  states!: any[];
  cities!: any[];
  brands!: any[];
  categories!: any[];
  yearInMarkets!: any[];
  countryControl = new FormControl();
  stateControl = new FormControl();
  cityControl = new FormControl();
  brandControl = new FormControl();
  filteredCountries: Country[] | undefined;
  filteredStates: State[] | undefined;
  filteredCities: City[] | undefined;
  filteredBrands: any[] | undefined;

  filterForm: FormGroup = new FormGroup({
    keywords: new FormControl(null),
    countries: new FormControl([]),
    states: new FormControl(null),
    cities: new FormControl(null),
    zipCode: new FormControl(null),
    brands: new FormControl(null),
    yearInMarkets: new FormControl(null),
    amountFrom: new FormControl(0),
    amountTo: new FormControl(0),
    categories: new FormControl(null),
  });

  filterControls = ['keywords', 'countries', 'states', 'cities', 'zipCode', 'brands', 'yearInMarkets', 'amountFrom', 'amountTo', 'categories'];

 
  constructor(
    private readonly masterDataService: MasterDataService,
    private oemService : OemService,
    private categoryService: CategoryService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataObj = this.data;  
  }

  ngOnInit(): void {
    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilter();
    });

    this.loadCountries('');
    this.loadStates('');
    this.loadCities('');
    this.loadBrands();
    this.loadcategories();


    
    this.countryControl.valueChanges.subscribe(value => {
      if (!value?.countryName) {
        this.filteredCountries = this.countries.filter(country =>
          country.countryName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      }
    });
    this.stateControl.valueChanges.subscribe(value => {
      if (!value?.stateName) {
        this.filteredStates = this.states.filter(state =>
          state.stateName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      } 
    });
    this.cityControl.valueChanges.subscribe(value => {
      if (!value?.cityName) {    
        this.filteredCities = this.cities.filter(city =>
          city.cityName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      } 
    });

    this.brandControl.valueChanges.subscribe(value => {
      console.log('brandControl', value);
      
      if (!value?.name) {
        this.filteredBrands = this.brands.filter(brand =>
          
          brand.name.toLowerCase().includes(value?.toLowerCase() || '') 
      
        );
      }
    });

  }

  applyFilter(): void {
    let filtered = this.dataObj;    
    const {keywords, countries, states, cities, zipCode, brands, yearInMarkets, categories,amountFrom, amountTo } = this.filterForm.value;
    
    if (keywords && keywords.length > 0) {
      const lowerCaseKeywords = keywords.map((keyword: string) => keyword.toLowerCase());
      filtered = filtered.filter(item =>
        (item.name && lowerCaseKeywords.some((keyword: any) => item.name.toLowerCase().includes(keyword))) ||
        (item.oemName && lowerCaseKeywords.some((keyword: any) => item.name.toLowerCase().includes(keyword))) ||
        (item.country && lowerCaseKeywords.some((keyword: any) => item.country.toLowerCase().includes(keyword))) ||
        (item.state && lowerCaseKeywords.some((keyword: any) => item.state.toLowerCase().includes(keyword))) ||
        (item.city && lowerCaseKeywords.some((keyword: any) => item.city.toLowerCase().includes(keyword)))
      );
    }

    if (countries && countries.length > 0) {
 
      filtered = filtered.filter(item =>
        item.country && countries.some((country: string) => item.country.toLowerCase() === country.toLowerCase())
      );
      this.loadStates(countries);
    }

    if (states && states.length > 0) {
      filtered = filtered.filter(item =>
        item.state && states.some((state: string) => item.state.toLowerCase() === state.toLowerCase())
      );
      this.loadCities(states);
    }

    if (cities && cities.length > 0) {
      filtered = filtered.filter(item =>
        item.city && cities.some((city: string) => item.city.toLowerCase() === city.toLowerCase())
      );
    }

    if (zipCode && zipCode.length > 0) {
      filtered = filtered.filter(item => 
        item.zipCode && String(item.zipCode) === String(zipCode));
    }
    
    if (brands && brands.length > 0) {
      filtered = filtered.filter(item =>
        (item.name && brands.some((name: string) => item.name.toLowerCase() === name.toLowerCase())) ||
        (item.oemName && brands.some((name: string) => item.oemName.toLowerCase() === name.toLowerCase()))
      );
    }

    if (yearInMarkets && yearInMarkets.length > 0) {
      filtered = filtered.filter(item => 
        item.year && yearInMarkets.includes(item.year));
    }
    
    if (amountTo) {
      filtered = filtered.filter(item => {
        const turnOver = item.turnOver ? parseFloat(item.turnOver) : null;
        return turnOver !== null && turnOver >= amountFrom && turnOver <= amountTo;
      });
    }

    if (categories && categories.length > 0) {
      // Map the selected category names to their corresponding IDs
      const selectedCategoryIds = categories.map((categoryName: string) => {
        const matchedCategory = this.categories.find(category => category.name.toLowerCase() === categoryName.toLowerCase());
        return matchedCategory ? matchedCategory.id : null;
      }).filter((id: null) => id !== null); // Filter out any null values

      // Apply the filter using the mapped IDs
      if (selectedCategoryIds.length > 0) {
        filtered = filtered.filter(item =>
          item.productCategories &&
          item.productCategories.some((categoryId: string) =>
            selectedCategoryIds.includes(categoryId)
          )
        );
      }
    }

    if (!keywords && !countries && !states && !cities && !zipCode && !brands && !yearInMarkets && !categories && !amountFrom && !amountTo) {
      filtered = this.dataObj;
    }
    this.filteredData.emit(filtered);
  }


  loadCountries(continentName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', [continentName]);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
        this.filteredCountries = allCountries;
      });
  }

  loadStates(countryName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allStates: any[] = [];
    let morePages = true;

    this.masterDataService.getStates(pageNumber, pageSize, '', [countryName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allStates = allStates.concat(response.data);
          pageNumber++;
          return this.masterDataService.getStates(pageNumber, pageSize, '', [countryName]);
        })
      )
      .subscribe(data => {
        allStates = allStates.concat(data.data);
        this.states = allStates;
        this.filteredStates = allStates;
      });
  }

  loadCities(stateName: string) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCities: any[] = [];
    let morePages = true;

    this.masterDataService.getCities(pageNumber, pageSize, '', [stateName])
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCities = allCities.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCities(pageNumber, pageSize, '', [stateName]);
        })
      )
      .subscribe(data => {
        console.log('data in flilters', data);
        
        if (data.data) {
          allCities = allCities.concat(data.data);
        }
        this.cities = allCities;
        this.filteredCities = allCities;
      });
  }

  loadBrands() {
    this.oemService.getBrandsList().subscribe({
      next: (response) => {
        this.brands = response.data; 
        this.filteredBrands = response.data;
        this.yearInMarkets = [...new Set(response.data
          .map((item: { year: any; }) => item.year)
          .filter((year: any) => year != null && year !== '' && year !== 0)
      )]; 
      },
      error: (error) => {
        console.error('Error fetching brand list data:', error);
      }
    });
  }

 
  loadcategories() {
    this.categoryService.getLastNodeCategory().subscribe(data => {
      if (data && data.data && data.data.length > 0) {
        this.categories = data.data;
      }
    }, error => {
      console.error("Error fetching categories", error);
    }); 
  }

  resetFilters() {
    this.filterForm.reset();
    this.applyFilter();
  }

  add(controlName: string, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const currentValues = this.filterForm.get(controlName)?.value || [];
      this.filterForm.get(controlName)?.setValue([...currentValues, value.trim()]);
    }

    if (input) {
      input.value = '';
    }
  }

  remove(controlName: string, item: string | number): void {
    const control = this.filterForm.get(controlName);
    if (control) {
      const currentValues = control.value || [];
      const index = currentValues.indexOf(item);
      if (index >= 0) {
        currentValues.splice(index, 1);
        control.setValue(currentValues);
      }
      this.applyFilter();
    }
  }
  onOptionSelected(event: any, filterType: string): void {
    let currentValues: any[] = [];
  
    switch (filterType) {
      case 'continent':
      case 'country':
        // Retrieve existing values
        currentValues = this.filterForm.get('countries')?.value || [];
        // Add new value only if it doesn't exist
        if (!currentValues.includes(event.option.value.countryName)) {
          this.filterForm.get('countries')?.setValue([...currentValues, event.option.value.countryName]);
        }
        this.countryControl.reset();
        break;
  
      case 'state':
        // Retrieve existing values
        currentValues = this.filterForm.get('states')?.value || [];
        // Add new value only if it doesn't exist
        if (!currentValues.includes(event.option.value.stateName)) {
          this.filterForm.get('states')?.setValue([...currentValues, event.option.value.stateName]);
        }
        this.stateControl.reset();
        break;
  
      case 'city':
        // Retrieve existing values
        currentValues = this.filterForm.get('cities')?.value || [];
        // Add new value only if it doesn't exist
        if (!currentValues.includes(event.option.value.cityName)) {
          this.filterForm.get('cities')?.setValue([...currentValues, event.option.value.cityName]);
        }
        this.cityControl.reset();
        break;
  
      case 'brand':
        console.log("$$$$$$$$$$$", event.option.value);
        // Retrieve existing values
        currentValues = this.filterForm.get('brands')?.value || [];
        // Add new value only if it doesn't exist
        if (!currentValues.includes(event.option.value.name)) {
          this.filterForm.get('brands')?.setValue([...currentValues, event.option.value.name]);
        }
        this.brandControl.reset();
        break;
  
      default:
        break;
    }
  
    this.applyFilter();
  }
  
 
}
 


<app-oem-filters [formColumns]="formColumns"></app-oem-filters>

<div class="cards">
    <div class="cards-container">
        <mat-card class="example-card" *ngFor="let request of oemData" appearance="outlined">
            <div class="d-flex justify-content-center position-relative">
                <div class="labimage">
                    <img width="325px" height="75px" [src]="distributorIds[this.cardIds[request.id]].logo" alt="">
                </div>
                <div class="shieldimage">
                    <img src="/assets/images/shield icon.svg" alt="" class="shield">
                </div>
                <div class="floating-icons d-flex g-2">
                    <div class="moreoptions">
                        <img src="/assets/images/more info icon.svg" class="more-options" alt="">
                    </div>
                    <div class="profileimage">
                        <img [src]="distributorIds[this.cardIds[request.id]].profilePic" alt="profile">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end heading-container">
                <h3 style="font-size: 14px;margin-top: 2px !important">{{distributorIds[this.cardIds[request.id]].distributorName}}</h3>
            </div>
            <mat-card-content style="padding: 0px 5px 10px 0px">
                <div class="company-info">
                    <div class="div card-content mt-2" style="    padding: 5px 5px 10px 5px;;">
                        <!-- <div class="row " style="width: 344px; ">
                            <span style="width: 200px; padding-left: 15px;" class="heading">{{distributorIds[this.cardIds[request.id]].companyName}}</span>
                            <div class="" style="width: 144px; padding: 0px;">
                                <span style="font-size: 12px;font-weight: 600; color: #0d87c8; width: 100%;">4.8/5 (536
                                    Reviews)</span>
                            </div>
                        </div> -->
                        <div class="row d-flex">
                            <span style="font-size: 11px;">More than {{ distributorIds[this.cardIds[request.id]].noOfEmployees }} Employees</span>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-6 d-flex justify-content-start">
                                <div class="company-name">
                                    <span class="heading" style="font-size: 11px;">Established in {{ distributorIds[this.cardIds[request.id]].year }}  </span>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex justify-content-end ">
                                <div class="company-name p-1" style="background-color:#0d87c8;border-radius: 1rem;">
                                    <span style="font-size: 14px;font-weight: 600; color: #fff; display: flex;">
                                        <img src="./../../../assets/images/phone rings.svg" alt="" class="phone-icon">
                                       {{distributorIds[this.cardIds[request.id]].phoneNumber}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Address</span>
                            <p>{{distributorIds[this.cardIds[request.id]].address}}</p>
                        </div>
                    </div>

                    <hr style="border-top: 1px solid #35673d ;margin:0px;">

                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Description</span>
                            <p>{{distributorIds[this.cardIds[request.id]].description}}</p>
                        </div>
                    </div>
                    <hr style="border-top: 1px solid #35673d ;margin:0px;">
                    <div class="row mt-2">
                        <div class="col">
                            <span class="heading">Distributing Brands</span>
                            <mat-chip-set aria-label="brands" *ngIf="distributorIds[this.cardIds[request.id]].distributionBrands.length > 0">
                                <mat-chip>{{ distributorIds[this.cardIds[request.id]].distributionBrands }}</mat-chip>
                            </mat-chip-set>
                        </div>
                    </div>
                </div>
            </mat-card-content>
            <div class="buttons">
                <mat-card-actions>
                    <button *ngIf="request && isRequested && request.status !== 'REJECTED'" class="button button-primary" (click)="acceptRequest(request.id)">Accept</button>
                    <button *ngIf="isRequested" class="button button-inverse mx-2" [disabled]="request.status === 'REJECTED'" (click)="rejectRequest(request.id)"> {{(request.status === 'REJECTED')?('Rejected'): 'Reject'}}</button>
                </mat-card-actions>
            </div>
        </mat-card>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedService } from 'src/app/services/shared-service.service'; // Adjust path according to your project structure

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  userId: string = '';
  allNotifications: any[] = [];
  pageNumber: number = 0;
  pageSize: number = 10;

  constructor(private sharedService: SharedService, private localStorageService: LocalStorageService) {}

  ngOnInit(): void {
    // Assume userId is passed through the state (e.g., router)
    this.localStorageService.getUserDetails().subscribe(userDetails => {
      this.userId = userDetails.userId;
    });
    this.fetchNotifications();
  }

  fetchNotifications() {
    this.sharedService.getNotifications(this.userId, this.pageNumber, this.pageSize).subscribe((response) => {
      if (response?.data) {
        this.allNotifications = response.data;
      }
    });
  }
}


<section class="job-filters-section p-3"> 
  <div class="">
      <div class="">
          <div class="d-flex">
              <div class="filters-heading me-2">Filters</div>
              <mat-chip class="reset-chip" (click)="resetFilters()"><img
                      src="/assets/images/job-filters-reset.svg" alt="job-filters-reset"
                      class="me-1">Reset</mat-chip>
          </div>

          <div class="card mt-3" style="height: 95%;">
              <div class="card-body">

                  <form [formGroup]="filterForm">

                      <div class="filter-field-container" *ngIf="filterColumns?.includes('Products')">
                          <div class="filter-label mb-3">Your Search Keyword</div>

                          <mat-chip-grid #chipList>
                            <div *ngFor="let controlName of filterControls">
                                <mat-chip-row *ngFor="let item of filterForm.get(controlName)?.value" 
                                class="reset-chip" [selectable]="selectable" [removable]="removable" 
                                (removed)="remove(controlName, item)">
                                  {{ item }}
                                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip-row>
                            </div>

                              <label class="input-container w-100">
                                  <mat-icon matPrefix class="inp">search</mat-icon>
                                  <input matInput [matChipInputFor]="chipList" placeholder="Search By Keyword"
                                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                      [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add('keywords',$event)">
                              </label>

                          </mat-chip-grid>
                      </div>

                      <div class="filter-field-container" *ngIf="filterColumns?.includes('Region')">
                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="filter-label"> Region </mat-panel-title>
                              </mat-expansion-panel-header>
                              <mat-form-field appearance="outline" class="input-font w-100">
                                  <mat-select multiple placeholder="Filter By Country" formControlName="countries">
                                      <mat-option *ngFor="let country of countries"
                                          [value]="country.countryName">{{
                                          country?.countryName }}</mat-option>
                                  </mat-select>
                              </mat-form-field>

                               <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Filter By State" formControlName="states">
                                        <mat-option *ngFor="let state of states"
                                            [value]="state.stateName">{{
                                                state?.stateName }}</mat-option>
                                    </mat-select>
                                </mat-form-field> 

                                 <mat-form-field appearance="outline" class="input-font w-100">
                                    <mat-select multiple placeholder="Filter By City" formControlName="cities">
                                        <mat-option *ngFor="let city of cities"
                                            [value]="city.cityName">{{
                                                city?.cityName }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="input-font w-100">
                                    <input matInput multiple placeholder="Filter By Zip Code" formControlName="zipCode">
                                </mat-form-field> 
                          </mat-expansion-panel>
                      </div>

                        <div class="filter-field-container" *ngIf="filterColumns?.includes('Application')">
                          <mat-expansion-panel>
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="filter-label"> Application </mat-panel-title>
                              </mat-expansion-panel-header>
                              <mat-form-field appearance="outline" class="input-font w-100">
                                  <mat-select multiple placeholder="Filter By Application" formControlName="applications">
                                      <mat-option *ngFor="let application of applications"
                                          [value]="application">{{
                                            application }}</mat-option>
                                  </mat-select>
                              </mat-form-field>   
                          </mat-expansion-panel>
                      </div>

                      <div class="filter-field-container" *ngIf="filterColumns?.includes('Shape')">
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="filter-label"> Shape </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field appearance="outline" class="input-font w-100">
                                <mat-select multiple placeholder="Filter By Shape" formControlName="shapes">
                                    <mat-option *ngFor="let shape of shapes"
                                        [value]="shape">{{
                                            shape }}</mat-option>
                                </mat-select>
                            </mat-form-field>   
                        </mat-expansion-panel>
                    </div>

                    <div class="filter-field-container" *ngIf="filterColumns?.includes('Size')">
                      <mat-expansion-panel>
                          <mat-expansion-panel-header>
                              <mat-panel-title class="filter-label"> Size (in CM) </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-form-field appearance="outline" class="input-font w-100">
                              <mat-select multiple placeholder="Filter By Size" formControlName="sizes">
                                  <mat-option *ngFor="let size of sizes"
                                      [value]="size">{{
                                        size }}</mat-option>
                              </mat-select>
                          </mat-form-field>   
                      </mat-expansion-panel>
                  </div>

                  <div class="filter-field-container" *ngIf="filterColumns?.includes('Model')">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title class="filter-label"> Model </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-form-field appearance="outline" class="input-font w-100">
                            <mat-select multiple placeholder="Filter By Model" formControlName="models">
                                <mat-option *ngFor="let model of models"
                                    [value]="model">{{
                                        model }}</mat-option>
                            </mat-select>
                        </mat-form-field>   
                    </mat-expansion-panel>
                </div>

                <div class="filter-field-container" *ngIf="filterColumns?.includes('SampleType')">
                  <mat-expansion-panel>
                      <mat-expansion-panel-header>
                          <mat-panel-title class="filter-label"> Sample Type </mat-panel-title>
                      </mat-expansion-panel-header>
                      <mat-form-field appearance="outline" class="input-font w-100">
                          <mat-select multiple placeholder="Filter By Sample Type" formControlName="sampleTypes">
                              <mat-option *ngFor="let sampleType of sampleTypes"
                                  [value]="sampleType">{{
                                    sampleType }}</mat-option>
                          </mat-select>
                      </mat-form-field>   
                  </mat-expansion-panel>
              </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</section>



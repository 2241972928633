<div class="info-container"> 
  <button class="header-btn m-2 add-btn" mat-raised-button (click)="back()">
    <mat-icon>arrow_back</mat-icon> Back 
  </button>
  <form [formGroup]="exhibitionForm">
    <div class="row d-flex" style="background-color: #fff; margin-left: .2rem; border-radius: 10px; width: 100%;">
      <div class="mt-2" style="color: #0d87c8; font-weight: 600;">Create Exhibition</div>
      <div class="col-md-7">
        <div class="row">
          <div class="col-md-6 inputs">
            <label class="label-styles" [formGroup]="exhibitionForm">Event Title</label><br>
            <input class="input w-100" formControlName="title" name="name" type="text">         
          </div>
          <div class="col-md-3 inputs">
            <label class="label-styles" [formGroup]="exhibitionForm">Start Date</label><br>
            <input class="input w-100" formControlName="exhibitionStartDate" name="exhibitionStartDate" type="date"
              style="cursor: pointer;" [min]="currentDate">
          </div>
          <div class="col-md-3 inputs" [formGroup]="exhibitionForm">
            <label class="label-styles">End Date</label><br>
            <input class="input w-100" formControlName="exhibitionEndDate" name="exhibitionEndDate" type="date"
              style="cursor: pointer;" [min]="exhibitionForm.get('exhibitionStartDate')?.value">
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 inputs" [formGroup]="exhibitionForm" >
            <label class="label-styles">Description/Label</label><br>
            <textarea class="input w-100" formControlName="description" name="description" style="height: 6rem;"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="label-styles">Images</label><br>
            <div class="file-input-container inputs" [formGroup]="exhibitionForm">
              <input type="file" style="display: none;" (change)="selectFile($event)" accept=".jpg,.jpeg,.png,.svg" name="image" id="imageInput" formControlName="image">
              <label  *ngIf="!selectedFileName"  for="imageInput" class="d-flex" style="cursor: pointer;">
                <img src="./assets/images/photo-add.svg" alt="Upload Icon" height="20px" width="20px">&nbsp;<p
                  style="color: #0d87c8;">Drag images/videos to upload or <u>Select files</u></p>
              </label>
              
              <span class="drag-drop-text2 d-flex" *ngIf="selectedFileName">
                <img [src]="imageURL" alt="image" style="width: 5rem; height: 5rem;">
                <!-- <span>{{ selectedFileName }}</span> -->
                  <button type="button" style="border: none; background-color: #fff" class="close-icon" (click)="removeFile($event)"><mat-icon>delete</mat-icon></button>
              </span>

           </div>
            
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <label class="label-styles" [formGroup]="exhibitionForm">Select Country</label><br>    
          <mat-form-field appearance="outline" class="input-font w-100" [formGroup]="exhibitionForm">
            <input type="text" placeholder="Select" aria-label="Number" matInput  formControlName="country" #autoCompleteCountryInput="matAutocompleteTrigger" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCountrySelected($event)">
              <mat-option *ngFor="let option of countryDropdown" [value]="option?.countryName">
                {{ option?.countryName }}
              </mat-option>
            </mat-autocomplete>
            <mat-error class="error-message error-msg"
                *ngIf="exhibitionForm.get('country')?.hasError('required')"><img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> Country is
                Required</mat-error>
        </mat-form-field>          
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="inputs">
              <div>
                <label class="label-styles" >Select State</label><br>
                <mat-form-field appearance="outline" class="fields input-font w-100" [formGroup]="exhibitionForm"> 
               <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="state" [matAutocomplete]="stateAuto">
                  <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onStateSelected($event)" >
                    <mat-option *ngFor="let option of stateDropdown" [value]="option?.stateName">
                      {{ option?.stateName }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error class="error-message error-msg"
                  *ngIf="exhibitionForm.get('state')?.hasError('required')"><img class="error-icon"
                      loading="eager" alt="" src="./../../assets/images/alert.svg" /> State is
                Required</mat-error>
                </mat-form-field>
            </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="inputs">
              <label class="label-styles" >Select City</label><br>
              <mat-form-field appearance="outline" class="fields input-font w-100" [formGroup]="exhibitionForm">
                <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="city" [matAutocomplete]="cityAuto">
                <mat-autocomplete #cityAuto="matAutocomplete" >
                  <mat-option *ngFor="let option of cityDropdown" [value]="option?.cityName">
                    {{ option?.cityName }}

                  </mat-option>
                </mat-autocomplete>
                <mat-error class="error-message error-msg"
                *ngIf="exhibitionForm.get('city')?.hasError('required')"><img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" /> City is
                Required</mat-error>
            </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12">
            <div class="inputs">
              <label class="label-styles" [formGroup]="exhibitionForm">Address</label><br>
              <input class="input w-100" formControlName="address" type="text" name="address">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="inputs">
              <label class="label-styles" [formGroup]="exhibitionForm">Zip Code</label><br>
              <input class="input w-100" formControlName="zipCode" type="number" name="zipCode">
            </div>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">
            <div class="inputs">
              <label class="label-styles" [formGroup]="exhibitionForm">Book Tickets URL</label><br>
              <input class="input w-100" formControlName="bookTicketsUrl" type="text" name="bookTicketsUrl">
            </div>
          </div> -->
          <div class="col-md-6">
            <div >
                <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Book Tickets URL</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="input-font  w-100 mt-1">
                    <input matInput  formControlName="bookTicketsUrl">
                    <mat-error *ngIf="exhibitionForm.get('bookTicketsUrl')?.hasError('pattern')">
                      <img class="error-icon"
                      loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid URL starts with https:// or www.
                      </mat-error>
                </mat-form-field>
            </div>
            </div>
        
        <div class="col-md-6">
          <div >
              <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Facebook URL</p>
          </div>
          <div>
              <mat-form-field appearance="outline" class="input-font  w-100 mt-1">
                  <input matInput  formControlName="facebookUrl">
                  <mat-error *ngIf="exhibitionForm.get('facebookUrl')?.hasError('pattern')">
                    <img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid Facebook URL with https:// or www.
                    </mat-error>
              </mat-form-field>
          </div>
          </div>
      </div>
        <div class="row">
          <div class="col-md-6">
            <div >
                <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0  ">LinkedIn URL</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="input-font  w-100  mt-1">
                    <input matInput  formControlName="linkedinUrl">
                    <mat-error *ngIf="exhibitionForm.get('linkedinUrl')?.hasError('pattern')">
                      <img class="error-icon"
                      loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid LinkedIn URL  with https:// or www.
                      </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-6">
          <div >
              <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Twitter URL </p>
          </div>
          <div>
              <mat-form-field appearance="outline" class="input-font  w-100  mt-1">
                  <input matInput  formControlName="twitterUrl">
                  <mat-error *ngIf="exhibitionForm.get('twitterUrl')?.hasError('pattern')">
                    <img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid Twitter URL  with https:// or www.
                    </mat-error>
              </mat-form-field>
          </div>
      </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6">
            <div class="inputs">
              <label class="label-styles" [formGroup]="exhibitionForm"> Instagram URL</label><br>
              <input class="input w-100" formControlName="instagramUrl" type="text" name="instagramUrl">
            </div>
          </div> -->
          <!-- <div class="col-md-6">
            <div class="inputs">
              <label class="label-styles" [formGroup]="exhibitionForm">Other URL</label><br>
              <input class="input w-100" formControlName="otherUrl" type="text" name="otherUrl">
            </div>
          </div> -->
          <div class="col-md-6">
            <div >
                <p class="profile-h4 profile-h4__font-Weight-medium  profile-h4__color m-0">Other URL</p>
            </div>
            <div>
                <mat-form-field appearance="outline" class="input-font  w-100 mt-1">
                    <input matInput  formControlName="otherUrl">
                    <mat-error *ngIf="exhibitionForm.get('otherUrl')?.hasError('pattern')">
                      <img class="error-icon"
                      loading="eager" alt="" src="./../../assets/images/alert.svg" />Enter a valid URL starts with https:// or www.
                      </mat-error>
                </mat-form-field>
            </div>
            </div>
          
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-12 inputs">
              <label class="label-styles" [formGroup]="exhibitionForm">Assign Staff</label><br>
                  <mat-form-field class="input w-100" appearance="outline" >                  
                    <mat-select formControlName="assignStaff" multiple >
                      <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                        {{ staff.user_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="row buttons mt-3 gap-3">
                <button class="button-primary" type="submit" *ngIf="!updateExhibition" (click)="saveForm()"  >Save</button>
                <button class="button-primary" type="button" *ngIf="updateExhibition" (click)="updateForm()">Update</button>
                <button class="button-inverse" type="button" (click)="back()">Cancel</button>
              </div>
            </div>
        </div>

      </div>
      <div class="col-md-1"></div>
      <div class="col-md-4">
        <div class="row w-100">
          <div class="col-md-9 inputs">
            <div class="form-check form-check-inline w-100">
              <button style="background-color: transparent; border: none;">
                <input class="form-check-input" type="checkbox"  name="appointmentAvailable" id="appointmentAvailable" value="true" (change)="toggleAvailability()" [(ngModel)]="isAvailable"/>
                <label class="form-check-label" for="appointmentAvailable">Appointments Available</label>
              </button>
            </div>
            <div *ngIf="isAvailable || updateExhibition" formArrayName="appointments">
              <div *ngFor="let appointment of appointments.controls; let i = index" [formGroupName]="i" class="appointment-container">
                <div *ngIf="currentAppointmentIndex === i" class="appointment-form d-flex align-items-start">
                 
                  <div class="flex-grow-1">
                    <label class="label-styles" [formGroup]="exhibitionForm">Appointment Date</label><br />
                    <input class="input w-100" formControlName="appointmentDate" name="appointmentDate" type="date" [min]="exhibitionForm.get('exhibitionStartDate')?.value" [max]="exhibitionForm.get('exhibitionEndDate')?.value"/>
                    <label class="label-styles" [formGroup]="exhibitionForm" style="cursor: pointer;">Start Time</label><br />
                    <input class="input w-100" formControlName="startTime" name="startTime" type="time" />

                    <label class="label-styles" [formGroup]="exhibitionForm">End Time</label><br />
                    <input class="input w-100" formControlName="endTime" name="endTime" type="time" />

                    <label class="label-styles" [formGroup]="exhibitionForm">Break Time</label><br />
                    <input class="input w-100" formControlName="breakTime" name="breakTime" type="time" />

                    <label class="label-styles" [formGroup]="exhibitionForm">Per Slot Time</label><br />
                    <input class="input w-100" formControlName="perSlotTime" name="perSlotTime" placeholder="--:--" type="text" />

                    <label class="label-styles" [formGroup]="exhibitionForm">Total Slots</label><br />
                    <input class="input w-100" formControlName="availableSlots" name="availableSlots" type="text" />
                  </div>
                  <button class="delete-button ms-2" (click)="deleteAppointment(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>

                <div *ngIf="currentAppointmentIndex !== i" class="col-md-9 inputs d-flex w-100 align-items-center">
                  <button class="add-date-button" style="font-weight: 600;" (click)="selectAppointment(i)">
                    Day {{ daydifference(exhibitionForm.get('exhibitionStartDate')?.value, appointment.value.appointmentDate) + 1 || 'N/A' }} {{ appointment.value.appointmentDate  }}
                  </button>
                  <button class="add-date-button mt-2" style="font-weight: 600;" (click)="deleteAppointment(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

              <div class="col-md-9 inputs">
                <button type="button" class="add-date-button mt-2" (click)="addNewAppointment()" style="font-weight: 600;">
                  Add Appointment Date
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
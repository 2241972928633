import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ElementRef, ViewChild } from '@angular/core';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';

@Component({
  selector: 'app-oem-profile-summary',
  templateUrl: './oem-profile-summary.component.html',
  styleUrls: ['./oem-profile-summary.component.css']
})
export class OemProfileSummaryComponent {
  @Input() oemId: string = '';
  @Input() companyInfo!: FormGroup;
  companyInformationGroup!: FormGroup;
  @Input() attachmentsForm!: FormGroup;

  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  
  data: any[] = [];
  
  @Output() onUpdateEvent: EventEmitter<any> = new EventEmitter(); 
  @ViewChild('scrollContainer') scrollContainer: ElementRef = new ElementRef(null);

  imageUrl: string = 'assets/images/phillips-logo.svg';
  CompanyDescription = '';
  editedDescription: string = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '180px',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      { name: 'quote', class: 'quote' },
      { name: 'redText', class: 'redText' },
      { name: 'titleText', class: 'titleText', tag: 'h1' },
    ],
  };

  constructor(private fb: FormBuilder,private endCustomerService: EndCustomerService) {}

  ngOnInit(): void {
    
    this.companyInformationGroup = this.fb.group({
      description: ['', Validators.required],
      
    });
  
    
    if (this.companyInfo) {
      this.companyInformationGroup = this.companyInfo.get('companyInformation') as FormGroup;
    }
  
    
    this.companyInformationGroup.get('description')?.valueChanges.subscribe((value: string) => {
      this.CompanyDescription = value || '';
      if (!this.isEditMode) {
        this.editedDescription = this.CompanyDescription;
      }
    });
  
    
    this.CompanyDescription = this.companyInformationGroup.get('description')?.value || '';
    this.editedDescription = this.CompanyDescription;
    
  }

  ngAfterViewInit() {
    this.scrollContainer.nativeElement.scrollTop = 0;
  }
  
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.editedDescription = this.CompanyDescription; 
    }
    this.ngAfterViewInit();
  }
  
  saveEdit() {
    this.isEditMode = false;
    this.CompanyDescription = this.editedDescription;
    this.companyInformationGroup.get('description')?.setValue(this.CompanyDescription);
    this.onUpdateEvent.emit(this.CompanyDescription); 
  }
  
  isEditMode: boolean = false;

  

  cancelEdit() {
    this.isEditMode = false;
    this.editedDescription = this.CompanyDescription;
  }


}

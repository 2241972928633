import { Component, Input, OnInit,ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { Currency } from 'src/app/models/master-data.model';
import { MasterDataService } from 'src/app/services/master-data.service';
import { concatMap, takeWhile } from 'rxjs';
import Swal from 'sweetalert2';
import { SweetAlertService } from 'src/app/services/sweetalert.service';


@Component({
  selector: 'app-financial-information',
  templateUrl: './financial-information.component.html',
  styleUrls: ['./financial-information.component.scss']
})
export class FinancialInformationComponent implements OnInit {
  @Input() financialInformationForm!: FormGroup;
  @Input() isEdit: boolean = false;
  @Output() branchAdded = new EventEmitter<number>();

  columns: ListColumn[] = [
    { name: 'Financial Year', property: 'financialYear', visible: true, isModelProperty: true, isText: true },
    { name: 'Currency', property: 'currency', visible: true, isModelProperty: true, isText: true },
    { name: 'Net Worth', property: 'netWorth', visible: true, isModelProperty: true, isText: true },
    { name: 'Turnover', property: 'turnover', visible: true, isModelProperty: true, isText: true },
    { name: 'Profit', property: 'profit', visible: true, isModelProperty: true, isText: true },
    { name: 'Yearly Growth %', property: 'yearlyGrowth', visible: true, isModelProperty: true, isText: true },
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];

  data: any[] = [];
  financialForm!: FormGroup;
  actionType: any;
  index: number = 0;
  yearDropdown:any=[]
  isMore: boolean = false;
  isProceed: boolean = false;
  currencies: Currency[] = [];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef,private masterDataService:MasterDataService,private sweetAlertService:SweetAlertService) {}

  async ngOnInit(): Promise<void> {
    this.initializeFinancialForm();
    this.getYearDropdown();
   this.data=this.financialInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
   if(this.data.length>0){
    this.isMore = true;
     
   }
   this.cdr.detectChanges();
   await this.loadCurrencies();
  }

  initializeFinancialForm() {
    this.financialForm = this.fb.group({
      id: new FormControl(null),
      active: new FormControl(null),
      createdBy: new FormControl(null),
      createdOn: new FormControl(null),
      modifiedBy: new FormControl(null),
      modifiedOn: new FormControl(null),
      financialYear: new FormControl(null),
      currency: new FormControl(null),
      netWorth: new FormControl(null),
      turnover: new FormControl(null),
      profit: new FormControl(null),
      yearlyGrowth: new FormControl(null),
      leadId: new FormControl(null),
    });
  }
  loadCurrencies(): Promise<void> {
    return new Promise((resolve, reject) => {
      let pageNumber = 0;
      const pageSize = 100;
      let allCurrencies: Currency[] = [];
      let morePages = true;

      this.masterDataService.getCurrencies(pageNumber, pageSize)
        .pipe(
          takeWhile(() => morePages),
          concatMap(response => {
            if (response.data.length === 0) {
              morePages = false; 
              return ([]); 
            }
            allCurrencies = allCurrencies.concat(response.data);
            pageNumber++;
            return this.masterDataService.getCurrencies(pageNumber, pageSize);
          }),
        )
        .subscribe(data => {
          allCurrencies = allCurrencies.concat(data.data);
          this.currencies = allCurrencies;
          resolve();
        });

    });

  }

  financialInformationArray() {
    return this.financialInformationForm.get('leadFinancialInfo') as FormArray;
  }

  addFinancialInformation() {
    if (!this.areAllPropertiesNull(this.financialForm.value)) {
      if (this.actionType === MweConstants.editAction) {
        this.financialInformationArray().at(this.index).patchValue(JSON.parse(JSON.stringify(this.financialForm.value)));
        if(this.isEdit){
          this.branchAdded.emit(2);
          }
      } else {
        this.financialInformationArray().push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          financialYear: new FormControl(this.financialForm.get('financialYear')?.value),
          currency: new FormControl(this.financialForm.get('currency')?.value),
          netWorth: new FormControl(this.financialForm.get('netWorth')?.value),
          turnover: new FormControl(this.financialForm.get('turnover')?.value),
          profit: new FormControl(this.financialForm.get('profit')?.value),
          yearlyGrowth: new FormControl(this.financialForm.get('yearlyGrowth')?.value),
          leadId: new FormControl(null),
        }));
        if(this.isEdit){
          this.branchAdded.emit(1);
          }
      }
      this.data=this.financialInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
      this.financialForm.reset();
      this.isMore = true;
      this.isProceed = false;
      this.actionType = null;
    }
  }
  getYearDropdown(){
    for(let year=new Date().getFullYear();year>=1947;year--){
      this.yearDropdown.push(year);
    }
  }
  areAllPropertiesNull(obj: any): boolean {
    for (const key in obj) {
      if (!(obj[key] === null || obj[key] === '') && !(key === 'id' || key === 'active' || key === 'createdBy' || key === 'createdOn' || key === 'modifiedBy' || key === 'modifiedOn')) {
        return false;
      }
    }
    return true;
  }
  toggleIsProceed() {
    this.isProceed = true;
    this.actionType=null;
    this.financialForm.reset();
  }
  
  toggleCancel(){
    this.isProceed = false;
  }

  selectedAction(event: any) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].financialYear === event.row.financialYear &&
          this.data[i].currency === event.row.currency &&
          this.data[i].netWorth === event.row.netWorth &&
          this.data[i].turnover === event.row.turnover &&
          this.data[i].profit === event.row.profit &&
          this.data[i].yearlyGrowth === event.row.yearlyGrowth) {
        this.index = i;
        break;
      }
    }
    switch (event.action) {
      case MweConstants.editAction:
        this.isProceed = true;
        this.actionType = MweConstants.editAction;
        this.financialForm.patchValue(event.row);
        break;
      case MweConstants.deleteAction:
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.financialInformationArray().removeAt(this.index);
        this.financialForm.reset();
        this.actionType = null;
        this.data=this.financialInformationArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
        if(this.data.length==0){
          this.isMore = false;
        }
        if(this.isEdit){
          this.branchAdded.emit(3);
          }
          else{
            this.sweetAlertService.showSuccess('', 'Deleted successfully !!');
          }
        this.cdr.detectChanges();
           
          }
          else {
            Swal.fire(
              'Failed!',
              'Item could not be deleted.',
              'error'
            );
          }

        });

        break;
      default:
        break;
    }
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }
}

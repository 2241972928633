import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EndCustomerService {

  
  ONBOARDING_BASE_URL = environment.ONBOARDING_BASE_URL;
  AUTH_BASE_URL=environment.AUTH_BASE_URL;
  userId:any;
  constructor(private http: HttpClient,private localStorageService: LocalStorageService,) {
    this.localStorageService.getUserDetails().subscribe(data=>{
      this.userId=data?.userId;
    })
   }

  executeGetRequestURL(passedurl: string, queryparams: any) : Observable<any>{
    let queryParameters = new HttpParams();
    if(queryparams){

      Object.keys(queryparams).forEach(key=>{
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/json")

    return this.http.get(this. ONBOARDING_BASE_URL + passedurl, { headers: headers, params: queryParameters });
  }

  public executePostRequestForAuth(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
    return this.http.post(this.AUTH_BASE_URL + url, body, { headers: headers });
  }

  public executePostRequest(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId',this.userId);
    return this.http.post(this.ONBOARDING_BASE_URL + url, body, { headers: headers });
  }

  public executePostRequestWithParam(url: string, body: any,queryparams: any): Observable<any> {
    let queryParameters = new HttpParams();
    if(queryparams){

      Object.keys(queryparams).forEach(key=>{
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }

    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId',this.userId);
    return this.http.post(this.ONBOARDING_BASE_URL + url, body, { headers: headers , params: queryParameters});
  }

  public executePutRequest(url: string, body: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId',this.userId);
    return this.http.put(this.ONBOARDING_BASE_URL + url, body, { headers: headers });
  }

  executeDeleteRequestURL(passedurl: string, queryparams: any) : Observable<any>{
    let queryParameters = new HttpParams();
    if(queryparams){

      Object.keys(queryparams).forEach(key=>{
        if (queryparams[key] != undefined && queryparams[key] != null) {
          queryParameters = queryParameters.set(key, queryparams[key]);
        }
      })
    }
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set('userId',this.userId);
    return this.http.delete(this. ONBOARDING_BASE_URL + passedurl, { headers: headers, params: queryParameters });
  }

  getEndCustomerdetails(id: string){
    return this.http.get<any>(`${this.ONBOARDING_BASE_URL}/end-customer/customerByUserId/${id}`);
  }
  
}

<app-website-header></app-website-header>

<div class="job-details-container p-4">
    <h2 class="page-heading" style="cursor: pointer;" (click)="onBack()">Back to all Jobs</h2>


    <div style="height: 400px; overflow: hidden; margin-top: 1rem;">
        <img [src]="jobPost?.image" alt="featured-image" style="width: 100%;max-height: 100%;object-fit: cover">
    </div>


    <section class="job-details-section mb-5 mt-4">
        <div class="row">
            <div class="col-md-8">
                <div class="card mb-3">
                    <div class="card-header">
                        <div style="float:left;">
                            <h2>{{jobPost?.title}}</h2>
                        </div>

                        <div style="float:right;">
                            <img src="/assets/images/careers-apply-icon.svg" style="cursor: pointer;" alt="apply-icon"
                                (click)="onSaveJob(jobPost)">
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="job-description-container">
                            <div class="title">Job Description</div>
                            <div class="description mt-2" [innerHTML]="jobPost?.description"></div>
                        </div>
                        <div class="job-details-container">
                            <div class="title">Job Details</div>
                            <div class="description mt-2" [innerHTML]="jobPost?.content"></div>
                        </div>
                        <div *ngIf="!companyDetailsHide">
                            <div class="address-container">
                              <div class="title">Address</div>
                              <div class="description mt-2">
                                <img src="/assets/images/careers-address-icon.svg" alt="careers-address-icon">
                                {{jobPost?.address}}
                              </div>
                            </div>
                        </div>

                        <button type="button" class="button button-primary w-auto me-4"
                            (click)="onApplyJob(jobPost?.id)">
                            <img src="/assets/images/angle-arrow-small-right.svg" alt="angle-arrow-small-right">
                            Apply Now</button>

                        <button type="button" class="button button-primary secondary w-auto" (click)="onBack()">
                            <img src="/assets/images/angle-arrow-small-right.svg" alt="angle-arrow-small-right">
                            Cancel</button>
                    </div>
                </div>
            </div>

            <!-- Job Details Sidebar -->
            <div class="col-md-4">
                <div class="card mb-3">
                    <div class="card-body application-dates">
                        <div class="date-item">
                            <img src="/assets/images/application-start.svg" alt="Start Icon" class="date-icon">
                            <div>
                                <div class="date-text">Applications Starts</div>
                                <div class="date-value">{{jobPost?.applicationStartDate | date:'dd/MM/YYYY'}}</div>
                            </div>
                        </div>
                        <div class="separator"></div>
                        <div class="date-item">
                            <img src="/assets/images/application-end.svg" alt="End Icon" class="date-icon">
                            <!-- Add your calendar icon source here -->
                            <div>
                                <div class="date-text">Applications Ends</div>
                                <div class="date-value">{{jobPost?.applicationCloseDate | date:'dd/MM/YYYY'}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <div class="job-categories-section">
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-category.svg" alt="Job Category">
                                </div>
                                <p>Job Category<br><strong>{{jobPost?.category}}</strong></p>
                            </div>
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-type.svg" alt="Job Type"></div>
                                <p>Job Type<br><strong>{{jobPost?.jobType}}</strong></p>
                            </div>
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-level.svg" alt="Level"></div>
                                <p>Level<br><strong>{{jobPost?.careerLevel}}</strong></p>
                            </div>
                        </div>

                        <div class="job-categories-section">
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-experience.svg" alt="Experience">
                                </div>
                                <p>Experience<br><strong>{{jobPost?.experience}} yrs</strong></p>
                            </div>
                            <div class="mb-4" *ngIf="!salaryHide">
                                <div class="icon"><img src="/assets/images/careers-job-salary.svg" alt="Salary"></div>
                                <p>Salary<br>
                                  <strong>{{getCurrencySymbol(jobPost?.salaryCurrency)}}{{jobPost?.salaryFrom | salary}} - 
                                  {{getCurrencySymbol(jobPost?.salaryCurrency)}}{{jobPost?.salaryTo | salary}}</strong>
                                </p>
                              </div>
                              
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-degree.svg" alt="Degree"></div>
                                <p>Degree<br><strong>{{jobPost?.degreeLevel}}</strong></p>
                            </div>
                        </div>

                        <div class="job-categories-section">

                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-speciality.svg" alt="Speciality">
                                </div>
                                <p>Speciality<br><strong>{{jobPost?.speciality}}</strong></p>
                            </div>
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-positions.svg" alt="Positions">
                                </div>
                                <p>Positions<br><strong>{{jobPost?.totalPositions}}</strong></p>
                            </div>
                            <div class="mb-4">
                                <div class="icon"><img src="/assets/images/careers-job-applicants.svg" alt="Applicants">
                                </div>
                                <p>Applicants<br><strong>125</strong></p>
                            </div>
                        </div>

                        <div class="skills mt-3 mb-2">Skills Required</div>
                        <mat-chip class="skills-chip" *ngFor="let skill of jobPost.skills; let i = index"
                            class="me-2 mb-2">{{skill}}</mat-chip>


                        <div class="skills mt-3 mb-2">Tags</div>
                        <mat-chip class="tags-chip" *ngFor="let tag of jobPost.tags; let i = index"
                            class="me-2 mb-2">{{tag}}</mat-chip>

                        <div class="skills mt-3">Share</div>
                        <div class="icon-flex">
                            <div class="icon-wrapper"><img src="/assets/images/facebook-app-symbol.svg"
                                    alt="facebook-app-symbol"></div>
                            <div class="icon-wrapper"><img src="/assets/images/twitter-app-symbol.svg" alt=""></div>
                            <div class="icon-wrapper"><img src="/assets/images/linkedin-app-symbol.svg" alt=""></div>
                            <div class="icon-wrapper"><img src="/assets/images/youtube-app-symbol.svg" alt=""></div>
                            <div class="icon-wrapper"><img src="/assets/images/instagram-app-symbol.svg" alt=""></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


</div>

<app-website-footer></app-website-footer>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
// import {EquipmentComponent } from 'src/app/services/equipment;

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent {

  
  @Input() equipmentForm!:FormGroup;
  @Input() actionType:any;
  equipments:any=[];
 

  @Input() endCustomerData:any;

  @Output() onUpdate:EventEmitter<any>=new EventEmitter();
 

  showEditForm:boolean=false;

  copyEquipmentSetForm:any;

  equipmentData:any=[];

  constructor(private endCustomerService:EndCustomerService) {}

  ngOnInit(): void {
    this.getEquipments();
    
  }
  getEquipments(){
    this.endCustomerService.executeGetRequestURL(MweConstants.equipmentUrl,null).subscribe(data=>{
      if(data && data?.data && data.data.length>0){
        this.equipments=data.data;
        if( this.equipmentForm.get('equipments')?.value &&this.equipmentForm.get('equipments')?.value.length>0){
          this.getEquipmentData();
        }
       
      }
    },error=>{

    })
  }
  update(){
    this.onUpdate.emit(30);
    this.showEditForm=false;
    this.getEquipments();
  }

  cancel(){
    this.showEditForm=false;
  
       this.equipmentForm.get('equipments')?.patchValue([]); 
       
  if(this.copyEquipmentSetForm?.equipments && this.copyEquipmentSetForm?.equipments.length>0){
    this.equipmentForm.get('equipments')?.patchValue(this.copyEquipmentSetForm?.eq); 
  
   
  }
  this.getEquipmentData(); 
   
  
  }

  editEquipments(){
    this.copyEquipmentSetForm=JSON.parse(JSON.stringify(this.equipmentForm.value));
    this.showEditForm=true;
  }

  refresh(){
    this.showEditForm=false;
    this.getEquipmentData();
  }

  getEquipmentData(){
    
    const equipment=this.equipments;
    
    let equipments:any=[];
    
    if(this.endCustomerData?.equipments && this.endCustomerData.equipments.length>0){
      equipments=equipment.filter((obj1:any)=>{
        return this.endCustomerData.equipments.some((obj2:any)=>{
          return obj1.id===obj2.equipmentInfoId;
        })
      })
    }
   
    
    this.equipmentData=equipments;
  }

  remove(i:number): void {
    this.equipmentForm.get('equipments')?.value.splice(i,1);
    this.equipmentForm.get('equipments')?.patchValue(this.equipmentForm.get('equipments')?.value);
}

getequipment(id:any){
  return this.equipments.find((obj:any)=>obj?.id===id)?.name;
 }

}

<app-website-header></app-website-header>
<div class="signup-container">
  <div class="col-3 mx-auto" style="padding-top: 18%">
    <div class="col-12 mx-auto top">
      <p class="exhibit mr-3"><span>Exhibit</span>&nbsp;With Us</p>
    </div>
  </div>
  <div class="col-4 mx-auto" style="padding-top: 2%">
    <p class="subtext">
      Looking for an Opportunity to Showcase and Promote Your Products and
      Services Virtually?
    </p>
  </div>
  <div class="col-10 mx-auto" style="padding-top: 8%">
    <div
      class="d-flex justify-content-center align-items-center signup-cards-container"
    >
      <div
        class="col-md-4 d-flex flex-column align-items-center bg-white p-4 br-2 signup-card"
      >
        <img
          class="icon"
          src="assets/images/account_type/trolley.svg"
          alt=""
          style="width: 64px; height: 64px; margin-top: 60px"
        />
        <h1>You are a Distributor</h1>
        <p class="text-center" style="max-width: 60vw; max-height: 17vh">
          Connect with Original Equipment<br />
          Manufacturers (OEMs) to start<br />
          distributing and selling their products.
        </p>
        <a
          routerLink="/distributor/signup"
          class="btn btn-primary d-flex align-items-center"
        >
          Sign up
          <img
            src="assets/images/account_type/arrow right top.svg"
            alt=""
            class="ml-2"
            height="18px"
            width="20px"
          />
        </a>
      </div>
      <div
        class="col-md-4 d-flex flex-column align-items-center bg-white p-4 br-2 green-border signup-card"
      >
        <img
          class="icon"
          src="assets/images/account_type/factory.svg"
          alt=""
          style="width: 64px; height: 64px; margin-top: 60px"
        />
        <h1>You are a Manufacturer</h1>
        <p class="text-center" style="max-width: 60vw; max-height: 17vh">
          Showcase your products, Connect with<br />
          new Distributors & receive<br />
          international leads.
        </p>
        <a
          routerLink="/oem/signup"
          class="btn btn-primary d-flex align-items-center"
        >
          Sign up
          <img
            src="assets/images/account_type/arrow right top.svg"
            alt=""
            class="ml-2"
            height="18px"
            width="20px"
          />
        </a>
      </div>
    </div>
  </div>
</div>


<app-website-footer></app-website-footer>
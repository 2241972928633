import { Component, Input, OnInit,ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup ,Validators} from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import { OemManagementService } from 'src/app/services/super-admin/oem-management.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { Currency } from 'src/app/models/master-data.model';
import { concatMap, takeWhile } from 'rxjs';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-company-finacial-info',
  templateUrl: './company-finacial-info.component.html',
  styleUrls: ['./company-finacial-info.component.scss']
})
export class CompanyFinacialInfoComponent {
  @Input() financialInformationForm!: FormGroup;
  @Output() financialAdded = new EventEmitter<number>();

  columns: ListColumn[] = [
    { name: 'Financial Year', property: 'financialYear', visible: true, isModelProperty: true, isText: true },
    { name: 'Currency', property: 'currency', visible: true, isModelProperty: true, isText: true },
    { name: 'Net Worth', property: 'netWorth', visible: true, isModelProperty: true, isText: true },
    { name: 'Turnover', property: 'turnover', visible: true, isModelProperty: true, isText: true },
    { name: 'Profit', property: 'profit', visible: true, isModelProperty: true, isText: true },
    { name: 'Yearly Growth %', property: 'yearOfGrowth', visible: true, isModelProperty: true, isText: true },
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];

  data: any[] = [];
  financialForm!: FormGroup;
  actionType: any;
  isMore: boolean = false;
  index: number = 0;
  isProceed: boolean = false;
  yearDropdown:any=[]
  currencies: Currency[] = [];
  dataWithIndex: any[] = [];


  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef , private oemManagementService : OemManagementService,
     private masterDataService: MasterDataService, private sweetAlertService: SweetAlertService) {}

  async ngOnInit(): Promise<void> {
    this.initializeFinancialForm();
    this.getYearDropdown();
    this.sortFinancialInformation();
    // this.data = this.financialInformationArray().value;
    
    await this.loadCurrencies();
  }
  
  
  

  loadCurrencies(): Promise<void> {
    return new Promise((resolve, reject) => {
      let pageNumber = 0;
      const pageSize = 100;
      let allCurrencies: Currency[] = [];
      let morePages = true;

      this.masterDataService.getCurrencies(pageNumber, pageSize)
        .pipe(
          takeWhile(() => morePages),
          concatMap(response => {
            if (response.data.length === 0) {
              morePages = false; 
              return ([]); 
            }
            allCurrencies = allCurrencies.concat(response.data);
            pageNumber++;
            return this.masterDataService.getCurrencies(pageNumber, pageSize);
          }),
        )
        .subscribe(data => {
          allCurrencies = allCurrencies.concat(data.data);
          this.currencies = allCurrencies;
          resolve();
        });

    });

  }

  

  initializeFinancialForm() {
    this.financialForm = this.fb.group({
      oemId: new FormControl(''),
      financialInfoId: new FormControl(''),
      financialYear: new FormControl(''),
      currency: new FormControl(''),
      netWorth: new FormControl(''),
      turnover: new FormControl(''),
      profit: new FormControl(''),
      yearOfGrowth: new FormControl(''),
    });
  }

  financialInformationArray() {
    return this.financialInformationForm.get('financialInformation') as FormArray;
  }

  toggleIsProceed() {
    this.isProceed = true;
    this.financialForm.reset();
    this.actionType=null;
    this.cdr.detectChanges();
  }
  
  toggleCancel(){
    this.isProceed = false;
  }
  

  addFinancialInformation() {
    if (!this.areAllPropertiesNull(this.financialForm.value) && this.financialForm.valid) {
      if (this.actionType === MweConstants.editAction) {
        this.financialInformationArray().at(this.index).patchValue(JSON.parse(JSON.stringify(this.financialForm.value)));
        this.dataWithIndex[this.index] = {
          ...this.financialForm.value,
          originalIndex: this.index
        };
        this.financialAdded.emit(2);

      } else {

        this.financialInformationArray().push(this.fb.group({
          oemId: new FormControl(''),
          financialInfoId: new FormControl(''),
          financialYear: new FormControl(this.financialForm.get('financialYear')?.value),
          currency: new FormControl(this.financialForm.get('currency')?.value),
          netWorth: new FormControl(this.financialForm.get('netWorth')?.value),
          turnover: new FormControl(this.financialForm.get('turnover')?.value),
          profit: new FormControl(this.financialForm.get('profit')?.value),
          yearOfGrowth: new FormControl(this.financialForm.get('yearOfGrowth')?.value),
        }));
        this.financialAdded.emit(1);
       
      }
      this.sortFinancialInformation();
      
      this.financialForm.reset();
      this.actionType = null;
      this.removeValidators()
      this.isMore=true
      this.isProceed = false;
    }
  }


  areAllPropertiesNull(obj: any): boolean {
    const requiredFields = ['financialYear', 'currency', 'netWorth', 'turnover', 'profit', 'yearOfGrowth'];
    for (const key of requiredFields) {
      const value = obj[key];
      if (value == null || (typeof value === 'string' && value.trim() === '')) {
        this.sweetAlertService.showError('Error', 'Please fill all required fields.');
        this.showValidators();
        return true;
      }
    }
  
    return false; 
  }
  
  removeValidators() {
    this.financialForm.get('financialYear')?.clearValidators();
    this.financialForm.get('currency')?.clearValidators();
    this.financialForm.get('netWorth')?.clearValidators();
    this.financialForm.get('turnover')?.clearValidators(); 
    this.financialForm.get('profit')?.clearValidators();
    this.financialForm.get('yearOfGrowth')?.clearValidators();
  
    this.financialForm.get('financialYear')?.updateValueAndValidity();
    this.financialForm.get('currency')?.updateValueAndValidity();
    this.financialForm.get('netWorth')?.updateValueAndValidity();
    this.financialForm.get('turnover')?.updateValueAndValidity();
    this.financialForm.get('profit')?.updateValueAndValidity();
    this.financialForm.get('yearOfGrowth')?.updateValueAndValidity();
  }
  
  
  showValidators() {
    this.financialForm.get('financialYear')?.setValidators([Validators.required]);
    this.financialForm.get('currency')?.setValidators([Validators.required]);
    this.financialForm.get('netWorth')?.setValidators([Validators.required]);
    this.financialForm.get('turnover')?.setValidators([Validators.required]);
    this.financialForm.get('profit')?.setValidators([Validators.required]);
    this.financialForm.get('yearOfGrowth')?.setValidators([Validators.required]);

  
    this.financialForm.get('financialYear')?.updateValueAndValidity();
    this.financialForm.get('currency')?.updateValueAndValidity();
    this.financialForm.get('netWorth')?.updateValueAndValidity();
    this.financialForm.get('turnover')?.updateValueAndValidity();
    this.financialForm.get('profit')?.updateValueAndValidity();
    this.financialForm.get('yearOfGrowth')?.updateValueAndValidity();
  }
    

  
  sortFinancialInformation() {
    const sortedData = this.financialInformationArray().controls.sort((a, b) => {
      return a.value.financialYear - b.value.financialYear;
    });
    this.financialInformationForm.setControl('financialInformation', this.fb.array(sortedData));
    this.data = this.financialInformationArray().value;
    if(this.data.length>0){
      this.isMore=true;
    }
  }
  
  
  getYearDropdown(){
    for(let year=new Date().getFullYear();year>=1947;year--){
      this.yearDropdown.push(year);
    }
  }
 
  selectedAction(event: any) {
    const index = this.data.findIndex(item => 
      item.financialYear === event.row.financialYear &&
      item.currency === event.row.currency &&
      item.netWorth === event.row.netWorth &&
      item.turnover === event.row.turnover &&
      item.profit === event.row.profit &&
      item.yearOfGrowth === event.row.yearOfGrowth
    );
  
    if (index !== -1) {
      this.index = index;
    } else {
      console.error('Row not found for the selected action');
      return;
    }
  
    switch (event.action) {
      case MweConstants.editAction:
        this.actionType = MweConstants.editAction;
        this.financialForm.patchValue(this.financialInformationForm.get('financialInformation')?.value[this.index]);
        this.isProceed = true;
        break;
      case MweConstants.deleteAction:

      Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRevenue(index);
          this.financialForm.reset();
          this.actionType = null;  
          if(this.data.length==0){
            this.isMore=false;
          }
             
        }
        else{
          Swal.fire('Cancelled', 'Record is safe :)', 'error');
        }
      });
      break;
      default:
        break;
    }
  }
  
  
  
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  deleteRevenue(index: number) {
    const annualRevenueFormArray = this.financialInformationForm.get('financialInformation') as FormArray;
    if (annualRevenueFormArray && index >= 0 && index < annualRevenueFormArray.length) {
      const revenue = annualRevenueFormArray.at(index).value;
      if (revenue?.financialInfoId) {
        this.oemManagementService.deleteFinancialInfoById(revenue.financialInfoId).subscribe(
          response => {
            annualRevenueFormArray.removeAt(index);
            this.sortFinancialInformation();
            this.sweetAlertService.showSuccess('', 'Deleted successfully !!');
          
          },
          error => {
            console.error(`Error deleting revenue with ID ${revenue.financialInfoId}:`, error);
          }
        );
      } else {
        annualRevenueFormArray.removeAt(index);
        this.sortFinancialInformation();
      }
    }
  }
  
  
 
  

}

import { Component, Inject, Input, ViewChild, inject } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Distributers, TodoItemFlatNode, TodoItemNode, noWhitespaceValidator } from 'src/app/models/product-settings-model';
import { AllProductService } from 'src/app/services/shared/product-settings/all-products.service';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import { MasterDataService } from 'src/app/services/master-data.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CategoryService } from 'src/app/services/shared/product-settings/category.service';
import { ChecklistDatabase } from 'src/app/services/shared/product-settings/check-list-database';
import { concatMap, takeWhile } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips'; 
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Country } from 'src/app/models/master-data.model';

@Component({
  selector: 'app-add-edit-all-products',
  templateUrl: './add-edit-all-products.component.html',
  styleUrls: ['./add-edit-all-products.component.css'],
  providers: [ChecklistDatabase]
})
export class AddEditAllProductsComponent {
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl!: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener!: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource!: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  categoryData : any;
  isAnyNodeSelected = false;
  selectedTab : number = 0;
  selectedDistributors!: string;
  isAddSpecification:boolean = false;
  specificationRowCount = 0;
  showNoDistributorFounds = true;
  selectedOEMName = '';
  isEditMode = false;
  showImageError = false;

  oem: any []= [];
  id : any;

  @Input() allProducts: FormGroup;
  @Input() photosForm!:FormGroup;
  @Input() productDetails!:FormGroup;
  @Input() preselectedDistributers: string[] = [];
  distributersDisplyColumns: string[] = ['select','continent', 'country', 'state', 'city', 'distributorName'];
  distributerData: any[] = [ ];
  distributerFilterData: any[] = [ ];
  specifications! : FormArray;
  photos! : [];
  images: any[] = [];
  catalogs: any[] = [];
  editImages: any[] = [];
  editCatalogs: any[] = [];
  imageFiles: any[] = [];
  catalogFiles: any[] = [];
  uploadedImagesVedioCats : string = '';
  countryDropdown:any=[];
  distributorId:any =[];
  continents!: any[];
  countries!: any[];
  countryControl = new FormControl();
  filteredCountries: Country[] | undefined;

  selectedOEM: string = '';
  addOnBlur = true;
  selectable = true;
  removable = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  announcer = inject(LiveAnnouncer);

  allSelectedCountries: string[] = [];
  showAllCountries: boolean = false;

  postJobForm = new FormGroup({
    video: new FormControl('')
  });
  constructor(
    private dialogRef: MatDialogRef<AddEditAllProductsComponent>, 
    private formBuilder: FormBuilder,
    private allProductService : AllProductService,
    private sweetAlertService : SweetAlertService,
    private masterDataService : MasterDataService,
    private _database: ChecklistDatabase,
    private categoryService : CategoryService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.allProducts = this.formBuilder.group({
      oemId: ['',Validators.required],
      name: ['', [Validators.required, noWhitespaceValidator()]],
      productSku: ['', [Validators.required, noWhitespaceValidator()]],
      dateManufacture: ['', Validators.required],
      soldTillNoe: ['', Validators.required],
      description : [''],
      specifications: this.formBuilder.array([]), 
      images : ['', Validators.required],
      video: ['', [Validators.pattern(/^(https:\/\/|www\.).*/)]],
      catalogs : [''],
      distributorId : [''],
      continent : [''],
      country : [''],
      tags : [''],
      certificates : [''],
      category : [''],
      startWith : [''],
      selectedDistributorCtrl : ['']
    });

   if(this.data) {
    if(this.data?.country?.length > 0){
      const trimmedString = data?.country?.replace(/[{}]/g, '');
      if(trimmedString !== ''){
        const countryArray = trimmedString?.split(',').map((country: any) => country.trim()) || [];
        this.allSelectedCountries = [...this.allSelectedCountries,...countryArray];
      }
    }
    this.id = data.id;
    }

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  populateFormArray(specificationsData : any[]) {
    specificationsData.forEach(spec => {
      this.addSpecification({
        name: spec.name,
        type: spec.type,
        fromUnit: spec.fromUnit,
        toUnit: spec.toUnit,
        fromValue: spec.fromValue, 
        toValue : spec.toValue,
        isRange: spec.isRange 
      });
    });  
  }

  addSpecification(specification:any = null) {
    const newSpecGroup = this.formBuilder.group({
      name: [specification ? specification.name : null, Validators.required],
      type: [specification ? specification.type : 'Number', Validators.required],
      fromUnit: [{ value: specification ? specification.fromUnit : '', disabled: !specification }, Validators.required],
      toUnit: [{ value: specification ? specification.toUnit : '', disabled: !specification }, Validators.required],
      fromValue: [specification ? specification.fromValue : '', Validators.required], 
      toValue: [specification ? specification.toValue : '', Validators.required], 
      isRange:  [specification ? specification.isRange : 'false'], 
    });  
    this.specificationsData.push(newSpecGroup);
  }

  get specificationsData(): FormArray {
    return this.allProducts.get('specifications') as FormArray;
  }

   getSpecification()  {
     return this.allProducts.get('specifications') as FormArray<FormGroup>;
   }

   get distributors(): FormArray {
    return this.allProducts.get('distributors') as FormArray;
  }

  selectedNode = new TodoItemFlatNode();
  ngOnInit() {
    this.getAllDistributors();
    this.loadContinents();
    this.countryControl.valueChanges.subscribe(value => {
      if (!value?.countryName) {
        this.filteredCountries = this.countries.filter(country =>
          country.countryName.toLowerCase().includes(value?.toLowerCase() || '')
        );
      }
    });
    this.getAllCategories();
    this.allProducts.get('startWith')?.valueChanges.subscribe(value => {
      console.log('this.startWith init', value)
      this.getOEM(value)
    }); 

    this.allProducts.patchValue(this.data);
    if(this.data) {
      this.allProducts.patchValue({
        dateManufacture: new Date(this.data.dateManufacture),
      });
      this.isEditMode = true;
      this.selectedOEM = this.data.oemName;
      this.allProducts.get('startWith')?.setValue('');
      this.oemId?.setValue(this.data.oemId);
      this.editImages = this.data.images;
      this.editCatalogs = this.data.catalogs;
      this.soldTillNoe?.setValue(this.data.soldTillNow);
      this.loadCountries([this.data.continent]);
      this.processImages(this.data.images);
      this.processCatalogs(this.data.catalogs);
      this.selectedNode.item = this.data.categoryName;
      this.selectedNode.id = this.data.categoryId;
      this.oemChangeOnEdit(this.data.oemId);
      this.populateFormArray(this.data.specifications);
    }

    this.allProducts.get('selectedDistributorCtrl')?.setValue('assignDistributor');
    this.selectedDistributors = this.allProducts.get('selectedDistributorCtrl')?.value;
  }


  getLevel = (node: TodoItemFlatNode) => node.level;
  isExpandable = (node: TodoItemFlatNode) => node.expandable;
  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;
  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable =!!node.children && node.children.length > 0
    flatNode.id = node.id;  // Ensure id is set here
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.selectedNode = node;
    node.selected = !node.selected;
    this.updateSelectionState();
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }
  updateSelectionState(): void {
    
    this.isAnyNodeSelected = this.treeControl.dataNodes.some(node => node.selected);
    this.dataSource.data = [...this.dataSource.data]; // Trigger change detection
    console.log('this.treeControl.dataNodes', this.treeControl.dataNodes);
    
    console.log('isAnyNodeSelected', this.isAnyNodeSelected);
    
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    console.log('node-1', node);
    this.selectedNode = node;
    const categoryPayload = {
      id:  node.id,
      name:  node.item,
   };
    this.category?.setValue(categoryPayload); 
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    node.selected = !node.selected;
    this.updateSelectionState();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  dynamicJsonStructure: { [key: string]: any } = {};
  getAllCategories() {
    this.categoryService.getAllParentCategories().subscribe({
      next: (response) => {
        if (response.data.length > 0) {
          console.log('response.data-1', response.data);
          this.categoryData = response.data;
          this._database.initialize(this.categoryData);
        } 
      },
      error: (error) => {
        console.error('Error fetching levels data:', error);
      }
    });
  }

  processImages(urls: string[]): void {
    urls.forEach(url => {
      this.fetchImageDetails(url);
    });
  }

  processCatalogs(urls: string[]): void {
    urls.forEach(url => {
      this.fetchCatalogsDetails(url);
    });
  }

  fetchImageDetails(url: string): void {
    const fileName = this.extractFileName(url);
    const file = {
      name: fileName,
      size: Math.floor(Math.random() * 1024 * 1024), 
      url: url,
      progress: Math.floor(Math.random() * 100)
    };

    this.imageFiles.push({
      file: file,
      name: file.name,
      size: (file.size / 1024).toFixed(2), 
      url: file.url,
      progress: file.progress
    });
  }

  fetchCatalogsDetails(url: string): void {
    const fileName = this.extractFileName(url);
    const fileExtension = url.split('.').pop(); 
    const definedExtensions = ['jpeg', 'png', 'jpg']; 
    const fileType = definedExtensions.includes(fileExtension as string) ? 'image' : 'pdf'; 
    
    const file = {
      name: fileName,
      size: Math.floor(Math.random() * 1024 * 1024), 
      url: url,
      progress: Math.floor(Math.random() * 100),
      type: fileType
    };

    this.catalogFiles.push({
      file: file,
      name: file.name,
      size: (file.size / 1024).toFixed(2), 
      url: file.url,
      type: fileType,
      progress: file.progress
    });

    console.log('catalogFiles', this.catalogFiles);
    
  }

  markSelectedNodes() {
    if (this.isEditMode) 
      { 
        this.treeControl.dataNodes.forEach(node => {
          if (node.id === this.id) {
              node.selected = true;
          }
        });
          if(!this.isAnyNodeSelected)  this.todoLeafItemSelectionToggle(this.selectedNode)
            this.treeControl.expandAll();
      }
  }
  extractFileName(url: string): string {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  get oemId() {
    return this.allProducts.get('oemId');
  }

  get name() {
    return this.allProducts.get('name');
  }

  get productSku() {
    return this.allProducts.get('productSku');
  }

  get dateManufacture() {
    return this.allProducts.get('dateManufacture');
  }

  get soldTillNoe() {
    return this.allProducts.get('soldTillNoe');
  }

  get description() {
    return this.allProducts.get('description');
  }

  get productSpecifications() {
    return this.allProducts.get('specifications');
  }

  get category() {
    return this.allProducts.get('category');
  }

  get tags(){
    return this.allProducts.get('tags');
  }

  get certificates() {
    return this.allProducts.get('certificates');
  }


  changeTab(tab : number) {
    this.markSelectedNodes();
    if(tab === 0) {
      this.selectedTab = tab;
    }else if(tab === 1) {
      if (this.oemId?.valid) {
        this.selectedTab = tab;
      } else {
        this.oemId?.markAsTouched(); 
      }
    } else if (tab === 2) {
      if (this.category?.value !== '') {
        this.selectedTab = tab;
      } else {
        this.category?.markAsTouched(); 
      }
    } else if(tab === 3) {
      if (this.name?.valid && this.productSku?.valid && this.dateManufacture?.valid && this.soldTillNoe?.valid) {
        this.selectedTab = tab;
      } else {
        this.name?.markAsTouched(); 
        this.productSku?.markAsTouched(); 
        this.dateManufacture?.markAsTouched(); 
        this.soldTillNoe?.markAsTouched(); 
        //this.description?.markAsTouched(); 
      }
    } else if (tab === 4) {
      if (this.imageFiles.length > 0 ) {
        this.selectedTab = tab;
        this.showImageError = false;
      } else {
        this.showImageError = true;
      }
    } else {
      this.selectedTab = tab;
    }
  }

  next(tab : number) {
    if(tab === 0) {
      if (this.oemId?.valid) {
        this.selectedTab = tab + 1;
        
        this.markSelectedNodes();

      } else {
        this.oemId?.markAsTouched(); 
      }
    } else if (tab === 1) {
      if (this.category?.value !== '') {
        this.selectedTab = tab + 1;
      } else {
        this.category?.markAsTouched(); 
      }
    } else if (tab === 2) {
      if (this.name?.valid && this.productSku?.valid && this.dateManufacture?.valid && this.soldTillNoe?.valid) {
        this.selectedTab = tab + 1;
      } else {
        this.name?.markAsTouched(); 
        this.productSku?.markAsTouched(); 
        this.dateManufacture?.markAsTouched(); 
        this.soldTillNoe?.markAsTouched(); 
        //this.description?.markAsTouched(); 
      }
    } else if (tab === 3) {
      if (this.imageFiles.length === 0) {
        // this.selectedTab = tab + 1;
        this.showImageError = true;
      }
      else if( this.postJobForm?.get('video')?.invalid){
        this.postJobForm?.get('video')?.markAsTouched();
      }
      else if (this.catalogFiles.length === 0) {
        console.log("upload product catelog is required")
      } 
      else {
        this.showImageError = false;
        this.selectedTab = tab + 1;
      }
    } else {
      this.selectedTab = tab + 1
    }
  }

  back(tab : number) {
    this.selectedTab = tab - 1;
  }

  onContinentChange(event: any) {
    const continentName = event.value;
    this.loadCountries([continentName]);
  }

  loadContinents() {
    this.masterDataService.getContinents().subscribe(data => {
      this.continents = data.data;
      if(this.data && this.data?.continent){
        const arr = this.addExistingData();
        arr.forEach((data: any)=>this.onContinentChange({value: data }))
      }
      console.log('this.continents', this.continents)
    });
  }

  loadCountries(continentNames: string[]) {
    let pageNumber = 0;
    let pageSize = 100;
    let allCountries: any[] = [];
    let morePages = true;

    this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames)
      .pipe(
        takeWhile(() => morePages),
        concatMap(response => {
          if (response.data.length === 0) {
            morePages = false; // No more items to fetch
            return [];
          }
          allCountries = allCountries.concat(response.data);
          pageNumber++;
          return this.masterDataService.getCountries(pageNumber, pageSize, '', continentNames);
        })
      )
      .subscribe(data => {
        allCountries = allCountries.concat(data.data);
        this.countries = allCountries;
        this.filteredCountries = allCountries;
      });

      console.log('this.countries', this.countries)
      //this.allProducts.get('country')?.setValue('');
  }

  getOEM(oemName : string) {
    this.allProductService.getOEMs(0,15, oemName).subscribe({
      next: (response) => {
        this.oem = response.data;
      },
      error: (error) => {
        console.error('Error fetching oem data:', error);
      }
    });
  }

  getPhotos(){
    return this.allProducts.get('photos') as FormArray<FormGroup>;
  }

  invalidFileType = false;
  fileType = '';
    uploadPhoto(event:any, fileInput: HTMLInputElement){
      let uploadData = new FormData();
      uploadData.append('file', event.target.files[0]);
      uploadData.append('destinationKey',MweConstants.productSettingAllProduct+'photos/'); 

      const files = event.target.files;
      if(!event.target.files[0].type.includes('image/')){
        this.fileType = event.target.files[0].type;
        this.invalidFileType = true;
        return;
      }
      this.invalidFileType = false
      if (files) {
        this.showImageError = false;
        for (let file of files) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.imageFiles.push({
              file: file,
              name: file.name,
              size: (file.size / 1024).toFixed(2), 
              url: reader.result,
              progress: (file.size / 1024).toFixed(2)
            });
          };
          reader.readAsDataURL(file);
        }
      }
      fileInput.value = '';
      this.allProductService.uploadFile(MweConstants.uploadFile,uploadData).subscribe(data=>{
        if(data && data?.data){
          this.uploadedImagesVedioCats += data.data + ', ';
          this.allProducts.get('image')?.setValue(this.uploadedImagesVedioCats);
          this.images.push(data.data);
          this.editImages.push(data.data);
        }
      },error=>{
        console.log(error);
      })
    }

    onDragOver(event: DragEvent){
      event.preventDefault();
      console.log("file is dragged over a targeted area!!")
    }
  
    onDrop(event: DragEvent, inputElement:  HTMLInputElement){
      event.preventDefault();
      const files = event.dataTransfer?.files;
      if(files){
        this.uploadPhoto({ target: { files } } , inputElement);
      }
    }

    deleteImage(index: number) {
      this.imageFiles.splice(index, 1);
      this.images.splice(index, 1);
      this.editImages.splice(index, 1);
      if(this.imageFiles.length == 0  || this.editImages.length == 0){ 
        this.showImageError = true;
      }
    }

    onDropCatelogFile(event: DragEvent, inputElement:  HTMLInputElement){
      event.preventDefault();
      const files = event.dataTransfer?.files;
      if(files){
        this.uploadCatelog({ target: { files } } , inputElement);
      }
    }

    invalidCatalogsType = false;
    catalogFileType = '';
    uploadCatelog(event:any, fileInput: HTMLInputElement){
      let uploadData = new FormData();
      uploadData.append('file', event.target.files[0]);
      uploadData.append('destinationKey',MweConstants.productSettingAllProduct+'catelogs/'); 

      const files = event.target.files;
      if(!['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(event.target.files[0].type)){
        this.catalogFileType = event.target.files[0].type;
        this.invalidCatalogsType = true;
        return;
      }

      this.invalidCatalogsType = false
      if (files) {
        for (let file of files) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const fileType = file.type.startsWith('image/') ? 'image' : 'pdf';
            console.log('fileType', fileType);
            
            this.catalogFiles.push({
              file: file,
              name: file.name,
              type: fileType,
              size: (file.size / 1024).toFixed(2), 
              url: reader.result,
              progress: Math.floor(Math.random() * 100)
            });
          };
          if (file.type.startsWith('image/')) {
            reader.readAsDataURL(file);
          } else {
            reader.readAsArrayBuffer(file);
          }
        }
      }
      fileInput.value = '';
      this.allProductService.uploadFile(MweConstants.uploadFile,uploadData).subscribe(data=>{
        if(data && data?.data){
         this.catalogs.push(data.data);
         this.editCatalogs.push(data.data);
        }
      },error=>{
        console.log(error);
      })
    }
  
    deleteCatelog(index: number) {
      this.catalogFiles.splice(index, 1);
      this.catalogs.splice(index, 1);
      this.editCatalogs.splice(index, 1)
    }

    removeOEM() {
      this.selectedOEM = '';
      this.allProducts.get('oemId')?.setValue(null);
      this.allProducts.get('startWith')?.setValue('');
      this.isEditMode = false;
    }
  
    addOEM(event: MatChipInputEvent) {
      event.chipInput!.clear();
    }

    loadOEM() {
      this.getOEM('');
    }

    getOEMNameById(givenId: string): string {
      const item = this.oem.find(entry => entry.id === givenId);
      return item ? item.name : '';
    }
    
    onOEMChange(event : any) {
      this.allProducts.get('startWith')?.setValue('');
      this.isEditMode = false;
      this.selectedOEM = event.option.value.name
      this.allProducts.get('oemId')?.setValue( event.option.value.id);
      this.selectedOEMName = this.getOEMNameById(event.option.value.id);
      this.distributerData = this.distributerFilterData.filter((entry) => entry.oemId === event.option.value.id);
      if(this.distributerData.length > 0) {
        this.showNoDistributorFounds = false;
      } else {
        this.showNoDistributorFounds = true;
      }
    }

    oemChangeOnEdit(oemId : any) {
      this.selectedOEMName  = this.getOEMNameById(oemId);
      this.distributerData = this.distributerFilterData.filter((entry) => entry.oemId === oemId);
      if(this.distributerData.length > 0) {
        this.showNoDistributorFounds = false;
      } else {
        this.showNoDistributorFounds = true;
      }
    }

    getAllDistributors() {
      this.allProductService.getAllDistributors().subscribe({
        next: (response) => {
          if(response.data.length > 0) {
            this.distributerFilterData = response.data;
            this.distributerData = response.data;
            console.log('this.distributerData', this.distributerData)
            if(this.data) {
              this.oemChangeOnEdit(this.data.oemId);
              this.preselectedDistributers = this.data.distributorsId;
              if(this.preselectedDistributers){
                this.preselectedDistributers.forEach(id => {
                  const distributer = this.distributerData.find(dist => dist.distributorId === id);
                  if (distributer) {
                    this.selection.select(distributer);
                  }
                });
              }
            }
          } 
        },
        error: (error) => {
          console.error('Error fetching tertiary data:', error);
        }
      });
    }

    distributersDataSource = new MatTableDataSource(this.distributerData);
    selection = new SelectionModel<Distributers>(true, []);
    onDistributorSelect(value :string) {
      this.selectedDistributors = value;
    }

    save () {

    }

    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.distributersDataSource.data.length;
      return numSelected === numRows;
    }

    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.distributersDataSource.data.forEach(row => this.selection.select(row));
    }

    checkboxLabel(row?: Distributers): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
    }

    formatAsBracedString = (data: any): string => {
      if (Array.isArray(data)) {
          return `{${data.map(item => item.toString().trim()).join(', ')}}`;
      } else if (typeof data === 'string') {
          return `{${data.split(',').map(item => item.trim()).join(', ')}}`;
      } else {
          return '{}'; // Default empty format
      }
  };

  onFormSubmit() {
    const formData = this.allProducts.value;
    this.selection.selected.forEach(item => {
      let index: number = this.distributerData.findIndex(d => d === item);
      this.distributorId.push(this.distributerData[index].distributorId);
    });

    console.log('form data', formData);
      if (this.data) {
       const payload = {
        oemId:  formData.oemId,
        name: formData.name,
        productSku: formData.productSku,
        dateManufacture: formData.dateManufacture,
        soldTillNoe: formData.soldTillNoe,
        description : formData.description,
        specifications:formData.specifications,
        images : this.editImages,
        video :  formData.video,
        catalogs : this.editCatalogs,
        distributorId :this.distributorId,
        continent: this.formatAsBracedString(formData.continent),
        country: this.formatAsBracedString([...this.allSelectedCountries]),
        tags : formData.tags === '' ? [] : formData.tags,
        certificates : formData.certificates === '' ? [] : formData.certificates,
        category : formData.category
     };

     console.log('edit payload', payload);
        this.allProductService.updateProduct(payload, this.id).subscribe(
          (response) => {
            console.log("update success", response);
            this.dialogRef.close(true);
            this.sweetAlertService.showSuccess('', 'Product updated successfully !!');
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to update product !!');
          }
        );
      } else {
        // Add logic
        const payload = {
          oemId:  formData.oemId,
          name: formData.name,
          productSku: formData.productSku,
          dateManufacture: formData.dateManufacture,
          soldTillNoe: formData.soldTillNoe,
          description : formData.description,
          specifications:formData.specifications,
          images : this.images,
          video : formData.video,
          catalogs :this.catalogs,
          distributorId :this.distributorId,
          continent: this.formatAsBracedString(formData.continent),
          country: this.formatAsBracedString([...this.allSelectedCountries]),
          tags : formData.tags === '' ? [] : formData.tags,
          certificates : formData.certificates === '' ? [] : formData.certificates,
          category : formData.category
       };
       console.log('save product payload ', payload)
        this.allProductService.saveProduct(payload).subscribe(
          (response) => {
            this.dialogRef.close(true);
            if(response.statusCode === '400 BAD_REQUEST') {
              this.sweetAlertService.showError('', response.message);
            } else {
              this.sweetAlertService.showSuccess('', 'Product added successfully !!');
            }
          },
          (error) => {
            console.log("error", error);
            this.dialogRef.close(true);
            this.sweetAlertService.showError('', 'Failed to add product !!');
          }
        );
      }
    }

    closeDialog(){
      this.dialogRef.close(true);
    }

    // Method to check and convert the video link
  checkAndConvertLink(event: any) {
    const inputUrl = event.target.value;
    const convertedUrl = this.convertToEmbeddedUrl(inputUrl);
    
    if (convertedUrl !== inputUrl) {
      this.postJobForm.patchValue({ video: convertedUrl });
    }
  }

  // Method to convert URL to embedded format
  convertToEmbeddedUrl(url: string): string {
    // Check if the URL is already an embedded link
    if (url.includes('youtube.com/embed') || url.includes('vimeo.com/video')) {
      return url; // Already in embedded format
    }

    // Convert YouTube URL
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const youtubeMatch = url.match(youtubeRegex);
    if (youtubeMatch) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }

    // Convert Vimeo URL
    const vimeoRegex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;
    const vimeoMatch = url.match(vimeoRegex);
    if (vimeoMatch) {
      return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }

    // Return original URL if no match found
    return url;
  }

  toggleSelection(event: any){
    const country = event?.option?.value?.countryName
    console.log("Selected counntry is ",country);
    if(!this.allSelectedCountries.includes(country)){
      this.allSelectedCountries.push(country);
    }else{
      this.allSelectedCountries = this.allSelectedCountries.filter((countryData)=>countryData !== country);
    }
    this.countryControl.reset();
  }

  moreSelectedCountries(){
    this.showAllCountries = !this.showAllCountries;
  }

  addExistingData(){
    const array = this.data?.continent.replace(/[{}]/g, '').split(',').map((c: string) => c.trim());
    this.allProducts.get('continent')?.setValue(array);
    return array;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OEMUrls } from 'src/app/utils/apiUrls';

@Injectable({
  providedIn: 'root'
})
export class MessageService {



  constructor(private http: HttpClient) { }

public getLastMessages(userId : string): Observable<any> {
  const url = `${OEMUrls.enquiry}/recent-messages`;
  const headers = new HttpHeaders().set('Accept', 'application/json');
  const params = new HttpParams().set('userId', userId);
  return this.http.get<any>(url, { headers: headers, params: params });
}

public getMessagesByNonGroup(userId1: string, userId2: string): Observable<any> {
   const params = new HttpParams()
  .set('userId1', userId1)
  .set('userId2', userId2);
const url = `${OEMUrls.message}/between`;
return this.http.get<any>(url, { params });
}

public getGroupsByUser(userId : string): Observable<any> {
  const url = `${OEMUrls.message}/groups/${userId}`;
  const headers = new HttpHeaders().set('Accept', 'application/json');
  // const params = new HttpParams().set('userId', userId);
  return this.http.get<any>(url, { headers: headers});
}

public getMessagesByGroup(groupId : string): Observable<any> {
  const url = `${OEMUrls.message}/recent-messages/${groupId}`;
  return this.http.get<any>(url);
}
public getConversationsById(id : string): Observable<any> {
  const url = `${OEMUrls.message}/conversations/${id}`;
  return this.http.get<any>(url);
}

public getMessagesBetweenUsers(userId1: string, userId2: string): Observable<any> {
  const url = `${OEMUrls.message}/between?userId1=${userId1}&userId2=${userId2}`;
  return this.http.get<any>(url);
}

public getUserNameByUserId(userId: string): Observable<any> {
  const url = `${OEMUrls.namebyUserId}${userId}`;
  return this.http.get<any>(url);
}

public getConversationWithUser(userId : string): Observable<any> {
const url = `${OEMUrls.message}/conversations/${userId}`
  return this.http.get<any>(url);
}
}
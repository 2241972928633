<div class="modal-popup" mat-dialog-content>
    <div class="popup-heading">
        <h2 style="color: #0D87C8">{{ actionType === 'view' ? 'View Unit of Measure' : (actionType === 'edit' ? 'Edit Unit of Measure' : 'Add Unit of Measure') }}</h2>
    </div>
  
    <form [formGroup]="addEditUnitOfMeasureForm" (ngSubmit)="onFormSubmit()">
        <div class="field">
          <p class="label">Measure Category</p>
          <mat-form-field appearance="outline" class="fields-input">
            <input type="text" placeholder="Select" aria-label="Number" matInput formControlName="measureCategory" 
                   [matAutocomplete]="auto" (click)="getAllMeasureCategories()">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let measureCategory of existMesaureCategories" [value]="measureCategory.name">
                {{ measureCategory.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error class="error-message error-msg" *ngIf="addEditUnitOfMeasureForm.get('measureCategory')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Measure Category is Required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <p class="label">Name</p>
          <mat-form-field appearance="outline" class="fields-input">
            <input matInput formControlName="name" required>
            <mat-error class="error-message error-msg" *ngIf="addEditUnitOfMeasureForm.get('name')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Name is Required
            </mat-error>
          </mat-form-field>
        </div>

       

        <div class="field">
          <p class="label">Code</p>
          <mat-form-field appearance="outline" class="fields-input">
            <input matInput formControlName="code" required>
            <mat-error class="error-message error-msg" *ngIf="addEditUnitOfMeasureForm.get('code')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Code is Required
            </mat-error>
          </mat-form-field> 
        </div>

        <div class="field">
          <p class="label">Status</p>
          <mat-radio-group appearance="outline" formControlName="active">
            <mat-radio-button value="true"> <span style="color: #858585;">Active</span></mat-radio-button>
            <mat-radio-button value="false" ><span style="color: #858585;">Inactive</span></mat-radio-button>
            <mat-error class="error-message error-msg" *ngIf="addEditUnitOfMeasureForm.get('active')?.hasError('required')">
              <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Status is Required
            </mat-error>
        </mat-radio-group>
        </div>

        <div class="popup-buttons">
          <button mat-raised-button class="save-btn" type="submit" *ngIf="actionType!=='view'" >{{data ? 'Update': 'Save'}}</button> 
          <button mat-raised-button class="cancel-btn" [mat-dialog-close]="false" type="button">{{actionType === 'view' ? 'Close' : 'Cancel'}}</button>
      </div>
    </form>
</div>


<app-website-header></app-website-header>

<div class="header">
  <img src="../../../../../assets/images/product-enquiry.svg" width="100%" height="auto" />
  <div class="header-name">
      <p>Product Distribution Request</p>
  </div>
</div>

<div style="background-color: hsla(0, 0%, 96%, 1);">
  <div class=" p-4  " [formGroup]="enquiryForm"> 
    <div class="d-flex flex-row justify-content-between" style="margin-left: .5rem;">
      <h2 style="border-bottom: 2px solid #0D87C8;  margin: 0; margin-left: .5rem; display: inline-block; color: #0D87C8; font-size: medium; font-weight: 600; font-family: 'work sans';">
        Product Distribution Request</h2>
        <div class="d-flex justify-content-between ">
          <p><b>Available Distributors:</b></p>
          <p class="end-text">{{ prodData?.distributorsId?.length ? prodData.distributorsId.length : 0 }}</p>
        </div>
    </div>  
    <h1 style="border: none; border-top: 1px solid #0D87C8;  width: 100%; margin: 0; margin-left: .5rem;"></h1>
    <div class="d-flex row m-2 gap-1 " style="background-color: #ffffff; border-radius: 10px;">
      <div style="flex: 2;" class="d-flex row m-2 p-3 ">
        <div class="d-flex justify-content-between">
          <h2 class="profile-heading">Provide details about your Company and receive Distribution Deal</h2>
        </div>
    
        <div class="row">
          <div class="col-lg-6">
            <div
              class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading heading"
            >
            Choose Country <span style="color: red;">*</span>
            </div>
            <div>
              <mat-form-field appearance="outline" class="w-100 input-font">
                <input type="text" aria-label="Number" matInput [formControlName]="'countryName'" #autoCompleteCountryInput="matAutocompleteTrigger" [matAutocomplete]="auto" (input)="handleCountry($event)" >
                <!-- <input type="text" aria-label="Number" matInput [formControlName]="'countryName'" #autoCompleteCountryInput="matAutocompleteTrigger" [matAutocomplete]="auto" (change)="handleCountry()" > -->
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of countries" [value]="option?.countryName">
                    {{ option?.countryName }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  class="error-message error-msg"
                  *ngIf="enquiryForm?.get('firstName')?.hasError('required')"
                  ><img
                    class="error-icon"
                    loading="eager"
                    alt=""
                    src="./../../assets/images/alert.svg"
                  />
                  Country is Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-lg-6">
            <div
              class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading heading"
            >
            Company Name <span style="color: red;">*</span>
            </div>
            <div>
              <mat-form-field appearance="outline" class="input-font w-100">
                <input matInput required formControlName="companyName" />
                <mat-error
                  class="error-message error-msg"
                  *ngIf="enquiryForm.get('lastName')?.hasError('required')"
                  ><img
                    class="error-icon"
                    loading="eager"
                    alt=""
                    src="./../../assets/images/alert.svg"
                  />
                  Company Name is Required</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div
              class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading"
            >
              Email Address<span style="color: red;">*</span>
            </div>
            <div>
              <mat-form-field appearance="outline" class="w-100 input-font">
                <input matInput required formControlName="email" />
                <div
                                      *ngIf="enquiryForm.get('email')?.invalid && enquiryForm.get('email')?.touched">
                                      <div *ngIf="enquiryForm.get('email')?.hasError('required')"
                                          class="error-message error-msg">
                                          <img class="error-icon" loading="eager" alt=""
                                              src="./../../assets/images/alert.svg" />
                                          Email is required.</div>
                                      <div *ngIf="enquiryForm.get('email')?.hasError('pattern')"
                                          class="error-message error-msg">
                                          <img class="error-icon" loading="eager" alt=""
                                              src="./../../assets/images/alert.svg" />
                                          Please enter a valid Email address.</div>
                                          <div *ngIf="enquiryForm.get('email')?.hasError('hasEmailExists')"class="error-message error-msg">
                                            <img class="error-icon" loading="eager" alt=""src="./../../assets/images/alert.svg" />
                                            Email already exists.</div>
                                          </div>
                                       
              </mat-form-field>
            </div>
          </div>
           <div class="col-md-6">
              <label for="phone" class="form-label">Phone Number</label><span style="color: red;">*</span>
              <div class="input-group" style="display: flex; align-items: center; position: relative;">
            
                <!-- Search and Select Input Field (Country Code) -->
                <input 
                  type="text" 
                  placeholder="IN" 
                  [formControl]="searchControl"
                  (focus)="showDropdownList()"
                  (input)="filterCountryCodes(searchControl.value || '')"
                  (blur)="hideDropdown()"
                  class="form-control country-input"
                />
            
                <!-- Custom Dropdown -->
                <div class="dropdown-menu" [ngClass]="{'show': showDropdown}" style="position: absolute; top: 100%; left: 0; width: 100%; z-index: 1000;">
                  <button 
                    class="dropdown-item" 
                    *ngFor="let code of filteredCountryCodes" 
                    (mousedown)="selectCountryCode(code)"
                  >
                    {{ code.countryCode | uppercase }}
                  </button>
                </div>
            
                <!-- Phone Code Input (Automatically updated) -->
                <input type="text" class="form-control phone-code-input" placeholder="+91" formControlName="phoneCode" readonly required>
            
                <!-- Phone Number Input -->
                <input type="text" class="form-control phone-number-input" formControlName="phone" title="Enter a valid mobile number" minlength="10" maxlength="15" required>
              </div>
            
              <!-- Validation Error Messages -->
              <mat-error class="error-message" *ngIf="enquiryForm.get('phoneCode')?.invalid && enquiryForm.get('phoneCode')?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Country Code is required.
              </mat-error>
            
              <!-- Validation Error Messages for Phone Number -->
              <mat-error class="error-message" *ngIf="phone?.hasError('required') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number is required.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('minlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should be at least 10 digits long.
              </mat-error>
              <mat-error class="error-message" *ngIf="phone?.hasError('maxlength') && phone?.touched">
                <img class="error-icon" loading="lazy" alt="" src="./../../assets/images/alert.svg" /> Phone Number should not exceed 10 digits.
              </mat-error>
            </div>
          </div>
        <div class="row">
            <div class="col-lg-6">
              <div
                class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading heading"
              >
              Profile URL <span style="color: red;">*</span>
              </div>
              <div>
                <mat-form-field appearance="outline" class="w-100 input-font">
                  <input matInput required formControlName="profileURL" />
                  <mat-error class="error-message error-msg" *ngIf="enquiryForm.get('profileURL')?.hasError('required')">
                    <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                    Profile URL is Required
                  </mat-error>
                  <mat-error *ngIf="enquiryForm.get('profileURL')?.hasError('pattern')"><img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" />
                    URL must start with "www." or "https://"
                  </mat-error>
                </mat-form-field>
              </div>
              </div>
  
            <div class="col-lg-6">
              <div
                class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading heading"
              >
              Portfolio <span style="color: red;">*</span>
              </div>
              <div>
                <mat-form-field appearance="outline" class="w-100 input-font">
                  <input matInput required formControlName="portfilio" />
                  <mat-error class="error-message error-msg" *ngIf="enquiryForm.get('portfilio')?.hasError('required')">
                    <img class="error-icon" loading="eager" alt="" src="./../../assets/images/alert.svg" />
                    Portfolio URL is Required
                  </mat-error>
                  <mat-error *ngIf="enquiryForm.get('portfilio')?.hasError('pattern')"><img class="error-icon"
                    loading="eager" alt="" src="./../../assets/images/alert.svg" />
                    URL must start with "www." or "https://"
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

        <div class="row" style="min-height: 100px;">
          <div class="col-lg-12">
            <div
              class="med-world-basic-font med-world-basic-font__medium secondary_black_color m-0 heading">
              Message
            </div>
            <div>
                <textarea matInput id="message" class="form-control" formControlName="message" rows="4"></textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <label
            class="med-world-basic-font med-world-basic-font__medium secondary_black_color heading"
            >I Would like to</label
          >
          <div class="col-lg-12">
          </div>
          <div class="col-lg-12">
            <mat-checkbox>
              <label
                class="med-world-basic-font med-world-basic-font__medium secondary_black_color"
                value="opt1"

                >Be Contacted by Telephone
              </label>
            </mat-checkbox>
          </div>
          <div class="col-lg-12">
            <mat-checkbox>
              <label
                class="med-world-basic-font med-world-basic-font__medium secondary_black_color"
                value="opt3"
                >Schedule a Meeting
              </label>
            </mat-checkbox>
          </div>
        </div>

        <div class="row">
          <label
            class="med-world-basic-font med-world-basic-font__medium secondary_black_color heading"
            >Attachments (If any)</label
          >
          <div class="col-lg-12">
            <div class="file-container">
              <input
                class="file-input-container"
                type="file"
                id="upload"
                accept=".png,.jpg,.jpeg,.svg"
                (change)="uploadLogo($event)">
              <label class="d-flex justify-content-center text align-items-center p-2" for="upload" mat-raised-button>
                <img style="margin-top:-4px; margin-right: 1px;" alt="" src="./../../assets/images/icons/drag-icon.svg" *ngIf="!selectedFile">
                <span *ngIf="!selectedFile" style="color:#0D87C8; padding: 10px;">Drag files here to upload (or) <span style="text-decoration: underline;"> Select Files</span></span>
                <span class="drag-drop-text2 d-flex g-2" *ngIf="selectedFile">
                
                  <span> <span  ><img style="width:4rem; height: 4rem ;margin-right: 1rem;" alt="" [src]="imageURL "></span> </span>
                

                  <button  type="button" class="close-icon mr-3" (click)="removeFile($event)" ><mat-icon style="color: #0D87C8 ">delete</mat-icon></button>
                </span></label>
            </div>
          </div><br>
            <mat-error
              class="error-message"
              *ngIf="
                enquiryForm.controls['logo']?.invalid &&
                enquiryForm.controls['logo']?.touched &&
                enquiryForm.get('logo')?.hasError('required')
              "
            >
              <img
                class="error-icon"
                loading="eager"
                alt=""
                src="./../../assets/images/alert.svg"
              />Photo is Required</mat-error
            >
        </div>


        <div class="action mt-2">
          <button
            class="mwe-button mwe-button--primary med-world-basic-font med-world-basic-font__medium ps-3 pe-3"
            style="margin-right: 10px"
            (click)="save()"
          >
            Send Enquiry
          </button>
          <button
            class="mwe-button mwe-button--secondary ps-3 pe-3"
            (click)="cancel()"
          >
            Cancel
          </button>
        </div>
      </div>
      <div style="flex: 1; border-left: 1px solid hsla(0, 0%, 84%, 1);" class="d-flex column-gap-1">
        <div class="m-2 p-3 d-flex flex-column gap-3">
          <div class="prod-heading">
            <img src="../../assets/images/icons/prod-heading-icon.svg" /> Your Requesting Product
          </div>
          <div class="main-img" style="margin: 0 55px;"> <img [src]="prodData.images[0]" width="350px" height="300px" /> </div>
          <div class="prod-name"> {{ prodData.name }} </div>
          <div class="ratings-star">
            <div class="stars">
              <ng-container *ngFor="let j of [1, 2, 3, 4, 5]">
                <img 
                  src="../../assets/images/full-star-rating.svg" 
                  *ngIf="j <= oemData.averageRating" 
                  alt="Full star"
                /> 
                <img 
                  src="../../assets/images/empty-star-rating.svg" 
                  *ngIf="j > oemData.averageRating" 
                  alt="Empty star"
                />
              </ng-container>
            </div>
            <div class="d-flex align-items-center justify-content-center rating-text">
              <img src="../../assets/images/message-productpage.svg" style="padding-right: 5px;"/> 
              {{oemData.averageRating}} out of 
              <strong class="mx-1">({{oemData.totalReviews}})</strong> Reviews
            </div>
          </div>          
          <div class="sold d-flex flex-row gap-2">
          <b> {{ prodData.soldTillNow }} </b> <span style="font-weight: 400; font-size: 16px;" >  sold now </span> <a [href]="oemData.oemUrl" *ngIf="oemData.oemUrl" class="view-profile" > View {{(prodData?.oemName.split(' '))[0]}} Profile </a>
          </div>
          <div class="logo">
            <img [src]="oemData.logo" width="100%" height="auto" />
          </div>
          <div class="row">
            <div class="col-1 my-2"><img src="../../assets/images/icons/location-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><strong>Office</strong></div>
            <div class="col-9 my-2">{{oemData.address}}</div>
            <div class="w-100"></div>
            <div class="col-1 my-2"><img  src="../../assets/images/icons/phone-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><strong>Phone</strong></div>
            <div class="col-9 my-2">{{oemData.phone}}</div>
            <div class="w-100"></div>
            <div class="col-1 my-2"><img src="../../assets/images/icons/email-icon-productpage.svg" class="star"></div>
            <div class="col-2 my-2"><p><strong>Email</strong></p></div>
            <div class="col-9 my-2">{{oemData.email}}</div>
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>
<app-website-footer></app-website-footer>

import { Component, Input, OnInit ,ChangeDetectorRef, Output, EventEmitter} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { ListColumn } from 'src/app/models/list-column.model';
import Swal from 'sweetalert2';
import { SweetAlertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-points-of-contacts',
  templateUrl: './points-of-contacts.component.html',
  styleUrls: ['./points-of-contacts.component.scss']
})
export class PointsOfContactsComponent implements OnInit {
  @Input() pointsOfContactsForm!: FormGroup;
  @Input() isEdit: boolean = false;
  @Output() branchAdded = new EventEmitter<number>();

  columns: ListColumn[] = [
    { name: 'Sr.no', property: 'sNo', visible: true, isModelProperty: false },
    { name: 'Contact Name', property: 'contactName', visible: true, isModelProperty: true, isText: true },
    { name: 'Phone Number', property: 'phoneNumber', visible: true, isModelProperty: true, isText: true },
    { name: 'Email', property: 'email', visible: true, isModelProperty: true, isText: true },
    { name: 'Actions', property: 'action', visible: true, isModelProperty: true, hasAction: true,
      actions: [
        { imageUrl: './../../assets/images/edit.svg', isImage: true, actionType: MweConstants.editAction },
        { imageUrl: './../../assets/images/bin.svg', isImage: true, actionType: MweConstants.deleteAction }
      ]
    }
  ] as ListColumn[];
  data: any[] = [];
  contactForm!: FormGroup;
  actionType: any;
  index: number = 0;
  isMore: boolean = false;
  isProceed: boolean = false;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef, private sweetAlertService: SweetAlertService) {}

  ngOnInit(): void {
    this.initializeContactForm();
   this.data=this.pointOfContactArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
   if(this.data.length>0){
    this.isMore=true
   }
  }

  initializeContactForm() {
    this.contactForm = this.fb.group({
      id: new FormControl(null),
      active: new FormControl(null),
      createdBy: new FormControl(null),
      createdOn: new FormControl(null),
      modifiedBy: new FormControl(null),
      modifiedOn: new FormControl(null),
      salutation: new FormControl(''),
      contactName: new FormControl(''),
      phoneNumber: new FormControl(''),
      email: new FormControl(''),
      leadId: new FormControl(null),
    });
  }

  pointOfContactArray() {
    return this.pointsOfContactsForm.get('leadContact') as FormArray;
  }

  

  addPointOfContact() {
    if (this.contactForm.valid && this.areAllPropertiesNotNull(this.contactForm.value)) {
      const salutation = this.contactForm.get('salutation')?.value;
      const contactName = this.contactForm.get('contactName')?.value;

      if (!salutation || !contactName) {   
        return;
      }
      const fullName = `${salutation}.${contactName}`;
      if (this.actionType === MweConstants.editAction) {
        this.pointOfContactArray().at(this.index).patchValue({
          ...this.contactForm.value,
          contactName: fullName
        });
        if(this.isEdit){
        this.branchAdded.emit(2);
        }
      } else {
        this.pointOfContactArray().push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          contactName: new FormControl(fullName),
          phoneNumber: new FormControl(this.contactForm.get('phoneNumber')?.value),
          email: new FormControl(this.contactForm.get('email')?.value),
          leadId: new FormControl(null),
        }));
        if(this.isEdit){
        this.branchAdded.emit(1);
        }
      }
      this.data=this.pointOfContactArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
      this.contactForm.reset();
      this.isProceed=false;
      this.isMore=true
      this.actionType = null;
      this.index = 0;
    }
  }

  toggleIsProceed() {
    this.isProceed = true;
    this.actionType=null;
    this.contactForm.reset();
  }
  
  toggleCancel(){
    this.isProceed = false;
  }
   

  areAllPropertiesNotNull(obj: any): boolean {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== '') {
        return true;
      }
    }
    return false;
  }

  selectedAction(event: any) {
    this.index = this.pointOfContactArray().controls.findIndex((control: any) => {
      const controlValue = control.value;
      return controlValue.contactName === event.row.contactName &&
             controlValue.phoneNumber === event.row.phoneNumber &&
             controlValue.email === event.row.email;
    });

    switch (event.action) {
      case MweConstants.editAction:
        this.isProceed=true;
        this.actionType = MweConstants.editAction;
        const [salutation, ...contactNameParts] = event.row.contactName.split('.');
        const contactName = contactNameParts.join('.');
  
        this.contactForm.patchValue({
          ...event.row,
          salutation: salutation,
          contactName: contactName
        });
        break;
        case MweConstants.deleteAction:
          Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
              this.pointOfContactArray().removeAt(this.index);
              this.contactForm.reset();
              this.actionType = null;
              this.data=this.pointOfContactArray().value.filter((item: any) => Object.values(item).some(val => val !== null && val !== ""));
              if(this.data.length==0){
                this.isMore=false;
              }
              if(this.isEdit){
                this.branchAdded.emit(3);
              }
              else{
                this.sweetAlertService.showSuccess('', 'Deleted successfully !!');
              }
              
              this.cdr.detectChanges();
            }
            else {
              Swal.fire(
                'Failed!',
                'Item could not be deleted.',
                'error'
              );
            }
 
          });
              
         
          
          break;
      default:
        break;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));

  }
  

}



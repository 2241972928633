import { Component, HostListener, OnInit, inject } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject } from 'rxjs';
import { delay, map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { BreadcrumbService } from 'src/app/services/shared/breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../shared/logout/logout.component';
import { UserDetails } from 'src/app/models/authData';
import { SignoutPopupComponent } from '../shared/signout-popup/signout-popup.component';
import { PermissionService } from 'src/app/services/permission.service';
import { HeaderbreadcrumbService } from 'src/app/services/shared/headerbreadcrumb.service';
import { PopupVisibityService } from 'src/app/services/shared/popup-visibity.service';
import { NotificationsPopUpComponent } from '../shared/notifications-pop-up/notifications-pop-up.component';
import { SharedService } from 'src/app/services/shared-service.service';
import Swal from 'sweetalert2';
import { authGuard } from 'src/app/guards/auth.guard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isCategoryLevelsExpanded: boolean = false;
  headerLabel!: string;
  userName!: string;
  roleName!: string;
  profilePhoto!: string;
  profilePhotoString!: string;
  userData!: UserDetails;
  breadcrumbs: string[] = [];
  expandedNodes: { [key: string]: boolean } = {};
  accountName!: string;
  userId!: string;
  staffMenu: any = [
  ];
  private destroy$ = new Subject<void>(); // Subject to manage unsubscription

  permissions = [];
  filteredMenu: any = [];
  isPopUpVisible!: boolean;
  allNotifications: any[] = [];
  limitedNotifications: any[] = [];
  showNotificationDropdown = false;
  hideTimeout: any;
  noData: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private sharedService: SharedService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    private permissionService: PermissionService,
    private headerBreadCrumbService: HeaderbreadcrumbService, private popupVisibityService: PopupVisibityService) { }
  ngOnInit(): void {
    
    this.roleName = this.localStorageService.getItem('roleName');
    this.accountName = this.localStorageService.getItem('accountName');
    this.isPopUpVisible = this.localStorageService.getItem('isPopupVisible');
    console.log('popup is ---', this.isPopUpVisible);
    
    
    if (this.accountName == 'Staff') {
      this.permissions = this.localStorageService.getItem('preferences');
      this.staffMenu = this.roleName == 'SUPER_ADMIN' ? this.permissionService.getSuperAdminMenu() : this.roleName === 'OEM' ? this.permissionService.getOemMenu() : this.permissionService.getDistributorMenu();
      this.getStaffNavLink()
    }
    this.localStorageService.getUserDetails().subscribe(userDetails => {
      this.profilePhoto = userDetails.profilePic;
      this.userName = userDetails.userName;
      this.userId = userDetails.userId;

    });
    
  
this.popupVisibityService.popupVisibility$.pipe(delay(0)).subscribe(popupVisibility => {
  this.isPopUpVisible = popupVisibility;
})
   
    
    this.setHeaderLabelBasedOnURL();
    this.breadcrumbService.breadcrumbs$.pipe(delay(0)).subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
    });

    this.breadcrumbService.setBreadcrumbs(['Dashboard']);
    this.lengthHeaderBreadCrumb();
    this.fetchNotifications();
  }

  ngOnDestroy(): void {
    this.destroy$.next(); 
    this.destroy$.complete(); 
  }
  lengthHeaderBreadCrumb(){
    this.headerBreadCrumbService.headerBreadcrumbs$.subscribe(headerBreadCrumb =>{
      if(headerBreadCrumb.length>0){
        this.setHeaderLabel( headerBreadCrumb[0]);
      }
    })
  }

  profile() {
    this.router.navigate(['super-admin/profile']);
  }

  fetchNotifications() {
    const pageNumber = 0;
    const pageSize = 10;
    this.sharedService.getNotifications(this.userId, pageNumber, pageSize).subscribe(
      (response) => {
        if (response?.data) {
          this.allNotifications = response.data;
          this.limitedNotifications = this.allNotifications.slice(0, 4);
        }
        if(this.allNotifications.length == 0){
          
          this.noData = true;
        }
        else{
          this.noData = false;
        }
      },
      (error) => {
        this.noData = true;
        console.error('Error fetching notifications', error);
      }
    );
  }
  
  
  toggleNotifications() {
    this.showNotificationDropdown = !this.showNotificationDropdown;
  }
  

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    const isInsideDropdown = target.closest('.notification-dropdown');
    const isNotificationButton = target.closest('button[mat-icon-button]');
  
    if (!isInsideDropdown && !isNotificationButton) {
      this.showNotificationDropdown = false;
    }
  }
  
  openAllNotifications() {

    if (this.roleName === 'SUPER_ADMIN') {
      this.router.navigate(['dashboard/super-admin/notifications']);   
     } 
      else if (this.roleName === 'OEM') {
      this.router.navigate(['dashboard/oem/notifications']);
    } else if (this.roleName === 'DISTRIBUTOR') {
      this.router.navigate(['dashboard/distributor/notifications']);
    }


    this.showNotificationDropdown = false;
  }
    
  signOut() {
    const dialogRef = this.dialog.open(SignoutPopupComponent, {
      panelClass: 'custom-dialog-container',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
            console.log("Log", result.message);
            

      }
    });
  }
  private breakpointObserver = inject(BreakpointObserver);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  navLinks = {
    'super-admin': [
      { label: 'Dashboard', route: 'dashboard/super-admin', icon: 'assets/images/dashboard.svg' },
      { label: "Master Data", route: 'dashboard/super-admin/master-data', icon: '/assets/images/data-master.svg' },
      {
        label: 'Product Settings', route: 'dashboard/super-admin/categories',
        children: [
          { label: 'Categories', route: 'dashboard/super-admin/categories', icon: '/assets/images/icons/categories.svg' },
          { label: 'Unit of Measure', route: 'dashboard/super-admin/unit-of-measure', icon: '/assets/images/icons/unitOfMeasure.svg' },
          { label: 'Specifications', route: 'dashboard/super-admin/specifications', icon: '/assets/images/icons/specifications.svg' },
          { label: 'All Products', route: 'dashboard/super-admin/all-products', icon: '/assets/images/icons/allProducts.svg' }
        ], icon: '/assets/images/base left.svg'
      },
      { label: 'OEM', route: 'dashboard/super-admin/oem-management', icon: 'assets/images/oems.svg' },
      { label: 'Distributor', route: 'dashboard/super-admin/distributor', icon: '/assets/images/import-warehouse.svg' },
      // { label: 'Add Oem', route: 'dashboard/oem/add-oem' },
      // { label: 'MyDashboard', route: 'dashboard/distributor/view-distributor' },
      { label: 'End Customers', route: 'dashboard/super-admin/end-customer', icon: 'assets/images/ec.svg' },
      { label: 'Subscription Plans', route: 'dashboard/super-admin/subscription', icon: '/assets/images/subscription-plans.svg' },
      { label: 'Payments', route: '', icon: '/assets/images/payments.svg' },
      {
        label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/StaffManagment.svg',
        children: [
          { label: 'Roles', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg' },
          { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg' },

        ]
      },
      { label: 'Webinars', route: 'dashboard/super-admin/webinars', icon: '/assets/images/webinar.svg' },
      { label: 'Exhibitions', route: 'dashboard/super-admin/exhibitions', icon: 'assets/images/Exhibitions.svg' },
      { label: 'Careers', route: 'dashboard/super-admin/careers', icon: '/assets/images/careers.svg' },
      { label: 'Leads', route: 'dashboard/super-admin/leads', icon: '/assets/images/leads.svg' },
      { label: 'Analytics', route: ' ', icon: '/assets/images/analytics.svg' },
      { label: 'CMS Pages', route: 'dashboard/super-admin/cms-pages', icon: '/assets/images/cms pages.svg' },
      { label: 'Notification', route: ' ', icon: '/assets/images/notification.svg' },
      { label: 'Settings', route: ' ', icon: '/assets/images/settings.svg' },
      { label: 'Support Tickects', route: ' ', icon: '/assets/images/support1.svg' },
      { label: 'Knowledge Base', route: ' ', icon: '/assets/images/knowledge-base.svg' }

    ],
    'distributor': [
      { label: 'Dashboard', route: 'dashboard/distributor', icon: 'assets/images/dashboard.svg' },
      { label: 'Company Profile', route: 'dashboard/distributor/company-details', icon: 'assets/images/company-profile.svg' },
      { label: 'Distributing Products', route: 'dashboard/distributor/distributing-products', icon: 'assets/images/ProductsManagement.svg' },
      { label: 'Exhibitions', route: 'dashboard/distributor/exhibitions', icon: 'assets/images/Exhibitions.svg' },
      { label: 'Enquiry Management', route: 'dashboard/distributor/product-enquiry', icon: 'assets/images/ProductEnquiry.svg' },
      { label: 'OEM Networks', route: 'dashboard/distributor/oem-networks', icon: 'assets/images/oem networks.svg' },
      {
        label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/StaffManagment.svg',
        children: [
          { label: 'Roles', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg' },
          { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg' },

        ]
      },
      { label: 'Careers', route: 'dashboard/distributor/careers', icon: 'assets/images/careers.svg' },
      { label: 'Catalogs', route: 'dashboard/distributor/catalogs', icon: 'assets/images/Catalogs.svg' },
      { label: 'Webinars', route: 'dashboard/distributor/webinars', icon: 'assets/images/Webinars.svg' },
      { label: 'Notifications', route: 'dashboard/distributor/notifications', icon: 'assets/images/notification.svg' },
      { label: 'Support', route: 'dashboard/distributor/support', icon: 'assets/images/Support.svg' }
    ],
    'oem': [
      { label: 'Dashboard', route: 'dashboard/oem', icon: 'assets/images/dashboard.svg' },
      { label: 'Company Profile', route: 'dashboard/oem/company-details', icon: 'assets/images/company-profile.svg' },
      { label: 'Products Management', route: 'dashboard/oem/products-management', icon: 'assets/images/ProductsManagement.svg' },
      { label: 'Catalogs', route: 'dashboard/oem/catalogs', icon: 'assets/images/Catalogs.svg' },
      { label: 'Exhibitions', route: 'dashboard/oem/exhibitions', icon: 'assets/images/Exhibitions.svg' },
      { label: 'Enquiry ', route: 'dashboard/oem/enquiry', icon: 'assets/images/ProductEnquiry.svg' },
      { label: 'Distributor Network', route: 'dashboard/oem/distributor-network', icon: 'assets/images/DistributorNetwork.svg' },
      {
        label: 'Staff Management', route: 'dashboard/staff-management/roles-and-permissions',
        children: [
          { label: 'Roles', route: 'dashboard/staff-management/roles-and-permissions', icon: '/assets/images/icons/rolesAndPermissions.svg' },
          { label: 'Staff', route: 'dashboard/staff-management/staff', icon: '/assets/images/icons/staff.svg' },
        ], icon: 'assets/images/StaffManagment.svg'
      },
      { label: 'Careers', route: 'dashboard/oem/careers', icon: 'assets/images/careers.svg' },
      { label: 'Webinars', route: 'dashboard/oem/webinars', icon: 'assets/images/Webinars.svg' },
      { label: 'Notifications', route: 'dashboard/oem/notifications', icon: 'assets/images/notification.svg' },
      { label: 'Support', route: 'dashboard/oem/support', icon: 'assets/images/Support.svg' },
    ]
  };

  blockDashboard(type: string, label: any){
    console.log('hello from blaco dashboard!!', label, type, this.isPopUpVisible, typeof this.isPopUpVisible);
    if(type === 'distributor'){
      this.router.navigate(['/dashboard/distributor']);
    }
    else{
      this.router.navigate(['/dashboard/oem']);
    }
  }

  setHeaderLabel(label: string) {
    this.headerLabel = label;
    if (label !== 'Dashboard') {
      if (label === 'Product Settings' || label === 'Specifications' || label === 'Unit of Measure' || label === 'All Products' || label === 'Categories') {
        this.headerLabel = 'Categories';
        if (label === 'Product Settings') {
          label = 'Categories'
        }
        this.breadcrumbService.setBreadcrumbs(['Product Settings', label]);
      }
      else if (label === 'OEM') {
        this.headerLabel = "OEM's Management";
        
      }
       else {
        this.breadcrumbService.setBreadcrumbs(['Dashboard', label]);
      }
    } else {
      this.breadcrumbService.setBreadcrumbs([]);
    }
  }


  penLogoutConfirmationDialog(): void {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  isActive(route: string): boolean {
    return this.router.url === '/' + route;
  }

  toggleCategoryLevels(label?: string) {
    if (label === 'Product Settings' || label === 'Staff Management') {
      this.expandedNodes[label] = !this.expandedNodes[label];
    }
  }

  isExpanded(label: string): boolean {
    return !!this.expandedNodes[label];
  }

  getStaffNavLink() {
    this.filteredMenu = this.staffMenu.filter((item: any) => {
      if (item?.children && item.children.length > 0) {
        const children = item.children.filter((child: any) => {
          return this.permissionService.hasAnyPermission(child.permissions, this.permissions);
        })
        if (children && children.length > 0) {
          item.route = children[0].route;
          item.children = children;
          return true;
        }
        return false;
      } else {
        return this.permissionService.hasAnyPermission(item.permissions, this.permissions)
      }

    })
  }

  getProfileRouteBasedOnRole(RoleName: string): string {

    switch (RoleName) {
      case 'SUPER_ADMIN':
        return 'super-admin/profile';
      case 'OEM':
        return 'oem/profile';
      case 'DISTRIBUTOR':
        return 'distributor/profile';
      default:
        return '';
    }
  }

  private setHeaderLabelBasedOnURL() {
    if(this.router.url.includes('careers')){
      this.headerLabel = 'Careers';
    }
    else if(this.router.url.includes('oem-management')){
      this.headerLabel = "OEM's Management";
    }
    else {
      this.headerLabel = 'Dashboard';
    }
  }
}

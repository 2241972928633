import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MweConstants } from 'src/app/constants/mwe-constants.service';
import { EndCustomerService } from 'src/app/services/end-customer.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import Swal from 'sweetalert2';
import { HospitalProfileComponent } from './hospital-profile/hospital-profile.component';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  endCustomerEditForm!: FormGroup;
  endCustomerData: any;
  oldEndCustomerData: any;
  selectedAccountType: any;
  userId: any;
  @Input() jobPost: any = undefined;
  @Output() endCustomerId: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(HospitalProfileComponent) hospitalProfileComponent!: HospitalProfileComponent;
  @ViewChild(DoctorProfileComponent) doctorProfileComponent!: DoctorProfileComponent;


  constructor(private fb: FormBuilder, private endCustomerService: EndCustomerService, private localStorageService: LocalStorageService,
    private snackbarService: SnackbarService
  ) {

  }
  ngOnInit(): void {
    this.initializeEndCustomerForm();
    this.localStorageService.getUserDetails().subscribe(data => {
      this.userId = data.userId;
      this.getEndCustomerByUserId();
    })

  }

  initializeEndCustomerForm() {
    this.endCustomerEditForm = this.fb.group({
      userId: new FormControl(null),
      id: new FormControl(null),
      active: new FormControl(null),
      createdBy: new FormControl(null),
      createdOn: new FormControl(null),
      modifiedBy: new FormControl(null),
      modifiedOn: new FormControl(null),
      account: new FormControl(null, [Validators.required]),
      // Organisation and personal Info form
      name: new FormControl('', [Validators.required]),
      establishedIn: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      portfolioUrl: new FormControl('', [Validators.required]),
      workingIn: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      /* "photo":new FormControl('',[Validators.required]), */
      noEmployees: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{5,10}$")]),
      url: new FormControl('', [Validators.required]),
      "logo": new FormControl(null, [Validators.required]),
      logoFileName: new FormControl(null),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      // About You Form
      aboutId: this.fb.group({
        id: new FormControl(null),
        active: new FormControl(null),
        createdBy: new FormControl(null),
        createdOn: new FormControl(null),
        modifiedBy: new FormControl(null),
        modifiedOn: new FormControl(null),
        about: new FormControl(null),
        facebookUrl: new FormControl(null, [Validators.pattern('^(https?:\/\/)?(www\.)?facebook\.com(/.*)?$')]),
        twitterUrl: new FormControl(null, [Validators.pattern('^(https?:\/\/)?(www\.)?x\.com(/.*)?$')]),
        linkedin: new FormControl(null, [Validators.pattern('^(https?:\/\/)?(www\.)?linkedin\.com(/.*)?$')]),
        instagramUrl: new FormControl(null, [Validators.pattern('^(https?:\/\/)?(www\.)?instagram\.com(/.*)?$')]),

      }),
      specialityInfos: new FormControl([]),
      // members Form
      members: this.fb.array([
        /*  this.fb.group({
           id:new FormControl(null),
           active:new FormControl(null),
           createdBy:new FormControl(null),
           createdOn:new FormControl(null),
           modifiedBy:new FormControl(null),
           modifiedOn:new FormControl(null),
          "name"  :new FormControl(null),
           "position": new FormControl(null),
           "phone": new FormControl('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{5,10}$")]),
           "image":new FormControl(null),
           fileName:new FormControl(null),
            
         }),
         this.fb.group({
           id:new FormControl(null),
           active:new FormControl(null),
           createdBy:new FormControl(null),
           createdOn:new FormControl(null),
           modifiedBy:new FormControl(null),
           modifiedOn:new FormControl(null),
           "name"  :new FormControl(null),
            "position": new FormControl(null),
            "phone": new FormControl('',[Validators.pattern("^((\\+91-?)|0)?[0-9]{5,10}$")]),
              "image":new FormControl(null),
              fileName:new FormControl(null),
          }), */
      ]),
      //Infrastructure Form
      infras: this.fb.array([
        this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          roomsAvailable: new FormControl(null),
          totalRooms: new FormControl(null),
          /*  normalRooms:new FormControl(null), */
          totalIcuRooms: new FormControl(null),
          totalBeds: new FormControl(null),
          patientCapacity: new FormControl(null),

        }),
      ]),
      laboratoryAvailable: new FormControl(null),
      equipments: new FormControl([]),

      //Services screen
      serviceInfos: new FormControl([], null),
      //Certifications & Licenses Form
      certifications: this.fb.array([
        /* this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
         "name"  :new FormControl(null),
          "certificate":new FormControl(null),
          fileName:new FormControl(null)
        }),
        this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
          "name"  :new FormControl(null),
         
             "certificate":new FormControl(null),
             fileName:new FormControl(null)
             
         }), */
      ]),

      photos: this.fb.array([
        //     this.fb.group({ 
        //      "photoLink"  :new FormControl(null),
        //      "fileName":new FormControl(null)

        //  }),
      ]),
      resumes: this.fb.array([
        /* this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
         "name"  :new FormControl(null),
          "resume":new FormControl(null),
          fileName:new FormControl(null)
        }),
      
        //Add More Info Hospital Form
        // addNewSection:new FormControl([],null),
        //Photos Form
        //"uploadPhoto":new FormControl(null),
       */
      ]),

      //Specialities Doctor Form
      //selectSpecialisation:new FormControl([],null),
      //Education Details Doctor Form
      educationInfos: this.fb.array([
        /*  this.fb.group({
           id:new FormControl(null),
           active:new FormControl(null),
           createdBy:new FormControl(null),
           createdOn:new FormControl(null),
           modifiedBy:new FormControl(null),
           modifiedOn:new FormControl(null),
          "degree"  :new FormControl(null),
           "yearFrom": new FormControl(null),
           "yearTo": new FormControl(null),
           "location": new FormControl(null),
           "fieldOfStudy": new FormControl(null),
         }),
         this.fb.group({
           id:new FormControl(null),
           active:new FormControl(null),
           createdBy:new FormControl(null),
           createdOn:new FormControl(null),
           modifiedBy:new FormControl(null),
           modifiedOn:new FormControl(null),
           "degree"  :new FormControl(null),
            "yearFrom": new FormControl(null),
            "yearTo": new FormControl(null),
            "location": new FormControl(null),
            "fieldOfStudy": new FormControl(null),
          }), */
      ]),
      //Work Experience Doctor Form
      workInfos: this.fb.array([
        /* this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
         "designation"  :new FormControl(null),
          "yearFrom": new FormControl(null),
          "yearTo": new FormControl(null),
          "organization": new FormControl(null),
          "specialization": new FormControl(null),
        }),
        this.fb.group({
          id:new FormControl(null),
          active:new FormControl(null),
          createdBy:new FormControl(null),
          createdOn:new FormControl(null),
          modifiedBy:new FormControl(null),
          modifiedOn:new FormControl(null),
          "designation"  :new FormControl(null),
           "yearFrom": new FormControl(null),
           "yearTo": new FormControl(null),
           "organization": new FormControl(null),
           "specialization": new FormControl(null),
         }), */
      ]),
      //Skill Set Doctor Form
      skillSets: new FormControl([]),
      //Add More Info Publications Doctor Form
      moreInfos: this.fb.array([
        this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          publications: this.fb.array([
            /*     this.fb.group({
                  id:new FormControl(null),
                  active:new FormControl(null),
                  createdBy:new FormControl(null),
                  createdOn:new FormControl(null),
                  modifiedBy:new FormControl(null),
                  modifiedOn:new FormControl(null),
                 "title"  :new FormControl(null),
                  "url": new FormControl(null),
                  "publishedby": new FormControl(null),
                  "publicationDate": new FormControl(null),
                  // "publishedYear": new FormControl(null),
                  "authors": new FormControl(null),
                  "summary": new FormControl(null),
                }), */
          ]),
          awards: this.fb.array([
            /* this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
             "title"  :new FormControl(null),
              "association": new FormControl(null),
              "awardDate": new FormControl(null),
              // "awardYear": new FormControl(null),
              "summary": new FormControl(null),
            }), */
          ]),
          courseTrainings: this.fb.array([
            /*  this.fb.group({
               id:new FormControl(null),
               active:new FormControl(null),
               createdBy:new FormControl(null),
               createdOn:new FormControl(null),
               modifiedBy:new FormControl(null),
               modifiedOn:new FormControl(null),
              "title"  :new FormControl(null),
               "institution": new FormControl(null),
               "courseDate": new FormControl(null),
               // "courseYear": new FormControl(null),
               "summary": new FormControl(null),
             }), */
          ]),
          associations: this.fb.array([
            /* this.fb.group({
              id:new FormControl(null),
              active:new FormControl(null),
              createdBy:new FormControl(null),
              createdOn:new FormControl(null),
              modifiedBy:new FormControl(null),
              modifiedOn:new FormControl(null),
             "associationName"  :new FormControl(null),
              "positionHeld": new FormControl(null),
              "fromDate": new FormControl(null),
              // "fromYear": new FormControl(null),
              "toDate": new FormControl(null),
              // "untilYear": new FormControl(null),
              "summary": new FormControl(null),
            }), */
          ]),
          volunteerings: this.fb.array([
            /*  this.fb.group({
               id:new FormControl(null),
               active:new FormControl(null),
               createdBy:new FormControl(null),
               createdOn:new FormControl(null),
               modifiedBy:new FormControl(null),
               modifiedOn:new FormControl(null),
              "organizationName"  :new FormControl(null),
               "positionHeld": new FormControl(null),
               "fromDate": new FormControl(null),
               // "fromYear": new FormControl(null),
               "toDate": new FormControl(null),
               // "untilYear": new FormControl(null),
               "summary": new FormControl(null),
             }), */
          ]),
        }),
      ]),
      organization: new FormControl('')




    })
  }

  getEndCustomerByUserId() {
    //  this.userId='1f85d828-8bfd-414d-8307-817745f2f2a0';
    //   this.userId='184a04f9-6c88-4067-9ec9-e38e36c4416b'
    this.endCustomerService.executeGetRequestURL(MweConstants.getCustomerByUserId + this.userId, null).subscribe(data => {
      if (data && data?.data) {
        this.endCustomerData = data?.data;
        this.oldEndCustomerData = data?.data;
        this.endCustomerEditForm.patchValue(data?.data);
        this.selectedAccountType = data.data?.account;
        this.endCustomerId.emit(this.endCustomerData.id);
        if (this.selectedAccountType?.accountType?.name == 'Organization') {
          this.updateHospitalForm();
          this.updateOrganizationForm();

        } else {
          this.updateDoctorForm();
          this.updateIndividual();
        }
        if (this.endCustomerData?.logo) {
          this.endCustomerEditForm.get('logoFileName')?.patchValue(this.endCustomerData.logo.split('/')[this.endCustomerData.logo.split('/').length - 1]);
        }
      }

    }, error => {

    })
  }

  updateOrganizationForm() {
    this.endCustomerEditForm.get('dob')?.disable();
    this.endCustomerEditForm.get('gender')?.disable();
    this.endCustomerEditForm.get('workingIn')?.disable();
    this.endCustomerEditForm.get('position')?.disable();

    this.endCustomerEditForm.get('skillSets')?.disable();

    this.endCustomerEditForm.get('workInfos')?.disable();

    this.endCustomerEditForm.get('educationInfos')?.disable();
  }

  updateIndividual() {
    this.endCustomerEditForm.get('establishedIn')?.disable();
    this.endCustomerEditForm.get('noEmployees')?.disable();
    // this.endCustomerEditForm.get('url')?.disable();
    this.endCustomerEditForm.get('members')?.disable();
    this.endCustomerEditForm.get('infras')?.disable();
    this.endCustomerEditForm.get('equipments')?.disable();
    this.endCustomerEditForm.get('serviceInfos')?.disable();
    this.endCustomerEditForm.get('photos')?.disable();

  }

  updateDoctorForm() {
    if (this.endCustomerData?.dob) {
      this.endCustomerEditForm.get('dob')?.patchValue(new Date(this.endCustomerData.dob));
    }
    this.updateSpecility();
    this.updateEducations();
    this.updateworkInfos();
    this.updateSkillSets();
    this.updateCertifications();
    this.updateMoreInfos();
    this.updateResumes();
  }

  updateHospitalForm() {
    if (this.endCustomerData?.establishedIn) {
      this.endCustomerEditForm.get('establishedIn')?.patchValue(new Date(this.endCustomerData.establishedIn));
    }

    this.updateMembers();
    this.updateCertifications();
    this.updateMoreInfos();
    this.updatePhotos();
    this.updateInframForm();
    this.updateSpecility();
    this.updateEquipments();
    this.updateServiceInfoId();

  }

  updateSpecility() {
    if (this.endCustomerData?.specialityInfos && this.endCustomerData.specialityInfos.length > 0) {
      this.endCustomerEditForm.get('specialityInfos')?.patchValue(this.endCustomerData.specialityInfos.map((obj: any) => obj.specialityInfoId));
    }
  }

  updateInframForm() {
    if (this.endCustomerEditForm.get('equipments')?.value && this.endCustomerEditForm.get('equipments')?.value.length > 0) {
      this.endCustomerEditForm.get('laboratoryAvailable')?.patchValue('yes');
    } else {
      this.endCustomerEditForm.get('laboratoryAvailable')?.patchValue('no');
    }
    for (let i = 0; i < (this.endCustomerEditForm.get('infras') as FormArray).controls.length; i++) {
      if ((this.endCustomerEditForm.get('infras') as FormArray).at(i).get('totalRooms')?.value == null &&
        (this.endCustomerEditForm.get('infras') as FormArray).at(i).get('totalBeds')?.value == null &&
        (this.endCustomerEditForm.get('infras') as FormArray).at(i).get('totalIcuRooms')?.value == null &&
        (this.endCustomerEditForm.get('infras') as FormArray).at(i).get('patientCapacity')?.value == null) {
        (this.endCustomerEditForm.get('infras') as FormArray).at(i).get('roomsAvailable')?.patchValue('no');
      } else {
        (this.endCustomerEditForm.get('infras') as FormArray).at(i).get('roomsAvailable')?.patchValue('yes');
      }
    }
  }
  updateSkillSets() {
    if (this.endCustomerData?.skillSets && this.endCustomerData.skillSets.length > 0) {
      this.endCustomerEditForm.get('skillSets')?.patchValue(this.endCustomerData.skillSets.map((obj: any) => obj.skillsetInfoId));
    }
  }

  updateEquipments() {
    if (this.endCustomerData?.equipments && this.endCustomerData.equipments.length > 0) {
      this.endCustomerEditForm.get('equipments')?.patchValue(this.endCustomerData.equipments.map((obj: any) => obj.equipmentInfoId));
    }
  }
  updateServiceInfoId() {
    if (this.endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length > 0) {
      this.endCustomerEditForm.get('serviceInfos')?.patchValue(this.endCustomerData.serviceInfos.map((obj: any) => obj.serviceInfoId));
    }
  }
  updateMoreInfos() {
    if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
      this.updatePublications();
      this.updateAwards();
      this.updateCourseTrainings();
      this.updateAssociations();
      this.updateVolunteerings();
    }
  }
  updatePublications() {
    if (this.endCustomerData?.moreInfos[0]?.publications && this.endCustomerData.moreInfos[0].publications.length > 0) {
      for (let i = 0; i < this.endCustomerData.moreInfos[0]?.publications.length; i++) {
        ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "title": new FormControl(null),
          "url":  ['',[Validators.pattern(/^(https:\/\/|www\.).*/)]],
          "publishedby": new FormControl(null),
          "publicationDate": new FormControl(null),
          "authors": new FormControl(null),
          "summary": new FormControl(null),
        }));
      }
      ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).patchValue(this.endCustomerData.moreInfos[0].publications);
    }
    if (this.endCustomerData?.moreInfos[0]?.publications && this.endCustomerData.moreInfos[0].publications.length > 0) {
      for (let i = 0; i < ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).controls.length; i++) {
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).at(i).get('publicationDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).at(i).get('publicationDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('publications') as FormArray).at(i).get('publicationDate')?.value));
        }
      }
    }
  }
  updateAwards() {
    if (this.endCustomerData?.moreInfos[0]?.awards && this.endCustomerData.moreInfos[0].awards.length > 0) {
      for (let i = 0; i < this.endCustomerData.moreInfos[0]?.awards.length; i++) {
        ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "title": new FormControl(null),
          "association": new FormControl(null),
          "awardDate": new FormControl(null),
          "summary": new FormControl(null),
        }));
      }
      ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).patchValue(this.endCustomerData.moreInfos[0].awards);
    }
    if (this.endCustomerData?.moreInfos[0]?.awards && this.endCustomerData.moreInfos[0].awards.length > 0) {
      for (let i = 0; i < ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).controls.length; i++) {
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).at(i).get('awardDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).at(i).get('awardDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('awards') as FormArray).at(i).get('awardDate')?.value));
        }
      }
    }
  }

  updateCourseTrainings() {
    if (this.endCustomerData?.moreInfos[0]?.courseTrainings && this.endCustomerData.moreInfos[0].courseTrainings.length > 0) {
      for (let i = 0; i < this.endCustomerData.moreInfos[0]?.courseTrainings.length; i++) {
        ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "title": new FormControl(null),
          "institution": new FormControl(null),
          "courseDate": new FormControl(null),
          "summary": new FormControl(null),
        }));
      }
      ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).patchValue(this.endCustomerData.moreInfos[0].courseTrainings);
    }
    if (this.endCustomerData?.moreInfos[0]?.courseTrainings && this.endCustomerData.moreInfos[0].courseTrainings.length > 0) {
      for (let i = 0; i < ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).controls.length; i++) {
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).at(i).get('courseDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).at(i).get('courseDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('courseTrainings') as FormArray).at(i).get('courseDate')?.value));
        }
      }
    }
  }

  updateAssociations() {
    if (this.endCustomerData?.moreInfos[0]?.associations && this.endCustomerData.moreInfos[0].associations.length > 0) {
      for (let i = 0; i < this.endCustomerData.moreInfos[0]?.associations.length; i++) {
        ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "associationName": new FormControl(null),
          "positionHeld": new FormControl(null),
          "fromDate": new FormControl(null),
          "toDate": new FormControl(null),
          "summary": new FormControl(null),
        }));
      }
      ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).patchValue(this.endCustomerData.moreInfos[0].associations);
    }
    if (this.endCustomerData?.moreInfos[0]?.associations && this.endCustomerData.moreInfos[0].associations.length > 0) {
      for (let i = 0; i < ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).controls.length; i++) {
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('fromDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('fromDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('fromDate')?.value));
        }
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('toDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('toDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('associations') as FormArray).at(i).get('toDate')?.value));
        }
      }
    }
  }

  updateVolunteerings() {
    if (this.endCustomerData?.moreInfos[0]?.volunteerings && this.endCustomerData.moreInfos[0].volunteerings.length > 0) {
      for (let i = 0; i < this.endCustomerData.moreInfos[0]?.volunteerings.length; i++) {
        ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "organizationName": new FormControl(null),
          "positionHeld": new FormControl(null),
          "fromDate": new FormControl(null),
          "toDate": new FormControl(null),
          "summary": new FormControl(null),
        }));
      }
      ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).patchValue(this.endCustomerData.moreInfos[0].volunteerings);
    }
    if (this.endCustomerData?.moreInfos[0]?.volunteerings && this.endCustomerData.moreInfos[0].volunteerings.length > 0) {
      for (let i = 0; i < ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).controls.length; i++) {
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('fromDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('fromDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('fromDate')?.value));
        }
        if (((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('toDate')?.value) {
          ((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('toDate')?.patchValue(new Date(((this.endCustomerEditForm.get('moreInfos') as FormArray).at(0).get('volunteerings') as FormArray).at(i).get('toDate')?.value));
        }
      }
    }
  }


  updateMembers() {
    if (this.endCustomerData?.members && this.endCustomerData.members.length > 0) {
      for (let i = 0; i < this.endCustomerData.members.length; i++) {
        (this.endCustomerEditForm.get('members') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "name": new FormControl(null),
          "position": new FormControl(null),
          "phone": new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
          "image": new FormControl(null),
          fileName: new FormControl(null),
        }));
      }
      (this.endCustomerEditForm.get('members') as FormArray).patchValue(this.endCustomerData.members);
    }
    if (this.endCustomerData?.members && this.endCustomerData.members.length > 0) {
      for (let i = 0; i < (this.endCustomerEditForm.get('members') as FormArray).controls.length; i++) {
        if ((this.endCustomerEditForm.get('members') as FormArray).at(i).get('image')?.value) {
          const fileName = (this.endCustomerEditForm.get('members') as FormArray).at(i).get('image')?.value.split('/');
          (this.endCustomerEditForm.get('members') as FormArray).at(i).get('fileName')?.patchValue(fileName[fileName.length - 1]);
        }

      }
    }
  }

  updateCertifications() {
    if (this.endCustomerData?.certifications && this.endCustomerData.certifications.length > 0) {
      for (let i = 0; i < this.endCustomerData.certifications.length; i++) {
        (this.endCustomerEditForm.get('certifications') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "name": new FormControl(null),
          "certificate": new FormControl(null),
          fileName: new FormControl(null)
        }));
      }
      (this.endCustomerEditForm.get('certifications') as FormArray).patchValue(this.endCustomerData.certifications);
    }
    if (this.endCustomerData?.certifications && this.endCustomerData.certifications.length > 0) {
      for (let i = 0; i < (this.endCustomerEditForm.get('certifications') as FormArray).controls.length; i++) {
        if ((this.endCustomerEditForm.get('certifications') as FormArray).at(i).get('certificate')?.value) {
          const fileName = (this.endCustomerEditForm.get('certifications') as FormArray).at(i).get('certificate')?.value.split('/');
          (this.endCustomerEditForm.get('certifications') as FormArray).at(i).get('fileName')?.patchValue(fileName[fileName.length - 1]);
        }

      }
    }
  }

  updateResumes() {
    if (this.endCustomerData?.resumes && this.endCustomerData.resumes.length > 0) {
      for (let i = 0; i < this.endCustomerData.resumes.length; i++) {
        (this.endCustomerEditForm.get('resumes') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "name": new FormControl(null),
          "resume": new FormControl(null),
          fileName: new FormControl(null)
        }));
      }
      (this.endCustomerEditForm.get('resumes') as FormArray).patchValue(this.endCustomerData.resumes);
    }
    if (this.endCustomerData?.resumes && this.endCustomerData.resumes.length > 0) {
      for (let i = 0; i < (this.endCustomerEditForm.get('resumes') as FormArray).controls.length; i++) {
        if ((this.endCustomerEditForm.get('resumes') as FormArray).at(i).get('resume')?.value) {
          const fileName = (this.endCustomerEditForm.get('resumes') as FormArray).at(i).get('resume')?.value.split('/');
          (this.endCustomerEditForm.get('resumes') as FormArray).at(i).get('fileName')?.patchValue(fileName[fileName.length - 1]);
        }

      }
    }
  }


  updateEducations() {
    
    if (this.endCustomerData?.educationInfos && this.endCustomerData.educationInfos.length > 0) {
      for (let i = 0; i < this.endCustomerData.educationInfos.length; i++) {
        (this.endCustomerEditForm.get('educationInfos') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "degree": new FormControl(null),
          "yearFrom": new FormControl(null),
          "yearTo": new FormControl(null),
          "location": new FormControl(null),
          "fieldOfStudy": new FormControl(null),
        }));
      }
      (this.endCustomerEditForm.get('educationInfos') as FormArray).patchValue(this.endCustomerData.educationInfos);
      
    }
  }

  updateworkInfos() {
    if (this.endCustomerData?.workInfos && this.endCustomerData.workInfos.length > 0) {
      for (let i = 0; i < this.endCustomerData.workInfos.length; i++) {
        (this.endCustomerEditForm.get('workInfos') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "designation": new FormControl(null),
          "yearFrom": new FormControl(null),
          "yearTo": new FormControl(null),
          "organization": new FormControl(null),
          "specialization": new FormControl(null),
        }));
      }
      (this.endCustomerEditForm.get('workInfos') as FormArray).patchValue(this.endCustomerData.workInfos);
    }
  }

  updatePhotos() {
    if (this.endCustomerData?.photos && this.endCustomerData.photos.length > 0) {


      for (let i = 0; i < this.endCustomerData.photos.length; i++) {
        (this.endCustomerEditForm.get('photos') as FormArray).push(this.fb.group({
          id: new FormControl(null),
          active: new FormControl(null),
          createdBy: new FormControl(null),
          createdOn: new FormControl(null),
          modifiedBy: new FormControl(null),
          modifiedOn: new FormControl(null),
          "photoLink": new FormControl(null),
          fileName: new FormControl(null),
        }));
      }
      (this.endCustomerEditForm.get('photos') as FormArray).patchValue(this.endCustomerData.photos);
    }
    if (this.endCustomerData?.photos && this.endCustomerData.photos.length > 0) {
      for (let i = 0; i < (this.endCustomerEditForm.get('photos') as FormArray).controls.length; i++) {
        if ((this.endCustomerEditForm.get('photos') as FormArray).at(i).get('photoLink')?.value) {
          const fileName = (this.endCustomerEditForm.get('photos') as FormArray).at(i).get('photoLink')?.value.split('/');
          (this.endCustomerEditForm.get('photos') as FormArray).at(i).get('fileName')?.patchValue(fileName[fileName.length - 1]);
        }

      }
    }

  }

  onUpdateEvent(event: any) {
    if (this.selectedAccountType?.accountType?.name == 'Organization') {
      switch (event) {
        case 0:

         

          this.endCustomerData.name = this.endCustomerEditForm.get('name')?.value;
          this.endCustomerData.establishedIn = this.endCustomerEditForm.get('establishedIn')?.value;
          this.endCustomerData.noEmployees = this.endCustomerEditForm.get('noEmployees')?.value;
          this.endCustomerData.url = this.endCustomerEditForm.get('url')?.value;
          this.endCustomerData.address = this.endCustomerEditForm.get('address')?.value;
          this.endCustomerData.email = this.endCustomerEditForm.get('email')?.value;
          this.endCustomerData.phone = this.endCustomerEditForm.get('phone')?.value;
          this.endCustomerData.country = this.endCustomerEditForm.get('country')?.value;
          this.endCustomerData.state = this.endCustomerEditForm.get('state')?.value;
          this.endCustomerData.city = this.endCustomerEditForm.get('city')?.value;
          this.endCustomerData.zipCode = this.endCustomerEditForm.get('zipCode')?.value;
          this.endCustomerData['logo'] = this.endCustomerEditForm.get('logo')?.value;

          break;
        case 10:
          this.endCustomerData['aboutId'] = this.endCustomerEditForm.get('aboutId')?.value;
          this.endCustomerData['specialityInfos'] = this.endCustomerEditForm.get('specialityInfos')?.value;
          break;
        case 20:
          this.endCustomerData['members'] = this.endCustomerEditForm.get('members')?.value;
          break;
        case 30:
          this.endCustomerData['infras'] = this.endCustomerEditForm.get('infras')?.value;
          this.endCustomerData['equipments'] = this.endCustomerEditForm.get('equipments')?.value;
          break;
        case 40:
          this.endCustomerData['serviceInfos'] = this.endCustomerEditForm.get('serviceInfos')?.value;
          break;
        case 50:
          this.endCustomerData['certifications'] = this.endCustomerEditForm.get('certifications')?.value;
          break;
        case 60:
          this.endCustomerData['photos'] = this.endCustomerEditForm.get('photos')?.value;
          break;
        case 100:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['publications'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ publications: [] }];
            this.endCustomerData['moreInfos'][0]['publications'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).value;
          }
          break;
        case 110:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['awards'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ awards: [] }];
            this.endCustomerData['moreInfos'][0]['awards'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).value;
          }
          break;
        case 120:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['courseTrainings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ courseTrainings: [] }];
            this.endCustomerData['moreInfos'][0]['courseTrainings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).value;
          }
          break;
        case 130:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['associations'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ associations: [] }];
            this.endCustomerData['moreInfos'][0]['associations'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).value;
          }
          break;

        case 140:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['volunteerings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ volunteerings: [] }];
            this.endCustomerData['moreInfos'][0]['volunteerings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).value;
          }
          break;
        default:
          break;
      }
    } else {

      switch (event) {


        case 0:
          this.endCustomerData.name = this.endCustomerEditForm.get('name')?.value;
          this.endCustomerData.dob = this.endCustomerEditForm.get('dob')?.value;
          this.endCustomerData.gender = this.endCustomerEditForm.get('gender')?.value;
          this.endCustomerData.workingIn = this.endCustomerEditForm.get('workingIn')?.value;
          this.endCustomerData.position = this.endCustomerEditForm.get('position')?.value;
          this.endCustomerData.address = this.endCustomerEditForm.get('address')?.value;
          this.endCustomerData.email = this.endCustomerEditForm.get('email')?.value;
          this.endCustomerData.phone = this.endCustomerEditForm.get('phone')?.value;
          this.endCustomerData.url = this.endCustomerEditForm.get('url')?.value;
          this.endCustomerData.country = this.endCustomerEditForm.get('country')?.value;
          this.endCustomerData.state = this.endCustomerEditForm.get('state')?.value;
          this.endCustomerData.city = this.endCustomerEditForm.get('city')?.value
          this.endCustomerData.zipCode = this.endCustomerEditForm.get('zipCode')?.value;
          this.endCustomerData['logo'] = this.endCustomerEditForm.get('logo')?.value;
          break;
        case 10:
          this.endCustomerData['aboutId'] = this.endCustomerEditForm.get('aboutId')?.value;

          this.endCustomerData['specialityInfos'] = this.endCustomerEditForm.get('specialityInfos')?.value;
          break;
        case 30:
          this.endCustomerData['educationInfos'] = this.endCustomerEditForm.get('educationInfos')?.value;
          break;
        case 40:
          this.endCustomerData['workInfos'] = this.endCustomerEditForm.get('workInfos')?.value;
          break;
        case 50:
          this.endCustomerData['certifications'] = this.endCustomerEditForm.get('certifications')?.value;
          break;
        case 150:
          this.endCustomerData['resumes'] = this.endCustomerEditForm.get('resumes')?.value;
          break;
        case 80:
          this.endCustomerData['skillSets'] = this.endCustomerEditForm.get('skillSets')?.value;
          break;
        case 100:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['publications'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ publications: [] }];
            this.endCustomerData['moreInfos'][0]['publications'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).value;
          }
          break;
        case 110:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['awards'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ awards: [] }];
            this.endCustomerData['moreInfos'][0]['awards'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).value;
          }
          break;
        case 120:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['courseTrainings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ courseTrainings: [] }];
            this.endCustomerData['moreInfos'][0]['courseTrainings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).value;
          }
          break;
        case 130:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['associations'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ associations: [] }];
            this.endCustomerData['moreInfos'][0]['associations'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).value;
          }
          break;

        case 140:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            this.endCustomerData.moreInfos[0]['volunteerings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).value;
          } else {
            this.endCustomerData['moreInfos'] = [{ volunteerings: [] }];
            this.endCustomerData['moreInfos'][0]['volunteerings'] = (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).value;
          }
          break;

        default:
          break;
      }
    }

    this.updateProfile(event);
  }

  areAllPropertiesNull(obj: any): boolean {
    for (const key in obj) {
      if (!(obj[key] === null || obj[key] === '') && !(key == 'id' || key == 'active' || key == 'createdBy' || key == 'createdOn' || key == 'modifiedBy' || key == 'modifiedOn' /* || key=='roomsAvailable' */)) {
        return false;
      }
    }
    return true;
  }
  updateProfile(event: any) {
    if (this.selectedAccountType?.accountType?.name == 'Organization') {
      switch (event) {
        case 0:

          break;
        case 10:
          if (this.areAllPropertiesNull(this.endCustomerData.aboutId)) {
            delete this.endCustomerData.aboutId;
          }
          if (this.endCustomerData.specialityInfos && this.endCustomerData.specialityInfos.length > 0) {
            let specialityInfos = [];
            for (let i = 0; i < this.endCustomerData.specialityInfos.length; i++) {
              specialityInfos.push({ specialityInfoId: this.endCustomerData.specialityInfos[i] });
            }
            this.endCustomerData.specialityInfos = specialityInfos;
          }
          break;
        case 20:
          this.endCustomerData.members = this.removeEmptyRecords(this.endCustomerData, 'members');
          break;
        case 30:
          this.endCustomerData.infras = this.removeEmptyRecords(this.endCustomerData, 'infras');



          if (this.endCustomerData?.equipments && this.endCustomerData.equipments.length > 0) {
            let equipments = [];
            for (let i = 0; i < this.endCustomerData.equipments.length; i++) {
              if (this.oldEndCustomerData?.equipments && this.oldEndCustomerData.equipments.length > 0) {
                const equipmentExist = this.oldEndCustomerData.equipments.find((obj: any) => this.endCustomerData.equipments[i] === obj?.equipmentInfoId);
                if (equipmentExist) {
                  equipments.push(equipmentExist);
                } else {
                  equipments.push({ equipmentInfoId: this.endCustomerData.equipments[i] });
                }
              } else {
                equipments.push({ equipmentInfoId: this.endCustomerData.equipments[i] });
              }
            }
            this.endCustomerData.equipments = JSON.parse(JSON.stringify(equipments));
          }
          break;

        case 40:
          if (this.endCustomerData?.serviceInfos && this.endCustomerData.serviceInfos.length > 0) {
            let serviceInfos = [];
            for (let i = 0; i < this.endCustomerData.serviceInfos.length; i++) {
              if (this.oldEndCustomerData?.serviceInfos && this.oldEndCustomerData.serviceInfos.length > 0) {
                const serviceExist = this.oldEndCustomerData.serviceInfos.find((obj: any) => this.endCustomerData.serviceInfos[i] === obj?.serviceInfoId);
                if (serviceExist) {
                  serviceInfos.push(serviceExist);
                } else {
                  serviceInfos.push({ serviceInfoId: this.endCustomerData.serviceInfos[i] });
                }
              } else {
                serviceInfos.push({ serviceInfoId: this.endCustomerData.serviceInfos[i] });
              }

            }
            this.endCustomerData.serviceInfos = serviceInfos;
          }
          break;

        case 50:
          this.endCustomerData.certifications = this.removeEmptyRecords(this.endCustomerData, 'certifications');
          break;
        case 60:
          this.endCustomerData.photos = this.removeEmptyRecords(this.endCustomerData, 'photos');
          break;
        case 100:
          const publications = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'publications');
          this.endCustomerData.moreInfos[0].publications = publications;
          break;
        case 110:
          const awards = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'awards');
          this.endCustomerData.moreInfos[0].awards = awards;
          break;
        case 120:
          const courseTrainings = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'courseTrainings');
          this.endCustomerData.moreInfos[0].courseTrainings = courseTrainings;
          break;
        case 130:
          const associations = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'associations');
          this.endCustomerData.moreInfos[0].associations = associations;
          break;
        case 140:
          const volunteerings = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'volunteerings');
          this.endCustomerData.moreInfos[0].volunteerings = volunteerings;
          break;

        default:
          break;
      }

    } else {
      switch (event) {
        case 0:

          break;
        case 10:
          if (this.areAllPropertiesNull(this.endCustomerData.aboutId)) {
            delete this.endCustomerData.aboutId;
          }

          if (this.endCustomerData.specialityInfos && this.endCustomerData.specialityInfos.length > 0) {
            let specialityInfos = [];
            for (let i = 0; i < this.endCustomerData.specialityInfos.length; i++) {
              if (this.oldEndCustomerData?.skillSets && this.oldEndCustomerData.skillSets.length > 0) {
                const specialityInfoIdExist = this.oldEndCustomerData.specialityInfos.find((obj: any) => this.endCustomerData.specialityInfos[i] === obj?.specialityInfoId);
                if (specialityInfoIdExist) {
                  specialityInfos.push(specialityInfoIdExist);
                } else {
                  specialityInfos.push({ specialityInfoId: this.endCustomerData.specialityInfos[i] });
                }
              } else {
                specialityInfos.push({ specialityInfoId: this.endCustomerData.specialityInfos[i] });
              }

            }
            this.endCustomerData.specialityInfos = specialityInfos;
          }
          break;
        case 30:
          this.endCustomerData.educationInfos = this.removeEmptyRecords(this.endCustomerData, 'educationInfos');
          break;
        case 40:
          this.endCustomerData.workInfos = this.removeEmptyRecords(this.endCustomerData, 'workInfos');
          break;
        case 50:
          this.endCustomerData.certifications = this.removeEmptyRecords(this.endCustomerData, 'certifications');
          break;
        case 150:
         


          this.endCustomerData.resumes = this.removeEmptyRecords(this.endCustomerData, 'resumes');
          break
        case 80:
          if (this.endCustomerData?.skillSets && this.endCustomerData.skillSets.length > 0) {
            let skillSets = [];
            for (let i = 0; i < this.endCustomerData.skillSets.length; i++) {
              if (this.oldEndCustomerData?.skillSets && this.oldEndCustomerData.skillSets.length > 0) {
                const skillsetInfoIdExist = this.oldEndCustomerData.skillSets.find((obj: any) => this.endCustomerData.skillSets[i] === obj?.skillsetInfoId);
                if (skillsetInfoIdExist) {
                  skillSets.push(skillsetInfoIdExist);
                } else {
                  skillSets.push({ skillsetInfoId: this.endCustomerData.skillSets[i] });
                }
              } else {
                skillSets.push({ skillsetInfoId: this.endCustomerData.skillSets[i] });
              }
            }
            this.endCustomerData.skillSets = skillSets;
          }
          break;
        case 100:
          const publications = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'publications');
          this.endCustomerData.moreInfos[0].publications = publications;
          break;
        case 110:
          const awards = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'awards');
          this.endCustomerData.moreInfos[0].awards = awards;
          break;
        case 120:
          const courseTrainings = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'courseTrainings');
          this.endCustomerData.moreInfos[0].courseTrainings = courseTrainings;
          break;
        case 130:
          const associations = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'associations');
          this.endCustomerData.moreInfos[0].associations = associations;
          break;
        case 140:
          const volunteerings = this.removeEmptyRecords(this.endCustomerData.moreInfos[0], 'volunteerings');
          this.endCustomerData.moreInfos[0].volunteerings = volunteerings;
          break;

        default:
          break;
      }
    }
    
    this.endCustomerService.executePutRequest(MweConstants.updateEndCustomerUrl + this.endCustomerData.id, this.endCustomerData).subscribe(data => {
      Swal.fire('', 'Profile updated successfully !!', 'success').then(result => {
        this.endCustomerData = data?.data;
        this.oldEndCustomerData = data?.data;
        this.endCustomerData['account'] = this.selectedAccountType;
        this.oldEndCustomerData['account'] = this.selectedAccountType;
        this.updateForm(event)
      });

    }, error => {
      this.snackbarService.openSnackBar(error.error.error, '');
    })
  }

  updateForm(event: any) {
    if (this.selectedAccountType?.accountType?.name == 'Organization') {
      switch (event) {
        case 0:
          this.endCustomerEditForm.get('name')?.patchValue(this.endCustomerData.name)
          if (this.endCustomerData?.establishedIn) {
            this.endCustomerEditForm.get('establishedIn')?.patchValue(new Date(this.endCustomerData.establishedIn));
          }
          //this.endCustomerEditForm.get('establishedIn')?.patchValue(this.endCustomerData.establishedIn);
          this.endCustomerEditForm.get('noEmployees')?.patchValue(this.endCustomerData.noEmployees);
          this.endCustomerEditForm.get('url')?.patchValue(this.endCustomerData.url);
          this.endCustomerEditForm.get('address')?.patchValue(this.endCustomerData.address);
          this.endCustomerEditForm.get('email')?.patchValue(this.endCustomerData.email);
          this.endCustomerEditForm.get('phone')?.patchValue(this.endCustomerData.phone);
          this.endCustomerEditForm.get('country')?.patchValue(this.endCustomerData.country);
          this.endCustomerEditForm.get('state')?.patchValue(this.endCustomerData.state);
          this.endCustomerEditForm.get('city')?.patchValue(this.endCustomerData.city);
          this.endCustomerEditForm.get('zipCode')?.patchValue(this.endCustomerData.zipCode);
          this.endCustomerEditForm.get('logo')?.patchValue(this.endCustomerData['logo']);
          this.hospitalProfileComponent?.personalInfomationComponent?.refresh();
          break;
        case 10:
          this.endCustomerEditForm.get('aboutId')?.patchValue(this.endCustomerData.aboutId);
          this.endCustomerEditForm.get('specialityInfos')?.patchValue(this.endCustomerData.specialityInfos);
          this.updateSpecility();
          this.hospitalProfileComponent?.profileAboutComponent?.refresh();
          break;
        case 20:
          (this.endCustomerEditForm.get('members') as FormArray).clear();
          this.updateMembers();
          //this.endCustomerEditForm.get('members')?.patchValue(this.endCustomerData.members);
          this.hospitalProfileComponent?.memberProfileComponent?.refresh();
          break;
        case 30:
          this.endCustomerEditForm.get('infras')?.patchValue(this.endCustomerData.infras);
          this.endCustomerEditForm.get('equipments')?.patchValue(this.endCustomerData.equipments);
          this.updateEquipments();
          this.hospitalProfileComponent?.infraProfileComponent?.refresh();
          break;
        case 40:
          this.endCustomerEditForm.get('serviceInfos')?.patchValue(this.endCustomerData.serviceInfos);
          this.updateServiceInfoId();
          this.hospitalProfileComponent?.servicesProfileComponent?.refresh();
          break;
        case 50:
          (this.endCustomerEditForm.get('certifications') as FormArray).clear();
          this.updateCertifications();
          //this.endCustomerEditForm.get('certifications')?.patchValue(this.endCustomerData.certifications);
          this.hospitalProfileComponent?.certificationProfileComponent?.refresh();
          break;
        case 60:
          (this.endCustomerEditForm.get('photos') as FormArray).clear();
          this.updatePhotos();
          this.hospitalProfileComponent?.photosProfileComponent?.refresh();
          break;
        case 100:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).clear();
            this.updatePublications();
          }
          this.hospitalProfileComponent?.moreInfoProfileComponent?.updatePublications();
          break;
        case 110:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).clear();
            this.updateAwards();
          }
          this.hospitalProfileComponent?.moreInfoProfileComponent?.updateAwards();
          break;
        case 120:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).clear();
            this.updateCourseTrainings();
          }
          this.hospitalProfileComponent?.moreInfoProfileComponent?.updateCourses();
          break;
        case 130:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).clear();
            this.updateAssociations();
          }
          this.hospitalProfileComponent?.moreInfoProfileComponent?.updateAssociations();
          break;
        case 140:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).clear();
            this.updateVolunteerings();
          }
          this.hospitalProfileComponent?.moreInfoProfileComponent?.updateVolunteering();
          break;

        default:
          break;
      }
    } else {
      this.endCustomerData['educationInfos'] = JSON.parse(JSON.stringify(this.endCustomerEditForm.get('educationInfos')?.value));
      this.endCustomerData['workInfos'] = JSON.parse(JSON.stringify(this.endCustomerEditForm.get('workInfos')?.value));
      switch (event) {
        case 0:
          this.endCustomerEditForm.get('name')?.patchValue(this.endCustomerData.name)
          if (this.endCustomerData?.dob) {
            this.endCustomerEditForm.get('dob')?.patchValue(new Date(this.endCustomerData.dob));
          }
          this.endCustomerEditForm.get('gender')?.patchValue(this.endCustomerData.gender);
          this.endCustomerEditForm.get('workingIn')?.patchValue(this.endCustomerData.workingIn);
          this.endCustomerEditForm.get('position')?.patchValue(this.endCustomerData.position);
          this.endCustomerEditForm.get('address')?.patchValue(this.endCustomerData.address);
          this.endCustomerEditForm.get('email')?.patchValue(this.endCustomerData.email);
          this.endCustomerEditForm.get('phone')?.patchValue(this.endCustomerData.phone);
          this.endCustomerEditForm.get('country')?.patchValue(this.endCustomerData.country);
          this.endCustomerEditForm.get('state')?.patchValue(this.endCustomerData.state);
          this.endCustomerEditForm.get('city')?.patchValue(this.endCustomerData.city);
          this.endCustomerEditForm.get('zipCode')?.patchValue(this.endCustomerData.zipCode);
          this.endCustomerEditForm.get('logo')?.patchValue(this.endCustomerData['logo']);
          this.doctorProfileComponent?.personalInfomationComponent?.refresh();
          break;
        case 10:
          this.endCustomerEditForm.get('aboutId')?.patchValue(this.endCustomerData.aboutId);
          this.doctorProfileComponent?.profileAboutComponent?.refresh();
          this.endCustomerEditForm.get('specialityInfos')?.patchValue(this.endCustomerData.specialityInfos);
          this.updateSpecility();
          this.doctorProfileComponent?.specialitiesComponent?.refresh();
          break;
        case 20:

          break;
        case 30:
          //this.endCustomerData['workInfos']=JSON.parse(JSON.stringify(this.endCustomerEditForm.get('workInfos')?.value));
          //this.endCustomerData['educationInfos']=JSON.parse(JSON.stringify(this.endCustomerEditForm.get('educationInfos')?.value));
          this.endCustomerData.educationInfos = this.removeEmptyRecords(this.endCustomerData, 'educationInfos');
          (this.endCustomerEditForm.get('educationInfos') as FormArray).clear();
          this.updateEducations();
          this.doctorProfileComponent?.educationProfileComponent?.refresh();
          break;
        case 40:
          //this.endCustomerData['educationInfos']=JSON.parse(JSON.stringify(this.endCustomerEditForm.get('educationInfos')?.value));
          //  this.endCustomerData['workInfos']=JSON.parse(JSON.stringify(this.endCustomerEditForm.get('workInfos')?.value));
          this.endCustomerData.workInfos = this.removeEmptyRecords(this.endCustomerData, 'workInfos');
          (this.endCustomerEditForm.get('workInfos') as FormArray).clear();
          this.updateworkInfos();
          this.doctorProfileComponent?.workExperienceProfileComponent?.refresh();
          break;

        case 50:
          (this.endCustomerEditForm.get('certifications') as FormArray).clear();
          this.updateCertifications();
          //this.endCustomerEditForm.get('certifications')?.patchValue(this.endCustomerData.certifications);
          this.doctorProfileComponent?.certificationProfileComponent?.refresh();
          break;
        case 80:
          this.endCustomerEditForm.get('skillSets')?.patchValue(this.endCustomerData.skillSets);
          this.updateSkillSets();
          this.doctorProfileComponent?.skillSetProfileComponent?.refresh();
          break;
        case 100:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['publications'] as FormArray).clear();
            this.updatePublications();
          }
          this.doctorProfileComponent?.moreInfoProfileComponent?.updatePublications();
          break;
        case 110:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['awards'] as FormArray).clear();
            this.updateAwards();
          }
          this.doctorProfileComponent?.moreInfoProfileComponent?.updateAwards();
          break;
        case 120:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['courseTrainings'] as FormArray).clear();
            this.updateCourseTrainings();
          }
          this.doctorProfileComponent?.moreInfoProfileComponent?.updateCourses();
          break;
        case 130:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['associations'] as FormArray).clear();
            this.updateAssociations();
          }
          this.doctorProfileComponent?.moreInfoProfileComponent?.updateAssociations();
          break;
        case 140:
          if (this.endCustomerData?.moreInfos && this.endCustomerData.moreInfos.length > 0) {
            (((this.endCustomerEditForm.controls['moreInfos'] as FormArray).at(0) as FormGroup).controls['volunteerings'] as FormArray).clear();
            this.updateVolunteerings();
          }
          this.doctorProfileComponent?.moreInfoProfileComponent?.updateVolunteering();
          break;
        default:
          break;
      }
    }
  }

  removeEmptyRecords(req: any, type: string) {
    if (req[type] && req[type].length > 0) {
      let members: any = [];
      for (let i = 0; i < req[type].length; i++) {
        if (!this.areAllPropertiesNull(req[type][i])) {
          members.push(req[type][i]);
        }
      }
      return members;
    } else {
      return [];
    }
  }
}

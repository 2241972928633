<app-website-header></app-website-header>
<div class="container">
    <div class="row">
        <div class="col-md-12 mt-3 terms">
            <h2>Terms and Conditions</h2>
        </div>
    </div>
    <div>
        <p [innerHTML]="content" ></p>
    </div>
</div>
<app-website-footer></app-website-footer>
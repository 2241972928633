import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hospital-services',
  templateUrl: './hospital-services.component.html',
  styleUrls: ['./hospital-services.component.scss']
})
export class HospitalServicesComponent {

  @Input() data: any;
}

import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Signup } from 'src/app/models/SignupModels';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, map, startWith } from 'rxjs';
import { SweetAlertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-oem-signup',
  templateUrl: './oem-signup.component.html',
  styleUrls: ['./oem-signup.component.css']
})
export class OemSignupComponent {


  OemSignup!: Signup;
  emailSent: boolean = true;
  showError: boolean = false;
  errorMessage = '';
  countryCodes: any[] = [];
  accountType: string ='';
  badRequest: boolean = false;
  selectedPhoneCode: string = '';  
isSignupButtonEnabled: boolean = false;
countrycodes: any[] =[];
countryCode: string | undefined;
filter: any;
  showOptions: boolean | undefined;
  emailInvalid: boolean= false;
  email:string = '';
  validNumber: boolean=true;
  isProceed:boolean=false;
showPassword: boolean = false;
showConfirmPassword: boolean = false;
urlInvalid: boolean = false;
@ViewChild('countryCodeInput') countryCodeInput!: ElementRef;
  constructor(private authService: AuthService, private http: HttpClient, private sweetAlertService : SweetAlertService) {
    this.OemSignup = { role: 'OEM', phoneCode: '' } as Signup;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (this.countryCodeInput && !this.countryCodeInput.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }
  ngOnInit(): void {
    this.fetchDropdownData();
 
  }
  fetchDropdownData() {
    forkJoin([
      this.authService.getAccounttypes('admin'),
      this.authService.getCountrycodes(0, 300)
    ]).subscribe(
      ([accounttypesResponse, countryCodesResponse]) => {
        this.countrycodes = countryCodesResponse.data;
        const adminAccountType = accounttypesResponse.data.find((account: any) => account.accountType.name === 'admin');
               if (adminAccountType) {
                 this.OemSignup.accountType = adminAccountType.id;;
               } else {
                 console.error('Admin account type not found.');
               }
      },
      (error) => {
        console.error('Error fetching account types or country codes:', error);
      }
    );
  }
  updateSelectedCountry(event: any) {
    const selectedValue = event.target?.value;
    if (selectedValue) {
      this.selectedPhoneCode = selectedValue;
      this.OemSignup.phoneCode = selectedValue;
    }
  }

  preventCopyPaste(event: Event): void {
    event.preventDefault();
  }
  
  preventContextMenu(event: MouseEvent): void {
    event.preventDefault();
  }
  
  submitForm(form: NgForm) {
    if (form.valid && !this.urlInvalid) {
      this.isProceed=false;

      this.authService.signup(this.OemSignup)
        .subscribe({
          next: (response) => {
            this.emailSent = false;

          },
          error: (error) => {
            console.error('Error submitting form:', error);
            if (error.status === 400) {
              this.badRequest = true;
              this.emailSent = true;
              this.errorMessage = error.error.message || 'Invalid data entered.';
            } else {
              this.errorMessage = error.message || 'An error occurred while submitting the form.';
            }
            Swal.fire('Error', this.errorMessage? this.errorMessage : 'An error occurred while submitting the form.', 'warning');
            
          }
        });
    } else {
      this.showError = true;
      this.errorMessage = 'Please fill out all required fields.';
      
      this.isProceed = true;
      Swal.fire('Error',  'An error occurred while submitting the form.', 'warning');
    }
  }
  passwordValidation(password: string): string[] {
    const errors = [];
    const capitalRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /^(?!\s+)(?!.*\s+$)(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
   

    if (typeof password !== "undefined" && password != null && password.length > 0) {
        if (password.length < 8 ) {
            errors.push('Password must be at least 8 characters');
        } else if (!capitalRegex.test(password)) {
            errors.push('Password must contain at least one capital letter');
        } else if (!numberRegex.test(password)) {
            errors.push('Password must contain at least one number');
        } else if (!specialCharRegex.test(password)) {
            errors.push('Password must contain at least one special character');
        } 
    }
    return errors;
}
updateSignupButtonState(event: Event) {
  const target = event.target as HTMLInputElement;
  this.isSignupButtonEnabled = target.checked;
}

filteredCountryCodes: {
  phoneCode: string | undefined; countryCode: string 
}[] = [];
filterOptions(inputValue: string) {  
  if (!inputValue) {
    this.filteredCountryCodes = this.countrycodes;
    return;
  }

  const lowerInput = inputValue.toLowerCase();
  this.filteredCountryCodes = this.countrycodes.filter(code =>
    code.countryCode && code.countryCode.toLowerCase().includes(lowerInput)
    
  );
  const exactMatch = this.countrycodes.find(code =>
    code.countryCode && code.countryCode.toLowerCase() === lowerInput
  );

  if (exactMatch) {
    this.selectOption(exactMatch);
  }
  else {
    this.showOptions = true; 
  }

}



selectOption(code: any) {
  this.countryCode = code.countryCode; 
  this.OemSignup.phoneCode = code.phoneCode; 
  this.OemSignup.countryCode = code.countryCode;
  this.showOptions = false; 
}

  
  emailValidation(email: string) {
this.emailInvalid = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
} 
phonenumbervalidation(phoneNumber: any) { 
  this.validNumber = /^[0-9]+$/.test(phoneNumber) &&phoneNumber.length >= 5 && phoneNumber.length <= 15;}

  resendVerificationMail(): void {
    this.authService.resendVerificationEmail(this.OemSignup.email)
      .subscribe(
        (response) => {
          if (response && response.status === 'OK') {
            this.sweetAlertService.showSuccess('Email Sent', 'Verification Email Sent');
          } else {
            this.sweetAlertService.showError('Error', 'Error Sending Verification Email');
          }
        }
        
      );
  }

isValidUrl(url: string): boolean {
  const urlPattern = /^(https:\/\/|http:\/\/|www\.)/;
  return urlPattern.test(url);
}


validateUrl(event: any): void {
  const url = event.target.value;
  this.urlInvalid = url && !this.isValidUrl(url);
}



  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onlyText(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || 
        (charCode >= 65 && charCode <= 90) || 
        (charCode >= 97 && charCode <= 122) || 
        charCode === 32) { 
      return true;
    }
    return false;
  }
  onlyText2(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    if ( 
        (charCode >= 65 && charCode <= 90) || 
        (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }


}
